import devLog from "@/classes/log"
import ObjectType, { type AccountId, type AddObjectTypeObject, type ObjectTypeObject, type ObjectTypePayload } from "../../objectType"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import apis from "@/classes/apis"
import jsonHelpers from "@/helpers/helpers.json"
import { T } from "@/classes/i18n"
import { Button } from "@/classes/buttons"
import dialogs from "@/dialogs/dialogs"
import products from "../.."
import type { ZeroTouchDevice } from "./zerotouchDevices"
import type { AndroidDevice, EnterpriseDevicePlusZeroTouch } from "./androidDevices"
import deviceHelpers from "@/helpers/helpers.devices"
import router from "@/router/router"
import stringHelpers from "@/helpers/helpers.strings"
import numberHelpers from "@/helpers/helpers.numbers"
import androidDevices from "./androidDevices"

interface ZeroTouchCustomer extends ObjectType<ZeroTouchCustomer> {
    "id": string,
    "customers": [
        {
            "companyId": string,
            "companyName": string,
            "name": string
        }
    ]
}


class ZeroTouchCustomers extends ObjectType<ZeroTouchCustomer> {
    constructor(payload: ObjectTypePayload<ZeroTouchCustomer>) {
        super(payload)

    }
    /**
    * Converts Object for ObjectTypeStore
    */
    convertObjectForStore(accountId: AccountId, objectBase: AddObjectTypeObject<ZeroTouchCustomer>) {
        objectBase.$itemlist = {
            "isCheckboxChecked": false,
            "isCheckboxHovering": false,
        }
        
        let newObject = jsonHelpers.copyObject(objectBase) as ObjectTypeObject<ZeroTouchCustomer>
        objectBase.toJSON = () => {
            delete newObject.$itemlist
            delete newObject.toJSON
            return newObject as ZeroTouchCustomer
        }
    }
}

const zeroTouchCustomers = new ZeroTouchCustomers({
    "productType":"mobileSecurity",
    "objectType":"zeroTouchCustomers",
    "slug":"zeroTouchCustomers",
    "hasStore":true,
    "objectTypeInfo":{
        "nameProperty":{
            "primary": "id"
        },
        "primaryKeyProperty":{
            "property":"id"
        }
    },
    "appearance":{
        "iconClass":"fab fa-android",
    },
    "apiInfo":{
        "url": "/sms-mgt-api/api/2.0",
        "getCountGETProperties": "?props[]=null&select=total",
        // GET
        "getObjectListResponseProperty": "accounts",
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/android/androiddeviceprovisioning/customers",
        // delete
        "deleteObjectPath": "/tenants/{tenantDomain}/android/androiddeviceprovisioning/{objectId}",
    }
})
export default zeroTouchCustomers