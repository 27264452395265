<template>
  <div v-html="markdown.render(src)" />
</template>

<script setup lang="ts">
import MarkdownIt from "markdown-it";

const markdown = new MarkdownIt({
  breaks: true,
  html: false,
  linkify: true,
})

defineProps({
  src: {
    type: String,
    default: ""
  }
});
</script>