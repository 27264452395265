<script setup lang="ts">
    import getterHelpers from "@/helpers/helpers.getters";
    import loader from "../components/loader.vue";
    import { T } from "@/classes/i18n";
    import { computed, onMounted, onUnmounted, ref, watch } from "vue";
    import { useModalStateStore, type AddEditRuleState } from '@/classes/modalStates'
    import inputVueSelect from "../inputtypes/input-vue-select.vue";
    import objectStores from "@/classes/init";
    import type { SunNkViewNode, SunNkViewService, SunUtmNode } from "@/classes/objectTypes/unifiedNetwork/topologies";
    import products from "@/classes/objectTypes";
    import buttonComponent from "../components/button.vue";
    import { type Modal } from "../templates/modal";
    import { MutationTypes } from "@/store/vuex.store";
    import { useVue } from "@/app"
    import requestHandler from "@/queries/requests";
    import config from "@/classes/config";
    import tenantHelpers from "@/helpers/helpers.tenants";
    import encodingHelpers from "@/helpers/helpers.encoding";

    const props = defineProps<{
        properties: {
            coreUtmId:"" | string,
            satelliteUtmId:"" | string,
            nodes: SunUtmNode[],
            sourceUTM:"" | string,
            sourceNode:"" | string,
            destinationUTM:""
            destinationNode:""
            service:"",
            selectedTopologyId: string
        }
    }>();

    const activeAccountId = computed(() => {
        return getterHelpers.useStore()?.getters.getActiveAccountId
    })
    const utmNameMap = computed(() => {
        const nameMap: { [id: string]: {
            utmName: string,
            icon: string
        } } = {}
        objectStores.uscUtms.getObjectsFromStore(activeAccountId.value).filter((utm) => {
            return [props.properties.satelliteUtmId, props.properties.coreUtmId].includes(utm.utmId)
        }).forEach((utm) => {
            nameMap[utm.utmId] = {
                utmName: utm.utmname || utm.utmId,
                icon: utm.utmId == props.properties.coreUtmId ? 'fal fa-crown' : 'fal fa-satellite-dish'
            }
        })
        return nameMap
    })
    const modalState = computed(() => {
        return useModalStateStore().getStateById("addEditRule") as AddEditRuleState | undefined
    })

    const initialized = ref(false)

    // FUNCTIONS
    const checkReadyState = () => {
        if (initialized.value == true && sourceUTM.value && sourceNode.value && destinationUTM.value && destinationNode.value && service.value) {
            getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
            getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = false
        }
        else {
            getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = true
        }
    }
   
    const allNodes = ref(<SunUtmNode[] | undefined>undefined)
    const getGroupedNodes = (nodes: SunNkViewNode[]) => {
        const groups = {
            networkGroups: {
                name: 'Network Groups',
                nodes: [] as selectOption[]
            },
            networkObjects: {
                name: 'Network Objects',
                nodes: [] as selectOption[]
            }
        }

        nodes.forEach((node) => {
            if (Array.isArray(node.node_refs)) {
                groups.networkGroups.nodes.push({
                    id: node.id ? node.id : node.name,
                    text: node.name,
                    groupId: `#${'Network Groups'.replace(" ", "")}Header`,
                    icon: "icon icon-node-group"
                })
            } else {
                groups.networkObjects.nodes.push({
                    id: node.id ? node.id : node.name,
                    text: node.name + (node.node_address ? " (" + node.node_address + ")" : "") + (node?.node_zone?.name ? " (" + node?.node_zone?.name + ")" : ""),
                    groupId: `#${'Network Objects'.replace(" ", "")}Header`,
                    icon: products.unifiedNetwork.topologies.view.getIconClassForNode(node)
                })
            }
        })

        return Object.values(groups).reduce((acc, group) => {
            if (group.nodes.length > 0) {
                acc.push({
                    id: `#${group.name.replace(" ", "")}Header`,
                    text: T(group.name),
                    type: 'groupName',
                    disabled: true
                })
                acc.push(...group.nodes.sort((a, b) => a.text.localeCompare(b.text)).sort((a, b) => (a.icon || '').localeCompare(b.icon || '')))
            }
            return acc
        }, [] as selectOption[])
    }

    const renderAddServiceDialog = () => {
        useModalStateStore().addStateToStore("addEditRule", {
            "sourceUTM": sourceUTM.value,
            "sourceNode": sourceNode.value,
            "destinationUTM": destinationUTM.value,
            "destinationNode": destinationNode.value,
            "service": service.value
        })
        const modal: Modal = {
            "id": "addServiceDialog",
            "content": {
                "body": {
                    "component": "add-service",
                    "properties": {
                        "sattelitteId": destinationUTM.value,
                        "selectedTopologyId": props.properties.selectedTopologyId || "",
                        "serviceGroups": serviceOptions.value
                    },
                },
                "title": {
                    "icon": "fal fa-plus",
                    "text": T("Add Service")
                }
            },
            "abortable": true,
            "buttons": [
                {
                    'text': T('Abort'),
                    'icon': 'fal fa-times',
                    'disabled': false,
                    'onClick': () => {
                        getterHelpers.useStore().commit(MutationTypes.removeModal, { })
                    }
                },
                {
                    'text': T('Add Service'),
                    'icon': 'fal fa-plus',
                    'disabled': true,
                    'loading': false,
                    'onClick': () => {
                        useVue().$refs.modals.$refs.modal.$refs.modalComponent.submit()
                    }
                },
            ],
        }
        getterHelpers.useStore().commit(MutationTypes.addModal, modal)
        
    }

    const renderAddNetworkObjectDialog = (utm: "source" | "destination") => {
        useModalStateStore().addStateToStore("addEditRule", {
            "sourceUTM": sourceUTM.value,
            "sourceNode": sourceNode.value,
            "destinationUTM": destinationUTM.value,
            "destinationNode": destinationNode.value,
            "service": service.value
        })
        const modal: Modal = {
            "id": "addNetworkObject",
            "content": {
                "body": {
                    "component": "add-network-object",
                    "properties": {
                        "sattelitteId": utm == "destination" ? destinationUTM.value : sourceUTM.value,
                        "selectedTopologyId": props.properties.selectedTopologyId || "",
                    },
                },
                "title": {
                    "icon": "fal fa-plus",
                    "text": T("Add Network Object")
                }
            },
            "abortable": true,
            "buttons": [
                {
                    'text': T('Abort'),
                    'icon': 'fal fa-times',
                    'disabled': false,
                    'onClick': () => {
                        getterHelpers.useStore().commit(MutationTypes.removeModal, {})
                    }
                },
                {
                    'text': T('Add Network Object'),
                    'icon': 'fal fa-plus',
                    'disabled': true,
                    'loading': false,
                    'onClick': () => {
                        useVue().$refs.modals.$refs.modal.$refs.modalComponent.submit()
                    }
                },
            ],
        }
        getterHelpers.useStore().commit(MutationTypes.addModal, modal)
    }

    const sourceNodeOptions = computed(() => {
        const thisNode = (allNodes.value ? allNodes.value : props.properties.nodes).find(node => node.utmId === sourceUTM.value);
        return getGroupedNodes(thisNode?.nkView?.nodes || []);
    });

    const destinationNodeOptions = computed(() => {
        const thisNode = (allNodes.value ? allNodes.value : props.properties.nodes).find(node => node.utmId === destinationUTM.value);
        return getGroupedNodes(thisNode?.nkView?.nodes || []);
    });


    const getGroupedServices = (services: SunNkViewService[]) => {
        const groups = {
            serviceGroups: {
                name: 'Service Groups',
                services: [] as selectOption[]
            },
            services: {
                name: 'Services',
                services: [] as selectOption[]
            }
        }
 
        services.forEach((service) => {
            if (Array.isArray(service.service_refs)) {
                groups.serviceGroups.services.push({
                    id: service.id ? service.id : service.name,
                    text: service.name,
                    groupId: `#${'Service Groups'.replace(" ", "")}Header`,
                    icon: "icon icon-serviceobject-group",
                    tooltip:{
                        "htmlTooltip":true,
                        "customId": service.name + service.id,
                        "content":`
                            <table style="margin:0;">
                                <tr>
                                    <td><strong>${T('Service Group')}</strong></td>
                                </tr>
                                <tr>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>${T('Services:')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        ${service.service_refs.join('<br>')}
                                    </td>
                                </tr>
                            </table>
                        `
                    }
                })
            }
            else if(Array.isArray(service?.services) && service.id == undefined) {
                if(service.id == undefined) console.log(service);
                let allServices = ""
                service.services.forEach((service) => {
                    allServices = allServices + encodingHelpers.escapeHTML(service.name) + "<br>"
                })
                groups.serviceGroups.services.push({
                    id: service.name,
                    text: service.name,
                    groupId: `#${'Service Groups'.replace(" ", "")}Header`,
                    icon: "icon icon-serviceobject-group",
                    tooltip:{
                        "htmlTooltip":true,
                        "customId": service.name,
                        "content":`
                            <table style="margin:0;">
                                <tr>
                                    <td><strong>${T('Service Group')}</strong></td>
                                </tr>
                                <tr>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>${T('Services:')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        ${allServices}
                                    </td>
                                </tr>
                            </table>
                        `
                    }
                })  
            }
            else {
                groups.services.services.push({
                    id: service.id ? service.id : service.name,
                    text: service.name,
                    groupId: `#${'Services'.replace(" ", "")}Header`,
                    icon: /^(tcp|udp|icmp|ipv6-icmp)$/.test(service.protocol) ? 'icon icon-serviceobject-' + service.protocol : 'icon icon-serviceobject-other',
                    tooltip: {
                        "htmlTooltip": true,
                        "customId":service.name+service.id,
                        "content": `
                            <table style="margin:0;">
                                <tr>
                                    <td><strong>${T('Service')}</strong></td>
                                </tr>
                                <tr>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>${T('Protocol')}:</td>
                                    <td>${service.protocol}</td>
                                </tr>
                                <tr>
                                    <td>${T('Source ports')}:</td>
                                    <td>${(service["src-ports"]?.join(", ") || '-')}</td>
                                </tr>
                                <tr>
                                    <td>${T('Destination ports')}:</td>
                                    <td>${(service["dst-ports"]?.join(", ") || '-')}</td>
                                </tr>
                            </table>
                        `
                    }
                })
            }
        })

        return Object.values(groups).reduce((acc, group) => {
            if (group.services.length > 0) {
                acc.push({
                    id: `#${group.name.replace(" ", "")}Header`,
                    text: T(group.name),
                    type: 'groupName',
                    disabled: true
                })
                acc.push(...group.services.sort((a, b) => a.text.localeCompare(b.text)))
            }
            return acc
        }, [] as selectOption[])
    }


    const serviceOptions = computed(() => {
        let thisNode = (<any[]>(allNodes.value ? allNodes.value : props.properties.nodes)).find((node: any) => {
            return node.utmId == destinationUTM.value
        })

        return getGroupedServices(thisNode?.nkView?.services)
    })

    const sourceUTM = ref("")
    const sourceNode = ref("")
    const destinationUTM = ref("")
    const destinationNode = ref("")
    const service = ref("")

    onMounted(async () => {

        if(props.properties.sourceUTM) {
            sourceUTM.value = props.properties.sourceUTM
        }
        else {
            sourceUTM.value = props.properties.satelliteUtmId    
        }
        if (props.properties.destinationUTM) {
            destinationUTM.value = props.properties.destinationUTM
        }
        else {
            destinationUTM.value = props.properties.coreUtmId
        }

        if(props.properties.sourceNode) {
            sourceNode.value = props.properties.sourceNode
        }
        
        if(props.properties.destinationNode) {
            destinationNode.value = props.properties.destinationNode
        }
        if(props.properties.service) {
            service.value = props.properties.service
        }

        if (modalState.value) {
            if (modalState.value?.sourceUTM) sourceUTM.value = modalState.value.sourceUTM;
            if (modalState.value?.destinationUTM) destinationUTM.value = modalState.value.destinationUTM;
            if (modalState.value?.sourceNode) sourceNode.value = modalState.value.sourceNode;
            if (modalState.value?.destinationNode) destinationNode.value = modalState.value.destinationNode;
            if (modalState.value?.service) service.value = modalState.value.service;
            useModalStateStore().removeStateFromStore('addEditRule')
        }
        try {
            let allUtms = await requestHandler.request("GET", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(activeAccountId.value) + "/sun/topologies/" + props.properties.selectedTopologyId + "/nodes/utms")
            allNodes.value = allUtms
        }
        catch(e: unknown) {
            console.error(e)
        }

        checkReadyState()
        initialized.value = true
    })


    watch(sourceUTM, checkReadyState)
    watch(sourceNode, checkReadyState)
    watch(destinationUTM, checkReadyState)
    watch(destinationNode, checkReadyState)
    watch(service, checkReadyState)

    // EXPOSE SELECTED VALUES
    defineExpose({
        sourceUTM,
        sourceNode,
        destinationUTM,
        destinationNode,
        service
    })

</script>
<template>
    <div class="content-2">
        <template v-if="initialized">
            <h5>{{ T('Direction') }}</h5>
            <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom border-top">
                <br>
                <div class="input col-xs-24 col-lg-10 padding-xs-y text-center">
                    <i v-if="typeof utmNameMap[sourceUTM] == 'object'" :class="utmNameMap[sourceUTM].icon"></i>
                    {{ utmNameMap[sourceUTM]?.utmName || "" }}
                </div>
                <div class="input col-xs-24 col-lg-2 padding-xs-y text-center">
                    <i class="fal fa-arrow-right"></i>
                </div>
                <div class="input col-xs-24 col-lg-10 padding-xs-y text-center">
                    <i v-if="typeof utmNameMap[destinationUTM] == 'object'"
                        :class="utmNameMap[destinationUTM].icon"></i>
                    {{ utmNameMap[destinationUTM]?.utmName || "" }}
                </div>
                <div class="input col-xs-24 col-lg-2">
                    <buttonComponent :button-options="{
                        'icon':'fal fa-arrow-right-arrow-left',
                        'title':T('Switch source and destination'),
                        'onClick':() => {
                            if(destinationUTM == props.properties.coreUtmId) {
                                destinationUTM = props.properties.satelliteUtmId 
                                sourceUTM = props.properties.coreUtmId
                            }
                            else {
                                sourceUTM = props.properties.satelliteUtmId
                                destinationUTM = props.properties.coreUtmId
                            }

                            sourceNode = '';
                            destinationNode = '';
                            service = '';

                        }
                    }"></buttonComponent>
                </div>
            </div>
            <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-4">
                    <label class="control-label inputname" for="sourceNodeSelect">
                        {{ T('Source') }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13">
                    <input-vue-select id="sourceNodeSelect" v-model="sourceNode" :disabled="sourceUTM ? null : true"
                        :select-options="sourceNodeOptions" :placeholder="T('Please select a network object')"
                        append-to-body></input-vue-select>
                </div>
                <div class="col-lg-7 col-xl-4">
                    <buttonComponent :button-options="{
                        'icon': 'fal fa-plus',
                        'id': 'addServiceButton',
                        'text': '',
                        'title': T('Add Network Object'),
                        'onClick': () => { renderAddNetworkObjectDialog('source') },
                        'size': 'sm'
                    }"></buttonComponent>
                </div>
            </div>

            <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-4">
                    <label class="control-label inputname" for="destinationNodeSelect">
                        {{ T('Destination') }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13">
                    <input-vue-select id="destinationNodeSelect" v-model="destinationNode"
                        :disabled="destinationUTM ? null : true" :select-options="destinationNodeOptions"
                        :placeholder="T('Please select a network object')" append-to-body></input-vue-select>
                </div>
                <div class="col-lg-7 col-xl-4">
                    <buttonComponent :button-options="{
                        'icon': 'fal fa-plus',
                        'id': 'addServiceButton',
                        'text': '',
                        'title': T('Add Network Object'),
                        'onClick': () => { renderAddNetworkObjectDialog('destination') },
                        'size': 'sm'
                    }"></buttonComponent>
                </div>
            </div>
            <div class="row padding-xs-y form-group border-bottom border-top">
                <div class="first col-xs-24 col-lg-6 col-xl-4">
                    <label class="control-label inputname" for="serviceSelect">
                        {{ T('Service') }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13">
                    <input-vue-select id="serviceSelect" v-model="service" :disabled="destinationNode ? null : true"
                        :select-options="serviceOptions" :placeholder="T('Select a service')"
                        append-to-body></input-vue-select>
                </div>
                <div class="col-lg-7 col-xl-4">
                    <buttonComponent :button-options="{
                        'icon': 'fal fa-plus',
                        'id': 'addServiceButton',
                        'text': '',
                        'title': T('Add Service'),
                        'onClick': renderAddServiceDialog,
                        'size': 'sm'
                    }">
                    </buttonComponent>
                </div>
            </div>

        </template>
        <template v-else>

            <div class="text-center padding-xs-y-8 col-xs">
                <div class="text-size-3">
                    <loader class="text-size-2 color-red" />
                </div>
            </div>

        </template>
    </div>
</template>