<script setup lang="ts">
    import arrayHelpers from "../../helpers/helpers.arrays";
    import { ActionTypes } from "@/store/vuex.store";
    import loaderComponent from "../components/loader.vue";
    import { useStore } from '@/store/vuex.store'
    import tenantHelpers from "@/helpers/helpers.tenants";
    import inputVueSelect from "../inputtypes/input-vue-select.vue";
    import products from "@/classes/objectTypes";
    import config from "@/classes/config";
    import deviceHelpers from "@/helpers/helpers.devices";
    import { computed, onMounted, ref } from "vue";
    import getterHelpers from "@/helpers/helpers.getters";
    import type { User } from "@/classes/unifiedSecurity/users";
    import { T } from "@/classes/i18n";

    const initialized = ref(false)
    const loaderText = ref("Loading...")
    const selectedDevices = ref([])
    const selectedAppleIds = ref([])
    const selectedAssets = ref([])

    const activeAccountId = computed(() => {
        return getterHelpers.useStore().getters.getActiveAccountId
    })
    const canUseAppleDEP = computed(() => { return tenantHelpers.hasFunctionality(activeAccountId.value, 'appleDEP') })
    const canUseAppleVPP = computed(() => { return tenantHelpers.hasFunctionality(activeAccountId.value, 'appleVPP') })
    const devices = computed(() => {
        return config.canUseNewObjectType("iosDevices") ? products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObjects(activeAccountId.value) : getterHelpers.useStore().getters.getObjects({
            "accountId":activeAccountId.value,
            "productType":"mobileSecurity",
            "objectType":"devices",
        })
    })
    const deviceOptions = computed(() => {
        return arrayHelpers.sortByObjProperty((devices.value || [])
            .filter((device: any) => { return device.deviceType == "IOS" && device.depDevice && ((device.info.serial || "").length ||(device.serialNumber || device.info.serial || device.info.SerialNumber || device.serial_number || "").length) })
            .map((device: any) => {
                return {
                    "id": device.serialNumber || device.info.serial || device.info.SerialNumber || device.serial_number,
                    "text": deviceHelpers.getAliasedShortDeviceId(device.deviceId, device.alias, false)
                }
            }), "text", "ASC")
    })
    const vppassets = computed(() => {
        return config.canUseNewObjectType("vppassets") ? products.mobileSecurity.vppassets.useStore?.().getObjectStoreObjects(activeAccountId.value) : getterHelpers.useStore().getters.getObjects({
            "accountId": activeAccountId.value,
            "productType": "mobileSecurity",
            "objectType": "vppassets",
        })
    })
    const vppassetOptions = computed(() => {
        return arrayHelpers.sortByObjProperty((vppassets.value || [])
            .map((vppasset: any) => {
                return {
                    "id": vppasset.adamId || vppasset.adamIdStr,
                    "text": vppasset.assetInfo?.trackName + " (" + vppasset.assignedCount + "/" + vppasset.availableCount + ")"
                }
            }), "text", "ASC")

    })
    const users =  computed(() => {
        return config.canUseNewObjectType("users") ? products.unifiedSecurity.users.useStore?.().getObjectStoreObjects(activeAccountId.value) : getterHelpers.useStore().getters.getObjects({
            "accountId": activeAccountId.value,
            "productType": "unifiedSecurity",
            "objectType": "users",
        })
    })
    const appleIdOptions = computed(() => {
        return arrayHelpers.sortByObjProperty((<User[]>users.value || [])
            .filter((user: User) => {
                return user.profile?.clientUserIdStr != undefined && user.profile.appleId != undefined && user.profile.appleId.length > 0
            })
            .map((user: User) => {
                return {
                    "id": user.profile?.clientUserIdStr,
                    "text": (user.username + ' (' + user.profile?.appleId + ')')
                }
            }), "text", "ASC")
    })

    onMounted(async () => {
        let requestObjectTypes = []
        if (config.canUseNewObjectType("iosDevices")) {
            await products.mobileSecurity.iosDevices.queries.getObjectsFromApi(activeAccountId.value)
        }
        else {
            requestObjectTypes.push(`devices?props[]=deviceId&props[]=consent&props[]=deviceType&props[]=signedIn&props[]=profile&props[]=tags&props[]=ownership&props[]=lastContact&props[]=managedAppleId&props[]=signedIn&props[]=info&props[]=configured&props[]=licenseUUID&props[]=username&props[]=info&props[]=enrolledAt&props[]=alias&props[]=lostMode&props[]=availableUpdates`)
        }

        if (canUseAppleDEP.value) {
            if (!config.canUseNewObjectType("depProfiles")) {
                requestObjectTypes.push('depProfiles')
            }
            else {
                await products.mobileSecurity.depProfiles.queries.getObjectsFromApi(activeAccountId.value)
            }

            if (!config.canUseNewObjectType("iosDevices")) {
                requestObjectTypes.push('depDevices')
            }
        }

        if (canUseAppleVPP.value && !config.canUseNewObjectType("users")) {
            requestObjectTypes.push('users')
        }
        else if (canUseAppleVPP.value && config.canUseNewObjectType("users")) {
            await products.unifiedSecurity.users.queries.getObjectsFromApi(activeAccountId.value)
        }

        await useStore().dispatch(ActionTypes.getObjectInfos, {
            "accountId": activeAccountId.value,
            "objectTypes": requestObjectTypes
        })
        initialized.value = true
    })


    defineExpose({
        selectedDevices,
        selectedAppleIds,
        selectedAssets
    })

</script>
<template>
    <div>
        <template v-if="initialized">
            <div class="padding-xs">
                <div class="row flexrow" style="align-items: stretch; flex-wrap: wrap; justify-content: center;">
                    <div class="col-xs-24 col-md-10 content-2 border">
                        <h4>{{ T('Devices') }}</h4>
                        <input-vue-select
                            :selectOptions="deviceOptions"
                            :multiple="true"
                            v-model="selectedDevices"
                            :placeholder="T('Select devices')"
                        />
                    
                        <h4>{{ T('Managed Apple IDs') }}</h4>
                        <input-vue-select
                            :selectOptions="appleIdOptions"
                            :multiple="true"
                            v-model="selectedAppleIds"
                            :placeholder="T('Select Apple IDs')"
                        />
                    </div>
                    <div class="col-xs-24 col-md-4 text-center text-size-2" style="align-self:center">
                        <i class="fal fa-angle-right"></i>
                    </div>
                    <div class="col-xs-24 col-md-10 content-2 border" style="align-content: center; display: flex; flex-flow: inherit;">
                        <h4>{{ T('VPP Licenses') }}</h4>
                        <input-vue-select
                            :selectOptions="vppassetOptions"
                            :multiple="true"
                            v-model="selectedAssets"
                            :placeholder="T('Select licenses')"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template v-if="!initialized">
            <div>
                <div class="text-size-3 text-center padding-xs-t-4">
                    <loaderComponent class="color-red"></loaderComponent>
                </div>
                <template v-if="loaderText">
                    <div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity:0.8">
                    <span>{{ T(loaderText) }}</span>
                    </div>
                </template>
                <template v-else>
                    <div class="padding-xs-b-4"></div>
                </template>
            </div>
        </template>
    </div>
</template>