import unifiedSecurity from "../queries/unified.security"
import tenantHelpers from "../helpers/helpers.tenants"
import products, { prepareObjectTypeStores } from "../classes/objectTypes/index"
import type { UserSession } from "../classes/storeSession"
import { MutationTypes, ActionTypes, useStore } from "../store/vuex.store"
import config from "@/classes/config"


const accounts = {
    getAccounts: async (session: UserSession) => {
        let vuexStore = useStore()
        await prepareObjectTypeStores(session)
        let availableAccountIds: string[] = []
        let tenants: any
        if (session?.scopes?.list_accounts) {
            try {
                tenants = await unifiedSecurity.getTenants()
            }
            catch (e: any) {
                tenants = {
                    tenants: []
                }
            }
            availableAccountIds = tenants.tenants.map((tenant: any) => {
                return tenantHelpers.getAccountId(tenant.tenantDomain)
            })
            session.scopes.list_accounts = session.scopes.list_accounts.filter((account: any) => {
                if ((availableAccountIds.indexOf(String(account.accountid)) != -1)) {
                    return true
                }
            })
        }
        vuexStore.commit(MutationTypes.setAccounts, { session:session, tenants:tenants?.tenants})
        
        if (config.canUseNewObjectType("licenses")) {
            await products.unifiedSecurity.licenses.queries.getObjectsFromApi(session.targetDomain || session.domain, undefined, [
                { "property": "with_subtenants", "value": "true" }
            ])
        }
        else {
            await vuexStore.dispatch(ActionTypes.getObjectInfos, {
                'accountId': tenantHelpers.getAccountId(session.targetDomain || session.domain),
                'objectTypes': ['licenses?with_subtenants=true']
            })
        }
        
        
        vuexStore.state.session.initialized = true
    }
}
export default accounts