import { T } from "@/classes/i18n";
import { useGlobalMixin } from "@/mixins/mixins.global";

const inputImage64Component = {
    "name":"input-image64",
    "template": `
        <label style="margin: 0px; white-space: nowrap; display: inline-block;" class="file">
            <div :class="{'hidden':modelValue != ''}">
                <input
                    class="noBehaviour"
                    style="display:none"
                    type="file"
                    v-on:change="onInputChange($event)"
                >
                <div class="uploadbox"></div>
                <input type="hidden" :name="name || undefined" v-model="modelValue"/>
            </div>
            <div>
                <a
                    v-if="modelValue == ''"
                    class="btn btn-primary"
                >
                    <i class="fal fa-fw fa-upload"></i> {{ T('Select image') }}
                </a>
                <template v-if="error">
                    <br>
                    <span class="label bg-red">
                        {{ T(error) }}
                    </span>
                </template>
            </div>
            <div
                :class="{'hidden':modelValue == ''}"
            >
                <div
                    :title="T('Change image')"
                    style="display:inline-block;cursor: pointer; width: 300px; border: 1px solid rgba(0,0,0,0.1); height: 150px; background-size: auto; background-color:#333; vertical-align: top; background-repeat: no-repeat; background-position: center; position:relative"
                    :style="'background-image:url('+modelValue+')'"
                ></div>
                <a
                    v-if="modelValue != ''"
                    class="btn btn-sm btn-primary"
                    v-on:click.prevent.stop="deleteImage()"
                    :title="T('Delete image')"
                    style="position: relative; margin-left: -37px; margin-top: 5px;"
                >
                    <i class="fal fa-fw fa-trash"></i>
                </a>
            </div>
        </label>
    `,
    mixins: [useGlobalMixin()],
    "props": {
        "modelValue": "",
        "name":"",
        "allowedTypes":{
            type:Array,
            default:['image/png','image/jpg','image/jpeg'],
            required:false
        }
    },
    data() {
        return {
            "error":""
        }
    },
    "methods":{
        "deleteImage":function(this:any) {
            $(this.$el).find('input').val("")
            this.onInputChange()
        },
        "setValue":function(this:any,value:any) {
            this.$emit('update:modelValue', value)
        },
        "onInputChange":async function(this:any,e?:any) {
            if(this.$parent.field) {
                this.$parent.field.errors = []
            }
            this.error = ""
            let target = e ? e.target : $(this.$el).find('input')[0]
            let file : any = target.files ? target.files[0] : ""
            if(file) {
                if(this.allowedTypes.indexOf(file.type) == -1) {
                    if(this.$parent.field) {
                        this.$parent.field.errors.push(T("Wrong filetype")+": " + file.type)
                    } 
                    else {
                        this.error = T("Wrong filetype")+": " + file.type
                    }
                    file = ""
                }
                this.setValue(file ? await this.toBase64(file) : "")
            }
            else {
                this.setValue("")
            }
        },
        "toBase64": function(file:any) {
            return new Promise((resolve:any, reject:any) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
    },
    "mounted":function(this:any) {
    }
}
export default inputImage64Component
