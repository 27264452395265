<script setup lang="ts">
import { T } from "@/classes/i18n"
import { useStore } from "../../store/vuex.store"
import { computed, onMounted, reactive, ref, watch } from "vue";
import mixinHelpers from "@/helpers/helpers.mixins";
import { sprintf } from "sprintf-js";
import config from "@/classes/config";
import products from "@/classes/objectTypes";
import type ObjectType from "@/classes/objectTypes/objectType";

const props = defineProps<{
    properties: {
        items: any[]
    }
}>();

// Reactive Values
const loader = reactive({
    "loading": true,
    "loaderText": ""
})

// Ref Values
const hasManagedConfigurationTemplates = ref(false)
const takenProfileNames = ref(<string[]>[])

// Computed Values
const activePage = computed(() => {
    return mixinHelpers.getActivePage() || ''
})
const activeAccountId = computed(() => {
    return useStore()?.state.session.activeAccountId || ''
})
const nameProperty = computed(() => {
    switch (activePage.value) {
        case 'vpns':
            return "vpnname"
        break;
        case 'iosProfiles':
            return 'profilename'
        break;
        case 'androidProfiles':
            if (config.canUseNewObjectType("androidProfiles")) {
                return 'name'
            }
            else {
                return 'profilename'
            }   
        case 'iosApps':
        case 'apps':
            return 'name'
        default:
            return 'name'
    }
})

const iconClass = computed(() => {
    switch (activePage.value) {
        case 'vpns':
            return "fal fa-laptop"
        case 'iosProfiles':
        case 'androidProfiles':
            return 'fal fa-shield-alt fa-fw'
        case 'apps':
            return 'fal fa-rocket'
        case 'iosApps':
            return 'fal fa-grid-2-plus'
        default:
            return 'fal fa-chess'
    }
})
const objectText = computed(() => {
    let text: string = T("The following %s have to be unique:")
    switch (activePage.value) {
        case 'vpns':
            text = sprintf(text, T('vpn names'))
            break;
        case 'iosProfiles':
        case 'androidProfiles':
            text = sprintf(text, T("profilenames"))

            break;

        case 'apps':
        case 'iosApps':
            text = sprintf(text, T("appnames"))
            break;

        default:

    }
    return text
})

const isAnyNameTaken = computed(() => {
    if (nameProperty.value) {
        return props.properties.items.some((item: any) => {
            return isNameTaken(item[nameProperty.value as keyof any])
        })
    }
})
const isAnyNameEmpty = computed(() => {
    if(nameProperty.value) {
        return props.properties.items.some((item: any) => {
            return isNameEmpty(item[nameProperty.value])
        })
    }
})
const isAnyNameTooShort = computed(() => {
    if(nameProperty.value) {
        return props.properties.items.some((item: any) => {
            return isNameTooShort(item[nameProperty.value])
        })
    }
})
const isAnyNameTooLong = computed(() => {
    if(nameProperty.value) {
        return props.properties.items.some((item: any) => {
            return isNameTooLong(item[nameProperty.value])
        })
    }
})
const isAnyNameInvalid = computed(() => {
    if(nameProperty.value) {
        return props.properties.items.some((item: any) => {
            return isNameInvalid(item[nameProperty.value])
        })
    }
})

const areDuplicateNewNames = computed(() => {
    let result = false
    if (nameProperty.value && activePage.value == "androidProfiles") {
        let allNameSet = new Set();
        for (const item of props.properties.items) {
            if (allNameSet.has(item[nameProperty.value])) {
                result = true
            }
            allNameSet.add(item[nameProperty.value])
        }
    }
    return result
})

const minLength = computed(() => {
    const lengthMap: { [key: string]: number } = {
        "androidProfiles": 0,
        "iosProfiles": 3,
        "vpns": 3,
        "iosApps": 0,
        "apps": 0
    }
    return lengthMap[activePage.value] || 0
})
const maxLength = computed(() => {
    const lengthMap: { [key: string]: number } = {
        "androidProfiles": 128,
        "iosProfiles": 120,
        "vpns": 120,
        "iosApps": Infinity,
        "apps": Infinity
    }
    return lengthMap[activePage.value] || 0
})

function isNameTaken(name: string | undefined) {
    return typeof name === 'string' ? takenProfileNames.value.indexOf(name) != -1 || takenProfileNames.value.indexOf(name.trim()) != -1 : true
}
function isNameEmpty(name: string | undefined) {
    return typeof name === "string" ? name.trim() == "" : true
}
function isNameTooShort(name: string | undefined) {
    return typeof name === "string" ? name.trim().length < minLength.value : true
}
function isNameTooLong(name: string | undefined) {
    return name ? name.trim().length > maxLength.value : true
}
function isNameInvalid(name: string | undefined) {
    if(activePage.value == "androidProfiles" || activePage.value == "iosApps" || activePage.value == "apps") return false;
    else return name ? !(/^[ 0-9A-Za-zäÄöÖüÜß\\(\\)\\._-]{0,120}$/.test(name)) : false

}



function checkData() {
    let thisHasManagedConfigurationTemplates: boolean = false
    props.properties.items.forEach(function (profile: any) {
        (profile.policy?.applications || []).forEach(function (application: any) {
            if (!thisHasManagedConfigurationTemplates && application.managedConfigurationTemplate != undefined) {
                thisHasManagedConfigurationTemplates = true
            }
        })
    })
    hasManagedConfigurationTemplates.value = thisHasManagedConfigurationTemplates
}

onMounted(async () => {
    loader.loading = true
    loader.loaderText = "Checking for managed configuration templates..."
    checkData()

    let activeItems: any[] | ObjectType<any>[] | undefined = undefined
    switch (activePage.value) {
        case 'androidProfiles':
            if (config.canUseNewObjectType("androidProfiles")) {
                activeItems = (products.mobileSecurity.androidProfiles.useStore?.().getObjectStoreObjects(activeAccountId.value) || []) as ObjectType<any>[]
            }
            else {
                activeItems = useStore().getters.getObjectTypeStore({
                    "accountId": activeAccountId.value,
                    "objectType": "androidProfiles"
                })?.items || []
            }
            break;
        case 'apps':
        case 'iosApps':
            if (config.canUseNewObjectType("iosApps")) {
                activeItems = products.mobileSecurity.iosApps.useStore?.().getObjectStoreObjects(activeAccountId.value) || []
            }
            else {
                activeItems = useStore().getters.getObjectTypeStore({
                    "accountId": activeAccountId.value,
                    "objectType": "apps"
                })?.items || []
            }
            break;
        case 'vpns':
            if (config.canUseNewObjectType("vpns")) {
                activeItems = products.mobileSecurity.vpns.useStore?.().getObjectStoreObjects(activeAccountId.value) || []
            }
            else {
                activeItems = useStore().getters.getObjectTypeStore({
                    "accountId": activeAccountId.value,
                    "objectType": "vpns"
                })?.items || []
            }
            break;
        case 'iosProfiles':
            if (config.canUseNewObjectType('iosProfiles')) {
                activeItems = products.mobileSecurity.iosProfiles.useStore?.().getObjectStoreObjects(activeAccountId.value) || []
            }
            else {
                activeItems = useStore().getters.getObjectTypeStore({
                    "accountId": activeAccountId.value,
                    "objectType": "iosProfiles"
                })?.items || []
            }
            break;
    }

    takenProfileNames.value = activeItems?.map((item: any) => {
        if (config.canUseNewObjectType("androidProfiles") && activePage.value == "androidProfiles") {
            return item[nameProperty.value as keyof any].split("/")[3]
        }
        return item[nameProperty.value as keyof any]
    }) || []

    loader.loading = false
})


// Watches
watch(props.properties.items, () => {
    checkData()
}, {
    "deep": true
})

watch([isAnyNameTaken, isAnyNameEmpty, areDuplicateNewNames, isAnyNameTooShort, loader, isAnyNameInvalid, isAnyNameTooLong], () => {
    if (isAnyNameTaken.value || isAnyNameEmpty.value || areDuplicateNewNames.value || isAnyNameTooShort.value || loader.loading == true || isAnyNameInvalid.value || isAnyNameTooLong.value) {
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = true
    }
    else {
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = false
    }
})


</script>
<template>
    <div>
        <p class="notification bg-red color-white margin-xs-b-2" v-if="hasManagedConfigurationTemplates">
            {{ T('You have copied a profile with a managed configuration template. Changes in that template will affect all profiles using this template.') }}
        </p>
        <p class="text-bold">
            {{ objectText }}
        </p>
        <div class="box box-shadow padding-xs-x">
            <div v-for="item, index in properties.items" :key="item.oldItemName">
                <div class="row padding-xs-y form-group"
                    :class="{ 'border-bottom': ((index + 1) < properties.items.length) }">
                    <div class="first col-xs-24 col-lg-12">
                        <label class="control-label inputname" :for="'input' + item.oldItemName + '-' + index">
                            <i :class="iconClass" style="opacity:0.6"></i> {{ item.oldItemName }}
                        </label>
                    </div>
                    <div class="col-xs-24 col-lg-12">
                        <label class="form-field margin-xs-b-0">
                            <input type="text" v-model="item[nameProperty as keyof any]">
                        </label>
                    </div>
                </div>
                <template v-if="isNameTaken(item[nameProperty as keyof any])">
                    <div class="alert notification content bg-red">
                        <i class="fal fa-fw fa-exclamation-triangle"></i> {{ T("The name is already taken") }}
                    </div>
                </template>
                <template v-else-if="isNameEmpty(item[nameProperty as keyof any])">
                    <div class="alert notification content bg-red">
                        <i class="fal fa-fw fa-exclamation-triangle"></i> {{ T("The name is empty") }}
                    </div>
                </template>
                <template v-else-if="item[nameProperty as keyof any] != '' && isNameTooShort(item[nameProperty as keyof any])">
                    <div class="alert notification content bg-red">
                        <i class="fal fa-fw fa-exclamation-triangle"></i> {{ T("The name is too short. The minumum length is %s").replace("%s", minLength) }}
                    </div>
                </template>
                <template v-else-if="item[nameProperty as keyof any] != '' && isNameTooLong(item[nameProperty as keyof any])">
                    <div class="alert notification content bg-red">
                        <i class="fal fa-fw fa-exclamation-triangle"></i> {{ T("The name is too long. The maximum length is %s").replace("%s", maxLength) }}
                    </div>
                </template>
                <template v-else-if="item[nameProperty as keyof any] != '' && isNameInvalid(item[nameProperty as keyof any])">
                    <div class="alert notification content bg-red">
                        <i class="fal fa-fw fa-exclamation-triangle"></i> {{ T("The name may comprise alphanumeric characters, spaces, periods, hyphens, underscores, parentheses, and German umlauts (ä, Ä, ö, Ö, ü, Ü, ß).") }}
                    </div>
                </template>
                <template v-if="item[nameProperty as keyof any]?.endsWith(' ')">
                    <div class="alert notification content bg-blue">
                        <i class="fal fa-fw fa-info-circle"></i> {{ T("Trailing spaces will be removed") }}
                    </div>
                </template>
            </div>
        </div>
        <template v-if="areDuplicateNewNames">
            <div class="alert notification content bg-red">
                <i class="fal fa-fw fa-exclamation-triangle"></i> {{ T("Some of the names entered are identical") }}
            </div>
        </template>
    </div>
</template>
