import i18n, { T } from "@/classes/i18n";
import { useGlobalMixin } from "@/mixins/mixins.global";
import queries from "@/queries/queries";
import { ActionTypes } from "@/store/vuex.store";
import $ from "jquery"
import loaderComponent from "../components/loader.vue";
import { useStore } from '@/store/vuex.store'
import timeHelpers from "@/helpers/helpers.time";
import validationHelpers from "@/helpers/helpers.validation";
import Dropzone from "dropzone"
import config from "@/classes/config";
const applePushCertDialogComponent = {
    mixins: [useGlobalMixin()],
    name:"apple-push-cert-dialog",
    "template": `
        <div>
            <div class="padding-xs">
                <h5 class="margin-xs-y-2">{{ properties.update ? T('Follow these steps to renew your Apple Push Certificate') : T('Follow these steps to generate your Apple Push Certificate and upload it')}}:</h5>
                <ol style="line-height:2.25em">
                    <li class="margin-xs-y">
                        ${T("Download your certificate signing request file (.csr):")}
                        <label style="display:inline-block; width:200px; min-width:0px; max-width:100%;" class="form-inputgroup">
                            <a
                                id="downloadCsr"
                                target="_blank"
                                rel="noreferrer noopener"
                                v-if="!csrLoading && !csrDownloaded"
                                v-on:click="downloadCsr()"
                                class="btn bet-primary"
                            >
                                <i class="fal fa-fw fa-download"></i>&nbsp;&nbsp;${T('Download file')}
                            </a>
                            <loader v-if="csrLoading" class="color-primary text-size-2"/>
                            <i v-if="!csrLoading && csrDownloaded" class="fal fa-fw fa-check color-green"></i>
                        </label>
                        <span class="bg-red label error padding-xs display-block" style="max-width: 400px;" v-if="csrError">
                            <i class="fal fa-exclamation-triangle"></i>&nbsp;&nbsp;{{ csrError }}
                        </span>
                    </li>
                    <hr>
                    <li class="margin-xs-y">
                        ${T("Upload your .csr file to the")}
                        <a target="_blank" rel="noreferrer noopener" href="https://identity.apple.com/pushcert">
                            ${T('Apple Push Certificate Portal')}
                        </a>
                        <template v-if="properties.update">
                            {{ T('in order to download your renewed Apple Push Certificate') }}
                        </template>
                        <template v-else>
                            {{ T('in order to download your Apple Push Certificate') }}
                        </template>
                        
                        <template v-if="properties.update && tenantApns.history && tenantApns.history.length">
                            <br>
                            <strong>{{ T('Here is a list of your last uploads.') }}</strong>
                            <strong>{{ T('Please renew the latest of the following Apple Push Certificates with the following subject lines:') }}</strong>
                            <br>
                            <table>
                                <template v-for="historyApn in tenantApns.history">
                                    <tr :key="historyApn.serial">
                                        <th style="width: 142px;">
                                            {{ getDateWithTime(historyApn.uploadDate) }}
                                        </th>
                                        <td>
                                            {{ historyApn.subjectDN }}<br>
                                            {{ T('Expire time') }}: {{ getDateWithTime(historyApn.notAfter) }}
                                        </td>
                                    </tr>
                                </template>
                            </table>
                        </template>
                        <template v-else-if="properties.update && tenantApns?.subjectDN != undefined">
                            <br>
                            <strong>{{ T('Please locate the Apple Push Certificate with the following subject line:') }}</strong>
                            <br>
                            <i>{{ tenantApns.subjectDN }}</i>
                        </template>


                    </li>

                    <hr>
                    <li class="margin-xs-y">
                        ${T("Enter the Apple ID that you used to generate the certificate:")}&nbsp;&nbsp;
                        <label
                            style="display:inline-block; width:250px; min-width:0px; max-width:100%;"
                            class="form-inputgroup"
                            :class="{'error': emailError != undefined}"
                        >
                            <input
                                v-model="emailEntered"
                                type="text"
                                id="apns-apple-id"
                                placeholder="john@doe.com"
                                v-on:change="onEmailChange()"
                            >

                            <span v-if="emailEntered != '' && emailValid" class="form-icon-postfix color-lightgreen">
                                <i class="fal fa-check"></i>
                            </span>
                            <span v-if="emailEntered != '' && !emailValid" class="form-icon-postfix color-red">
                                <i class="fal fa-times"></i>
                            </span>
                            <span class="bg-red label error padding-xs display-block" style="max-width: 400px;" v-if="emailError">
                                <i class="fal fa-exclamation-triangle"></i>&nbsp;&nbsp;{{ emailError }}
                            </span>
                        </label>
                    </li>
                    <hr>
                    <li class="margin-xs-y">
                        ${T("Upload your push certificate (MDM_Securepoint GmbH_Certificate.pem):")}&nbsp;&nbsp;&nbsp;
                        <label style="display:inline-block; width:160px; min-width:0px; max-width:100%;" class="file form-inputgroup">
                            <a v-if="!certLoading && !certUploaded" id="certChooser" class="btn btn-primary">
                                <i class="fal fa-fw fa-upload"></i>&nbsp;&nbsp;{{T('Upload Certificate')}}
                            </a>
                            <loader v-if="certLoading" class="color-primary text-size-2"/>
                            <i v-if="!certLoading && certUploaded" class="fal fa-fw fa-check color-green"></i>
                        </label>

                        <span class="bg-red label error padding-xs display-block" style="max-width: 400px;" v-if="certError">
                            <i class="fal fa-exclamation-triangle"></i>&nbsp;&nbsp;{{ certError }}
                        </span>
                        <div id="theDropZone" class="dropzoneArea" v-if="!certUploaded">
                            <div class="uploadcontent dz-default dz-message">
                                <template v-if="!certLoading">
                                    <i class="fal fa-upload"></i> ${T('Drop File (*.pem) here')}
                                </template>
                                <loader v-if="certLoading" class="color-primary text-size-2"/>
                            </div>
                        </div>
                    </li>
                </ol>               
            </div>
        </div>
    `,
    "data":() => {
        return {
            "initialized":true,

            "csrDownloaded":false,
            "csrLoading":false,
            "csrError":undefined,

            "emailEntered":"",
            "emailValid":true,
            "emailError":undefined,

            "certUploaded":false,
            "certLoading":false,
            "certError":undefined
        }
    },
    "computed": {
        "iosData":function(this:any) {
            return useStore().state.session.accounts[this.properties.accountId].mobileSecurity.settings.ios
        },
        "tenantApns": function(this:any) {
            return useStore().state.session.accounts[this.properties.accountId].mobileSecurity.settings.ios.apns
        }
    },
    "props": {
        "properties":{
            required:true,
            default:() => {
                return {
                    "accountId":undefined,
                    "update":false
                }
            }
        }
    },
    "methods": {
        "submit":function(this:any) {
            if(this.emailValid && this.certUploaded) {
                useStore().getters.getActiveModal(this.activeAccountId).buttons[1].onClick()
            }
        },
        "getDateWithTime": function (this: any, date: any) {
            return timeHelpers.formatDate(date, timeHelpers.getDateFormatI18n(i18n.getLanguage(), true))
        },
        "onEmailChange":async function(this:any) {
            if(validationHelpers.isEmail(this.emailEntered)) {
                this.emailValid = true
                if(this.initialized) {
                    try {
                        await useStore().dispatch(ActionTypes.setTenantProperty,{
                            'accountId':this.properties.accountId,
                            'property':'appleId',
                            'value':this.emailEntered
                        })
                    }
                    catch(e:any) {
                        console.error(e)
                        this.emailError = T("Something went wrong. Please try again.")
                    }
                }
            }
            else {
                this.emailValid = false
            }
        },
        "downloadCsr":async function(this:any) {
            this.csrLoading = true
            try {
                await queries.mobileSecurity.downloadCsr(this.properties.accountId)
                this.csrDownloaded = true
            }
            catch(e:any) {
                console.error(e)
            }
            this.csrLoading = false
        },


    },
    "created":function(this:any) {
        this.emailEntered = this.iosData.appleId
    },
    "mounted": function(this:any) {
        let thisComponent : any = this

        Dropzone.options.theDropZone = {
            "clickable": '#certChooser',
            "uploadMultiple": false,
            "paramName": "file",
            "accept": function (file: any, done: Function) {
                thisComponent.certError = undefined
                if (!/pem$/.test(file.name)) {
                    done(T("Please provide the correct filetype: *.pem"))
                    thisComponent.certError = T("Please provide the correct filetype: *.pem")
                }
                else {
                    done()
                }
            },
            "previewTemplate": `
            <div class="dz-preview dz-file-preview"></div>`,
        }
        var myDropzone = new Dropzone('#theDropZone', { url: '/sms-mgt-api/api/'+config.mgtApiVersionNext+'/tenants/'+thisComponent.properties.accountId+'.sms/apple/apns/cert' })
        let $buttonTemplate = $('<a class="btn btn-primary">'+T("back")+'</a>')
        myDropzone.on("complete",function(event:any) {
            if(event.status == 'success') {
                thisComponent.certLoading = false
                thisComponent.certUploaded = true
            }
            else {
                thisComponent.certLoading = false
                thisComponent.certUploaded = false
                thisComponent.certError = T("Something went wrong. Please try again.")
            }
        })

        this.initialized = true

    },
    "watch": {

    },
    "components": {
        "loader":loaderComponent
    },
}
export default applePushCertDialogComponent
