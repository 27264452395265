<script setup lang=ts>
import { debounce } from 'throttle-debounce';
import { ref, computed } from 'vue';
const props = defineProps<{
    modelValue: number | string,
    min?: number | string,
    max?: number | string,
    format?: string | number,
    debounce?: number,
    beforeFilterInput?: Function,
    afterFilterInput?: Function

}>();
const emit = defineEmits(['update:modelValue'])
const inputElement = ref(null)
const value = computed({
    get: () => props.modelValue,
    set: (value) => { 
        if(props.beforeFilterInput) props.beforeFilterInput();
        filterInput(value) 
    }
})
const filterInput = debounce(props.debounce ? props.debounce : 0, (value: number | string) => {
    let newValue = value
    if (props.min != undefined && value < props.min) newValue = props.min
    if (props.max != undefined && value > props.max) newValue = props.max
    if (Number.isNaN(value)) newValue = props.min != undefined ? props.min : 0
    if(props.afterFilterInput) props.afterFilterInput();

    emit('update:modelValue', newValue);
    (<any>inputElement.value).value = newValue
})

</script>
<template name="input-number">
    <input type="number" ref="inputElement" style="width:100%" v-model="value">
</template>
