import arrayHelpers from "./helpers.arrays"
import timeHelpers from "./helpers.time"

export interface PromiseObject {
    [property:string]:Promise<any>|any
}
const promiseHelpers = {
    resolvePromiseObject: async function(promiseObject:PromiseObject) {
        let result : any = {}
        let propertyArray : string[] = []
        let promiseArray : Promise<any>[] = []
        for (let property in promiseObject) {
            propertyArray.push(property)
            let thisPromise = promiseObject[property] as Promise<any>
            //@ts-ignore
            promiseArray.push(thisPromise != null ? thisPromise.catch((err) => { return null }) : null as Promise<any>)
        }
        const qResults = await Promise.all(promiseArray)
        propertyArray.forEach((property:string,i:number) => {
            result[property] = qResults[i]
        })
        return result
    },
    resolvePromiseAsChunks: async function(promiseArray:Promise<any>[]|any[],chunkSize:number=10,timeout:number=0) {
        let results: any[] = []
        async function resolvePromiseChunks(chunks: Array<Promise<any>>[], timeout: number, currentIndex: number = 0) {
            let result = await Promise.all(chunks[currentIndex])
            if (chunks[currentIndex + 1] != undefined) {
                await timeHelpers.sleep(timeout)
                result = result.concat(await resolvePromiseChunks(chunks, timeout, currentIndex + 1))
            }
            return result
        }
        if((promiseArray || []).length) {
            const chunks = arrayHelpers.chunkify(promiseArray, chunkSize) as Array<Promise<any>[] | any[]>
            results = await resolvePromiseChunks(chunks, timeout,0)
        }
        return results
    }
}
export default promiseHelpers