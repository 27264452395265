<template>
    <section id="secureDnsLogs">
        <div class="row margin-xs-0" v-if="accountInitialized != undefined">
            <div class="col-xs-24 padding-xs-x-4 padding-xs-t-4">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="padding-xs-t padding-xs-l">
                            <div class="toolbar flexrow">
                                <div class="col-xs-6 padding-xs-0 padding-xs-b padding-xs-r">
                                    <label class="form-field margin-xs-b-0">
                                        <span class="form-icon-prefix"><i class="fal fa-pen"></i></span>
                                        <select v-model="itemsPerPage" class="form-control">
                                            <option value="50">{{ "50 " + T('Entries') }}</option>
                                            <option value="100">{{ "100 " + T('Entries') }}</option>
                                            <option value="500">{{ "500 " + T('Entries') }}</option>
                                            <option value="1000">{{ "1000 " + T('Entries') }}</option>
                                        </select>
                                    </label>
                                </div>

                                <div class="col-xs-6" style="margin-bottom:8px; margin-right:8px;" :title="T('Period')">
                                    <label class="form-field margin-xs-b-0">
                                        <span class="form-icon-prefix"><i class="fal fa-clock"></i></span>
                                        <select v-model="period" class="form-control">
                                            <option value="today">{{ T('Today') }}</option>
                                            <option value="lastday">{{ T('Yesterday') }}</option>
                                            <option value="currentweek">{{ T('Current week') }}</option>
                                            <option value="currentmonth">{{ T('Current month') }}</option>
                                            <option value="last30days">{{ T('Last 30 days') }}</option>
                                        </select>
                                    </label>
                                </div>


                                <div class="col-xs padding-xs-0 padding-xs-b padding-xs-r">
                                    <label class="form-inputgroup margin-xs-b-0">
                                        <span class="form-icon-prefix">
                                            <i class="fal fa-search"></i>
                                        </span>
                                        <input id="itemlistSearch" type="text" v-model="filter"
                                            :placeholder="T('Search')" />
                                    </label>
                                </div>

                                <div class="btn-toolbar" style="margin-bottom:8px; margin-right:8px;">
                                    <div class="btn-group">
                                        <a class="btn margin-btn-b-0 btn-loader twist-in" v-on:click="load(true)"
                                            title="Refresh">
                                            <span class="animate">
                                                <i class="progress-circular no-progress"
                                                    style="font-size: 1.5em;top:2px;">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                                        viewBox="0 0 50 50" style="stroke-width: 4px;">
                                                        <circle cx="25" cy="25" r="20"
                                                            style="stroke: rgba(0, 0, 0, 0.1); fill: none;"></circle>
                                                        <g transform="translate(25,25) rotate(-90)">
                                                            <circle stroke-dasharray="110" stroke-dashoffset="0" cx="0"
                                                                cy="0" r="20" style="fill: none; stroke-linecap: round;"
                                                                transform="rotate(14.0181)">
                                                                <animate attributeName="stroke-dashoffset"
                                                                    values="360;140" dur="2.2s" keyTimes="0;1"
                                                                    calcMode="spline" fill="freeze"
                                                                    keySplines="0.41,0.314,0.8,0.54"
                                                                    repeatCount="indefinite" begin="0"></animate>
                                                                <animate attributeName="stroke" fill="freeze" dur="8s"
                                                                    begin="0" repeatCount="indefinite"></animate>
                                                            </circle>
                                                        </g>
                                                    </svg>
                                                </i>
                                            </span>
                                            <span><i class="fal fa-fw fa-sync"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="!loading">
                            <tableNext :objectList="logEntries" :selectable-columns="tableStructure"
                                :isSearchable="false" :isFilterable="false" :hasOptions="false" :maxHeight="500" />

                            <div class="pagination">
                                <button @click="previousPage" :disabled="currentPage === 1">&laquo; Zurück</button>
                                <span>Seite {{ currentPage }} von {{ totalPages }}</span>
                                <button @click="nextPage" :disabled="currentPage === totalPages">Weiter &raquo;</button>
                            </div>
                        </template>
                        <template v-else>
                            <div style="text-align:center; padding:122px 0;">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { T } from '@/classes/i18n';
import tableNext from "../components/tableNext.vue";
import moment from "moment";
import loaderComponent from "@/templates/components/loader.vue";
import { debounce } from 'throttle-debounce';
import router from '@/router/router';
import requestHandler from '@/queries/requests';
import tenantHelpers from '@/helpers/helpers.tenants';
import secureDns from '@/classes/objectTypes/mobileSecurity/secure-dns/secure-dns';

const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId
})

const accountInitialized = computed(() => {
    return useStore().state.session.accounts[activeAccountId.value || ''].initialized
})

const filter = ref("")
const itemsPerPage = ref(50)
const loading = ref(false)

const tableStructure = ref([
    {
        "property": "domain",
        "text": T("Domain"),
        "sortable": false,
        "displayType": "text",
        "getValue": (item: any) => item.data.domain
    },
    {
        "property": "type",
        "text": T("Typ"),
        "sortable": false,
        "displayType": "labels",
        "getValue": (item: any) => {
            return item.data.types.map((type: string) => {
                return {
                    "text": type
                }
            })
        }
    },
    {
        "property": "client",
        "text": T("Client"),
        "sortable": false,
        "displayType": "labels",
        "getValue": (item: any) => {
            const labels = []

            if (item.data.deviceName) {
                labels.push({
                    "text": item.data.deviceName,
                    "icon": "fa fa-user"
                })
            }

            if (item.data.ip) {
                labels.push({
                    "text": item.data.ip,
                    "icon": "fa-solid fa-network-wired"
                })
            }

            // fallback
            if (labels.length === 0) {
                labels.push({
                    "text": T("No info"),
                    "icon": "fa fa-ban"
                })
            }

            return labels
        }
    },
    {
        "property": "profile",
        "text": T("Profile"),
        "sortable": false,
        "displayType": "labels",
        "getValue": (item: any) => {
            const profileId = item.data.profileId

            return [{
                "text": secDnsIdNameMapping.value[profileId] || "N/A",
                onClick: () => {
                    router.navigate('edit-tenant-' + activeAccountId.value + '.sms-secureDns-' + item.data.profileId)
                }
            }]
        }
    },
    {
        "property": "time",
        "text": T("Time"),
        "sortable": false,
        "displayType": "text",
        "getValue": (item: any) => {
            const momentDate = moment.utc(item.data.windowStart).local()

            return `${moment(momentDate).fromNow()} (${momentDate.format("DD.MM HH:mm")})`
        }
    },
    {
        "property": "status",
        "text": T("Status"),
        "sortable": false,
        "displayType": "status",
        "getValue": (item: any) => {
            const blockReason = item.data.blockReason

            if (blockReason === null) {
                return {
                    "color": "green",
                    "statusText": "OK",
                    "tooltip": "OK"
                }
            } else {
                return {
                    "color": "red",
                    "statusText": `Blocked (${tenantHelpers.secureDnsCategoryIdToName(blockReason)})`,
                    "tooltip": "Blocked"
                }
            }
        }
    },
    {
        "property": "action",
        "text": T("Actions"),
        "sortable": false,
        "displayType": "buttons",
        "width": 110,
        "getValue": (item: any) => {
            const {profileId, domain, blockReason} = item.data

            if (blockReason === null) {
                return [{
                    color: "green",
                    icon: "fal fa-fw fa-ban",
                    text: "Blocklist",
                    onClick: () => handleDomainAction(item.data.domain, profileId, "block")
                }]
            } else {
                return [{
                    color: "green",
                    icon: "fal fa-fw fa-check-circle",
                    text: "Allowlist",
                    onClick: () => handleDomainAction(item.data.domain, profileId, "allow")
                }]
            }
        }
    }
])

const totalEntries = ref(0)
const logEntries = ref<any[]>([])
const secDnsIdNameMapping = ref<{ [id: string]: string }>({})
const period = ref("currentweek")


const currentPage = ref(1)
const totalPages = computed(() => Math.ceil(totalEntries.value / itemsPerPage.value) || 1)
const offset = computed(() => (currentPage.value - 1) * itemsPerPage.value)

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--
    }
}

function nextPage() {
    if (currentPage.value < totalPages.value) {
        currentPage.value++
    }
}

async function load(loadProfiles = false) {
    loading.value = true

    const data = await requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + tenantHelpers.getTenantDomain(activeAccountId.value || "") + "/stats/execute", {
        "query": {
            "modul": "SecureDns",
            "name": "Protocols",
            "options": {
                "size": parseInt(itemsPerPage.value as any as string),
                "offset": offset.value,
                "period": period.value,
                ...(!!filter.value && {
                    "search": filter.value
                })
            }
        }
    })

    totalEntries.value = data.result.totalCount
    logEntries.value = data.result.entries.map((entry: any) => ({ data: entry }))

    if (loadProfiles) {
        const items = await secureDns.queries.getObjectsFromApi(activeAccountId.value || "")
        if (items instanceof Error) {
            console.error("failed to load secdns profiles", items)
            return
        }

        items.forEach((item) => {
            secDnsIdNameMapping.value[item.id] = item.name
        })
    }

    loading.value = false
}

async function handleDomainAction(domain: string, profileId: string, action: "allow" | "block") {
    await requestHandler.request("PUT", `/sms-mgt-api/api/2.0/tenants/${tenantHelpers.getTenantDomain(activeAccountId.value || "")}/secure-dns/configs/${profileId}/domain-action`, [{
        domain,
        action
    }])
}

const loadDebounce = debounce(500, load)

onMounted(async () => {
    await load(true)
})

watch(filter, async () => {
    await loadDebounce()
})

watch([itemsPerPage, currentPage, period], async () => {
    await load()
})

watch([itemsPerPage, period, filter], async () => {
    currentPage.value = 1
})
</script>

<style scoped>
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 10px;
}

.pagination span {
    margin: 0 10px;
}
</style>