import { useGlobalMixin } from "@/mixins/mixins.global";
import loaderComponent from "./loader.vue";
import table2Component from "./table2";
import i18n, { T } from "@/classes/i18n";
import moment from "moment";
import objectStores from "@/classes/init";
import { ActionTypes } from "@/store/vuex.store";
import router from "@/router/router";
import { useStore } from '@/store/vuex.store'
import tenantHelpers from "@/helpers/helpers.tenants";
import timeHelpers from "@/helpers/helpers.time";
import stringHelpers from "@/helpers/helpers.strings";
import deviceHelpers from "@/helpers/helpers.devices";

const cronojoblistComponent = {
  "mixins": [useGlobalMixin()],
  "name": "cronojoblist",
  "template": `<div>
        <table2
          :searchable="false"
          :loading="table.loading"
          :table="table.tableData"
          :maxHeight="600"
        >
        </table2>
    </div>`,
  "data": () => {
    return {
      "initialized": false,
      "data":[],
      "table": {
        "loading": true,
        "tableData": {
          "titles":[
            
          ],
          "rows":[]
        }
      }
    }
  },
  "computed": {},
  "props": {},
  "methods": {
    getJobList:async function(this:any) {
      let data 

      this.table.tableData.titles = [
        {
          "id": "nextTrigger",
          "text": "Execution time",
          "sortable": true,
          "sortBy": "triggerSort"
        },
        {
          "id": this.activePage == "uscProfiles" ? "utm" : "profile",
          "text": this.activePage == "uscProfiles" ? "UTM" : "Profile",
          "sortable": true,
        },
        {
          "id": "type",
          "text": "Type",
          "sortable": true,
        },
        {
          "id": "status",
          "text": "Status",
          "sortable": true,
        },
        {
          "id": "actions",
          "text": "Actions",
          "sortable": false
        }
      ]

      if (this.objectType == "uscProfiles") {
        data = await objectStores.uscProfiles.getProfileHistoryJobsFromApi(this.activeAccountId,this.objectId)
      }
      else if (this.objectType == "uscUtms") {
        useStore().dispatch(ActionTypes.getObjectInfos,{
          "accountId":this.activeAccountId,
          "objectTypes":["uscProfiles"]
        })
        data = await objectStores.uscProfiles.getUTMHistoryJobsFromApi(this.activeAccountId, this.objectId)
      }

      
      this.data = data

      if(data && !(data instanceof Error)) {
        this.table.tableData.rows = data.map((job) => {
          let relatedItem = useStore().getters.getObject({
            "accountId": this.activeAccountId,
            "objectId": this.activePage == "uscProfiles" ? job.data.deviceId : job.data.profileId,
            "objectType": this.activePage == "uscProfiles" ? "uscUtms" : "uscProfiles"
          })
          let nameProperty = tenantHelpers.getObjectNamePropertyForObjectType(this.activePage == "uscProfiles" ? "uscUtms" : "uscProfiles").primary
          let relatedItemName = relatedItem?.[nameProperty]
          return {
            data:{
              nextTrigger: timeHelpers.formatDate(moment(job.nextTrigger * 1000),timeHelpers.getDateFormatI18n(i18n.getLanguage(),true)),
              triggerSort:job.nextTrigger,
              [this.activePage == "uscProfiles" ? 'utm' : 'profile']: {
                labels: [
                  {
                    "title": relatedItemName ? deviceHelpers.getAliasedShortDeviceId(this.activePage == "uscProfiles" ? job.data.deviceId : job.data.profileId, relatedItemName) : T("Action was not triggered by a profile"),
                    "text": relatedItemName ? deviceHelpers.getAliasedShortDeviceId(this.activePage == "uscProfiles" ? job.data.deviceId : job.data.profileId, relatedItemName) : T("No profile"),
                    "class":'',
                    "onClick":() => {
                      if (relatedItemName) {
                        if(this.activePage == "uscProfiles") {
                          router.navigate('show-tenant-' + this.activeAccountId + '.sms-usc-utms-' + job.data.deviceId)
                        }
                        else {
                          router.navigate('edit-tenant-' + this.activeAccountId + '.sms-usc-profiles-' + job.data.profileId)
                        }
                      }
                    }
                  }
                ]
              },
              type:stringHelpers.capitalizeFirstLetter(job.type.replace("utm/","")),
              status:{ 
                labels: [
                  {
                    "title": job.status == "ERROR" ? T(job.statusText) : T(stringHelpers.capitalizeFirstLetter(job.status)),
                    "text": T(stringHelpers.capitalizeFirstLetter(job.status)),
                    "class": job.status == 'ERROR' ? 'bg-red' : job.status == 'FINISHED' ? 'bg-green' : '',
                    "icon": job.status == 'ERROR' ? "fal fa-exclamation-triangle" : job.status == 'PENDING' ? "fal fa-bell" : "fal fa-info",
                  }
                ]
              },
              actions:{
                buttons:[{
                  "text": T("Show history"),
                  "title": T("Show history"),
                  "icon": "fal fa-info",
                  "onClick": "toggleSubTable",
                  "disabled": false,
                  "loading": false
							  }]
              }
            },
            subTable:{
              "active": false,
              "titles": [
                {
                  "id": "issued",
                  "title": "Issued",
                  "text": "Issued"
                },
                {
                  "id": "status",
                  "title": "Status",
                  "text": "Status"
                },
                {
                  "id": "statusText",
                  "title": "Info",
                  "text": "Info"
                }
              ],
              "rows": job.stateHistory?.map((historyJob:any) => {
                return {
                  data:{
                    issued: timeHelpers.formatDate(moment(historyJob.issued * 1000), timeHelpers.getDateFormatI18n(i18n.getLanguage(), true)),
                    status: {
                      labels: [
                        {
                          "title": historyJob.status == "ERROR" ? T(historyJob.statusText) : T(stringHelpers.capitalizeFirstLetter(historyJob.status)),
                          "text": T(stringHelpers.capitalizeFirstLetter(historyJob.status)),
                          "class": historyJob.status == 'ERROR' ? 'bg-red' : historyJob.status == 'FINISHED' ? 'bg-green' : '',
                          "icon": historyJob.status == 'ERROR' ? "fal fa-exclamation-triangle" : historyJob.status == 'PENDING' ? "fal fa-bell" : "fal fa-info",
                        }
                      ]
                    },
                    statusText: historyJob.statusText
                  }
                }
              })
            }
          }
        })
      }

    }
  },
  "mounted": function (this: any) {
    this.getJobList()
  },
  "watch": {

  },
  "components": {
    "loader": loaderComponent,
    "table2": table2Component,
  }
}
export default cronojoblistComponent
