<script setup lang="ts">
    import loader from "../components/loader.vue";
  import { MutationTypes } from "@/store/vuex.store";
  import $ from "jquery"
  import { useStore } from '@/store/vuex.store'
  import inputFile from "../inputtypes/input-file-async";
  import { T } from "@/classes/i18n";
  import { computed, onMounted, ref, watch } from "vue";
import { useVue } from "@/app";

  const activeAccountId = computed(() => {
    return useStore()?.getters.getActiveAccountId
  })

  const initialized = ref(true)
  const file = ref(<any>undefined)
  const name = ref("")
  const mode = ref("legacy")
  const hasFile = computed(() => {
    return file.value != undefined
  })
  const fileError = ref(<undefined|string>undefined)
  const nameError = ref(undefined)
  const fileUploadSuccessful = ref(false)
  const formElement = ref(null)

  

  const submit = () => {
    submitAsForm()
  }
  const checkFile = (event: any) => {
    if (event.target.files.length > 0) {
      
      
      if (["pem", "cer", "cert", "crt"].indexOf(event.target.files[0].name.split(".").slice(-1).join("")) != -1) {
        file.value = event.target.files[0]
        fileError.value = undefined
      }
      else {
        file.value = undefined
        fileError.value = T("Wrong file format") + ". " + T("Accepted file formats:") + ".pem, .cer, .cert, .crt" 
      }
      
    }
    else {
      fileError.value = undefined
      file.value = undefined
    }
  }

  const submitAsForm = () => {
    initialized.value = false
    //@ts-ignore
    const form = $(formElement.value as HTMLElement)
    form
      .off('submit')
      .on('submit', function () {
        let frameName = 'upload' + Date.now(),
          frame = $('<iframe></iframe>', {
            name: frameName,
            id: frameName,
            css: {
              display: 'none'
            }
          })
            .on('load', function (event) {
              event.preventDefault()
              let data: any = $(frame).contents().find("body")[0].innerText
              let iframe = <HTMLObjectElement>this
              try {
                data = JSON.parse(data)
              }
              catch (e: any) { /* handled elsewhere */ }
              if (!iframe.parentNode) {
                return
              }
              // fixing Opera 10.53
              if (iframe.contentDocument &&
                iframe.contentDocument.body &&
                iframe.contentDocument.body.innerHTML == "false") {
                return
              }
              setTimeout(function () {
                frame.off('load')
                frame.remove()
              }, 1000 * 60 * 10)
              // prevent double action
              if (!data) {
                return
              }
              if (data?.errors?.valid === false) {

                data.errors.errors.forEach((err: any) => {
                  if (err.dataPath == "/name") {
                    nameError.value = err.message
                  }
                  if (err.dataPath == "/data") {
                    fileError.value = T(err.message)
                  }
                });
                return
              }

              fileUploadSuccessful.value = true
              useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = true

              setTimeout(function () {
                useVue().$refs?.itemlist?.refresh?.()
                useStore().commit(MutationTypes.removeModal, {
                  "accountId": activeAccountId.value
                })
              }, 2000)
            })
            .appendTo('body')

        form.attr('target', frameName)
        form.off('submit')
      })
      .trigger('submit')
    initialized.value = true
  }
 
  const submitAsJson = () => {

  }
  
  const checkInput = () => {
    if (name.value.indexOf(" ") != -1) {
      nameError.value = T("The name may not contain spaces")
    }
    else {
      nameError.value = undefined
    }
    if (name.value.length >= 3 && name.value.indexOf(" ") == -1 && file.value != undefined && file.value != "") {
      nameError.value = undefined
      useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = false
    }
    else {
      useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = true
    }
  }

  onMounted(() => {
    checkInput()
    initialized.value = true
  })

  watch(file,function() {
    checkInput()
  })
  watch(name, function () {
    checkInput()
  })

  defineExpose({submit,name})

</script>
<template>
  <div class="content-2">
    <template v-if="initialized">
      <template v-if="fileUploadSuccessful">
        <p class="text-center padding-xs-y-2" style="font-size:1.25em">
          <i class="fal fa-check color-green"></i> {{ T('The certificate was successfully uploaded') }}
        </p>
      </template>
      <template v-else>
        <form ref="formElement" enctype="multipart/form-data" :action="'/sms-mgt-api/api/1.1/tenants/' + activeAccountId + '.sms/certificates'" method="POST" v-on:keydown.enter.prevent='submit'>
          <div class="row form-group validation-base border-bottom padding-xs-b">
            <div class="col-lg-6">
              <label class="control-label" for="add-certificate-name">
                Name
              </label>
            </div>
            <div class="col-lg-18">
              <label class="text">
                <input 
                  id="add-certificate-name" 
                  name="name" 
                  type="text" 
                  class="form-control user-selection" 
                  placeholder="Name"
                  v-model="name"
                />
              </label>
              <template v-if="nameError">
                  <p class="label bg-red color-white">
                    {{ nameError }}
                  </p>
                </template>
            </div>
          </div>
          <div class="row form-group validation-base padding-xs-t">
            <div class="col-lg-6">
              <label class="control-label">
                {{ T('File') }}
              </label>
            </div>
            <div class="col-lg-18">
              <template v-if="mode == 'base64'">
                <input-file
                  name="certificate"
                  returntype="base64"
                  v-model="file"
                />
              </template>
              <template v-else>
                <label class="file">
                  <input 
                    id="add-certificate-file" 
                    name="certificate" 
                    type="file" 
                    class="form-control user-selection" 
                    placeholder="File" 
                    required data-validation-property="/data"
                    v-on:change="checkFile"
                  >
                  <div class="uploadbox" :class="{'hasfile': hasFile }">
                    <template v-if="file">
                      {{ file.name }}
                    </template>
                  </div>
                </label>
              </template>
              <template v-if="fileError">
                <p class="label bg-red color-white">
                  {{ fileError }}
                </p>
              </template>
            </div>
          </div>
        </form>
      </template>
    </template>

    <template v-else>
      <div class="text-center padding-xs-y-8 col-xs">
        <div class="text-size-3">
          <loader class="text-size-2 color-red" />
        </div>
      </div>
    </template>
  </div>
</template>