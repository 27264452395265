const inputToggleComponent = {
    "name":"input-toggle",
    "template": `
        <label class="checkbox toggle">
            <input
                class="toggle"
                type="checkbox"
                v-bind="$attrs"
                
                v-model="modelValue"
                v-on="{
                    input: event => $emit('update:modelValue', event.target.checked)
                }"
            />
            <span></span>
        </label>
    `,
    "props": {
        "modelValue": null
    }
}
export default inputToggleComponent
