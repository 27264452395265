import usersettingsComponent from "./user-settings"
import notificationsComponent from './notifications'
import activeUsersComponent from "./active-users.vue"
import config from '@/classes/config'

const mainHeaderComponent = {
    "name":"headerTemplate",
    "data":function() {
        return {
            "collapsed":false,
            "openMenu":false
        }
    },
    "computed":{
        "DEV_MODE":function(this:any) {
            return config.devMode
        }
    },
    "template": `
        <header id="main-header" class="media-screen-only" style="padding-left:24px">
            <a v-on:click="toggleCollapsed()">
                <i class="html-icon" :class="{'collapse-menu': collapsed, 'open-menu':!collapsed}">
                    <span></span>
                    <span></span>
                </i>
            </a>
            <a v-on:click="toggleMenu()" class="action-toggle-class" data-class="open-menu">
                <i class="html-icon open-menu">
                    <span></span>
                    <span></span>
                    <span></span>
                </i>
            </a>
            <span class="app-name">
                Unified Security <small v-if="DEV_MODE">- Developer Mode</small>
            </span>
            <div class="menus">
                <activeusers ref="activeusersArea"></activeusers>

                <div style="height: 25px; align-self: center; margin: 0 5px" class="border-right"></div>

                <usersettings ref="usersettingsArea"></usersettings>
                <notifications ref="notificationArea"></notifications>
            </div>
            
        </header>
    `,
    "methods": {
        "toggleCollapsed":function(this:any) {
            this.collapsed = !this.collapsed
            if(this.collapsed) {
                document.body.classList.add("collapsed-menu")
            }
            else {
                document.body.classList.remove("collapsed-menu")
            }
        },
        "toggleMenu":function(this:any) {
            this.openMenu = !this.openMenu
            if(this.openMenu) {
                document.body.classList.add("open-menu")
            }
            else {
                document.body.classList.remove("open-menu")
            }
        }
    },
    "components": {
        "notifications":notificationsComponent,
        "usersettings":usersettingsComponent,
        "activeusers":activeUsersComponent
    }
}
export default mainHeaderComponent