import { T } from "@/classes/i18n";
import PlistParser from "@/classes/plistParser";
import { useGlobalMixin } from "@/mixins/mixins.global";


const inputMobileConfigComponent = {
  name:"input-mobileconfig-upload",
  mixins: [useGlobalMixin()],
  template: `
        <div>
            <div class="hidden">
                <input 
                    style="display:none" 
                    type="file" 
                    v-bind="$attrs"
                    :key="'mobileConfigUpload'+field.id"
                    v-on:change="mobileconfigUploadChange($event,field)"
                >
              <div class="uploadbox"></div>
            </div>
            <div>
                <a v-if="modelValue == ''" class="btn btn-primary">
                    <i class="fal fa-plus"></i> {{ T('Upload') }}
                </a>
            </div>
            <div v-if="json != undefined" v-on:click.prevent.stop="function(){}">
                <div class="row padding-xs-y form-group">
                    <div class="col-xs-24 col-xl-10 first">
                        <label class="control-label inputname">
                            {{ T('Name') }}
                        </label>
                    </div> 
                    <div class="col-xs-24 col-xl-14 input">
                        <input type="text" :value="json.PayloadDisplayName || ''" disabled=""/>
                    </div> 
                </div>
                <div class="row padding-xs-y form-group border-bottom">
                    <div class="col-xs-24 col-xl-10 first">
                        <label class="control-label inputname">
                            {{ T('Type') }}
                        </label>
                    </div> 
                    <div class="col-xs-24 col-xl-14 input">
                        <input type="text" :value="json.PayloadType || ''" disabled=""/>
                    </div> 
                </div> 
                <div class="row padding-xs-y form-group border-bottom">
                    <div class="col-xs-24 col-xl-10 first">
                        <label class="control-label inputname">
                            {{ T('Version') }}
                        </label>
                    </div> 
                    <div class="col-xs-24 col-xl-14 input">
                        <input type="text" :value="json.PayloadVersion || ''" disabled=""/>
                    </div> 
                </div> 
                <div class="row padding-xs-y form-group border-bottom">
                    <div class="col-xs-24 col-xl-10 first">
                        <label class="control-label inputname">
                            {{ T('Identifier') }}
                        </label>
                    </div> 
                    <div class="col-xs-24 col-xl-14 input">
                        <input type="text" :value="json.PayloadIdentifier || ''" disabled=""/>
                    </div> 
                </div> 
                <div class="row padding-xs-y form-group border-bottom">
                    <div class="col-xs-24 col-xl-10 first">
                        <label class="control-label inputname">
                            {{ T('UUID') }}
                        </label>
                    </div> 
                    <div class="col-xs-24 col-xl-14 input">
                        <input type="text" :value="json.PayloadUUID || ''" disabled=""/>
                    </div> 
                </div>
                
            </div>
            <div>
                <a v-if="modelValue != ''" class="btn btn-primary">
                    <i class="fal fa-retweet"></i> {{ T('Replace') }}
                </a>
                <a
                    v-if="modelValue != ''"
                    class="btn btn-primary"
                    v-on:click.prevent.stop="clear()"
                >
                    <i class="fal fa-trash"></i> {{ T('Delete') }}
                </a>
            </div>
        </div>
            `,
  data: function () {
    return {
      "initialized": false,
      "modelValue": "",
      "json": undefined
    }
  },
  props: {
    "name": {
      required: false,
      default: ""
    },
    "id": {
      required: false,
      default: null
    },
    "field": {
      required: false,
      default: function () { return {} }
    }
  },
  methods: {
    profileErrorCheck: function (this: any, profileJson: any) {
      if (!profileJson.PayloadType) {
        this.field.errors.push(T("PayloadType is missing"))
      }
      if (!profileJson.PayloadVersion) {
        this.field.errors.push(T("PayloadVersion is missing"))
      }
      if (!profileJson.PayloadIdentifier) {
        this.field.errors.push(T("PayloadIdentifier is missing"))
      }
      if (!profileJson.PayloadUUID) {
        this.field.errors.push(T("PayloadUUID is missing"))
      }
      if (!profileJson.PayloadDisplayName) {
        this.field.errors.push(T("PayloadDisplayName is missing"))
      }
    },
    init: async function (this: any) {
      let field: any = this.field
      if (field.value != "") {
        let xml: any = field.value
        let xmlStart: number = xml.indexOf("<?xml")
        if (xmlStart > 0) { xml = xml.substring(xmlStart) }
        xml = xml.replace(/^<\?xml\s+version\s*=\s*(["'])[^\1]+\1[^?]*\?>/, "").replace(/\<\!DOCTYPE[^\>]+\>/, "")
        let json = PlistParser.parse(xml)
        let profileJson: any
        if (Array.isArray(json)) {
          profileJson = (json[0] == undefined && json[1] != undefined) ? json[1] : json[0]
        }
        else {
          profileJson = json
        }

        this.profileErrorCheck(profileJson)

        if (field.errors.length == 0) {
          this.modelValue = xml
          this.json = profileJson
        }
        else {
          this.modelValue = ""
          this.json = undefined
        }
      }
      else {
        this.modelValue = ""
        this.json = undefined
      }
      this.initialized = true
    },
    mobileconfigUploadChange: async function (this: any, event: any, field: any) {
      field.errors = []
      field.json = undefined
      let file: any = event.target.files ? event.target.files[0] : ""
      if (file.name.indexOf('.mobileconfig') == -1) {
        field.errors.push(T("Wrong filetype"))
        file = ""
      }
      if (file) {
        let xml: any = await this.readFileContents(file)
        let xmlStart: number = xml.indexOf("<?xml")
        if (xmlStart > 0) {
          xml = xml.substring(xmlStart)
        }
        xml = xml.replace(/^<\?xml\s+version\s*=\s*(["'])[^\1]+\1[^?]*\?>/, "").replace(/\<\!DOCTYPE[^\>]+\>/, "")
        let json = PlistParser.parse(xml)
        let profileJson: any
        if (Array.isArray(json)) {
          profileJson = (json[0] == undefined && json[1] != undefined) ? json[1] : json[0]
        }
        else {
          profileJson = json
        }

        this.profileErrorCheck(profileJson)

        if (field.errors.length == 0) {
          this.modelValue = xml
          this.json = profileJson
        }
        else {
          this.modelValue = ""
          this.json = undefined
        }
      }
      else {
        this.modelValue = ""
        this.json = undefined
      }
    },
    readFileContents: function (file: any) {
      return new Promise((resolve: any, reject: any) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    clear: function (this: any) {
      this.modelValue = ""
      this.json = undefined
    }
  },
  mounted: function (this: any) {
    let thisComponent: any = this
    this.init()
  },
  destroyed: function (this: any) {

  },
  watch: {
    "modelValue": function (this: any, value: any) {
      this.field.value = value
    }
  }
}
export default inputMobileConfigComponent