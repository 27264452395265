import { T } from "@/classes/i18n";
import { useGlobalMixin } from "@/mixins/mixins.global";
import type { Widget } from "./widgetarea";
import deviceHelpers from "@/helpers/helpers.devices";

const widgetareaAddWidgetComponent = {
    "mixins": [useGlobalMixin()],
    "name": "widgetarea-add-widget",
    data() {
        return {
            widget: <Widget["widget"]>{
                "title": "",
                "queryType": "null",
                "objectType": "null",
                "icon": "fa-signal",
                "color": "red",
                "type": "count"
            },
            colorpicker: {
                colors: ['red', 'orange', 'yellow', 'green', 'lightblue', 'blue', 'purple', 'pink'],
                visible: true
            },
            iconpicker: {
                icons: ['fa-signal', 'fa-chart-bar', 'fa-chart-area', 'fa-chart-line', 'fa-ban', 'fa-bell', 'fa-info-circle', 'fa-mobile-alt', 'fa-laptop'],
                visible: true
            },
            visible: false,
            fromWidgetArea: undefined,
            thisContext: <"add" | "edit" | undefined>undefined,
            index: <string | undefined>undefined,
            loading: true,
        }
    },
    computed: {
        activeTenantDomain: function (this: any) {
            return this.activeAccountId + ".sms"
        }
    },
    props: {

    },
    template: `
    <div class="modal" v-if="visible">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title float-xs-left">${T('Add a widget')}</h4>&nbsp;
                    <button type="button" class="close" v-on:click="close()">
                        <span>×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="margin-xs-b-2">
                        <input type="text" v-model="widget.title" placeholder="${T('Title')}" style="font-size:1.5em">
                    </p>
                    <div class="box-shadow padding-xs-2 margin-xs-t-2">


                        <h5>Appearance</h5>
                        <div class="row-2 margin-xs-t-2">
                            <hr>
                        </div>
                        <div class="row flexrow">
                            <div class="col-xs-4 col-md-2 padding-xs">
                                <p class="text-bold" style="padding-top:0.8em">
                                    {{ T('Color') }}
                                </p>
                            </div>
                            <div class="col-xs-4 col-md-3 flex-shrink padding-xs" v-if="!colorpicker.visible">
                                <div class="specialpicker-picked" :class="'bg-'+widget.color" v-on:click="toggleColorpicker()"></div>
                            </div>
                            <div class="col-xs-20 col-md-22 flex-grow padding-xs" v-if="colorpicker.visible">
                                <div class="row flexrow widget-specialpicker" style="flex-wrap:wrap">
                                    <div class="specialpicker-box col-xs padding-xs-x" v-for="color in colorpicker.colors" :class="{'specialpicker-selected': widget.color == color}">
                                        <label 
                                            :for="'specialpicker-'+color" 
                                            class="specialpicker-item text-center" 
                                            :class="'bg-'+color" 
                                            v-on:click="setColor(color);toggleColorpicker()"
                                        >
                                            <input 
                                                :id="'specialpicker-'+color" 
                                                type="radio" 
                                                name="specialpicker" 
                                                v-model="widget.color" 
                                                :value="color" 
                                                style="display:none"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr>

                        <div class="row flexrow">
                            <div class="col-xs-4 col-md-2 padding-xs">
                                <p class="text-bold" style="padding-top:0.8em">
                                    Icon
                                </p>
                            </div>
                            <div class="col-xs-4 col-md-3 flex-shrink padding-xs" v-if="!iconpicker.visible">
                                <div class="specialpicker-picked " :class="'bg-'+widget.color"  v-on:click="toggleIconpicker()">
                                    <i class="fal" :class="widget.icon"></i>
                                </div>
                            </div>
                            <div class="col-xs-20 col-md-22 flex-grow padding-xs" v-if="iconpicker.visible">
                                <div class="row flexrow widget-specialpicker"  style="flex-wrap:wrap">
                                    <div class="specialpicker-box col-xs padding-xs-x" v-for="icon in iconpicker.icons" :class="{'specialpicker-selected': widget.icon == icon}">
                                    <label :for="'specialpicker-'+icon" class="specialpicker-item text-center" :class="'bg-'+widget.color"  v-on:click="setIcon(icon);toggleIconpicker()">
                                        <i class="fal fa-fw" :class="icon"></i>
                                        <input :id="'specialpicker-'+icon" type="radio" name="specialpicker" v-model="widget.icon" :value="icon" style="display:none">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <hr>


                        <h5>${T('Data')}</h5>
           
                        <p class="text-bold margin-xs-t-2">
                            ${T('What datatype do you want to display?')} <span class="color-red">*</span>
                        </p>
                        <div class="row flexrow">
                            <div class="col-xs select-datatype-container padding-xs">
                                <select v-model="widget.objectType">
                                    <option value="null">
                                        {{ T('Select') }}
                                    </option>
                                    <option value="uscUtms">
                                         UTMs (Unified Security Console)
                                    </option>
                                </select>
                            </div>
                        </div>

                        <p class="text-bold margin-xs-t-2">
                            ${T('What data do you want to display?')} <span class="color-red">*</span>
                        </p>
                        <div class="row flexrow">
                            <div class="col-xs select-datatype-container padding-xs">
                                <select v-model="widget.queryType" :disabled="widget.objectType != 'null' ? null : true">
                                    <option value="null">
                                        {{ T('Select') }}
                                    </option>
                                    <option value="count" v-if="widget.objectType == 'uscUtms'">
                                        {{ T('Count') }}
                                    </option>
                                    <option value="list" v-if="widget.objectType == 'uscUtms'">
                                        {{ T('Devices list') }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <p class="text-bold margin-xs-t-2">
                            ${T('How do you want to display the data?')} <span class="color-red">*</span>
                        </p>
                        <div class="row flexrow">
                            <div class="col-xs select-datatype-container padding-xs">
                                <select v-model="widget.type" :disabled="widget.queryType != 'null' ? null : true">
                                    <option value="null">
                                        {{ T('Select') }}
                                    </option>
                                    <option value="counter" v-if="widget.queryType == 'count'">
                                        {{ T('Counter') }}
                                    </option>
                                    <option value="table" v-if="widget.queryType == 'list'">
                                        {{ T('Table') }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    
                </div>
                <hr>
                <div class="box-footer content-2">
                    <button type="button" v-on:click="close()" class="btn btn-default pull-left">
                        <i class="fal fa-fw fa-times"></i>&nbsp;${T('Abort')}
                    </button>
                    <a
                        v-if="thisContext == 'add'"
                        class="btn margin-md-0 btn-loader twist-in float-xs-right"
                        v-on:click="submit()"
                        title="${T('Add widget')}"
                        :disabled="!isSubmitConditionFulfilled() ? true : null"
                    >
                        <span><i class="fal fa-fw fa-plus"></i>&nbsp;${T('Add widget')}</span>
                    </a>
                    <a
                        v-if="thisContext == 'edit'"
                        class="btn margin-md-0 btn-loader twist-in float-xs-right"
                        v-on:click="submit()"
                        title="${T('Update widget')}"
                        :disabled="!isSubmitConditionFulfilled() ? true : null"
                    >
                        <span><i class="fal fa-fw fa-sync"></i>&nbsp;${T('Update widget')}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>`,
    methods: {
        init: function (this:any,widgetArea: any, context: "add" | "edit" = 'add', widget?: any, widgetIndex?: string) {

            this.resetToDefaults()
            this.visible = true
            this.thisContext = context
            this.fromWidgetArea = widgetArea


            if (context == 'edit' && widget && widgetIndex) {
                this.widget.color = widget.color
                this.widget.icon = widget.icon
                this.widget.objectType = widget.objectType
                this.widget.queryType = widget.queryType
                this.widget.title = widget.title
                this.widget.type = widget.type
                this.index = widgetIndex
            }
            this.loading = false
        },

        submit: function (this: any) {
            let widget: any = {}
            for (let field in this.widget) {
                widget[field] = this.widget[field]
            }
            if (this.thisContext == 'add') {
                this.fromWidgetArea.addWidget(widget)
            }
            else if (this.thisContext == 'edit') {
                let widgetIndex: number = this.index
                this.fromWidgetArea.updateWidget(widgetIndex, widget)
            }
            this.close()
        },

        close: function (this: any) {
            this.visible = false
            this.resetToDefaults()
        },

        resetToDefaults: function (this: any) {
            this.widget.title = ""
            this.widget.queryType = 'null'
            this.widget.objectType = 'null'
            this.widget.icon = "fa-signal"
            this.widget.color = "red"
            this.widget.type = 'null'
            this.fromWidgetArea = undefined
            this.thisContext = undefined
            this.index = undefined
            this.colorpicker.visible = true
            this.iconpicker.visible = true
            this.loading = true
        },

        isSubmitConditionFulfilled: function (this: any) {
            let widgetArea: any = this.fromWidgetArea
            let result: boolean = true
            if (this.widget.objectType != 'null' && this.widget.queryType != 'null' && this.widget.type != 'null') {

            }
            else {
                result = false
            }
            return result
        },
        toggleColorpicker: function (this: any) {
            this.colorpicker.visible = !this.colorpicker.visible
        },
        setColor: function (this: any, color: string) {
            this.widget.color = color
        },
        toggleIconpicker: function (this: any) {
            this.iconpicker.visible = !this.iconpicker.visible
        },
        setIcon: function (this: any, icon: string) {
            this.widget.icon = icon
        },
        getAnonymDeviceLabel: function (device: any) {
            let deviceLabel: any = ""
            deviceLabel = deviceHelpers.getAliasedShortDeviceId(device.anonymousDeviceId, "Anonymized device")
            return deviceLabel
        },
        getAnonymVpnLabel: function (vpn: any) {
            let vpnLabel: any = ""
            vpnLabel = deviceHelpers.getAliasedShortDeviceId(vpn.anonymousVPNId, "Anonymized device")
            return vpnLabel
        },
    },
    watch: {
        'visible': {
            handler: function (isVisible: boolean) {

            }
        },
        'widget.objectType': {
            handler: function (this: any) {
                let thisComponent: any = this
                if (thisComponent.loading == false) {
                    thisComponent.widget.queryType = 'null'
                }
            }
        },
        'widget.queryType': {
            handler: function (this: any) {
                let thisComponent: any = this
                let currentTag: string = thisComponent.widget.tag
                let currentobjectType: string = thisComponent.widget.objectType
                let currentQueryType: string = thisComponent.widget.queryType
                if (thisComponent.loading == false) {
                    if ([currentTag, currentobjectType, currentQueryType].indexOf('null') != -1) {
                        thisComponent.widget.type = "null"
                    }
                    else {


                    }

                }
            }
        }
    },
    created() {
        let thisComponent: any = this
    }
}
export default widgetareaAddWidgetComponent
