class LocalStorageHandler {
    set(name:string, value:string) {
        localStorage.setItem(name,value)
    }
    get(name:string) {
        return localStorage.getItem(name)
    }
    delete(name:string) {
        localStorage.removeItem(name)
    }
}
const localStorageHandler = new LocalStorageHandler()
export default localStorageHandler