const imageHelpers = {
  printImage: function(headerContent: string = "",footerContent: string = "", source: string) {
    let Pagelink: string = "about:blank";
    var pwa: any = window.open(Pagelink, "_new");
    pwa.document.open();
    pwa.document.write(`
      <html>
        <head>
          <link rel="stylesheet" href="https://${document.location.hostname}/sms/client/sp-sass/css/style.css"/>
          <script>
              function step1(){
                setTimeout('step2()', 10);
              }
              function step2(){
                window.print();
                window.close()
              }
          </script>
        </head>
        <body onload='step1()'>
          ${headerContent}
          <img src='${source}'/>
          ${footerContent}
        </body>
      </html>
    `);
    pwa.document.close();
  }
}
export default imageHelpers