<script setup lang="ts">
    import { ref } from 'vue';
    import DocsObjectType from './docs-object-type.vue';
    import DocsComponents from './docs-components.vue';

    DocsObjectType

    const activeTab = ref("docs")
</script>

<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent" id="docs">
        <div class="row">
            <div class="col-xs-24 col-md-6 col-lg-4">
                <div class="box-shadow">
                    <nav class="menu-vertical">
                        <ul class="navigation">
                            <li :class="{ 'active': activeTab == 'docs' }">
                                <a v-on:click="activeTab = 'docs'" ><i class="fa fa-fw fa-info-circle"></i> Docs</a>
                                <ul>
                                    <li :class="{ 'active': activeTab == 'objectTypes' }">
                                        <a v-on:click="activeTab = 'objectTypes'" ><i class="fa fa-fw fa-boxes"></i> ObjectTypes</a>
                                    </li>
                                    <li :class="{ 'active': activeTab == 'components' }">
                                        <a v-on:click="activeTab = 'components'" ><i class="fa fa-fw fa-boxes"></i> Components</a>
                                    </li>
                                </ul>
                            </li>
                        
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="col-xs-24 col-md-18 col-lg-20">
                <div class="box-shadow">
                    <div class="box-content-2">
                        <DocsObjectType v-if="activeTab == 'objectTypes'"></DocsObjectType>
                        <DocsComponents v-if="activeTab == 'components'"></DocsComponents>
                    </div>
                </div>
            </div> 
        </div>
        

    </section>
</template>

<style lang="scss">
    #docs {
        pre,code {
            background:#303338 !important;
            border-radius:5px;
        }
        nav.menu-vertical {
            padding:8px 0;
            ul {
                li {
                    a {
                        padding-top:4px;
                        padding-bottom:4px;
                        color:rgba(0,0,0,0.4);
                        transition:0.3s;
                        &:hover {
                            color:#E74C3C;
                        }
                    }
                    &.active {
                        > a {
                            padding-top:4px;
                            padding-bottom:4px;
                            color:rgba(0,0,0,0.6);
                            font-weight:bold;
                            transition:0.3s;
                            i {
                                color:#E74C3C;
                            }
                            &:hover {
                                color:rgba(0,0,0,0.6);
                            }
                        }   
                    }
                }
            }
        }
        .code.hljs.atom_one_dark {
            border-radius:5px !important;
            background:darken(#303338,5%);
            pre {
                background:darken(#303338,5%) !important;
                padding:0;
                margin:0;
                min-height:0;
                max-height:620px;
            }
            code {
                background:darken(#303338,5%) !important;
                padding:0;
                margin:0;
            }
        }
    }
    body.darkmode {
        #docs {
            nav.menu-vertical {
                padding:8px 0;
                ul {
                    li {
                        a {
                            color:rgba(255,255,255,0.6);
                            &:hover {
                                color:rgba(255,255,255,0.95);
                            }
                        }
                        &.active {
                            > a {
                                padding-top:4px;
                                padding-bottom:4px;
                                color:rgba(255,255,255,0.95);
                                font-weight:bold;
                                transition:0.3s;
                                i {
                                    color:#E74C3C;
                                }
                                &:hover {
                                    color:rgba(255,255,255,0.95);
                                }
                            }   
                        }
                    }
                }
            }
            .code.hljs.atom_one_dark {
                background:darken(#303338,5%);
                pre {
                    background:darken(#303338,5%) !important;
                    padding:0;
                    margin:0;
                    min-height:0;
                    max-height:620px;
                }
                code {
                    background:darken(#303338,5%) !important;
                    padding:0;
                    margin:0;
                }
            }
        }

    }
</style>