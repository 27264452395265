import tenantHelpers from "@/helpers/helpers.tenants"
import config from "@/classes/config"

export type FeatureName = "androidEnterprise" | "zeroTouch" | "apple" | "appleDEP" | "appleDEPToken" | "appleVPP" | "appleAPNs" | "appleSingleDevice" | "appleDeviceApps" | "androidEnterprisePolicies" | "androidEnterpriseHistory" | "androidEnterpriseApps" | "tenantNotifications"
export type ApiVersion = "1.1" | "2.0"
export type FeatureToApi = {
  [featureName in FeatureName]: {
    "mdm-001": ApiVersion
    "mdm-002": ApiVersion
    "mdm-003": ApiVersion
    "mdm-004": ApiVersion
  }
}
class Apis {
  private featureToApi: FeatureToApi = {
    "androidEnterprise": {
      "mdm-001": "2.0",
      "mdm-002": "2.0",
      "mdm-003": "2.0",
      "mdm-004": "2.0"
    },
    "androidEnterprisePolicies": {
      "mdm-001": "1.1",
      "mdm-002": "1.1",
      "mdm-003": "1.1",
      "mdm-004": "1.1"
    },
    "androidEnterpriseHistory": {
      "mdm-001": "1.1",
      "mdm-002": "1.1",
      "mdm-003": "1.1",
      "mdm-004": "1.1"
    },
    "androidEnterpriseApps": {
      "mdm-001": "1.1",
      "mdm-002": "1.1",
      "mdm-003": "1.1",
      "mdm-004": "1.1"
    },
    "zeroTouch": {
      "mdm-001": "2.0",
      "mdm-002": "2.0",
      "mdm-003": "2.0",
      "mdm-004": "2.0"
    },
    "appleSingleDevice": {
      "mdm-001": "2.0",
      "mdm-002": "2.0",
      "mdm-003": "2.0",
      "mdm-004": "2.0"
    },
    "appleDeviceApps": {
      "mdm-001": "2.0",
      "mdm-002": "2.0",
      "mdm-003": "2.0",
      "mdm-004": "2.0"
    },
    "apple": {
      "mdm-001": "2.0",
      "mdm-002": "2.0",
      "mdm-003": "2.0",
      "mdm-004": "2.0"
    },
    "appleDEP": {
      "mdm-001": "1.1",
      "mdm-002": "1.1",
      "mdm-003": "1.1",
      "mdm-004": "1.1"
    },
    "appleDEPToken": {
      "mdm-001": "2.0",
      "mdm-002": "2.0",
      "mdm-003": "2.0",
      "mdm-004": "2.0"
    },
    "appleVPP": {
      "mdm-001": "1.1",
      "mdm-002": "1.1",
      "mdm-003": "1.1",
      "mdm-004": "1.1"
    },
    "appleAPNs": {
      "mdm-001": "2.0",
      "mdm-002": "2.0",
      "mdm-003": "2.0",
      "mdm-004": "2.0"
    },
    "tenantNotifications": {
      "mdm-001": "2.0",
      "mdm-002": "2.0",
      "mdm-003": "2.0",
      "mdm-004": "2.0"
    }
  }

  private currentServer : string = "mdm-001"
  
  constructor() {
    this.getCurrentserver()
  }

  getCurrentserver() {
    this.currentServer = config.server
  }

  parseApiResponse(res: any) {
    if (res && res.name != undefined && res.code != undefined && res.message != undefined && res.data != undefined) {
      return res.data
    } else {
      return res
    }
  }

  getApiVersionForFeature(feature?: FeatureName, fallback: ApiVersion = "1.1"): ApiVersion {
    let result = fallback
    if (feature) {
      const serverToApi = this.featureToApi[feature]
      if(serverToApi) {
        const currentServer = this.currentServer as keyof typeof serverToApi
        if(currentServer) {
          const apiVersion = serverToApi[currentServer]
          if(apiVersion) {
            result = apiVersion
          }
        }
      }
    }
    return result
  }
  getPostFixAfterTenantDomain(featureName?:FeatureName) {
    const thisApiVersion = this.getApiVersionForFeature(featureName)
    // add "/apple" to Path for 2.0 
    if (["apple", "appleDEP", "appleVPP", "appleAPNs", "appleSingleDevice", "appleDeviceApps", "appleDEPToken"].indexOf(featureName || "") != -1 && thisApiVersion == "2.0") {
      return "/apple"
    }
    if (["appleDEP", "appleVPP", "appleAPNs", "appleDEPToken"].indexOf(featureName || "") != -1 && thisApiVersion == "1.1") {
      return "/ios"
    }

    return ""
  }
  /**
   * 
   * @param accountId 
   * @param featureName 
   * @returns <string>"/sms-mgt-api/api/{apiForThisFeature}/tenants/{tenantDomain}" or Error
   */
  getTenantApiUrl(accountId:string|undefined,featureName?:FeatureName) {
    const thisApiVersion = this.getApiVersionForFeature(featureName)
    if(accountId) {
      const tenantDomain = tenantHelpers.getTenantDomain(accountId)
      const postFix = this.getPostFixAfterTenantDomain(featureName)
      return "/sms-mgt-api/api/" + thisApiVersion + "/tenants/" + tenantDomain + postFix
    }
    else {
      throw "missing accountId"
    }
  }
}
const apis = new Apis()
export default apis