import { T } from "@/classes/i18n";
import { useGlobalMixin } from "@/mixins/mixins.global";
import { MutationTypes } from "@/store/vuex.store";
import { useStore } from '@/store/vuex.store';
import notificationComponent from "./notification";
import frontendNotifications from "@/classes/notifications";
import dialogs from "@/dialogs/dialogs";
import config from "@/classes/config";


const notificationsComponent = {
    mixins: [useGlobalMixin()],
    "name":"notifications",
    "data":function() {
        return {
            "showNotifications":false,
            "updateCount":0,
            "initialized": false,
            "notificationRefs": []
        }
    },
    "computed":{
        "DEV_MODE":function(this:any) {
            return config.devMode
        },
        "activeAccountId":function(this:any) {
            return useStore().state.session.activeAccountId
        },
        "notifications":function(this:any) {
            return useStore().state.session.accounts[this.activeAccountId]?.notifications || []
        },
        "hasSelectedNotifications": function (this: any) {
            this.updateCount;
            if(this.initialized) {
                return this.notificationRefs?.some((notificationComponent: any) => {
                    if (notificationComponent?.selected == true) {
                        return true
                    }
                })
            }
            return false
        }
    },
    "template": `
        <div class="menu" v-on:click.stop>
            <ul>
                <li>
                    <a class="toggle" v-on:click="toggleShowNotifications()">
                        <i class="fal fa-comment"></i>
                        <span class="count" v-if="getNewNotificationsCount() > 0">
                            {{ getNewNotificationsCount() }}
                        </span>
                    </a>
                    <transition name="notifications"
                        :duration="{ enter:300, leave:300 }"
                        enterActiveClass="notifications-entering"
                        enterToClass="notifications-enter-to"
                        leaveActiveClass="notifications-leaving"
                        leaveToClass="notifications-leave-to"
                    >
                        <ul v-on:blur="hideNotifications()" class="menu" v-if="showNotifications" :key="'default'">
                            <li v-if="notifications.length">
                                <div class="padding-xs-2 text-center">
                                    <a v-on:click="deleteNotificationsDialog('all')" class="btn btn-primary deleteAll">
                                        {{ T('Delete all') }}
                                    </a>
                                    <a v-on:click="deleteNotificationsDialog('selected')" class="btn btn-primary deleteSelected" :disabled="!hasSelectedNotifications || null" :class="{'disabled':!hasSelectedNotifications}">
                                        {{ T('Delete selected only') }}
                                    </a>
                                </div>
                            </li>
                            <li>
                                <template v-for="notification,index in notifications" :key="notification.id" >
                                        <notification
                                            :index="index"
                                            :notification="notification"
                                            :ref="(el) => (notificationRefs[index] = el)"
                                        />
                                </template>
                                <template v-if="notifications.length == 0">
                                    <p class="text-bold padding-xs-y-2 text-center" style="opacity:0.7">{{ T('No notifications') }}</p>
                                </template>
                            </li>
                            
                        </ul>
                    </transition>
                    <transition name="notifications"
                        :duration="{ enter:300, leave:300 }"
                        enterActiveClass="notifications-entering"
                        enterToClass="notifications-enter-to"
                        leaveActiveClass="notifications-leaving"
                        leaveToClass="notifications-leave-to"
                    >
                        <ul v-if="!showNotifications && getNewNotificationsCount(true) > 0" :key="'highlights'">
                            <li>
                                <template v-for="notification,index in notifications">
                                    <notification
                                        v-if="notification.highlightNew"
                                        :ref="'notification-'+ notification.id"
                                        :key="notification.id"
                                        :index="index"
                                        :notification="notification"
                                    />
                                </template>
                            </li>
                        </ul>
                    </transition>
                </li>
                
            </ul>
            
        </div>
    `,
    "methods": {
        "toggleShowNotifications": function(this:any) {
            this.showNotifications = !this.showNotifications
        },
        "hideNotifications": function(this:any) {
            this.showNotifications = false
        },
        "addNotification":function(this:any,options:any) {
            options.accountid = options.accountid || this.activeAccountId
            frontendNotifications.addNotification(options.accountId,options)
        },
        "removeNotification":function(this:any,id:string,accountId?:string) {
            frontendNotifications.removeNotification(accountId || this.activeAccountId, id)
        },
        "getNewNotificationsCount": function(this:any,highlightedOnly:boolean=false) {
            let count = this.notifications.filter((notification:any) => {
                return highlightedOnly ? notification.state == "new" && notification.highlightNew : notification.state == "new"
            }).length
            return count
        },
        "deleteNotificationsDialog": function(this:any,type:"all"|"selected") {
            let dialogContent = type == "all" ? T('Do you really want to delete all notifications?') : T('Do you really want to delete all selected notifications?')
            dialogs.misc.confirmDialog(
                this.activeAccountId,
                T('Confirm delete'),
                dialogContent,
                async () => {
                    let promises : any = []
                    this.notificationRefs.forEach((notificationComponent:any) => {
                        if (type == "all") {
                            if(notificationComponent)
                            promises.push(frontendNotifications.removeNotification(this.activeAccountId, notificationComponent.notification.id))
                        }
                        else if (notificationComponent?.selected) {
                            promises.push(frontendNotifications.removeNotification(this.activeAccountId, notificationComponent.notification.id))
                        }
                    });
                    await Promise.all(promises)
                }
            )
        },
        "logSmth": function(this:any, el:any) {

        }
    },
    "components": {
        "notification":notificationComponent
    },
    mounted: function(this:any) {
        useStore().commit(MutationTypes.addOnClickHandler,{
            "id":"notifications",
            "ref":"root",
            "method":() => {
                if(this.showNotifications) {
                    this.showNotifications = false
                }
            }
        })
        this.initialized = true
    }
}
export default notificationsComponent