<script setup lang="ts">
import { T } from '@/classes/i18n';
import getterHelpers from '@/helpers/helpers.getters';
import useRouterStore from '@/router/routerStore';
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import itemlistItem from '@/templates/components/itemlist-item.vue';
import loaderComponent from "../components/loader.vue";
import googleMap from '../components/map';
import products from '@/classes/objectTypes';
import Tabs from '../components/tabs.vue';
import moment from 'moment';
import inputDate from '../inputtypes/input-date.vue';
import jsonHelpers from '@/helpers/helpers.json';
import dialogs from '@/dialogs/dialogs';
import encodingHelpers from '@/helpers/helpers.encoding';
import mixinHelpers from '@/helpers/helpers.mixins';
import type { Connection, VPNSystemInfoMessage, WindowsVpnJob } from '@/classes/objectTypes/mobileSecurity/windows/windowsVpns';
import buttonComponent from "../components/button.vue";
import tableNext, { type TableEntryInfo } from '../components/tableNext.vue';
import { Button } from '@/classes/buttons';
import type { Label } from '@/classes/objectTypes/objectType';
import type { VPNConnectionGetMessage } from '@/classes/objectTypes/mobileSecurity/windows/windowsVpns'
import stringHelpers from '@/helpers/helpers.strings';
import config from '@/classes/config';
import numberHelpers from '@/helpers/helpers.numbers';
import Tooltip from '../components/tooltip.vue';
import ClipboardHandler from '@/classes/clipboard';
import frontendNotifications from '@/classes/notifications';

// Ref Values

const initialized = ref(false)
const inventoryLoaded = ref(false)
const savingInventory = ref(false)

const loader = ref({
    "loading": true,
    "saving": false,
    "loaderText": "Loading..."
})

const inventoryForm = ref({
    "billNumber": "",
    "billDate": "",
    "hardwareDescription": "",
    "providerName": "",
    "providerTariff": "",
    "contractDate": "",
    "contractDuration": "",
    "handoverDate": "",
    "contractNumber": "",
    "providerCustomerNumber": "",
    "warrantyStartDate": "",
    "warrantyEndDate": "",
    "inventoryExternalLink": ""
})


// Computed Values

const darkmode = computed(() => { return mixinHelpers.getDarkmode() })
const activeAccountId = computed(() => { return getterHelpers.useStore().getters.getActiveAccountId })
const objectId = computed(() => { return useRouterStore().getObjectId || "" })
const context = computed(() => { return useRouterStore().context })
const activePage = computed(() => { return useRouterStore().activePage })
const device = computed(() => { return objectId.value ? products.mobileSecurity.windowsVpns.useStore?.().getObjectStoreObject(activeAccountId.value, objectId.value) : undefined })

const systemInfoMessage = computed(() => {
    return device?.value?.messages?.['vpn-system-info'] as VPNSystemInfoMessage | undefined
});

const hasConnections = computed(() => {
    let result: boolean = false
    const connectionGetMessage = device.value?.messages?.['vpn-connection-get']
    if (initialized.value &&
        connectionGetMessage &&
        Array.isArray(connectionGetMessage.connections) &&
        connectionGetMessage.connections.length > 0 &&
        isMessageTooOld(connectionGetMessage.timestamp, 7) == false
    ) result = true;

    return result
})

// Connection Tab
const connectionToColorMap = {
    "Error": "color-red",
    "Disconnected": "",
    "Connected": "color-green",
    "Connecting": "color-yellow"
}
const connectionToLabelMap: { [state: string]: (entry: Connection) => Label } = {
    "Error": (entry) => {
        return {
            "text": T("Error"),
            "title": T(entry.lastErrorText),
            "class": "bg-red",
            "icon": "fal fa-fw fa-exclamation-triangle",
        }
    },
    "Disconnected": (entry) => {
        return {
            "text": T("Disconnected"),
            "title": T("Last Contact:") + " " + getDate(entry.lastConnect, true),
            "class": "bg-grey",
            "icon": "fal fa-sign-out",
        }
    },
    "Connected": (entry) => {
        return {
            "text": T("Connected"),
            "title": T("Connected"),
            "class": "bg-green",
            "icon": "fal fa-sign-in",
        }
    },
    "Connecting": (entry) => {
        return {
            "text": T("Connecting"),
            "title": T("Connecting"),
            "class": "bg-yellow",
            "icon": "fal fa-wifi",
        }
    }
}
const connectionsTableOptions: TableEntryInfo[] = [
    {
        "text": T("Connection"),
        "property": "name",
        "displayType": "text",
        "iconsBefore": (entry: Connection) => {
            let colorClass = connectionToColorMap[entry.currentState as keyof typeof connectionToColorMap] ? connectionToColorMap[entry.currentState as keyof typeof connectionToColorMap] : "";

            return [{
                "class": "fal fa-laptop margin-xs-r margin-xs-l" + " " + colorClass,
                "title": T(entry.currentState)
            }]

        },
        "getValue": (entry: Connection) => {
            return entry.name
        },
        "labelsAfter": (entry: Connection) => {
            let result: Label[] = []

            if(connectionToLabelMap[entry.currentState]) {
                result.push(connectionToLabelMap[entry.currentState](entry))
            }

            return result
        },
        "subContent": {
            "displayOrder": ["subLabels"],
            "subLabels": (entry: Connection) => {
                let result: Label[] = []
                const flagToLabel: { [key: string]: Label | undefined } = {
                    "Active": undefined,
                    "Cloud": undefined,
                    "OTP deactivated": {
                        "class": "bg-grey color-white",
                        "text": "OTP deactivated",
                        "title": "OTP deactivated"
                    },
                    "Autostart": {
                        "class": "bg-green",
                        "icon": "fal fa-rotate-left",
                        "text": "",
                        "title": T("Verbindung enthält gespeicherte Benutzerdaten und Autostart ist eingeschaltet")
                    },
                    "CredentialsSaved": {
                        "class": "bg-green",
                        "icon": "user-check",
                        "text": "",
                        "title": T("Verbindung enthält gespeicherte Benutzerdaten")
                    },
                    "System": {
                        "class": "bg-red",
                        "text": "SYSTEM",
                        "title": T("Systemverbindung"),
                    },
                    "User": {
                        "class": "bg-purple",
                        "icon": "fal fa-user-check",
                        "text": "Userverbindung",
                        "title": T("Userverbindung")
                    },
                    "Wiregurad": {
                        "class": "bg-red",
                        "text": "WireGuard",
                        "title": T("Verbindung ist vom Typ Wireguard")
                    },
                    "SslVpn": {
                        "class": "bg-orange",
                        "text": "SSLVPN",
                        "title": T("Verbindung ist vom Typ SSLVPN")
                    },
                    "Inactive": {
                        "class": "bg-grey",
                        "text": T("Deactivated"),
                        "title": T("Verbindung ist deaktiviert")
                    },
                    "Otp": {
                        "class": "bg-green",
                        "icon": "fal fa-key",
                        "text": "",
                        "title": T("OTP-Dialog öffnet sich vor jedem Verbindungsversuch, um das OTP anzugeben (nur SSLVPN)")
                    },
                    "Favorite": {
                        "class": "bg-yellow",
                        "icon": "fal fa-star",
                        "text": "",
                        "title": T("Verbindung ist ein Favorit")
                    }, 
                    "Pin": {
                        "class": "bg-green",
                        "icon": "fal fa-lock",
                        "text": "",
                        "title": T("PIN für eine Wireguard-Verbindung wurde angelegt und die Verbindung ist gesperrt bis zur nächsten Eingabe des PINs"),
                    }
                }
                if (Array.isArray(entry.flags) && entry.flags.length > 0) {
                    entry.flags.forEach((flag) => {
                        if (flagToLabel[flag] != undefined) {
                            result.push(flagToLabel[flag])
                        }
                    })
                }
                return result
            }
        }
    },
    {
        "text": T("Actions"),
        "property": "actions",
        "displayType": "buttons",
        "width": 300,
        "getValue": (entry: Connection) => {
            return [new Button({
                "text": T("Diagnosis"),
                "title": T("Diagnosis"),
                "onClick": () => {
                    sendVpnMessage(activeAccountId.value, device.value?.id || "", 'connection-diagnose', entry.id)
                },
                "size": "sm",
                "icon": "fal fa-stethoscope",
                "loading": sendingMessages.connectionDiagnose,
                "disabled": sendingMessages.connectionDiagnose == true
            })]
        },

    },
]

// Messages
const sendingMessages = reactive({
    systemInfo: false,
    connectionGet: false,
    connectionDiagnose: false
})
const getVpnMessage = products.mobileSecurity.windowsVpns.getVpnMessage
const sendVpnMessage = products.mobileSecurity.windowsVpns.sendVpnMessage
const isMessageTooOld = products.mobileSecurity.windowsVpns.isMessageTooOld

// Operationslog
const loadingOperationsLog = ref(false)
const operationsLog = ref(<WindowsVpnJob[]>[])
const operationsLogTable: TableEntryInfo[] = [
    {
        'property': 'timestamp',
        'text': T('Time'),
        'displayType': 'text',
        getSortValue: (job: WindowsVpnJob) => {
            return job?.issued || job?.lastUpdate || ""
        },
        'getValue': (job: WindowsVpnJob) => {
            let date = job?.issued || job?.lastUpdate || ""
            return date != '' ? moment(date as number * 1000).format("DD.MM.YYYY HH:mm:ss") : date
        }
    },
    {
        'property': 'type',
        'text': T('Job'),
        'displayType': 'text',
        'getValue': (job: WindowsVpnJob) => {
            return job?.context || ""
        }
    },
    {
        'property': 'profile',
        'text': T('Profile'),
        'displayType': 'labels',
        'getValue': (job: WindowsVpnJob) => {
            return []
            /* return [
                {
                    "icon": "",
                    "text": "SomeProfile",
                    "title": "SomeProfile",
                    "onClick": () => {
                    }
                }
            ] */
        }
    },
    {
        'property': 'direction',
        'text': T('Direction'),
        'displayType': 'labels',
        'getValue': (job: WindowsVpnJob) => {
            return [
                {
                    "icon": job?.queue == 'in' ? "fal fa-arrow-left" : "fal fa-arrow-right",
                    "text": T(stringHelpers.capitalizeFirstLetter(job?.queue)),
                    "title": job?.queue == 'in' ? T('Message from device to server') : T('Message from server to device')
                }
            ]
        }
    },
    {
        'property': 'status',
        'text': T('Status'),
        'displayType': 'labels',
        'getValue': (job: WindowsVpnJob) => {
            return [
                {
                    "title": job?.statusText ? job?.statusText : T(stringHelpers.capitalizeFirstLetter(job?.status)),
                    "text": T(stringHelpers.capitalizeFirstLetter(job?.status)),
                    "class": job?.status == 'ERROR' ? 'bg-red' : job?.status == 'PENDING' ? 'bg-yellow' : '',
                    "icon": job?.status == 'ERROR' ? "fal fa-exclamation-triangle" : job?.status == 'PENDING' ? "fal fa-bell" : "fal fa-info",
                }
            ]
        }
    },
    {
        'property': 'statusText',
        'text': T('Info'),
        'displayType': 'text',
        'getValue': (job: WindowsVpnJob) => {
            return T(job?.statusText) || ""
        }
    },
    {
        'property': 'actions',
        'text': T('Actions'),
        'displayType': 'buttons',
        'getValue': (job: WindowsVpnJob) => {
            let thisbuttons: any = []
            if (job?.status == "PENDING") {
                thisbuttons.push(
                    {
                        "text": T("Revoke"),
                        "title": T("Revoke"),
                        "icon": "fal fas-fw fa-undo",
                        "onClick": async function () {
                            this.loading = true
                            await products.mobileSecurity.windowsVpns.revokeJob(activeAccountId.value, objectId.value, job.jobId)
                            await loadOperationsLog()
                            this.loading = false
                        },
                        "disabled": function () { },
                        "loading": function () { }
                    }
                )
            }
            return thisbuttons
        }
    }
]

const loadOperationsLog = async () => {
    loadingOperationsLog.value = true

    try {
        const result = await products.mobileSecurity.windowsVpns.getOperationsLogEntries(activeAccountId.value, objectId.value)
        if(Array.isArray(result)) operationsLog.value = result;
    }
    catch(e: unknown) {
        console.error(e)
    }

    loadingOperationsLog.value = false
}

//Inventory
const initInventoryform = () => {
        inventoryForm.value.billDate = device.value?.inventory?.billDate ? moment(parseInt(device.value?.inventory.billDate) * 1000).format("DD.MM.YYYY") : ""
        inventoryForm.value.billNumber = device.value?.inventory?.billNumber || ""
        inventoryForm.value.contractDate = device.value?.inventory?.contractDate ? moment(parseInt(device.value?.inventory.contractDate) * 1000).format("DD.MM.YYYY") : ""
        inventoryForm.value.contractDuration = device.value?.inventory?.contractDuration || ""
        inventoryForm.value.contractNumber = device.value?.inventory?.contractNumber || ""
        inventoryForm.value.handoverDate = device.value?.inventory?.handoverDate ? moment(parseInt(device.value?.inventory.handoverDate) * 1000).format("DD.MM.YYYY") : ""
        inventoryForm.value.hardwareDescription = device.value?.inventory?.hardwareDescription || ""
        inventoryForm.value.inventoryExternalLink = device.value?.inventory?.inventoryExternalLink || ""
        inventoryForm.value.providerCustomerNumber = device.value?.inventory?.providerCustomerNumber || ""
        inventoryForm.value.providerName = device.value?.inventory?.providerName || ""
        inventoryForm.value.providerTariff = device.value?.inventory?.providerTariff || ""
        inventoryForm.value.warrantyEndDate = device.value?.inventory?.warrantyEndDate ? moment(parseInt(device.value?.inventory.warrantyEndDate) * 1000).format("DD.MM.YYYY") : ""
        inventoryForm.value.warrantyStartDate = device.value?.inventory?.warrantyStartDate ? moment(parseInt(device.value?.inventory.warrantyStartDate) * 1000).format("DD.MM.YYYY") : ""
        inventoryLoaded.value = true
}

const saveInventory = async () => {
    savingInventory.value = true
    let properties: any = jsonHelpers.copyObject(inventoryForm.value)

    if (properties.billDate) {
        properties.billDate = moment(properties.billDate, "DD.MM.YYYY").format('X')
    }
    if (properties.contractDate) {
        properties.contractDate = moment(properties.contractDate, "DD.MM.YYYY").format('X')
    }
    if (properties.handoverDate) {
        properties.handoverDate = moment(properties.handoverDate, "DD.MM.YYYY").format('X')
    }
    if (properties.warrantyStartDate) {
        properties.warrantyStartDate = moment(properties.warrantyStartDate, "DD.MM.YYYY").format('X')
    }
    if (properties.warrantyEndDate) {
        properties.warrantyEndDate = moment(properties.warrantyEndDate, "DD.MM.YYYY").format('X')
    }
    if (properties.inventoryExternalLink && properties.inventoryExternalLink.indexOf('http://') == -1 && properties.inventoryExternalLink.indexOf('https://') == -1) {
        properties.inventoryExternalLink = 'http://' + properties.inventoryExternalLink
    }

    try {
        if (objectId.value) {
            await products.mobileSecurity.windowsVpns.queries.updateObjectPropertiesFromApi(activeAccountId.value, objectId.value,properties,undefined,undefined,undefined,"/properties")
            await products.mobileSecurity.windowsVpns.queries.getObjectFromApi(activeAccountId.value, objectId.value)
        }
    }
    catch (e: any) {
        console.error(e)
    }
    initInventoryform()
    savingInventory.value = false
}

const getInventoryLocationDialog = () => {
    let location = jsonHelpers.copyObject(device.value?.inventory?.inventoryLocation || {})
    dialogs.unifiedSecurity.setInventoryLocation(
        location,
        async (inventoryLocation, inventoryLocationDescription) => {
            if (!jsonHelpers.isObjectEmpty(inventoryLocation) && device.value?.id) {
                await products.mobileSecurity.windowsVpns.queries.updateObjectPropertiesFromApi(activeAccountId.value, device.value.id, { inventoryLocation: JSON.stringify(inventoryLocation) }, 'inventoryLocation', ["inventory"], undefined, "/properties")
                return true
            }
            else {
                return false
            }
        }
    )
}

// Other Functions
const formatBytes = numberHelpers.formatBytes
const getStoragePercentage = (used?: number, free?: number, size?: number): number => {
    let percenTage = 0;

    if(size == undefined && typeof used == "number" && typeof free == "number") size = used + free;
    if(typeof used == "number" && typeof size == "number") percenTage = (used / size) * 100;
    return percenTage;
}

const getTooltipForInterface = (thisInterface: { name: string, ips: string[] }): string => {
    let tootlTip: string = T("Name") + ": " + encodingHelpers.escapeHTML(thisInterface.name) + "<br><br>" + T("IPs:") + "<br>"
    thisInterface.ips.forEach((entry, index) => {
        tootlTip = tootlTip + (index + 1).toString() + ": " + encodingHelpers.escapeHTML(entry) + "<br>"
    })
    tootlTip = tootlTip.slice(0, -2)
    return tootlTip
}

const copyInterfaceToClipboard = (thisInterface: { name: string, ips: string[] }): void => {
    const clipboardHandler = new ClipboardHandler()
    let ips: string = ""
    thisInterface.ips.forEach((entry) => {
        ips = ips + encodingHelpers.escapeHTML(entry) + ", "
    })
    ips = ips.slice(0, -2)
    clipboardHandler.copyToClipboard(ips)
    frontendNotifications.addNotification(activeAccountId.value, {
        "accountId": activeAccountId.value,
        "content": {
            "body": {
                "content": T("Copied IP-adresses") + " " + ips + " " + T("to clipboard")
            },
            "title": {
                "text": T("Copied IP-adresses"),
                "icon": "fal fa-check"
            }
        },
        "highlightNew": true,
    })

}

const getInfoBoxText = () => {
    let inventoryStreet = device.value?.inventory?.inventoryLocation?.street
    let inventoryPostalCode = device.value?.inventory?.inventoryLocation?.postalCode
    let inventoryCity = device.value?.inventory?.inventoryLocation?.city
    const content = `
			<div> 
				${inventoryStreet ? ('<i class="fal fa-fw fa-road"></i>&nbsp;' + encodingHelpers.escapeHTML(inventoryStreet) + '<br>') : ''}
				${inventoryPostalCode ? ('<i class="fal fa-fw fa-globe"></i>&nbsp;' + encodingHelpers.escapeHTML(inventoryPostalCode) + '<br>') : ''}
				${inventoryCity ? encodingHelpers.escapeHTML(inventoryCity) : ''}
			<div>
		`
    return content
}

const isObjectEmpty = jsonHelpers.isObjectEmpty

const init = async (doRequests = true) => {
    loader.value.loading = true
    loader.value.loaderText = "Loading..."

    // do stuff
    if (objectId.value) {
        await products.mobileSecurity.windowsVpns.queries.getObjectFromApi(activeAccountId.value,objectId.value)
        initInventoryform()
        loadOperationsLog()
    }

    loader.value.loading = false
    initialized.value = true
}

const getDate = (timestamp: any, dateTime: boolean = false) => {
    let date: any = ""
    if (typeof timestamp == "number") {
        date = moment.unix(timestamp).format(dateTime ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY")
    }
    else {
        date = moment(timestamp).format(dateTime ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY")
    }
    return date;
}


onMounted(() => {
    init()
})

onUnmounted(() => {
    
})

</script>
<template>
    <div>
        <section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-if="initialized && device != undefined">
            <div class="row itemlist show-details view-grid">
                <div class="col-xs-24 col-print-24 col-lg-12  padding-xs item" style="z-index:999999">
                    <itemlist-item :item="device" :editable="true" :show-menu="false" :clickable="false"></itemlist-item>
                </div>
                <div class="col-xs-24 col-print-24 col-lg-12 padding-xs" style="min-height:250px">
                    <div class="box-shadow box-fullheight content-2">


                        <template
                            v-if="device.inventory && device.inventory.inventoryLocation != undefined && !isObjectEmpty(device.inventory.inventoryLocation)">
                            <h3 class="float-xs-left">{{ T('Inventory location') }}</h3>
                            <a v-on:click="getInventoryLocationDialog()" class="btn padding-xs-y-0 float-xs-right"
                                :class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'"
                                :title="T('Set inventory device location')">
                                <i class="fal fa-edit"></i>
                            </a>
                        </template>
                        <div class="clearfix"></div>
                        <template v-if="device
                        && device.inventory
                        && device.inventory.inventoryLocation
                        && device.inventory.inventoryLocation.latitude
                        && device.inventory.inventoryLocation.longitude
                    ">
                            <googleMap id="inventoryMap" :entries="[{
                            'position': {
                                'lat': device.inventory.inventoryLocation.latitude,
                                'lng': device.inventory.inventoryLocation.longitude
                            },
                            'infoHtml': getInfoBoxText()
                        }]" />
                        </template>
                        <template
                            v-if="!device.inventory || device.inventory.inventoryLocation != undefined && isObjectEmpty(device.inventory.inventoryLocation)">
                            <div :style="(darkmode == '1' ? 'background: rgba(0, 0, 0, 0.2);' : 'background: rgba(0, 0, 0, 0.08);')
                            + 'top: 16px;'
                            + 'height:calc(100% - 32px);'
                            + 'left: 16px;'
                            + 'right: 16px;'
                            + 'position: absolute;'
                        ">
                                <div style="position:absolute;top:50%;transform: translate(0, -50%); width: 100%;"
                                    class="text-center">
                                    <h3 style="
                            width: 100%;
                            text-align: center;
                        ">
                                        {{ T('Inventory location is not set') }}
                                    </h3>
                                    <p>{{ T('Click the following link to set the inventory location:') }} <br>
                                        <br><a class="btn" :title="T('Set inventory device location')"
                                            v-on:click="getInventoryLocationDialog()"> <i
                                                class="fal fa-fw fa-edit"></i>
                                            {{ T('Set location') }}</a>
                                    </p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-24 padding-xs">
                    <div class="box-shadow">

                        <Tabs ref="tabs" :tabs="[
                        {
                            'text': T('Overview'),
                            'tooltip': T('Overview'),
                            'id': 'overview',
                            'icon': 'fal fa-laptop'
                        },
                        {
                            'text': T('Connections'),
                            'tooltip': T('Connections'),
                            'id': 'connections',
                            'icon': 'fal fa-laptop',
                        },
                        {
                            'text': T('Operations log'),
                            'tooltip': T('Operations log'),
                            'id': 'operationsLog',
                            'icon': 'fal fa-list'
                        },
                        {
                            'text': T('Inventory'),
                            'tooltip': T('Inventory'),
                            'id': 'inventory',
                            'icon': 'fal fa-info'
                        }
                    ]">
                            <template v-slot:overview>
                                <template v-if="systemInfoMessage">
                                    <div class="row padding-xs-y">
                                        <div class="col-xs-24 col-lg-21">
                                            <small>{{T('Last update:')}} <span>{{ getVpnMessage(device, 'vpn-connection-get') ? getDate(getVpnMessage(device, 'vpn-connection-get')?.timestamp,true) : T('Unknown') }}</span></small>
                                        </div>
                                        <div class="col-xs-24 col-lg-3 text-right">
                                            <buttonComponent
                                                id="refreshVpnInfo"
                                                :buttonOptions="{
                                                    loading: sendingMessages.systemInfo,
                                                    disabled: sendingMessages.systemInfo == true,
                                                    title: T('Refresh'),
                                                    text: undefined,
                                                    icon: 'fal fa-sync',
                                                    onClick: async () => { 
                                                        sendingMessages.systemInfo = true;
                                                        await sendVpnMessage(activeAccountId, device?.id || '', 'system-info'); 
                                                        sendingMessages.systemInfo = false;
                                                    }
                                                }"
                                            ></buttonComponent>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-24 col-lg-15">
                        
                                            <h4>{{ T('Arbeitsspeicherinformationen') }}</h4>
                                            <template v-if="systemInfoMessage && systemInfoMessage?.system?.ram">
                                                <div 
                                                    class="progress-bar progress-bar-sm color-red" :data-progress="'0'"
                                                    :class="{
                                                        'color-red':getStoragePercentage(systemInfoMessage.system.ram.used, systemInfoMessage.system.ram.free, systemInfoMessage.system.ram.size) >= 90,
                                                        'color-yellow':getStoragePercentage(systemInfoMessage.system.ram.used, systemInfoMessage.system.ram.free, systemInfoMessage.system.ram.size) < 90 && getStoragePercentage(systemInfoMessage.system.ram.used, systemInfoMessage.system.ram.free, systemInfoMessage.system.ram.size) >= 70,
                                                        'color-green':getStoragePercentage(systemInfoMessage.system.ram.used, systemInfoMessage.system.ram.free, systemInfoMessage.system.ram.size) < 70
                                                    }"
                                                >
                                                    <div
                                                        style="position:absolute;border-bottom:1em solid; top:0;"
                                                        :style="'width:'+getStoragePercentage(systemInfoMessage.system.ram.used, systemInfoMessage.system.ram.free, systemInfoMessage.system.ram.size) + '%'"
                                                    >
                                                    </div>
                                                </div>
                                                <span>
                                                    {{T('Storage used')}}:
                                                    {{
                                                        formatBytes(systemInfoMessage.system.ram.used, 2).value +
                                                        formatBytes(systemInfoMessage.system.ram.used, 2).unit
                                                    }}
                                                    {{
                                                        T('of')
                                                    }}
                                                    {{
                                                        formatBytes(systemInfoMessage.system.ram.size ?systemInfoMessage.system.ram.size : (systemInfoMessage.system.ram.free + systemInfoMessage.system.ram.used), 2).value + formatBytes(systemInfoMessage.system.ram.size ?systemInfoMessage.system.ram.size : (systemInfoMessage.system.ram.free + systemInfoMessage.system.ram.used), 2).unit
                                                    }}
                                                </span>
                                            </template>

                                            <h4>{{ T('CPU') }}</h4>
                                            <template v-if="systemInfoMessage && systemInfoMessage?.system?.cpu">
                                                <div 
                                                    class="progress-bar progress-bar-sm color-red" :data-progress="'0'"
                                                    :class="{
                                                        'color-red': systemInfoMessage.system.cpu.utilization >= 90,
                                                        'color-yellow':systemInfoMessage.system.cpu.utilization < 90 && systemInfoMessage.system.cpu.utilization >= 70,
                                                        'color-green': systemInfoMessage.system.cpu.utilization < 70
                                                    }"
                                                >
                                                    <div
                                                        style="position:absolute;border-bottom:1em solid; top:0;"
                                                        :style="'width:'+ systemInfoMessage.system.cpu.utilization + '%'"
                                                    >
                                                    </div>
                                                </div>
                                                <span>
                                                    {{ T('CPU Auslastung:') }} {{ systemInfoMessage.system.cpu.utilization }}% <small>(Cores: {{ systemInfoMessage.system.cpu.cores }})</small>
                                                </span>
                                            </template>

                                            <h4>{{ T('Speicher') }}</h4>
                                            <template v-if="systemInfoMessage && systemInfoMessage?.system?.hdd">
                                                <template v-for="hdd of systemInfoMessage.system.hdd">
                                                    {{ T("Laufwerk ") + hdd.name.replace(":\\", ":") }}
                                                    <div 
                                                    class="progress-bar progress-bar-sm color-red" :data-progress="'0'"
                                                    :class="{
                                                        'color-red':getStoragePercentage(hdd.used, hdd.free, hdd.size) >= 90,
                                                        'color-yellow':getStoragePercentage(hdd.used, hdd.free, hdd.size) < 90 && getStoragePercentage(hdd.used, hdd.free, hdd.size) >= 70,
                                                        'color-green':getStoragePercentage(hdd.used, hdd.free, hdd.size) < 70
                                                    }"
                                                    >
                                                        <div
                                                            style="position:absolute;border-bottom:1em solid; top:0;"
                                                            :style="'width:'+getStoragePercentage(hdd.used, hdd.free, hdd.size) + '%'"
                                                        >
                                                        </div>
                                                    </div>
                                                    <span>
                                                        {{T('Storage used')}}:
                                                        {{
                                                            formatBytes(hdd.used, 2).value +
                                                            formatBytes(hdd.used, 2).unit
                                                        }}
                                                        {{
                                                            T('of')
                                                        }}
                                                        {{
                                                            formatBytes(hdd.size ?hdd.size : (hdd.free + hdd.used), 2).value + formatBytes(hdd.size ?hdd.size : (hdd.free + hdd.used), 2).unit
                                                        }}
                                                    </span>
                                                </template>
                                            </template>

                                            <h4>{{ T('Interfaces') }}</h4>
                                            <template v-if="systemInfoMessage && systemInfoMessage?.system?.interfaces">
                                                <Tooltip v-for="thisInterface of systemInfoMessage.system.interfaces" 
                                                    class="label color-white margin-xs-r margin-xs-t" 
                                                    :is-tag="'span'" :tooltip="getTooltipForInterface(thisInterface)" 
                                                    :html-tooltip="true"
                                                    @click="copyInterfaceToClipboard(thisInterface)"
                                                    :style="'cursor:pointer;'"
                                                >
                                                    <i class="fal fa-copy"></i>
                                                    {{ thisInterface.name }}

                                                </Tooltip>
                                            </template>

                                        </div>
                                    </div>
                                    <template v-if="config.devMode">
                                        <br>
                                        <br>
                                        <pre>
                                            {{ systemInfoMessage }}
                                        </pre>

                                    </template>
                                </template>
                                
                                <div class="margin-xs-t-1 margin-xs-b-1" v-else>
                                    <div class="col-xs-24 col-lg-21">
                                        {{ T('There is no system info for this VPN') }}
                                        <br>
                                        <br>
                                        <br>
                                    </div>
                                    <div class="col-xs-24 col-lg-3 text-right">
                                        <buttonComponent
                                            id="refreshVpnInfo"
                                            :buttonOptions="{
                                                loading: sendingMessages.systemInfo,
                                                disabled: sendingMessages.systemInfo == true,
                                                title: T('Refresh'),
                                                text: undefined,
                                                icon: 'fal fa-sync',
                                                onClick: async () => { 
                                                    sendingMessages.systemInfo = true;
                                                    await sendVpnMessage(activeAccountId, device?.id || '', 'system-info'); 
                                                    sendingMessages.systemInfo = false;
                                                },
                                            }"
                                        ></buttonComponent>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:connections>
                                <template v-if="hasConnections">
                                    <div class="row padding-xs-y">
                                        <div class="col-xs-24 col-lg-21">
                                            {{ T('The currently configured connections of the VPN client are displayed here.') }} 
                                            {{ T('In addition to the current settings and the status of these connections, the status at the time of the query is also displayed.') }}
                                            {{ T('The connections are sent automatically when the VPN client is opened and can be queried again if required.') }}
                                            <br>
                                            <small>{{T('Last update:')}} <span>{{ getVpnMessage(device, 'vpn-connection-get') ? getDate(getVpnMessage(device, 'vpn-connection-get')?.timestamp,true) : T('Unknown') }}</span></small>
                                        </div>
                                        <div class="col-xs-24 col-lg-3 text-right">
                                            <buttonComponent
                                                id="refreshVpnInfo"
                                                :buttonOptions="{
                                                    loading: sendingMessages.connectionGet,
                                                    disabled: sendingMessages.connectionGet == true,
                                                    title: T('Refresh'),
                                                    text: undefined,
                                                    icon: 'fal fa-sync',
                                                    onClick: async () => { 
                                                        sendingMessages.connectionGet = true;
                                                        await sendVpnMessage(activeAccountId, device?.id || '', 'connection-get'); 
                                                        sendingMessages.connectionGet = false;
                                                    }
                                                }"
                                            ></buttonComponent>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <tableNext :selectableColumns="connectionsTableOptions" :objectList="((getVpnMessage(device, 'vpn-connection-get') as VPNConnectionGetMessage)?.connections || [])"></tableNext>
                                    </div>
                                </template>
                                <div class="margin-xs-t-1 margin-xs-b-1" v-else>
                                    <div class="col-xs-24 col-lg-21">
                                        {{ T('There are currently no connections for this VPN') }}
                                        <br>
                                        <br>
                                        <br>
                                    </div>
                                    <div class="col-xs-24 col-lg-3 text-right">
                                        <buttonComponent
                                            id="refreshVpnInfo"
                                            :buttonOptions="{
                                                loading: sendingMessages.connectionGet,
                                                disabled: sendingMessages.connectionGet == true,
                                                title: T('Refresh'),
                                                text: undefined,
                                                icon: 'fal fa-sync',
                                                onClick: async () => { 
                                                    sendingMessages.connectionGet = true;
                                                    await sendVpnMessage(activeAccountId, device?.id || '', 'connection-get'); 
                                                    sendingMessages.connectionGet = false;
                                                },
                                            }"
                                        ></buttonComponent>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:operationsLog>

                                <div class="text-right padding-xs-y">
									<buttonComponent
										id="refreshOperations"
										:buttonOptions="{
											loading: loadingOperationsLog,
											disabled: loadingOperationsLog == true,
											title: T('Refresh'),
											text: undefined,
											icon: 'fal fa-sync',
											onClick: loadOperationsLog,
										}"
									></buttonComponent>
								</div>

								<tableNext 
									:selectable-columns="operationsLogTable"
									:object-list="operationsLog"
									:initial-sort-property="'timestamp'"
									:initial-sort-direction="'DESC'"
									:has-options="false"
									:is-filterable="false"
									:isLoading="loadingOperationsLog"
								></tableNext>
                            
                            
                            </template>
                            <template v-slot:inventory>
                                <div class="row">
                                    <div class="col-xs-24">
                                        <div class="row">
                                            <div class="col-xs-24 padding-xs-x">
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="billNumber">
                                                            {{T('Bill number')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="billNumber" type="text"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Bill number')"
                                                                v-model="inventoryForm.billNumber"
                                                                data-validation-property="/billNumber">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Bill number')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="billDate">
                                                            {{T('Bill date')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input-date v-model="inventoryForm.billDate"
                                                                :value="inventoryForm.billDate"
                                                                :placeholder="T('Select a date')" id="billDate" />
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Bill date')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="warrantyStartDate">
                                                            {{T('Warranty starts on')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input-date v-model="inventoryForm.warrantyStartDate"
                                                                :value="inventoryForm.warrantyStartDate"
                                                                :placeholder="T('Select a date')"
                                                                id="warrantyStartDate" />
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Warranty start')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="warrantyEndDate">
                                                            {{T('Warranty ends on')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input-date v-model="inventoryForm.warrantyEndDate"
                                                                :value="inventoryForm.warrantyEndDate"
                                                                :placeholder="T('Select a date')"
                                                                id="warrantyEndDate" />
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Warranty end')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="inventoryExternalLink">
                                                            {{T('External inventory link')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="inventoryExternalLink" type="text"
                                                                class="form-control user-selection"
                                                                :placeholder="T('URL')"
                                                                v-model="inventoryForm.inventoryExternalLink"
                                                                data-validation-property="/inventoryExternalLink">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Set link to inventory software')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="hardwareDescription">
                                                            {{T('Description')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <textarea id="hardwareDescription"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Description')"
                                                                v-model="inventoryForm.hardwareDescription"
                                                                data-validation-property="/hardwareDescription"></textarea>
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Device description')}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-24 padding-xs-t-4">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <h4>{{T('Contract')}}</h4>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="margin-xs-y-2">
                                    <div class="col-xs-24">
                                        <div class="row">
                                            <div class="col-xs-24 padding-xs-x">
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="providerName">
                                                            {{T('Provider')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="providerName" type="text"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Provider')"
                                                                v-model="inventoryForm.providerName"
                                                                data-validation-property="/providerName">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('The name of the provider')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="providerTariff">
                                                            {{T('Tariff')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="providerTariff" type="text"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Tariff')"
                                                                v-model="inventoryForm.providerTariff"
                                                                data-validation-property="/providerTariff">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Tariff')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="providerCustomerNumber">
                                                            {{T('Customer number')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="providerCustomerNumber" type="text"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Customer number')"
                                                                v-model="inventoryForm.providerCustomerNumber"
                                                                data-validation-property="/providerCustomerNumber">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Customer number')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="contractNumber">
                                                            {{T('Contract number')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="contractNumber" type="text"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Contract number')"
                                                                v-model="inventoryForm.contractNumber"
                                                                data-validation-property="/contractNumber">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Contract number')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="contractDate">
                                                            {{T('Start of contract')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input-date v-model="inventoryForm.contractDate"
                                                                :value="inventoryForm.contractDate"
                                                                :placeholder="T('Select a date')"
                                                                id="contractDate" />
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Start of contract')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="contractDuration">
                                                            {{T('Contract term')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input id="contractDuration" type="number" min="0"
                                                                class="form-control user-selection"
                                                                :placeholder="T('Months')"
                                                                v-model="inventoryForm.contractDuration"
                                                                data-validation-property="/contractDuration">
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Contract term')}} ({{T('In months')}})
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row form-group validation-base border-bottom padding-xs-y">
                                                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                        <label class="control-label" for="handoverDate">
                                                            {{T('Handover date')}}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                        <label class="form-field margin-xs-b-0">
                                                            <input-date v-model="inventoryForm.handoverDate"
                                                                :value="inventoryForm.handoverDate"
                                                                :placeholder="T('Select a date')"
                                                                id="handoverDate" />
                                                        </label>
                                                    </div>
                                                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                        <p class="input-description">
                                                            {{T('Handover date')}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <button
                                                    class="btn margin-xs-b-0 margin-xs-t-2 btn-loader twist-in float-xs-right"
                                                    v-on:click="saveInventory()"
                                                    :class="{ 'btn-loading': savingInventory == true }"
                                                    :disabled="savingInventory == true || null" id="saveButton"
                                                    title="Save" type="submit">
                                                    <span class="animate">
                                                        <loader v-if="savingInventory == true"
                                                            style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);">
                                                        </loader>
                                                    </span>
                                                    <span><i class="fal fa-save"></i> <span
                                                            class="display-xs-none display-lg-inline">{{T('Save')}}</span></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

                        </Tabs>

                    </div>
                </div>
            </div>

        </section>
        <template v-else>
            <p class=" text-center padding-xs-t-4 text-size-4 padding-xs-b-0 text-center">
                <loaderComponent class="color-red"></loaderComponent>
            </p>
            <p class="text-center">
                {{ T(loader.loaderText) }}
            </p>
        </template>
    </div>
</template>