<script setup lang="ts">
    import { defineComponent, onMounted, getCurrentInstance, readonly, ref, nextTick } from 'vue'
    import nodeHeader from './unified-network-drawflow-node-header.vue'
    const el = ref(<any>null);
    const nodeId = ref(<number>0);
    let df : any = ref(null)
    const dataNode = ref(<any>{});

    df = getCurrentInstance()?.appContext.config.globalProperties.$df.value;

    const generateUUID = /* später: stringHelpers.generateUUID()*/ function () {
        let u: string = (new Date()).getTime().toString(16) +
            Math.random().toString(16).substring(2) + Math.random().toString(16).substring(2);
        var guid = u.substr(0, 8) + '-' + u.substr(8, 4) + '-4' + u.substr(12, 3) + '-8' +
            u.substr(15, 3) + '-' + u.substr(18, 12);
        return guid
    }
    const uuid = generateUUID()
    const serial = generateUUID().replaceAll("-","").slice(0,12)
    const connection = function() { 
        return Math.random() > 0.5
    }() 
    onMounted(async () => {
        await nextTick()
        nodeId.value = el.value.parentElement.parentElement.id.slice(5)
        dataNode.value = df.getNodeFromId(nodeId.value)

        console.log(df)
        console.log(dataNode)
        
    });
</script>
<template>
    <div ref="el" class="box">
        <nodeHeader 
            :title="dataNode?.data?.title"
            :icon="dataNode.class == 'client' ? 'fal fa-server' : 'fal fa-server'"
        />
        <div class="box-body details">
            <div class="text-smaller">
                <div class="data-row">
                    <div class="data-key text-semibold">
                        <i class="fal fa-hashtag"></i> ID
                    </div>
                    <div class="data-value">
                        {{ uuid }}
                    </div>
                </div>
                <div class="data-row">
                    <div class="data-key text-semibold">
                        <i class="fal fa-hashtag"></i> Serial
                    </div>
                    <div class="data-value">
                        {{ serial }}
                    </div>
                </div>
                <div class="data-row">
                    <div class="data-key text-semibold">
                        <i class="fal fa-power-off"></i> Connection
                    </div>
                    <div class="data-value">
                        
                        <span class="label" :class="{'bg-red': !connection, 'bg-green': connection }"> {{ connection ? 'Online' : 'Offline' }}</span>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>