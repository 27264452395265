<script setup lang="ts">
	import jsonHelpers from "../../helpers/helpers.json";
	import apis from "../../classes/apis";
	import { computed, nextTick, onMounted, ref } from "vue";
	import { T } from "@/classes/i18n";
	import moment from "moment";
	import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store";
	import router from "@/router/router";
	import $ from "jquery";
	import requestHandler from "@/queries/requests";
	import loaderComponent from "../components/loader.vue";
	import table2Component from "../components/table2";
	import itemlistItemComponent from "../components/itemlist-item.vue";
	import tenantHelpers from "@/helpers/helpers.tenants";
	import numberHelpers from "@/helpers/helpers.numbers";
	import dialogs from "@/dialogs/dialogs";
	import timeHelpers from "@/helpers/helpers.time";
	import googleMapComponent from "../components/map";
	import stringHelpers from "@/helpers/helpers.strings";
	import encodingHelpers from "@/helpers/helpers.encoding";
	import useRouterStore from "@/router/routerStore";
	import products from "@/classes/objectTypes";
	import type { EnterpriseDevice } from "@/classes/objectTypes/mobileSecurity/android/enterpriseDevices";
	import { useVue } from "@/app";
import type { AndroidDeviceActionStates } from "@/classes/objectTypes/mobileSecurity/android/androidDevices";
import mixinHelpers from "@/helpers/helpers.mixins";

	const loader = loaderComponent
	const googleMap = googleMapComponent
	const itemlistItem = itemlistItemComponent
	const table2 = table2Component


	declare var datepicker: any

	const activeAccountId = computed(() => { return useStore().getters.getActiveAccountId })
	const objectId = computed(() => { return useRouterStore().getObjectId })
	
	const device = computed(() => {
		return products.mobileSecurity.androidDevices.useStore?.().getObjectStoreObject(activeAccountId.value,objectId.value || "") as EnterpriseDevice || undefined
	})

	const policiyOptions = computed(() => {
		return products.mobileSecurity.androidProfiles.useStore?.().getObjectStoreObjects(activeAccountId.value)?.map((profile) => {
			return {
				'id': profile.name || "null",
				'text': profile.name?.split('/')[3] || "null"
			}
		}) || []
	})

	const darkmode = computed(() => {
		return mixinHelpers.getDarkmode()
	})
	const policyLoading = ref(<boolean>false)
	const thisDevicePolicyName = computed({
		get:() => { 
			return device.value.policyName || ""
		},
		set: async (val:string) => { 
			policyLoading.value = true
			products.mobileSecurity.androidDevices.queries.updateObjectPropertiesFromApi(activeAccountId.value,device.value.id || "", { policyName:val }, 'policyName', undefined, undefined, "?updateMask[]=policyName") 
			policyLoading.value = false
		}
	})
	const refreshCounter = ref(<number>0)
	const activeTab = ref(<string>"overview")
	const initialized = ref(<boolean>false)
	const changingDeviceState = ref(<boolean>false)

	const deviceActionStates = ref(<AndroidDeviceActionStates>{
		"LOCK": false,
		"REBOOT": false,
		"RESET_PASSWORD": false,
		"LOCATION": false,
		"RELINQUISH_OWNERSHIP": false
	})
	const deviceLocation = ref({
		"loading": <boolean>false,
		"long": <any>undefined,
		"lat": <any>undefined,
		"errors": <Array<string>>[],
		"timestamp": <any>undefined
	})
	
	const applicationReportTableData = ref({
		"loading":<boolean>true,
		"table":{
			"titles": [
				{
					"id": "number",
					"text": "#",
					"sortable": true,
					"width": 60
				},
				{
					"id": "name",
					"text": T("Name"),
					"sortable": true
				},
				{
					"id": "packageName",
					"text": T("Packagename"),
					"sortable": true
				},
				{
					"id": "version",
					"text": "Version",
					"sortable": true
				},
				{
					"id": "status",
					"text": "Status",
					"sortable": true
				},
				{
					"id": "info",
					"text": "Info",
					"sortable": true
				}
			],
			"rows":<any[]>[]
		}
	})
	const nonComplianceTableData = ref({
		"loading": <boolean>false,
		"table": {
			"titles": [
				{
					"id": "number",
					"text": "#",
					"sortable": true,
					"width": 60
				},
				{
					"id": "setting",
					"text": T("Setting"),
					"sortable": true
				},
				{
					"id": "nonComplianceReason",
					"text": T("Non compliance reason"),
					"sortable": false
				},
				{
					"id": "packageName",
					"text": T("Packagename"),
					"sortable": true
				},
				{
					"id": "path",
					"text": T("Path"),
					"sortable": true
				},
				{
					"id": "value",
					"text": T("Value"),
					"sortable": false
				},
				{
					"id": "installationFailureReason",
					"text": T("Installation failure reason"),
					"sortable": false
				}
			],
			"rows": <any[]> []
		}
	})

	const enrollmentTokenDataAsJSON = computed(() => {
		return device.value.enrollmentTokenData && device.value.enrollmentTokenData?.indexOf("{") != -1 ? JSON.parse(device.value.enrollmentTokenData) : device.value.enrollmentTokenData
	})


	const hasLocationPlugin = () => {
		return device.value?.applicationReports?.some((applicationReport) => { return applicationReport.packageName == 'de.securepoint.emm.toolbox' }) 
		&& device.value?.applicationReports?.some((applicationReport) => { return applicationReport.packageName == 'de.securepoint.emm.toolbox.location' })
	}


	const getPolicyName = (title: string) => {
		return title.replace(/\^(.*)/, " ($1)")
	}

	const getInventoryLocationDialog = () => {
		let location = jsonHelpers.copyObject(device.value?.inventory?.inventoryLocation || {})
		dialogs.unifiedSecurity.setInventoryLocation(
			location,
			async (inventoryLocation, inventoryLocationDescription) => {
				if (!jsonHelpers.isObjectEmpty(inventoryLocation)) {
					await products.mobileSecurity.androidDevices.queries.updateObjectPropertiesFromApi(activeAccountId.value, device.value.id, { inventoryLocation: JSON.stringify(inventoryLocation) }, 'inventoryLocation', ["inventory"], undefined, "/properties?updateMask[]=inventoryLocation")
					return true
				}
				else {
					return false
				}
			},
			device.value?.inventory?.inventoryLocationDescription
		)
	}

	const init = async () => {
		try {
			if (!activeAccountId.value) {
				throw "Missing accountId"
			}
			if (!objectId.value) {
				throw "Missing objectId"
			}
			await products.mobileSecurity.androidDevices.queries.getObjectFromApi(activeAccountId.value || "", objectId.value || "",undefined)
			await products.mobileSecurity.androidProfiles?.queries.getObjectsFromApi(activeAccountId.value || "", undefined, [{ 'property': "fields", 'value': '*(name)' }])
			await products.mobileSecurity.androidDevices.queries.getObjectsFromApi(activeAccountId.value || "", undefined,[{ "property":"zerotouchOnly", "value":true }])

			if (tenantHelpers.hasFunctionality(activeAccountId.value, 'zerotouch')) {
				await useStore().dispatch(ActionTypes.getObjectInfos, {
					"accountId": activeAccountId.value,
					"objectTypes": ['zeroTouchDevices']
				})
			}

			initialized.value = true

			nextTick(function () {
				getApplicationReportTableData()
				getNonComplianceTableData()
			})
		}
		catch (e: any) {
			if (e.status == 404) {
				router.navigate('404-' + objectId.value)
			}
		}
	}

	const toggleDeviceState = async() => {
		changingDeviceState.value = true
		try {
			if (device.value.state == "ACTIVE") {
				await products.mobileSecurity.androidDevices.queries.updateObjectPropertiesFromApi(activeAccountId.value, device.value.id, { state: "DISABLED" }, 'state', undefined, undefined, "?updateMask[]=state")
			}
			else {
				await products.mobileSecurity.androidDevices.queries.updateObjectPropertiesFromApi(activeAccountId.value, device.value.id, { state: "ACTIVE" }, 'state', undefined, undefined, "?updateMask[]=state")
			}
		}
		catch (e: any) {
			changingDeviceState.value = false
			console.error(e)
		}
		changingDeviceState.value = false
	}

	const getLocationError = (errorMsg: string) => {
		let errorMap: any = {
			"timeout": "Timeout: The device did not respond",
			"legal_not_accepted": "Terms and conditions or privacy policy not accepted",
			"permission_denied": "Location authorization is missing",
			"gps_disabled": "GPS / location is system-wide deactivated",
			"could_not_compute_location_was_null": "Android reported a location error",
			"could_not_compute_cancelled_by_android": "Android reported a location error",
			"could_not_compute_error_occurred": "Android reported a location error"
		}
		return errorMap[errorMsg] || "Unknown Error"
	}

	const waitForLocation = async() => {
		deviceLocation.value.loading = true
		useStore().commit(MutationTypes.addSubscriptionHook, {
			"accountId": activeAccountId.value,
			"hookKey": "locationMessage",
			"hookFunction": async (message: any) => {

				if (message.topic == "/device/located" && message.data?.name.split('/')[3] == device.value.id) {
					let location = await getLocation()
					if (location?.longitude != undefined && location?.latitude != undefined && location?.errors != undefined) {
						deviceLocation.value.long = location.longitude
						deviceLocation.value.lat = location.latitude
						deviceLocation.value.errors = location.errors
						deviceLocation.value.loading = false
						deviceLocation.value.timestamp = message.timestamp
					}
					useStore().commit(MutationTypes.deleteSubscriptionHook, {
						"accountId": activeAccountId.value,
						"hookKey": "locationMessage"
					})
				}
			}
		})


		setTimeout(() => {
			if (deviceLocation.value.loading) {
				useStore().commit(MutationTypes.deleteSubscriptionHook, {
					"accountId": activeAccountId.value,
					"hookKey": "locationMessage"
				})
				deviceLocation.value.loading = false
				deviceLocation.value.errors = ["timeout"]
			}
		}, 60000)
	}
	
	const getLocation = async() => {
		let location
		try {
			location = await requestHandler.request("GET", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('androidEnterprise') + "/tenants/" + tenantHelpers.getTenantDomain(activeAccountId.value) + "/android/emm/enterprise/devices/" + objectId.value + "/location")
		}
		catch (e: any) {
			if (e.status == 404) {
			}
			else {
				console.error(e)
			}
		}
		return apis.parseApiResponse(location)
	}

	const getNonComplianceTableData = async() => {
		let nonComplianceDetailsTableData: any = []
		nonComplianceTableData.value.loading = true
		nonComplianceTableData.value.table.rows = device.value.nonComplianceDetails?.map((nonCompliance, nonComplianceIndex) => {
			return {
				data: {
					"number": nonComplianceIndex + 1,
					"setting": T(nonCompliance.settingName) || '',
					"nonComplianceReason": T(nonCompliance.nonComplianceReason) || '',
					"packageName": nonCompliance.packageName || '',
					"path": nonCompliance.fieldPath || '',
					"value": nonCompliance.currentValue || '',
					"installationFailureReason": T(nonCompliance.installationFailureReason) || ''
				}
			}
		}) || []
		nonComplianceTableData.value.loading = false
	}

	const getApplicationReportTableData = async() => {

		applicationReportTableData.value.loading = true
		if ((device.value.applicationReports?.length || 0) > 0) {
			applicationReportTableData.value.table.rows = device.value.applicationReports?.map((application: any, index) => {
				return {
					data: {
						"number": index + 1,
						"name": T(application.displayName) || '',
						"packageName": application.packageName || '',
						"version": application.versionName || '',
						"status": application.state || '',
						"info": {
							"buttons": [
								application.keyedAppStates ? {
									"text": "Keyed app states",
									"title": "App states",
									"icon": "fal fa-fw fa-info",
									"onClick": async function () {

										if (application.keyedAppStates) {
											let tableData: any = {
												"loading": false,
												"titles": [
													{
														"id": "severity",
														"text": "Severity",
														"width": 110,
														"sortable": true
													},
													{
														"id": "lastUpdate",
														"text": "Last update",
														"sortable": true
													},
													{
														"id": "key",
														"text": "Key",
														"sortable": true
													},
													{
														"id": "message",
														"text": "Message",
														"sortable": false
													},
													{
														"id": "data",
														"text": "Data",
														"sortable": true
													},
												],
												"rows": (application.keyedAppStates || []).map((appState: any) => {
													return {
														data: {
															"severity": {
																"labels": [
																	{
																		"title": T(stringHelpers.capitalizeFirstLetter(appState.severity)),
																		"text": T(stringHelpers.capitalizeFirstLetter(appState.severity)),
																		"icon": appState.severity == 'ERROR' ? 'fal fa-exclamation-triangle' : 'fal fa-info-circle',
																		"class": appState.severity == 'ERROR' ? 'bg-red color-white' : 'bg-blue color-white',
																	}
																]
															},
															"lastUpdate": moment(appState.lastUpdateTime).format('DD.MM.YY, HH:mm:ss'),
															"key": encodingHelpers.escapeHTML(appState.key),
															"message": encodingHelpers.escapeHTML(appState.message),
															"data": encodingHelpers.escapeHTML(appState.data)
														}
													}
												})
											}
											let newModal: any = {
												"id": "keyedAppStates",
												"abortable": true,
												"content": {
													"title": {
														"text": T("Keyed app states")
													},
													"body": {
														"use": true,
														"content": undefined,
														"component": "key-app-states",
														"properties": {
															tableData: tableData
														}
													}
												},
												"buttons": [
													{
														"text": T("Close"),
														"icon": "fal fa-times",
														"onClick": async function () {
															useStore().commit(MutationTypes.removeModal,{accountId:activeAccountId.value})
														},
														"align": "right",
														"loading": false
													}
												]
											}
											useVue()?.$refs.modals.addModal(newModal)
										}
									},
									"disabled": function () {
										let result: boolean = (application.keyedAppStates || []).length == 0
										return result
									}
								} : null
							]
						}
					}
				}
			}) || []
		}
		applicationReportTableData.value.loading = false
	}

	const getInfoBoxText = () => {
		let inventoryStreet = device.value?.inventory?.inventoryLocation?.street
		let inventoryPostalCode = device.value?.inventory?.inventoryLocation?.postalCode
		let inventoryCity = device.value?.inventory?.inventoryLocation?.city
		const content = `
			<div> 
				${inventoryStreet ? ('<i class="fal fa-fw fa-road"></i>&nbsp;' + encodingHelpers.escapeHTML(inventoryStreet) + '<br>') : ''}
				${inventoryPostalCode ? ('<i class="fal fa-fw fa-globe"></i>&nbsp;' + encodingHelpers.escapeHTML(inventoryPostalCode) + '<br>') : ''}
				${inventoryCity ? encodingHelpers.escapeHTML(inventoryCity) : ''}
			<div>
		`
		return content
	}

	const execDeviceAction = products.mobileSecurity.androidDevices.execDeviceAction
	const isObjectEmpty = jsonHelpers.isObjectEmpty
	onMounted(() => {
		init()
	})



</script>
<template>
	<section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-if="!initialized">
		<div class="loaderbox text-center">
			<loader
				class="color-red"
				style="font-size:6em;"
			></loader>
		</div>
	</section>
	<section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-else-if="initialized">
		<div class="row itemlist show-details view-grid">
			<div class="col-xs-24 col-print-24 col-lg-12  padding-xs item" style="z-index:999999">
				<itemlistItemComponent
					:item="device"
					:index="1"
					:showInfo="true"
					key="itemlistItem"
					ref="itemlistItem"
					:showMenu="false"
					:clickable="false"
					:refreshCounterProp="refreshCounter"
				></itemlistItemComponent>
			</div>
			<div class="col-xs-24 col-print-24 col-lg-12 padding-xs">
				<div class="box-shadow box-fullheight content-2">
										

					<template v-if="device.inventory && device.inventory.inventoryLocation != undefined && !isObjectEmpty(device.inventory.inventoryLocation)">
						<h3 class="float-xs-left">{{T('Inventory location')}}</h3>
						<a 
							v-on:click="getInventoryLocationDialog()" 
							class="btn padding-xs-y-0 float-xs-right" 
							:class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'" 
							:title="T('Set inventory device location')"
						><i class="fal fa-edit"></i></a>
					</template>
					<div class="clearfix"></div>
					<template 
						v-if="device
							&& device.inventory
							&& device.inventory.inventoryLocation
							&& device.inventory.inventoryLocation.latitude
							&& device.inventory.inventoryLocation.longitude
							"
					>
						<googleMap
							id="inventoryMap"
							:entries="[{
								'position': {
									'lat': device.inventory.inventoryLocation.latitude,
									'lng': device.inventory.inventoryLocation.longitude
								},
								'infoHtml': getInfoBoxText()
							}]"
						/>
					</template>
					<template v-if="!device.inventory || device.inventory.inventoryLocation != undefined && isObjectEmpty(device.inventory.inventoryLocation)">
						<div :style="(darkmode == '1' ? 'background: rgba(0, 0, 0, 0.2);' : 'background: rgba(0, 0, 0, 0.08);')
							+ 'top: 16px;'
							+ 'height:calc(100% - 32px);'
							+ 'left: 16px;'
							+ 'right: 16px;'
							+ 'position: absolute;'
							">
							<div style="position:absolute;top:50%;transform: translate(0, -50%); width: 100%;" class="text-center">
								<h3 style="
								width: 100%;
								text-align: center;
							">
									{{ T('Inventory location is not set') }}
								</h3>
								<p>{{ T('Click the following link to set the inventory location:') }} <br>
								<br><a class="btn" :title="T('Set inventory device location')" v-on:click="getInventoryLocationDialog()"> <i class="fal fa-fw fa-edit"></i> {{ T('Set location') }}</a></p>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-24 padding-xs">
				<div class="box-shadow">
					<div class="tabs">
						<nav>
							<ul class="nav nav-tabs hide-on-print">
								<li>
									<a :class="{ 'active': activeTab == 'overview' }" v-on:click="activeTab = 'overview'">
										<i class="fal fa-fw fa-mobile-alt"></i> {{T('Overview')}}
									</a>
								</li>
								<li>
									<a :class="{ 'active': activeTab == 'operations' }" v-on:click="activeTab = 'operations'">
										<i class="fal fa-fw fa-wrench"></i> {{T('Operations')}}
									</a>
								</li>
								<li>
									<a :class="{ 'active': activeTab == 'applications' }" v-on:click="activeTab = 'applications'">
										<i class="fal fa-fw fa-rocket"></i> {{T('Applications')}}
									</a>
								</li>
							
								<li>
									<a :class="{ 'active': activeTab == 'policy' }" v-on:click="activeTab = 'policy'">
										<i class="fal fa-fw fa-shield-alt"></i> {{T('Policy')}}
									</a>
								</li>
								<li>
									<a :class="{ 'active': activeTab == 'nonCompliance' }" v-on:click="activeTab = 'nonCompliance'">
										<i class="fal fas-fw fa-exclamation-triangle"></i> {{T('Non compliance details')}}
										<span class="label" :class="{ 'bg-red': nonComplianceTableData.table.rows.length > 0 }">
											{{ nonComplianceTableData.table.rows.length }}
										</span>
									</a>
								</li>
							</ul>
						</nav>
						<div class="tabcontent">
							<div :class="{ 'hidden': activeTab != 'overview' }" id="device-summary">
								<div class="box-content-2">
									<div class="row">
										<div class="col-xs-24 col-lg-12">
											<h4>{{T('Device Summary')}}</h4>
											<table class="noborder border-h">
												<tbody>
													<tr v-if="device.enrollmentTime">
														<td class="title"><i class="fal fa-fw fa-calendar"></i> {{T('Enrolled at')}}</td>
														<td>
															{{ moment(device.enrollmentTime).format('DD.MM.YYYY HH:mm') }}
														</td>
													</tr>
													<tr>
														<td class="title"><i class="fal fa-fw fa-hashtag"></i> {{T('Device ID')}}</td>
														<td>
															{{ device.id }}
														</td>
													</tr>
													<tr>
														<td class="title"><i class="fal fa-fw fa-microchip"></i> {{T('Device Type')}}</td>
														<td>
															Android
														</td>
													</tr>
													<tr>
														<td class="title"><i class="fal fa-fw fa-mobile-alt"></i> {{T('Model')}}</td>
														<td>
															<template v-if="device && device.hardwareInfo">
																{{ device.hardwareInfo.model || '' }}
															</template>
														</td>
													</tr>
													<tr>
														<td class="title"><i class="fal fa-fw fa-building"></i> {{T('Manufacturer')}}</td>
														<td>
															<template v-if="device && device.hardwareInfo">
																{{ device.hardwareInfo.manufacturer || '' }}
															</template>
														</td>
													</tr>
													<tr>
														<td class="title"><i class="fal fa-fw fa-hashtag"></i> {{T('IMEI Number')}}</td>
														<td>
															<template v-if="device && device.networkInfo">
																{{ device.networkInfo.imei || '' }}
															</template>
														</td>
													</tr>
													<tr>
														<td class="title"><i class="fal fa-fw fa-microchip"></i> {{T('OS')}}</td>
														<td>
															Android 
															<template v-if="device && device.softwareInfo">
																<span title="Version">({{ device.softwareInfo.androidVersion || '' }})</span>
															</template>
														</td>
													</tr>
													<tr>
														<td class="title"><i class="fal fa-fw fa-hashtag"></i>  {{T('Serial Number')}}</td>
														<td>
															<template v-if="device && device.hardwareInfo">
																{{ device.hardwareInfo.serialNumber || '' }}
															</template>

														</td>
													</tr>
													<tr>
														<td class="title"><i class="fal fa-fw fa-hdd"></i>  {{T('Storage Capacity')}}</td>
														<td>
															{{ device.memoryInfo ? numberHelpers.bytesToSize(device.memoryInfo.totalInternalStorage) : '' }}
														</td>
													</tr>
													<template v-if="device.enrollmentTokenData">
														<tr v-if="enrollmentTokenDataAsJSON != undefined">
															<td class="title">
																<i class="fal fa-fw fa-info"></i>  {{T('Additional data')}}
															</td>
															<td>
																{{ enrollmentTokenDataAsJSON.userInput || "" }}
															</td>
														</tr>

														<tr v-else-if="device.enrollmentTokenData">
															<td class="title">
																<i class="fal fa-fw fa-info"></i>  {{T('Additional data')}}
															</td>
															<td>
																{{ device.enrollmentTokenData }}
															</td>
														</tr>
													</template>
												</tbody>
											</table>

											<template v-if="device.softwareInfo">
												<h4>{{T('Softwareinfo')}}</h4>
												<table class="noborder border-h">
													<tr>
														<td class="title">
															<i class="fal fa-fw fa-info-circle"></i> {{ T('Android version') }}
														</td>
														<td>
															{{ device.softwareInfo.androidVersion }}
														</td>
													</tr>
													<tr>
														<td class="title">
															<i class="fal fa-fw fa-info-circle"></i> Build
														</td>
														<td>
															{{ device.softwareInfo.androidBuildNumber }}
														</td>
													</tr>
													<tr>
														<td class="title">
															<i class="fal fa-fw fa-info-circle"></i> {{ T('Kernel version') }}
														</td>
														<td>
															{{ device.softwareInfo.deviceKernelVersion }}
														</td>
													</tr>
													<tr>
														<td class="title">
															<i class="fal fa-fw fa-info-circle"></i> {{ T('Policy version') }}
														</td>
														<td>
															{{ device.softwareInfo.androidDevicePolicyVersionName }}
														</td>
													</tr>
													<tr>
														<td class="title">
															<i class="fal fa-fw fa-info-circle"></i> {{ T('Update info') }}
														</td>
														<td>
															{{ (device.softwareInfo.systemUpdateInfo && device.softwareInfo.systemUpdateInfo.updateStatus) ? T(device.softwareInfo.systemUpdateInfo.updateStatus.replaceAll('_', ' ').toLowerCase()) : '' }}
														</td>
													</tr>
												</table>

											</template>

										</div>
										<div class="col-xs-24 col-lg-12">
											<template v-if="device.securityPosture && device.securityPosture.devicePosture != 'POSTURE_UNSPECIFIED'">
												<h4>{{T('Security posture')}}</h4>
												<table class="noborder border-h">
													<tr>
														<td>

															<i 
																class="fa fa-fw" 
																style="font-size: 1.25em;"
																:class="[
																	'color-' + (device.securityPosture.devicePosture == 'SECURE' ? 'green' : 'red'),
																	{
																		'fa-times-circle': device.securityPosture.devicePosture != 'SECURE',
																		'fa-circle-check': device.securityPosture.devicePosture == 'SECURE'
																	}
																]"
															></i>&nbsp;
															<span class="text-bold">
																<template v-if="device.securityPosture.devicePosture == 'SECURE'">
																	{{ T('Secure') }}
																</template>
																<template v-else>
																	{{ T('Unsecure') }}
																</template>
															</span>
										
														</td>
													</tr>
													<tr v-if="(device.securityPosture.postureDetails || []).length > 0">
														<td>
															<p v-for="postureDetail in device.securityPosture.postureDetails">
																<template v-if="postureDetail.securityRisk">
																	{{ T(postureDetail.securityRisk.replace("_", " ")) }}<br>
																</template>
																<template v-if="postureDetail.advice && postureDetail.advice.length">
																	<template v-if="postureDetail.advice[0].defaultMessage">
																		{{ T(postureDetail.advice[0].defaultMessage) }}
																		<br>
																	</template>
																</template>
															</p>
														</td>
													</tr>
												</table>
											</template>

											<template v-if="device.networkInfo">
													<h4>{{T('Network Summary')}}</h4>
													<table class="noborder border-h ">
															<tbody>
																	<tr>
																		<td class="title"><i class="fal fa-fw fa-hashtag"></i> {{T('IMEI Number')}}</td>
																		<td>
																				{{ device.networkInfo.imei || '' }}
																		</td>
																	</tr>
																	<tr>
																		<td class="title"><i class="fal fa-fw fa-wifi"></i> {{T('WIFI MAC')}}</td>
																		<td>
																				{{ device.networkInfo.wifiMacAddress || '' }}
																		</td>
																	</tr>
															</tbody>
													</table>

													<template v-if="device.networkInfo.telephonyInfos">
															<template v-for="slot, index in device.networkInfo.telephonyInfos">

																	<h4>Sim slot {{ index }}</h4>
																	<table class="noborder border-h ">
																			<tr>
																					<td class="title">
																							<i class="fal fa-fw fa-phone"></i> {{ T('Phone Number') }}
																					</td>
																					<td>
																							{{ slot.phoneNumber || "" }}
																					</td>
																			</tr>
																			<tr>
																					<td class="title">
																							<i class="fal fa-fw fa-info-circle"></i> {{ T('Carrier info') }}
																					</td>
																					<td>
																							<template v-if="slot.carrierName">
																									{{ slot.carrierName || "" }}
																							</template>
																					</td>
																			</tr>
																	</table>
															</template>
													</template>


											</template>

											<template v-if="device.deviceSettings">
												<h4>{{T('Device settings')}}</h4>

												<table class="noborder border-h">
													<tr>
														<td style="width:230px;">
															<strong>
																<i class="fal fa-fw fa-shield-alt"></i>
																{{ T('Is secure') }}
															</strong>
															<p><i>{{ T('Whether the device is secured with PIN/password.') }}</i></p>
														</td>
														<td>
                                                            <i 
																class="fa fa-fw" 
																style="font-size: 1.25em;"
																:class="[
																	'color-' + (device.deviceSettings.isDeviceSecure ? 'green' : 'red'),
																	{
																		'fa-times-circle': !device.deviceSettings.isDeviceSecure,
																		'fa-circle-check': device.deviceSettings.isDeviceSecure
																	}
																]"
															></i>
                                                            

														</td>
													</tr>
													<tr>
														<td>
															<strong>
																<i class="fal fa-fw fa-file-code"></i>
																{{ T('Allow unknown sources') }}
															</strong>
															<p><i>{{ T('Whether installing apps from unknown sources is enabled.') }}</i></p>
														</td>
														<td>
															<i 
																class="fa fa-fw" 
																style="font-size: 1.25em;"
																:class="[
																	'color-' + (device.deviceSettings.unknownSourcesEnabled ? 'green' : 'red'),
																	{
																		'fa-times-circle': !device.deviceSettings.unknownSourcesEnabled,
																		'fa-circle-check': device.deviceSettings.unknownSourcesEnabled
																	}
																]"
															></i>
														</td>
													</tr>
													<tr>
														<td>
															<strong>
																<i class="fal fa-fw fa-file-code"></i>
																{{ T('Development settings enabled') }}
															</strong>
															<p><i>{{ T('Whether developer mode is enabled on the device.') }}</i></p>
														</td>
														<td>
															<i 
																class="fa fa-fw" 
																style="font-size: 1.25em;"
																:class="[
																	'color-' + (device.deviceSettings.developmentSettingsEnabled ? 'green' : 'red'),
																	{
																		'fa-times-circle': !device.deviceSettings.developmentSettingsEnabled,
																		'fa-circle-check': device.deviceSettings.developmentSettingsEnabled
																	}
																]"
															></i>
														</td>
													</tr>
													<tr>
														<td>
															<strong>
																<i class="fal fa-fw fa-file-code"></i>
																{{ T('ADB enabled') }}
															</strong>
															<p><i>{{ T('Whether ADB is enabled on the device.') }}</i></p>
														</td>
														<td>
															<i 
																class="fa fa-fw" 
																style="font-size: 1.25em;"
																:class="[
																	'color-' + (device.deviceSettings.adbEnabled ? 'green' : 'red'),
																	{
																		'fa-times-circle': !device.deviceSettings.adbEnabled,
																		'fa-circle-check': device.deviceSettings.adbEnabled
																	}
																]"
															></i>
														</td>
													</tr>
													<tr>
														<td>
															<strong>
																<i class="fal fa-fw fa-lock"></i>
																{{ T('Encrypted') }}
															</strong>
															<p><i>{{ T('Whether the storage encryption is enabled.') }}</i></p>
														</td>
														<td>
															<i 
																class="fa fa-fw" 
																style="font-size: 1.25em;"
																:class="[
																	'color-' + (device.deviceSettings.isEncrypted ? 'green' : 'red'),
																	{
																		'fa-times-circle': !device.deviceSettings.isEncrypted,
																		'fa-circle-check': device.deviceSettings.isEncrypted
																	}
																]"
															></i>
														</td>
													</tr>
													<tr>
														<td>
															<strong>
																<i class="fal fa-fw fa-check-circle"></i>
																{{ T('Verify Apps') }}
															</strong>
															<p><i>{{ T('Whether Google Play Protect verification is enforced on the device.') }}</i></p>
														</td>
														<td>
															<i 
																class="fa fa-fw" 
																style="font-size: 1.25em;"
																:class="[
																	'color-' + (device.deviceSettings.verifyAppsEnabled ? 'green' : 'red'),
																	{
																		'fa-times-circle': !device.deviceSettings.verifyAppsEnabled,
																		'fa-circle-check': device.deviceSettings.verifyAppsEnabled
																	}
																]"
															></i>
														</td>
													</tr>
												</table>
											</template>
										</div>
									</div>
								</div>
							</div>
							<div :class="{ 'hidden': activeTab != 'applications' }" id="applications">
								<div class="box-content-2">
									<div class="row">
										<div class="col-xs-24">
											<div class="row">
												<div class="col-xs-24">
													<h4>{{T('Applications')}}</h4>
													<span></span>
												</div>
											</div>
										</div>
										<div class="col-xs-24">
											<div class="row">
												<div class="col-xs-24 padding-xs-x" >
													<template v-if="applicationReportTableData.table.rows.length == 0">
														{{ T('Applications are only available for enterprise devices that have application reporting activated in their profile.') }}
													</template>
													<table2
														:class="{ 'hidden': applicationReportTableData.table.rows.length == 0 }"
														ref="applicationReportTable"
														class="applicationReportTable"
														:table="applicationReportTableData.table"
														:loading="applicationReportTableData.loading"
														:minheight="50"
													></table2>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div :class="{ 'hidden': activeTab != 'operations' }" id="device-operations">
								<div class="box-content-2">
									<div class="row">
										<div class="col-xs-24">
											<div class="row">
												<div class="col-xs-24">
													<h4>{{T('Device Operations')}}</h4>
													<span></span>
												</div>
											</div>
										</div>
										<div class="col-xs-24 padding-xs-t">
											<div class="row">
												<div class="col-lg-6 col-xl-3 padding-xs-x">
													<button
														class="btn-loader twist-in col-xs-24"
														v-on:click="execDeviceAction(activeAccountId,objectId || '','LOCK',deviceActionStates)"
														:class="{ 'btn-loading': deviceActionStates['LOCK'] }"
														:disabled="deviceActionStates['LOCK'] || undefined"
													>
														<span class="animate">
															<loader
																v-if="deviceActionStates['LOCK']"
																style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
															></loader>
														</span>
														<span><i class="fal fa-lock"></i> {{T('Lock')}}</span>
													</button>
												</div>
												<div class="col-lg-6 col-xl-3 padding-xs-x">
													<button
														class="btn-loader twist-in col-xs-24"
														v-on:click="execDeviceAction(activeAccountId, objectId || '', 'REBOOT', deviceActionStates)"
														:class="{ 'btn-loading': deviceActionStates['REBOOT'] }"
														:disabled="deviceActionStates['REBOOT'] || undefined"
													>
														<span class="animate">
															<loader
																v-if="deviceActionStates['REBOOT']"
																style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
															></loader>
														</span>
														<span><i class="fal fa-redo"></i> {{T('Reboot')}}</span>
													</button>
												</div>
												<div class="col-lg-6 col-xl-3 padding-xs-x">
													<button
														class="btn-loader twist-in col-xs-24"
														v-on:click="execDeviceAction(activeAccountId, objectId || '', 'RESET_PASSWORD', deviceActionStates)"
														:class="{ 'btn-loading': deviceActionStates['RESET_PASSWORD'] }"
														:disabled="deviceActionStates['RESET_PASSWORD'] || undefined"
													>
														<span class="animate">
															<loader
																v-if="deviceActionStates['RESET_PASSWORD']"
																style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
															></loader>
														</span>
														<span><i class="fal fa-redo"></i> {{T('Reset password')}}</span>
													</button>
												</div>
												<div 
													class="col-lg-6 col-xl-3 padding-xs-x" 
													v-if="hasLocationPlugin && device.managementMode != 'PROFILE_OWNER'"
												>
													<button
														class="btn-loader twist-in col-xs-24"
														v-on:click="execDeviceAction(activeAccountId, objectId || '', 'LOCATION', deviceActionStates,waitForLocation)"
														:class="{ 'btn-loading': deviceActionStates['LOCATION'] }"
														:disabled="deviceActionStates['LOCATION'] || undefined"
													>
														<span class="animate">
															<loader
																v-if="deviceActionStates['LOCATION']"
																style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
															></loader>
														</span>
														<span><i class="fal fa-search"></i> {{T('Locate')}}</span>
													</button>
												</div>
												<div class="col-lg-6 col-xl-3 padding-xs-x">
													<button
														class="btn-loader twist-in col-xs-24"
														v-on:click="toggleDeviceState()"
														:class="{ 'btn-loading': changingDeviceState }"
														:disabled="changingDeviceState || undefined"
													>
														<span class="animate">
															<loader
																v-if="changingDeviceState"
																style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
															></loader>
														</span>
														<span>
															<template v-if="device.state == 'ACTIVE'">
																<i class="fal fa-toggle-off"></i> {{T('Disable Device')}}
															</template>
															<template v-else>
																<i class="fal fa-toggle-on"></i> {{T('Activate Device')}}
															</template>
														</span>
													</button>
												</div>

												<div class="col-lg-6 col-xl-3 padding-xs-x" v-if="device.managementMode == 'PROFILE_OWNER' && device.ownership == 'COMPANY_OWNED'">
													<button
														class="btn-loader twist-in col-xs-24"
														v-on:click="execDeviceAction(activeAccountId, objectId || '', 'RELINQUISH_OWNERSHIP', deviceActionStates)"
														:class="{ 'btn-loading': deviceActionStates['RELINQUISH_OWNERSHIP'] }"
														:disabled="deviceActionStates['RELINQUISH_OWNERSHIP'] || undefined"
													>
														<span class="animate">
															<loader
																v-if="deviceActionStates['RELINQUISH_OWNERSHIP']"
																style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
															></loader>
														</span>
														<span><i class="fal fa-search"></i> {{T('Relinquish ownership')}}</span>
													</button>
												</div>

											</div>
										</div>
										<div class="col-xs-24">
											<div class="row">
												<div class="col-xs-24 padding-xs-t">
													<template v-if="deviceLocation.lat && deviceLocation.long && !deviceLocation.loading && deviceLocation.errors.length == 0">
														<googleMap
															id="locateDevice"
															:entries="[{
																'position': {
																	'lat': deviceLocation.lat,
																	'lng': deviceLocation.long,
																	'accuracy': 2
																},
																'infoHtml': T('Last update:') + ' ' + moment(deviceLocation.timestamp * 1000).format('DD.MM.YYYY HH:mm')
															}]"
														/>
													</template>
													<template v-if="!deviceLocation.loading && deviceLocation.errors.length > 0">
														<template v-for="error in deviceLocation.errors">
															<p class="error-bubble label bg-red margin-xs-t">
																{{ T(getLocationError(error)) }}
															</p>
														</template>
													</template>
													<template v-if="deviceLocation.loading">
														<p class="text-size-2 text-center">
															<loader/>
														</p>
													</template>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div :class="{ 'hidden': activeTab != 'policy' }" class="tab-pane" id="device-policy">
								<div class="box-content-2">
									<div class="row">
										<div class="col-xs-24">
											<div class="row">
												<div class="col-xs-24">
													<h4>{{T('Policy')}}</h4>
													<span></span>
												</div>
											</div>
										</div>
										<div class="col-xs-24">
											<div class="row padding-xs-y form-group border-bottom" v-if="device != undefined && initialized">
												<div class="first col-xs-24 col-lg-4 col-xl-5">
													<label class="control-label inputname" for="policypicker">
														{{ T('Device policy') }}
														<loader v-if="policyLoading" style="font-size: 1.5em;top:2px;"></loader>
													</label>
												</div>
												<div class="input col-xs-20 col-lg-8 col-xl-7">
													<label class="form-field margin-xs-b-0 select">
														<select v-model="thisDevicePolicyName" id="policypicker" :disabled="policyLoading">
															<option v-for="policy in policiyOptions" :value="policy.id">{{ policy.text }}</option>
														</select>
													</label>
												</div>
												<div class="desc col-xs-24 col-lg-8 col-xl-10">		
													<p class="input-description">
														{{T('Choose a policy for this device.')}}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div :class="{ 'hidden': activeTab != 'nonCompliance' }" class="tab-pane" id="nonComplianceDetails">
								<div class="box-content-2">
									<div class="row">
										<div class="col-xs-24">
											<div class="row">
												<div class="col-xs-24">
													<h4>{{T('Non compliance details')}}</h4>
													<span></span>
												</div>
											</div>
										</div>
										<div class="col-xs-24">
											<table2
												ref="nonComplianceTable"
												class="nonComplianceTable"
												:table="nonComplianceTableData.table"
												:loading="nonComplianceTableData.loading"
												:minheight="50"
											></table2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="padding-xs-y-4"></div>
	</section>
</template>
