import { useStore } from '@/store/vuex.store';
import jsonHelpers from "../../helpers/helpers.json";
import { useGlobalMixin } from "@/mixins/mixins.global";
import { T } from "@/classes/i18n";
import loaderComponent from "../components/loader.vue";
import sidebarAccountSelectComponent from "./main-sidebar-accountselect.vue";
import tenantHelpers from '@/helpers/helpers.tenants';
import type { ObjectStoreCountProperties } from '../../../../types/vuex';
import jsonFormHelpers from '@/helpers/helpers.jsonForms';
import storeHelpers from '@/helpers/helpers.store';
import config from '@/classes/config';
import licenseHelpers from '@/helpers/helpers.license';
import { useAzureStore } from '@/classes/objectTypes/unifiedSecurity/users/azure';
import products from '@/classes/objectTypes';



const mainSidebarComponent: any = {
	"name": "main-sidebar",
	mixins: [useGlobalMixin()],
	template: `
		<nav class="menu-vertical" style="z-index:5">
			<ul class="sidebar-menu tenants" data-widget="tree">
				

				<template v-for="page,key in getPages()">
					<template v-if="(page.options.type || null) == 'title'">
						<li class="title" v-html="T(page.text.singular)">
						</li>
					</template>
					<template v-else-if="(page.options.type || null) == 'accountSelection'">
						<li class="select hide-collapsed margin-xs-y" v-if="getObjectLength(accounts) > 1">
							<div id="selector" class="has-feedback clearfix">
								<i class="fal fa-building form-control-feedback"></i>
								<sidebar-accountselect></sidebar-accountselect>
							</div>
						</li>
					</template>
					<template v-else>
						<li
							v-bind:class="{'treeview':hasChildren(key),'active': activePage == key,'menu-open': page.link.opened}"
						>
							<a
								:href="page.link.href.replace('{tenantDomain}',activeTenantDomain)"
								:target="page.link.target"
								:title="T(page.text.sidebar || page.text.plural)"
								:id="'linkTo'+key"
								:onclick="page.link.onclick"
							>
								<i :class="page.appearance.icon" class="fa-fw"></i>
								<span class="title">
									{{ translate(page.text.sidebar || page.text.plural) }}

									<template v-if="page.appearance.label">
										<small class="label" :class="page.appearance.label.class" :title="T(page.appearance.label.title)">
											{{ page.appearance.label.text }}
										</small>
									</template>
								</span>
								<span class="pull-right-container hide-collapsed" v-if="hasChildren(key)">
									<i class="fal fa-fw fa-angle-left"></i>
								</span>
								<a class="addLink" style="float:right" v-if="useAddLink(page) && checkAddLink(page.text.add)" :title="T(page.text.add)" :href="page.addLink.href.replace('{tenantDomain}',activeTenantDomain)">
									<i class="fal fa-fw fa-plus-circle"></i>
								</a>
							</a>

							<ul v-if="hasChildren(key) == true" class="treeview-menu">
								<li v-for="childPage,childKey in getPages(key)" v-bind:class="{'treeview':hasChildren(childKey),'active': activePage == childKey,'menu-open': childPage.link.opened}">
									<a
										:href="childPage.link.href.replace('{tenantDomain}',activeTenantDomain)"
										:target="childPage.link.target"
										:title="T(childPage.text.sidebar || childPage.text.plural)"
										:id="'linkTo'+childKey"
										:onclick="childPage.link.onclick"
									>
										<i :class="childPage.appearance.icon" class="fa-fw"></i>
										<span class="title">
											{{ translate(childPage.text.sidebar || childPage.text.plural) }}

											<template v-if="childPage.appearance.label">
												<small class="label" :class="childPage.appearance.label.class" :title="T(childPage.appearance.label.title)">
													{{ childPage.appearance.label.text }}
												</small>
											</template>
										</span>
										<span class="pull-right-container hide-collapsed" v-if="hasChildren(childKey)">
											<i class="fal fa-fw fa-angle-left"></i>
										</span>
										<a class="addLink" style="float:right" v-if="useAddLink(childPage) && checkAddLink(childPage.text.add)" :title="T(childPage.text.add)" :href="childPage.addLink.href.replace('{tenantDomain}',activeTenantDomain)">
											<i class="fal fa-fw fa-plus-circle"></i>
										</a>
									</a>

									<ul v-if="hasChildren(childKey) == true" class="treeview-menu">
										<li v-for="childChildPage,childChildKey in getPages(childKey)" v-bind:class="{'treeview':hasChildren(childChildKey),'active': activePage == childChildKey,'menu-open': childPage.link.opened}">
											<a
												:href="childChildPage.link.href.replace('{tenantDomain}',activeTenantDomain)"
												:target="childChildPage.link.target"
												:title="T(childChildPage.text.sidebar || childChildPage.text.plural)"
												:id="'linkTo'+childChildKey"
												:onclick="childChildPage.link.onclick"
											>
												<i :class="childChildPage.appearance.icon" class="fa-fw"></i>
												<span class="title">
													{{ T(childChildPage.text.sidebar || childChildPage.text.plural) }}

													<template v-if="childChildPage.appearance.label">
														<small class="label" :class="childChildPage.appearance.label.class" :title="T(childChildPage.appearance.label.title)">
															{{ childChildPage.appearance.label.text }}
														</small>
													</template>

												</span>
												<a class="addLink" style="float:right" v-if="useAddLink(childChildPage) && checkAddLink(childChildPage.text.add)" :title="T(childChildPage.text.add)" :href="childChildPage.addLink.href.replace('{tenantDomain}',activeTenantDomain)">
													<i class="fal fa-fw fa-plus-circle"></i>
												</a>
											</a>

										</li>
									</ul>

								</li>
							</ul>

						</li>
					</template>
				</template>
			</ul>


		</nav>
	`,
	data: function () {
		return {
			openedSubmenu: undefined,
			refreshCounter: 0
		}
	},
	computed: {
		"loadingTenant": function (this: any) {
			return !this.$root.activeAccountInitialized
		},
		"accounts": function (this: any) {
			return useStore().state.session.accounts
		},
		"licenses": function (this: any) {
			this.refreshCounter;
			return useStore().state.session.accounts[this.activeAccountId].unifiedSecurity?.licenses?.items
		},
		"pages": function (this: any) {
			this.refreshCounter;
			return useStore().state.resources.pages
		},
		"twofabannerHeight": function (this:any) {
			return this.$root.twofabannerHeight
		},
		"mainheaderHeight": function (this: any) {
			return this.$root.mainheaderHeight
		},
		"isMobileView": function (this: any) {
			return this.$root.isMobileView
		}
	},
	created: function (this: any) {
	},
	methods: {
		getUrlPath: () => {
			return document.location.pathname
		},
		doRefresh: function (this: any) {
			this.refreshCounter = this.refreshCounter + 1
		},
		getObjectLength: jsonHelpers.getObjectLength,
			getPages: function (this: any, parent: any = null) {
				let pages: any = this.pages
				let result: any = {}
				this.refreshCounter;
				this.licenses;
				for (let slug in pages) {
					let page = pages[slug]
					let addThisPage: boolean = true
					if (page.options.parent != parent) {
						addThisPage = false
					}
					if (page.options.show != undefined) {
						if (page.options.show == false) {
							addThisPage = false
						}
					}
					if (page.options.activeAccountHasNoParentAccount) {
						if (useStore().state.session.accounts[useStore().state.session.activeAccountId || ""].unifiedSecurity.account.parent_accountid != null) {
							addThisPage = false
						}
					}
					if (page.options.resellerAccountOnly) {
						if (useStore().state.session.isReseller != true) {
							addThisPage = false
						}
					}
					if (page.options.resellerTenantOnly) {
						if (jsonHelpers.getObjectProperty(this, "$store.state.session.accounts." + this.activeAccountId + ".unifiedSecurity.account.isReseller") != true) {
							addThisPage = false
						}
					}
					if (page.options.vppOnly) {
						if (tenantHelpers.hasFunctionality(this.activeAccountId, 'appleVPP') != true) {
							addThisPage = false
						}
					}
					if (page.options.depOnly) {
						if (tenantHelpers.hasFunctionality(this.activeAccountId, 'appleDEP') != true) {
							addThisPage = false
						}
					}
					if (page.options.enterpriseOnly) {
						if (tenantHelpers.hasFunctionality(this.activeAccountId, 'enterprise') != true) {
							addThisPage = false
						}
					}
					if (page.options.zeroTouchOnly) {
						if (tenantHelpers.hasFunctionality(this.activeAccountId, 'zerotouch') != true) {
							addThisPage = false
						}
					}
					if (page.options.forLicenses) {
						if (!licenseHelpers.hasOneOfLicenses(this.activeAccountId, page.options.forLicenses) === true) {
							addThisPage = false
						}
					}
					if (page.options.forTenants) {
						if (!config.devMode) {
							if (page.options.forTenants.indexOf(tenantHelpers.getAccountId(useStore().state.session.userInfo.targetDomain)) == -1) {
								addThisPage = false
							}
						}
					}
					if (page.options.forRoles) {
						// check for roles
						let hasAnyRole = false
						useStore().state.session.userInfo.roles.forEach((roleName:string) => {
							if(page.options.forRoles.indexOf(roleName) != -1) {
								hasAnyRole = true;
							}
						})
						if(!hasAnyRole) {
							addThisPage = false
						}
					}
					if (page.options.forLicensesInAnyTenant) {
						let accountIds : string[] = useStore().state.session.userInfo.scopes.list_accounts.map(function (accountInfo:any) { return accountInfo.accountid })
						let foundLicense = accountIds.some((accountId) => {
							return licenseHelpers.hasOneOfLicenses(accountId, page.options.forLicensesInAnyTenant)
						})
						if (!foundLicense) {
							addThisPage = false
						}
					}

					if (page.options.forLicensesInAnyChildOrThisTenant) {
						let accountIds : string[] = useStore().state.session.userInfo.scopes.list_accounts.filter((accountInfo:any) => {
							return String(accountInfo.parent_accountid) == this.activeAccountId || String(accountInfo.accountid) == this.activeAccountId
						}).map(function (accountInfo:any) { 
							return String(accountInfo.accountid)
						})
						let foundLicense = accountIds.some((accountId) => {
							return licenseHelpers.hasOneOfLicenses(accountId, page.options.forLicensesInAnyChildOrThisTenant)
						})
						if (!foundLicense) {
							addThisPage = false
						}
					}
					if (!config.devMode && page.options.devOnly == true) {
						addThisPage = false
					}
					if (addThisPage) {
						result[slug] = page
					}
				}
				return result
			},
		openSubmenu: function (this: any, submenuName: string) {
			this.pages[submenuName].link.opened =  true
		},
		closeSubmenu: function (this: any, submenuName: string) {
			this.pages[submenuName].link.opened =  false
		},
		toggleSubmenu: function (this: any, submenuName: string) {
			this.pages[submenuName].link.opened =  !this.pages[submenuName].link.opened
		},
		hasChildren: function (this: any, pageIndex: string) {
			for (let page in this.pages) {
				if (this.pages[page].options.parent == pageIndex) {
					return true
				}
			}
			return false
		},
		translate: function (content: string) {
			return T(content)
		},
		useAddLink: function (this: any, page: any) {
			let result: boolean = false
			if (page.addLink != undefined) {
				result = true
				if (page.addLink.forLicenses) {
					if (!licenseHelpers.hasOneOfLicenses(this.activeAccountId, page.addLink.forLicenses) === true) {
						result = false
					}
				}
				if ((page.text.plural == "Other devices (VPN)" || page.text.plural == "Devices") && useStore().state.session.accounts[this.activeAccountId].mobileSecurity.settings.enrollmentAllowed === false) {
					result = false
				}
				if(page.addLink.conditions) {
					if(!jsonFormHelpers.areConditionsFulfilled(this, page.addLink.conditions, undefined)) {
						result = false
					}
				}
			}
			return result
		},
		checkAddLink: function(this: any, addLinkText: string) {
			let result: boolean = true
			if((addLinkText == "Add role" || addLinkText == "Add user") && useAzureStore().isAzureImporting) {
				result = false
			}
			return result
		}
	},
	components:{
		"loader": loaderComponent,
		"sidebar-accountselect": sidebarAccountSelectComponent
	}
}

export default mainSidebarComponent