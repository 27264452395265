import getterHelpers from "@/helpers/helpers.getters"
import { T } from "@/classes/i18n"
import { ActionTypes } from "@/store/vuex.store"
const miscDialogs = {
    confirmDialog:function(accountid: string, title: string, content: string, onSubmit: any, enterString?: string, okButtonText?: string, okButtonIcon?: string,abortable:boolean=true, dialogIcon='fal fa-exclamation-triangle',onAbort?:any,autoRemove:boolean=true) {

        let buttons = [{
            loading: false,
            onClick: async function (modalComponent:any) {
                if(typeof onAbort === "function") {
                    await onAbort(modalComponent)
                }
                getterHelpers.useVue().$refs.modals.removeModal()
            },
            icon: "fal fa-times",
            text: T('Cancel'),
            align: "left",
            disabled: false,
            id:"close"
        },
        {
            loading: false,
            onClick: async function (modalComponent:any) {
                this.loading = true
                this.disabled = true
                getterHelpers.useStore().state.session.accounts[accountid].modals[0].buttons[0].disabled = true
                getterHelpers.useStore().state.session.accounts[accountid].modals[0].buttons[0].loading = true
                getterHelpers.useStore().state.session.accounts[accountid].modals[0].abortable = false
                try {
                    await onSubmit(modalComponent)
                    if (autoRemove) {
                        getterHelpers.useVue().$refs.modals.removeModal()
                    }
                }
                catch(e:any) {
                    this.loading = false
                    this.disabled = false
                    getterHelpers.useStore().state.session.accounts[accountid].modals[0].buttons[0].disabled = false
                    getterHelpers.useStore().state.session.accounts[accountid].modals[0].buttons[0].loading = false
                    getterHelpers.useStore().state.session.accounts[accountid].modals[0].abortable = true
                }
            },
            icon: okButtonIcon ? okButtonIcon : "fal fa-check",
            text: okButtonText ? T(okButtonText) : T('Confirm'),
            align: "right",
            disabled: false,
            id: "confirm"
        }]
        if(!abortable) {
            buttons = buttons.slice(1)
        }
        getterHelpers.useStore().dispatch(ActionTypes.addModal,{
            "id":"confirm-"+title+"-"+accountid,
            "accountId":accountid,
            "abortable":abortable,
            "content":{
                "title":{
                    "text":title,
                    "icon":dialogIcon,
                },
                "body":{
                    "use":true,
                    "content":undefined,
                    "component":'confirm-dialog',
                    "properties":{
                        "enterString":enterString,
                        "content":content,
                    }
                },
            },
            "buttons": buttons
        })
    },
    errorDialog: function(accountid:string,title:string,content:string) {
        getterHelpers.useStore().dispatch(ActionTypes.addModal,{
            "id":"confirm-"+title+"-"+accountid,
            "accountId":accountid,
            "abortable":true,
            "content":{
                "title":{
                    "text":title,
                    "icon":"fal fa-exclamation-triangle",
                },
                "body":{
                    "use":true,
                    "content":undefined,
                    "component":'confirm-dialog',
                    "properties":{
                        "enterString":undefined,
                        "content":content,
                    }
                },
            },
            "buttons":[
                {
                    loading:false,
                    onClick:async function() {
                        getterHelpers.useVue().$refs.modals.removeModal()
                    },
                    icon:"fal fa-check",
                    text:'OK',
                    align:"right",
                    disabled:false
                }
            ]
        })
    },

    inputDialog: function(accountId:string,title:string,content:string,onSubmit:any,type:"textarea"|"text"="textarea",checkValue?:(value:string)=>boolean,placeholder?:string,useLinebreak=false) {
        getterHelpers.useStore().dispatch(ActionTypes.addModal,{
            "id":"textarea-"+title+"-"+accountId,
            "accountId":accountId,
            "abortable":true,
            "content":{
                "title":{
                    "text":title,
                    "icon":"fal fa-pencil-alt",
                },
                "body":{
                    "use":true,
                    "content":undefined,
                    "component":'input-dialog',
                    "properties":{
                        "content":content,
                        "type": type,
                        "sendCondition": checkValue,
                        "placeholder": placeholder,
                        "useLinebreak": useLinebreak
                    }
                },
            },
            "buttons":[
                {
                    loading:false,
                    onClick:function() {
                        getterHelpers.useVue().$refs.modals.removeModal()
                    },
                    icon:"fal fa-times",
                    text:T('Cancel'),
                    align:"left",
                    disabled:false
                },
                {
                    loading:false,
                    onClick:async function(value?:string) {
                        this.loading = true
                        this.disabled = true
                        getterHelpers.useStore().state.session.accounts[accountId].modals[0].buttons[0].loading = true
                        getterHelpers.useStore().state.session.accounts[accountId].modals[0].buttons[0].disabled = true
                        getterHelpers.useStore().state.session.accounts[accountId].modals[0].abortable = false
                        if(typeof value != "string") {
                            value = getterHelpers.useVue().$refs.modals.$refs.modal.$refs.modalComponent.value || ""
                        }
                        await onSubmit(value)
                        getterHelpers.useVue().$refs.modals.removeModal()
                    },
                    icon:"fal fa-check",
                    text:T('Confirm'),
                    align:"right",
                    disabled:false
                }
            ]
        })
    }
}
export default miscDialogs