const inputSelectComponent = {
    "name":"input-select",
    "template": `
        <select
            v-bind="$attrs"
            v-on="{
                input: event => $emit('update:modelValue', event.target.value)
            }"
            style="width:100%"
        >
            <template v-for="option in selectOptions">
                <option
                    :value="option.id"
                    :disabled="option.disabled || null"
                >
                    {{ option.text }}
                </option>
            </template>
        </select>
    `,
    "props": {
        "modelValue": null,
        "selectOptions": {
            "type":Array,
            "required":false,
            "default":function () { return [] }
        }
    }
}
export default inputSelectComponent
