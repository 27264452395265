<script setup lang=ts>
import { T } from '@/classes/i18n';
import { computed, onMounted, reactive } from 'vue';
import { MutationTypes, useStore } from '@/store/vuex.store';
import type { ListAccount } from "../../../../landingpages/src/classes/sessions"
import tenantHelpers from '@/helpers/helpers.tenants';
import router from '@/router/router';

// Properties
const props = defineProps<{
    "properties": {
        "affectedAccountIds": string[]
    }
}>();

// Computed Values

const affectedAccounts = computed(() => {
    let result: ListAccount[] = []
    props.properties.affectedAccountIds.forEach((accountId: string) => {
        result.push(useStore().state.session.accounts[accountId].unifiedSecurity.account)
    })
    return result
})

const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId
})

function navigate(accountId: number) {
    router.navigate('show-tenant-' + tenantHelpers.getTenantDomain(accountId.toString()) + '-usc-utms-dashboard')
    useStore().commit(MutationTypes.removeModal, {
        "accountId": activeAccountId.value
    })
}

onMounted(() => {
})

</script>
<template>
    {{ T('As of version 12.5.1, the UTM initiates a connection to our cloud services and can be administered via these without a public IP address.') }}
    <br>
    <br>
    {{ T('A former procedure for UTMs with version 12.2.2.8 or older will therefore be switched off from 30.11.2023 and the UTMs will no longer be accessible via websession.') }}
    <br>
    <br>
    {{ T('Please perform the required update as soon as possible. The following tenants are affected:') }}
    <br>
    <br>
    <div class="flexrow" v-for="account of affectedAccounts">
        <a @click="navigate(account.accountid)">{{ tenantHelpers.getTenantDomain(account.accountid.toString()) + " - " + account.accountname }}</a>
        <br>
    </div>
</template>