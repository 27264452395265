


<script setup lang="ts">
    import jsonHelpers from "../../helpers/helpers.json";
    import { useStore } from '@/store/vuex.store';
    import tenantHelpers from "@/helpers/helpers.tenants";
    import jsonFormHelpers from "@/helpers/helpers.jsonForms";
    import { computed, ref } from "vue";
    import { T } from "@/classes/i18n";
import licenseHelpers from "@/helpers/helpers.license";

    const routeBoxList = ref([])
    const hoveringIconLink = ref("")
    const activeAccountId = computed(() => {
        return useStore()?.getters.getActiveAccountId
    })
    const resources = computed(() => {
        return useStore().state.resources
    })
    
    const hasOneOfLicenses = licenseHelpers.hasOneOfLicenses
    const areConditionsFulfilled = jsonFormHelpers.areConditionsFulfilled
</script>
<template>
    <section class="padding-xs-2 padding-lg-4 padding-print-0 pagecontent">
        <div class="row">
            <template v-if="hasOneOfLicenses(activeAccountId, ['Mobile Security', 'MDM'])">
                <div class="col-xs-24 padding-xs margin-xs-b-2">
                    <h2 class="padding-xs-b border-bottom">
                        <span>
                            <i class="fal fa-fw fa-mobile"></i>Mobile Security
                            <template v-if="!hasOneOfLicenses(activeAccountId, ['Mobile Security', 'MDM'], 'valid')">
                                <span class="label bg-red" style="font-size: 0.55em;">{{ T('expired') }}</span>
                            </template>
                        </span>
                    </h2>
                </div>
        
                <div class="col-xs-24 padding-xs-0">
                    <div class="row flexrow flex-wrap">
                        <template v-for="objectInfo, objectType in resources.registeredProducts.mobileSecurity.objectTypes">
                            <template 
                                v-if="objectInfo.appearance
                                    && objectInfo.appearance.showOnDashboard
                                    && areConditionsFulfilled(undefined,objectInfo.appearance.showConditions || [])
                                    "
                            >
                                <div class="col-xs-6 col-lg-2 padding-xs text-center">
                                    <a 
                                        class="icon-link"
                                        :href="objectInfo.link.href.replace('{tenantDomain}', activeAccountId + '.sms')"
                                        :target="objectInfo.link.target"
                                        :title="T(objectInfo.text.plural)"
                                    >
       
                                        <span class="icon-circle" :class="'bg-' + objectInfo.appearance.color">
                                            <i :class="objectInfo.appearance.icon"></i>
                                        </span>
                                        <span class="link-text">
                                            {{ T(objectInfo.text.dashboard) }}
                                        </span>
                                    </a>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>

            </template>

            <template v-if="hasOneOfLicenses(activeAccountId, ['laas'], 'valid')">
                <div class="col-xs-24 padding-xs margin-xs-b-2">
                    <h2 class="padding-xs-t-2 padding-xs-b border-bottom">
                        <span>
                            <i class="fa-fw fal fa-chart-pie"></i>Unified Reporting
                        </span>
                    </h2>
                </div>

                <div class="col-xs-24 padding-xs-0">
                    <div class="row flexrow flex-wrap">
                        <template v-for="objectInfo, objectType in resources.registeredProducts.unifiedReporting.objectTypes">
                            <template 
                                v-if="objectInfo.appearance
                                    && objectInfo.appearance.showOnDashboard
                                    && areConditionsFulfilled(undefined,objectInfo.appearance.showConditions || [])
                                    "
                            >
                                <div class="col-xs-6 col-lg-2 padding-xs text-center">
                                    <a 
                                        class="icon-link"
                                        :href="objectInfo.link.href.replace('{tenantDomain}', activeAccountId + '.sms')"
                                        :target="objectInfo.link.target"
                                        :title="T(objectInfo.text.plural)"
                                    >
                                        <span class="icon-circle" :class="'bg-' + objectInfo.appearance.color">
                                            <i :class="objectInfo.appearance.icon"></i>
                                        </span>
                                        <span class="link-text">
                                            {{ T(objectInfo.text.dashboard) }}
                                        </span>
                                    </a>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>

            </template>

            <template v-if="hasOneOfLicenses(activeAccountId, ['unifiedSecurityConsole'], 'valid')">
                <div class="col-xs-24 padding-xs margin-xs-b-2">
                    <h2 class="padding-xs-t-2 padding-xs-b border-bottom">
                        <span>
                            <i class="fa-fw fal fa-server"></i>Unified Security Console
                        </span>
                    </h2>
                </div>
                <div class="col-xs-24 padding-xs-0">
                    <div class="row flexrow flex-wrap">
                        <template v-for="objectInfo, objectType in resources.registeredProducts.unifiedSecurityConsole.objectTypes">
                            <template 
                                v-if="objectInfo.appearance
                                    && objectInfo.appearance.showOnDashboard
                                    && areConditionsFulfilled(undefined, objectInfo.appearance.showConditions || [])
                                    "
                            >
                                <div class="col-xs-6 col-lg-2 padding-xs text-center">
                                    <a 
                                        class="icon-link"
                                        :href="objectInfo.link.href.replace('{tenantDomain}', activeAccountId + '.sms')"
                                        :target="objectInfo.link.target"
                                        :title="T(objectInfo.text.plural)"
                                    >
                                        <span class="icon-circle" :class="'bg-' + objectInfo.appearance.color">
                                            <i :class="objectInfo.appearance.icon"></i>
                                        </span>
                                        <span class="link-text">
                                            {{ T(objectInfo.text.dashboard) }}
                                        </span>
                                    </a>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>

            </template>
        </div>
    </section>
</template>
