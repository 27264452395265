import { T } from "@/classes/i18n";
import { useGlobalMixin } from "@/mixins/mixins.global";

const inputFileComponent = {
    "name":"input-file",
    "template": `
        <label style="margin: 0px; white-space: nowrap; display: inline-block;" class="file">
            <div :class="{'hidden':modelValue != ''}">
                <input
                    class="noBehaviour"
                    style="display:none"
                    type="file"
                    v-on:change="onInputChange($event)"
                >
                <div class="uploadbox"></div>
                <input type="hidden" :name="name || undefined" v-model="modelValue"/>
            </div>
            <div>
                <a
                    v-if="modelValue == ''"
                    class="btn btn-primary"
                >
                    <i class="fal fa-fw fa-upload"></i> {{ T('Select file') }}
                </a>
                <template v-if="error">
                    <br>
                    <span class="label bg-red">{{ error }}</span>
                </template>
            </div>
            <div :class="{'hidden':modelValue == ''}">
                <div
                    :title="T('Change file')"
                    style="display:inline-block;"
                >
                    <i class="fal fa-file-alt"></i>
                    {{ filename }}
                </div>
                &nbsp;
                &nbsp;
                <a
                    v-if="modelValue != ''"
                    class="btn btn-sm btn-primary"
                    v-on:click.prevent.stop="deleteFile()"
                    :title="T('Delete file')"
                >
                    <i class="fal fa-fw fa-trash"></i>
                </a>
            </div>
        </label>
    `,
    mixins: [useGlobalMixin()],
    "props": {
        "modelValue": {
            type: Object,
            default: () => { return {}},
            required: true
        },
        "name":"",
        "allowedTypes":{
            type:Array,
            default:() => [ "application/pkcs8", "application/pkcs10", "application/pkix-cert", "application/pkix-crl", "application/pkcs7-mime", "application/x-x509-ca-cert", "application/x-x509-user-cert", "application/x-pkcs7-crl", "application/x-pem-file", "application/x-pkcs12" ],
            required:false
        },
    },
    data() {
        return {
            "filename":"",
            "error":""
        }
    },
    "methods":{
        "deleteFile":function(this:any) {
            $(this.$el).find('input').val("")
            this.filename = ""
            this.onInputChange()
        },
        "setValue":function(this:any,value:any) {
            this.$emit('update:modelValue', value)
        },
        "onInputChange":async function(this:any,e?:any) {
            if(this.$parent.field) {
                this.$parent.field.errors = []
            }
            this.error = ""
            let target = e ? e.target : $(this.$el).find('input')[0]
            let file = target.files ? target.files[0] : undefined
            if(file) {
                if(this.allowedTypes.indexOf(file.type) == -1) {
                    if(this.$parent.field) {
                        this.$parent.field.errors.push(T("Wrong filetype")+": " + file.type)
                    } 
                    else {
                        this.error = T("Wrong filetype")+": " + file.type
                    }
                    file = {}
                    this.filename = ""

                }
                this.setValue(file ? this.returntype == "base64" ? await this.toBase64(file) : file : {})
                this.filename = file.name
            }
            else {
                this.setValue("")
                this.filename = ""
            }
        },
        "toBase64": function(file:any) {
            return new Promise((resolve:any, reject:any) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
    },
    "mounted":function(this:any) {
    }
}
export default inputFileComponent
