import ObjectType, { type AccountId, type GetPropertiesObjectList, type ItemlistItemDetail, type Label, type ObjectTypeObject, type ObjectTypePayload } from "../objectType";
import config from "@/classes/config";
import devLog from "@/classes/log";
import requestHandler from "@/queries/requests";
import apis from "@/classes/apis";
import jsonHelpers from "@/helpers/helpers.json";
import type { UscUtm } from "@/classes/unifiedSecurityConsole/uscUtms";
import deviceHelpers from "@/helpers/helpers.devices";
import { T } from "@/classes/i18n";
import stringHelpers from "@/helpers/helpers.strings";
import numberHelpers from "@/helpers/helpers.numbers";
import objectStores from "@/classes/init";
import getterHelpers from "@/helpers/helpers.getters";
import { ActionTypes, MutationTypes } from "@/store/vuex.store";
import ipaddr from "@/lib/ipaddr"
import websocketHandler from "@/classes/websocket";
import arrayHelpers from "@/helpers/helpers.arrays";
import type { Modal } from "@/templates/templates/modal";
import tenantHelpers from "@/helpers/helpers.tenants";
import { Button } from "@/classes/buttons";
import products from "..";
import router from "@/router/router";
import dialogs from "@/dialogs/dialogs";
import { type TableEntryIcons } from "@/templates/components/tableNext.vue";
import useRouterStore from "@/router/routerStore";

export interface SunTopology extends ObjectType<SunTopology> {
    "id": string,
    "data":{
        "coreId":string,
        "properties": {
            "interfaceId": number
            "listenPort"?: number
            "transferNetwork"?:string
            "hostname"?:string
        }
        "satellites":SunSatellite[]
    },
    "changes":{
        "data": SunTopologyDiff
    },
    "actions":{
        "isReversible":boolean,
        "isPublishable":boolean
    }
}
export interface SunTopologyResponse {
    "id": string,
    "data":{
        "coreId":string,
        "properties": {
            "interfaceId": number
            "listenPort"?: number
            "transferNetwork"?:string
            "hostname"?:string
        }
        "satellites":SunSatellite[]
    },
    "changes": SunTopologyDiff
}

export type CreateNode = {
    name: string
    node_address: string
    node_zone: { id: number; name: string }
}

export type CreateNodeGroup = {
    name: string
    nodes: CreateNode[]
}

export type AddNodePayload = CreateNode | (CreateNodeGroup & { nodes: (CreateNode | { id: number })[] })

export type CreateService = {
    name: string
    protocol: string
    ct_helper?: string
    "src-ports"?: string[]
    "dst-ports"?: string[]
    "icmp-types"?: string[]
}

export type CreateServiceGroup = {
    name: string
    services: CreateService[]
}

export type SunTopologyDiff = {
    "addedNodes": string[]
    "removedNodes": string[]
    "changedNodes": SunTopologyChangedNode[]
    "changedSites": SunTopologyChangedSite[]
}

export type SunTopologyChangedSite = {
    "serverId": string
    "clientId": string
    "rules": { 
        "added": string[]; 
        "removed": string[]; 
        "changed": string[] 
    }
}

export type SunTopologyChangedNode = {
    "nodeId": string
    "properties": {
        "added": Record<string, any>
        "removed": Record<string, any>
        "changed": Record<string, any>
    }
}

export interface SunSatellite {
    "id": string,
    "rules":SunRule[]
    "errors":string[]
    "siteIps":{
        "clientAddress":string
        "serverAddress":string
    }
    "state":string,
    "toBeDeleted":boolean
}

export interface SunSatelliteOnAdd {
    "id": string,
    "rules"?: SunRule[]
}
export enum PublishState {
    NOT_PUBLISHED = "NOT_PUBLISHED",
    PENDING = "PENDING",
    PUBLISHED = "PUBLISHED",
    FAILED = "FAILED" 
}

export interface SunNkViewInterface {
    id: number
    name: string
    description?: string
    isCoreCompatible:boolean
    type: number
    owner_id: number
    permissions: number
    refby_uuids: string[]
    interface_type: number
    flags?: string[]
    addresses?: {
        address:string,
        public:boolean
    }[]
    public_key?: string
    listen_port?: number
    options?: { name: string; value: string }[]
    dynDns?: {
        hostname?: string
    }
}
export interface SunNkViewNodeZone {
    id: number
    name: string
    description: string
    type: number
    permissions: number
    flags: string[]
    zone_interface: string
}
export interface SunNkViewNode {
    id: number
    name: string
    type: number
    static_node_addresses: string[]
    description: string
    owner_id: number
    permissions: number
    refby_uuids: string[]
    node_address: string
    /**
     * If node_refs is not empty, the node is a group node
     */
    node_refs?: string[]
    node_zone: SunNkViewNodeZone
}
export interface SunNkViewService {
    "id": number
    "name": string
    "description": string
    "type": number
    "refby_uuids": string[]
    "protocol_id": number
    "protocol": string
    "ct_helper_id": number
    "ct_helper"?: string
    "src-ports"?: string[],
    "dst-ports"?: string[],
    "service_refs"?:string[]
    /**
     * If the service is not Published but is a Service Group
     */
    "services"?: SunNkViewService[]
}

export interface SunNetworkView {
    "interfaces":SunNkViewInterface[],
    "nodes":SunNkViewNode[],
    "services": SunNkViewService[],
    
}
export interface SunUtmNode {
    "utmId":string,
    "online":boolean,
    "nkView": null | SunNetworkView,
}

export interface SunRule {
    "id":string,
    "src": {
        "utmId": string
        "node": SunNkViewNode
    }
    "dst": {
        "utmId": string
        "node": SunNkViewNode
        "service": SunNkViewService
    }
    
}

export type SunReferenceNode = { id: number } | { name: string }


export interface SunRuleOnUpdate {
    "id":string,
    "src": {
        "utmId": string
        "node": SunReferenceNode
    }
    "dst": {
        "utmId": string
        "node": SunReferenceNode
        "service": SunReferenceNode
    }
}
export interface SunRuleOnAdd {
    "src": {
        "utmId": string
        "node": SunReferenceNode
    }
    "dst": {
        "utmId": string
        "node": SunReferenceNode
        "service": SunReferenceNode
    }
}



const geoIPRegEx = /^GEOIP:[A-Z]{2}$/,
    hostnameLabelRegEx = /^[a-z\d_]([a-z\d-_]{0,61}[a-z\d_]){0,1}$/i,
    hostnameLastLabelRegEx = /^[a-z_][a-z-_]{0,61}[a-z_]$/i


export class SunTopologies extends ObjectType<SunTopology> {
    public showDialogOnBeforeUnload = false
    public v4CidrToIpLimit = {
        "30": 4,
        "29": 8,
        "28": 16,
        "27": 32,
        "26": 64,
        "25": 128,
        "24": 256,
        "23": 512,
        "22": 1024,
        "21": 2048,
        "20": 4096,
        "19": 8192,
        "18": 16384,
        "17": 32768,
        "16": 65536
    }
    public v6CidrToIpLimit = {
        "126": 4,
        "125": 8,
        "124": 16,
        "123": 32,
        "122": 64,
        "121": 128,
        "120": 256,
        "119": 512,
        "118": 1024,
        "117": 2048,
        "116": 4096,
        "115": 8192,
        "114": 16384,
        "113": 32768,
        "112": 65536,
    }
    constructor(payload: ObjectTypePayload<SunTopology>) {
        super(payload)
        this.itemlist.getSortingOptions = () => [
            {
                "id": "name",
                "text": "Name"
            }
        ]
        this.itemlist.getInfoBoxContent = (accountId: string, itemlistComponent: any) => {
            let result = ""
            return result
        }
        this.itemlistItem.onClick = (accountId: string, item) => {
            
        }
        this.itemlistItem.getLabels = (accountId: string, item) => {
            let result: Label[] = []
            return result
        }

        this.itemlistItem.getDetails = (accountId, item?, component?) => {
            component = component?.exposed ? component?.exposed : component
            let result : ItemlistItemDetail[] = []
            return result
        }

        this.itemlistItem.hasCheckbox = () => {
            return false
        }
        this.itemlistItem.isClickable = (accountId, item) => {
            return true
        }

        this.itemlistItem.getDisabledState = (accountId, item) => {
            return false
        }

        this.queries.addObjectToApi = async (accountId, object, customerId?, updateStore = true) => {
            let result: SunTopology | Error
            try {
                let response = await requestHandler.request(this.options.apiInfo.addObjectMethod, this.getAddObjectApiUrlPath(accountId, customerId), object)
                if (!jsonHelpers.isObjectEmpty(response)) {
                    result = response
                    ;(<SunTopology>result).changes = {
                        data: {
                            "addedNodes":[],
                            "changedNodes":[],
                            "removedNodes":[],
                            "changedSites":[],
                        }
                    }
                    ;(<SunTopology>result).actions = {
                        'isPublishable':false,
                        'isReversible':true
                    }
                }
                else {
                    throw "Error getting objects"
                }
                if (updateStore) {
                    this.useStore?.().addObjectTypeObject(accountId, result as SunTopology)
                }
                return result
            }
            catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                throw e as Error
            }
        }

    }


    /**
    * delete topology in api
    * @param accountId 
    * @param topologyId
    */
    public deleteTopologyFromApi = async (accountId: AccountId, topologyId: string) => {
        try {
            if (!topologyId) throw "Missing topologyId"
            let response = await requestHandler.request("DELETE", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId)
            response = apis.parseApiResponse(response)

            return true
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * Gets nodes from api
    * @param accountId 
    * @param objectId 
    * @param props
    * @param updateStore
    */
    public getNodeObjectsFromApi = async (accountId: AccountId, props?: GetPropertiesObjectList) => {
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: SunUtmNode[] | Error
        try {
            let response = await requestHandler.request("GET", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/nodes/utms" + propertiesString)
            response = apis.parseApiResponse(response)
            if (Array.isArray(response)) {
                result = response as SunUtmNode[]
            }
            else {
                throw "Error getting objects"
            }
            return result
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * Gets single node from api
    * @param accountId 
    * @param objectId 
    * @param props
    * @param updateStore
    */
    public getNodeObjectFromApi = async (accountId: AccountId, nodeId: string, props?: GetPropertiesObjectList) => {
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: SunUtmNode | Error
        try {
            if (!nodeId) throw "Missing nodeId"
            let response = await requestHandler.request("GET", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/nodes/utms/" + nodeId  + propertiesString)
            response = apis.parseApiResponse(response)
            if (!jsonHelpers.isObjectEmpty(response)) {
                result = response as SunUtmNode
            }
            else {
                throw "Error getting objects"
            }
            return result
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
     * 
     * @param accountId 
     * @param nodeId 
     * @param statteliteId 
     */
    getNodeObjectWithUnpublishedChangesFromApi = async (accountId: AccountId, nodeId: string, topologyId: string) => {
        let result: SunUtmNode | Error
        try {
            if (!nodeId) throw "Missing nodeId"
            let allNodes = await requestHandler.request("GET", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/sun/topologies/" + topologyId + "/nodes/utms")
            allNodes = apis.parseApiResponse(allNodes)
            if (!jsonHelpers.isObjectEmpty(allNodes) && Array.isArray(allNodes)) {
                result = allNodes.find(node => node.utmId === nodeId)
                if(!result) throw "Could not find node for nodeId: " + nodeId
            }
            else {
                throw "Error getting objects"
            }
            return result
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }

    }
    getLocalNodesOrServices = async (accountId: string, nodeId: string, topologyId: string, type: "objects" | "services"): Promise<Error | { [key: string]: SunNkViewService | SunNkViewNode }> => {
        let result: Error | { [key: string]: SunNkViewService | SunNkViewNode }
        try {
            if (!nodeId) throw "Missing nodeId"

            let objects = await requestHandler.request("GET", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/sun/topologies/" + topologyId + "/nodes/" + nodeId + "/" + type)
            
            objects = apis.parseApiResponse(objects)
            if (typeof objects == "object") {
                result = objects
            }
            else {
                throw "Error getting objects"
            }
            return objects
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * Adds single node to api
    * @param accountId 
    * @param topologyId  
    * @param object
    * @param props
    * @param updateStore
    */
    public addSatelliteToApi = async (accountId: AccountId,topologyId:string, object:SunSatelliteOnAdd, props?: GetPropertiesObjectList) => {
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: SunSatellite | Error
        try {
            if (!object) throw "Missing object"
            if (!topologyId) throw "Missing topologyId"
            let response = await requestHandler.request("POST", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/nodes" + propertiesString,object)
            response = apis.parseApiResponse(response)
            if (!jsonHelpers.isObjectEmpty(response)) {
                result = response as SunSatellite
            }
            else {
                throw "Error getting objects"
            }
            return result
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }

    /**
    * delete single rule in api
    * @param accountId 
    * @param topologyId
    * @param satelliteId
    * @param ruleId
    */
    public deleteSatelliteInApi = async (accountId: AccountId, topologyId: string, satelliteId: string) => {
        try {
            if (!topologyId) throw "Missing topologyId"
            if (!satelliteId) throw "Missing satelliteId"
            let response = await requestHandler.request("DELETE", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/nodes/" + satelliteId)
            return apis.parseApiResponse(response)
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }



    /**
    * Adds single rule to api
    * @param accountId 
    * @param topologyId
    * @param satelliteId
    * @param object
    * @param props
    * @param updateStore
    */
    public addRuleForSatelliteInApi = async (accountId: AccountId, topologyId: string, satelliteId:string, object: SunRuleOnAdd, props?: GetPropertiesObjectList) => {
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: SunRule | Error
        let response: SunTopology | Error
        try {
            if (!object) throw "Missing object"
            if ((<SunRule>object).id) throw "Object should not contain property \"id\""
            if (!topologyId) throw "Missing topologyId"
            if (!satelliteId) throw "Missing satelliteId"
            response = await requestHandler.request("PUT", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/nodes/" + satelliteId + "/rules" + propertiesString, object)
            return apis.parseApiResponse(response)

        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * updates single rule in api
    * @param accountId 
    * @param topologyId
    * @param satelliteId
    * @param object
    * @param props
    * @param updateStore
    */
    public updateRuleForSatelliteInApi = async (accountId: AccountId, topologyId: string, satelliteId:string, object: SunRuleOnUpdate, props?: GetPropertiesObjectList) => {
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: SunSatellite | Error
        try {
            if (!object) throw "Missing object"
            if (!object.id) throw "Missing property \"id\" in object"
            if (!topologyId) throw "Missing topologyId"
            if (!satelliteId) throw "Missing satelliteId"
            let response = await requestHandler.request("PUT", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/nodes/" + satelliteId + "/rules" + propertiesString, object)
            response = apis.parseApiResponse(response)
            if (!jsonHelpers.isObjectEmpty(response)) {
                result = response as SunSatellite
            }
            else {
                throw "Error getting objects"
            }
            return result
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * delete single rule in api
    * @param accountId 
    * @param topologyId
    * @param satelliteId
    * @param ruleId
    */
    public deleteRuleForSatelliteInApi = async (accountId: AccountId, topologyId: string, satelliteId: string, ruleId:SunRule['id']) => {
        try {
            if (!topologyId) throw "Missing topologyId"
            if (!satelliteId) throw "Missing satelliteId"
            if (!ruleId) throw "Missing ruleId"
            let response = await requestHandler.request("DELETE", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/nodes/" + satelliteId + "/rules/" + ruleId)
            return apis.parseApiResponse(response)
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * publish topology in api
    * @param accountId 
    * @param topologyId
    * @returns current sate of the topology
    */
    public publishTopologyInApi = async (accountId: AccountId, topologyId: string,pin:string) => {
        let result: { publishId:string } | Error
        try {
            if (!accountId) throw "Missing accountId"
            if (!topologyId) throw "Missing topologyId"
            if (!pin) throw "Missing pin"
            let response = await requestHandler.request("POST", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/commit",{ "pin":pin})
            response = apis.parseApiResponse(response)
            if (!jsonHelpers.isObjectEmpty(response)) {
                this.showDialogOnBeforeUnload = false
                result = response
            }
            else {
                throw "Error publishing topology"
            }
            return result
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }
    /**
    * publish topology in api
    * @param accountId 
    * @param topologyId
    */
    public restoreTopologyInApi = async (accountId: AccountId, topologyId: string) => {
        let result: true | Error
        try {
            if (!accountId) throw "Missing accountId"
            if (!topologyId) throw "Missing topologyId"
            let response = await requestHandler.request("POST", this.getApiUrl() + "/tenants/" + accountId + ".sms/sun/topologies/" + topologyId + "/restore")
            response = apis.parseApiResponse(response)
            if (!jsonHelpers.isObjectEmpty(response)) {
                result = true
            }
            else {
                throw "Error restoring topology"
            }
            return result
        }
        catch (e: any) {
            devLog.log("ObjectType", e.message, e, "error")
            throw e as Error
        }
    }




    getUtmLabels = (accountId: string, item:UscUtm) => {
        let result: Label[] = []
        if(item) {
            if (item?.cluster?.isCluster === true &&  item.license.clusterUuid) {
                result.push(
                    {
                        title: "Cluster " + deviceHelpers.getShortDeviceId(item.license.clusterUuid, 4),
                        text:"",
                        class: "bg-blue",
                        icon: "fa fa-circle-nodes"
                    }
                )
            }
            if (objectStores.uscUtms.utmHasLicenseConflict(accountId, item)) {
                result.push({
                    "text": T('License conflict'),
                    "title": T('A license conflict occurs when the same license is installed on several UTMs. In addition to billing problems, this can lead to unpredictable side effects and incorrect configurations. Make sure that a unique license is installed on each UTM to ensure stable and reliable operation of the UTM.'),
                    "class": "bg-red",
                    "icon": "fal fa-fw fa-exclamation-triangle",
                    "displayType": "label"
                })
            }
        }

        return result
    }

    view = {
        getSatellite: (topology: SunTopology, satelliteId:string) => {
            return (topology.data.satellites || []).find((satellite: any) => {
                return satellite.utmId == satelliteId   
            })
        },

        getSatelliteStates: (topology: SunTopology) => {
            let satelliteStates: string[] = [];
            (topology.data.satellites || []).forEach((satellite) => {

                const topologyChanges = topology.changes.data
                let removedNodes = topologyChanges.removedNodes || []
                const hasBeenRemoved = removedNodes.indexOf(satellite.id) != -1 || satellite.toBeDeleted
                const hasBeenAdded = topology.changes.data.addedNodes.indexOf(satellite.id || '') != -1
                const hasBeenChanged = topology.changes.data.changedNodes.some((node) => { return node.nodeId == satellite.id }) || topology.changes.data.changedSites.some((site) => {
                    return site.clientId == satellite.id
                })
                const state = satellite.state != 'FAILED' ? hasBeenAdded || hasBeenChanged || hasBeenRemoved ? 'NOT_PUBLISHED' : satellite.state : 'FAILED';

                satelliteStates.push(state)
            })
            return satelliteStates
        },
        getIconForTableEntry: (accountId:string,entry: any, withColor = true) => {
            let result: TableEntryIcons = []
    
            if (entry) {
                const utm = objectStores.uscUtms.getObjectFromStore(accountId, entry.data ? entry?.data?.coreId : entry.id);
                const thisState = (getterHelpers.useStore()?.getters.getObjects({
                    "accountId": accountId,
                    "productType": "unifiedSecurityConsole",
                    "objectType": "ccutmStates",
                }) || []).find((state: any) => {
                    return entry.data ? entry?.data?.coreId == state.deviceId : entry.id == state.deviceId
                })
    
                const isCoreUtm: boolean = Object.hasOwn((entry?.data || {}), "satellites") && Array.isArray(entry?.data?.satellites)
                const isCluster: boolean = utm?.cluster?.isCluster == true
                const iconClass: string = isCoreUtm ? (thisState?.online == true ? "fal fa-fw fa-crown" : "fal fa-fw fa-crown fa-overlay-xmark") : (thisState?.online == true ? "fal fa-satellite-dish" : "fal fa-satellite-dish fa-overlay-xmark") ;
                const colorClass: string = withColor == true ? thisState?.online == true ? "color-green" : "color-red" : "";
                const title = thisState?.online == true ? T("Connected") : T("Disconneced");
    
                result.push({
                    "class": iconClass + " " + colorClass + " margin-xs-l",
                    "title": title
                })
            }
            return result
        },
        nodeIsGeoIP: (node:SunNkViewNode) => {
            let nodeAddress = typeof node === 'string' ? node : node.node_address;
            return geoIPRegEx.test(nodeAddress!);
        },
        getHostnameValidationMessage: (text: string, forceTestWithTrailingDot:boolean) => {
            let extendedText = forceTestWithTrailingDot === true && text[text.length - 1] !== '.' ? text + '.' : text,
                labels = extendedText.split('.'),
                hasTrailingDot = extendedText[extendedText.length - 1] === '.',
                invalidLabels: string[];

            if (extendedText.length < 1) {
                return T('Too short!');
            } 
            else if (hasTrailingDot && extendedText.length > 255) {
                return T('Too long!');
            } 
            else if (!hasTrailingDot && extendedText.length > 254) {
                return T('Too long!');
            } 
            else {
                if (hasTrailingDot) {
                    labels = labels.slice(0, -1);

                    invalidLabels = labels.filter(function (label, i) {
                        if (i < labels.length - 1) {
                            return !hostnameLabelRegEx.test(label);
                        } else {
                            return !hostnameLastLabelRegEx.test(label);
                        }
                    });
                } else {
                    invalidLabels = labels.filter(function (label, i) {
                        return !hostnameLabelRegEx.test(label);
                    });
                }

                if (invalidLabels.length > 0) {
                    return T('Some labels are invalid:') + ' ' + invalidLabels.join(' ');
                } else {
                    return '';
                }
            }
        },
        getIconClassForNode: (node : SunNkViewNode) => {
            let type : "host"|"network"|"world"|"vpn-host"|"vpn-network"|"interface"|"geo-ip" = 'network'
            if (node.node_address) {
                const nodeIpAddr = new ipaddr(node.node_address)
                const nodeIpCIDR = nodeIpAddr.cidr
                const zone = node.node_zone

                if (this.view.nodeIsGeoIP(node)) {
                    type = 'geo-ip';
                } 
                else {
                    if (nodeIpAddr.isValidIP6Address()) {
                        if (nodeIpCIDR == 128) {
                            type = 'host';
                        } 
                        else if (nodeIpCIDR == 0 && node.node_zone?.name !== 'internal' && node.node_zone?.name !== 'internal_v6') {
                            type = 'world';
                        }
                    } 
                    else if (nodeIpAddr.isValidIP4Address()) {
                        if (nodeIpCIDR == 32) {
                            type = 'host';
                        } 
                        else if (nodeIpCIDR == 0 && node.node_zone?.name !== 'internal' && node.node_zone?.name !== 'internal_v6') {
                            type = 'world';
                        }
                    } 
                    else if (nodeIpAddr.addr !== null && this.view.getHostnameValidationMessage(nodeIpAddr.addr, true) === '') {
                        type = 'host'
                    }
                }

                var zoneFlags = zone.flags || [];
                if (zone.name.indexOf('vpn-') === 0 || zoneFlags.indexOf('POLICY_IPSEC') > -1 || zoneFlags.indexOf('PPP_VPN') > -1) {
                    if (type === 'network') {
                        type = 'vpn-network';
                    } 
                    else if (type === 'host') {
                        type = 'vpn-host';
                    }
                } 
                else if (zoneFlags.indexOf('INTERFACE') > -1) {
                    type = 'interface';
                }
                    
            }
            return 'icon icon-node-' + type;
        },
        getNodeTypeNameFromNodeType: (type: "host" | "network" | "world" | "vpn-host" | "vpn-network" | "interface" | "geo-ip") => {
            const map = {
                "host":"Network Objects",
                "network":"Network Group",
                "world":"World",
                "vpn-host":"VPN Host",
                "vpn-network":"VPN Network",
                "interface":"Interface",
                "geo-ip":"Geo-IP"
            }
            return map[type]
        },
        getSatelliteUtmIdsForCoreUtm: (topology: SunTopology, utmId: string) => {
            return topology.data.satellites.map((satellite) => {
                return satellite.id
            })
        },
        getNodeByUtmId: (nodes: SunUtmNode[], utmId: string) => {
            return nodes.find((node: any) => {
                return node.utmId == utmId
            })
        },
        getNodeInfo: (nodes: SunUtmNode[], utmId: string, nodeId: number) => {
            let utmNode = nodes.find((utmNode: any) => {
                return utmNode.utmId == utmId
            })
            if (utmNode) {
                return utmNode.nkView?.nodes.find((node: any) => {
                    return node.id == nodeId
                })
            }
            else {
                return undefined
            }
        },
        getRuleInfo: (topology: SunTopology, satelliteId: string, ruleId: string) => {
            
            
                return topology.data.satellites.find((satellite) => {
                    return satellite.id == satelliteId
                })?.rules.find((rule) => {
                    return rule.id == ruleId
                })
            
        },
        getServiceInfo: (nodes: SunUtmNode[], utmId: string, serviceId: number) => {
            let utmNode = nodes.find((utmNode) => {
                return utmNode.utmId == utmId
            })
            if (utmNode) {
                return utmNode.nkView?.services.find((service: any) => {
                    return service.id == serviceId
                })
            }
            else {
                return undefined
            }
        },
        addNode: (nodes: SunUtmNode[], nodeInfo: SunUtmNode) => {
            if (!nodeInfo.utmId) {
                console.error("missing utmId")
            }
            else {
                if (!this.view.getNodeByUtmId(nodes, nodeInfo.utmId)) {
                    nodes.push(nodeInfo)
                }
                else {
                    nodes.some((node) => {
                        if (node.utmId == nodeInfo.utmId) {
                            if (nodeInfo.nkView) {
                                node.nkView = nodeInfo.nkView
                            }
                            if(Object.hasOwn(nodeInfo,"online")) {               
                                node.online = nodeInfo.online
                            }
                            return true
                        }
                    })
                }
            }
        },
        getPublishInfoFromApi: async (accountId:string) => {
            const response = await this.queries.getObjectsFromApi(accountId)
            if (
                Array.isArray(response) 
                && response?.length 
                && (response[0].actions.isPublishable === true
                || (response[0].changes.data.addedNodes.length > 0
                || response[0].changes.data.changedNodes.length > 0
                || response[0].changes.data.changedSites.length > 0
                || response[0].changes.data.removedNodes.length > 0))
            ) {
                this.showDialogOnBeforeUnload = true
                return true
            }
            this.showDialogOnBeforeUnload = false
            return false
        },
        getUnpulishedChangesDialog: (accountId:string,onAbort?:Function,loggingOut:boolean=false,switchingTenant:boolean=false) => {
            accountId = tenantHelpers.getAccountId(accountId)
            const activePage = useRouterStore().getActivePage
            let modalOptions : Modal = {
                "id": "unpublishedChanges",
                "accountId": accountId,
                "abortable": true,
                "content": {
                    "title": {
                        "icon": "fal fa-bell",
                        "text": "Unified Network Console - " + T("Unpublished changes")
                    },
                    "body": {
                        "content": loggingOut ? T(`You still have unpublished changes. Please check your changes and publish them if necessary.`) : T(`You still have unpublished changes. Please switch to the Unified Network Console to check your changes and publish them if necessary.`),
                        "use":true
                    }
                },
                "buttons":[
                    {
                        "text": loggingOut == true ? T('Sign out') : switchingTenant ? T('Switch tenant') : T('Close'),
                        "icon": switchingTenant ? "fal fa-sync" : "fal fa-times",
                        "onClick":() => {
                            onAbort?.()
                            getterHelpers.useStore().commit(MutationTypes.removeModal,{ "accountId": accountId})
                        }
                    }, 
                    {
                        "text": activePage == 'unifiedNetworkConfiguration' ? T('Continue editing') : T('Switch to config'),
                        "icon": "fal fa-angle-right",
                        "onClick": async () => {
                            router.navigate('show-tenant-' + accountId + '.sms-unified-network')
                            getterHelpers.useStore().commit(MutationTypes.removeModal, { "accountId": accountId })
                        }
                    }
                ]
            }
            getterHelpers.useStore().dispatch(ActionTypes.addModal,modalOptions)
        },
        mergeTopologies: (oldTopology:SunTopology,newTopology:SunTopology|SunTopologyResponse) => {
            // update changes
            oldTopology.changes.data.addedNodes = (<SunTopology>newTopology).changes.data?.addedNodes || (<SunTopologyResponse>newTopology).changes.addedNodes
            oldTopology.changes.data.changedNodes = (<SunTopology>newTopology).changes.data?.changedNodes || (<SunTopologyResponse>newTopology).changes.changedNodes
            oldTopology.changes.data.changedSites = (<SunTopology>newTopology).changes.data?.changedSites || (<SunTopologyResponse>newTopology).changes.changedSites
            oldTopology.changes.data.removedNodes = (<SunTopology>newTopology).changes.data?.removedNodes || (<SunTopologyResponse>newTopology).changes.removedNodes
            // update core properties
            oldTopology.data.properties = newTopology.data.properties
            // update satellites
            newTopology.data.satellites.forEach((newSatellite) => {
                let oldSatellite = oldTopology.data.satellites.find((satellite) => {
                    return satellite.id == newSatellite.id
                })
                if(oldSatellite) {
                    oldSatellite.errors = newSatellite.errors
                    oldSatellite.rules = newSatellite.rules
                    oldSatellite.state = newSatellite.state
                    oldSatellite.toBeDeleted = newSatellite.toBeDeleted
                    oldSatellite.siteIps.clientAddress = newSatellite.siteIps.clientAddress
                    oldSatellite.siteIps.serverAddress = newSatellite.siteIps.serverAddress
                }
                else {
                    oldTopology.data.satellites.push(newSatellite)
                }
            })
            // delete old satellites that are not in the new topology
            oldTopology.data.satellites = oldTopology.data.satellites.filter((oldSatellite) => {
                return (newTopology.data.satellites.some((newSatellite) => {
                    return newSatellite.id == oldSatellite.id
                }))
            })
        },
        getAddCoreDialog: (accountId:string,nodes:SunUtmNode[],onSubmit?:(result:any) => void,onError?:(e:any) => void) => {
            const modal: Modal = {
                "accountId": accountId,
                "id": "addCoreUtm",
                "content": {
                    "title": {
                        "text": T('Add Core-UTM'),
                        "icon": "fal fa-plus"
                    },
                    "body": {
                        "component": "add-edit-core-utm",
                        "properties": {
                            "nodes": nodes
                        }
                    }
                },
                "buttons": [
                    {
                        'text': T('Abort'),
                        'icon': 'fal fa-times',
                        'disabled': false,
                        'onClick': () => {
                            getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                        }
                    },
                    {
                        'text': T('Add UTM'),
                        'icon': 'fal fa-plus',
                        'disabled': false,
                        'onClick': async (modalWrapper: any) => {
                            try {
                                modalWrapper.buttons[1].loading = true
                                modalWrapper.$refs.modalComponent.clearErrors()
                                const selectedUtmId = modalWrapper.$refs.modalComponent.selectedUtm
                                const utm = objectStores.uscUtms.getObjectFromStore(accountId, selectedUtmId)
                                if (utm) {
                                    const payload = {
                                        "coreId": selectedUtmId,
                                        "properties": <any>{
                                            "interfaceId": modalWrapper.$refs.modalComponent.selectedInterface,
                                        },
                                        "satellites": []
                                    }
                                    if (modalWrapper.$refs.modalComponent.port) {
                                        payload.properties.listenPort = modalWrapper.$refs.modalComponent.port
                                    }
                                    if (modalWrapper.$refs.modalComponent.transferNetwork) {
                                        payload.properties.transferNetwork = modalWrapper.$refs.modalComponent.transferNetwork
                                    }
                                    if (modalWrapper.$refs.modalComponent.selectedHostname) {
                                        payload.properties.hostname = modalWrapper.$refs.modalComponent.selectedHostname
                                    }
                                    let result = await products.unifiedNetwork.topologies.queries.addObjectToApi(accountId, payload)
                                    onSubmit?.(result)
                                    getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                                }
                            }
                            catch (e) {
                                console.error(e)
                                onError?.(e)
                                modalWrapper.$refs.modalComponent.addError(e)
                                modalWrapper.buttons[1].loading = false
                            }
                        }
                    }
                ]
            }
            getterHelpers.useStore().commit(MutationTypes.addModal, modal)
        },
        getDeleteCoreDialog: (accountId:string,topologyId:string,coreId:string,doPublish:boolean=false,isReversible:boolean=false,onSuccess?:() => void,onError?:(e:any) => void) => {
            const modal: Modal = {
                "accountId": accountId,
                "id": "deleteCoreUtm",
                "content": {
                    "title": {
                        "text": T('Delete Core-UTM'),
                        "icon": "fal fa-trash"
                    },
                    "body": {
                        "component": "delete-core-utm",
                        "properties": {
                            "revertChanges": () => { products.unifiedNetwork.topologies.view.getRevertDialog(accountId,topologyId) },
                            "doPublish": doPublish,
                            "isReversible":isReversible
                        }
                    }
                },
                "buttons": [
                    {
                        'text': T('Abort'),
                        'icon': 'fal fa-times',
                        'disabled': false,
                        'onClick': () => {
                            getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                        }
                    },
                    {
                        'id': "publishConfig",
                        'text': T('Delete'),
                        'icon': 'fal fa-trash',
                        'disabled': true,
                        'onClick': async (modalWrapper: any) => {
                            try {
                                const pin = modalWrapper.$refs.modalComponent.pin
                                const setError = modalWrapper.$refs.modalComponent.setError
                                const clearErrors = modalWrapper.$refs.modalComponent.clearErrors
                                const clearPin = modalWrapper.$refs.modalComponent.clearPin
                                
                                modalWrapper.buttons[0].disabled = true
                                modalWrapper.buttons[1].loading = true
                                modalWrapper.buttons[1].disabled = true

                                clearErrors()
                                let thisTopology = this.useStore?.().getObjectStoreObject(accountId,topologyId)
                                
                                let result = await products.unifiedNetwork.topologies.deleteSatelliteInApi(accountId, topologyId, coreId)
                                
                                // If should be published
                                if (doPublish) {

                                    // publish this delete
                                    let response = await products.unifiedNetwork.topologies.publishTopologyInApi(accountId, topologyId || '', pin)
                                    if (!(response instanceof Error) && response?.publishId != undefined) {
                                        let publishId = response?.publishId
                                        // wait for publish answer
                                        if (websocketHandler.hasHookWithId("unifiedNetworkWaitForPublishAnswer") == false) {
                                            getterHelpers.useStore().commit(MutationTypes.addSubscriptionHook, {
                                                "accountId": accountId,
                                                "hookKey": "unifiedNetworkWaitForPublishAnswer",
                                                "hookFunction": (message: any) => {
                                                    if(message.topic == "/sun/publish" && message.data.publishId == publishId) {

                                                        if(message.data?.error == "LOCKED_PIN") {
                                                            clearPin()
                                                            setError('The UTM was locked due to too many failed attempts')
                                                        }
                                                        else if (message.data?.error == "INVALID_PIN") {
                                                            clearPin()
                                                            setError('The PIN you have entered is incorrect')
                                                        }
                                                        else if (message.data?.error == "DISABLED_PIN") {
                                                            clearPin()
                                                            setError('The PIN authentication method is disabled for this UTM and the action is therefore not available.')
                                                        }
                                                        else if(message.data?.success === true) {
                                                            this.showDialogOnBeforeUnload = false
                                                            // Delete & Publish succeeded -> delete topology from store
                                                            this.useStore?.().deleteObjectTypeObjectFromStore(accountId, topologyId)
                                                            onSuccess?.()
                                                            getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                                                        }
                                                        // stop waiting for answer
                                                        getterHelpers.useStore().commit(MutationTypes.deleteSubscriptionHook, {
                                                            "accountId": accountId,
                                                            "hookKey": "unifiedNetworkWaitForPublishAnswer",
                                                        })
                                                        modalWrapper.buttons[0].disabled = false
                                                        modalWrapper.buttons[1].loading = false
                                                        modalWrapper.buttons[1].disabled = false
                                                    }
                                                }
                                            })
                                        }
                                    }
                                    else {
                                        // error or missing publishId in response
                                        modalWrapper.buttons[0].disabled = false
                                        modalWrapper.buttons[1].loading = false
                                        modalWrapper.buttons[1].disabled = false
                                        onError?.(response)
                                        
                                    }
                                }
                                else {
                                    // has been added after last publish -> delete from store (No publish needed)
                                    this.useStore?.().deleteObjectTypeObjectFromStore(accountId, topologyId)
                                    // request the topology
                                    modalWrapper.buttons[0].disabled = false
                                    modalWrapper.buttons[1].loading = false
                                    modalWrapper.buttons[1].disabled = false
                                    onSuccess?.()
                                    getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                                }
                                
                            }
                            catch (e) {
                                console.error(e)
                                modalWrapper.buttons[0].disabled = false
                                modalWrapper.buttons[1].loading = false
                                modalWrapper.buttons[1].disabled = false
                                getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                            }
                        }
                    }
                ]
            }
            getterHelpers.useStore().commit(MutationTypes.addModal, modal)
        },
        getRevertDialog: (accountId:string,topologyId:string,onSuccess?:() => void,onAbort?:() => void,onError?:(e:any) => void) => {
            dialogs.misc.confirmDialog(accountId, T("Discard changes"), T("Do you really want to discard the changes on this configuration?"), async (modal:any) => {
                try {
                    await products.unifiedNetwork.topologies.restoreTopologyInApi(accountId, topologyId)
                    modal.buttons[1].loading = false
                    modal.buttons[1].disabled = true
                    modal.buttons[1].text = T('Discarded')
                    modal.buttons[1].icon = "fal fa-check"
                    onSuccess?.()
                    setTimeout(() => {
                        getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': accountId })
                    }, 2000)
                }
                catch (e) {
                    console.error(e)
                    onError?.(e)
                }
            }, undefined, T("Discard"), "fal fa-clock-rotate-left", true, "fal fa-clock-rotate-left",onAbort,false)
        },
        getPublishDialog: async (accountId:string,topologyId:string,nodes:SunUtmNode[],onSuccess:() => void,onAbort:() => void,onError?:(e:any) => void) => {
            await products.unifiedNetwork.topologies.view.getPublishInfoFromApi(accountId)
                let modalOptions: Modal = {
                    "id": "publishChanges",
                    "accountId": accountId,
                    "abortable": true,
                    "content": {
                        "title": {
                            "icon": "fal fa-save",
                            "text": "Unified Network Console - " + T("Publish configuration")
                        },
                        "body": {
                            "component": "unc-publish-changes",
                            "properties": {
                                nodes:nodes
                            },
                            "use": true
                        }
                    },
                    "buttons": [
                        {
                            "text": T('Abort'),
                            "icon": "fal fa-times",
                            "onClick": () => {
                                onAbort()
                                getterHelpers.useStore().commit(MutationTypes.removeModal, { "accountId": accountId })
                            }
                        },
                        {
                            "id":"publishConfig",
                            "text": T('Publish'),
                            "icon": "fal fa-save",
                            "onClick": async (modal: any) => {
                                const pin = modal.$refs.modalComponent.pin
                                const setError = modal.$refs.modalComponent.setError
                                const clearErrors = modal.$refs.modalComponent.clearErrors
                                const clearPin = modal.$refs.modalComponent.clearPin
                                clearErrors()
                                modal.buttons[0].disabled = true
                                modal.buttons[1].loading = true
                                modal.buttons[1].disabled = true
                                try {
                                    let response = await products.unifiedNetwork.topologies.publishTopologyInApi(accountId, topologyId || '',pin)
                                    
                                    if (!(response instanceof Error) && response?.publishId != undefined) {
                                        let publishId = response?.publishId

                                        if (websocketHandler.hasHookWithId("unifiedNetworkWaitForPublishAnswer") == false) {
                                            getterHelpers.useStore().commit(MutationTypes.addSubscriptionHook, {
                                                "accountId": accountId,
                                                "hookKey": "unifiedNetworkWaitForPublishAnswer",
                                                "hookFunction": (message: any) => {
                                                    if (message.topic == "/sun/publish" && message.data.publishId == publishId) {

                                                        modal.buttons[0].disabled = false
                                                        modal.buttons[1].loading = false
                                                        modal.buttons[1].disabled = false

                                                        if (message.data?.error == "LOCKED_PIN") {
                                                            clearPin()
                                                            setError('The UTM was locked due to too many failed attempts')
                                                        }
                                                        else if (message.data?.error == "INVALID_PIN") {
                                                            clearPin()
                                                            setError('The PIN you have entered is incorrect')
                                                        }
                                                        else if (message.data?.error == "DISABLED_PIN") {
                                                            clearPin()
                                                            setError('The PIN authentication method is disabled for this UTM and the action is therefore not available.')
                                                        }
                                                        else if (message.data?.success === true) {
                                                            this.showDialogOnBeforeUnload = false
                                                            modal.buttons[0].disabled = false
                                                            modal.buttons[1].loading = false
                                                            modal.buttons[1].disabled = false
                                                            onSuccess()                                                                      
                                                            getterHelpers.useStore().commit(MutationTypes.removeModal, { "accountId": accountId })  
                                                        }
                                                        getterHelpers.useStore().commit(MutationTypes.deleteSubscriptionHook, {
                                                            "accountId": accountId,
                                                            "hookKey": "unifiedNetworkWaitForPublishAnswer",
                                                        })
                                                    }
                                                }
                                            })
                                        }
                                    }
                                    else {
                                        modal.buttons[0].disabled = false
                                        modal.buttons[1].loading = false
                                        modal.buttons[1].disabled = false
                                        onError?.(response)
                                    }
                                }
                                catch(e) {
                                    modal.buttons[0].disabled = false
                                    modal.buttons[1].loading = false
                                    modal.buttons[1].disabled = false
                                    onError?.(e)
                                }
                            },
                            "disabled": true
                        }
                    ]
                }
                getterHelpers.useStore().dispatch(ActionTypes.addModal, modalOptions)

        },
        handleWebsocketHooks: (accountId:string,nodes: SunUtmNode[]) => {
            if(websocketHandler.hasHookWithId("unifiedNetwork") == false) {
                getterHelpers.useStore().commit(MutationTypes.addSubscriptionHook, {
                    "accountId": accountId,
                    "hookKey": "unifiedNetwork",
                    "hookFunction": async (message: any) => {

                        if (message.topic == "/usc/utm/message" && message.data.clientContext == "handled-model-nwkview-get") {
                            const messageData = message.data
                            delete messageData.clientContext
                            delete messageData.tenantDomain
                            if(messageData.data) {
                                messageData.nkView = messageData.data
                                delete messageData.data
                            }
                            const nodeInfo = messageData as SunUtmNode
                            this.view.addNode(nodes, nodeInfo)
                        }
                        if (message.topic == "/sun/topology/site/state/change") {
                            const messageState: {
                                "topologyId": string,
                                "satelliteId": string,
                                "state": PublishState,
                                "coreId": string
                            } | undefined = message?.data
                            if (messageState) {
                                const topology = this.useStore?.().getObjectStoreObject(accountId, messageState.topologyId)
                                if (topology) {
                                    const satellite = topology.data.satellites.find((satellite) => { return satellite.id == messageState.satelliteId })
                                    if (satellite) {
                                        satellite.state = messageState.state
                                    }
                                    else {
                                        console.error('satellite not found')
                                    }
                                }
                                else {
                                    console.error('topology not found')
                                }
                            }
                        }

                    }
                })
            }
        },
        deleteWebsocketHooks: (accountId:string) => {
            getterHelpers.useStore().commit(MutationTypes.deleteSubscriptionHook, {
                "accountId": accountId,
                "hookKey": "unifiedNetwork",
            })
        },
        handleGlobalWebsocketHooks: (accountId:string) => {
            if(websocketHandler.hasHookWithId("unifiedNetworkGlobal") == false) {
                getterHelpers.useStore().commit(MutationTypes.addSubscriptionHook, {
                    "accountId": accountId,
                    "hookKey": "unifiedNetworkGlobal",
                    "hookFunction": async (message: any) => {
                        if (message.topic == "/sun/state/changed") {
                            let user = message.data.username                           
                            let currentUser = getterHelpers.useStore().state.session.userInfo.username
                            if(currentUser != user) {
                                let topologies = await this.queries.getObjectsFromApi(accountId)
                                if(!(topologies instanceof Error) && topologies.length > 0) {
                                    this.showDialogOnBeforeUnload = topologies[0].actions.isPublishable
                                }
                                else {
                                    this.showDialogOnBeforeUnload = false
                                }
                            }
                        }

                        if (message.topic == "/sun/publish" && message.data?.success === true) {
                            this.showDialogOnBeforeUnload = false
                        }
                    }
                })
            }
        },
        deleteGlobalWebsocketHooks: (accountId:string) => {
            getterHelpers.useStore().commit(MutationTypes.deleteSubscriptionHook, {
                "accountId": accountId,
                "hookKey": "unifiedNetworkGlobal",
            })
        },


    }



}


const topologies = new SunTopologies({
    "objectType": "topologies",
    "productType": "unifiedNetwork",
    "slug": "topologies",
    "hasStore": true,
    "objectTypeInfo": {
        "nameProperty": {
            "primary": "utmname"
        },
        "primaryKeyProperty": {
            "property": "id"
        },
    },
    "appearance": {
        "color": "red",
        "iconClass": "fal fa-server",
        "showInSidebar": true,
        "text": {
            "plural": "Topologies",
            "sidebarName": "Topologies",
            "title": "Topologies",
            "singular": "Topology"
        }
    },
    "apiInfo": {
        "getCountGETProperties": "?props[]=null&select=data.total",
        "url": config.mgtApiUriNext,
        "getObjectListPath": "/tenants/{tenantDomain}/sun/topologies",
        "getObjectListResponseProperty": "topology",
        "getObjectPath": "/tenants/{tenantDomain}/sun/topologies/{objectId}"
    },

})
export default topologies