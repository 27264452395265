import requestHandler from "@/queries/requests"
import ObjectType, { type ObjectTypePayload } from "../../objectType"
import apis from "@/classes/apis"
import devLog from "@/classes/log"

export interface AuditlogEntry {
    "timestamp": ISO_8601_DATE_TIME
    "tenantDomain": string
    "product": ProductType
    "type": AuditlogType
    "user": string
    "message": string
}

enum ProductType {
    UNSPECIFIC = "UNSPECIFIC",
    MOBILE_SECURITY = "MOBILE_SECURITY",
    UNIFIED_SECURITY_REPORT = "UNIFIED_SECURITY_REPORT",
    UNIFIED_SECURITY_CONSOLE = "UNIFIED_SECURITY_CONSOLE"
}

enum AuditlogType {
    PRIVACYLEVEL = "PRIVACYLEVEL",
    DEPSEUDONYMIZED = "DEPSEUDONYMIZED",
    USER_ACTION = "USER_ACTION"
}

type ISO_8601_DATE_TIME = string

class AuditLog extends ObjectType<AuditlogEntry> {
    constructor(payload: ObjectTypePayload<AuditlogEntry>) {
        super(payload)
        let thisClass = this

        this.replaceStoreFunctionWith.setObjectTypeObjects = (accountId, objects) => {
            let accountObjectStore = thisClass.useStore?.().getObjectStore(accountId)
            if (accountObjectStore) {
                accountObjectStore.objects.push(...objects)
            }
        }

        this.queries.getObjectsFromApi = async (accountId, customerId?, props?, updateStore = true) => {
            this.useStore?.().clearObjectTypeObjectsFromStore(accountId)
            let propertiesString: string = props ? this.getPropertiesString(props) : ""
            if (this.getListUrl(accountId).indexOf("?") != -1) {
                propertiesString = propertiesString.replace("?", "&")
            }

            let result: AuditlogEntry[] | Error = []
            try {
                let batchLoop = async (pageSize:number=1000,offset:number=0) => {
                    let response = await requestHandler.request(this.options.apiInfo.getObjectListMethod, this.getListUrl(accountId) + "?limit="+pageSize+"&offset="+offset)
                    ;(<AuditlogEntry[]>result).push(...response as AuditlogEntry[])
                    if (updateStore) {
                        this.useStore?.().setObjectTypeObjects(accountId, response)
                    }
                    if (response?.length == pageSize) {
                        await batchLoop(pageSize, offset + pageSize)
                    }
                }
                await batchLoop()
                return result
            }
            catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                throw e as Error
            }
        }
    }
}

const auditlog = new AuditLog({
    "objectType": "auditlog",
    "productType": "unifiedSecurity",
    "slug": "auditlog",
    "appearance": {
        "color": "red",
        "iconClass": "fal fa-clipboard-list fa-fw",
        "showInSidebar": true,
        "showOnDashboard": false,
        "text": {
            "plural": "Auditlogs",
            "singular": "Auditlog",
            "sidebarName": "Auditlog",
            "title": "Auditlog"
        }
    },
    "apiInfo": {
        "url": "/sms-mgt-api/api/2.0",
        // GET
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/auditlog",
    },
    "hasStore": true,
})
export default auditlog