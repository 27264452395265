import i18n from "@/classes/i18n"
import { useStore } from '@/store/vuex.store'
import tenantHelpers from "@/helpers/helpers.tenants"
import jsonHelpers from "@/helpers/helpers.json"
import config from "@/classes/config"
import useRouterStore from "@/router/routerStore"
import mixinHelpers from "@/helpers/helpers.mixins"
import { allObjectTypes } from "@/classes/objectTypes"

const globalMixin : any = {
    data: () => {
        return {
            $store:useStore()
        }
    },
    computed: {
        "activeAccountId": function (this: any) { return useStore()?.state.session.activeAccountId },
        "activeTenantDomain": function (this: any) { return this.activeAccountId ? this.activeAccountId + '.sms' : undefined },
        "activeAccount": function (this: any) { return useStore()?.state.session.accounts[this.activeAccountId] },
        "initializedSession": function (this: any) { return useStore()?.state.session.initialized || false },
        "activeAccountInitialized": function (this: any) { return useStore()?.state.session.accounts[this.activeAccountId]?.initialized || false },
        "activePage": function (this: any) { return useRouterStore().getActivePage },
        "productType": function (this: any) { return tenantHelpers.getProductTypeFromObjectType(this.activePage) || undefined },
        "objectType": function (this:any) { 
            const productType = String(this.productType)
            const activePage = String(this.activePage)

            if (config.canUseNewObjectType(activePage)) {
                return allObjectTypes[activePage].options.objectType
            }

            if (productType == undefined && activePage == undefined) return undefined
            const registeredProducts = useStore()?.state.resources.registeredProducts
            if (!registeredProducts) return undefined
            const registeredProduct = registeredProducts[productType as keyof typeof registeredProducts]
            return registeredProduct?.objectTypes[activePage as keyof typeof registeredProduct.objectTypes].options?.objectType || activePage
        },
        "objectId": function (this: any) { return useRouterStore().getObjectId },
        "context": function (this: any) { return useRouterStore().getContext },
        "zeroTouchCustomerId": function (this: any) { return useRouterStore().getZeroTouchCustomerId },
        "search": function (this: any) { return useRouterStore().getSearch },
        "DEV_MODE": function (this: any) { return config.devMode },
        "darkmode": function (this: any) { return useStore()?.state.browserSettings.darkmode },
        "ccutmStates": function(this:any) {
            let result : any = undefined
            if (this.activePage == "uscUtms") {
                result = useStore()?.getters.getObjectTypeStore({
                    "accountId":this.activeAccountId,
                    "objectType":"ccutmStates"
                })?.items || []
            }
            return result
        }
    },
    methods: {
        T: i18n.T,
        // include legacy vue-prototype functions
        "$getObjectProperty":(obj:any,props:string) => { return jsonHelpers.getObjectProperty(obj, props) },
        "$hasAccountRole": function (this:any,roleName: string) {
            return useStore()?.state.session.userInfo.roles.indexOf(roleName) != -1
        },
        "$tenantItemNeedsRefresh": (accountid: string, productType: string, objectType: string) => { return tenantHelpers.tenantItemNeedsRefresh(accountid,productType,objectType) },
        "$getProductTypeFromObjectType": (objectType: string) => { return tenantHelpers.getProductTypeFromObjectType(objectType) },
        "$getObjectIdPropertyForObjectType": (objectType: string) => { return tenantHelpers.getObjectIdPropertyForObjectType(objectType) },
        "$getObjectNamePropertyForObjectType": (objectType: string) => { return tenantHelpers.getObjectNamePropertyForObjectType(objectType) },
        "$getSelectOptionsForObjectType": (accountid: string, objectType: string) => { return tenantHelpers.getSelectOptionsForObjectType(accountid,objectType) },
        "$isItemEnterprise": (item: any) => { return tenantHelpers.isItemEnterprise(item) },
        "$isTenantReseller":function (this:any,tenantdomain: any) {
            let result: boolean = false
            const session = useStore()?.state.session.userInfo
            for (let i in session.scopes.list_accounts) {
                if (session.scopes.list_accounts[i].accountid + ".sms" == tenantdomain) {
                    result = session.scopes.list_accounts[i].parent_accountid == null
                    break;
                }
            }
            return result
        }
    },
    created: function (this: any) {

    }
}
export function useGlobalMixin() {
    return globalMixin || undefined
}
