import { useGlobalMixin } from "@/mixins/mixins.global";
import jsonHelpers from "../../helpers/helpers.json"
import devLog from "../../classes/log"
import apis from "../../classes/apis"
import { nextTick } from "vue";
import { ActionTypes } from "@/store/vuex.store";
import queries from "@/queries/queries";
import objectStores from "@/classes/init";
import type { ZeroTouchDevice } from "@/classes/mobileSecurity/zeroTouchDevices";
import router from "@/router/router";
import moment from "moment";
import i18n, { T } from "@/classes/i18n";
import $ from "jquery"
import loaderComponent from "../components/loader.vue";
import dialogs from "@/dialogs/dialogs";
import jsonFormEntryComponent from "../inputtypes/jsonform-entry-async";
import tenantHelpers from "@/helpers/helpers.tenants";
import { useStore } from '@/store/vuex.store'
import jsonFormHelpers from "@/helpers/helpers.jsonForms";
import timeHelpers from "@/helpers/helpers.time";
import arrayHelpers from "@/helpers/helpers.arrays";
import promiseHelpers from "@/helpers/helpers.promises";
import encodingHelpers from "@/helpers/helpers.encoding";
import enterpriseHelpers from "@/helpers/helpers.enterprise";
import mixinHelpers from "@/helpers/helpers.mixins";
import licenseHelpers from "@/helpers/helpers.license";
import products, { allObjectTypes } from "@/classes/objectTypes";
import config from "@/classes/config";
import type { UscUtm } from "@/classes/unifiedSecurityConsole/uscUtms";
import type { Certificate } from "@/classes/objectTypes/mobileSecurity/certificates/certificates";
import deviceHelpers from "@/helpers/helpers.devices";
import type { HomescreenLayout } from "@/classes/objectTypes/mobileSecurity/apple/homescreenLayouts";
import getterHelpers from "@/helpers/helpers.getters";
import { sprintf } from "sprintf-js";
import requestHandler from "@/queries/requests";
import frontendNotifications from "@/classes/notifications";

const editpageComponent = {
	name: 'sms-page-editpage',
	mixins: [useGlobalMixin()],
	data() {
		return {
			"initialized":false,
			"loading":false,
			"loaderText":"Loading...",
			"selectOptions":{},
			"form":{},
			"formBackups":{},
			"hasProfileLicenseConflict":false,
			"isEnterpriseProfileNameTaken":false,
			"abort":false,
			"rootObject":undefined
		}
	},
	computed: {
		"devices": function(this:any) {
			if (config.canUseNewObjectType("iosDevices")) {
				return products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObjects(this.activeAccountId)
			}
			else {
				return useStore().getters.getObjectTypeStore({
					"accountId":this.activeAccountId,
					"objectType":"devices",
				})?.items
			}
		},
		"enterpriseDevices": function(this:any) {
			if(config.canUseNewObjectType("androidDevices")) {
				return products.mobileSecurity.androidDevices.useStore?.().getObjectStoreObjects(this.activeAccountId)
			}
			else {
				return useStore().getters.getObjectTypeStore({
					"accountId": this.activeAccountId,
					"objectType": "enterpriseDevices",
				})?.items
			}
			
		},
		"isGeneratedUscProfile": function(this:any) {
			if (this.activePage == "uscProfiles") {
				return this.rootObject?.template?.tenantDomains == undefined && this.rootObject?.template?.uuid != undefined && this.rootObject?.template?.childProfiles == undefined
			}
			else {
				return false
			}
		},
		"hasFormErrors": function (this: any) {
			let fieldsWithConditions = this.getFieldsWithErrorConditions(this.form)
			if (fieldsWithConditions) {
				return fieldsWithConditions.some((field: JsonFormShemaObject) => {
					return !this.areConditionsFulfilled(field.errorConditions || [], field) && this.areConditionsFulfilled(field.disableConditions, field)
				})
			}
			else {
				return false
			}
		}

	},
	template: `
		<section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
			<div class="row media-screen-only" v-if="hasProfileLicenseConflict">
				<div class="col-xs-24 padding-xs">
					<div class="box-border bg-white padding-xs" style="max-width:960px;">
						<i class="fal fa-fw fa-exclamation-triangle color-red"></i>
						{{ T('Warning! Mobile Security is enabled in this profile, but it contains devices that are not licensed for Mobile Security. Please note that these devices may receive the Securepoint Mobile Security app, but cannot establish a VPN connection.') }}
					</div>
				</div>
			</div>
			<div class="row media-screen-only" v-if="isEnterpriseProfileNameTaken">
				<div class="col-xs-24 padding-xs">
					<div class="box-border bg-white padding-xs" style="max-width:960px;">
						<i class="fal fa-fw fa-exclamation-triangle color-red"></i>
						{{ T('A profile with this name already exists. Saving this profile would overwrite the existing one.') }}
					</div>
				</div>
			</div>
			<div class="row media-screen-only">
				<div class="col-xs-24 padding-xs">
					<div class="box-shadow">
						<div v-if="!initialized" class="page-loader">
							<div class="text-size-3 text-center padding-xs-t-4">
								<loader class="color-red"></loader>
							</div>
							<template v-if="loaderText">
								<div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity:0.8">
									<span>
										{{ T(loaderText) }}
									</span>
								</div>
							</template>
							<template v-else>
								<div class="padding-xs-b-4"></div>
							</template>
						</div>
						<transition>
							<div v-if="initialized">
								<div class="content-2" v-if="form.errors.length">
									<template v-for="error in form.errors">
										<div class="alert notification content bg-red">
											<strong class="color-white">
												<i class="icon fal fa-ban color-white"></i>&nbsp;&nbsp;{{ T('Error') }}:
											</strong>
											<span class="color-white">{{ error }}</span>
										</div>
									</template>
								</div>
								<div class="content-2">
									<form autocomplete="off">
										<input autocomplete="off" name="hidden" type="text" style="display:none;">
										<jsonform-entry
											:fields="form.fields"
											:full-json="form"
											:this-field="form"
										>
										</jsonform-entry>
									</form>
									<hr>
								</div>
								<div class="box-footer content-2">
									<button v-on:click="cancel()" id="closeButton" type="button" class="btn btn-default">${T('Close')}</button>
									<template v-if="isGeneratedUscProfile || isEnterpriseProfileNameTaken">
									
									</template>
									<template v-else>
										<button 
											class="btn margin-md-0 btn-loader twist-in float-xs-right" 
											v-on:click="isEnterpriseProfileNameTaken ? null : hasProfileLicenseConflict ? confirmSavingWithLicenseConflict() : save()" 
											:class="{ 'btn-loading': loading }"
											id="saveButton" 
											title="Save" 
											type="submit"
											:disabled="hasFormErrors"
										>
											<span class="animate">
												<loader v-if="loading" style="font-size: 1.5em;top:2px;"></loader>
											</span>
											<span><i class="fal fa-save"></i> <span class="display-xs-none display-lg-inline">${T('Save')}</span></span>
										</button>
									</template>
								</div>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</section>
	`,
	methods: {
		T: T,
		getFieldById: jsonFormHelpers.getFieldById,
		init: async function(this:any) {
			let thisComponent : any = this
			if(this.activeAccount?.initialized === true && !this.abort) {
				let objectType : string = this.activePage;
				let queryType : string = this.activePage;
				let activeShema : string = this.activePage;
				let objectId = this.objectId

				switch(this.activePage) {
					case 'androidProfiles':
						if (config.canUseNewObjectType(this.activePage)) {
							objectType = "androidProfiles"
							queryType = "androidProfiles"
						}
						else {
							objectType = "profiles"
							queryType = "profiles"
						}
					break; 
					case 'iosProfiles':
						objectType = "profiles"
						queryType = "profiles"
					break;
					case 'iosDevices':
						objectType = "devices"
					break;
				}

				switch (this.context) {
					case 'editDevice':
						queryType = 'devices'
						objectType = 'devices'
						activeShema = "msInventory"
					break;
					case 'editVPN':
						queryType = 'vpns'
						objectType = 'vpns'
						activeShema = "msInventory"
					break;
					case 'addVpp':
					case 'editVpp':
						activeShema = 'vppusers'
						queryType = 'vppusers'
						objectType = 'vppusers'
					break;
					case 'editEnterprise':
						queryType = 'enterpriseDevices'
						objectType = 'enterpriseDevices'
						activeShema = "msInventory"
					break;
					case 'editUtm':
					case 'editUscUtm':
					case 'editReportingUtm':
						if (this.activePage == "inventory") {
							queryType = 'uscUtms'
							objectType = 'uscUtms'
							activeShema = 'uscInventory'
						}
					break;
				}


				if(objectId != undefined && objectId.indexOf('enterprise_') != -1) {
					objectId = objectId.replace('enterprise_','')
					queryType = "enterpriseProfiles"
				}

				let hasObject : any = false
				if (objectId) {
					devLog.log("[formpage]", "Getting Object Info...")
					this.loaderText = 'Getting object info...';
					try {

						if (config.canUseNewObjectType(objectType)) {							
							hasObject = await allObjectTypes[objectType].queries.getObjectFromApi(this.activeAccountId, objectId, this.zeroTouchCustomerId || undefined)
						}
						else {
							hasObject = await useStore().dispatch(ActionTypes.getObjectInfo, {
								"accountId": this.activeAccountId,
								"productType": tenantHelpers.getProductTypeFromObjectType(objectType),
								"objectType": objectType,
								"queryType": queryType,
								"objectId": objectId,
								"customerId": this.zeroTouchCustomerId || undefined
							})
						}

						if (!hasObject || hasObject instanceof Error) {
							this.abort = true
							router.navigate('404-' + objectId)
						}
					}
					catch(e:any) {
						console.error(e)
						this.abort = true
							router.navigate('404-' + objectId)
					}
				}
				if((objectId != undefined && hasObject !== false) || !objectId) {
					

					devLog.log("[formpage]","Getting Form Fields...")
					this.loaderText = 'Getting form...';
					this.form = JSON.parse(JSON.stringify(useStore().state.resources.shemas[activeShema])) as JsonFormShemaObject

					this.beforeGetSelectOptions(this.form,this.activePage, objectId)
					devLog.log("[formpage]","Getting Select Options...")
					this.loaderText = 'Getting select field options...';

					let gotSelectOptions : boolean = await this.getSelectOptions(JSON.parse(JSON.stringify(useStore().state.resources.shemas[activeShema])))
					if (gotSelectOptions) {
						this.beforeSetSelectOptions(this.form,this.activePage)
						devLog.log("[formpage]","Setting Select Options...")
						this.loaderText = 'Setting select field options...';
						this.setSelectOptions(this.form)
					}

					this.afterSetSelectOptions(this.form,this.activePage, objectId)

					if (objectId) {
						devLog.log("[formpage]","We are in edit mode...")
						let object: any = undefined
						if (config.canUseNewObjectType(objectType)) {
							object = allObjectTypes[objectType].useStore?.().getObjectStoreObject(this.activeAccountId,objectId)
						}
						else {
							object = useStore().getters.getObject({
								"accountId": this.activeAccountId,
								"productType": tenantHelpers.getProductTypeFromObjectType(objectType),
								"objectType": objectType,
								"objectId": objectId
							})
						}
						object = jsonHelpers.copyObject(object)
						this.rootObject = jsonHelpers.copyObject(object)
						object = this.beforeFillFormWithData(this.form, this.activePage, object, objectId)

						this.loaderText = 'Filling form with object data...';
						let filled : any = await this.fillFormWithData(this.form,object)
						object = jsonHelpers.copyObject(this.form)
						if (filled) {
							await this.afterFillFormWithData(this.form, this.activePage, object, objectId)

							this.loaderText = 'Ready!';
							nextTick(function() {
								thisComponent.initialized = true
							})
						}
					}
					else {
						this.loaderText = 'Ready!';
						nextTick(function() {
							thisComponent.initialized = true
						})
					}
				}
				else {
					await timeHelpers.sleep(500)
					this.init()
				}
			}
		},
		beforeGetSelectOptions: function(this : any, form : JsonFormShemaObject, activePage : string, objectId : string) {
			if (activePage == 'iosProfiles' && !tenantHelpers.hasFunctionality(this.activeAccountId,'dep')) {
				let platformField : any = jsonFormHelpers.getFieldById(this.form,'profilePlatform')
				platformField.options = platformField.options.filter(function(option:any) {
					return option.id != "IOS_SHARED_IPAD"
				})
			}
			if (activePage == 'reports' && this.activeAccountId == '974857') {
				let periodField = jsonFormHelpers.getFieldById(this.form,'period')
				if(typeof periodField.options == "object") {
					periodField.options.push({"id":"day","text":"Day"})
				}
			}
		},
		beforeSetSelectOptions: function(this : any, form : JsonFormShemaObject, activePage : string, objectId : string) {},
		afterSetSelectOptions: function(this : any, form : JsonFormShemaObject, activePage : string, objectId : string) {
			let thisComponent : any = this

			if (thisComponent.context == 'add' && thisComponent.activePage == 'depProfiles') {
				let urlField = jsonFormHelpers.getFieldById(this.form,'url')
				urlField.value = useStore().state.session.accounts[useStore().state.session.activeAccountId || ""].mobileSecurity.settings.ios.enrollmentURL
			}

			// ADD EXCEPTION FOR VPN LICENSE SELECTION
			if (activePage == "vpns" && objectId == undefined) {
				let licenseUUIDField : any = jsonFormHelpers.getFieldById(this.form,'licenseUUID')
				let licenseOptions = licenseUUIDField.options
				if (Array.isArray(licenseOptions) && licenseOptions.length != 0) {
					licenseUUIDField.value = licenseOptions[0].id
				}
			}
			if(activePage == "depProfiles") {
				
				

				let devicesRaw: any = config.canUseNewObjectType('iosDevices') ? products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObjects(this.activeAccountId) : useStore().getters.getObjectTypeStore({
					"accountId":this.activeAccountId,
					"objectType":"devices"
				})?.items || []

				if (devicesRaw != undefined && thisComponent.objectId) {
					let devicesField : any = jsonFormHelpers.getFieldById(this.form,'devices')
					let devicesBeforeField : any = jsonFormHelpers.getFieldById(this.form,'devicesBefore')
					devicesRaw.forEach(function(device:any) {
						if((device.depDevice || device.depOnly) && (device.depDeviceInfo?.profile_uuid || false) == thisComponent.objectId) {
							devicesField.value.push(device.depDeviceInfo.serial_number)
							devicesBeforeField.value.push(device.depDeviceInfo.serial_number)
						}
						else if (device.depOnly && device.serial_number == thisComponent.objectId) {
							devicesField.value.push(device.serial_number)
							devicesBeforeField.value.push(device.serial_number)
						}
					})
				}
			}

			// select first zero touch customer in zero touch configs
			if (this.activePage == "zeroTouchConfigurations" && this.context == "add") {
				const customerField = jsonFormHelpers.getFieldById(this.form, 'customerId')
				if (this.selectOptions.zeroTouchCustomers.length) {
					customerField.value = this.selectOptions.zeroTouchCustomers[0].id
				}
			}

		},
		beforeFillFormWithData: function (this: any, form: JsonFormShemaObject, activePage: string, object: any, objectId: string) {
			let thisComponent: any = this

			if(object.tags) {
				object.tags = object.tags.sort((tagA: string, tagB: string) => {
					return tagA.toLowerCase() > tagB.toLowerCase() ? 1 : -1
				})
				
			}

			if (object.profile?.autoUpdateCronValues?.length) {
				object.profile.autoUpdateCronValues = object.profile.autoUpdateCronValues[0]
			}

			if (object.hasOwnProperty("iOSPerUserChannelUsers") && tenantHelpers.hasFunctionality(this.activeAccountId, 'dep')) {
				object.platform = "IOS_SHARED_IPAD"
			}
			if (jsonHelpers.getObjectProperty(object, "profile.custom.mobileconfig") != undefined && !object.hasOwnProperty("iOSPerUserChannelUsers")) {
				//object.platform = "IOS_CUSTOM_MOBILECONFIG"
			}
			if (this.activePage == "androidProfiles") {
				let thisPolicyEnterpriseDevices: any = []
				devLog.log("[formpage]","This is an enterprise profile. Getting enterprise devices.")
				let thisPolicyName = decodeURIComponent(objectId)
				this.enterpriseDevices.forEach(function (device: any) {				
					if (enterpriseHelpers.getCurrentPolicyName(device) == thisPolicyName) {
						thisPolicyEnterpriseDevices.push(device)
					}
				})
				
				object = config.canUseNewObjectType("androidProfiles") ? object : object.policy
				object.name = object.name.split('/')[3]
				if (thisPolicyEnterpriseDevices.length) {
					object.devices = []
					for (let deviceIndex in thisPolicyEnterpriseDevices) {
						if (thisPolicyEnterpriseDevices[deviceIndex].signedIn !== false) {
							object.devices.push(thisPolicyEnterpriseDevices[deviceIndex].id)
						}
					}
				}
			}
	


			if (this.activePage == 'inventory') {
				object = object.inventory
			}
			
			if (this.activePage == 'homescreenlayouts') {
				object.homescreenLayout = {
					"Dock":object.Dock || [],
					"Pages":object.Pages || []
				}
				delete object.Dock
				delete object.Pages
			}

			// declarativeManagement
			if (jsonHelpers.getObjectProperty(object, "profile.declarativeManagement.subscriptions") != undefined) {
				let subscriptions: any = {};
				(<Array<string>>object.profile.declarativeManagement.subscriptions).forEach((subscription: string) => {
					subscriptions[subscription.replaceAll(".", "_")] = true
				})
				object.profile.declarativeManagement.subscriptions = subscriptions
			}


			if (jsonHelpers.getObjectProperty(object, "applications") != undefined) {
				for (let i: number = 0; (object.applications || []).length > i; i++) {
					let thisApp = object.applications[i]
					if (jsonHelpers.getObjectProperty(thisApp, "managedConfigurationTemplate") != undefined) {
						if (jsonHelpers.getObjectProperty(thisApp, "managedConfigurationTemplate.configurationVariables.%users%") != undefined) {
							object.useConditionVariables = true
							//let templateToggle : any = jsonFormHelpers.getFieldById(this.form,'useConditionVariables')
							//templateToggle.value = true
							object.conditionVariablesUsers = JSON.parse(JSON.stringify(thisApp.managedConfigurationTemplate.configurationVariables["%users%"].split(',')))
							delete thisApp.managedConfigurationTemplate.configurationVariables
							break;
						}
					}
				}
			}
			// APPCONFIG AUSNAHME
			if (object.appconfigs && object.profile && object.profile.security) {
				object.profile.security.appconfigs = object.appconfigs[0]
				delete object.appconfigs
			}

	

			if (activePage == 'androidProfiles' || activePage == 'iosProfiles' || activePage == 'profiles') {
				if (object.applications != undefined && object.applications.length) {
					if (this.getLowestLicenseTypeOfDeviceIdList(object.devices || []) == "MDM") {
						if (object.applications != undefined && object.applications.length) {
							object.applications.forEach(function (application: any) {
								if (application.packageName == "de.securepoint.ms.agent" && application.managedConfiguration) {
									thisComponent.hasProfileLicenseConflict = true
								}
							})
						}
					}
				}
				// Set security tab if sp app exists && toolbox
				if (object.applications != undefined && object.applications.length && licenseHelpers.hasLicense(this.activeAccountId,'Mobile Security','valid')) {
					object.applications.forEach((application: any) => {
						if (application.packageName == "de.securepoint.ms.agent") {
							if(application.managedConfiguration) {
								object.smsManagedConfiguration = application.managedConfiguration
								let toggle: any = jsonFormHelpers.getFieldById(this.form as JsonFormShemaObject,'toggleEnterpriseSecuritySection')
								toggle.value = true
								let enterpriseSecuritySection: any = jsonFormHelpers.getFieldById(this.form as JsonFormShemaObject,'section_emm_security')
								enterpriseSecuritySection.activator.value = true
							}
						}
						
						if (application.packageName == "de.securepoint.emm.toolbox") {
							if (application.managedConfiguration) {
								let certs : Certificate[] = []
								if(config.canUseNewObjectType("certificates")) {
									certs = products.mobileSecurity.certificates.useStore?.().getObjectStoreObjects(thisComponent.activeAccountId) || []
								}
								else {
									certs = useStore().getters.getObjects({
										"accountId": thisComponent.activeAccountId,
										"objectType": "certificates",
										"productType": "mobileSecurity"
									})
								}
								
								if (application.managedConfiguration.certificates) {
									application.managedConfiguration.certificates.uninstall = application.managedConfiguration.certificates?.uninstall?.filter((certInfo: any) => {
										return certs.some((cert: any) => {
											return cert.certificateId == JSON.parse(certInfo).certificateId
										})
									})
								}

								object.toolboxCertificatesUninstallOld = application.managedConfiguration.certificates?.uninstall || []
								object.toolboxCertificatesOld = application.managedConfiguration.certificates?.install || []
								object.toolboxCertificates = application.managedConfiguration.certificates?.install || []

								delete application.managedConfiguration
							}
						}


					})
				}

				// check toolboxPlugin
				if (object.applications?.length) {
					const hasToolbox = object.applications.some((application: any) => { return application.packageName == "de.securepoint.emm.toolbox"})
					if (hasToolbox) {
						let toggle: any = jsonFormHelpers.getFieldById(this.form,'toggleToolboxPlugin')
						toggle.value = true
					}
				}
				// check locationPlugin
				if (
					object.applications?.length
					&& object.statusReporting?.applicationReportsEnabled === true
					&& object.locationMode == "LOCATION_ENFORCED"
				) {
					const hasToolbox = object.applications.some((application: any) => { return application.packageName == "de.securepoint.emm.toolbox"})
					const hasToolboxLocation = object.applications.some((application: any) => { return application.packageName == "de.securepoint.emm.toolbox.location"})
					if (hasToolbox && hasToolboxLocation) {
						let toggle: any = jsonFormHelpers.getFieldById(this.form,'toggleLocationPlugin')
						toggle.value = true
					}
				}

				if(!licenseHelpers.hasLicense(this.activeAccountId,'Mobile Security','valid')) {
					if(object.profile?.security != undefined) {
						delete object.profile?.security
					}
				}
				if (jsonHelpers.getObjectProperty(object, "profile.exchange")) {
					if (object.profile.exchanges != undefined && Array.isArray(object.profile.exchanges)) {
						object.profile.exchanges.splice(0, 0, object.profile.exchange)
					}
					else {
						object.profile.exchanges = [object.profile.exchange]
					}
				}
				if (jsonHelpers.getObjectProperty(object, "profile.email")) {
					if (object.profile.emails != undefined && Array.isArray(object.profile.emails)) {
						object.profile.emails.splice(0, 0, object.profile.email)
					}
					else {
						object.profile.emails = [object.profile.email]
					}
				}
			}
			if (object?.permissionScopes?.length) {
				let permissionScopesObject: any = {}
				object?.permissionScopes?.forEach(function (permission: any) {
					permissionScopesObject[permission] = true
				})
				object.permissionScopes = permissionScopesObject
			}
			if (activePage == 'zeroTouchConfigurations') {
				let zeroTouchDevices = config.canUseNewObjectType("zeroTouchConfigurations") ? products.mobileSecurity.androidDevices.useStore?.().getObjectStoreObjects(this.activeAccountId) || [] : (useStore().getters.getObjectTypeStore({
					"accountId": this.activeAccountId,
					"objectType": "zeroTouchDevices"
				})?.items || [])
				let thisZeroTouchDevices: any[] = zeroTouchDevices.filter((device: ZeroTouchDevice) => {
					if(config.canUseNewObjectType("zeroTouchConfigurations")) {
						return object?.name && (device?.configuration == object.name || (<any>device)?.zeroTouchInfo?.configuration == object.name)
					}
					else {
						return ((device.configuration != undefined) && device.configuration?.split('/')[3] == object.name.split('/')[3]) || ((device.configuration != undefined) && device.configuration?.split('/')[3] == object.name.split('/')[3])
					}
				})

				object.devices = (thisZeroTouchDevices || []).map((device : ZeroTouchDevice) => {
					return (<any>device)?.zeroTouchInfo != undefined ? (<any>device).zeroTouchInfo.deviceId : device?.deviceId
				})
				//let dpcInfos = JSON.parse(object?.dpcExtras || "{}")
				//let enrollmentTokenId = dpcInfos?.["android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE"]?.["com.google.android.apps.work.clouddpc.EXTRA_ENROLLMENT_TOKEN"]
				//object.enrollmentTokenId = enrollmentTokenId
				object.devices = object.devices.filter(arrayHelpers.onlyUniqueFilter)                
			}


			// #17361 -> rename ios properties
			if (object.profile?.restrictions?.forceWiFiWhitelisting != undefined) { 
				object.profile.restrictions.forceWiFiToAllowedNetworksOnly = object.profile?.restrictions.forceWiFiWhitelisting;
				delete object.profile.restrictions.forceWiFiWhitelisting 
			}
			if (object.profile?.restrictions?.whitelistedAppBundleIDs != undefined) {
				object.profile.restrictions.allowListedAppBundleIDs = object.profile?.restrictions.whitelistedAppBundleIDs; 
				delete object.profile.restrictions.whitelistedAppBundleIDs
			}
			if (object.profile?.restrictions?.blacklistedAppBundleIDs != undefined) {
				object.profile.restrictions.blockedAppBundleIDs = object.profile?.restrictions.blacklistedAppBundleIDs; 
				delete object.profile.restrictions.blacklistedAppBundleIDs
			}


			// usc profiles
			if(activePage == "uscProfiles") {

				if(object?.profile?.geoip) {
						if (object.profile.geoip.blockSource?.length == 0) {
							delete object.profile.geoip.blockSource
						}
						if (object.profile.geoip.blockSourceExceptions?.length == 0) {
							delete object.profile.geoip.blockSourceExceptions
						}
						if (object.profile.geoip.blockDestination?.length == 0) {
							delete object.profile.geoip.blockDestination
						}
						if (object.profile.geoip.blockDestinationExceptions?.length == 0) {
							delete object.profile.geoip.blockDestinationExceptions
						}
					if (jsonHelpers.isObjectEmpty(object.profile.geoip)) {
						delete object.profile.geoip
					}

					if (object?.profile?.autoUpdateEnabled !== undefined) {
						if(object.profile.update === undefined) {
							object.profile.update = {
								enabled:true,
								enabledUpdate: object.profile.autoUpdateEnabled
							}
						}
						else {
							object.profile.update.updateEnabled = object.profile.autoUpdateEnabled
						}
					}
					
				}
				if (object?.profile?.autoUpdateCronValues != undefined) {
					if (object?.profile?.autoUpdateCronValues) {
						if	(object.profile.update == undefined) {
							object.profile.update = {}
						}
						if (Array.isArray(object?.profile?.autoUpdateCronValues)) {
							if (object?.profile?.autoUpdateCronValues.length) {
								object.profile.update.enabled = object?.profile.autoUpdateOnce !== true ? object?.profile.autoUpdateEnabled || false : false
								object.profile.update.enabledUpdate = object?.profile.autoUpdateOnce !== true ? object?.profile.autoUpdateEnabled || false : false
								object.profile.update.cronValue = object?.profile?.autoUpdateCronValues[0]
							}
						}
						else {
							object.profile.update.enabled = object?.profile.autoUpdateOnce !== true ? object?.profile.autoUpdateEnabled || false : false
							object.profile.update.enabledUpdate = object?.profile.autoUpdateOnce !== true ? object?.profile.autoUpdateEnabled || false : false
							if (object?.profile.autoUpdateOnce !== true) {
								object.profile.update.cronValue = object?.profile?.autoUpdateCronValues
							}
						}
					}
				}
				if(Array.isArray(object?.profile?.nameserver?.ips)) {
					if (object.profile.nameserver.ips[0]) {
						object.profile.nameserver.primaryNameserver = object.profile.nameserver.ips[0] ? object.profile.nameserver.ips[0] : ""
						if (object.profile.nameserver.ips[1]) {
							object.profile.nameserver.secondaryNameserver = object.profile.nameserver.ips[1] ? object.profile.nameserver.ips[1] : ""
						}
					}
					delete object.profile.nameserver.ips
				}
				if(Array.isArray(object?.profile?.globalVpnSettings?.nameserver)) {
					if (object.profile?.globalVpnSettings?.nameserver[0]) {
						object.profile.globalVpnSettings.primaryVpnNameserver = object.profile.globalVpnSettings.nameserver[0]
						if (object.profile.globalVpnSettings.nameserver[1]) {
							object.profile.globalVpnSettings.secondaryVpnNameserver = object.profile.globalVpnSettings.nameserver[1]
						}
					}
					delete object.profile.globalVpnSettings.nameserver
				}
			}

			return object
		},
		afterFillFormWithData: async function(this : any, form : JsonFormShemaObject, activePage : string, object : any, objectId : string) {
			let thisComponent : any = this

			if (activePage == 'androidProfiles' || activePage == 'enterpriseProfiles') {
				// Make generated policies read only
				if (jsonHelpers.getObjectProperty(object,"type") != undefined) {
					if (object.type == "autogenerated policy") {
						this.setAllReadonly(this.form)
					}
				}

				this.setSecurityDependingOnLicenseFromDevices()

				// Get App Infos
				
				let applications = jsonFormHelpers.getFieldById(this.form,'emm_applications')
				let requests : any[] = []
				let fields : any[] = []
				let packageNames : any[] = []
				let packageNameSelectOptions : any[] = []
				if(applications?.fields) {
					this.loaderText = 'Getting application infos...';
					applications.fields?.forEach(function(thisApplication:any) {
						fields.push(thisApplication)
						requests.push(thisApplication?.fields[0].value ? queries.mobileSecurity.getEnterpriseAppInfo(thisComponent.$root.activeTenantDomain,thisApplication?.fields[0].value) : null)    
					});
				}
				
				let results : any[] = []
				try {
					results = await promiseHelpers.resolvePromiseAsChunks(requests,10,1000)
				}
				catch(e:any) {
					console.error(e)
				}
				results.forEach(function (thisResult: any,index:number) {
					let thisField : any = fields[index]
					let thisPackageName : string = thisField?.fields[0].value || ""
					let appResult: any = thisResult

					let permissions: any = appResult.permissions
					let appTracks: any = appResult.appTracks
					let appTitle: any = encodingHelpers.unescapeHTML(appResult.title)
					thisField.fields[0].appTitle = appTitle
					packageNameSelectOptions.push({
						"id": thisPackageName,
						"text": appTitle + ' ' + '(' + thisPackageName + ')'
					})
					;(thisField.fields || []).forEach(function (field: any) {
						if (field.id == "accessibleTrackIds") {
							let addThisTracks: any[] = (appTracks || []).filter(function (appTrack: any) {
								let searchId: string = appTrack.trackId
								let foundOption: boolean = ((field.options || []).filter(function (option: any) {
									return option.id == searchId
								})).length > 0
								return !foundOption
							})
								; (addThisTracks || []).forEach(function (appTrack: any) {
									if (!Array.isArray(field.options)) {
										field.options = []
									}
									field.options.push(
										{
											"id": appTrack.trackId,
											"text": appTrack.trackAlias
										}
									)
								})
							field.disabled = false
						}
					})
					; (permissions || []).forEach(function (permission: any) {
						thisField?.fields[0].permissions.push({
							'id': permission.permissionId,
							'text': permission.permissionId
						})
					})
				});
				
				applications?.fields?.sort((loopEntryA: any, loopEntryB: any) => {
					let titleA = (loopEntryA.fields[0].appTitle || loopEntryA.fields[0].value).toLowerCase()
					let titleB = (loopEntryB.fields[0].appTitle || loopEntryB.fields[0].value).toLowerCase()
					return titleA > titleB ? 1 : -1
				})
				
				thisComponent.updateOptionsOfField('disallowedApplications',packageNameSelectOptions)
				
			}


			if (activePage == 'iosProfiles') {
				const devicesField = jsonFormHelpers.getFieldById(this.form, 'devices-ios')
				devicesField.value = devicesField.value.filter((deviceId:string) => {
					return (<Array<JsonFormSelectOption>>devicesField.options || []).some((option) => {
						return option.id == deviceId
					})
				})

				// get app infos
				let allowListedAppBundleIDsField = this.getFieldById(form, "allowListedAppBundleIDs")
				let blockedAppBundleIDsField = this.getFieldById(form, "blockedAppBundleIDs")
				let allUnknownOptions = (allowListedAppBundleIDsField.options.concat(blockedAppBundleIDsField.options)).filter(arrayHelpers.onlyUniqueFilter).filter((option:selectOption) => { 
					return option.id == option.text 
				}).map((option:selectOption) => {
					return { "Identifier":option.id }
				})
				

				allUnknownOptions = await products.mobileSecurity.iosApps.completeAssetInfos(allUnknownOptions,"Identifier")


				allowListedAppBundleIDsField.options.forEach((option:selectOption) => {
					if (option.id == option.text) {
						const appInfo = allUnknownOptions.find((unknownAppInfo:{'Identifier':string, assetInfo?:any}) => {
							return unknownAppInfo.Identifier == option.id
						})
						if (appInfo?.assetInfo?.trackName) {
							option.text = appInfo.assetInfo.trackName + " ("+option.id+")"
						}
					}
				})


			}

			// set tenants toggle in edit mode
			if(activePage == "apiKeys" && this.context == "edit") {
				const tenantsField = jsonFormHelpers.getFieldById(this.form, 'tenants')
				const toggleField = jsonFormHelpers.getFieldById(this.form, '_use_tenants')
				if (tenantsField.value.length > 0) {
					toggleField.value = false
				}
				else {
					toggleField.value = true
				}
			}

			if(activePage == "uscProfiles") {
				if(jsonFormHelpers.getFieldById(this.form, "cloudBackupTime").value == "0") {
					jsonFormHelpers.getFieldById(this.form, "activatorCloudBackupTime").activator.value = false;
					jsonFormHelpers.getFieldById(this.form, "cloudBackupPassword").value = ""
					jsonFormHelpers.getFieldById(this.form, "cloudBackupTime").value = "* 00 * * *"

				}
				jsonFormHelpers.getFieldById(this.form, "askRemoteFirst").value = jsonFormHelpers.getFieldById(this.form, "askRemoteFirst").value == 1
			}
			return true
		},
		updateOptionsOfField: function(this:any,fieldId:string,addOptions:any[]) {
			let field : any = jsonFormHelpers.getFieldById(this.form,fieldId)
			if(field != -1 && (field?.options || []).length) {
				;(addOptions|| []).forEach(function(addOption:any) {
					let optionsFound : any[] = field.options.filter(function(option:any) {
						return option.id == addOption.id
					})
					let optionExists : boolean = optionsFound.length > 0
					if(!optionExists) {
						if (!Array.isArray(field.options)) {
							field.options = []
						}
						field.options.push(addOption)
					}
					else {
						optionsFound[0].text = addOption.text
					}
				})

			}
			else if(field != -1 && field != undefined) {
				field.options = addOptions
			}
			else {
				throw "field not found"
			}
		},
		setSecurityDependingOnLicenseFromDevices: function(this:any) {
			devLog.log("[formpage]","License check started...")
			this.updateDevicesOptions()
		},
		updateDevicesOptions: function(this : any) {
			if (this.initialized) {
				let devicesField : any = undefined,
				devicesDefaultOptions : any = undefined,
				platformField : any = jsonFormHelpers.getFieldById(this.form,'profilePlatform'),
				securityTab : any = undefined,
				securitySection : any = undefined,
				securityActivated : boolean = false,
				lowestLicenseOption : any = this.getLowestLicenseTypeOfProfileDeviceOptions()
				switch(platformField.value) {
					case 'IOS':
						securityTab = jsonFormHelpers.getFieldById(this.form,'tab_security')
						devicesField = jsonFormHelpers.getFieldById(this.form,'devices-ios')
						devicesDefaultOptions = JSON.parse(JSON.stringify(jsonHelpers.getObjectProperty(this,"selectOptions.iosDevices" || [])))
					break;
					case 'ANDROID':
						securityTab = jsonFormHelpers.getFieldById(this.form,'tab_security')
						devicesField = jsonFormHelpers.getFieldById(this.form,'devices-android')
						devicesDefaultOptions = JSON.parse(JSON.stringify(jsonHelpers.getObjectProperty(this,"selectOptions.androidDevices" || [])))
					break;
					
				}
				if(this.activePage == 'androidProfiles') {
					securityTab = jsonFormHelpers.getFieldById(this.form, 'tab_emm_security')
					devicesField = jsonFormHelpers.getFieldById(this.form, 'devices-emm')
					devicesDefaultOptions = JSON.parse(JSON.stringify(jsonHelpers.getObjectProperty(this, "selectOptions.emmDevices" || [])))
				}
				if(securityTab) {
					securitySection = securityTab.fields[0]
					securityActivated = (securitySection.id == "section_security" && securitySection.activator.value == true) || (securitySection.id == "toggleEnterpriseSecuritySection" && securitySection.value == true)

					if(securityActivated && lowestLicenseOption == "MDM") {
						var newOptions = devicesDefaultOptions.filter((option:any) => {
							let thisDevice = this.devices.filter( function (device : any) {
								return device.deviceId == option.id
							})
							thisDevice = thisDevice.length > 0 ? thisDevice[0] : undefined

							if (thisDevice != undefined && [undefined,"MDM"].indexOf(jsonHelpers.getObjectProperty(thisDevice,'licenseInformation.type')) == -1 && thisDevice.configured) {
								return true
							}
						})

						if(devicesField.options.length != newOptions.length || JSON.stringify(devicesField.options) != JSON.stringify(newOptions)) {
							devicesField.options = newOptions
						}
					}
					else if(!securityActivated) {
						var newOptions = devicesDefaultOptions
						if(devicesField.options.length != newOptions.length || JSON.stringify(devicesField.options) != JSON.stringify(newOptions)) {
							devicesField.options = newOptions
						}
					}
				}
			}
		},
		getLowestLicenseTypeOfProfileDevices: function(this:any) {
			// GET THE LOWEST LICENSE FROM ALL DEVICES (MDM > Mobile Security)
			let thisComponent: any = this,
			result :string = "",
			devicesField : any = undefined,
			platformField : any = jsonFormHelpers.getFieldById(this.form,'profilePlatform')
			result = licenseHelpers.hasLicense(this.$root.activeAccountId, 'Mobile Security','valid') ? 'Mobile Security' : 'MDM'
			switch(platformField.value) {
				case 'IOS':
					devicesField = jsonFormHelpers.getFieldById(this.form,'devices-ios')
				break;
				case 'ANDROID':
					devicesField = jsonFormHelpers.getFieldById(this.form,'devices-android')
				break;
				case 'ANDROID_ENTERPRISE':
					devicesField = jsonFormHelpers.getFieldById(this.form,'devices-emm')
				break;
			}
			let devices : any[] = devicesField.value || []

			;(devices || []).forEach(function (deviceId: any) {
				let thisDevice = thisComponent.devices.items.filter(function (device: any) {
					return device.deviceId == deviceId
				})
				let licenseInformation: any = jsonHelpers.getObjectProperty(thisDevice[0], "licenseInformation")
				if (licenseInformation != undefined) {
					if (result == "MDM") {
						// Ignore
					}
					else {
						result = licenseInformation.type
					}
				}
				else {
					result = "MDM"
				}
			})
			
			return result
		},
		getLowestLicenseTypeOfDeviceIdList: function(this:any,devices:string[]) {
			// GET THE LOWEST LICENSE FROM ALL DEVICES (MDM > Mobile Security)
			let thisComponent : any = this,
			result :string = "",
			devicesField : any = undefined,
			platformField : any = jsonFormHelpers.getFieldById(this.form,'profilePlatform');

			;(devices || []).forEach(function(deviceId:any) {
				let thisDevice : any = {}

				if(thisComponent.activePage == "androidProfiles") {
					thisDevice = thisComponent.enterpriseDevices.filter(function (device: any) {
						return device.id == deviceId
					})
				}
				else {
					thisDevice = thisComponent.devices.filter(function (device: any) {
						return device.deviceId == deviceId
					})
				}
				

				let licenseInformation: any = jsonHelpers.getObjectProperty(thisDevice[0], "licenseInformation")


				if (licenseInformation != undefined) {
					if (result == "MDM") {
						// Ignore
					}
					else {
						result = licenseInformation.type
					}
				}
				else {
					result = "MDM"
				}
			})

			return result
		},
		getLowestLicenseTypeOfProfileDeviceOptions: function(this:any) {
			// GET THE LOWEST LICENSE FROM ALL DEVICES (MDM > Mobile Security)
			let result :string = "",
			devicesField : any = undefined,
			platformField : any = jsonFormHelpers.getFieldById(this.form,'profilePlatform')
			if (!licenseHelpers.hasLicense(this.$root.activeAccountId,"Mobile Security",'valid') && licenseHelpers.hasLicense(this.$root.activeAccountId,'MDM','valid')) {
				result = "MDM"
			}
			else {
				switch(platformField.value) {
					case 'IOS':
						devicesField = jsonFormHelpers.getFieldById(this.form,'devices-ios')
					break;
					case 'ANDROID_ENTERPRISE':
						devicesField = jsonFormHelpers.getFieldById(this.form,'devices-emm')
					break;
				}
			}
			let devices : any[] = devicesField ? devicesField.options || [] : []
			if (devices.length > 0) {
				for (let i : number = 0; devices.length > i; i++) {
					let deviceId = devices[i].id

					let devicesStore = this.activePage == "androidProfiles" ? this.enterpriseDevices : this.devices

					let thisDevice = (devicesStore || []).filter( function (device : any) {
						return (device.deviceId == deviceId) || (device.id == deviceId)
					})
					let licenseInformation : any = jsonHelpers.getObjectProperty(thisDevice[0],"licenseInformation")
					if (licenseInformation != undefined) {
						if (result == "") {
							result = licenseInformation.type
						}
						else if (result == "MDM") {
							// Do nothing
							break;
						}
						else if (result == "Mobile Security" && licenseInformation.type == "MDM") {
							result = "MDM"
						}
					}
					else {
						result = "MDM"
					}
				}
			}
			else {
				result = licenseHelpers.hasLicense(this.$root.activeAccountId,'Mobile Security','valid') ? 'Mobile Security' : 'MDM'
			}
			return result
		},

		setAllReadonly: async function(this:any,formJson:any,path:any = []) {
			for (let fieldIndex in formJson.fields) {
				let field = formJson.fields[fieldIndex]
				let fieldProperty : any = field.property || null
				let fieldPath : any = JSON.parse(JSON.stringify(path))
				if (fieldProperty) {
					fieldPath.push(fieldProperty)
				}
				if (field.type == "tabs" || field.type == "tab" || field.type == "section") {
					if (field.activator != undefined) {
						field.activator.disabled = true
					}
					this.setAllReadonly(field,fieldPath)
				}
				else if (field.type == "loopentry") {
					field.disabled = true
					this.setAllReadonly(field.fields,[])
				}
				else if (field.type == 'loop') {
					field.disabled = true
					for (let fieldIndex in field.fields) {
						this.setAllReadonly(field.fields[fieldIndex],[])
					}
				}
				else {
					field.disabled = true
				}
			}
			return true
		},
		fillFormWithData: async function(this:any,formJson:any,object:any,path:any = []) {
			let thisComponent : any = this
			for (let fieldIndex in formJson.fields) {
				let field = formJson.fields[fieldIndex]
				let fieldProperty : any = field.property || null
				let fieldPath : any = JSON.parse(JSON.stringify(path))
				if (field.id == 'conditionVariablesUsers') {
				}
				if (fieldProperty) {
					fieldPath.push(fieldProperty)
				}
				if (field.type == "tabs" || field.type == "tab" || field.type == "section") {
					if (field.activator) {
						let objectValue : any = jsonHelpers.getObjectProperty(object,fieldPath.join('.'))
						if (objectValue != undefined) {
							field.activator.value = true
						}
					}

					let objectValue: any = jsonHelpers.getObjectProperty(object, fieldPath.join('.'))


					if (objectValue != undefined) {
						// ACTIVATE THE _USE_ TOGGLES
						if (field.conditions != undefined && field.conditions.length) {
							for (let conditionIndex in field.conditions) {
								if (field.conditions[conditionIndex].refId) {
									if (field.conditions[conditionIndex].refId.indexOf('_use_') != -1) {
										let useField: any = jsonFormHelpers.getFieldById(this.form, field.conditions[conditionIndex].refId)
										useField.value = field.conditions[conditionIndex].value
									}
								}
							}
						}
					}


					// exception for usc partials
					if (field.id == "uscAdministrationPartial") {
						let hasPartialPermission = Object.keys(object.permissionScopes).some((permissionScope : string) => {
							return permissionScope.indexOf("usc:administration:") != -1
						})
						if(hasPartialPermission) {
							field.visible = true
						}
					}

					this.fillFormWithData(field,object,fieldPath)
				}
				else if (field.type == "loopentry") {
					for (let objectIndex in object) {
						this.fillFormWithData(field,object[objectIndex],[])
					}
				}
				else if (field.type == 'loop') {
					let objectValue : any = jsonHelpers.getObjectProperty(object,fieldPath.join('.'))
					if (objectValue != undefined) {

						;(field.disableConditions || []).forEach((condition:any) => {
							if ((condition?.refId || "").indexOf('_use_') != -1) {
								let conditionField : any = jsonFormHelpers.getFieldById(this.form,condition.refId)
								conditionField.value = !condition.value
							}
						})
						
						;(objectValue || []).forEach((value:any,objectIndex:number) => {
							let stringFields = JSON.stringify(field.template)
							stringFields = stringFields.replace(/{index}/g, objectIndex.toString())
							stringFields = stringFields.replace(/{childIndex}/g, "{index}")
							stringFields = stringFields.replace(/{childChildIndex}/g, "{childIndex}")
							stringFields = stringFields.replace(/{childChildChildIndex}/g, "{childChildIndex}")
							let thisTitle: string = field.loopFieldTitle
							if (field.getLoopFieldTitleFrom) {
								if (objectValue[objectIndex][field.getLoopFieldTitleFrom]) {
									thisTitle = objectValue[objectIndex][field.getLoopFieldTitleFrom]
									if (!thisTitle && field.getLoopFieldTitleFromAlt) {
										if (objectValue[objectIndex][field.getLoopFieldTitleFromAlt]) {
											thisTitle = objectValue[objectIndex][field.getLoopFieldTitleFromAlt]
										}
									}
								}
							}
							
							field.fields.push(
								{
									"randomID": (+new Date).toString(36).slice(-5),
									"type": "loopentry",
									"minified": true,
									"title": thisTitle,
									"fields": JSON.parse(stringFields)
								}
							)
							this.fillFormWithData(field.fields[objectIndex], objectValue[objectIndex], []) 
						});
					}
				}
				else {
					if (fieldProperty != null) {
						let objectValue : any = jsonHelpers.getObjectProperty(object,fieldPath.join('.'))
						if (objectValue != "" && objectValue != null && objectValue != undefined && field.format) {
							if(typeof field.format == "object") {
								if(field.format.frontend) {
									switch(field.format.frontend) {
										case "milliseconds":
											if(typeof objectValue == "string") {
												objectValue = parseInt(objectValue)
											}
											if(field.format.backend == "seconds") {
												objectValue = objectValue * 1000
											}
											if(field.format.backend == "minutes") {
												objectValue = objectValue * 1000 * 60
											}
											if(field.format.backend == "hours") {
												objectValue = objectValue * 1000 * 60 * 60
											}
											if(field.format.backend == "days") {
												objectValue = objectValue * 1000 * 60 * 60 * 24
											}
										break;
										case "seconds":
											if(typeof objectValue == "string") {
												objectValue = parseInt(objectValue)
											}
											if(field.format.backend == "milliseconds") {
												objectValue = objectValue / 1000
											}
											if(field.format.backend == "minutes") {
												objectValue = objectValue * 60
											}
											if(field.format.backend == "hours") {
												objectValue = objectValue * 60 * 60
											}
											if(field.format.backend == "days") {
												objectValue = objectValue * 60 * 60 * 24
											}
										break;
										case "minutes":
											if(typeof objectValue == "string") {
												objectValue = parseInt(objectValue)
											}
											if(field.format.backend == "milliseconds") {
												objectValue = objectValue / 1000 / 60
											}
											if(field.format.backend == "seconds") {
												objectValue = objectValue / 60
											}
											if(field.format.backend == "hours") {
												objectValue = objectValue * 60
											}
											if(field.format.backend == "days") {
												objectValue = objectValue * 60 * 24
											}
										break;
										case "hours":
											if(typeof objectValue == "string") {
												objectValue = parseInt(objectValue)
											}
											if(field.format.backend == "milliseconds") {
												objectValue = objectValue / 1000 / 60 / 60
											}
											if(field.format.backend == "seconds") {
												objectValue = objectValue / 60 / 60
											}
											if(field.format.backend == "minutes") {
												objectValue = objectValue / 60
											}
											if(field.format.backend == "days") {
												objectValue = objectValue * 24
											}
										break;
										case "days":
											if(typeof objectValue == "string") {
												objectValue = parseInt(objectValue)
											}
											if(field.format.backend == "milliseconds") {
												objectValue = objectValue / 1000 / 60 / 60 / 24
											}
											if(field.format.backend == "seconds") {
												objectValue = objectValue / 60 / 60 / 24
											}
											if(field.format.backend == "minutes") {
												objectValue = objectValue / 60 / 24
											}
											if(field.format.backend == "hours") {
												objectValue = objectValue / 24
											}
										break;
									}
								}
							}
							else {
								switch(field.format) {
									case 'number':
										if (field.type == 'select2' && field.multiple == true) {
											if (objectValue) {
												objectValue = objectValue.map((val:string|number) => {
													return Number(val)
												})
											}
											else {
												objectValue = undefined
											}
										}
									break;
									case 'utcTimestamp':
										if (field.type == 'date') {
											if (objectValue && objectValue != "NaN") {
												objectValue = moment.utc(parseInt(objectValue) * 1000).format("DD.MM.YYYY")
											}
											else {
												objectValue = undefined
											}
										}
										if (field.type == 'dateTime') {
											if (objectValue && objectValue != "NaN") {
												objectValue = moment.utc(parseInt(objectValue) * 1000).format("DD.MM.YYYY HH:mm")
											}
											else {
												objectValue = undefined
											}
										}
									break;
									case 'timestamp':
										if (field.type == 'date') {
											if (objectValue && objectValue != "NaN") {
												objectValue = moment(parseInt(objectValue) * 1000).format("DD.MM.YYYY")
											}
											else {
												objectValue = undefined
											}
										}
										if (field.type == 'dateTime') {
											if (objectValue && objectValue != "NaN") {
												objectValue = moment(parseInt(objectValue) * 1000).format("DD.MM.YYYY HH:mm")
											}
											else {
												objectValue = undefined
											}
										}
									break;
									case 'arrayToString':
										objectValue = (typeof objectValue == "string") ? objectValue.split(",") : []

									break;
									case 'parseEachChild':
										if(objectValue) {
											for (let i : number = 0; objectValue.length > i; i++) {
												if(field.id == 'certificates') {
													objectValue[i] = {
														"certificateId":objectValue[i].certificateId,
														"name":objectValue[i].name,
														"data":"",
														"type":"pkcs1"
													}
												}
												objectValue[i] = JSON.stringify(objectValue[i])
											}
											if(field.id == 'certificates') {
												objectValue = objectValue.filter(function (value : any, index : any, self : any) {
													return self.indexOf(value) === index;
												})
											}
										}

									break;
									case 'unicodeToPunycode':
										objectValue = encodingHelpers.punycodeToUnicode(objectValue)
									break;
								}
							}
						}


						if (fieldProperty == 'homeScreenLayout') {
							// Set Homescreenlayout type 
							if (object.profile.homeScreenLayout) {
								let homescreenLayoutTypeSelect: any = jsonFormHelpers.getFieldById(this.form as JsonFormShemaObject, 'homeScreenLayoutTypeSelect')

								if (typeof object.profile.homeScreenLayout == "string") {
									homescreenLayoutTypeSelect.value = "UUID"
									if (field.id == "homeScreenLayoutUUIDSelect") {
										objectValue == object.profile.homeScreenLayout
									}
									else {
										objectValue = {
											"Dock": [],
											"Pages": [
												[]
											]
										}
									}
								}
								else {
									homescreenLayoutTypeSelect.value = "Object"
									if (field.id == "homeScreenLayoutUUIDSelect") {
										objectValue == ""
									}
									else {
										objectValue = object.profile.homeScreenLayout
									}
								}
							}

						}
						if (objectValue != undefined && objectValue != null) {


							// SELECT2
							if (
								field.select2Options != undefined 
								|| ['select2Tags', 'select2IpMask', 'select2AppSearch','select2AppSearchTvOs'].indexOf(field.select2Options) != -1 
								|| ['accessibleTrackIds'].indexOf(field.property) != -1
								|| (field.type == "select2" && field.tags == true)
							) {
								if (field.multiple) {
									// Select2 Tag (Multiple)
									for (let valueIndex in objectValue) {
										var value = objectValue[valueIndex]
										var hasValue = false
										for (let i in field.options) {
											if (field.options[i].id == value) {
												hasValue = true
											}
										}
										if (!hasValue) {
											if (!Array.isArray(field.options)) {
												field.options = []
											}
											field.options.push(
												{
													"id":value,
													"text":value
												}
											)
										}
									}
								}
								else {
									// Select2 Tag (Single)
									var value = objectValue
									var hasValue = false
									for (var i in field.options) {
										if (field.options[i].id == value) {
											hasValue = true
										}
									}
									if (!hasValue) {
										if (!Array.isArray(field.options)) {
											field.options = []
										}
										field.options.push(
											{
												"id":value,
												"text":value
											}
										)
									}
								}
							}



							// SET VALUE
							if (typeof objectValue == "string" && objectValue != "") {
								objectValue = objectValue
							}
							field.value = objectValue
							// ACTIVATE THE _USE_ TOGGLES
							if (field.conditions != undefined && field.conditions.length) {
								for (let conditionIndex in field.conditions) {
									if (field.conditions[conditionIndex].refId) {
										if (field.conditions[conditionIndex].refId.indexOf('_use_') != -1) {
											let useField : any = jsonFormHelpers.getFieldById(this.form,field.conditions[conditionIndex].refId)
											useField.value = field.conditions[conditionIndex].value
										}
										else if (['selectWhiteBlackList','linkWithVPPUser'].indexOf(field.conditions[conditionIndex].refId) != -1) {
											if (objectValue.length) {
												let conditionField : any = jsonFormHelpers.getFieldById(this.form,field.conditions[conditionIndex].refId)
												conditionField.value = field.conditions[conditionIndex].value
											}
										}
									}
								}
							}
							// KOMISCHE AUSNAHMEN
							if (field.id == 'vpnSubType') {
								if (objectValue == 'de.securepoint.ms.agent') {
									field.visible = false
								}
								else {
									field.visible = true
								}
							}
						}
						else if (objectValue == undefined) {
							if(fieldProperty == 'cf_blacklist') {
								field.value = ["127.0.28.1","127.0.28.2","127.0.28.8"]
							}
						}
						// #38119
						if (fieldProperty == "tetheringSettings") {
							if(typeof object?.tetheringConfigDisabled == "boolean" && object.tetheringConfigDisabled == true) {
								field.value = "DISALLOW_ALL_TETHERING"
							}
						}
						// #38118
						if(fieldProperty == "configureWifi") {
							if(typeof object?.wifiConfigDisabled == "boolean" && object.wifiConfigDisabled == true) {
								field.value = "DISALLOW_CONFIGURING_WIFI"
							}
						}
					}
				}
			}
			return true
		},
		generateObject: function (this: any, formJson: JsonFormShemaObject,object:any = {},platform:any = null) {
			let objectValue : any = null


			if (['iosProfiles'].indexOf(this.activePage) != -1 && platform == null) {
				platform = jsonFormHelpers.getFieldById(this.form,'profilePlatform').value
			}
			for (let fieldIndex in formJson.fields) {
				let field = formJson.fields[Number(fieldIndex)]
				let fieldProperty : any = field.property || null
				if (this.areConditionsFulfilled(field.sendConditions || [],field) && this.areConditionsFulfilled(field.conditions || [],field)) {
					if (["managedConfiguration","tabs","tab","section"].indexOf(field.type) != -1) {
						if (fieldProperty != null) {
							// HAS PROPERTY
							if (field.activator) {
								if (field.activator.value == true) {
									if (!object[fieldProperty]) {
										object[fieldProperty] = {}
									}
									object[fieldProperty] = this.generateObject(field,object[fieldProperty],platform)
								}
							}
							else {
								if (fieldProperty == "appconfigs") {
									if (!object[fieldProperty]) {
										object[fieldProperty] = []
										object[fieldProperty].push({})
									}
									object[fieldProperty][0] = this.generateObject(field,{},platform)
								}
								else {
									if (!object[fieldProperty]) {
										object[fieldProperty] = {}
									}
									object[fieldProperty] = this.generateObject(field,object[fieldProperty],platform)
								}
							}
							if ((this.activePage == 'androidProfiles' || this.activePage == 'iosProfiles' || this.activePage == 'enterpriseProfiles') && fieldProperty != 'profile' && jsonHelpers.isObjectEmpty(object[fieldProperty])) {
								delete object[fieldProperty]
							}
						}
						else {
							// HAS NO PROPERTY
							if (field.activator) {
								if (field.activator.value == true) {
									(<any>Object).assign(object,this.generateObject(field,object,platform))
								}
							}
							else {
								(<any>Object).assign(object,this.generateObject(field,object,platform))
							}
						}
					}
					else if (field && field.type == "loop") {
						if (fieldProperty != null && (field.fields?.length || 0) > 0) {
							if (!object[fieldProperty]) {
								object[fieldProperty] = []
							}
							for (let arrayIndex in field.fields) {
								let thisObject : any = this.generateObject(field.fields[Number(arrayIndex)],{},platform)
								object[fieldProperty].push(thisObject)
							}
						}
					}
					else {
						if (fieldProperty != null) {
							if(field.format && typeof field.format == "object") {
								if(field.format.backend) {
									switch(field.format.backend) {
										case "milliseconds":
											if(typeof field.value == "string") {
												objectValue = parseInt(field.value)
											}
											if(field.format.frontend == "seconds") {
												objectValue = field.value * 1000
											}
											if(field.format.frontend == "minutes") {
												objectValue = field.value * 1000 * 60
											}
											if(field.format.frontend == "hours") {
												objectValue = field.value * 1000 * 60 * 60
											}
											if(field.format.frontend == "days") {
												objectValue = field.value * 1000 * 60 * 60 * 24
											}
										break;
										case "seconds":
											if(typeof field.value == "string") {
												objectValue = parseInt(field.value)
											}
											if(field.format.frontend == "milliseconds") {
												objectValue = field.value / 1000
											}
											if(field.format.frontend == "minutes") {
												objectValue = field.value * 60
											}
											if(field.format.frontend == "hours") {
												objectValue = field.value * 60 * 60
											}
											if(field.format.frontend == "days") {
												objectValue = field.value * 60 * 60 * 24
											}
											if(field.format.type == "string-with-s-appended") {
												objectValue = objectValue + "s"
											}
										break;
										case "minutes":
											if(typeof field.value == "string") {
												objectValue = parseInt(field.value)
											}
											if(field.format.frontend == "milliseconds") {
												objectValue = field.value / 1000 / 60
											}
											if(field.format.frontend == "seconds") {
												objectValue = field.value / 60
											}
											if(field.format.frontend == "hours") {
												objectValue = field.value * 60
											}
											if(field.format.frontend == "days") {
												objectValue = field.value * 60 * 24
											}
										break;
										case "hours":
											if(typeof field.value == "string") {
												objectValue = parseInt(field.value)
											}
											if(field.format.frontend == "milliseconds") {
												objectValue = field.value / 1000 / 60 / 60
											}
											if(field.format.frontend == "seconds") {
												objectValue = field.value / 60 / 60
											}
											if(field.format.frontend == "minutes") {
												objectValue = field.value / 60
											}
											if(field.format.frontend == "days") {
												objectValue = field.value * 24
											}
										break;
										case "days":
											if(typeof field.value == "string") {
												objectValue = parseInt(field.value)
											}
											if(field.format.frontend == "milliseconds") {
												objectValue = field.value / 1000 / 60 / 60 / 24
											}
											if(field.format.frontend == "seconds") {
												objectValue = field.value / 60 / 60 / 24
											}
											if(field.format.frontend == "minutes") {
												objectValue = field.value / 60 / 24
											}
											if(field.format.frontend == "hours") {
												objectValue = field.value / 24
											}
										break;
									}
								}
							}
							else {
								switch(field.format || "default") {
									case 'utcTimestamp':
										if (field.id == 'expiresOn') {
											if (field.value) {
												let germanDateRegex: RegExp = RegExp(/[0-9]{2}.[0-9]{2}.[0-9]{4}/gm)
												if (germanDateRegex.test(field.value)) {
													objectValue = parseInt(moment.utc(field.value, "DD.MM.YYYY").unix().toString())
												}
												else {
													objectValue = parseInt(moment.utc(field.value).unix().toString())
												}
											}
											else {
												objectValue = ""
											}
										}
										else if (field.type == 'date') {
											if (field.value) {
												let germanDateRegex: RegExp = RegExp(/[0-9]{2}.[0-9]{2}.[0-9]{4}/gm)
												if (germanDateRegex.test(field.value)) {
													objectValue = moment.utc(field.value, "DD.MM.YYYY").unix().toString()
												}
												else {
													objectValue = moment.utc(field.value).unix().toString()
												}

											}
											else {
												objectValue = ""
											}
										}
										else if (field.type == 'dateTime') {
											if (field.value) {
												let germanDateRegex: RegExp = RegExp(/[0-9]{2}.[0-9]{2}.[0-9]{4}/gm)
												if (germanDateRegex.test(field.value)) {
													objectValue = (moment.utc(field.value, "DD.MM.YYYY HH:mm").toDate().getTime() / 1000).toString()
												}
												else {
													objectValue = (moment.utc(field.value).toDate().getTime() / 1000).toString()
												}

											}
											else {
												objectValue = ""
											}
										}
									break;
									case 'timestamp':
										if (field.id == 'expiresOn') {
											if (field.value) {
												let germanDateRegex : RegExp = RegExp(/[0-9]{2}.[0-9]{2}.[0-9]{4}/gm)
												if(germanDateRegex.test(field.value)) {
													objectValue = parseInt(moment(field.value,"DD.MM.YYYY").unix().toString())
												}
												else {
													objectValue = parseInt(moment(field.value).unix().toString())
												}

											}
											else {
												objectValue = ""
											}
										} 
										else if (field.type == 'date') {
											if (field.value) {

												let germanDateRegex : RegExp = RegExp(/[0-9]{2}.[0-9]{2}.[0-9]{4}/gm)
												if(germanDateRegex.test(field.value)) {
													objectValue = moment(field.value,"DD.MM.YYYY").unix().toString()
												}
												else {
													objectValue = moment(field.value).unix().toString()
												}

											}
											else {
												objectValue = ""
											}
										}
										else if (field.type == 'dateTime') {
											if (field.value) {
												let germanDateRegex: RegExp = RegExp(/[0-9]{2}.[0-9]{2}.[0-9]{4}/gm)
												if (germanDateRegex.test(field.value)) {
													objectValue = (moment(field.value, "DD.MM.YYYY HH:mm").toDate().getTime() / 1000).toString()
												}
												else {
													objectValue = (moment(field.value).toDate().getTime() / 1000).toString()
												}

											}
											else {
												objectValue = ""
											}
										}
									break;
									case 'arrayToString':
										objectValue = Array.isArray(field.value) ? field.value.join(",") : []
									break;
									case 'parseEachChild':
										objectValue = []
										for (let i : number = 0; field.value.length > i; i++) {
											objectValue.push(JSON.parse(field.value[i]))
										}
									break;
									case 'number':
										if (field.type == 'select2' && field.multiple == true) {
											if (field.value.length) {
												objectValue = field.value.map((val: string | number) => {
													return Number(val)
												})
											}
											else {
												objectValue = undefined
											}
										}
										else {
											objectValue = parseInt(field.value)
										}
									break;
									case 'string':
										objectValue = field.value.toString()
									break;
									case 'unicodeToPunycode':
										objectValue = encodingHelpers.unicodeToPunycode(field.value)
									break;
									default:
										objectValue = field.value
									break;
								}
							}


							if (this.activePage == "androidProfiles" && ['allowVPNSuspend','android_emm_allowAddProfiles'].indexOf(field.id) == -1) {
								if (field.type == 'toggle' && objectValue == false) {

								}
								else if (field.type == 'select2' && field.multiple && objectValue.length < 1) {
									if (field.id == 'cf_whitelist' || field.id == 'cf_blacklist') {
										object[fieldProperty] = []	
									}
								}
								else if (objectValue != null) {
									object[fieldProperty] = objectValue
								}
							}
							else if (objectValue != null) {
								object[fieldProperty] = objectValue
							}
						}
					}
				}
			}
			return object
		},
		areConditionsFulfilled: function (this: any, conditions: JsonFormCondition[], field?: any) {
			return jsonFormHelpers.areConditionsFulfilled(this, conditions,field)
		},
		deleteUseLessLoopfields: function(this:any,object:any) {
			let testFields : any = {
				"applications":"packageName",
				"permissionGrants":"permission",
				"choosePrivateKeyRules":"urlPattern",
				"persistentPreferredActivities":"receiverActivity",
				"policyEnforcementRules":"settingName",
				"setupActions":"launchApp",
				"openNetworkConfiguration":{
					"NetworkConfigurations": "Name"
				},

			}
			let property : string = ""
			for (let field in testFields) {
				if (typeof testFields[field] == "string") {
					property = testFields[field]
					if (jsonHelpers.getObjectProperty(object,field)) {
						let i : number = object[field].length
						while(i--) {
							let loopField = object[field][i]
							if (!jsonHelpers.getObjectProperty(loopField,property)) {
								object[field].splice(i,1)
							}
						}
					}
				}
				else if (typeof testFields[field] == "object") {
					for (let childField in testFields[field]) {
						property = testFields[field][childField]
						if (jsonHelpers.getObjectProperty(object,field+'.'+childField)) {
							let i : number = object[field][childField].length
							while(i--) {
								let loopField = object[field][childField][i]
								if (!jsonHelpers.getObjectProperty(loopField,property)) {
									object[field][childField].splice(i,1)
								}
							}
						}
					}
				}
			}
			for (let field in testFields) {
				if (typeof testFields[field] == "string") {
					property = testFields[field]
					if (jsonHelpers.getObjectProperty(object,field)) {
						let i : number = object[field].length
						if (i == 0) {
							delete object[field]
						}
					}
				}
				else if (typeof testFields[field] == "object") {
					for (let childField in testFields[field]) {
						property = testFields[field][childField]
						if (jsonHelpers.getObjectProperty(object,field+'.'+childField)) {
							let i : number = object[field][childField].length
							if (i == 0) {
								delete object[field][childField]
							}
							if (jsonHelpers.isObjectEmpty(object[field])) {
								delete object[field]
							}
						}
					}
				}
			}
			return object
		},
		async confirmSavingWithLicenseConflict(this:any) {
			dialogs.misc.confirmDialog(this.activeAccountId, "Confirm", T('Warning! Mobile Security is enabled in this profile, but it contains devices that are not licensed for Mobile Security. Please note that these devices may receive the Securepoint Mobile Security app, but cannot establish a VPN connection.'),async () => {
				await this.addOrUpdate()
			})
		},
		async save(this: any) {
			// #36305
			if (this.activePage == "uscProfiles" && Array.isArray(jsonFormHelpers.getFieldById(this.form, "tags")?.value) && (jsonFormHelpers.getFieldById(this.form, "tags")?.value as Array<string>).some((tag: string) => {
				return tag == "utms"
			})) {
				this.saveProfileWithUtmsTag()
			}
			else {
				this.addOrUpdate()
			}
		},
		async saveProfileWithUtmsTag(this: any) {
			dialogs.misc.confirmDialog(this.activeAccountId, T("Confirm Saving"), T('One of the selected tags is the "utms" tag. Please note that the selected action is executed on all your UTMs when this tag is selected.'), async () => {
				await this.addOrUpdate()
			}, undefined, T("Save"), "fal fa-save", undefined, "fal fa-save")
		},
		addOrUpdate: async function(this:any) {
			let thisComponent : any = this
			let tenantDomain = tenantHelpers.getTenantDomain(useStore().state.session.activeAccountId || "")
			thisComponent.loading = true
			this.clearErrors(this.form)
			let object : any = this.generateObject(jsonHelpers.copyObject(this.form))
			let objectType : string = this.activePage
			let queryType : string = this.activePage
			if (this.context == 'addVpp' || this.context == 'editVpp') {
				objectType = 'vppusers'
			}
			if (thisComponent.activePage == "androidProfiles") {
				queryType = "enterpriseProfiles"
			}
			if(objectType == "iosProfiles") {
				objectType = "profiles"
				queryType = "profiles"
			}
			if(object.platform && object.platform == "IOS_SHARED_IPAD") {
				object.platform = "IOS"
			}
			if(object.platform && object.platform == "IOS_CUSTOM_MOBILECONFIG") {
				object.platform = "IOS"
			}

			// KOMISCHE AUSNAHMEN
			if (["iosProfiles","androidProfiles","profiles"].indexOf(objectType) != -1) {
				object = this.deleteUseLessLoopfields(object)
				if (jsonHelpers.getObjectProperty(object,"profile.security.appconfigs")) {
					object.appconfigs = object.profile.security.appconfigs
					delete object.profile.security.appconfigs
				}

				// declarativeManagement
				if (jsonHelpers.getObjectProperty(object, "profile.declarativeManagement.subscriptions") != undefined) {
					object.profile.declarativeManagement.subscriptions = Object.keys(object.profile.declarativeManagement.subscriptions).filter((key) => {
						return object.profile.declarativeManagement.subscriptions[key]
					}).map((key) => {
						return key.replaceAll("_", ".")
					})
				}


				if (jsonHelpers.getObjectProperty(object,"useConditionVariables") == true) {
					delete object.useConditionVariables;
						(object.applications || []).forEach(function(application:any) {
							if (jsonHelpers.getObjectProperty(application,"managedConfigurationTemplate") != undefined) {
								if (jsonHelpers.getObjectProperty(application,"managedConfigurationTemplate.configurationVariables") == undefined) {
									application.managedConfigurationTemplate.configurationVariables = {
										"%users%":""
									}
								}
								if (jsonHelpers.getObjectProperty(object,"conditionVariablesUsers") != undefined) {
									application.managedConfigurationTemplate.configurationVariables["%users%"] = object.conditionVariablesUsers.toString()
								}
								else {
									application.managedConfigurationTemplate.configurationVariables["%users%"] = ""
								}
							}
						})
					if (jsonHelpers.getObjectProperty(object,"conditionVariablesUsers") != undefined) {
						delete object.conditionVariablesUsers
					}
				}
				else if (jsonHelpers.getObjectProperty(object,"applications")){
					(object.applications || []).forEach(function(application:any) {
						if (jsonHelpers.getObjectProperty(application,"managedConfigurationTemplate") != undefined) {
							if (jsonHelpers.getObjectProperty(application,"managedConfigurationTemplate.configurationVariables") != undefined) {
								delete application.managedConfigurationTemplate.configurationVariables
							}
						}
					})
				}

				if (jsonHelpers.getObjectProperty(object,"smsManagedConfiguration") != undefined) {
					let securepointApp = (object.applications || []).find(function(application:any) {
						return application.packageName == "de.securepoint.ms.agent"
					})
					securepointApp.installType = "FORCE_INSTALLED"
					securepointApp.lockTaskAllowed = true
					securepointApp.managedConfiguration = object.smsManagedConfiguration

					if (!securepointApp.managedConfiguration.exclude_ssid) {
						securepointApp.managedConfiguration.exclude_ssid = []
					}
					if (!securepointApp.managedConfiguration.disallowedApplications) {
						securepointApp.managedConfiguration.disallowedApplications = []
					}

					//securepointApp.managedConfiguration.timestamp = Math.round((new Date()).getTime() / 1000)
					securepointApp.managedConfiguration.vpnConfigDownloadUrl = window.origin + '/sms-mgt-api/api/' + apis.getApiVersionForFeature('androidEnterprise') + '/tenants/' + tenantDomain + '/android/emm/enterprise/policies/' + object.name + '/enrollVPN/' + thisComponent.$store.state.session.accounts[thisComponent.activeAccountId].mobileSecurity.settings.android.enterprise.VDT
					delete object.smsManagedConfiguration
				}

				if (object.toolboxCertificates && object.toolboxCertificates.length > 0) {
					let toolboxApp = (object.applications || []).find(function (application: any) {
						return application.packageName == "de.securepoint.emm.toolbox"
					})
					if (toolboxApp != undefined) {
						const uninstallCerts = object.toolboxCertificatesOld.filter((certInfo:string) => {
							return object.toolboxCertificates.indexOf(certInfo) == -1
						}).concat(object.toolboxCertificatesUninstallOld)

						toolboxApp.managedConfiguration = {
							"certificates": {
								"install":object.toolboxCertificates,
								"uninstall": uninstallCerts
							}
						}
					}
				}

				delete object.toolboxCertificatesUninstallOld
				delete object.toolboxCertificatesOld
				delete object.toolboxCertificates

				if (jsonHelpers.getObjectProperty(object, "profile.homeScreenLayout") != undefined && typeof object.profile.homeScreenLayout != "string") {
					object.profile.homeScreenLayout = products.mobileSecurity.homescreenlayouts.stripExtrasFromHomescreenLayout(object.profile.homeScreenLayout)
				}
			}
			if(this.activePage == 'androidProfiles') {
				object?.deviceConnectivityManagement?.tetheringSettings == "TETHERING_SETTINGS_UNSPECIFIED" ? delete object.deviceConnectivityManagement.tetheringSettings : undefined
			}
			if(this.activePage == 'apiKeys') {
				delete object.key
			}
			if (this.activePage == 'windowsEnrollmentTokens') {
				typeof object.name == "string" ? object.name = object.name.trim() : undefined
				let nameField = jsonFormHelpers.getFieldById(this.form, "name")
				typeof nameField.value == "string" ? nameField.value = nameField.value.trim() : undefined
				
			}
			if (this.activePage == 'uscProfiles') {
				if (object?.profile?.update?.additionalSuccessCheckEndpoint?.url) {
					let urlText = object.profile.update.additionalSuccessCheckEndpoint.url
					object.profile.update.additionalSuccessCheckEndpoint.url = urlText.replace("https://", "").replace("http://", "")
				}
				if (object.profile?.globalVpnSettings != undefined) { 
					if (!object.profile?.globalVpnSettings?.nameserver) {
						object.profile.globalVpnSettings.nameserver = []
					}
					const vpnNameserver = object.profile.globalVpnSettings.nameserver
					const primaryVpnNameserverField = jsonFormHelpers.getFieldById(this.form, "primaryVpnNameserver")
					const secondaryVpnNameserverField = jsonFormHelpers.getFieldById(this.form, "secondaryVpnNameserver")
					if (primaryVpnNameserverField && secondaryVpnNameserverField) {
						if (secondaryVpnNameserverField.value != "" && primaryVpnNameserverField.value == "") {
							vpnNameserver[0] = secondaryVpnNameserverField.value
						}
						else {
							if (Array.isArray(vpnNameserver)) {
								if (primaryVpnNameserverField.value) {
									vpnNameserver[0] = primaryVpnNameserverField.value
								}
								if (secondaryVpnNameserverField.value) {
									vpnNameserver[1] = secondaryVpnNameserverField.value
								}
							}
							delete object.profile.globalVpnSettings.primaryVpnNameserver
							delete object.profile.globalVpnSettings.secondaryVpnNameserver
						}

					}
				}

				if (object?.profile?.nameserver != undefined) {
					if (!object.profile.nameserver.ips) {
						object.profile.nameserver.ips = []
					}
					const nameserver = object.profile.nameserver.ips
					const primaryNameserverField = jsonFormHelpers.getFieldById(this.form, "primaryNameserver")
					const secondaryNameserverField = jsonFormHelpers.getFieldById(this.form, "secondaryNameserver")
					if (primaryNameserverField && secondaryNameserverField) {
						if (secondaryNameserverField.value != "" && primaryNameserverField.value == "") {
							if (secondaryNameserverField.value) {
								nameserver[0] = secondaryNameserverField.value
							}
						}
						else {
							if (Array.isArray(nameserver)) {
								if (primaryNameserverField.value) {
									nameserver[0] = primaryNameserverField.value
								}
								if (secondaryNameserverField.value) {
									nameserver[1] = secondaryNameserverField.value
								}
							}
							delete object.profile.nameserver.primaryNameserver
							delete object.profile.nameserver.secondaryNameserver
						}
					}
				}
			}
			if (this.activePage == 'homescreenlayouts') {
				
				object.homescreenLayout = products.mobileSecurity.homescreenlayouts.stripExtrasFromHomescreenLayout(object.homescreenLayout)
				object.Pages = object.homescreenLayout.Pages
				object.Dock = object.homescreenLayout.Dock
				if (this.objectId) {
					object.layoutId = this.objectId
				}
				delete object.homescreenLayout
			}
			try {
				let result : any = null
				let result2 : any = null
				let licenseUUID : string = ""

				if(this.activePage == 'vpns' && object.licenseUUID == "") {
					throw {
						"errors":{
							"errors":[
								{
									"message":T("Missing license"),
									"params":{
										"message":T("Missing license")
									},
									"code":500,
									"dataPath":"/licenseUUID",
									"schemaPath":"/properties/licenseUUID",
									"subErrors":null,
									"stack":null
								}
							],
							"missing":[],
							"valid":false
						}
					}
				}

				if (object.permissionScopes) {
					let newPermissionScopes : string[] = []
					for (let property in object.permissionScopes) {
						if (object.permissionScopes[property] === true) {
							newPermissionScopes.push(property)
						}
					}
					object.permissionScopes = newPermissionScopes
				}



				if (this.objectId == undefined && this.activePage == "users") {

					let newProductType = config.canUseNewObjectType(this.objectType) ? products?.[this.productType as keyof typeof products] : undefined
					let newObjectType = newProductType?.[this.objectType as keyof typeof newProductType]

					let saveUser = async () => {
						try {
							if (newProductType != undefined && newObjectType != undefined && config.canUseNewObjectType(this.objectType)) {
								//@ts-ignore
								return await newObjectType.queries.addOrUpdateObjectFromApi(this.activeAccountId, undefined, object, (object.customerId || thisComponent.zeroTouchCustomerId || undefined))
							}
							else {
								return await useStore().dispatch(ActionTypes.addOrUpdateObject, {
									"accountId": this.activeAccountId,
									"objectType": objectType,
									"queryType": queryType,
									"object": object,
									"customerId": object.customerId || thisComponent.zeroTouchCustomerId || undefined
								})
							}
						}
						catch(e:any) {
							console.error(e)
							throw e?.data ? e.data : e
						}
					}

					getterHelpers.useStore().dispatch(ActionTypes.addModal, {
						"id": "sendUserPasswordReset",
						"accountId": this.activeAccountId,
						"abortable": true,
						"content": {
							"title": {
								"text": T("Send password reset email?"),
								"icon": "fal fa-question",
							},
							"body": {
								"use": true,
								"content": "<div class='text-center'><p>" + T("Would you like to send the new user an e-mail to reset the password?") + "</p><p>" + T("The email will be sent to the following address:") + "</p><p class='notification margin-xs-y-2' style='font-size:1.2em; width:max-content; margin-left:auto; margin-right:auto'>"+object.profile?.email+"</p></div>" 
							},
						},
						"buttons": [
							{
								loading: false,
								onClick: async function () {
									thisComponent.loading = false
									getterHelpers.useVue().$refs.modals.removeModal()
								},
								icon: "fal fa-times",
								text: T("Abort"),
								disabled: false
							
							},
							{
								loading: false,
								onClick: async function () {
									try {
										await saveUser()
										thisComponent.loading = false
										thisComponent.redirectToDashboard()
									}
									catch(e) {
										console.error(e)
										thisComponent.loading = false
										thisComponent.catchErrors(e)
									}
									getterHelpers.useVue().$refs.modals.removeModal()
								},
								icon: "fal fa-save",
								text: T("Add User only"),
								disabled: false
							},
							{
								loading: false,
								onClick: async function () {

									try {
										await saveUser()
										requestHandler.request("POST", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(thisComponent.activeAccountId) + "/users/temporary", {
											"username": object.username
										})
										thisComponent.loading = false
										frontendNotifications.addNotification(thisComponent.activeAccountId, {
											"accountId": thisComponent.activeAccountId,
											"content": {
												"body": {
													"content": T("A password reset e-mail has been sent to %s.").replace("%s", object.profile?.email || ""),
												},
												"title": {
													"text": T("Password Reset E-Mail Requested"),
													"icon": "fal fa-check"
												}
											},
											"highlightNew": true,
										})
										thisComponent.redirectToDashboard()
										getterHelpers.useVue().$refs.modals.removeModal()
									}
									catch (e: unknown) {
										console.error(e)
										thisComponent.loading = false
										thisComponent.catchErrors(e)
										getterHelpers.useVue().$refs.modals.removeModal()
									}

									
								},
								icon: "fal fa-envelope",
								text: T("Send password reset email"),
								disabled: false
							}
						]
					})

				}
				else if (this.objectId == undefined) {
					let newProductType = config.canUseNewObjectType(this.objectType) ? products?.[this.productType as keyof typeof products] : undefined
					let newObjectType = newProductType?.[this.objectType as keyof typeof newProductType]

					if (newProductType != undefined && newObjectType != undefined && config.canUseNewObjectType(this.objectType)) {
						//@ts-ignore
						result = await newObjectType.queries.addOrUpdateObjectFromApi(this.activeAccountId, undefined, object, (object.customerId || thisComponent.zeroTouchCustomerId || undefined))
					}
					else {
						result = await useStore().dispatch(ActionTypes.addOrUpdateObject, {
							"accountId": this.activeAccountId,
							"objectType": objectType,
							"queryType": queryType,
							"object": object,
							"customerId": object.customerId || thisComponent.zeroTouchCustomerId || undefined
						})
					}
				
					if (jsonHelpers.getObjectProperty(result, "code") == 400 || jsonHelpers.getObjectProperty(result, "status") == 400) {
						throw result.data
					}
					else {
						nextTick(async function() {
							thisComponent.redirectToDashboard()
						})
					}
				}
				else {
					// UPDATE OBJECT
					if (objectType == "vpns") {
						licenseUUID = object.licenseUUID
						delete object.licenseUUID
					}
					switch(objectType) {
						case 'inventory':
							
							if(this.$root.context == 'editDevice') {
								if (config.canUseNewObjectType('iosDevices')) {
									result = await products.mobileSecurity.iosDevices.queries.updateObjectPropertiesFromApi(this.activeAccountId, this.objectId, object, undefined, undefined, undefined, '/properties', 'POST')
								}
								else {
									result = await useStore().dispatch(ActionTypes.updateObjectInventory,{
										"accountId":this.activeAccountId,
										"productType":'mobileSecurity',
										"objectType":'devices',
										"queryType":'devices',
										"objectId":this.objectId,
										"object":object
									})
								}
							}
							else if(this.$root.context == 'editEnterprise') {
								if (config.canUseNewObjectType('androidDevices')) {
									result = await products.mobileSecurity.androidDevices.queries.updateObjectPropertiesFromApi(this.activeAccountId, this.objectId, object, undefined, undefined, undefined, '/properties', 'PUT')
								}
								else {
									result = await useStore().dispatch(ActionTypes.updateObjectInventory,{
										"accountId":this.activeAccountId,
										"productType":'mobileSecurity',
										"objectType":'enterpriseDevices',
										"queryType":'enterpriseDevices',
										"objectId":this.objectId,
										"object":object
									})
								}
							}
							else if(this.$root.context == 'editVPN') {
			
								if(config.canUseNewObjectType('vpns')) {
									result = await products.mobileSecurity.vpns.queries.updateObjectPropertiesFromApi(this.activeAccountId, this.objectId, object, undefined, undefined, undefined, '/properties','POST')
								}
								else {
									result = await useStore().dispatch(ActionTypes.updateObjectInventory, {
										"accountId": this.activeAccountId,
										"productType": 'mobileSecurity',
										"objectType": 'vpns',
										"objectId": this.objectId,
										"object": object
									})
								}
								
								
							}
							else if(this.$root.context == 'editReportingUtm') {
								result = await useStore().dispatch(ActionTypes.updateObjectInventory,{
									"accountId":this.activeAccountId,
									"productType":'unifiedReporting',
									"objectType":'utms',
									"objectId":this.objectId,
									"object":object
								})
							}
							else if(this.$root.context == 'editUscUtm') {
								result = await useStore().dispatch(ActionTypes.updateObjectInventory,{
									"accountId":this.activeAccountId,
									"productType":'unifiedSecurityConsole',
									"objectType":'uscUtms',
									"queryObjectType": 'ccutmProperties',
									"objectId":this.objectId,
									"object":object
								})
							}
						break;
						default:
							let newProductType = config.canUseNewObjectType(this.objectType) ? products?.[this.productType as keyof typeof products] : undefined
							let newObjectType = newProductType?.[this.objectType as keyof typeof newProductType]

							if (newProductType!= undefined && newObjectType != undefined && config.canUseNewObjectType(this.objectType)) {
								//@ts-ignore
								result = await newObjectType.queries.addOrUpdateObjectFromApi(this.activeAccountId, this.objectId, object, (object.customerId || thisComponent.zeroTouchCustomerId || undefined))
							}
							else {
								result = await useStore().dispatch(ActionTypes.addOrUpdateObject, {
									"accountId": this.activeAccountId || "",
									"objectType": objectType,
									"object": object,
									"objectId": this.objectId,
									"customerId": object.customerId || thisComponent.zeroTouchCustomerId || undefined
								})
							}
						break;
					}

					if (jsonHelpers.getObjectProperty(result, "code") == 400 || jsonHelpers.getObjectProperty(result, "status") == 400) {
						throw result.data
					}
					else if (jsonHelpers.getObjectProperty(result,"errors") != undefined && (result.errors.hasOwnProperty('valid') ? result.errors.valid === false : (result.errors || []).length > 0)) {
						throw result.data
					}
					else {
						nextTick(async function() {
							if (thisComponent.activePage == "utms" && licenseHelpers.hasLicense(thisComponent.$root.activeAccountId,'laas','valid')) {
								let reports : any = await queries.unifiedReporting.getObjectInfos(thisComponent.activeAccountId,'reports')
								if (reports.reports.length) {
									if (reports.reports[0].recipients.length == 0) {
										dialogs.misc.confirmDialog(
											thisComponent.activeAccountId,
											T('There are no recipients defined for your reports'),
											T("Update your report-settings in order to receive reports via e-mail"),
											() => {
												router.navigate('edit-tenant-' + thisComponent.activeTenantDomain + '-report-' + reports.reports[0].reportId)
											},
											undefined,
											T('Edit now'),
											"fal fa-edit"
										)
									}
								}
							}
							thisComponent.redirectToDashboard()
						})
					}
				}
			}
			catch(err : any) {
				thisComponent.catchErrors(err)
				this.setActiveTabWithErrors(this.form)
				thisComponent.loading = false
			}
		},
		catchErrors:function (this:any,err:any) {
			let thisComponent : any = this
			if (err.data) {
				err = err.data
			}
			if (this.activePage == "androidProfiles" && err.valid == false) {
				if (err.errors) {
					err.responseJSON = {
						errors:err.errors
					}
				}
			}
			else if (err.responseText) {
				try {
					let responseJson: any = JSON.parse(err.responseText)
					if (responseJson) {
						err.responseJSON = responseJson
					}

					if (this.activePage == "androidProfiles") {
						if (err.responseJSON.data?.error) {
							err.responseJSON = err.responseJSON.data.error
						}
					}

				}
				catch (e: any) {
					// skip error
				}
			}
			if (jsonHelpers.getObjectProperty(err, "responseJSON.errors")) {
				thisComponent.setErrors(this.form, err.responseJSON.errors)
				$('main').scrollTop(0)
			}
			else if (jsonHelpers.getObjectProperty(err, "responseJSON.data.result")) {
				thisComponent.setErrors(this.form, err.responseJSON.data.result)
				$('main').scrollTop(0)
			}
			else if (jsonHelpers.getObjectProperty(err, "error.message")) {
				thisComponent.setErrors(this.form, err.error.message)
				$('main').scrollTop(0)
			}
			else if (err.message && ! err.data) {
				if (this.activePage == "androidProfiles" && err.message == 'Unauthorized') {
					err.message = "Missing name"
				}
				thisComponent.setErrors(this.form, err.message)
				$('main').scrollTop(0)
			}
			else if (Array.isArray(err.errors) && typeof err.valid == "boolean") {
				thisComponent.setErrors(this.form, err)
				$('main').scrollTop(0)
			}
			else if (err.errors) {
				thisComponent.setErrors(this.form, err.errors)
				$('main').scrollTop(0)
			}
			else if (err.data?.errors) {
				if(Array.isArray(err.data?.errors) && err.data?.errors.length > 0 && err.data?.errors[0].message != undefined) {
					thisComponent.setErrors(this.form, err.data)
				}
				else {
					thisComponent.setErrors(this.form, err.data.errors)
				}
				$('main').scrollTop(0)
			}
			else if (err.responseText) {
				let errorText: string = err.responseText

				switch (errorText) {
					case "CONFIG_URL_REQUIRED":
						errorText = "Config URL is missing";
						break;
					case "CONFIG_NAME_REQUIRED":
						errorText = "Config name is missing";
						break;
					case "FLAGS_INVALID":
						errorText = 'Flags are invalid. Flag "is mdm removable" can be set to false only if "supervised" flag is set to true.';
						break;
					case "CONFIG_URL_INVALID":
						errorText = 'The provided config URL is invalid'
						break;
					case "CONFIG_NAME_INVALID":
						errorText = 'The provided config name is invalid'
						break;
					case "DEPARTMENT_INVALID":
						errorText = 'The provided department is invalid'
						break;
					case "SUPPORT_PHONE_INVALID":
						errorText = 'The provided support phone is invalid'
						break;
					case "SUPPORT_EMAIL_INVALID":
						errorText = 'The provided support email is invalid'
						break;
					case "MAGIC_INVALID":
						errorText = 'The provided magic is invalid'
						break;
					case "LOCALE_INVALID":
						errorText = 'The provided locale is invalid'
						break;

				}

				thisComponent.setErrors(this.form, errorText)
				$('main').scrollTop(0)
			}
			else if (typeof err == "string") {

				let errorText: string = err

				switch (errorText) {
					case "CONFIG_URL_REQUIRED":
						errorText = "Config URL is missing";
						break;
					case "CONFIG_NAME_REQUIRED":
						errorText = "Config name is missing";
						break;
					case "FLAGS_INVALID":
						errorText = 'Flags are invalid. Flag "is mdm removable" can be set to false only if "supervised" flag is set to true.';
						break;
					case "CONFIG_URL_INVALID":
						errorText = 'The provided config URL is invalid'
						break;
					case "CONFIG_NAME_INVALID":
						errorText = 'The provided config name is invalid'
						break;
					case "DEPARTMENT_INVALID":
						errorText = 'The provided department is invalid'
						break;
					case "SUPPORT_PHONE_INVALID":
						errorText = 'The provided support phone is invalid'
						break;
					case "SUPPORT_EMAIL_INVALID":
						errorText = 'The provided support email is invalid'
						break;
					case "MAGIC_INVALID":
						errorText = 'The provided magic is invalid'
						break;
					case "LOCALE_INVALID":
						errorText = 'The provided locale is invalid'
						break;
				}
				
				$('main').scrollTop(0)
				thisComponent.setErrors(this.form, errorText)
				
			}
			// #37980
			else if (this.activePage == "zeroTouchConfigurations" && err.code == 400 && typeof err.data?.error?.message == "string") {
				this.setZeroTouchConfigurationError(err.data.error.message)
			}
			thisComponent.loading = false
		},
		setZeroTouchConfigurationError: function (this: any, errorMessage: string) {
			let fieldName: string | undefined;
			let errorToFieldName: { [erorrMessage: string]: string } = {
				"Phone number can only contain numbers, spaces, hyphen, and parentheses.": "contactPhone",
				"Company name must be set.": "companyName",
				"Contact E-mail is not valid.": "contactEmail"
			}
			fieldName = errorToFieldName[errorMessage]
			
			if (typeof fieldName == "string" && fieldName && Array.isArray(jsonFormHelpers.getFieldById(this.form, fieldName)?.errors)) {
				jsonFormHelpers.getFieldById(this.form, fieldName).errors.push(T(errorMessage))
				$('main').scrollTop(0)
			}
		},
		setActiveTabWithErrors: function(this : any, currentField : any, currentTabsField : any = undefined, currentTabField : any = undefined) {
			
			if(currentField) {
				if (currentField.type == 'tabs') {
					currentTabsField = currentField
				}
				if (currentField.type == 'tab') {
					currentTabField = currentField
				}
				if(currentTabsField && currentTabField && (currentField.errors || []).length > 0) {
					console.log(currentTabsField,currentTabField.id)
					currentTabsField.activeTab = currentTabField.id
					console.log(currentTabsField,currentTabField.id)
				}
				// IF HAS FIELDS
				for (let i : number = 0; (currentField.fields || []).length > i; i++) {
					this.setActiveTabWithErrors(currentField.fields[i], currentTabsField, currentTabField)
				}
			}
		},
		cancel: function(this:any) {
			this.redirectToDashboard()
		},
		redirectToDashboard: function(this:any) {
			switch(this.activePage) {
				case 'msInventory':
					router.navigate('show-tenant-'+this.activeTenantDomain+'-inventory-dashboard')
				break;
				case 'uscInventory':
					router.navigate('show-tenant-'+this.activeTenantDomain+'-inventory-dashboard')
				break;
				case 'vppusers':
					router.navigate('show-tenant-'+this.activeTenantDomain+'-users-dashboard')
				break;
				case 'depProfiles':
					router.navigate('show-tenant-'+this.activeTenantDomain+'-dep-profiles-dashboard')
				break;
				case 'iosProfiles':
					router.navigate('show-tenant-'+this.activeTenantDomain+'-ios-profiles-dashboard')
				break;
				case 'androidProfiles':
					router.navigate('show-tenant-'+this.activeTenantDomain+'-android-profiles-dashboard')
				break;
				case 'zeroTouchConfigurations':
					router.navigate('show-tenant-'+this.activeTenantDomain+'-zerotouchconfigurations-dashboard')
				break;
				case 'uscProfiles':
					router.navigate('show-tenant-'+this.activeTenantDomain+'-usc-profiles-dashboard')
				break;
				case 'apiKeys':
					router.navigate('show-tenant-'+this.activeTenantDomain+'-apikeys-dashboard')
				break;
				case 'usrUtms':
					router.navigate('show-tenant-' + this.activeTenantDomain + '-usrutms-dashboard')
				break;
				case 'windowsEnrollmentTokens':
					router.navigate('show-tenant-' + this.activeTenantDomain + '-windows-enrollmenttokens-dashboard')
				break;
				default:
					router.navigate('show-tenant-'+this.activeTenantDomain+'-'+this.activePage+'-dashboard')
				break;
			}
		},
		clearErrors: function(this:any,formObj:JsonFormShemaObject) {
			if (formObj.errors) {
				formObj.errors = []
			}
			if (formObj.fields && formObj.fields.length) {
				for (let i : number = 0; formObj.fields.length > i; i++) {
					let thisField = formObj.fields[i]
					if (thisField.errors) {
						thisField.errors = []
					}
					if (thisField.fields) {
						this.clearErrors(thisField)
					}
				}
			}
		},
		setErrors: function (this: any, formObj: JsonFormShemaObject,errorArray : any, errorObject : any = null, objPath : string = "") {
			let platform : any = jsonFormHelpers.getFieldById(this.form,'profilePlatform')?.value || ""
			console.log(errorArray)
			if (this.activePage == 'zeroTouchConfigurations') {
				if (typeof errorArray == "object") {
					for (let i: number = 0; errorArray.length > i; i++) {
						let error = errorArray[i]
						formObj.errors.push(this.formatError(error.message))
					}
				}
				else {
					formObj.errors.push(this.formatError(errorArray))
				}
			}
			else if (this.activePage == 'androidProfiles') {
				if (typeof errorArray == "object") {
					for (let i: number = 0; errorArray.length > i; i++) {
						let error = errorArray[i]
						formObj.errors.push(this.formatError(error.message))
					}
				}
				else {
					formObj.errors.push(this.formatError(errorArray))
				}
			}
			else {
				if (typeof errorArray == "string") {
					formObj.errors.push(this.formatError(errorArray))
				}
				else {

					if (errorObject == null) {
						errorObject = {}
						if (errorArray?.errors?.length) {
							for (let i: number = 0; errorArray.errors.length > i; i++) {
								let error = errorArray.errors[i]

								if (error.schemaPath.indexOf("emptyString/const") != -1) {
									continue;
								}

								if (error.subErrors != null) {
									error.sub = {
										"errors": error.subErrors
									}
									this.setErrors(formObj, error.sub)
								}
								if (error.instancePath == "/profile/globalVpnSettings/nameserver/0") {
									error.instancePath = "/profile/globalVpnSettings/primaryVpnNameserver"
								}
								if (error.instancePath == "/profile/globalVpnSettings/nameserver/1") {
									error.instancePath = "/profile/globalVpnSettings/secondaryVpnNameserver"
								}
								if (error.instancePath == "/profile/nameserver/ips/0") {
									error.instancePath = "/profile/nameserver/primaryNameserver"
								}
								if (error.instancePath == "/profile/nameserver/ips/1") {
									error.instancePath = "/profile/nameserver/secondaryNameserver"
								}
								let dataPathArray: any = error.dataPath ? JSON.parse(JSON.stringify(error.dataPath)).split('/') : error.instancePath ? JSON.parse(JSON.stringify(error.instancePath)).split('/') : []

								console.log(dataPathArray)

								let pattern = new RegExp('^[0-9]*$')
								if (dataPathArray.length && pattern.test(dataPathArray[dataPathArray.length - 1])) {
									error.dataPath = error.dataPath ? error.dataPath.replace('/' + dataPathArray[dataPathArray.length - 1], '') : error.instancePath ? error.instancePath.replace('/' + dataPathArray[dataPathArray.length - 1], '') : ""
								}
								let errorPath: string = error.dataPath ? error.dataPath : error.instancePath ? error.instancePath : ""
								if(error.params?.missingProperty != undefined) {
									errorPath += "/"+error.params?.missingProperty
								}
								let errorText: string = error.message
								if(!errorObject[errorPath]) {
									errorObject[errorPath] = []
								}
								errorObject[errorPath].push(errorText)
							}

						}
						else if (errorArray?.missing?.length) {
							for (let i: number = 0; errorArray.missing.length > i; i++) {
								let error = errorArray.missing[i]
								if (typeof error == 'string' && error.includes("User") && error.includes("already exists")) {
									let field = jsonFormHelpers.getFieldById(formObj, "username")
									field?.errors?.push(error)
								}
							}
						}
					}
					if (formObj.fields && formObj.fields.length) {
						formObj.fields.forEach((field: JsonFormShemaObject) => {
							let thisPath = objPath

							if (field.property) {
								thisPath = objPath + '/' + field.property
								if (field.type == 'loop' && field.fields?.length) {
									let errorPath = Object.entries(errorObject)[0]?.[0]

									if (thisPath.length < errorPath.length) {
										let fieldIndex = Number(errorPath.replace(thisPath + '/', '')[0])
										if (field.fields[fieldIndex]) {
											field.fields[fieldIndex].minified = false
										}
										this.setErrors(field.fields[fieldIndex], errorArray, errorObject, thisPath + '/' + fieldIndex)
									}
								}

								if (errorObject[thisPath] != undefined) {
									if (field.errors != undefined) {
										if(errorObject[thisPath]?.length) {

											

											errorObject[thisPath].forEach((message:string) => {
												field.errors.push(this.formatError(message, field.id))
											});
										}
									} else {
										console.error(field.id + ' has no error property')
									}
								}
							}
							if (field.fields) {
								this.setErrors(field, errorArray, errorObject, thisPath)
							}
						});
					}
				}
			}
		},
		formatError: function (this: any, error: unknown, fieldId: string) {
			let formattedError = error;
			console.log(error)
			if(typeof formattedError == "string") {
				
				// Execptions for users #33240
				if (this.activePage == "users" || this.activePage == "roles") {
					const userErrorMap: {
						[key: string]: string | undefined
					} = {
						'must match pattern \"^[\\S]{3,45}$\"': "The Username must not contain any whitespace characters and its length has to be between 3 and 45 characters.",
						'must NOT have more than 45 characters': 'Must NOT have more than 45 characters',
						'must NOT have fewer than 3 characters': 'Must NOT have fewer than 3 characters',
						'must match pattern \"^[\\S]{5,128}$\"': 'The Password must not contain any whitespace characters and its length has to be between 5 and 128 characters.',
						'must NOT have more than 128 characters': 'Must NOT have more than 128 characters',
						'must NOT have fewer than 5 characters': 'Must NOT have fewer than 5 characters',
						"must NOT have more than 30 characters": "Must NOT have more than 30 characters",
						"must NOT have more than 32 characters": "Must NOT have more than 32 characters",
						"must NOT have fewer than 2 characters": "Must NOT have fewer than 2 characters",
						"must NOT have more than 100 characters": "Must NOT have more than 100 characters",
						'must match pattern \"^[a-zA-Z0-9_-]{3,30}$\"': "The rolename may only contain letters, numbers, hyphens and underscores",
						'must match pattern \"^[^,~!#$;%^*+={}\\\\|': 'The username must not contain any special characters, except for "@", "." or ",". Additionally the username must be between 3 and 100 characters long.'
					}
					formattedError = userErrorMap[formattedError] ? T(userErrorMap[formattedError]) : formattedError
				}

				else if (this.activePage == "androidProfiles") {
					const duplicateAppPattern: RegExp = /Duplicate application policies for package name (\S+) and target profile type (\S+)/;
					if (duplicateAppPattern.test(formattedError)) {
						const matchResult: Array<string> | null = duplicateAppPattern.exec(formattedError);
						const packageName: string | undefined = matchResult ? matchResult[1] : undefined;
						const profileType: string | undefined = matchResult ? matchResult[2] : undefined
						if (packageName && profileType) {
							formattedError = T('You can only select the %s application once for your %y.').replace("%s", packageName).replace("%y", profileType)
						}
						else {
							formattedError = T('You may only select applications once per profile.')
						}
					}
				}
				else if (formattedError.indexOf("must have required property") != -1) {
					formattedError = T("This field is required")
				}
				else if (formattedError.indexOf("must match exactly one schema in oneOf") != -1) {
					formattedError = T("One requirement ist not met")
				}
				else if(formattedError.indexOf('must match format "extendedname"') != -1) {
					formattedError = T("The name may comprise alphanumeric characters, spaces, periods, hyphens, underscores, parentheses, German umlauts (ä, Ä, ö, Ö, ü, Ü, ß) and must be between 3 and 120 characters long.")
				}
				else if (formattedError.indexOf("must match format") != -1) {
					let format = formattedError.replace("must match format ","")
					formattedError = sprintf(T("Must match format %s"),format)
				}
			}
			return formattedError
		},
		getSelectOptions: async function (this: any, form: JsonFormShemaObject) {
			let thisComponent : any = this
			let neededSelectOptions: Array<JsonFormSelectOptionFromStore> = (<Array<JsonFormSelectOptionFromStore>>this.findNeededSelectOptions(form)).filter(function(objectType) {
				let result : boolean = true
				if (thisComponent.activePage == "iosProfiles" && ['emmDevices', 'emmDevicesLocked', 'zeroTouchDevicesLocked', 'zeroTouchDevices'].indexOf(objectType) != -1) {
					result = false
				}
				if(thisComponent.activePage == "androidProfiles" && ( objectType == "devices" || objectType == "iosDevices" || objectType == "devicesAndVpns" )) {
					result = false
				}
				return result
			})
			let devicesRaw : any = []
			let enterpriseDevicesRaw : any = []
			let zeroTouchDevicesRaw : any = []
			let vpnsRaw : any = []
			let androidDevices : any = []
			let devices : any = []
			let vpns : any = []
			let iosDevices : any = []
			let iosDepDevicesBySerial : any = []
			let iosDepDevicesBySerialLocked : any = []
			let emmDevices : any = []
			let emmDevicesLocked : any = []
			let accountTypes : any = []
			let types : any = {}
			let licenses : any = []
			let msLicenses : any = []
			let usersRaw : any = []
			let users : any = []
			let emails : any = []
			let rolesRaw : any = []
			let roles : any = []
			let certificatesRaw : any = []
			let certificates : any = []
			let certificates2 : any = []
			let certificatesCaWithoutPrivateKey : any = []
			let tags : any = []
			let stringifiedEnrollmentQR : any[] = []
			let enrollmentTokenIds : any[] = []
			let enrollmentTokenIdsWithPin: any[] = []
			let zeroTouchDevices : any[] = []
			let zeroTouchDevicesLocked : any[] = []
			let zeroTouchCustomers : any[] = []
			let homescreenlayouts : any[] = []
			let uscUtmTimezones: any[] = []
			let geoips: any[] = []
			let uscProfileIps: any[] = []
			let optionalUscProfileIps: any[] = []

			let options : any = {
				iosDeviceMacAdresses:[],
				appleTvMacAdresses:[],
				usersWithEnterpriseDevice:[],
				vppUsers:[],
				apps:[],
				webclips:[],
				iosAppsBundleId:[],
				iosAppsPlusNativeAppsBundleId:[],
				iosNativeAppsBundleId:[],
				accountTypes:[],
				devices:[],
				androidDevices:[],
				iosDevices:[],
				iosDepDevicesBySerial:[],
				iosDepDevicesBySerialLocked:[],
				emmDevices:[],
				emmDevicesLocked:[],
				zeroTouchDevicesLocked:[],
				zeroTouchDevices:[],
				devicesAndVpns:[],
				vpns:[],
				users:[],
				usersPlusDeviceUser:[],
				emails:[],
				emailsPlusDeviceEmail:[],
				vppUsersOrCreate:[],
				roles:[],
				certificatesPlusNone:[],
				certificates:[],
				certificates2:[],
				certificatesCaWithoutPrivateKey:[],
				licenses:[],
				msLicenses:[],
				tags:[],
				contentFilterCategories:[],
				contentFilterCategoriesWithoutWhite:[],
				portFilterGroups:[],
				zeroTouchCustomers:[],
				stringifiedEnrollmentQR:[],
				enrollmentTokenIds:[],
				enrollmentTokenIdsWithPin: [],
				homescreenlayouts:[],
				uscUtms:[],
				tenants:[],
				childTenants:[],
				childTenantsWithUtms:[],
				tvosAppsBundleId:[],
				tvosNativeAppsBundleId:[],
				tvosAppsPlusNativeAppsBundleId:[],
				uscUtmTimezones: [],
				geoips: [],
				uscProfileIps: [],
				optionalUscProfileIps: []
			}
			options.tenants = Object.keys(useStore().state.session.accounts).map((accountId) => {
				return {
					"id": accountId + ".sms",
					"text": useStore().state.session.accounts[accountId].unifiedSecurity.account.accountname + " (" + accountId + ".sms)"
				}
			})
			if ([null, undefined].indexOf(useStore().state.session.accounts[thisComponent.activeAccountId].unifiedSecurity.account.parent_accountid) != -1) {
				// IS RESELLER
				let accountIdsWithoutSelf = Object.keys(useStore().state.session.accounts).filter((accountId) => {
					return accountId != thisComponent.activeAccountId
				})
				options.childTenants = accountIdsWithoutSelf.map((accountId) => {
					return {
						"id":accountId+".sms",
						"text": useStore().state.session.accounts[accountId].unifiedSecurity.account.accountname + " (" + accountId + ".sms)"
					}
				})
				options.childTenantsWithUtms = accountIdsWithoutSelf.filter((accountId) => {
					return licenseHelpers.hasLicense(accountId, "unifiedSecurityConsole")
				}).map((accountId) => {
					return {
						"id":accountId+".sms",
						"text": useStore().state.session.accounts[accountId].unifiedSecurity.account.accountname + " (" + accountId + ".sms)"
					}
				})
			}

			function selectTypesNeeded(types: JsonFormSelectOptionFromStore[]) : boolean {
				let isNeeded : boolean = false
				for (let i : number = 0; types.length > i; i++) {
					if (neededSelectOptions.indexOf(types[i]) != -1) {
						isNeeded = true
						break;
					}
				}
				return isNeeded
			}

			if (selectTypesNeeded(['accountTypes'])) {
				options.accountTypes = accountTypes
			}
			
			if (selectTypesNeeded(['apiScopes'])) {
				let scopes = await products.unifiedSecurity.apiKeys.getApiKeyScopes(thisComponent.activeAccountId)
				const scopeToDisplayname = {
					"metrics":T("Metrics"),
					"product:ms:role:admin":T("Mobile Security Administration"),
				}
				options.apiScopes = scopes?.map((scope: keyof typeof scopeToDisplayname ) => {
					return {
						id: scope,
						text: scopeToDisplayname[scope]
					}
				})
			}

			

			if (selectTypesNeeded(['androidDevices', 'iosDevices', 'devices', 'devicesAndVpns', 'iosDepDevicesBySerial', 'iosDepDevicesBySerialLocked', 'emmDevicesLocked', 'zeroTouchDevicesLocked', 'zeroTouchDevices', 'iosDeviceMacAdresses', 'appleTvMacAdresses'])) {
				
				// Request devices if needed

				let thisObjectTypes : string[] = []
				if(config.canUseNewObjectType("iosDevices")) {
					await products.mobileSecurity.iosDevices.queries.getObjectsFromApi(this.activeAccountId, undefined, [
						{ "property": "props[]", "value": "licenseUUID" },
						{ "property": "props[]", "value": "props" },
						{ "property": "props[]", "value": "profile" },
						{ "property": "props[]", "value": "info" },
						{ "property": "props[]", "value": "deviceId" },
						{ "property": "props[]", "value": "signedIn" },
						{ "property": "props[]", "value": "alias" },
						{ "property": "props[]", "value": "deviceType" },
						{ "property": "props[]", "value": "configured" },
						{ "property": "props[]", "value": "serialNumber" },
						{ "property": "props[]", "value": "" },
						{ "property": "props[]", "value": "" },
					])
				}
				else {
					thisObjectTypes.push('devices?props[]=licenseUUID&props[]=props&props[]=serialNumber&props[]=profile&props[]=info&props[]=deviceId&props[]=signedIn&props[]=alias&props[]=deviceType&props[]=configured')
				}
				

				if (tenantHelpers.hasFunctionality(this.activeAccountId,'dep') && selectTypesNeeded(['devices','iosDevices','iosDepDevicesBySerial','iosDepDevicesBySerialLocked'])) {
					// Request dep devices if tenant has functionality enabled
					if (!config.canUseNewObjectType("iosDevices")) {
						thisObjectTypes.push('depDevices')
					}
				}
				
				if (selectTypesNeeded(['emmDevices', 'emmDevicesLocked', 'zeroTouchDevicesLocked', 'zeroTouchDevices']) && config.canUseNewObjectType('androidDevices')) {
					const getProperties = [{ "property": "props[]", "value": "appliedPolicyName" }, { "property": "props[]", "value": "appliedState" }, { "property": "props[]", "value": "enrollmentTime" }, { "property": "props[]", "value": "props" }, { "property": "props[]", "value": "hardwareInfo" }, { "property": "props[]", "value": "softwareInfo" }, { "property": "props[]", "value": "networkInfo" }, { "property": "props[]", "value": "lastStatusReportTime" }, { "property": "props[]", "value": "managementMode" }, { "property": "props[]", "value": "name" }, { "property": "props[]", "value": "ownership" }, { "property": "props[]", "value": "policyName" }, { "property": "props[]", "value": "previousDeviceNames" }, { "property": "props[]", "value": "state" }, { "property": "props[]", "value": "alias" }, { "property": "props[]", "value": "deviceId" }, { "property": "props[]", "value": "username" }, { "property": "props[]", "value": "consent" }, { "property": "props[]", "value": "licenseUUID" }, { "property": "props[]", "value": "signedIn" }, { "property": "fields", "value": "*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)" }]
					await products.mobileSecurity.androidDevices.queries.getObjectsFromApi(this.activeAccountId, undefined, getProperties)
				}
				else {
					if (tenantHelpers.hasFunctionality(this.activeAccountId, 'enterprise') && selectTypesNeeded(['emmDevices', 'emmDevicesLocked', 'zeroTouchDevicesLocked', 'zeroTouchDevices'])) {
						// Request enterprise devices if tenant has functionality enabled
						thisObjectTypes.push('enterpriseDevices?props[]=appliedPolicyName&props[]=appliedState&props[]=enrollmentTime&props[]=props&props[]=hardwareInfo&props[]=lastStatusReportTime&props[]=managementMode&props[]=name&props[]=ownership&props[]=policyName&props[]=previousDeviceNames&props[]=state&props[]=alias&props[]=deviceId&props[]=username&props[]=consent&props[]=licenseUUID&props[]=signedIn&fields=*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)')

					}
					if (tenantHelpers.hasFunctionality(this.activeAccountId, 'zeroTouch') && selectTypesNeeded(['zeroTouchDevicesLocked', 'zeroTouchDevices'])) {
						thisObjectTypes.push('zeroTouchDevices')
					}
				}
				
				// Do request & wait for store to be updated
				await useStore().dispatch(ActionTypes.getObjectInfos,{
					"accountId":this.activeAccountId,
					"objectTypes":thisObjectTypes
				})
			}
			if (selectTypesNeeded(['devicesAndVpns','vpns'])) {
				if (config.canUseNewObjectType('vpns')) {
					await products.mobileSecurity.vpns.queries.getObjectsFromApi(this.activeAccountId)
				}
				else {
					await useStore().dispatch(ActionTypes.getObjectInfos,{
						"accountId":this.activeAccountId,
						"objectTypes":['vpns']
					})
				}
			}
			// Get devices and vpns from store
			devicesRaw = config.canUseNewObjectType('iosDevices') ? products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObjects(this.activeAccountId) : useStore().getters.getObjectTypeStore({
				"accountId":this.activeAccountId,
				"objectType":"devices"
			})?.items || []

			enterpriseDevicesRaw = config.canUseNewObjectType('androidDevices') ? products.mobileSecurity.androidDevices.useStore?.().getObjectStoreObjects(this.activeAccountId).filter((device) => { return !Object.hasOwn(device,"claims") }) : useStore().getters.getObjectTypeStore({
				"accountId":this.activeAccountId,
				"objectType":"enterpriseDevices"
			})?.items || []
			
			zeroTouchDevicesRaw = config.canUseNewObjectType('androidDevices') ? products.mobileSecurity.androidDevices.useStore?.().getObjectStoreObjects(this.activeAccountId).filter((device) => { return device.zeroTouchDevice === true }) : useStore().getters.getObjectTypeStore({
				"accountId":this.activeAccountId,
				"objectType":"zeroTouchDevices"
			})?.items || []


			if (config.canUseNewObjectType('vpns')) {
				vpnsRaw = products.mobileSecurity.vpns.useStore?.().getObjectStoreObjects(this.activeAccountId)
			}
			else {
				vpnsRaw = useStore().getters.getObjectTypeStore({
					"accountId": this.activeAccountId,
					"objectType": "vpns"
				})?.items || []
			}

			for (let deviceIndex: number = 0; enterpriseDevicesRaw.length > deviceIndex; deviceIndex++) {
				let device = enterpriseDevicesRaw[deviceIndex]

				if(device.name.indexOf('enterprises') != -1) {
					devices.push({
						"id": device.deviceId,
						"text": deviceHelpers.getAliasedShortDeviceId(device.id, device.alias || device.hardwareInfo?.model || "", false)
					})
					androidDevices.push({
						"id": device.id,
						"text": deviceHelpers.getAliasedShortDeviceId(device.id, device.alias || device.hardwareInfo?.model || "", false)
					})
					emmDevices.push({
						"id": device.id,
						"text": deviceHelpers.getAliasedShortDeviceId(device.id, device.alias || device.hardwareInfo?.model || "", false)
					})
					emmDevicesLocked.push({
						"id": device.id,
						"text": deviceHelpers.getAliasedShortDeviceId(device.id, device.alias || device.hardwareInfo?.model || "", false),
						"locked": true
					})
				}
			}
			
			for (let deviceIndex: number = 0; zeroTouchDevicesRaw.length > deviceIndex; deviceIndex++) {
				let enterpriseDevice = zeroTouchDevicesRaw[deviceIndex]?.zeroTouchInfo != undefined ? zeroTouchDevicesRaw[deviceIndex] : undefined
				let device: ZeroTouchDevice = zeroTouchDevicesRaw[deviceIndex]
				device = (<any>device).zeroTouchInfo != undefined ? (<any>device).zeroTouchInfo : device;
				zeroTouchDevicesLocked.push({
					"id": device.deviceId,
					"deviceId": device.deviceId,
					"text": deviceHelpers.getAliasedShortDeviceId(enterpriseDevice?.deviceId || device.deviceId, enterpriseDevice?.alias || device.deviceIdentifier?.imei || device.deviceIdentifier?.serialNumber || "", false),
					"locked": true
				})
				zeroTouchDevices.push({
					"id": device.deviceId,
					"deviceId": device.deviceId,
					"text": deviceHelpers.getAliasedShortDeviceId(enterpriseDevice?.deviceId || device.deviceId, enterpriseDevice?.alias || device.deviceIdentifier?.imei || device.deviceIdentifier?.serialNumber || "", false),
				})
			}

			// Create Options
			for (let deviceIndex: number = 0; devicesRaw.length > deviceIndex; deviceIndex++) {
				let device = devicesRaw[deviceIndex]

				devices.push({
					"id": device.deviceId,
					"text": deviceHelpers.getAliasedShortDeviceId(device.deviceId, device.alias || device.info?.deviceName || "", false)
				})
				iosDevices.push({
					"id": device.deviceId,
					"text": deviceHelpers.getAliasedShortDeviceId(device.deviceId, device.alias || device.info?.deviceName || "", false)
				})
				options.iosDeviceMacAdresses.push({
					'id': device.info?.WiFiMAC || device.info?.wifiMAC ||  "",
					'text': deviceHelpers.getAliasedShortDeviceId(device.deviceId, device.alias, false)
				})
				if (device.info?.ModelName == 'AppleTV') {
					options.appleTvMacAdresses.push({
						'id': device.info.WiFiMAC || device.info.wifiMAC,
						'text': deviceHelpers.getAliasedShortDeviceId(device.deviceId, device.alias, false)
					})
				}
				if(config.canUseNewObjectType("iosDevices")) {
					if ((device.depDevice && !device.depOnly) && device.depDeviceInfo?.serial_number) {
						iosDepDevicesBySerial.push({
							"id": device.depDeviceInfo.serial_number,
							"text": (device.alias || device.info?.deviceName || "") + " (#" + device.depDeviceInfo.serial_number + ")"
						})
						iosDepDevicesBySerialLocked.push({
							"id": device.depDeviceInfo.serial_number,
							"locked": true,
							"text": (device.alias || device.info?.deviceName || "") + " (#" + device.depDeviceInfo.serial_number + ")"
						})
					}
					else if (device.serial_number) {
						iosDepDevicesBySerial.push({
							"id": device.serial_number,
							"text": device.description + " (#" + device.serial_number + ")"
						})
						iosDepDevicesBySerialLocked.push({
							"id": device.serial_number,
							"locked": true,
							"text": device.description + " (#" + device.serial_number + ")"
						})
					}
				}
				else {
					if ((device.depDevice || device.depOnly) && device.depDeviceInfo?.serial_number) {
						iosDepDevicesBySerial.push({
							"id": device.depDeviceInfo.serial_number,
							"text": (device.alias || device.info?.deviceName || "") + " (#" + device.depDeviceInfo.serial_number + ")"
						})
						iosDepDevicesBySerialLocked.push({
							"id": device.depDeviceInfo.serial_number,
							"locked": true,
							"text": (device.alias || device.info?.deviceName || "") + " (#" + device.depDeviceInfo.serial_number + ")"
						})
					}
				}

			}
			if (selectTypesNeeded(['devicesAndVpns', 'vpns'])) {
				for (let vpnIndex in vpnsRaw) {
					vpns.push({
						"id":vpnsRaw[vpnIndex].vpnId,
						"text":vpnsRaw[vpnIndex].vpnname + ' (VPN)'
					})
				}
			}

			options.devices = devices
			options.androidDevices = androidDevices
			options.iosDevices = iosDevices
			options.iosDepDevicesBySerial = iosDepDevicesBySerial
			options.iosDepDevicesBySerialLocked = iosDepDevicesBySerialLocked
			options.emmDevices = emmDevices
			options.emmDevicesLocked = emmDevicesLocked
			options.zeroTouchDevicesLocked = zeroTouchDevicesLocked
			options.zeroTouchDevices = zeroTouchDevices
			options.devicesAndVpns = devices.concat(vpns)
			options.vpns = vpns


			
			if (selectTypesNeeded(['users', 'usersPlusDeviceUser', 'emails', 'emailsPlusDeviceEmail', 'usersWithEnterpriseDevice', 'usersPlusUserVariables', 'emailsPlusUserVariables'])) {
				// Get Users
				if (config.canUseNewObjectType("users")) {
					await products.unifiedSecurity.users.queries.getObjectsFromApi(this.activeAccountId)
					usersRaw = products.unifiedSecurity.users.useStore?.()?.getObjectStoreObjects(this.activeAccountId) || []
				}
				else {
					await useStore().dispatch(ActionTypes.getObjectInfos,{
						"accountId": this.activeAccountId,
						"objectTypes": ["users?props[]=username&props[]=rspUserId&props[]=rspLogin"]
					})  
					usersRaw = useStore().getters.getObjectTypeStore({
						"accountId": this.activeAccountId,
						"objectType": "users"
					}).items || []
				}
								
				usersRaw.forEach(function(thisUser:any,userIndex:number) {
					users.push({
						"id":thisUser.username,
						"text": thisUser?.rspUserId ? thisUser.rspLogin : thisUser?.username
					})
					if (thisUser?.profile?.email && thisUser.profile.email != undefined && thisUser.profile.email != null) {
						let emailExists : boolean = false
						for (let emailIndex:number=0; emails.length > emailIndex; emailIndex++) {
							if (emails[emailIndex].id == thisUser.profile.email) {
								emailExists = true
								break;
							}
						}
						if (!emailExists) {
							emails.push({
								"id":thisUser.profile.email,
								"text":thisUser.profile.email
							})
						}
					}
					if (jsonHelpers.getObjectProperty(thisUser,"android.enterprise.devices") != undefined) {
						if (thisUser.android.enterprise.devices.length > 0) {
							options.usersWithEnterpriseDevice.push({
								"id":thisUser.username,
								"text": thisUser?.rspUserId ? thisUser.rspLogin : thisUser?.username
							})
						}
					}
				})
			}
			options.users = users || []
			options.usersPlusDeviceUser = [
				{ 'id': "", 'text':T('None')},
				{ 'id': '$username$', 'text':'$username$'},
				{'id':'$emailaddress$','text':'$emailaddress$'},
				{'id':'$variable1$','text':'$variable1$'},
				{'id':'$variable2$','text':'$variable2$'},
				{'id':'$variable3$','text':'$variable3$'}
			]
			options.usersPlusUserVariables = [
				{ 'id': "", 'text': T('None') },
				{ 'id': '$username$', 'text':'$username$'},
				{'id':'$emailaddress$','text':'$emailaddress$'},
				{'id':'$variable1$','text':'$variable1$'},
				{'id':'$variable2$','text':'$variable2$'},
				{'id':'$variable3$','text':'$variable3$'}
			]
			options.usersPlusDeviceUser = options.usersPlusDeviceUser.concat(JSON.parse(JSON.stringify(users)))
			options.usersPlusUserVariables = options.usersPlusDeviceUser.concat(JSON.parse(JSON.stringify(users)))
			options.emails = emails || []
			options.emailsPlusDeviceEmail = [
				{ 'id': "", 'text': T('None') },
				{ 'id': '$emailaddress$', 'text': '$emailaddress$' },
				{ 'id': '$variable1$', 'text': '$variable1$' },
				{ 'id': '$variable2$', 'text': '$variable2$' },
				{ 'id': '$variable3$', 'text': '$variable3$' }
			]
			options.emailsPlusUserVariables = [
				{ 'id': "", 'text': T('None') },
				{ 'id': '$emailaddress$', 'text': '$emailaddress$' },
				{ 'id': '$variable1$', 'text': '$variable1$' },
				{ 'id': '$variable2$', 'text': '$variable2$' },
				{ 'id': '$variable3$', 'text': '$variable3$' }
			]
			options.emailsPlusDeviceEmail = options.emailsPlusDeviceEmail.concat(JSON.parse(JSON.stringify(emails)))
			options.emailsPlusUserVariables = options.emailsPlusDeviceEmail.concat(JSON.parse(JSON.stringify(emails)))

			options.vppUsersOrCreate = [ {"id":"create","text":"Create a new VPP User"} ]


			if (tenantHelpers.hasFunctionality(this.activeAccountId,"vpp") && selectTypesNeeded(['vppUsers', 'vppUsersOrCreate'])) {
				// Get vppUsers
				let vppUsersRaw : any = []
				if (config.canUseNewObjectType("vppUsers")) {
					vppUsersRaw = products.mobileSecurity.vppUsers.queries.getObjectsFromApi(this.activeAccountId)
				}
				else {
					 vppUsersRaw = await objectStores.vppUsers.getObjectsFromApi(this.activeTenantDomain)
				}
				if(!(vppUsersRaw instanceof Error)) {
					vppUsersRaw = vppUsersRaw || []

					for (let i : number = 0; vppUsersRaw.length > i; i++) {
						if (vppUsersRaw[i].status == "Associated") {
							options.vppUsers.push({
								"id": vppUsersRaw[i].clientUserId || vppUsersRaw[i].clientUserIdStr,
								"text": deviceHelpers.getAliasedShortDeviceId(vppUsersRaw[i].clientUserIdStr || vppUsersRaw[i].clientUserId,vppUsersRaw[i].email || T("Undefined"),false)
							})
							options.vppUsersOrCreate.push({
								"id": vppUsersRaw[i].clientUserId || vppUsersRaw[i].clientUserIdStr,
								"text": deviceHelpers.getAliasedShortDeviceId(vppUsersRaw[i].clientUserIdStr || vppUsersRaw[i].clientUserId, vppUsersRaw[i].email || T("Undefined"), false)
							})
						}
					}
				}
			}


			if (selectTypesNeeded(['roles'])) {
				// Get Roles
				if (config.canUseNewObjectType("roles")) {
					await products.unifiedSecurity.roles.queries.getObjectsFromApi(this.activeAccountId, undefined, [
						{ "property": "props[]", "value": "rolename" }
					])

					rolesRaw = products.unifiedSecurity.roles.useStore?.()?.getObjectStoreObjects(this.activeAccountId) || []
				}
				else {
					await useStore().dispatch(ActionTypes.getObjectInfos, {
						"accountId": this.activeAccountId,
						"objectTypes": ["roles"]
					})
					rolesRaw = useStore().getters.getObjectTypeStore({
						"accountId": this.activeAccountId,
						"objectType": "roles"
					}).items || []
				}

				for (let roleIndex in rolesRaw) {
					roles.push({
						"id":rolesRaw[roleIndex].rolename,
						"text":rolesRaw[roleIndex].rolename
					})
				}
			}
			options.roles = roles

			if(selectTypesNeeded(['uscUtmTimezones'])) {
				let timeZones: undefined | any[] = getterHelpers.useStore()?.state.resources?.usc?.uscUtmTimezones
				uscUtmTimezones = Array.isArray(timeZones) ? timeZones.map((timeZone: string) => {
					return {
						"id": timeZone,
						"text": timeZone
					} as selectOption
				}) : []
			}
			options.uscUtmTimezones = uscUtmTimezones

			if(selectTypesNeeded(['geoips'])) {
				let ips: { [geoip: string]: string } | undefined =  i18n.getLanguage() == "de" ?  getterHelpers.useStore()?.state.resources?.usc?.geoipsDE : getterHelpers.useStore()?.state.resources?.usc?.geoipsEN
				geoips = Object.keys(ips || {}).map((geoip: string) => {
					return {
						"id": geoip,
						"text": ips ? ips[geoip] : geoip
					} as selectOption
				})
			}
			options.geoips = geoips

			if(selectTypesNeeded(['uscProfileIps'])) {
				await useStore().dispatch(ActionTypes.getObjectInfos,{
					"accountId":this.activeAccountId,
					"objectTypes":['uscProfiles']
				})
				let uscProfiles = (useStore().getters.getObjectTypeStore({
					"accountId": this.activeAccountId,
					"objectType": "uscProfiles"
				}) || {}).items;

				(uscProfiles || []).forEach((profile: any) => {
					const administrationIps: any[] = profile.profile?.manager?.address || []
					const nameserverIps: any[] = profile.profile?.nameserver?.ips || []
					const blockDestinationExceptionIps: any[] = profile.profile?.geoip?.blockDestinationExceptions || []
					const blockSourceExceptionIps: any[] = profile.profile?.geoip?.blockSourceExceptions || []
					const vpnNameserverIp: any[] = profile.profile?.globalVpnSettings?.nameserver ? [profile.profile.globalVpnSettings.nameserver] : []
					let allIps: any[] = (<any[]>[]).concat(administrationIps).concat(nameserverIps).concat(blockDestinationExceptionIps).concat(blockSourceExceptionIps).concat(vpnNameserverIp)
					if(allIps.length) {
						allIps = allIps.map((ip: string) => {
							return {
								"id": ip,
								"text": ip
							} as selectOption
						})
						uscProfileIps = uscProfileIps.concat(allIps)
					}
				})
				uscProfileIps = Array.from(new Set(uscProfileIps))
			}
			options.uscProfileIps = uscProfileIps

			if(selectTypesNeeded(['optionalUscProfileIps'])) {
				await useStore().dispatch(ActionTypes.getObjectInfos,{
					"accountId":this.activeAccountId,
					"objectTypes":['uscProfiles']
				})
				let uscProfiles = (useStore().getters.getObjectTypeStore({
					"accountId": this.activeAccountId,
					"objectType": "uscProfiles"
				}) || {}).items;

				(uscProfiles || []).forEach((profile: any) => {
					const administrationIps: any[] = profile.profile?.manager?.address || []
					const nameserverIps: any[] = profile.profile?.nameserver.ips || []
					const blockDestinationExceptionIps: any[] = profile.profile?.geoip?.blockDestinationExceptions || []
					const blockSourceExceptionIps: any[] = profile.profile?.geoip?.blockSourceExceptions || []
					const vpnNameserverIp: any[] = profile.profile?.globalVpnSettings?.nameserver ? [profile.profile.globalVpnSettings.nameserver] : []
					let allIps: any[] = (<any[]>[]).concat(administrationIps).concat(nameserverIps).concat(blockDestinationExceptionIps).concat(blockSourceExceptionIps).concat(vpnNameserverIp)
					if(allIps.length) {
						allIps = allIps.map((ip: string) => {
							return {
								"id": ip,
								"text": ip
							} as selectOption
						})
						optionalUscProfileIps.push({
							"id": "",
							"text": T("No IP selected")
						} as selectOption)
						optionalUscProfileIps = optionalUscProfileIps.concat(allIps)
					}
				})
				optionalUscProfileIps = Array.from(new Set(optionalUscProfileIps))
			}
			options.optionalUscProfileIps = optionalUscProfileIps

			if (selectTypesNeeded(['homescreenlayouts'])) {
				let homescreenlayoutsRaw : any | HomescreenLayout = []
				// Get homescreenlayouts
				if(config.canUseNewObjectType('homescreenlayouts')) {
					if ((products.mobileSecurity.homescreenlayouts.useStore?.().getObjectStoreObjects(this.activeAccountId).length || 0) > 0) {
						homescreenlayoutsRaw = products.mobileSecurity.homescreenlayouts.useStore?.().getObjectStoreObjects(this.activeAccountId) || []
					}
					else {
						await products.mobileSecurity.homescreenlayouts.queries.getObjectsFromApi(this.activeAccountId)
						homescreenlayoutsRaw = products.mobileSecurity.homescreenlayouts.useStore?.().getObjectStoreObjects(this.activeAccountId) || []
					}
				}
				else {
					if (this.activeAccount.mobileSecurity.homescreenlayouts.items.length > 0) {
						homescreenlayoutsRaw = this.activeAccount.mobileSecurity.homescreenlayouts.items
					}
					else {
						await useStore().dispatch(ActionTypes.getObjectInfos, {
							"accountId": this.activeAccountId,
							"objectTypes": ["homescreenlayouts"]
						})
						homescreenlayoutsRaw = useStore().getters.getObjectTypeStore({
							"accountId": this.activeAccountId,
							"objectType": "homescreenlayouts"
						})
						homescreenlayoutsRaw = homescreenlayoutsRaw.items
					}
				}
				
				for (let index in homescreenlayoutsRaw) {
					homescreenlayouts.push({
						"id":homescreenlayoutsRaw[index].layoutId,
						"text":homescreenlayoutsRaw[index].Name
					})
				}
			}
			options.homescreenlayouts = homescreenlayouts
			

			if (selectTypesNeeded(['certificates', 'certificates2','certificatesCaWithoutPrivateKey'])) {
				// Get certificates
				if (config.canUseNewObjectType("certificates")) {
					await products.mobileSecurity.certificates.queries.getObjectsFromApi(this.activeAccountId)
					certificatesRaw = products.mobileSecurity.certificates.useStore?.()?.getObjectStoreObjects(this.activeAccountId) || []
				}
				else {
					await useStore().dispatch(ActionTypes.getObjectInfos, {
						"accountId": this.activeAccountId,
						"objectTypes": ["certificates"]
					})
					certificatesRaw = useStore().getters.getObjectTypeStore({
						"accountId": this.activeAccountId,
						"objectType": "certificates"
					}).items || []
				}

				options.certificatesPlusNone = [
					{"id":"","text":T('None')}
				]
				for (let certificateIndex in certificatesRaw) {
					options.certificatesPlusNone.push({
						"id":certificatesRaw[certificateIndex].certificateId,
						"text":certificatesRaw[certificateIndex].name
					})
					certificates.push({
						"id":certificatesRaw[certificateIndex].certificateId,
						"text":certificatesRaw[certificateIndex].name
					})
					certificates2.push({
						"id":'{"certificateId":"'+certificatesRaw[certificateIndex].certificateId+'","name":"'+certificatesRaw[certificateIndex].name+'","data":"","type":"pkcs1"}',
						"text":certificatesRaw[certificateIndex].name
					})
					if (certificatesRaw[certificateIndex].ca == true && certificatesRaw[certificateIndex].hasPrivateKey == false) {
						certificatesCaWithoutPrivateKey.push({
							"id":'{"certificateId":"'+certificatesRaw[certificateIndex].certificateId+'","name":"'+certificatesRaw[certificateIndex].name+'","data":"","type":"pkcs1"}',
							"text":certificatesRaw[certificateIndex].name
						})
					}
				}
			}
			options.certificates = certificates
			options.certificates2 = certificates2
			options.certificatesCaWithoutPrivateKey = certificatesCaWithoutPrivateKey

			if (selectTypesNeeded(['msLicenses', 'licenses'])) {
				let activeAccountId = useStore().state.session.activeAccountId
				if (!activeAccountId) {
					throw "Missing accountId"
				}
				let accountLicenses = (config.canUseNewObjectType("licenses") ? products.unifiedSecurity.licenses.useStore?.().getObjectStoreObjects(this.activeAccountId) : objectStores.licenses.getObjectsFromStore(this.activeAccountId)) || []
				for (let i : number = 0; accountLicenses.length > i; i++) {
					let license : any = accountLicenses[i]
					let licenceUser = mixinHelpers.getLicenseLimit(license)
					licenses.push({
						"id": license.uuid,
						"text": license.name + " ("+license.assignedUser+"/"+licenceUser+")"
					})
					if (license.type == 'Mobile Security' && license.uuid && license.assignedUser < licenceUser && !license.expired && !license.dead) {
						msLicenses.push({
							"id": license.uuid,
							"text": license.name + " ("+license.assignedUser+"/"+licenceUser+")"
						})
					}
				}
			}
			options.licenses = licenses
			options.msLicenses = msLicenses

			if (selectTypesNeeded(['tags'])) {
				// Get Tags
				await useStore().dispatch(ActionTypes.getObjectInfos, {
					"accountId":this.activeAccountId,
					"objectTypes":["tags"]
				})
				tags = useStore().getters.getObjectTypeStore({
					"accountId":this.activeAccountId,
					"objectType":"tags"
				})?.items || []

				if(tags.length) {
					tags = tags.sort((tagA: selectOption, tagB: selectOption) => {
						return tagA.text.toLowerCase() > tagB.text.toLowerCase() ? 1 : -1
					})
				}
			}



			options.tags = tags

			if (selectTypesNeeded(['contentFilterCategories', 'contentFilterCategoriesWithoutWhite'])) {
				// Get Contentfilter categories
				let contentFilterCategoriesRaw : any = this.getCategoriesObject()
				let contentFilterCategories : any = []
				let contentFilterCategoriesWithoutWhite : any = []
				contentFilterCategoriesRaw.forEach(function(contentFilterCategory:any) {
					contentFilterCategories.push({
						"id":contentFilterCategory.ip,
						"text":contentFilterCategory.name
					})
					if(contentFilterCategory.name != 'White') {
						contentFilterCategoriesWithoutWhite.push({
							"id":contentFilterCategory.ip,
							"text":contentFilterCategory.name
						})
					}
				})
				options.contentFilterCategories = contentFilterCategories
				options.contentFilterCategoriesWithoutWhite = contentFilterCategoriesWithoutWhite
			}

			if (selectTypesNeeded(['portFilterGroups'])) {
				// Get portfilter groups
				options.portFilterGroups = Object.keys(useStore().state.resources.PortfilterGroups)
					.filter((name: string) => name != "pf_group_1" && name != "pf_group_2" && name != "pf_group_3")
					.map(function (groupName) {
						return {
							"id": groupName,
							"text": useStore().state.resources.PortfilterGroups[groupName].name
						}
					})
			}
			if (selectTypesNeeded(['zeroTouchCustomers'])) {
				let localZeroTouchCustomers = config.canUseNewObjectType('zeroTouchCustomers') ? products.mobileSecurity.zeroTouchCustomers.useStore?.().getObjectStoreObjects(thisComponent.activeAccountId) : useStore()?.getters.getObjects({
					"accountId": thisComponent.activeAccountId,
					"objectType": "zeroTouchCustomers",
					"productType": "mobileSecurity"
				})
				
				if (config.canUseNewObjectType('zeroTouchCustomers')) {
					localZeroTouchCustomers?.forEach((customerWrapper:any) => {
						customerWrapper.customers.forEach((customer: any) => {
							if (customer.companyId) {
								zeroTouchCustomers.push({
									"id": customer.companyId,
									"text": customer.companyName
								})
							}
						})
					})
				}
				else {
					localZeroTouchCustomers?.forEach((customer: any) => {
						if (customer.companyId) {
							zeroTouchCustomers.push({
								"id": customer.companyId,
								"text": customer.companyName
							})
						}
					})
				}

				
			}
			options.zeroTouchCustomers = zeroTouchCustomers

			if (selectTypesNeeded(['stringifiedEnrollmentQR','enrollmentTokenIds'])) {
				let enrollmentTokens : any = await queries.mobileSecurity.getEnterpriseEnrollmentTokens(thisComponent.activeAccountId)
				enrollmentTokens = enrollmentTokens.enrollmentTokens || []
				enrollmentTokens?.forEach((enrollmentToken: any) => {
					stringifiedEnrollmentQR.push({
						"id": enrollmentToken.qrCode,
						"text": T('Profile') + ': ' + enrollmentToken.policyName.split('/')[3] + ' | Token: ' + deviceHelpers.getShortDeviceId(enrollmentToken.name.split('/')[3]) + (enrollmentToken.signinDetail ? ' | Code: ' + enrollmentToken.signinDetail.code : ''),
						"html": T('Profile') + ': ' + enrollmentToken.policyName.split('/')[3] + '&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Token: ' + deviceHelpers.getShortDeviceId(enrollmentToken.name.split('/')[3]) + (enrollmentToken.signinDetail ? '&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Code: ' + enrollmentToken.signinDetail.code : '')
					})
					enrollmentTokenIds.push({
						"id": enrollmentToken.name.split('/')[3],
						"text": T('Profile') + ': ' + enrollmentToken.policyName.split('/')[3] + ' | Token: ' + deviceHelpers.getShortDeviceId(enrollmentToken.name.split('/')[3]) + (enrollmentToken.signinDetail ? ' | Code: ' + enrollmentToken.signinDetail.code : ''),
						"html": T('Profile') + ': ' + enrollmentToken.policyName.split('/')[3] + '&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Token: ' + deviceHelpers.getShortDeviceId(enrollmentToken.name.split('/')[3]) + (enrollmentToken.signinDetail ? '&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Code: ' + enrollmentToken.signinDetail.code : '')
					})
				})
				options.stringifiedEnrollmentQR = stringifiedEnrollmentQR
				options.enrollmentTokenIds = enrollmentTokenIds
			}
			if (selectTypesNeeded(["enrollmentTokenIdsWithPin"])) {
				let enrollmentTokens: any = await queries.mobileSecurity.getEnterpriseEnrollmentTokens(thisComponent.activeAccountId)
				enrollmentTokens = enrollmentTokens.enrollmentTokens || []
				enrollmentTokens = enrollmentTokens.filter((enrollmentToken: any) => {
					if(enrollmentToken?.signinDetail?.code) {
						return enrollmentToken
					}
				})
				enrollmentTokens?.forEach((enrollmentToken: any) => {
					enrollmentTokenIdsWithPin.push({
						"id": enrollmentToken.name.split('/')[3],
						"text": T('Profile') + ': ' + enrollmentToken.policyName.split('/')[3] + ' | Token: ' + deviceHelpers.getShortDeviceId(enrollmentToken.name.split('/')[3]) + (enrollmentToken.signinDetail ? ' | Code: ' + enrollmentToken.signinDetail.code : ''),
						"html": T('Profile') + ': ' + enrollmentToken.policyName.split('/')[3] + '&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Token: ' + deviceHelpers.getShortDeviceId(enrollmentToken.name.split('/')[3]) + (enrollmentToken.signinDetail ? '&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Code: ' + enrollmentToken.signinDetail.code : '')
					})
				})
				options.enrollmentTokenIdsWithPin = enrollmentTokenIdsWithPin
			}

			if (selectTypesNeeded(['apps', 'webclips', 'iosAppsBundleId', 'iosAppsPlusNativeAppsBundleId', 'iosNativeAppsBundleId', 'tvosNativeAppsBundleId', 'tvosAppsPlusNativeAppsBundleId', 'tvosAppsBundleId',])) {
				let appsRaw : any
				// Get Roles
				if (config.canUseNewObjectType("iosApps")) {
					await products.mobileSecurity.iosApps.queries.getObjectsFromApi(this.activeAccountId, undefined, [
						{ "property": "props[]", "value": "appId" },
						{ "property": "props[]", "value": "platform" },
						{ "property": "props[]", "value": "name" },
						{ "property": "props[]", "value": "URL" },
						{ "property": "props[]", "value": "label" },
						{ "property": "props[]", "value": "Label" },
						{ "property": "props[]", "value": "Icon" }
					])
				}
				else {
					await useStore().dispatch(ActionTypes.getObjectInfos, {
						"accountId": this.activeAccountId,
						"objectTypes": ["apps?props[]=appId&props[]=platform&props[]=name&props[]=URL&props[]=label&props[]=Icon"],
						"lookupApps": true
					})
				}
				
				appsRaw = config.canUseNewObjectType("iosApps") ? products.mobileSecurity.iosApps.useStore?.().getObjectStoreObjects(this.activeAccountId) : useStore().getters.getObjectTypeStore({
					"accountId":this.activeAccountId,
					"objectType":"apps"
				})?.items || []
				
				useStore().state.resources.apple.nativeAppInfos.apps.forEach((application:any) => {
					options.iosAppsPlusNativeAppsBundleId.push({
						"id":application.bundleId,
						"text":T(application.trackName) + " ("+application.bundleId+")"
					})
					options.iosNativeAppsBundleId.push({
						"id":application.bundleId,
						"text":T(application.trackName) + " ("+application.bundleId+")"
					})
				})

				useStore().state.resources.apple.tvOsNativeApps.apps.forEach((application:any) => {
					options.tvosAppsPlusNativeAppsBundleId.push({
						"id":application.bundleId,
						"text":T(application.trackName) + " ("+application.bundleId+")"
					})
					options.tvosNativeAppsBundleId.push({
						"id":application.bundleId,
						"text":T(application.trackName) + " ("+application.bundleId+")"
					})
				})


				appsRaw.forEach((application:any) => {
					if(application.platform != "WEBCLIP") {
						options.apps.push({
							"id":application.appId,
							"text":application.name
						})
					}
					if(application.platform == "IOS" && application.uninstallPackageName) {
						options.iosAppsBundleId.push({
							"id":application.uninstallPackageName,
							"text":application.name + " ("+application.uninstallPackageName+")"
						})
						options.iosAppsPlusNativeAppsBundleId.push({
							"id":application.uninstallPackageName,
							"text":application.name + " ("+application.uninstallPackageName+")"
						})
					}
					if(application.platform == "TVOS" && application.uninstallPackageName) {
						options.tvosAppsBundleId.push({
							"id":application.uninstallPackageName,
							"text":application.name + " ("+application.uninstallPackageName+")"
						})
						options.tvosAppsPlusNativeAppsBundleId.push({
							"id":application.uninstallPackageName,
							"text":application.name + " ("+application.uninstallPackageName+")"
						})
					}
					if(application.platform == "WEBCLIP") {
						options.webclips.push({
							"id":application.appId,
							"text": application.name + " [Label: "+application.Label+"] ("+application.URL+")"
						})
					}                   
				});
			}




			// USC UTMs
			if (selectTypesNeeded(['uscUtms'])) {
				// Get utms
				await useStore().dispatch(ActionTypes.getObjectInfos, {
					"accountId": this.activeAccountId,
					"objectTypes": ["uscUtms"]
				})
				let uscUtmsRaw = useStore().getters.getObjectTypeStore({
					"accountId": this.activeAccountId,
					"objectType": "uscUtms"
				})
				uscUtmsRaw = uscUtmsRaw.items
				
				if(this.activePage === "uscProfiles") {
					uscUtmsRaw = uscUtmsRaw.filter((utm: UscUtm) => {
						if(utm?.cluster?.isCluster !== true) {
						return utm
						}
					})
				}

				uscUtmsRaw.forEach((utm: any) => {
					options.uscUtms.push({
						"id": utm.utmId,
						"text": deviceHelpers.getAliasedShortDeviceId(utm.utmId, utm.utmname,false) 
					})
				});
			}

			Object.keys(options)?.forEach((key) => {
				if(options[key]?.length) {
					if (options[key].some((option: selectOption) => { return option.id == "" })) {
						let option = options[key].find((option: selectOption) => { return option.id == "" })
						options[key] = options[key].filter((option: selectOption) => { return option.id != "" })
						options[key] = arrayHelpers.sortByObjProperty(options[key], "text", "ASC")
						options[key].unshift(option)
					}
					else {
						options[key] = arrayHelpers.sortByObjProperty(options[key],"text","ASC")
					}
				}
			})

			this.selectOptions = options
			return true
		},
		getFieldsWithErrorConditions: function (this: any, formJson: JsonFormShemaObject) {
			let fields: any = []
			if (formJson.fields) {
				for (let fieldIndex in formJson.fields) {
					let field = formJson.fields[fieldIndex]
					if (field.type == "tabs" || field.type == "tab" || field.type == "section" || field.type == "loop") {
						let childFields: any = this.getFieldsWithErrorConditions(field)
						if (childFields.length) {
							fields = fields.concat(childFields)
						}

					}
					else if (field.errorConditions) {
						fields.push(field)
					}
				}
			}
			return fields
		},
		findNeededSelectOptions: function (this: any, formJson: JsonFormShemaObject) {
			let neededSelectOptions : any = []
			if (formJson.fields) {
				for (let fieldIndex in formJson.fields) {
					let field = formJson.fields[fieldIndex]
					if (field.type == "tabs" || field.type == "tab" || field.type == "section" || field.type == "loop") {
						let neededChildSelectOptions : any = this.findNeededSelectOptions(field)
						if (neededChildSelectOptions.length) {
							neededSelectOptions = neededSelectOptions.concat(neededChildSelectOptions)
						}
					}
					else if (['select','select2'].indexOf(field.type || "") != -1 && typeof (field.options || 0) == 'string'){
						neededSelectOptions.push(field.options)
					}
				}
			}
			if (formJson.template) {
				for (let fieldIndex in formJson.template) {
					let field = formJson.template[fieldIndex]
					if (field.type == "tabs" || field.type == "tab" || field.type == "section" || field.type == "loop") {
						let neededChildSelectOptions : any = this.findNeededSelectOptions(field)
						if (neededChildSelectOptions.length) {
							neededSelectOptions = neededSelectOptions.concat(neededChildSelectOptions)
						}
					}
					else if (['select','select2'].indexOf(field.type || "") != -1 && typeof (field.options || 0) == 'string'){
						neededSelectOptions.push(field.options)
					}
				}
			}
			return neededSelectOptions
		},
		setSelectOptions: function(this:any,formJson:any) {
			if (formJson.fields) {
				for (let fieldIndex in formJson.fields) {
					let field = formJson.fields[fieldIndex]
					if (field.type == "tabs" || field.type == "tab" || field.type == "section" || field.type == "loop") {
						this.setSelectOptions(field)
					}
					else if (['select','select2'].indexOf(field.type) != -1 && typeof field.options == 'string' && field.options != "appPermissions"){
						field.options = JSON.parse(JSON.stringify(this.selectOptions[field.options]))
						field.noTranslations = true
					}
				}
			}
			if (formJson.template) {
				for (let fieldIndex in formJson.template) {
					let field = formJson.template[fieldIndex]
					if (field.type == "tabs" || field.type == "tab" || field.type == "section" || field.type == "loop") {
						this.setSelectOptions(field)
					}
					else if (['select','select2'].indexOf(field.type) != -1 && typeof field.options == 'string' && field.options != "appPermissions"){
						field.options = JSON.parse(JSON.stringify(this.selectOptions[field.options]))
						field.noTranslations = true
					}
				}
			}
			return true
		},
		getCategoriesObject: function(this:any) {
			let categories : any = useStore().state.resources.ContentFilterCategories
			let catArray = []
			for (let category in categories) {
				catArray.push(
					{
						"ip":category,
						"name":T(categories[category].category)
					}
				)
			}
			arrayHelpers.sortByObjProperty(catArray, 'name', 'ASC')
			return catArray
		}
	},
	created(this:any) {
		$('main').scrollTop(0)
		this.init()
	},
	mounted(this:any) {},
	watch: {},
	"components": {
		"loader": loaderComponent,
		"jsonform-entry": jsonFormEntryComponent,
	}
}
export default editpageComponent