import products from "@/classes/objectTypes"
import jsonHelpers from "./helpers.json"
import tenantHelpers from "./helpers.tenants"
import type { License } from "@/classes/objectTypes/unifiedSecurity/licenses/licenses"
import config from "@/classes/config"
import getterHelpers from "./helpers.getters"
const licenseHelpers = {
    // CHECK FOR LICENSE: laas/fwaas, Mobile Security etc.
    hasLicense: function (accountid: string, licenseName: string, state: string | undefined = undefined) {
        let result = false
        accountid = tenantHelpers.getAccountId(accountid)
        
        let licenses = config.canUseNewObjectType("licenses") ? products.unifiedSecurity.licenses.useStore?.().getObjectStoreObjects(accountid) : getterHelpers.useStore()?.state.session.accounts[accountid].unifiedSecurity.licenses.items
        let license: License
        if (licenses) {
            for (let i: number = 0; licenses.length > i; i++) {
                license = licenses[i]
                if (state ? license.state == state : true) {
                    if (['laas', 'fwaas'].indexOf(licenseName) != -1) {
                        if (jsonHelpers.getObjectProperty(license, licenseName + '_enabled') == "1" || jsonHelpers.getObjectProperty(license, licenseName + 'Enabled') == "1") {
                            result = true
                            break
                        }
                    }
                    else if (['uscMessaging', 'uscMonitoring'].indexOf(licenseName) != -1) {
                        if (jsonHelpers.getObjectProperty(license, licenseName + '_enabled') == true || jsonHelpers.getObjectProperty(license, licenseName + 'Enabled') == true) {
                            result = true
                            break
                        }
                    }
                    else if (['unifiedSecurityConsole'].indexOf(licenseName) != -1) {
                        if (jsonHelpers.getObjectProperty(license, 'uscMessagingEnabled') == true || jsonHelpers.getObjectProperty(license, 'uscMonitoringEnabled') == true) {
                            result = true
                            break
                        }
                    }
                    else {
                        if (jsonHelpers.getObjectProperty(license, "type") == licenseName || jsonHelpers.getObjectProperty(license, "type") == licenseName) {
                            result = true
                            break
                        }
                    }
                }
            }
        }
        return result
    },

    isLicenseValid: function (license: License) {
        return license.state == "valid"
    },
    isLicenseExpired: function (license: License) {
        return license.state == "expired"
    },

    /** 
    * CHECK FOR AT LEAST ONE LICENSE OF ARRAY: laas / fwaas, Mobile Security etc.
    */
    hasOneOfLicenses: function (accountid: string, licenseNames: string[], state: string | undefined = undefined) {
        let result = false
        accountid = tenantHelpers.getAccountId(accountid)
        for (let i: number = 0; (licenseNames || []).length > i; i++) {
            if (licenseHelpers.hasLicense(accountid, licenseNames[i], state)) {
                result = true
                break;
            }
        }
        return result
    },
    getLicenseByUUID: function (accountId:string,licenseUUID: string) {
        let licenseInformation : License | undefined = undefined;
        accountId = tenantHelpers.getAccountId(accountId)
        if (!accountId) {
            throw "missing accountId"
        }
        
        let accountLicenses = config.canUseNewObjectType("licenses") ? products.unifiedSecurity.licenses.useStore?.().getObjectStoreObjects(accountId) : window.app?.vue?.$store?.state.session.accounts[accountId].unifiedSecurity.licenses.items

        licenseInformation = accountLicenses.find((license: License) => {
            return (license.uuid || -1) == licenseUUID && license.state == "valid"
        })
        if (!licenseInformation) {
            licenseInformation = accountLicenses.find((license: License) => {
                return (license.uuid || -1) == licenseUUID
            })
        }
        return licenseInformation
    }
}

export default licenseHelpers