import type ObjectType from "./objectType";
import auditlog from "@/classes/objectTypes/unifiedSecurity/auditlog/auditlog";
import licenses from "@/classes/objectTypes/unifiedSecurity/licenses/licenses";
import rolesNew from "@/classes/objectTypes/unifiedSecurity/roles/roles";
import usersNew from "@/classes/objectTypes/unifiedSecurity/users/users";
import ApiKeys from "@/classes/objectTypes/unifiedSecurity/apiKeys/apiKeys";
import type { UserSession } from "../storeSession";
import notificationServices from "./unifiedSecurity/notifications/notifications";
import certificates from "@/classes/objectTypes/mobileSecurity/certificates/certificates";
import vpns from "./mobileSecurity/vpns/vpns";
import androidDevices from "./mobileSecurity/android/androidDevices";
import androidProfiles from "./mobileSecurity/android/androidProfiles";
import zeroTouchConfigurations from "./mobileSecurity/android/zerotouchConfigurations";
import zeroTouchCustomers from "./mobileSecurity/android/zerotouchCustomers";
import zeroTouchDevices from "./mobileSecurity/android/zerotouchDevices";
import iosApps from "./mobileSecurity/apple/iosApps";
import iosDevices from "./mobileSecurity/apple/iosDevices";
import depDevices from "./mobileSecurity/apple/depDevices";
import vppassets from "./mobileSecurity/apple/vppAssets";
import vppAssignments from "./mobileSecurity/apple/vppAssignments";
import vppUsers from "./mobileSecurity/apple/vppUsers";
import iosProfiles from "./mobileSecurity/apple/iosProfiles"
import homescreenLayouts from "./mobileSecurity/apple/homescreenLayouts";
import depProfiles from "./mobileSecurity/apple/depProfiles";
import usrUtms from "./unifiedReporting/utms/usrUtms";
import reports from "./unifiedReporting/reports/reports";
import windowsEnrollmentTokens from "./mobileSecurity/windows/windowsEnrollmenttokens";
import windowsVpns from "./mobileSecurity/windows/windowsVpns";
import topologies from "./unifiedNetwork/topologies";
import activeUsers from "./unifiedSecurity/activeUsers/activeUsers";
import secureDns from "./mobileSecurity/secure-dns/secure-dns";

const products = {
    mobileSecurity:{
        iosApps:iosApps,
        iosDevices:iosDevices,
        depDevices:depDevices,
        vppassets:vppassets,
        vppAssignments:vppAssignments,
        vppUsers:vppUsers,
        androidDevices:androidDevices,
        androidProfiles:androidProfiles,
        certificates:certificates,
        vpns:vpns,
        zeroTouchCustomers: zeroTouchCustomers,
        zeroTouchConfigurations: zeroTouchConfigurations,
        zeroTouchDevices:zeroTouchDevices,
        iosProfiles: iosProfiles,
        homescreenlayouts: homescreenLayouts,
        depProfiles: depProfiles,
        windowsEnrollmentTokens:windowsEnrollmentTokens,
        windowsVpns:windowsVpns,
        activeUsers:activeUsers,
    },
    unifiedSecurity:{
        licenses:licenses,
        auditlog:auditlog,
        roles:rolesNew,
        users:usersNew,
        apiKeys:ApiKeys,
        notifications:notificationServices,
    },
    unifiedReporting: {
        reports:reports,
        usrUtms:usrUtms
    },
    unifiedNetwork: {
        topologies:topologies
    },
    secureDns: {
        secureDnsProfiles: secureDns
    }
}

export const allObjectTypes = {
    ...function() {
        let result : {[name:string]:ObjectType<any>} = {}
        Object.keys(products).forEach((productName) => {
            let product = products[productName as keyof typeof products]
            Object.keys(product).forEach((objectTypeName) => {
                result[objectTypeName] = product[objectTypeName as keyof typeof product]
            })
        })
        return result
    }()
}

export const prepareObjectTypeStores = async (session:UserSession) => {
    const tenantArray = session.scopes.list_accounts?.map((accountInfo) => {
        return {
            tenantDomain:accountInfo.accountid + ".sms"
        }
    })
    Object.keys(allObjectTypes).forEach((objectTypeName) => {
        const objectType = allObjectTypes[objectTypeName] as ObjectType<any>
        const objectTypeStore = objectType.useStore?.()

        if(objectTypeStore) {
            objectTypeStore.setAccounts(tenantArray)
        }
    })
}
export const clearObjectTypeStores = async () => {
    Object.keys(allObjectTypes).forEach((objectTypeName) => {
        const objectType = allObjectTypes[objectTypeName] as ObjectType<any>
        const objectTypeStore = objectType.useStore?.()

        if(objectTypeStore) {
            objectTypeStore.clearStore()
        }
    })
}
export default products
