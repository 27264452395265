<script setup lang="ts">
    import inputVueSelect from '@/templates/inputtypes/input-vue-select.vue';
    import { ref } from 'vue';
    import { HighCode } from 'vue-highlight-code';
    import 'vue-highlight-code/dist/style.css';
    
    const defaultValue = ref(null)
    const defaultCode = `<inputVueSelect  
    :select-options="[
        { 'id':'option1','text':'Option 1' },
        { 'id':'option2','text':'Option 2' },
    ]"
    placeholder="Bitte auswählen"
    v-model="inputValue"
></inputVueSelect>`


    const multipleValue = ref([])
    const multipleCode = `<inputVueSelect  
    :select-options="[
        { 'id':'option1','text':'Option 1' },
        { 'id':'option2','text':'Option 2' },
    ]"
    :multiple="true"
    placeholder="Bitte auswählen"
    v-model="inputValue"
></inputVueSelect>`

const selectOptionsType = `
{
    'id': string | number
    'text': string,
    'icon'?: string,
    'disabled'?: boolean
    'locked'?: boolean
    'objectTypeName'?: string
}[]`
</script>
<template>
    <div class="box border">
        <div class="row margin-xs-0">
            <div class="col-xs-12 padding-xs-2">
                <h4>Select (inputVueSelect)</h4>
                <div class="padding-xs-t-2"></div>
                <inputVueSelect  
                    :select-options="[
                        { 'id':'option1','text':'Option 1' },
                        { 'id':'option2','text':'Option 2' },
                    ]"
                    placeholder="Bitte auswählen"
                    v-model="defaultValue"
                ></inputVueSelect>
            </div>
            <div class="col-xs-12 padding-xs-2">
                <HighCode 
                    :fontSize="'12px'"
                    :codeValue="defaultCode"
                    lang="html"
                ></HighCode>
            </div>
            <div class="col-xs-24 padding-xs-2">
                <table class="border border-h border-v">
                    <thead>
                        <tr>
                            <th>Option</th>
                            <th style="width:300px">Beispiel</th>
                        </tr>
                    </thead>
                    <thead>
                        <!-- Reihe -->
                        <tr>
                            <!-- Option-->
                            <td>
                                <p>
                                    <strong class="color-red">:select-options</strong>
                                </p>
                                <p>
                                    <strong>Payload</strong><br>
                                    <HighCode
                                        :font-size="'12px'"
                                        :codeValue="selectOptionsType"
                                        :lang="'typescript'"
                                    ></HighCode>
                                </p>
                            </td>
                            <!-- Beispiel-->
                            <td>
                                <HighCode 
                                    :fontSize="'12px'"
                                    :codeValue="defaultCode"
                                    lang="html"
                                ></HighCode>
                            </td>
                        </tr>








                    </thead>
                </table>
            </div>
        </div>
    </div>
</template>