<script setup lang=ts>
    import { computed } from 'vue';
    const props = defineProps<{
        modelValue: string
    }>();
    const emit = defineEmits(['update:modelValue'])
    const value = computed({
        get: () => props.modelValue,
        set: (value) => { emit('update:modelValue', value) }
    })
</script>

<template name="input-textarea">
    <textarea type="text" style="width:100%; min-width:100%; max-width:100%; min-height:150px" v-model="value"></textarea>
</template>