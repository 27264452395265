<script setup lang="ts">
import { ref } from 'vue';


    const activeTab = ref("docs")
</script>

<template>
    <div>
        <h2>ObjectTypes</h2>
        <h3>Registering a new ObjectType</h3>
        <h4>Options</h4>
        <h3>Store</h3>
    </div>
</template>

<style lang="scss">

</style>