import { T } from "@/classes/i18n"
import useRouterStore from "@/router/routerStore"

const formValidationHelpers = {
    classErrorIcon: "c4fcc2e4",
    classHelpBlock: "fc3cc290",
    classAlertBox: "fbf2008",

    setInputErrorDOM($base: JQuery, errorMessage: string) {
        $base
            .closest(".row")
                .addClass("has-error form-group")
                .find('.errorlabel').remove().end()
                .find("label").addClass("error").end()
                .find("label")
                .has( "input,select,textarea" )
                .after($('<span class="error-bubble label bg-red margin-xs-t margin-xs-b ' + this.classHelpBlock + '"></span>').text(errorMessage))
    },

    setInputErrorAlertBox(errorMessage: string) {
        var $template = $("<div/>", {"class": "alert notification content bg-red " + this.classAlertBox})
        $template
        .append('<strong><i class="icon fal fa-ban color-white"></i>&nbsp;&nbsp;Error</strong>:&nbsp;&nbsp;')
        .append(errorMessage)
        $("main > section.content-2").prepend($template)
    },

    clearErrors() {
        //$("main > section.content-2 div.alert." + this.classAlertBox + ", span.help-block." + this.classHelpBlock).remove()
        //$(".has-error").removeClass("has-error").find("i." + this.classErrorIcon).remove()
        //

        $('.error-bubble,div.alert.notification').remove()
        $('label.error').removeClass("error")
    },

    // Evaluation of the error object to set input fields feedback.
    checkInputs(errorObject: any, $obj: JQuery, fn: Function = function (e: any) { console.error(e) }) {

        this.clearErrors()
        var self = this

        function parseErrors (resultErrorArray: any[], errorItem: any[]) {
            errorItem.forEach(function (item) {
                if (item.subErrors) {
                    parseErrors(resultErrorArray, item.subErrors)
                } else {
                    resultErrorArray.push(item)
                }
            })

        }

        var extractedErrors: any[] = []

        parseErrors(extractedErrors, errorObject.errors)


        extractedErrors.forEach(function (error) {
            let dataPath: string | undefined = error.dataPath ? error.dataPath : error.instancePath ? error.instancePath : undefined
            let $item = $(`[data-validation-property="${dataPath?.replace(/\//g,"\\/")}"]`)
            console.log(error)
            if (error.code == 302 && error.params && error.params.key) {
                $item = $(`[data-validation-property="${dataPath}/${error.params.key}"]`)
            }
            else if (!$item.length && error.params && error.params.key) {
                $item = $(`[data-validation-property="/${error.params.key}"]`)
            }
            else if (error.instancePath && !$item.length) {
                $item = $(`[data-validation-property="${error.instancePath}"]`)
            }
            if ($item.length) {
                let $currentItem : any = $item.first()
                console.error(error.message)
                fn(error) || self.setInputErrorDOM($item, T(error.message))
            } else {
                fn(error) || self.setInputErrorAlertBox(error.message)
            }
        })

        this.jumpToError()
    },

    jumpToError() {
        let $error = $("label.error")
        if ($error && $error.length) {
            let $navigation = $error.closest('.tabs').children('nav')
            let $activeTab = $error.closest('.tab-pane').attr('data-name')
            $navigation.find('a[data-target='+$activeTab+']').click()
            
            // iosApps dont have a Navbar
            if (useRouterStore().activePage == "iosApps") {
                let offset = $error.offset()?.top
                console.log(offset)
                $('main').scrollTop(offset || 0)
            }
        }
    }
}
export default formValidationHelpers