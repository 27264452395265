import validationHelpers from "@/helpers/helpers.validation"

    /*
    ipaddr - An IP utility knife for javascript
    Copyright (C) 2010 Clif Bratcher
    Copyright (C) 2012 Florian Schäfer <florian.schaefer@gmail.com>

    This program is free software; you can redistribute it and/or
    modify it under the terms of the GNU General Public License
    as published by the Free Software Foundation; either version 2
    of the License, or (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program; if not, write to the Free Software
    Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
     */

class ipaddr {

    addr : string = ""
    has_cidr : boolean = false
    cidr : string|number = ""
    version : number = 0
    maxcidr : number = 0
    args : any = {
        ipv4:false,
        ipv6:false,
        block_reserved:false,
    }
    validaddr:boolean = false
    validcidr:boolean = false
    validhost:boolean = false


    constructor(addr: string) {
        let addressParts;

        this.addr = addr;
        this.has_cidr = false;
        this.args = {};
        this.args.ipv4 = true;
        this.args.ipv6 = true;
        this.args.block_reserved = false;
        if (!addr) {
            throw false;
        }
        addressParts = this.addr.split('/');
        if (addressParts.length > 2) {
            throw false;
        } 
        else if (addressParts.length === 2) {
            this.addr = addressParts[0];
            this.cidr = addressParts[1];
            this.has_cidr = true;

            if (! /^\d+$/.test(this.cidr)) {
                throw false;
            }
        }
        if (this.addr.match(/:/)) {
            this.version = 6;
            this.maxcidr = 128;
            if ((typeof this.cidr == 'number' && isNaN(this.cidr)) || this.cidr == "") {
                this.cidr = 128;
                this.has_cidr = false;
            }
        } 
        else if (this.addr.length >= 7 && validationHelpers.isIp(this.addr)) {
            this.version = 4;
            this.maxcidr = 32;
            if ((typeof this.cidr == 'number' && isNaN(this.cidr)) || this.cidr == "") {
                this.cidr = 32;
                this.has_cidr = false;
            }
        }
        return this;
    }

    repeat = (str:string, num:number) => {
        if (!num || num < 1) return '';
        return new Array(num + 1).join(str);
    }

    getCidr = () => {
        return this.cidr;
    }
    hasCidr = () => {
        return this.has_cidr;
    }
    getVersion = () => {
        return this.version || 0;
    }

    isIPv4 = () => {
        return (this.getVersion() == 4);
    }

    isIPv6 = () => {
        return (this.getVersion() == 6);
    }

    getFullAddress = () => {
        if (!this.isValid()) {
            return '';
        }
        return this.getAddress() + '/' + this.cidr;
    }

    getWildcard = () => {
        if (!this.isValid()) return '';
        switch (this.getVersion()) {
            case 6:
                return this.v6_bin_expand(this.binv());
            case 4:
                return this.v4_bin_expand(this.binv());
            default:
                return '';
        }
    }

    getNetmask = () => {
        if (!this.isValid()) return '';
        switch (this.getVersion()) {
            case 6:
                return this.v6_bin_expand(this.getBinmask());
            case 4:
                return this.v4_bin_expand(this.getBinmask());
            default:
                return '';
        }
    }

    getNetwork = () => {
        if (!this.isValid()) return '';
        switch (this.getVersion()) {
            case 6:
                return this.ip6_network();
            case 4:
                return this.ip4_network();
            default:
                return '';
        }
    }

    getMaxHost = () => {
        if (!this.isValid()) return '';
        switch (this.getVersion()) {
            case 6:
                return this.ip6_max_host();
            case 4:
                return this.ip4_max_host();
            default:
                return '';
        }
    }

    getMinHost = () => {
        if (!this.isValid()) return '';
        switch (this.getVersion()) {
            case 6:
                return this.ip6_min_host();
            case 4:
                return this.ip4_min_host();
            default:
                return '';
        }
    }

    getBroadcast = () => {
        if (this.getVersion() != 4) return '';
        if (!this.isValid()) return '';

        let ip_quads = this.getAddress().split('.');
        let mask_quads = this.getWildcard().split('.');
        let outp = [];

        for (var i = 0; i <= 3; i++) {
            //@ts-ignore
            outp.push(ip_quads[i] | mask_quads[i]);
        }

        return outp.join('.');
    }

    expanded = () => {
        if (!this.isValid()) return '';
        switch (this.getVersion()) {
            case 6:
                return this.ip6_expand(this.addr.toLowerCase());
            case 4:
                return this.ip4_expand(this.addr);
            default:
                return '';
        }
    }

    getAddress = () => {
        if (!this.isValidAddress()) return '';
        switch (this.getVersion()) {
            case 6:
                return this.ip6_compressed();
            case 4:
                return this.addr;
            default:
                return '';
        }
    }

    /* Due to the maximum value of 2^128, we're using strings */
    getHostCount = () => {
        if (!this.isValid()) return '';
        if (typeof this.cidr == "number") {
            switch (this.getVersion()) {
                case 6:
                    return this.v6cidrs[128 - this.cidr];
                case 4:
                    return Math.pow(2, 32 - this.cidr).toString();
                default:
                    return '';
            }
        }
        else {
            return '';
        }
    }

    getBinmask = () => {
        if (!this.isValidCidr()) return '';
        return this.cidr_expand(this.cidr, this.maxcidr);
    }
    v4_bin_expand = (bitmask:any) => {
        if (!bitmask) return '';
        let chunks = [];
        while (bitmask) {
            chunks.push(this.b2d(bitmask.slice(0, 8), 3));
            bitmask = bitmask.slice(8);
        }
        for (var i = 0; i <= 3; i++)
            chunks[i] = Number(chunks[i]);

        return chunks.join('.');
    }

    v6_bin_expand = (bitmask:any) => {
        if (!bitmask) {
            return '';
        }
        let chunks = [];
        while (bitmask) {
            chunks.push(this.b2h(bitmask.slice(0, 16)));
            bitmask = bitmask.slice(16);
        }
        return chunks.join(':');
    }

    ip6_ip4_expand = (ip:string) => {
        let chunks = ip.match(/^([0:]+:FFFF:)([\d.]+)$/i);
        let ipv6 = chunks?.[1] || "";
        let ipv4 = chunks?.[2] || "";
        let quads = [];

        if (!this.ipv4_validate(ipv4)) {
            return '';
        }

        let octets = ipv4.split('.');

        for (var i = 0; i < octets.length; i++) {
            let quad = parseInt(octets[i]).toString(16);
            if (quad.length == 1) {
                quad = '0' + quad;
            }
            quads.push(quad);
        }

        return ipv6 + quads[0] + quads[1] + ':' + quads[2] + quads[3];
    }

    ip6_compressed = () => {
        let compressedResult = '',
            largestGroupsOfZerosIndex = -1,
            largestGroupsOfZerosLength = 0,

            /* Get the extended version of this value and split it into chunks
             * Example: 'a::b' => '000a:0000:0000:0000:0000:0000:0000:000b' => [ '000a', '0000', '0000', '0000', '0000', '0000', '0000', '000b' ]
             */
            chunks = this.expanded().split(':');

        /* Remove leading zeros from every chunk
         * But also ensure every chunk is at least a zero
         * Example: [ 'A', '0000', '000', '00', '0', '0BBB', 'D0', '0E0E' ] => [ 'A', '0', '0', '0', '0', 'BBB', 'D0', 'E0E' ]
         */
        chunks = chunks.map(function (chunk) {
            chunk = chunk.replace(/^0+/, '');

            if (!chunk) {
                chunk = '0';
            }

            return chunk;
        });

        /* Reduce the first, largest group of zeros
         * This happens in multiple steps
         * Example: [ 'A', '0', '0', '0', 'B', '0', 'C', 'D' ] => [ 'A', '', 'B', '0', 'C', 'D' ] => 'A::B:0:C:D'
         */
        chunks.forEach(function (rec, i) {
            // Search the first, largest group of zeros
            let k = i,
                newLength = 1,
                isZero = rec === '0';

            while (isZero && k < chunks.length) {
                if (largestGroupsOfZerosLength < newLength) {
                    largestGroupsOfZerosIndex = i;
                    largestGroupsOfZerosLength = newLength;
                }

                k++;
                isZero = chunks[k] === '0';
                newLength++;
            }
        });

        chunks = chunks
            .map(function (rec, i) {
                // Replace the matching zeros by empty strings
                return i >= largestGroupsOfZerosIndex && i < largestGroupsOfZerosIndex + largestGroupsOfZerosLength ? '' : rec;
            })
            .filter(function (rec, i) {
                if (rec !== '') {
                    // Keep the entries, that were not replaced
                    return true;
                } else if (i === largestGroupsOfZerosIndex) {
                    /* Keep the first replaced entry
                     * Example: [ 'A', '', '', '', '', '', '', 'B' ] => '[ 'A', '', 'B' ] => 'A::B'
                     */
                    return true;
                } else if (largestGroupsOfZerosIndex === 0 && largestGroupsOfZerosLength > 1 && i === 1) {
                    /* If the replaced entries happen to be at the beginning of the list: keep the second one, too
                     * Example: [ '', '', '', '', '', '', '', 'A' ] => '[ '', '', 'A' ] => '::A'
                     */
                    return true;
                } else if (largestGroupsOfZerosIndex === 0 && largestGroupsOfZerosLength === chunks.length && i === 2) {
                    /* If all entries happen to be replaced: keep the third one, too
                     * Example: [ '', '', '', '', '', '', '', '' ] => '[ '', '', '' ] => '::'
                     */
                    return true;
                } else if (largestGroupsOfZerosIndex + largestGroupsOfZerosLength === chunks.length && largestGroupsOfZerosLength > 1 && i === largestGroupsOfZerosIndex + 1) {
                    /* If the replaced entries happen to be at the end of the list: keep the second one, too
                     * Example: [ 'A', '', '', '', '', '', '', '' ] => '[ 'A', '', '' ] => 'A::'
                     */
                    return true;
                } else {
                    // Discard all other replaced entries
                    return false;
                }
            });

        // Reassemble the chunks
        compressedResult = chunks.join(':');

        compressedResult = compressedResult.toLowerCase();

        return compressedResult;
    }

    ip4_min_host = () => {
        let quads = this.getNetwork().split('.');
        if (this.cidr != 32) {
            quads[3] = String(Number(quads[3]) + 1);
        }
        return quads.join('.');
    }

    ip6_min_host = () => {
        let chunks :Array<string|number> = this.getNetwork().split(':');
        chunks[7] = 1 + parseInt(String(chunks[7]), 16);
        chunks[7] = chunks[7].toString(16);
        return this.ip6_expand(chunks.join(':'));
    }

    ip4_max_host = () => {
        let quads = this.getBroadcast().split('.');
        if (this.cidr != 32) {
            quads[3] = String(Number(quads[3]) - 1);
        }
        return quads.join('.');
    }

    ip6_max_host = () => {
        let chunks = this.expanded().split(':');
        let mask_chunks = this.getWildcard().split(':');
        let outp = [];

        for (var i = 0; i <= 7; i++) {
            let chunk = parseInt(chunks[i], 16) | parseInt(mask_chunks[i], 16);
            outp.push(chunk.toString(16));
        }

        return this.ip6_expand(outp.join(':')).toLowerCase();
    }

    ip4_expand = (ip:string) => {
        let quads = ip.split('.');
        let outp = [];
        for (var i = 0; i < quads.length; i++) {
            outp.push(this.repeat('0', 3 - quads[i].length) + quads[i]);
        }
        return outp.join('.');
    }

    ip6_expand = (address:string) => {
        var fullAddress = "";
        var expandedAddress = "";
        var validGroupCount = 8;
        var validGroupSize = 4;
    
        var ipv4 = "";
        var extractIpv4 = /([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})/;
        var validateIpv4 = /((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})/;
    
        // look for embedded ipv4
        if(validateIpv4.test(address))
        {
            let groups = address.match(extractIpv4);
            //@ts-ignore
            for(var i=1; i < groups.length; i++)
            {
                //@ts-ignore
                ipv4 += ("00" + (parseInt(groups[i], 10).toString(16)) ).slice(-2) + ( i==2 ? ":" : "" );
            }
            address = address.replace(extractIpv4, ipv4);
        }
    
        if(address.indexOf("::") == -1) // All eight groups are present.
            fullAddress = address;
        else // Consecutive groups of zeroes have been collapsed with "::".
        {
            var sides = address.split("::");
            var groupsPresent = 0;
            for(var i=0; i<sides.length; i++)
            {
                groupsPresent += sides[i].split(":").length;
            }
            fullAddress += sides[0] + ":";
            for(var i=0; i<validGroupCount-groupsPresent; i++)
            {
                fullAddress += "0000:";
            }
            fullAddress += sides[1];
        }
        var groups = fullAddress.split(":");
        for(var i=0; i<validGroupCount; i++)
        {
            while(groups[i].length < validGroupSize)
            {
                groups[i] = "0" + groups[i];
            }
            expandedAddress += (i!=validGroupCount-1) ? groups[i] + ":" : groups[i];
        }
        return expandedAddress;
    }

    b2h = (bin:any) => {
        const outp = parseInt(this.b2d(bin));
        const outpStr = outp.toString(16).toUpperCase();
        return this.repeat('0', 4 - outpStr.length) + outpStr;
    }

    b2d = (bin:any, len?:number) => {
        let outp = 0;
        for (var i = 0; i <= bin.length; i++) {
            if (bin.charAt(i) == 1) {
                outp += Math.pow(2, bin.length - 1 - i);
            }
        }
        const outpStr = outp.toString();
        if (!len) {
            return outpStr;
        }
        return this.repeat('0', len - outpStr.length) + outpStr;
    }

    binv = () => {
        let outp = '';
        let bin = this.getBinmask();
        for (var i = 0; i < bin.length; i++) {
            if (bin.charAt(i) == '1') {
                outp += '0';
            }
            else {
                outp += '1';
            }
        }
        return outp;
    }

    ip4_network = () => {
        let ip = this.addr.split('.');
        let mask = this.v4_bin_expand(this.cidr_expand(this.cidr, 32)).split('.');
        let outp = [];

        for (var i = 0; i <= 3; i++) {
            outp.push(Number(ip[i]) & Number(mask[i]));
        }

        return outp.join('.');
    }

    ip6_network = () => {
        let v6 = this.ip6_expand(this.addr).split(':');
        let mask = this.v6_bin_expand(this.cidr_expand(this.cidr, 128)).split(':');
        let outp = [];

        for (var i = 0; i <= 7; i++) {
            outp.push((parseInt(v6[i], 16) & parseInt(mask[i], 16)).toString(16));
        }

        return this.ip6_expand(outp.join(':').toLowerCase());
    }

    cidr_expand = (val:number|string, max:number) => {
        return this.repeat('1', Number(val)) + this.repeat('0', max - Number(val));
    }

    cidr_invert = (val: number | string, max: number) => {
        let outp = this.repeat('0', Number(val));
        outp += this.repeat('1', max - Number(val));
        return outp;
    }

    isValidCidr = () => {
        //@ts-ignore
        if (!isNaN(this.validcidr)) {
            return this.validcidr;
        }

        if (typeof this.cidr == 'number' && (this.cidr < 0 || this.maxcidr < this.cidr)) {
            return this.validcidr = false;
        }

        return this.validcidr = true;
    }

    isValidIP6Address = () => {
        if (this.getVersion() != 6) {
            return false;
        }
        if (!this.addr.match(/:/)) {
            return false;
        }

        if (!this.addr.match(/^[0-9A-F:.]+$/i)) {
            return false;
        }

        if (this.addr.match(/^.+:FFFF:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/i)) {
            this.addr = this.ip6_ip4_expand(this.addr);
            if (this.addr == '') {
                return false;
            }
        }

        /* Force consistant case */
        let ip = this.ip6_expand(this.addr.toUpperCase());
        if (!ip) {
            return false;
        }

        if (!ip.match(/^(?:[A-F0-9]{4}:){7}[A-F0-9]{4}$/)) {
            return false;
        }

        return true;
    }

    isValidIP4Address = () => {
        if (this.getVersion() != 4) {
            return false;
        }
        return this.ipv4_validate(this.addr);
    }

    isValidIPv4HostAddress = () => {
        return this.isValid() && this.cidr == 32;
    }

    isValidIPv6HostAddress = () => {
        return this.isValid() && this.cidr == 128;
    }

    isValidHostAddress = () => {
        return this.isValidIPv4HostAddress() || this.isValidIPv6HostAddress();
    }

    ipv4_validate = (host:string) => {
        if (host.length < 7) return false;

        if (!host.match(/^[\d.]+$/)) return false;

        let octet = host.split(/\./);

        if (octet.length != 4) return false;

        for (var i = 0; i < 4; i++) {
            let quad = octet[i]
            if (!quad) {
                return false;
            }
            else if (+quad < 0) {
                return false;
            }
            else if (255 < +quad) {
                return false;
            }
        }

        if (!this.args.block_reserved) return true;

        if (224 <= Number(octet[0]) && Number(octet[0]) <= 239) return false;

        else if (Number(octet[0]) == 0) return false;

        else if (Number(octet[0]) == 127) return false;

        else if (Number(octet[0]) == 255 && Number(octet[1]) == 255 && Number(octet[2]) == 255 && Number(octet[3]) == 255) return false;

        return true;
    }

    isValidAddress = () => {
        //@ts-ignore
        if (!isNaN(this.validaddr)) {
             return this.validaddr;
        }

        if (this.args.ipv6 && this.isValidIP6Address()) return this.validaddr = true;

        if (this.args.ipv4 && this.isValidIP4Address()) return this.validaddr = true;

        return false;
    }

    isValid = () => {
        //@ts-ignore
        if (!isNaN(this.validhost)) {
             return this.validhost;
        }

        if (this.isValidCidr() && this.isValidAddress()) return this.validhost = true;

        return this.validhost = false;
    }

    v6cidrs = [
        '1', '2', '4', '8',
        '16', '32', '64', '128', '256', '512', '1024', '2048', '4096',
        '8192', '16384', '32768', '65536', '131072', '262144', '524288', '1048576',
        '2097152', '4194304', '8388608', '16777216', '33554432', '67108864',
        '134217728', '268435456', '536870912', '1073741824', '2147483648',
        '4294967296', '8589934592', '17179869184', '34359738368', '68719476736',
        '137438953472', '274877906944', '549755813888', '1099511627776',
        '2199023255552', '4398046511104', '8796093022208', '17592186044416',
        '35184372088832', '70368744177664', '140737488355328', '281474976710656',
        '562949953421312', '1125899906842624', '2251799813685248',
        '4503599627370496', '9007199254740992', '18014398509481984',
        '36028797018963968', '72057594037927936', '144115188075855872',
        '288230376151711744', '576460752303423488', '1152921504606846976',
        '2305843009213693952', '4611686018427387904', '9223372036854775808',
        '18446744073709551616', '36893488147419103232', '73786976294838206464',
        '147573952589676412928', '295147905179352825856', '590295810358705651712',
        '1180591620717411303424', '2361183241434822606848',
        '4722366482869645213696', '9444732965739290427392',
        '18889465931478580854784', '37778931862957161709568',
        '75557863725914323419136', '151115727451828646838272',
        '302231454903657293676544', '604462909807314587353088',
        '1208925819614629174706176', '2417851639229258349412352',
        '4835703278458516698824704', '9671406556917033397649408',
        '19342813113834066795298816', '38685626227668133590597632',
        '77371252455336267181195264', '154742504910672534362390528',
        '309485009821345068724781056', '618970019642690137449562112',
        '1237940039285380274899124224', '2475880078570760549798248448',
        '4951760157141521099596496896', '9903520314283042199192993792',
        '19807040628566084398385987584', '39614081257132168796771975168',
        '79228162514264337593543950336', '158456325028528675187087900672',
        '316912650057057350374175801344', '633825300114114700748351602688',
        '1267650600228229401496703205376', '2535301200456458802993406410752',
        '5070602400912917605986812821504', '10141204801825835211973625643008',
        '20282409603651670423947251286016', '40564819207303340847894502572032',
        '81129638414606681695789005144064', '162259276829213363391578010288128',
        '324518553658426726783156020576256', '649037107316853453566312041152512',
        '1298074214633706907132624082305024', '2596148429267413814265248164610048',
        '5192296858534827628530496329220096',
        '10384593717069655257060992658440192',
        '20769187434139310514121985316880384',
        '41538374868278621028243970633760768',
        '83076749736557242056487941267521536',
        '166153499473114484112975882535043072',
        '332306998946228968225951765070086144',
        '664613997892457936451903530140172288',
        '1329227995784915872903807060280344576',
        '2658455991569831745807614120560689152',
        '5316911983139663491615228241121378304',
        '10633823966279326983230456482242756608',
        '21267647932558653966460912964485513216',
        '42535295865117307932921825928971026432',
        '85070591730234615865843651857942052864',
        '170141183460469231731687303715884105728',
        '340282366920938463463374607431768211456'];
}

export default ipaddr