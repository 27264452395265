<script setup lang="ts">
import { ref } from 'vue';
import DocsSelect from "./components/input/docs-select.vue"

const activeTab = ref("docs")
</script>

<template>
    <div>
        <!-- Page title -->
        <h2>Components</h2>

        <!-- Componenttype -->
        <h3>Input</h3>

        <!-- Components -->
        <DocsSelect></DocsSelect>

        <!-- Componenttype -->
        <h3>Table</h3>

        <!-- Components -->
        
        
    </div>
</template>

<style lang="scss">
</style>