const deviceHelpers = {
    getShortDeviceId: function(deviceId: string, range=6) {
        return (deviceId?.toString() || "").split('-').join('').substring(0, range)
    },
    getAliasedShortDeviceId: function(deviceId: string, alias?: string, smallTag: boolean = false) {
        let shortDeviceId = deviceHelpers.getShortDeviceId(deviceId)
        return alias
            ? shortDeviceId
                ? alias + (smallTag ? " <small>(" + shortDeviceId + ")</small>" : " (" + shortDeviceId + ")")
                : alias
            : shortDeviceId
    }
}

export default deviceHelpers