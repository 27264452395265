import { useGlobalMixin } from "@/mixins/mixins.global";
import moment from "moment";
import inputToggleComponent from "./input-toggle-async";

const inputCronComponent = {
    "name": "input-cron",
    "mixins": [useGlobalMixin()],
    "template": `
        <div class="cronValue">
            <label class="input checkbox cronDayBtn">
                <input type="checkbox" v-model="thisValues.days.mon" :disabled="disabled || (thisValues.days.mon ? !canDisableDay() : false) || null" class="checkbox">
                <span>{{ T('Mon') }}</span>
            </label>
            
            <label class="input checkbox cronDayBtn">
                <input type="checkbox" v-model="thisValues.days.tue" :disabled="disabled || (thisValues.days.tue ? !canDisableDay() : false) || null" class="checkbox">
                <span>{{ T('Tue') }}</span>
            </label>

            <label class="input checkbox cronDayBtn">
                <input type="checkbox" v-model="thisValues.days.wed" :disabled="disabled || (thisValues.days.wed ? !canDisableDay() : false) || null" class="checkbox">
                <span>{{ T('Wed') }}</span>
            </label>

            <label class="input checkbox cronDayBtn">
                <input type="checkbox" v-model="thisValues.days.thu" :disabled="disabled || (thisValues.days.thu ? !canDisableDay() : false) || null" class="checkbox">
                <span>{{ T('Thu') }}</span>
            </label>

            <label class="input checkbox cronDayBtn">
                <input type="checkbox" v-model="thisValues.days.fri" :disabled="disabled || (thisValues.days.fri ? !canDisableDay() : false) || null" class="checkbox">
                <span>{{ T('Fri') }}</span>
            </label>

            <label class="input checkbox cronDayBtn">
                <input type="checkbox" v-model="thisValues.days.sat" :disabled="disabled || (thisValues.days.sat ? !canDisableDay() : false) || null" class="checkbox">
                <span>{{ T('Sat') }}</span>
            </label>

            <label class="input checkbox cronDayBtn">
                <input type="checkbox" v-model="thisValues.days.sun" :disabled="disabled || (thisValues.days.sun ? !canDisableDay() : false) || null" class="checkbox">
                <span>{{ T('Sun') }}</span>
            </label>
            <div class="margin-xs-t">
                &nbsp;&nbsp;{{ T("xFrom")}}&nbsp;&nbsp;
                <label class="input select" style="display: inline-block; top: 2px;">
                    <select v-model="thisValues.hour" :disabled="disabled || null" style="display: inline-block;">
                        <option value="0">00:00</option>
                        <option value="1">01:00</option>
                        <option value="2">02:00</option>
                        <option value="3">03:00</option>
                        <option value="4">04:00</option>
                        <option value="5">05:00</option>
                        <option value="6">06:00</option>
                        <option value="7">07:00</option>
                        <option value="8">08:00</option>
                        <option value="9">09:00</option>
                        <option value="10">10:00</option>
                        <option value="11">11:00</option>
                        <option value="12">12:00</option>
                        <option value="13">13:00</option>
                        <option value="14">14:00</option>
                        <option value="15">15:00</option>
                        <option value="16">16:00</option>
                        <option value="17">17:00</option>
                        <option value="18">18:00</option>
                        <option value="19">19:00</option>
                        <option value="20">20:00</option>
                        <option value="21">21:00</option>
                        <option value="22">22:00</option>
                        <option value="23">23:00</option>
                    </select>
                </label>
                &nbsp;
                (UTC)
                <br>
                {{ T('local time of the UTM from 12.6.2.') }} <br/>
                {{ T('UTC for UTMs up to 12.6.1.') }} {{ ' (' + T('corresponds to %s your local time').replace('%s', getLocalTime()) + ')' }}
            </div>
        </div>
    `,
    "data":() => {
        return {
            "thisValues": {
                "days":{
                    "mon":true,
                    "tue":false,
                    "wed":false,
                    "thu":false,
                    "fri":false,
                    "sat":false,
                    "sun":false
                },
                "hour": "0",
            }
        }
    },
    "props": {
        "modelValue": {
            required: true,
            default: "0 * * 1"
        },
        "id": {
            required: false,
            default: null
        },
        "cronOptions": {
            required:true,
            default:"utmProfile"
        },
        "disabled": {
            required:false,
            default:false
        }
    },
    "methods": {
        init: function (this: any) {
            this.valueToFormObj()
        },
        canDisableDay: function(this:any) {
            let enabledCount = 0;
            for(let i = 0; Object.keys(this.thisValues.days).length > i; i++) {
                let day = this.thisValues.days[Object.keys(this.thisValues.days)[i]]
                if (day === true) {
                    enabledCount++;
                    if (enabledCount >= 2) break;
                }
            }
            return enabledCount >= 2
        },
        valueToFormObj: function(this:any) {
            let value = this.modelValue
            if(value.indexOf("*") == -1) value = "0-23 * * 1"
            let values = value.split(" ")
            if(values?.length) {
                let hour = values [0]
                let days = values [3]
                if(this.thisValues.hour != hour) {
                    if (hour?.indexOf("-") != -1) {
                        this.thisValues.hour = hour.split("-")[0]
                    }
                    else {
                        this.thisValues.hour = hour
                    }
                }
                this.thisValues.days.mon = days?.indexOf("1") != -1
                this.thisValues.days.tue = days?.indexOf("2") != -1
                this.thisValues.days.wed = days?.indexOf("3") != -1
                this.thisValues.days.thu = days?.indexOf("4") != -1
                this.thisValues.days.fri = days?.indexOf("5") != -1
                this.thisValues.days.sat = days?.indexOf("6") != -1
                this.thisValues.days.sun = days?.indexOf("7") != -1 || days?.indexOf("0") != -1
                
            }
        },
        getLocalTime:function(this:any) {

            let today = moment().format("YYYY-MM-DD")

            let date1 = today + "T" + (this.thisValues.hour.length == 1 ? "0" : "")+this.thisValues.hour+":00+00"
            let UTC1 = moment.utc(date1)
            let local1 = moment(UTC1).local().format("HH:mm")

            return local1
        }

    },
    "components": {
        "input-toggle": inputToggleComponent
    },
    "mounted": function (this: any) {
        this.init()
    },
    "destroyed": function (this: any) {
    },
    "watch": {
        "modelValue":function(this:any) {
            this.valueToFormObj()
        },
        "thisValues": {
            "deep":true,
            "handler": function (this:any) {
                let daysString = ""

                if (this.thisValues.days.sun) {
                    daysString.length ? daysString += ",0" : daysString += "0"
                }
                if (this.thisValues.days.mon) { 
                    daysString.length ? daysString += ",1" : daysString += "1" 
                }
                if (this.thisValues.days.tue) { 
                    daysString.length ? daysString += ",2" : daysString += "2" 
                }
                if (this.thisValues.days.wed) { 
                    daysString.length ? daysString += ",3" : daysString += "3" 
                }
                if (this.thisValues.days.thu) { 
                    daysString.length ? daysString += ",4" : daysString += "4" 
                }
                if (this.thisValues.days.fri) { 
                    daysString.length ? daysString += ",5" : daysString += "5" 
                }
                if (this.thisValues.days.sat) { 
                    daysString.length ? daysString += ",6" : daysString += "6" 
                }
                

                this.$emit('update:modelValue', this.thisValues.hour + " * * " + (daysString.length ? daysString : "*"))
                this.$emit('change')
            }
        }
    }
}
export default inputCronComponent
