import { useStore } from '@/store/vuex.store'
import tenantHelpers from "./helpers.tenants"
import jsonHelpers from "./helpers.json"
import type { ProductType } from "@/resources/registeredProducts"
import objectStores from "@/classes/init"
import { T } from "@/classes/i18n"
import type { License } from '@/classes/unifiedSecurity/licenses'
import config from '@/classes/config'
import useRouterStore from '@/router/routerStore'
import products, { allObjectTypes } from '@/classes/objectTypes'
import getterHelpers from './helpers.getters'

const mixinHelpers = {
    getActiveAccountId: function (): string {
        return getterHelpers.useStore()?.state.session.activeAccountId || ''
    },
    getActiveTenantDomain: function (): string {
        return getterHelpers.useStore()?.state.session.activeAccountId ? getterHelpers.useStore()?.state.session.activeAccountId + '.sms' : ''
    },
    getActiveAccount: function () {
        return getterHelpers.useStore()?.state.session.accounts[getterHelpers.useStore()?.state.session.activeAccountId || '']
    },
    isSessionInitialized: function (): boolean {
        return getterHelpers.useStore()?.state.session.initialized || false
    },
    isActiveAccountInitialized: function (): boolean {
        return getterHelpers.useStore()?.state.session.accounts[getterHelpers.useStore()?.state.session.activeAccountId || '']?.initialized || false
    },
    getActivePage: function (): string {
        return useRouterStore().getActivePage || ''
    },
    getProductType: function (): ProductType | undefined {
        if (config.canUseNewObjectType(this.getActivePage())) {
            return allObjectTypes[this.getActivePage()].options.productType
        }
        else {
            return tenantHelpers.getProductTypeFromObjectType(this.getActivePage()) || undefined
        }
    },
    getObjectType: function (): string | undefined {
        const productType = String(this.getProductType())
        const activePage = String(this.getActivePage())

        if (config.canUseNewObjectType(this.getActivePage())) {
            return allObjectTypes[this.getActivePage()].options.objectType
        }

        if (productType == undefined && activePage == undefined) return undefined
        const registeredProducts = getterHelpers.useStore()?.state.resources.registeredProducts
        if (!registeredProducts) return undefined
        const registeredProduct = registeredProducts[productType as keyof typeof registeredProducts]
        return registeredProduct?.objectTypes[activePage as keyof typeof registeredProduct.objectTypes].options?.objectType || this.getActivePage()
    },
    getObjectId: function (): string | undefined {
        return useRouterStore().getObjectId
    },
    getContext: function (): string | undefined {
        return useRouterStore().getContext
    },
    getZeroTouchCustomerId: function (): string | undefined {
        return useRouterStore().getZeroTouchCustomerId
    },
    getSearch: function (): string | undefined {
        return useRouterStore().getSearch
    },
    getDevMode: function (): boolean {
        return config.devMode
    },
    getDarkmode: function (): string {
        return getterHelpers.useStore()?.state.browserSettings.darkmode
    },
    getCcutemStates: function (): Array<any> {
        let result: any = undefined
        if (this.getActivePage() == "uscUtms") {
            result = getterHelpers.useStore()?.getters.getObjectTypeStore({
                "accountId": getterHelpers.useStore()?.state.session.activeAccountId,
                "objectType": "ccutmStates"
            })?.items || []
        }
        return result
    },
    canUseAppleDEP: function (): boolean {
        return tenantHelpers.hasFunctionality(getterHelpers.useStore()?.state.session.activeAccountId || '', 'appleDEP')
    },
    getDepProfileStatusInfo: function () {
        return {
            "empty": "No DEP Profile assigned",
            "assigned": "The profile has been assigned to the device, but will not be applied until the next reset",
            "pushed": "The profile has been applied to the device",
            "removed": "The profile has been removed from the device",
        }
    },
    getLicenseLimit: (license: License): number => {
        if(('MDM' == license.type || 'Mobile Security' == license.type) && license.mspLicenseId !== 0 && license.mspLicenseId !== undefined) {
            return license.enrollmentLimit !== undefined ? license.enrollmentLimit : license.user
        } 
        return license.user
    },
    $getObjectProperty: (obj: any, props: string) => { return jsonHelpers.getObjectProperty(obj, props) },
    $hasAccountRole: function (this: any, roleName: string) {
        return getterHelpers.useStore()?.state.session.userInfo.roles.indexOf(roleName) != -1
    },
    $tenantItemNeedsRefresh: (accountid: string, productType: string, objectType: string) => { return tenantHelpers.tenantItemNeedsRefresh(accountid, productType, objectType) },
    $getProductTypeFromObjectType: (objectType: string) => { return tenantHelpers.getProductTypeFromObjectType(objectType) },
    $getObjectIdPropertyForObjectType: (objectType: string) => { return tenantHelpers.getObjectIdPropertyForObjectType(objectType) },
    $getObjectNamePropertyForObjectType: (objectType: string) => { return tenantHelpers.getObjectNamePropertyForObjectType(objectType) },
    $getSelectOptionsForObjectType: (accountid: string, objectType: string) => { return tenantHelpers.getSelectOptionsForObjectType(accountid, objectType) },
    $isItemEnterprise: (item: any) => { return tenantHelpers.isItemEnterprise(item) },
    $isTenantReseller: function (this: any, tenantdomain: any) {
        let result: boolean = false
        const session = getterHelpers.useStore()?.state.session.userInfo
        for (let i in session.scopes.list_accounts) {
            if (session.scopes.list_accounts[i].accountid + ".sms" == tenantdomain) {
                result = session.scopes.list_accounts[i].parent_accountid == null
                break;
            }
        }
        return result
    }
}
export default mixinHelpers