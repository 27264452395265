<script setup lang="ts">
import mixinHelpers from "@/helpers/helpers.mixins";
import objectStores from "@/classes/init";
import type { License } from "@/classes/unifiedSecurity/licenses";
import { ActionTypes } from "@/store/vuex.store";
import { useStore } from '@/store/vuex.store'
import i18n from "@/classes/i18n";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { T } from "@/classes/i18n";
import inputVueSelect from "../inputtypes/input-vue-select.vue";

const props = defineProps<{
    properties: {
        "accountid": string | undefined,
        "productType": string | undefined,
        "objectType": string | undefined,
        "objectId": string | undefined,
        "licenseUUID": string | undefined,
        "item": any | undefined,
    }
}>();

// Ref Values
const initialized = ref(false)
const licenseConditions = ref("")
const privacyPolicy = ref("")
const ownership = ref("COPE")
const agreed = ref(false)

// Reactive Values
const user = reactive({
    "value": <string | number>"#",
    "options": <selectOption[]>[]
})
const license = reactive({
    "value": <string | number>"#",
    "options": <selectOption[]>[]
})
const ownershipOptions = [{
    "id": "COPE",
    "text": "COPE (Corporate owned personally enabled)"
}, {
    "id": "BYOD",
    "text": "BYOD (Bring your own device)"
}] as selectOption[]

//Computed values
const activeAccountId = computed(() => {
    return useStore()?.state.session.activeAccountId || ''
})

// functions
function checkSendConditions() {
    let result: boolean = true

    if (props.properties.objectType == "enterpriseDevices" || props.properties.objectType == "androidDevices" || props.properties.objectType == "devicesConsent") {
        if (!agreed.value) {
            result = false
        }
    }
    else {
        if (!agreed.value) {
            result = false
        }
        if (ownership.value == "BYOD" && (user.value == "#" || (license.value == "#" && !props.properties.item.licenseUUID))) {
            result = false
        }
        if(ownership.value == "COPE" && (license.value == "#" && !props.properties.item.licenseUUID)) {
            result = false
        }
    }

    if (result == true) {
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = false
    }
    else {
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = true
    }
}

// Lifecycle Hooks
onMounted(async () => {
    let licenseConditions: string = "",
        thisPrivacyPolicy: string = "",
        users: any,
        licenses: License[]

    await useStore().dispatch(ActionTypes.getObjectInfos, {
        "accountId": props.properties.accountid,
        "objectTypes": ["users", "licenses"]
    })

    licenses = objectStores.licenses.getObjectsFromStore(activeAccountId.value)
    license.options = licenses.filter((license) => {
        return (license.type == 'Mobile Security' || license.type == 'MDM') && license.uuid && license.state == "valid" && mixinHelpers.getLicenseLimit(license) > license.assignedUser
    }).map((license) => {
        let licenceUser = mixinHelpers.getLicenseLimit(license)
        return {
            "id": license.uuid,
            "text": license.name + " | " + license.type + " [" + license.assignedUser + "/" + licenceUser + "](" + (license.uuid || "").substring(0, 4) + ")",
            "disabled": license.assignedUser > license.user || license.expired || license.dead || license.uuid == undefined
        }
    });

    
    if (license.options.length) {
        license.value = license.options[0].id
        license.options = [{
            "id": "#",
            "text": T('Select license'),
            "disabled": true
        } as selectOption].concat(license.options)
    }

    users = useStore().getters.getObjectTypeStore({
        "accountId": props.properties.accountid,
        "objectType": "users"
    })
    users = users.items || []
    user.options = users.map((user: any) => {
        return {
            "id": user.username,
            "text": user.username
        }
    });
    user.options = [{
        "id": "#",
        "text": T('Select user'),
        "disabled": true
    } as selectOption].concat(user.options)

    thisPrivacyPolicy = await $.get("client/assets/common/license-conditions/dist/html/" + (i18n.getLanguage().toLowerCase() || "en") + "/mobile-security-privacy-policy.html")
    licenseConditions = await $.get("client/assets/common/license-conditions/dist/html/" + (i18n.getLanguage().toLowerCase() || "en") + "/mobile-security-licence-conditions.html")

    licenseConditions = licenseConditions
    privacyPolicy.value = thisPrivacyPolicy

    initialized.value = true
})

// Watches
watch(() => user.value, () => {
    checkSendConditions()
})
watch(() => license.value, () => {
    checkSendConditions()
})
watch(ownership, () => {
    checkSendConditions()
})
watch(agreed, () => {
    checkSendConditions()
})

// Exposed Values
defineExpose({
    user,
    ownership,
    license,
    agreed
})
</script>
<template>
    <div>
        <template v-if="properties.objectType != 'enterpriseDevices' && properties.objectType != 'androidDevices' && properties.objectType != 'devicesConsent'">

            <template v-if="properties.objectType != 'managedAppleIdDevices'">
                <p>
                    {{ T('This device is still unconfigured. Please set the device ownership and accept the appropriate license agreement: ') }}
                </p>
            </template>
            <div class="padding-xs-t">

                <template v-if="properties.objectType != 'managedAppleIdDevices'">

                    <div class="row form-group padding-xs-b" :class="{ 'border-bottom': ownership == 'BYOD' }">
                        <div class="col-lg-6">
                            <label class="control-label" for="enroll-device-ownership">
                                {{ T('Ownership') }}
                            </label>
                        </div>
                        <div class="col-lg-18">
                            <inputVueSelect id="enroll-device-ownership" v-model="ownership" :select-options="ownershipOptions">
                            </inputVueSelect>
                        </div>
                    </div>
                    <div class="row form-group padding-xs-t padding-xs-b" v-if="ownership == 'BYOD'">
                        <div class="col-lg-6">
                            <label class="control-label" for="set-device-ownership-byod-username">
                                {{ T('User') }}
                            </label>
                        </div>
                        <div class="col-lg-18">
                            <inputVueSelect id="set-device-ownership-byod-username" v-model="user.value" :select-options="user.options" :multiple="false">
                            </inputVueSelect>
                            <p class="warning-byod-only">
                                <strong class="color-red">{{ T('Warning') }}:</strong> {{ T("the user can't be changed for BYOD - Devices.")}}
                            </p>
                        </div>
                    </div>
                </template>

                <template v-if="!properties.item.licenseUUID">
                    <div class="row form-group padding-xs-t padding-xs-b">
                        <div class="col-lg-6">
                            <label class="control-label" for="set-device-license">
                                {{ T('License') }}
                            </label>
                        </div>
                        <div class="col-lg-18">
                            <inputVueSelect id="set-device-license" v-model="license.value" :select-options="license.options" :multiple="false">
                            </inputVueSelect>
                        </div>
                    </div>
                </template>
            </div>

        </template>
        <template v-else>
            <p>
                {{ T('Please accept the license agreement:') }}
            </p>
        </template>
        <div class="box-border padding-xs-2" style="height: 200px; overflow:auto;">
            <div v-html="licenseConditions"></div>
            <hr class="margin-xs-y-2">
            <div v-html="privacyPolicy"></div>
        </div>
        <br>
        <label class="checkbox">
            <input type="checkbox" id="i-agree-to-terms-of-use" class="form-control" v-model="agreed"><span></span>
            {{ T('I agree to the license agreement and the privacy conditions') }}
        </label>
    </div>
</template>