import config from '@/classes/config'
import products from '@/classes/objectTypes'
import getterHelpers from '@/helpers/helpers.getters'
import tenantHelpers from '@/helpers/helpers.tenants'
import { useStore } from '@/store/vuex.store'

const enrollmentMixin = {
    computed: {
        "deviceLimitReached": function(this:any) {
            return !useStore()?.state.session.accounts[this.activeAccountId].mobileSecurity.settings.enrollmentAllowed
        },
        "enrollmentDisabled": function(this:any) {
            return useStore()?.state.session.accounts[this.activeAccountId].mobileSecurity.settings.accountRestrictions > 0
        },
        "mdmLicenseExceeded": function(this:any) {
            return useStore()?.state.session.accounts[this.activeAccountId].mobileSecurity.settings.mdmLicenseExceeded
        },
        "devices": function(this:any) {
            return config.canUseNewObjectType("iosDevices") ? products.mobileSecurity.iosDevices.useStore?.().getObjectStore(this.activeAccountId).objects : getterHelpers.useStore().state.session.accounts[this.activeAccountId]?.mobileSecurity.devices?.items
        },
        "canUseAndroidLegacy": function(this:any) {
            return tenantHelpers.hasFunctionality(this.activeAccountId,'androidLegacy')
        },
        "canUseAndroidEnterprise": function(this:any) {
            return tenantHelpers.hasFunctionality(this.activeAccountId,'enterprise')
        },
    }
}
export default enrollmentMixin