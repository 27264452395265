import { useGlobalMixin } from "@/mixins/mixins.global";
import loaderComponent from "../components/loader.vue";
import table2Component from "../components/table2";
import requestHandler from "@/queries/requests";
import { T } from "@/classes/i18n";
import { ActionTypes, MutationTypes } from "@/store/vuex.store";
import { useStore } from '@/store/vuex.store'
import dialogs from "@/dialogs/dialogs";
import arrayHelpers from "@/helpers/helpers.arrays";
import timeHelpers from "@/helpers/helpers.time";
import config from "@/classes/config";
import products from "@/classes/objectTypes";

const showReportsDialogComponent = {
    mixins: [useGlobalMixin()],

    "name":"show-reports-dialog",
    "template": `
        <div>
            <template v-if="initialized">
                <div class="form-group">
                    <table2
                        :table="reportsTable.table"
                        :scrollbar="true"
                        :maxheight="350"
                        :minheight="350"
                        :loading="reportsTable.loading"
                        style="margin:0"
                        :searchable="false"
                    ></table2>
                </div>
            </template>
            <template v-else-if="!initialized">
                <div class="text-size-3 text-center padding-xs-t-4">
                    <loader class="color-red"></loader>
                </div>
                <template v-if="loaderText">
                    <div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity:0.8">
                        <span>{{ T(loaderText) }}</span>
                    </div>
                </template>
            </template>
        </div>
    `,
    "data":() => {
        return {
            "initialized":false,
            "loaderText":"Loading...",
            "reportId":undefined,
            "reportsTable":{
                "loading":true,
                "table":{
                    "titles":[
                        {
                            "id": "name",
                            "text": "Name",
                            "sortable": false
                        },
                        {
                            "id": "action",
                            "text": T("Actions"),
                            "width": 250,
                            "sortable": false
                        }
                    ],
                    "rows":[]
                }
            }
        }
    },
    "computed": {
        "activeAccountId": function(this: any) {
            return useStore().state.session.activeAccountId || undefined
        }
    },
    "props": {
        "properties":{
            required:true,
            default:() => {
                return {
                    "report":undefined
                }
            }
        }
    },
    "methods": {
        "getTableRowByIndex": function(this:any,i:string) {
            const rows = this.reportsTable.table.rows
            return rows.find((row:any) => {
                return row.data.rowIndex == i
            })
        },
        "init": async function(this:any) {



            let thisComponent : any = this,
            report = this.properties.report,
            reportId : any = report.reportId,
            rows : object[] = []
            if(config.canUseNewObjectType("reports")) {
                await products.unifiedReporting.reports.queries.getObjectsFromApi(this.activeAccountId)
            }
            else {
                await useStore().dispatch(ActionTypes.getObjectInfo,{
                    "accountId":this.activeAccountId,
                    "objectId":reportId,
                    "objectType":"reports",
                    "productType":"unifiedReporting"
                })
            }

            this.initialized = false
            this.loaderText = "Loading data..."
            arrayHelpers.sortByObjProperty(report.files || [],'date','DESC')

            for(let i in report.files) {
                if(report.files[i].name.indexOf('preview') == -1) {
                    let thisRow = () => { return thisComponent.getTableRowByIndex(String(i)) }
                    rows.push(
                        {
                            data: {
                                "rowIndex":String(i),
                                "name":report.files[i].name,
                                "action":{
                                    "buttons":[{
                                        "onClick": async function(this:any) {
                                            let thisButton = thisRow()?.data.action.buttons[0]
                                            thisButton.loading = true
                                            thisButton.disabled = true
                                            let success : boolean = await thisComponent.sendMail(report.reportId,report.files[i].filename)
                                            if(success) {
                                                thisButton.loading = false
                                                thisButton.disabled = true
                                                thisButton.onClick = undefined
                                                thisButton.icon = "fal fa-check"
                                                thisButton.text = T("Sent")
                                            }
                                            else {
                                                thisButton.loading = false
                                                thisButton.disabled = false
                                                thisButton.icon = "fal fa-exclamation-triangle"
                                                thisButton.text = T("Failed")
                                                setTimeout(function() {
                                                    thisButton.loading = false
                                                    thisButton.disabled = false
                                                    thisButton.icon = "fal fa-paper-plane"
                                                    thisButton.text = T("Retry")
                                                },2000)
                                            }
                                        },
                                        "icon":"fal fa-paper-plane",
                                        "text":report.recipients.length ? T("Send mail") : T("No recipients"),
                                        "loading":false,
                                        "disabled": report.recipients.length > 0 ? false : true
                                    },
                                    {
                                        "onClick": async function (this: any) {
                                            let thisButton = thisRow()?.data.action.buttons[1]
                                            thisButton.loading = true
                                            thisButton.disabled = true
                                            useStore().commit(MutationTypes.removeModal, { "accountId": useStore().state.session.activeAccountId })
                                            dialogs.misc.confirmDialog(
                                                thisComponent.activeAccountId,
                                                T('Confirm delete'),
                                                T('Do you really want to delete this report?'),
                                                async () => {
                                                    await thisComponent.deleteFile(report.reportId, report.files[i].filename)
                                                    dialogs.unifiedReporting.showReportsDialog(report)
                                                }    
                                            )
                                        },
                                        "icon": "fal fa-trash",
                                        "text": T("Delete"),
                                        "loading": false,
                                        "disabled": false
                                    }]
                                }
                            }
                        }
                    )
                }
            }
            this.reportsTable.table.rows = rows
            this.loaderText = "Finished loading..."
            this.initialized = true
            this.reportsTable.loading = false
        },
        "sendMail": async function(this:any,reportId:string,filename:any) {
            let result : boolean = false
            try {
                await requestHandler.request('POST',"/sms-mgt-api/api/1.1/tenants/"+this.activeAccountId+".sms/laas/reports/"+reportId+"/files/"+filename)
                result = true
            }
            catch(e:any) {
                console.error(e)
                result = false
            }
            return result
        },
        "deleteFile": async function(this:any,reportId:string,filename:any) {
            let result : boolean = false
            try {
                await requestHandler.request('DELETE',"/sms-mgt-api/api/1.1/tenants/"+this.activeAccountId+".sms/laas/reports/"+reportId+"/files/"+filename)
                result = true
            }
            catch(e:any) {
                console.error(e)
                result = false
            }
            return result
        }
    },
    "created": async function(this:any) {

    },
    "mounted": function(this:any) {
        this.init()
    },
    "watch": {
    },
    "destroyed": function (this: any) {
        this.initialized = false
    },
    "components": {
        "loader":loaderComponent,
        "table2":table2Component
    }
}
export default showReportsDialogComponent
