import { createStore } from "vuex"
import vuexGetters from "./vuex.getters"
import registeredProducts from "@/resources/registeredProducts"
import stringHelpers from "@/helpers/helpers.strings"
import devLog from "../classes/log"
import { GenericObjectStore } from "../classes/genericObjectStore"
import apis from "../classes/apis"
import i18n, { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import type { FrontendNotificationAdd } from "@/classes/notifications"
import queries from "@/queries/queries"
import requestHandler from "@/queries/requests"
import type { ProductType } from "@/resources/registeredProducts"
import router from "@/router/router"
import moment from "moment"
import tenantHelpers from "@/helpers/helpers.tenants"
import jsonHelpers from "@/helpers/helpers.json"
import type { AugmentedActionContext,AccountStore, AppState, AppStateResources, ObjectStoreCountProperties, SetAppStateConfig } from "./../../../types/vuex"
import cookies from "@/classes/cookieHandler"
import type { FrontendNotification } from "@/classes/notifications"
import type { VppUser } from "@/classes/mobileSecurity/vppUsers"
import type { EnterpriseDevice, EnterpriseDevicePlusZeroTouch } from "@/classes/mobileSecurity/enterpriseDevices"
import type { ZeroTouchDevice } from "@/classes/mobileSecurity/zeroTouchDevices"
import frontendNotifications from "@/classes/notifications"
import websocketHandler from "@/classes/websocket"

import resourcePages from "./../resources/resource-pages.json"
import resourcePackageNames from "./../resources/resource-packagenames.json"

import reportsShema from "./../resources/resource-shema-reports.json"
import usersShema from "./../resources/resource-shema-users.json"
import rolesShema from "./../resources/resource-shema-roles.json"
import vppusersShema from "./../resources/resource-shema-vppusers.json"
import utmsShema from "./../resources/resource-shema-utms.json"
import vpnsShema from "./../resources/resource-shema-vpns.json"
import notificationsShema from "./../resources/resource-shema-notifications.json"
import brandingShema from "./../resources/resource-shema-branding.json"
import msInventoryShema from "./../resources/resource-shema-msInventory.json"
import uscInventoryShema from "./../resources/resource-shema-uscInventory.json"
import depProfilesShema from "./../resources/resource-shema-depProfiles.json"
import androidProfilesShema from "./../resources/resource-shema-androidProfiles.json"
import iosProfilesShema from "./../resources/resource-shema-iosProfiles.json"
import zeroTouchConfigurationsShema from "./../resources/resource-shema-zeroTouchConfigurations.json"
import homescreenlayoutsShema from "./../resources/resource-shema-homescreenlayouts.json"
import uscProfilesShema from "./../resources/resource-shema-usc-profiles.json"
import apiKeysShema from "./../resources/resource-shema-apiKeys.json"
import windowsEnrollmentTokensShema from "./../resources/resource-shema-windowsEnrollmentTokens.json"
import resourcePluginLicenses from "./../resources/resource-plugin-licenses.json"
import resourceAppleDeviceNames from "./../resources/ios/deviceNames.json"
import resourceDefaultBundleIds from "./../resources/resource-apple-default-bundle-apps.json"
import resourceIOSNativeApps from "./../resources/ios/native_app_infos.json"
import resourceTVOSNativeApps from "./../resources/ios/tvos_native_app_infos.json"
import resourceSecureDns from "./../resources/resource-shema-secureDns-Config.json"

import PFGroups from "./../resources/config/pf_groups.json"
import uscUtmTimezones from "./../resources/usc/timezones-utm.json"
import geoipsEN from "./../resources/usc/geoips-en.json"
import geoipsDE from "./../resources/usc/geoips-de.json"
import CFCategoriesEn from "./../resources/config/cf_categories_en.json"
import CFCategoriesDe from "./../resources/config/cf_categories_de.json"
import { useVue } from "@/app"
import dialogs from "@/dialogs/dialogs"
import storeHelpers from "@/helpers/helpers.store"
import arrayHelpers from "@/helpers/helpers.arrays"
import validationHelpers from "@/helpers/helpers.validation"
import timeHelpers from "@/helpers/helpers.time"
import promiseHelpers from "@/helpers/helpers.promises"
import type TaskLooper from "@/classes/taskLooper"
import idleTimer from "@/classes/idleTimer"
import products, { prepareObjectTypeStores } from "@/classes/objectTypes"
import EnterpriseDevices from "@/classes/mobileSecurity/enterpriseDevices"
import config from "@/classes/config"
import useRouterStore from "@/router/routerStore"
import accounts from "@/modules/accounts"
import licenseHelpers from "@/helpers/helpers.license"
import vppApi from "@/classes/objectTypes/mobileSecurity/apple/vpp"
import encodingHelpers from "@/helpers/helpers.encoding"
import { useUserLogStore } from "@/classes/userLogStore"
import getterHelpers from "@/helpers/helpers.getters"
const envVars = import.meta.env



const CFCategories = {
    "en": CFCategoriesEn,
    "de": CFCategoriesDe
}

export type vuexClipboard = {
    "profiles": any[],
    "apps": any[],
    "vpns": any[],
    "androidProfiles": any[],
    "iosProfiles": any[],
}

export enum MutationTypes {
    clear = "clear",
    setResources = "setResources",
    setAccounts = "setAccounts",
    setCount = "setCount",
    setTenants = "setTenants",
    setTenantProperty = "setTenantProperty",
    setActiveAccountId = "setActiveAccountId",
    addOrUpdateObjects = "addOrUpdateObjects",
    iosMerge = "iosMerge",
    setObjectInfos = "setObjectInfos",
    setObjectProperty = "setObjectProperty",
    deleteObject = "deleteObject",
    addSubscription = "addSubscription",
    killSubscription = "killSubscription",
    addSubscriptionHook = "addSubscriptionHook",
    deleteSubscriptionHook = "deleteSubscriptionHook",
    addNotification = "addNotification",
    removeNotification = "removeNotification",
    addModal = "addModal",
    removeModal = "removeModal",
    setClipboard = "setClipboard",
    addOnClickHandler = "addOnClickHandler",
    setDarkmode = "setDarkmode",
    setWaitForSessionToSetActiveAccountId = "setWaitForSessionToSetActiveAccountId"
}

export type Mutations<S = AppState> = {
    [MutationTypes.clear](state: S): void
    [MutationTypes.setResources](state: S, payload: AppStateResources): void
    [MutationTypes.setAccounts](state: S, payload: any): void
    [MutationTypes.setCount](state: S, payload: {
        "accountId": string,
        "productType": ProductType,
        "objectType": string,
        "count": number | undefined,
        "countProperty": ObjectStoreCountProperties
    }): void
    [MutationTypes.setTenants](state: S, payload: any): void
    [MutationTypes.setTenantProperty](state: S, payload: {
        "accountId": string
        "property": string
        "additionalData": any
        "value": any
    }): void
    [MutationTypes.setActiveAccountId](state: S, accountId: string): void
    [MutationTypes.addOrUpdateObjects](state: S, payload: {
        "accountId": string,
        "productType": ProductType,
        "objectType": string,
        "items": Array<any>
    }): void
    [MutationTypes.iosMerge](state: S, accountId: string): void
    [MutationTypes.setObjectInfos](state: S, payload: {
        "accountId": string
        "fullRefresh"?: boolean
        "products": any
    }): void
    [MutationTypes.setObjectProperty](state: S, payload: {
        "accountId": string
        "objectId": string
        "productType": ProductType
        "objectType": string
        "objectPropertyPath"?: string[]
        "property": string
        "value": any
    }): void
    [MutationTypes.deleteObject](state: S, payload: {
        "accountId": string
        "productType": ProductType
        "objectType": string
        "objectIdProperty"?: string
        "objectId": string
    }): void
    [MutationTypes.addSubscription](state: S, payload: {
        "accountId": string
    }): void
    [MutationTypes.killSubscription](state: S, payload: {
        "accountId": string
    }): void
    [MutationTypes.addSubscriptionHook](state: S, payload: {
        "accountId": string
        "hookKey": string
        "hookFunction": (event: any) => void
    }): void
    [MutationTypes.deleteSubscriptionHook](state: S, payload: {
        "accountId": string
        "hookKey": string
    }): void
    [MutationTypes.addNotification](state: S, payload: FrontendNotification): void
    [MutationTypes.removeNotification](state: S, payload: {
        "accountId": string,
        "objectId": string
    }): void
    [MutationTypes.addModal](state: S, payload: any): void
    [MutationTypes.removeModal](state: S, payload: any): void
    [MutationTypes.setClipboard](state: S, payload: any): void
    [MutationTypes.addOnClickHandler](state: S, payload: {
        "id": string,
        "ref": keyof AppState["template"]["onClick"],
        "method": VoidFunction
    }): void
    [MutationTypes.setDarkmode](state: S, darkmode: "1" | "0"): void,
    [MutationTypes.setWaitForSessionToSetActiveAccountId](state: S, value: boolean): void
}

export enum ActionTypes {
    switchAccount = "switchAccount",
    killSubscription = "killSubscription",
    updateAccount = "updateAccount",
    getNotifications = "getNotifications",
    getObjectTypeCount = "getObjectTypeCount",
    addNotification = "addNotification",
    addModal = "addModal",
    setTenantProperty = "setTenantProperty",
    updateObjectProperty = "updateObjectProperty",
    updateObjectInventory = "updateObjectInventory",
    deleteObject = "deleteObject",
    publishProfile = "publishProfile",
    revokeProfile = "revokeProfile",
    getAuditLog = "getAuditLog",
    getObjectInfos = "getObjectInfos",
    getObjectInfo = "getObjectInfo",
    addOrUpdateObject = "addOrUpdateObject",
    setDarkmode = "setDarkmode"
}
export type Actions<C = AugmentedActionContext> = {
    [ActionTypes.switchAccount](context: C, accountId: string): void
    [ActionTypes.updateAccount](context: C, accountId: string): any
    [ActionTypes.getNotifications](context: C, accountId: string): any
    [ActionTypes.getObjectTypeCount](context: C, payload: {
        "accountId": string,
        "objectType": string,
        "queryType"?: string
    }): any
    [ActionTypes.addNotification](context: C, notification: FrontendNotificationAdd): any
    [ActionTypes.addModal](context: C, modal: any): any
    [ActionTypes.setTenantProperty](context: C, payload: {
        "accountId": string,
        "property": string,
        "additionalData"?: any,
        "value": any
    }): any
    [ActionTypes.updateObjectProperty](context: C, payload: {
        "accountId": string
        "productType": ProductType
        "objectType": string
        "queryType": string
        "objectPropertyPath"?: string[]
        "objectId": string
        "object": any
        "property": string
        "value": any
    }): any
    [ActionTypes.updateObjectInventory](context: C, payload: {
        "accountId": string
        "productType": ProductType
        "objectType": string
        "queryObjectType": string
        "objectId": string
        "object": any
    }): any
    [ActionTypes.deleteObject](context: C, payload: {
        "accountId": string
        "productType": ProductType
        "objectIdProperty"?: string
        "objectId": string
        "objectType": string
        "customerId"?: string
    }): any
    [ActionTypes.publishProfile](context: C, payload: {
        "accountId": string
        "objectId": string
    }): any
    [ActionTypes.revokeProfile](context: C, payload: {
        "accountId": string
        "objectId": string
    }): any
    [ActionTypes.getAuditLog](context: C, accountId: string): any
    [ActionTypes.getObjectInfos](context: C, payload: {
        "accountId": string
        "objectTypes": string[]
        "customerId"?: string
        "fullRefresh"?: boolean
        "lookupApps"?: boolean
    }): any
    [ActionTypes.getObjectInfo](context: C, payload: {
        "accountId": string,
        "productType": ProductType,
        "objectType": string,
        "objectId": string,
        "queryType"?: string,
        "customerId"?: string,
        "updateOthers"?: boolean
    }): any
    [ActionTypes.addOrUpdateObject](context: C, payload: {
        "accountId": string
        "productType"?: ProductType
        "objectType": string
        "queryType"?: string
        "customerId"?: string
        "objectId"?: string
        "object": any
    }): any
    [ActionTypes.setDarkmode](context: C, darkmode: "1" | "0"): any
}

const state : AppState = {
    "template": {
        "onClick": {
            "root":{}
        }
    },
    
    "modals":[],
    "session": {
        "accounts": {},
        "isReseller": false,
        "initialized": false,
        "activeAccountId": undefined,
        "userInfo": <any>{},
        "waitForSessionToSetActiveAccountId":false
    },
    "clipBoard": {
        "profiles": [],
        "androidProfiles": [],
        "iosProfiles": [],
        "apps": [],
        "vpns": []
    },
    "resources": {
        registeredProducts:registeredProducts,
        roles: {
            "RESELLER_Description": "Has multiple customers/tenants",
            "RESELLER": "__RESELLER__",
            "RESELLER_TECH_Description": "Technical administrator",
            "RESELLER_TECH": "__RESELLER_TECH__",
            "TENANT_Description": "A customer",
            "TENANT": "__TENANT__",
            "USER_Description": "The user in a tenant context/ The employee of a customer",
            "USER": "__USER__",
            "RSP_Description": "The role the RSP uses",
            "RSP": "__RSP__",
            "STATS__Description": "A role to query stats endpoint",
            "STATS": "__STATS__",
            "SYSTEM_Description": "Do everything",
            "SYSTEM": "__SYSTEM__",
            "SUPPORT_Description": "Support user can login as tenant.",
            "SUPPORT": "__SUPPORT__",
            "ADMIN_Description": "Create this role and assign to a user to allow him to manage users, roles, profiles, devices.",
            "ADMIN": "admin",
            "AUDITOR_Description": "Create this role and assign to a user to allow him auditing.",
            "AUDITOR": "auditor",
            "ANONYMOUS_Description": "Unauthed user.",
            "ANONYMOUS": "__ANONYMOUS__",
            "STEVE_JOBS_Description": "User for steve jobs service.",
            "STEVE_JOBS": "__STEVE_JOBS__"
        },
        "shemas": {
            reports: reportsShema,
            users: usersShema,
            roles: rolesShema,
            vppusers: vppusersShema,
            utms: utmsShema,
            usrUtms: utmsShema,
            vpns: vpnsShema,
            notifications: notificationsShema,
            branding: brandingShema,
            msInventory: msInventoryShema,
            uscInventory: uscInventoryShema,
            depProfiles: depProfilesShema,
            androidProfiles: androidProfilesShema,
            iosProfiles: iosProfilesShema,
            zeroTouchConfigurations: zeroTouchConfigurationsShema,
            homescreenlayouts: homescreenlayoutsShema,
            uscProfiles: uscProfilesShema,
            apiKeys: apiKeysShema,
            windowsEnrollmentTokens: windowsEnrollmentTokensShema,
            secureDnsProfiles: resourceSecureDns
        },
        "pages": resourcePages,
        "packagenames": resourcePackageNames,
        "ContentFilterCategories": CFCategories[i18n.getLanguage().toLowerCase() as ("en" | "de")],
        "PortfilterGroups": PFGroups,
        "pluginLicenses": function() { 
            let result = resourcePluginLicenses
            if (envVars.VITE_LICENSE_ARRAY) {
                result.plugins = result.plugins.concat(JSON.parse(envVars.VITE_LICENSE_ARRAY))
            }
            result.plugins = arrayHelpers.sortByObjProperty(result.plugins, "pluginName", "ASC")
            return result 
        }(),
        "apple": {
            deviceNames: resourceAppleDeviceNames,
            defaultBundleIds: resourceDefaultBundleIds,
            nativeAppInfos: resourceIOSNativeApps,
            tvOsNativeApps: resourceTVOSNativeApps
        },
        "usc": {
            "uscUtmTimezones": uscUtmTimezones,
            "geoipsEN": geoipsEN,
            "geoipsDE": geoipsDE
        }

    },
    "randomSeed": stringHelpers.generateRandomString(12),
    "browserSettings": {
        "darkmode":"0"
    }
}


const vuexStore = createStore({
    "state":state,
    "getters": vuexGetters,
    "mutations": <Mutations<AppState>>{
        [MutationTypes.clear]: (state) => {

            devLog.log('Clear', "Killing websocket checker...", undefined, "info", 4);
            (<TaskLooper>useVue().websocketChecker).removeTask('websocketCheck')
            devLog.log('Clear', "Unsubscribing active websocket subscriptions...", undefined, "info", 4)
            if (jsonHelpers.getObjectProperty(state, "session.accounts." + state.session.activeAccountId + ".websocket.subscription") != undefined) {
                let subscription: any = jsonHelpers.getObjectProperty(state, "session.accounts." + state.session.activeAccountId + ".websocket.subscription")
                if (typeof jsonHelpers.getObjectProperty(subscription, "unsubscribe") == "function") {
                    devLog.log('Clear', "Unsubscribing active websocket for " + state.session.activeAccountId + "...", subscription, "info", 4)
                    subscription.unsubscribe()
                }
            }
            Object.keys(state.session.accounts).forEach((key) => {
                websocketHandler.closeConnection()
                websocketHandler.deleteAllHooks()
            })
            devLog.log('Clear', "Clearing session...", undefined, "info", 4)
            state.session = {
                "userInfo": {},
                "accounts": {},
                "isReseller": false,
                "initialized": false,
                "activeAccountId": undefined,
                "waitForSessionToSetActiveAccountId":false
            }
            devLog.log('Clear', "Clearing clipboard..", undefined, "info", 4)
            state.clipBoard = {
                "profiles": [],
                "apps": [],
                "vpns": [],
                "androidProfiles": [],
                "iosProfiles": [],
            }
            state.modals = []
            devLog.log('Clear', "App resetted", undefined, "success", 4)
            idleTimer.killIdleTimer()
        },
        [MutationTypes.setResources]: function (state, payload: any) {
            for (let property in payload) {
                state.resources[property] = payload[property]
            }
        },
        [MutationTypes.setAccounts]: function (state, payload: { session: any, tenants: any } = { session: {}, tenants: []}) {
            let tenants : any = {}
            payload.tenants?.forEach((tenant: any) => {
                tenants[tenantHelpers.getAccountId(tenant.tenantDomain)] = {
                    ios:tenant.ios,
                    android:tenant.android
                }
            })
            let session = payload.session
            if (session) {
                state.session.userInfo = session
            }
            if (session?.roles?.length) {
                state.session.isReseller = session.roles.indexOf("__RESELLER__") != -1
            }
            if ((session?.scopes?.list_accounts || []).length) {
                session.scopes.list_accounts.forEach(function (account: any) {
                    account.isReseller = false                   
                    if (account.parent_accountid === null) {
                        account.isReseller = true
                    }
                    state.session.accounts[account.accountid] = <AccountStore>{
                        "initialized": false,
                        "notifications": frontendNotifications.addNotificationList(account.accountid, []),
                        "modals": [],
                        "unifiedSecurity": {
                            "account": account,
                            "flags": {},
                            "privacy": {
                                "privacyLevel": "1",
                                "auditlog": {
                                    "titles": [],
                                    "rows": []
                                }
                            },
                            "notifications": {
                                "count": undefined,
                                'lastUpdate': undefined,
                                "items": <notificationServices>[]
                            },
                            "apiKeys": {
                                "count": undefined,
                                'lastUpdate': undefined,
                                "items": []
                            },
                            "users": {
                                "count": undefined,
                                "items": [],
                                'lastUpdate': undefined,
                            },
                            'roles': {
                                "count": undefined,
                                "items": [],
                                'lastUpdate': undefined,
                            },
                            "licenses": {
                                "count": undefined,
                                "items": [],
                                'lastUpdate': undefined,
                            },
                            "images": {
                                "count": undefined,
                                "items": [],
                                "lastUpdate": undefined
                            },
                            "settings": {
                                "azureAd": {
                                    "accounts": []
                                }
                            }
                        },
                        "mobileSecurity": {
                            "devices": {
                                "androidCount": undefined,
                                "iosCount": undefined,
                                "count": undefined,
                                'lastUpdate': undefined,
                                "items": [],
                                "map": {}
                            },
                            "enterpriseDevices": {
                                "count": undefined,
                                'lastUpdate': undefined,
                                "items": []
                            },
                            "vpns": {
                                "count": undefined,
                                'lastUpdate': undefined,
                                "items": [],
                            },
                            "historyDevices": {
                                "count": undefined,
                                'lastUpdate': undefined,
                                "items": [],
                            },
                            "historyVpns": {
                                "count": undefined,
                                'lastUpdate': undefined,
                                "items": [],
                            },
                            "profiles": {
                                "count": undefined,
                                "iosCount": undefined,
                                "androidCount": undefined,
                                'lastUpdate': undefined,
                                "items": [],
                                "map": {}
                            },
                            "depProfiles": {
                                "count": undefined,
                                'lastUpdate': undefined,
                                "items": [],
                                "map": {}
                            },
                            "apps": {
                                "count": undefined,
                                'lastUpdate': undefined,
                                "items": []
                            },
                            "homescreenlayouts": {
                                "count": undefined,
                                'lastUpdate': undefined,
                                "items": []
                            },
                            'certificates': {
                                'count': undefined,
                                'lastUpdate': undefined,
                                'items': []
                            },
                            'tags': {
                                'count': undefined,
                                'lastUpdate': undefined,
                                'items': []
                            },
                            'vppassets': {
                                'count': undefined,
                                'lastUpdate': undefined,
                                'items': []
                            },
                            'vpplicenses': {
                                'count': undefined,
                                'lastUpdate': undefined,
                                'items': []
                            },
                            'vppUsers': {
                                'count': undefined,
                                'lastUpdate': undefined,
                                'items': <VppUser[]>[]
                            },
                            'zeroTouchCustomers': {
                                'count': undefined,
                                'lastUpdate': undefined,
                                'items': []
                            },
                            'zeroTouchConfigurations': {
                                'count': undefined,
                                'lastUpdate': undefined,
                                'items': []
                            },
                            'zeroTouchDevices': {
                                'count': undefined,
                                'lastUpdate': undefined,
                                'items': []
                            },
                            'settings': {
                                'accountRestrictions': undefined,
                                "enrollmentAllowed": false,
                                "mdmLicenseExceeded": false,
                                "depEnrollmentCode":"",
                                'ios': tenants[account.accountid].ios,
                                'android': tenants[account.accountid].android
                            }
                        },
                        "unifiedReporting": {
                            "utms": {
                                "count": undefined,
                                "items": [],
                                'lastUpdate': undefined,
                            },
                            "reports": {
                                "count": undefined,
                                "items": [],
                                'lastUpdate': undefined,
                            },
                            "settings": {
                                "legalHold": false
                            }
                        },
                        "unifiedSecurityConsole": {
                            "uscUtms": {
                                "count": undefined,
                                "items": [],
                                'lastUpdate': undefined,
                            },
                            "ccutmStates": {
                                "count": undefined,
                                "items": [],
                                'lastUpdate': undefined,
                            },
                            "uscProfiles": {
                                "count": undefined,
                                "items": [],
                                'lastUpdate': undefined,
                            },
                            "settings": {}
                        },
                        "secureDns": {
                            
                        }
                    }
                })
            }
            useVue().$refs.navigation?.doRefresh()
        },
        [MutationTypes.setCount]: (state, payload) => {
            const objectTypeStore = storeHelpers.getObjectTypeStore(payload.accountId, payload.productType, payload.objectType)
            if (objectTypeStore != undefined) {
                objectTypeStore[payload.countProperty] = payload.count
            }
        },
        [MutationTypes.setTenants]: (state, payload) => {
            for (let accountId in payload) {
                let tenant = payload[accountId]
                accountId = tenantHelpers.getAccountId(accountId)
                devLog.log('Vuex', 'Setting account: ' + accountId, tenant, 'default', 2)
                jsonHelpers.setObjectDeep(state.session.accounts[accountId], tenant, false, true)
            }
        },
        [MutationTypes.setTenantProperty]: async function (state, payload) {
            let accountId: string = payload.accountId
            let property: string = payload.property
            let additionalData: string = payload.additionalData || undefined
            let value: any = payload.value
            switch (property) {
                case 'privacyLevel':
                    state.session.accounts[accountId].unifiedSecurity.privacy[property] = value
                    break;
                case 'appleId':
                    state.session.accounts[accountId].mobileSecurity.settings[property] = value
                    break;
                case 'timezone':
                    state.session.accounts[accountId].unifiedSecurity.account[property] = value
                    break;
                case 'setup':
                    state.session.accounts[accountId].unifiedSecurity.account[property] = value
                    break;
                case 'legalHold':
                    state.session.accounts[accountId].unifiedReporting.settings[property] = value
                    break;
                case 'triggerVPPAppUpdates':
                    state.session.accounts[accountId].mobileSecurity.settings[property] = value
                break;
                case 'depEnrollmentCode':
                    state.session.accounts[accountId].mobileSecurity.settings[property] = value
                break;
                case 'appleReEnrollment':
                    state.session.accounts[accountId].mobileSecurity.settings[property] = value
                break;
                case 'enterpriseEmail':
                    state.session.accounts[accountId].mobileSecurity.settings[property] = value
                break;
            }
        },
        [MutationTypes.setActiveAccountId]: function (state, accountId) {
            useUserLogStore().resetLog(true)
            if (getterHelpers.useVue()?.showLog == true) {
                getterHelpers.useVue()?.toggleLog(true)
            }
            if (state.session.activeAccountId != accountId) {

                if (products.unifiedNetwork.topologies.showDialogOnBeforeUnload) {
                    let oldAccountId = state.session.activeAccountId || ""
                    products.unifiedNetwork.topologies.view.getUnpulishedChangesDialog(oldAccountId,() => {
                        products.unifiedNetwork.topologies.showDialogOnBeforeUnload = false
                        
                        state.session.activeAccountId = accountId
                    },false,true)
                }
                else {
                    state.session.activeAccountId = accountId
                }
            }
        },
        [MutationTypes.addOrUpdateObjects]: (state, payload) => {
            const objectIdProperty = tenantHelpers.getObjectIdPropertyForObjectType(payload.objectType)
            let addedAndUpdatedItems: any[] = []
            if (payload.items.length) {
                const objectTypeStore = storeHelpers.getObjectTypeStore(payload.accountId, payload.productType, payload.objectType)

                if (objectTypeStore?.items.length) {

                    if (payload.objectType == "uscUtms") {
                        payload.items = payload.items?.filter((utm: any) => {
                            if (utm?.license == undefined) {
                                return true
                            }
                            return utm.license.licenseScopes.some((scope: string) => {
                                return scope === 'cloud:messaging'
                            })
                        })
                    }

                    payload.items.forEach((item) => {
                        if (!objectTypeStore) throw "missing objectTypeStore: " + payload.objectType
                        if (!useStore().getters.hasObject({ "accountId": payload.accountId, "productType": payload.productType, "objectType": payload.objectType, "objectId": item[objectIdProperty] })) {

                            if (payload.objectType == "enterpriseDevices") {
                                const aeImei: string = item.networkInfo?.imei || "",
                                    aeMeid: string = item.networkInfo?.meid || "",
                                    aeManufacturer: string = item.hardwareInfo?.manufacturer || "",
                                    aeBrand: string = item.hardwareInfo?.brand || "",
                                    aeSerialNumber: string = item.hardwareInfo?.serialNumber || "",
                                    aeModel: string = item.hardwareInfo?.model || ""

                                let ztDeviceIndex = objectTypeStore?.items.findIndex((existingItem: EnterpriseDevice | ZeroTouchDevice | EnterpriseDevicePlusZeroTouch) => {
                                    if ((<ZeroTouchDevice>existingItem).deviceIdentifier != undefined) {
                                        // Is zero touch device
                                        const ztDeviceId: string = (<ZeroTouchDevice>existingItem).deviceId,
                                            ztImei: string | undefined = (<ZeroTouchDevice>existingItem).deviceIdentifier.imei || undefined,
                                            ztMeid: string | undefined = (<ZeroTouchDevice>existingItem).deviceIdentifier.meid || undefined,
                                            ztManufacturer: string | undefined = (<ZeroTouchDevice>existingItem).deviceIdentifier.manufacturer || undefined,
                                            ztSerialNumber: string | undefined = (<ZeroTouchDevice>existingItem).deviceIdentifier.serialNumber || undefined,
                                            ztModel: string | undefined = (<ZeroTouchDevice>existingItem).deviceIdentifier.model || undefined,
                                            hasSameImei: boolean = ztImei ? ztImei === aeImei : false,
                                            hasSameMeid: boolean = ztMeid ? ztMeid === aeMeid : false,
                                            hasSameManufacturer: boolean = ztManufacturer ? (ztManufacturer.toLowerCase() === aeManufacturer.toLowerCase() || ztManufacturer.toLowerCase() === aeBrand.toLowerCase()) : false,
                                            hasSameSerial: boolean = ztSerialNumber ? ztSerialNumber === aeSerialNumber : false,
                                            hasSameModel: boolean = ztModel ? ztModel === aeModel : false
                                        return (hasSameImei && hasSameManufacturer) || (hasSameMeid && hasSameManufacturer) || (hasSameSerial && hasSameModel)
                                    }
                                })

                                if (ztDeviceIndex != -1) {
                                    let ztDeviceInfo: ZeroTouchDevice = jsonHelpers.copyObject(objectTypeStore?.items[ztDeviceIndex])
                                    // replace zero Touch device with enterprise device plus zero touch info
                                    objectTypeStore.items[ztDeviceIndex] = item as EnterpriseDevice
                                    objectTypeStore.items[ztDeviceIndex].zeroTouchDevice = true
                                    objectTypeStore.items[ztDeviceIndex].zeroTouchInfo = ztDeviceInfo
                                }
                                else {
                                    // add object
                                    let newLength = objectTypeStore.items.push(item)
                                    addedAndUpdatedItems.push(objectTypeStore.items[newLength - 1])
                                }
                            }
                            else {
                                // add object
                                let newLength = objectTypeStore.items.push(item)
                                addedAndUpdatedItems.push(objectTypeStore.items[newLength - 1])
                            }
                        }
                        else {
                            if (payload.objectType == "enterpriseDevices") {
                                const aeImei: string = item.networkInfo?.imei || "",
                                    aeMeid: string = item.networkInfo?.meid || "",
                                    aeManufacturer: string = item.hardwareInfo?.manufacturer || "",
                                    aeBrand: string = item.hardwareInfo?.brand || "",
                                    aeSerialNumber: string = item.hardwareInfo?.serialNumber || "",
                                    aeModel: string = item.hardwareInfo?.model || ""
                                let ztDevice: ZeroTouchDevice | EnterpriseDevicePlusZeroTouch | undefined = objectTypeStore?.items.find((existingItem: EnterpriseDevice | ZeroTouchDevice | EnterpriseDevicePlusZeroTouch) => {
                                    if ((<ZeroTouchDevice>existingItem).deviceIdentifier != undefined) {
                                        // Is zero touch device
                                        const ztDeviceId: string = (<ZeroTouchDevice>existingItem).deviceId,
                                            ztImei: string | undefined = (<ZeroTouchDevice>existingItem).deviceIdentifier.imei || undefined,
                                            ztMeid: string | undefined = (<ZeroTouchDevice>existingItem).deviceIdentifier.meid || undefined,
                                            ztManufacturer: string | undefined = (<ZeroTouchDevice>existingItem).deviceIdentifier.manufacturer || undefined,
                                            ztSerialNumber: string | undefined = (<ZeroTouchDevice>existingItem).deviceIdentifier.serialNumber || undefined,
                                            ztModel: string | undefined = (<ZeroTouchDevice>existingItem).deviceIdentifier.model || undefined,
                                            hasSameImei: boolean = ztImei ? ztImei === aeImei : false,
                                            hasSameMeid: boolean = ztMeid ? ztMeid === aeMeid : false,
                                            hasSameManufacturer: boolean = ztManufacturer ? (ztManufacturer.toLowerCase() === aeManufacturer.toLowerCase() || ztManufacturer.toLowerCase() === aeBrand.toLowerCase()) : false,
                                            hasSameSerial: boolean = ztSerialNumber ? ztSerialNumber === aeSerialNumber : false,
                                            hasSameModel: boolean = ztModel ? ztModel === aeModel : false
                                        return (hasSameImei && hasSameManufacturer) || (hasSameMeid && hasSameManufacturer) || (hasSameSerial && hasSameModel)
                                    }
                                })
                                if (ztDevice != undefined) {
                                    // replace zero Touch device with enterprise device plus zero touch info
                                    let ztDeviceInfo: ZeroTouchDevice = jsonHelpers.copyObject(ztDevice)
                                    ztDevice = item as EnterpriseDevicePlusZeroTouch
                                    ztDevice.zeroTouchDevice == true
                                    ztDevice.zeroTouchInfo = ztDeviceInfo
                                }
                                else {
                                    // update object
                                    let targetItem = useStore().getters.getObject({
                                        "accountId": payload.accountId,
                                        "productType": payload.productType,
                                        "objectType": payload.objectType,
                                        "objectId": item[objectIdProperty]
                                    })
                                    for (let property in item) {
                                        targetItem[property] = item[property]
                                    }
                                    addedAndUpdatedItems.push(targetItem)
                                }
                            }
                            else {
                                // update object
                                let targetItem = useStore().getters.getObject({
                                    "accountId": payload.accountId,
                                    "productType": payload.productType,
                                    "objectType": payload.objectType,
                                    "objectId": item[objectIdProperty]
                                })
                                for (let property in item) {
                                    targetItem[property] = item[property]
                                }
                                addedAndUpdatedItems.push(targetItem)
                            }
                        }
                    })

                    // MERGE ZT INTO AE
                    if (payload.objectType == "zeroTouchDevices") {
                        const enterpriseDevicesStore = storeHelpers.getObjectTypeStore(payload.accountId, "mobileSecurity", "enterpriseDevices")
                        payload.items.forEach((item: ZeroTouchDevice) => {
                            if (enterpriseDevicesStore?.items?.length) {
                                const ztDeviceId: string = item.deviceId,
                                    ztImei: string | undefined = item.deviceIdentifier.imei || undefined,
                                    ztMeid: string | undefined = item.deviceIdentifier.meid || undefined,
                                    ztManufacturer: string | undefined = item.deviceIdentifier.manufacturer || undefined,
                                    ztSerialNumber: string | undefined = item.deviceIdentifier.serialNumber || undefined,
                                    ztModel: string | undefined = item.deviceIdentifier.model || undefined

                                let enterpriseDevice: EnterpriseDevice | undefined = enterpriseDevicesStore?.items.find((existingItem: EnterpriseDevice | ZeroTouchDevice) => {
                                    if ((<ZeroTouchDevice>existingItem).deviceIdentifier == undefined) {
                                        // Is enterprise device
                                        const aeImei: string = (<EnterpriseDevice>existingItem).networkInfo?.imei || "",
                                            aeMeid: string = (<EnterpriseDevice>existingItem).networkInfo?.meid || "",
                                            aeManufacturer: string = (<EnterpriseDevice>existingItem).hardwareInfo?.manufacturer || "",
                                            aeBrand: string = (<EnterpriseDevice>existingItem).hardwareInfo?.brand || "",
                                            aeSerialNumber: string = (<EnterpriseDevice>existingItem).hardwareInfo?.serialNumber || "",
                                            aeModel: string = (<EnterpriseDevice>existingItem).hardwareInfo?.model || "",
                                            hasSameImei: boolean = ztImei ? ztImei === aeImei : false,
                                            hasSameMeid: boolean = ztMeid ? ztMeid === aeMeid : false,
                                            hasSameManufacturer: boolean = ztManufacturer ? (ztManufacturer.toLowerCase() === aeManufacturer.toLowerCase() || ztManufacturer.toLowerCase() === aeBrand.toLowerCase()) : false,
                                            hasSameSerial: boolean = ztSerialNumber ? ztSerialNumber === aeSerialNumber : false,
                                            hasSameModel: boolean = ztModel ? ztModel === aeModel : false

                                        return (hasSameImei && hasSameManufacturer) || (hasSameMeid && hasSameManufacturer) || (hasSameSerial && hasSameModel)
                                    }
                                })

                                if (enterpriseDevice != undefined) {
                                    // found enterprise device
                                    (<EnterpriseDevicePlusZeroTouch>enterpriseDevice).zeroTouchDevice = true;
                                    (<EnterpriseDevicePlusZeroTouch>enterpriseDevice).zeroTouchInfo = item;
                                }
                                else {
                                    // found no enterprise device, search for existing zt info
                                    if (!useStore().getters.hasObject({ "accountId": payload.accountId, "productType": "mobileSecurity", "objectType": "enterpriseDevices", "objectId": item.deviceId, "objectIdProperty": "deviceId" })) {
                                        // add object
                                        enterpriseDevicesStore.items.push(item)
                                    }
                                    else {
                                        // update object
                                        let targetItem = useStore().getters.getObject({
                                            "accountId": payload.accountId,
                                            "productType": "mobileSecurity",
                                            "objectType": "enterpriseDevices",
                                            "objectId": item.deviceId,
                                            "property": "deviceId"
                                        })
                                        for (let property in item) {
                                            targetItem[property] = item[property as keyof ZeroTouchDevice]
                                        }
                                        addedAndUpdatedItems.push(targetItem)
                                    }
                                }

                            }
                            else {
                                enterpriseDevicesStore?.items.push(item)
                                addedAndUpdatedItems.push(item)
                            }
                        })
                    }
                }
                else if (objectTypeStore?.items !== undefined) {
                    // store is empty
                    objectTypeStore.items = payload.items
                }
            }
            if (useVue().$refs.itemlist) {
                useVue().$refs.itemlist.refreshCounter++
            }
            return addedAndUpdatedItems
        },
        [MutationTypes.iosMerge]: (state, accountId) => {
            const deviceStore = useStore().state.session.accounts[accountId].mobileSecurity.devices;
            devLog.log('Vuex', 'devices were added, scanning for duplicates', deviceStore, 'default', 4);
            let depDevices: any[] = [];
            let mergedDepDevices: any[] = [];
            let defaultDevices: any[] = [];
            (<Array<any>>deviceStore.items).forEach(function (device, i) {
                if (device.depOnly) {
                    depDevices.push(device);
                }
                else if (device.depDevice) {
                    mergedDepDevices.push(device);
                }
                else {
                    defaultDevices.push(device);
                }
            });
            // loop through mergedDepDevices and look for duplicates in defaultDevices
            mergedDepDevices.forEach(function (mergedDepDevice) {
                var mergedDepDeviceId = mergedDepDevice.deviceId;
                // bail out duplicates
                defaultDevices = defaultDevices.filter(function (defaultDevice) {
                    return mergedDepDeviceId != defaultDevice.deviceId;
                });
            });
            // concat device arrays
            var newDeviceList = depDevices.concat(mergedDepDevices, defaultDevices);
            // set devices in store
            deviceStore.items = newDeviceList
        },
        [MutationTypes.setObjectInfos]: (state, payload) => {
            if (payload.products != undefined) {

                let accountId = String(payload.accountId) || state.session.activeAccountId || ""
                if (!accountId) throw "Missing accountId"
                let products = payload.products
                devLog.log('Vuex', 'Setting Objects', products, 'default', 4)

                for (let productType in products) {
                    let objectTypes = products[productType]
                    for (let objectType in objectTypes) {
                        let items = objectTypes[objectType]

                        if(objectType == "uscUtms") {
                            items = items?.filter((utm: any) => {
                                if (utm?.license == undefined) {
                                    return true
                                }
                                return utm.license?.licenseScopes?.some((scope: string) => { return scope === 'cloud:messaging' })
                            })
                        }

                        const objectTypeStore = storeHelpers.getObjectTypeStore(payload.accountId, productType as ProductType, objectType)
                        const genericObjectStore = objectStores[objectType as keyof typeof objectStores]

                        if (!objectTypeStore) throw "missing objectTypestore " + objectType

                        if (genericObjectStore) {
                            items.forEach((item: any) => {
                                item = genericObjectStore.addGenericObjectInfos(accountId, item)
                            });
                        }

                        // Exception for tags
                        if (productType == 'tags') {
                            if (objectTypeStore.items) {
                                items = items.concat(objectTypeStore.items)
                            }
                            let uniqueTags: Array<{ "id": string, "text": string }> = items.filter(arrayHelpers.onlyUniqueFilter).map(function (tag: string) {
                                return { "id": tag, "text": tag }
                            })
                            items = uniqueTags
                        }


                        let idProperty = tenantHelpers.getObjectIdPropertyForObjectType(objectType)
                        if (objectTypeStore?.items == undefined || objectTypeStore?.items?.length == 0) {
                            devLog.log('Vuex', 'ObjectType ' + objectType + ' has no items. Setting items.', items, 'default', 4)
                            objectTypeStore.items = items
                        }
                        else {
                            devLog.log('Vuex', 'ObjectType ' + objectType + ' has items. Replacing/filling up items...', items, 'default', 4)
                            if (objectType == 'vpplicenses' || payload.fullRefresh) {
                                devLog.log('Vuex', 'FullRefresh is set to true. Replacing items...', items, 'default', 4)
                                objectTypeStore.items = []
                                objectTypeStore.items = items
                            }
                            else {
                                items.forEach((newItem: any) => {
                                    let newItemId: string = newItem[idProperty]
                                    let newDeviceSerial: string | undefined = objectType == 'devices' ? newItem.info?.serial || undefined : undefined
                                    let foundOldItem: boolean = false

                                    if (["vpns"].indexOf(objectType) != -1) {
                                        newItem.isCheckable = true
                                    }
                                    if (!accountId) throw "Missing accountId"
                                    if (!objectTypeStore) throw "missing objectTypestore " + objectType


                                    let existingItem = objectTypeStore.items.find((oldItem: any) => {
                                        let oldItemId: string = oldItem[idProperty]
                                        let oldDeviceSerial: string | undefined = oldItem.info?.serial || undefined
                                        return (
                                            newItem.depOnly && oldDeviceSerial != undefined && oldDeviceSerial == newDeviceSerial && oldItem.signedIn)
                                            || (oldItem.depOnly == true && oldDeviceSerial != undefined && oldDeviceSerial == newDeviceSerial && newItem.signedIn)
                                            || (!foundOldItem && oldItemId != undefined && oldItemId == newItemId)

                                    })

                                    if (existingItem != undefined) {
                                        let existingItemId: string = existingItem[idProperty]
                                        let oldDeviceSerial: string | undefined = existingItem.info?.serial || undefined
                                        let foundexistingItem = false
                                        if (newItem.depOnly && oldDeviceSerial != undefined && oldDeviceSerial == newDeviceSerial && existingItem.signedIn) {
                                            foundexistingItem = true
                                            devLog.log('Vuex', 'Merging DEP-Device infos into device', { "item": existingItem, "depInfos": newItem }, 'default', 4)
                                            existingItem.isDepDevice = true
                                            existingItem.depDeviceInfo = newItem.depDeviceInfo
                                        }
                                        else if (existingItem.depOnly == true && oldDeviceSerial != undefined && oldDeviceSerial == newDeviceSerial && newItem.signedIn) {
                                            foundexistingItem = true
                                            devLog.log('Vuex', 'Merging device infos into DEP-Device', { "depItem": existingItem, "Infos": newItem }, 'default', 4)
                                            existingItem.isDepDevice = true
                                            delete existingItem.depOnly
                                            newItem.depDeviceInfo = existingItem.depDeviceInfo
                                            jsonHelpers.setObjectDeep(existingItem, newItem, false, true)
                                        }

                                        if (!foundexistingItem && existingItemId != undefined && existingItemId == newItemId) {
                                            devLog.log('Vuex', 'Updating old item properties of type ' + objectType + ' with values from new one', newItem, 'default', 4)
                                            for (let property in newItem) {
                                                existingItem[property] = newItem[property]
                                            }
                                        }

                                    }
                                    else {
                                        devLog.log('Vuex', 'Item of type ' + objectType + ' does not exist, adding item to itemlist.', newItem, 'default', 4)
                                        objectTypeStore.items.push(newItem)
                                    }

                                });
                            }
                        }


                        let thisCount = objectTypeStore.count || 0

                        if (objectTypeStore.items?.length > thisCount) {
                            objectTypeStore.count = objectTypeStore.items?.length
                            useVue().$refs.navigation?.doRefresh()
                            if (useVue().$refs.itemlist != undefined) {
                                useVue().$refs.itemlist.refreshCounter++
                            }
                        }
                        objectTypeStore.lastUpdate = parseInt(moment().unix().toString())
                    }
                }
                if (payload.products.mobileSecurity?.devices != undefined || payload.products.mobileSecurity?.depDevices != undefined) {
                    useStore().commit(MutationTypes.iosMerge, payload.accountId);
                }
            }
            if (useVue().$refs.itemlist) {
                useVue().$refs.itemlist.refreshCounter++
            }
        },
        [MutationTypes.setObjectProperty]: (state, payload) => {
            if (payload) {
                let objectId = payload.objectId
                let productType = payload.productType
                let objectType = payload.objectType
                let objectPropertyPath: string[] = payload.objectPropertyPath || []
                let property: string = payload.property
                let value: any = payload.value
                let object: any = undefined
                let objectTypeStore = storeHelpers.getObjectTypeStore(payload.accountId, productType as ProductType, objectType)
                const genericObjectStore = objectStores[objectType as keyof typeof objectStores]

                try {
                    if (genericObjectStore instanceof GenericObjectStore) {
                        if (!objectTypeStore) throw "missing generic objectTypestore " + objectType
                        //@ts-ignore
                        object = genericObjectStore.getObjectFromStore(payload.accountId, payload.objectId) as any
                    }

                    if (!object) {
                        objectType = function () {
                            if (['iosDevices'].indexOf(payload.objectType) != -1) {
                                return "devices"
                            }
                            else if (['androidProfiles', 'iosProfiles'].indexOf(payload.objectType) != -1) {
                                return "profiles"
                            }
                            return payload.objectType
                        }()
                        let objectIdProperty: string = tenantHelpers.getObjectIdPropertyForObjectType(objectType)
                        objectTypeStore = storeHelpers.getObjectTypeStore(payload.accountId, productType as ProductType, objectType)
                        if (!objectTypeStore) throw "missing objectTypestore " + objectType
                        object = objectTypeStore.items.find((object: any) => {
                            return object[objectIdProperty] == objectId
                        })
                    }

                    if (object) {
                        let objectBase = object
                        if (objectPropertyPath.length) {
                            for (let i: number = 0; objectPropertyPath.length > i; i++) {
                                let subProperty = objectPropertyPath[i]
                                if (object[subProperty] == undefined) {
                                    object[subProperty] = {}
                                }
                                object = object[subProperty]
                            }
                        }
                        object[property] = property == "tags" && Array.isArray(value) ? value.sort((tagA: string, tagB: string) => { return tagA.toLowerCase() > tagB.toLowerCase() ? 1 : -1 }) : value
                        
                        if (objectType == "devices" && payload.property == "policyName") {
                            objectBase.profile.profileName = value.split('/')[3]
                            objectBase.profile.profileId = "emm_" + value.split('/')[3]
                            objectBase.profile.status = "PENDING"
                        }
                    }
                }
                catch (e: any) {
                    console.error(e)
                }
            }
        },
        [MutationTypes.deleteObject]: (state, payload) => {
            if (payload) {
                let accountId = payload.accountId
                let productType = payload.productType
                let objectType = payload.objectType
                let objectIdProperty = payload.objectIdProperty || tenantHelpers.getObjectIdPropertyForObjectType(payload.objectType)
                let objectId = payload.objectId
                let deviceType = "iosDevices"




                const objectTypeStore = storeHelpers.getObjectTypeStore(payload.accountId, productType as ProductType, objectType)
                if (!objectTypeStore) throw "missing objectTypestore " + objectType
                let objects: any = objectTypeStore.items
                let objectIndex: number = -1
                for (let i: number = 0; objects.length > i; i++) {
                    let object: any = objects[i]
                    if (object[objectIdProperty] == objectId) {
                        objectIndex = i
                        break;
                    }
                }
                if (objectType == 'zeroTouchCustomers') {
                    objects = objects.filter((item: any) => {
                        return item.customerId != objectId
                    })
                    objectTypeStore.items = objects
                }
                else if (objectIndex != -1) {
                    objects.splice(objectIndex,1)
                    let oldCount = objectTypeStore.count
                    if (oldCount != undefined && oldCount != 0) objectTypeStore.count = oldCount - 1



                    if (objectType == "profiles" && useRouterStore()?.getActivePage == "androidProfiles") {
                        let oldAndroidCount = objectTypeStore.androidCount
                        if (oldAndroidCount != undefined && oldAndroidCount != 0) objectTypeStore.androidCount = oldAndroidCount - 1
                    }
                    else if (objectType == "devices" && deviceType == "iosDevices" || objectType == "profiles" && useRouterStore()?.getActivePage == "iosProfiles") {
                        let oldIosCount = objectTypeStore.iosCount
                        if (oldIosCount != undefined && oldIosCount != 0) objectTypeStore.iosCount = oldIosCount - 1
                    }
                    useVue().$refs.navigation?.doRefresh()
                    if (useVue().$refs.itemlist != undefined) {
                        useVue().$refs.itemlist.refreshCounter++
                    }
                }
            }
        },
        [MutationTypes.addSubscription]: function (state, payload: any) {
            let accountId: string = payload.accountId
            devLog.log('Vuex', 'Adding subscription for tenant', accountId, 'default', 3)
            websocketHandler.closeConnection()
            websocketHandler.connect(accountId)
        },
        [MutationTypes.killSubscription]: function (state, payload: any) {
            let accountId: string = payload.accountId
            devLog.log('Vuex', 'Removing subscription for tenant', accountId, 'default', 3)
            websocketHandler.closeConnection()
        },
        [MutationTypes.addSubscriptionHook]: function (state, payload) {
            let hookKey = payload.hookKey
            let hookFunction = payload.hookFunction
            websocketHandler.addOnMessageHook(hookKey,hookFunction)
        },
        [MutationTypes.deleteSubscriptionHook]: function (state, payload) {
            let hookKey: any = payload.hookKey
            websocketHandler.removeOnMessageHook(hookKey)
        },
        [MutationTypes.addNotification]: (state, payload) => {
            let accountId = payload.accountId
            let notificationIndex = state.session.accounts[accountId].notifications.findIndex((notification: any) => {
                return notification.id == payload.id
            })
            let hasNotification = notificationIndex != -1
            if (!hasNotification) {
                state.session.accounts[accountId].notifications.unshift(payload)
            }
            else {
                state.session.accounts[accountId].notifications[notificationIndex] = payload
            }
            // Sort by timestamp
            state.session.accounts[accountId].notifications.sort((notificationA: any, notificationB: any) => {
                return notificationA.timestamp > notificationB.timestamp ? -1 : 1
            })

        },
        [MutationTypes.removeNotification]: (state, payload) => {
            state.session.accounts[payload.accountId].notifications = state.session.accounts[payload.accountId].notifications.filter((notification: FrontendNotification) => {
                return notification.id != payload.objectId
            })
        },
        [MutationTypes.addModal]: (state, payload) => {
            try {
                let accountId: string = payload.accountId
                let isSuspended = false
                if (payload.id != undefined && cookies.dialogSuspender.suspendedDialogsFlagMap.indexOf(payload.id) != -1) {
                    isSuspended = cookies.dialogSuspender.isDialogSuspended(payload.id)
                }
                if(!isSuspended) {
                    if (accountId) {
                        state.session.accounts[accountId].modals.push(payload)
                    }
                    else {
                        state.modals.push(payload)
                    }
                }
            }
            catch (e: any) {
                console.error(e)
            }
            return 1
        },
        [MutationTypes.removeModal]: (state, payload) => {
            let accountId: string = payload.accountId
            if (payload.index == undefined) {
                payload.index = 0
            }
            if (accountId) {
                state.session.accounts[accountId].modals.splice(payload.index, 1)
            }
            else {
                state.modals.splice(payload.index, 1)
            }
        },
        [MutationTypes.setClipboard]: (state, payload) => {
            let objectType: any = payload.objectType
            let objects: any = JSON.parse(JSON.stringify(payload.objects))
            devLog.log('Vuex', 'Copying:' + objectType + ' to clipboard', objects, 'default', 1)
            state.clipBoard[objectType as keyof typeof state.clipBoard] = objects
        },
        [MutationTypes.addOnClickHandler]: (state, payload) => {
            if (payload.id && payload.ref && payload.method) {
                if (state.template.onClick[payload.ref] == undefined) {
                    state.template.onClick[payload.ref] = {}
                }
                state.template.onClick[payload.ref][payload.id] = payload.method
            }
        },
        [MutationTypes.setDarkmode]: (state, darkmode) => {
            state.browserSettings.darkmode = darkmode
            cookies.set("darkmode", darkmode, 365)
        },
        [MutationTypes.setWaitForSessionToSetActiveAccountId]: (state, value) => {
            state.session.waitForSessionToSetActiveAccountId = value
        }

    },
    "actions": <Actions>{
        [ActionTypes.switchAccount]: async function (context, requestedAccountId) {
            if (context.state.session.initialized) {
                requestedAccountId = tenantHelpers.getAccountId(requestedAccountId)
                let currentAccountId: any = context.state.session.activeAccountId
                let hasAccountId: boolean = context.state.session.accounts.hasOwnProperty(requestedAccountId)
                if (hasAccountId) {
                    if (requestedAccountId && requestedAccountId != currentAccountId) {
                        if (currentAccountId == undefined) {
                            devLog.log('Vuex Session', 'Setting activeAccountId to ' + requestedAccountId, undefined, 'default', 3)
                            context.commit(MutationTypes.setActiveAccountId, requestedAccountId)
                        }
                        else {
                            context.commit(MutationTypes.addSubscription, { "accountId": requestedAccountId })
                            devLog.log('Vuex', 'Updating activeAccountId from ' + currentAccountId + ' to ' + requestedAccountId, undefined, 'default', 3)
                            context.commit(MutationTypes.setActiveAccountId, requestedAccountId)
                            if (useRouterStore().getObjectId != undefined) {
                                router.navigate('#show-tenant-' + requestedAccountId + '.sms-dashboard')
                            }
                        }
                        context.commit(MutationTypes.addSubscription, { "accountId": requestedAccountId })
                        context.dispatch(ActionTypes.updateAccount, requestedAccountId)
                    }
                }
                else {
                    console.log('requested account id does not exist')
                    router.navigate('dashboard')
                }
                context.commit(MutationTypes.setWaitForSessionToSetActiveAccountId, false)
            }
            else {
                context.commit(MutationTypes.setWaitForSessionToSetActiveAccountId,true)
                devLog.log('Vuex', 'Session not initialized. Waiting for session to set activeAccountId', undefined, 'default', 3)
                setTimeout(() => {
                    context.dispatch(ActionTypes.switchAccount, requestedAccountId)
                }, 900)
            }
        },
        [ActionTypes.updateAccount]: async function (context, accountId) {
            let zeroTouch: any;
            if (context.state.session.initialized) {

                // GET QUERY PROPERTIES
                let queryTenantProperties: any = ["created", "accountRestrictions", "active", "timezone", "setup", 'legalHold']
                let hasMobileSecurityLicense: boolean = licenseHelpers.hasLicense(accountId, 'Mobile Security')
                let hasMDMLicense: boolean = licenseHelpers.hasLicense(accountId, 'MDM')
                if (hasMobileSecurityLicense || hasMDMLicense) {
                    queryTenantProperties = queryTenantProperties.concat(["EID", "android", "enrollmentAllowed", "enrollmentURL", "ios", "maxDevices", "mdmLicenseExceeded", "triggerVPPAppUpdates"])
                }
                devLog.log('Vuex', 'Fetching tenant information', accountId, 'default', 3)
                let tenant: any = await queries.unifiedSecurity.getTenant(accountId, queryTenantProperties)
                let tenantProperties: any = await queries.unifiedSecurity.getTenantProperties(accountId)
                tenant = jsonHelpers.merge(tenant, tenantProperties, true)
                if (tenant[accountId].unifiedSecurity.account.hasOwnProperty("setup")) {
                    if (tenant[accountId].unifiedSecurity.account.setup != "1") {
                        dialogs.unifiedSecurity.setupTenant(accountId)
                    }
                }

                context.state.session.accounts[accountId].mobileSecurity.settings = tenant[accountId].mobileSecurity.settings

                // GET NOTIFICATIONS
                if ((licenseHelpers.hasLicense(accountId, 'Mobile Security', "valid") || licenseHelpers.hasLicense(accountId, 'MDM', "valid"))) {
                    // CHECK FOR APPLE PUSH CERT
                    let enterprise: any = undefined,
                        apns: any = undefined,
                        apnsStatus: string = "",
                        notificationTitle: string = "",
                        notificationText: string = "",
                        dialogText: string = ""
                    apns = jsonHelpers.getObjectProperty(tenant[accountId], "mobileSecurity.settings.ios.apns")
                    if (apns != undefined) {
                        if (apns.expired === true && apns.notAfter == "") {
                            apnsStatus = "not_set_up"
                            notificationTitle = T('Missing Apple Push Certificate')
                            notificationText = T("Click here to add an Apple Push Certificate") + "..."
                        }
                        else if (apns.matchesPrivateKey == false) {
                            apnsStatus = "wrong_certificate"
                            notificationTitle = T('Wrong Apple Push Certificate')
                            notificationText = T("It seems that you haven\'t uploaded the right Apple Push Cetificate. Click here to upload a new one") + "..."
                            dialogText = T("It seems that you have not uploaded the correct Apple Push Certificate.")
                        }
                        else if (apns.expired == true) {
                            apnsStatus = "expired"
                            notificationTitle = T('Apple Push Certificate needs to be extended')
                            notificationText = T("Click here to update your Apple Push Certificate") + "..."
                            dialogText = T('Your Apple Push Certificate needs to be extended.')
                        }
                        else if (apns.daysLeft <= 14) {
                            apnsStatus = "expires_soon"
                            notificationTitle = T('Apple Push Certificate needs to be extended')
                            notificationText = T("Your Apple Push Certificate is expiring in less than 14 days. Please click here to update your Apple Push Certificate") + "..."
                            dialogText = T('Your Apple Push Certificate will expire in less than 14 days. Please extend your Apple Push Certificate.') + " " + T("IMPORTANT: Please use the \"Renew\" function in Apple\\'s Push Certificate Portal to extend your existing certificate.")
                        }
                        else {
                            apnsStatus = "set_up"
                        }
                        let notification: any = undefined
                        if (apnsStatus !== 'set_up' && apnsStatus !== 'not_set_up') {

                            frontendNotifications.addNotification(accountId, {
                                "accountId": accountId,
                                "id": "apnsError",
                                "content": {
                                    "title": {
                                        "text": notificationTitle,
                                        "icon": "fal fa-exclamation-triangle",
                                    },
                                    "body": {
                                        "content": notificationText
                                    },
                                },
                                "onClick": function () {
                                    router.navigate('#show-tenant-' + accountId + '.sms-mobile-security-settings')
                                },
                                "highlightNew": true,
                                "state": "new",
                                "product": "Mobile Security"
                            })
                        }
                    }

                    // CHECK FOR ENTERPRISE
                    if (tenantHelpers.isFunctionalityActivated('enterprise')) {
                        enterprise = jsonHelpers.getObjectProperty(tenant, accountId + ".mobileSecurity.settings.android.enterprise")
                        if (tenantHelpers.isFunctionalityActivated('enterprise')) {
                            try {
                                let enterpriseInfo: any = await queries.mobileSecurity.getEnterpriseInfo(accountId)
                                if (enterpriseInfo.code && enterpriseInfo.code >= 500 && enterpriseInfo.message) {
                                    dialogs.mobileSecurity.renderEnterpriseErrorDialog(enterpriseInfo.message)
                                }

                                if (!enterpriseInfo.enabled && !enterpriseInfo.name) {
                                    enterpriseInfo.enabled = false
                                }


                                enterprise = jsonHelpers.merge(enterprise, enterpriseInfo)
                            }
                            catch (e: any) {
                                console.error(e)
                            }
                        }
                    }

                    if (apnsStatus == "not_set_up" && enterprise?.enabled === false && (licenseHelpers.hasLicense(accountId, 'Mobile Security', "valid") || licenseHelpers.hasLicense(accountId, 'MDM', "valid"))) {
                        context.dispatch(ActionTypes.addModal, {
                            "id": "noMobSecSetup",
                            "accountId": accountId,
                            "abortable": true,
                            "content": {
                                "title": {
                                    "text": T('Set up Android Enterprise or your Apple push certificate'),
                                    "icon": "fal fa-exclamation-triangle",
                                },
                                "body": {
                                    "use": true,
                                    "content": undefined,
                                    "component": 'confirm-dialog',
                                    "properties": {
                                        "enterString": undefined,
                                        "content": '<p>' + T("Go to Mobile Security settings in order to set up either one of them.") + '</p>',
                                    }
                                },
                            },
                            "buttons": [
                                {
                                    loading: false,
                                    onClick: function () {
                                        useVue().$refs.modals.removeModal()
                                    },
                                    icon: "fal fa-times",
                                    text: T('Close'),
                                    align: "left",
                                    disabled: false
                                },
                                {
                                    loading: false,
                                    onClick: async function () {
                                        useVue().$refs.modals.removeModal()
                                        router.navigate('#show-tenant-' + accountId + '.sms-mobile-security-settings')
                                    },
                                    icon: "fal fa-sync",
                                    text: T('Setup'),
                                    align: "right",
                                    disabled: false
                                }
                            ]
                        })
                    }
                    // CHECK FOR ZERO TOUCH
                    if (tenantHelpers.isFunctionalityActivated('zeroTouch')) {
                        zeroTouch = jsonHelpers.getObjectProperty(tenant, accountId + ".mobileSecurity.settings.android.zeroTouch")                       
                        try {
                            let zeroTouchInfo: any = await queries.mobileSecurity.getZeroTouchInfo(accountId)
                            if (zeroTouch && zeroTouchInfo) {
                                tenant[accountId].mobileSecurity.settings.android.zeroTouch = jsonHelpers.merge(zeroTouch, zeroTouchInfo)
                            }
                        }
                        catch (e: any) {
                            console.error(e)
                        }
                        if (zeroTouch.enabled) {
                            if(config.canUseNewObjectType('zeroTouchCustomers')) {
                                await products.mobileSecurity.zeroTouchCustomers.queries.getObjectsFromApi(accountId,undefined,undefined,true)
                            }
                            else {
                                await context.dispatch(ActionTypes.getObjectInfos, {
                                    'accountId': accountId,
                                    'objectTypes': ['zeroTouchCustomers']
                                })    
                            }
                        }
                        
                    }

                    if (apnsStatus == "set_up") {
                        // CHECK FOR DEP
                        let dep: any = jsonHelpers.getObjectProperty(tenant[accountId], "mobileSecurity.settings.ios.dep")
                        if (dep?.enabled === true && moment(dep.expiry).isAfter(moment())) {
                            // DEP IS ENABLED AND WORKY
                        }
                        else if (moment(dep.expiry).isBefore(moment())) {
                            // DEP IS ENABLED BUT EXPIRED
                            context.dispatch(ActionTypes.addModal, {
                                "id": "depExpired",
                                "accountId": accountId,
                                "abortable": true,
                                "content": {
                                    "title": {
                                        "text": T('Apple DEP-Token expired'),
                                        "icon": "fal fa-exclamation-triangle",
                                    },
                                    "body": {
                                        "use": true,
                                        "content": undefined,
                                        "component": 'confirm-dialog',
                                        "properties": {
                                            "enterString": undefined,
                                            "content": '<p>' + T("Go to Mobile Security settings in order to configure Apple-DEP.") + '</p>',
                                        }
                                    },
                                },
                                "buttons": [
                                    {
                                        loading: false,
                                        onClick: function () {
                                            useVue().$refs.modals.removeModal()
                                        },
                                        icon: "fal fa-times",
                                        text: T('Close'),
                                        align: "left",
                                        disabled: false
                                    },
                                    {
                                        loading: false,
                                        onClick: async function () {
                                            useVue().$refs.modals.removeModal()
                                            router.navigate('#show-tenant-' + accountId + '.sms-mobile-security-settings')
                                        },
                                        icon: "fal fa-sync",
                                        text: T('Configure'),
                                        align: "right",
                                        disabled: false
                                    }
                                ]
                            })
                        }
                        // CHECK FOR VPP
                        let vpp: any = jsonHelpers.getObjectProperty(tenant[accountId], "mobileSecurity.settings.ios.vpp")
                        if (vpp?.enabled && moment(vpp.expiry).isAfter(moment())) {
                            if(config.canUseNewObjectType("vppassets")) {
                                let configInfo = await vppApi.getTokenInfo(accountId)
                                /*
                                let clientConfig = await vppApi.configureTokenToV2(accountId)
                                if (clientConfig.countryISO2ACode) {
                                    tenant[accountId].mobileSecurity.settings.ios.vpp.countryCode = clientConfig.countryISO2ACode
                                }
                                else {
                                    tenant[accountId].mobileSecurity.settings.ios.vpp.countryCode = "DE"
                                }
                                */
                                tenant[accountId].mobileSecurity.settings.ios.vpp.countryCode = "DE"
                            }
                            else {
                                let vppClientConfig: any = await queries.mobileSecurity.vpp.vppClientConfigSrv(accountId + '.sms')
                                tenant[accountId].mobileSecurity.settings.ios.vpp.countryCode = vppClientConfig.countryCode
                            }
                        }
                        else if (moment(vpp.expiry).isBefore(moment())) {
                            // VPP IS ENABLED BUT EXPIRED
                            context.dispatch(ActionTypes.addModal, {
                                "id": "vppExpired",
                                "accountId": accountId,
                                "abortable": true,
                                "content": {
                                    "title": {
                                        "text": T('Apple VPP-Token expired'),
                                        "icon": "fal fa-exclamation-triangle",
                                    },
                                    "body": {
                                        "use": true,
                                        "content": undefined,
                                        "component": 'confirm-dialog',
                                        "properties": {
                                            "enterString": undefined,
                                            "content": '<p>' + T("Go to Mobile Security settings in order to configure Apple-VPP.") + '</p>',
                                        }
                                    },
                                },
                                "buttons": [
                                    {
                                        loading: false,
                                        onClick: function () {
                                            useVue().$refs.modals.removeModal()
                                        },
                                        icon: "fal fa-times",
                                        text: T('Close'),
                                        align: "left",
                                        disabled: false
                                    },
                                    {
                                        loading: false,
                                        onClick: async function () {
                                            useVue().$refs.modals.removeModal()
                                            router.navigate('#show-tenant-' + accountId + '.sms-mobile-security-settings')
                                        },
                                        icon: "fal fa-sync",
                                        text: T('Configure'),
                                        align: "right",
                                        disabled: false
                                    }
                                ]
                            })
                        }
                    }
                }


                try {
                    tenant[accountId].unifiedSecurity.account.rspUserConnectable = context.state.session.isReseller ? (await queries.unifiedSecurity.checkRSPConnect(accountId))?.connectable || false : false

                    if (tenant[accountId].unifiedSecurity.account.rspUserConnectable) {
                        context.dispatch(ActionTypes.addModal, {
                            "id": "rspImport",
                            "accountId": accountId,
                            "abortable": true,
                            "content": {
                                "title": {
                                    "text": T('RSP-Account can be connected'),
                                    "icon": "fal fa-user",
                                },
                                "body": {
                                    "use": true,
                                    "content": undefined,
                                    "component": 'confirm-dialog',
                                    "properties": {
                                        "enterString": undefined,
                                        "content": `
										<p>
											${T('Your useraccount from the Securepoint Reseller Portal can now be connected to Securepoint Unified Security.')}
										</p>
										<p>
											${T('Connecting your RSP-Account includes the creation of a user and a role called "USC-Administrator", that will be assigned to your user.')}
										</p>
										` ,
                                    }
                                },
                            },
                            "buttons": [
                                {
                                    loading: false,
                                    onClick: function () {
                                        useVue().$refs.modals.removeModal()
                                    },
                                    icon: "fal fa-times",
                                    text: T('Close'),
                                    align: "left",
                                    disabled: false
                                },
                                {
                                    loading: false,
                                    onClick: async function () {
                                        await queries.unifiedSecurity.importRSPUser(accountId)
                                        context.commit(MutationTypes.setTenants, {
                                            [accountId]: {
                                                unifiedSecurity: {
                                                    account: {
                                                        rspUserConnectable: false
                                                    }
                                                }
                                            }
                                        })
                                        const sessionInfo = await queries.unifiedSecurity.checkAuth()
                                        await accounts.getAccounts(sessionInfo)
                                        await context.dispatch(ActionTypes.updateAccount, accountId)
                                        useVue().$refs.modals.removeModal()
                                        router.navigate('#show-tenant-' + accountId + '.sms-users-dashboard')
                                    },
                                    icon: "fal fa-link",
                                    text: T('Connect now'),
                                    align: "right",
                                    disabled: false
                                }
                            ]
                        })
                    }
                }
                catch (e: any) {
                    tenant[accountId].unifiedSecurity.account.rspUserConnectable = false
                }
                try {
                    await queries.unifiedSecurityConsole.sync(accountId)
                }
                catch(e) {
                    
                }

                try {
                    // <unc>
                    const pageAccessedByReload = (
                        (window.performance.navigation && window.performance.navigation.type === 1) ||
                          window.performance
                            .getEntriesByType('navigation')
                            .map((nav) => {
                                return nav.entryType
                            })
                            .includes('reload')
                    );
                    
                    let routerStore = useRouterStore()
                    if (!pageAccessedByReload && routerStore.activePage != "unifiedNetworkConfiguration" && licenseHelpers.hasLicense(accountId, "unifiedSecurityConsole") && await products.unifiedNetwork.topologies.view.getPublishInfoFromApi(accountId)) {
                        let topologies = products.unifiedNetwork.topologies.useStore?.().getObjectStoreObjects(accountId)
                        if (Array.isArray(topologies)) {
                            products.unifiedNetwork.topologies.view.getUnpulishedChangesDialog(accountId)
                        }
                    }
                    // </unc>
                }
                catch (e) {

                }

                tenant[accountId].initialized = true
                context.commit(MutationTypes.setTenants, tenant)
                useVue()?.$refs?.navigation?.doRefresh()
                context.dispatch(ActionTypes.getNotifications, accountId)
            }
            else {
                setTimeout(function () {
                    context.dispatch(ActionTypes.updateAccount, accountId)
                }, 500)
            }
        },
        [ActionTypes.getNotifications]: async function (context: any, accountId: string) {

            let allNotifications: any[] = []

            let unspecificResult: any
            let mobileSecurityResult: any
            let unifiedReportingResult: any
            let unifiedSecurityConsoleResult: any

            try {
                unspecificResult = await queries.unifiedSecurity.getTenantNotifications(accountId, "UNSPECIFIC")
            }
            catch (e: any) {
                console.error(e)
            }

            if (licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"], 'valid')) {
                try {
                    mobileSecurityResult = await queries.unifiedSecurity.getTenantNotifications(accountId, "MOBILE_SECURITY")
                }
                catch (e: any) {
                    console.error(e)
                }
            }
            if (licenseHelpers.hasOneOfLicenses(accountId, ["laas"], 'valid')) {
                try {
                    unifiedReportingResult = await queries.unifiedSecurity.getTenantNotifications(accountId, "UNIFIED_SECURITY_REPORT")
                }
                catch (e: any) {
                    console.error(e)
                }
            }
            if (licenseHelpers.hasOneOfLicenses(accountId, ["unifiedSecurityConsole"], 'valid')) {
                try {
                    unifiedSecurityConsoleResult = await queries.unifiedSecurity.getTenantNotifications(accountId, "UNIFIED_SECURITY_CONSOLE")
                }
                catch (e: any) {
                    console.error(e)
                }
            }

            if (unspecificResult && unspecificResult.count > 0) {
                allNotifications = unspecificResult.entries
            }
            if (mobileSecurityResult && mobileSecurityResult.count > 0) {
                allNotifications = allNotifications.concat(mobileSecurityResult.entries)
            }
            if (unifiedReportingResult && unifiedReportingResult.count > 0) {
                allNotifications = allNotifications.concat(unifiedReportingResult.entries)
            }
            if (unifiedSecurityConsoleResult && unifiedSecurityConsoleResult.count > 0) {
                allNotifications = allNotifications.concat(unifiedSecurityConsoleResult.entries)
            }

            allNotifications.sort((notificationA: any, notificationB: any) => {
                return notificationA.timestamp > notificationB.timestamp ? -1 : 1
            })

            let severityToIcon: any = {
                "INFO": "fal fa-info",
                "WARNING": "fal fa-bell",
                "ERROR": "fal fa-exclamation"
            }

            allNotifications.forEach((notification: any) => {


                if (notification.state.toLowerCase() != "deleted") {
                    frontendNotifications.addNotification(accountId, {
                        "accountId": accountId,
                        "id": notification.id,
                        "content": {
                            "title": {
                                "text": T(notification.titel || 'Notification'),
                                "icon": severityToIcon[notification.severity] || "fal fa-info"
                            },
                            "body": {
                                "content": T(notification.message)
                            }
                        },
                        "onClick": notification.state == "NEW" ? async () => {
                            await requestHandler.request('PUT', '/sms-mgt-api/api/' + config.mgtApiVersion + '/tenants/' + accountId + '.sms/tenant-notifications/' + notification.id)
                            context.dispatch(ActionTypes.getNotifications, accountId)
                        } : undefined,
                        "onDelete": validationHelpers.isUUID(notification.id) ? async () => {
                            return await queries.unifiedSecurity.deleteTenantNotification(accountId, notification.id)
                        } : undefined,
                        "timestamp": notification.timestamp,
                        "state": notification.state.toLowerCase(),
                        "product": notification.productName,
                        "highlightNew": false
                    })
                }
            })

        },
        [ActionTypes.getObjectTypeCount]: async function (context, payload) {
            const accountId = payload.accountId
            let objectType = payload.objectType
            let queryType = payload.queryType || payload.objectType
            let skipRequest = false
            const countProperty = function () {
                let result = "count"
                if (
                    queryType == "androidProfiles" ||
                    queryType == "enterpriseProfiles"
                ) {
                    result = "androidCount"
                }
                else if (queryType == 'iosProfiles') {
                    result = "iosCount"
                }
                return result
            }()

            if (
                (countProperty == "androidCount" || objectType == "enterpriseDevices") && !tenantHelpers.hasFunctionality(accountId, "enterprise")
            ) {
                skipRequest = true
            }

            if(objectType == "enterpriseDevices") {
                objectType = "enterpriseDevices"
            }
            else if (objectType == "iosDevices") {
                objectType = "devices"
                queryType = "iosDevices"
            }
            else if (objectType == "androidProfiles") {
                objectType = "profiles"
                queryType = "androidProfiles"
            }
            else if (objectType == "iosProfiles") {
                objectType = "profiles"
                queryType = "iosProfiles"
            }

            const productType = tenantHelpers.getProductTypeFromObjectType(payload.objectType)
            if (!productType) throw "Missing productType"
            let count: number = 0
            let tenantsObject = {
                [accountId]: {
                    [productType]: {
                        [objectType]: {
                        }
                    }
                }
            }
            try {
                if (!skipRequest) {
                    let objectStore = objectStores[objectType as keyof typeof objectStores] ? objectStores[objectType as keyof typeof objectStores] : undefined
                    if (objectStore && objectStore instanceof GenericObjectStore) {
                        try {
                            let result = await objectStore.getCountFromApi(accountId, false)
                            if (typeof result == "number") {
                                count = result
                            }
                            else {
                                count = 0
                                throw "error"
                            }
                        }
                        catch (e: any) {
                            count = 0
                        }
                    }
                    else if (queries[productType as keyof typeof queries].hasOwnProperty("getObjectCount")) {
                        //@ts-ignore
                        count = await queries[productType as keyof typeof queries]?.getObjectCount(accountId, queryType)
                    }
                }
                if (!count) {
                    count = 0
                }
                if (typeof count == 'number') {
                    tenantsObject[accountId][productType][objectType] = {
                        [countProperty]: count
                    }
                    context.commit(MutationTypes.setTenants, tenantsObject)
                }
            }
            catch (e: any) {
                tenantsObject[accountId][productType][objectType] = {
                    [countProperty]: 0
                }
                context.commit(MutationTypes.setTenants, tenantsObject)
            }

        },
        [ActionTypes.addNotification]: function (context, notification) {
            if (notification != undefined) {
                let accountId: string = notification.accountId || ""
                frontendNotifications.addNotification(accountId, notification)
            }
        },
        [ActionTypes.addModal]: function (context, modal) {
            if (modal != undefined) {
                let accountId: string = modal.accountId
                modal.key = (+new Date).toString(36).slice(-5)
                // CHECK IF MODAL ALREADY EXISTS
                let hasModal: boolean = context.state.session.accounts[accountId]?.modals?.filter((checkModal: any) => {
                    return checkModal.id == modal.id
                }).length > 0

                // CHECK IF MODAL IS NOT HIDDEN BY USER
                const hiddenDialogsDecimal = cookies.get("hiddenDialogs") ? Number(cookies.get("hiddenDialogs")) : 0
                encodingHelpers.bitMask.getFlagObjectFromDecimal(hiddenDialogsDecimal, cookies.dialogSuspender.suspendedDialogsFlagMap)

                if (!hasModal) {
                    context.commit(MutationTypes.addModal, modal)
                }
            }
        },
        [ActionTypes.setTenantProperty]: async function (context: any, payload: any) {
            let accountId: string = payload.accountId
            let property: string = payload.property
            let additionalData: string = payload.additionalData || undefined
            let value: any = payload.value
            try {
                await queries.unifiedSecurity.setTenantProperty(accountId, property, value, additionalData)
                context.commit(MutationTypes.setTenantProperty, payload)
            }
            catch (e: any) {
                console.error(e)
            }
        },
        [ActionTypes.updateObjectProperty]: async (context, payload) => {
            let result: any = false
            try {
                let accountId = payload.accountId
                let productType = payload.productType
                let objectType = function () {
                    let result = payload.objectType
                    switch (payload.objectType) {
                        case 'androidProfiles':
                        case 'iosProfiles':
                            result = "profiles"
                            break;
                        case 'iosDevices':
                            result = "devices"
                            break;
                        case 'ccutmProperties':
                        case 'uscUtms':
                            result = "uscUtms"
                            break;
                        default:
                            result = payload.objectType
                            break;
                    }
                    return result
                }()
                let queryType = payload.queryType || objectType
                let objectPropertyPath = payload.objectPropertyPath
                let objectId = payload.objectId
                let object = payload.object
                let property = payload.property
                let value = payload.value
                object[property] = value
                if (property == "tags") {
                    let accountTags = context.state.session.accounts[accountId].mobileSecurity.tags.items
                    if (accountTags != undefined && accountTags?.length != 0) {
                        for (let tagIndex in value) {
                            let thisTagId: string = value[tagIndex]
                            let tagExists: boolean = false
                            for (let accountTagIndex in accountTags) {
                                let accountTagId: string = accountTags[accountTagIndex].id
                                if (accountTagId == thisTagId) {
                                    tagExists = true
                                    break;
                                }
                            }
                            if (!tagExists) {
                                accountTags.push({ "id": thisTagId, "text": thisTagId })
                            }
                        }
                    }
                    else {
                        for (let tagIndex in value) {
                            let thisTagId: string = value[tagIndex]
                            accountTags.push({ "id": thisTagId, "text": thisTagId })
                        }
                    }
                }

                let objectStore = objectStores[objectType as keyof typeof objectStores]
                if (objectStore && objectStore instanceof GenericObjectStore) {
                    result = await objectStore.updateObjectFromApi(accountId, objectId, object, undefined, ['policyName', 'state'].indexOf(property) != -1 ? false : true, [property])
                }
                else {
                    result = await (<any>queries)[productType].updateObjectInfo(accountId, queryType, objectId, object, [property])
                }

                if (result !== false) {
                    context.commit(MutationTypes.setObjectProperty, {
                        'accountId': accountId,
                        'productType': productType,
                        'objectType': objectType,
                        'objectId': objectId,
                        'property': property,
                        'objectPropertyPath': objectPropertyPath,
                        'value': value
                    })
                }
                else {
                    throw "could not update " + property
                }


            }
            catch (e: any) {
                console.error(e)
                throw e
            }
            return result
        },
        [ActionTypes.updateObjectInventory]: async (context, payload) => {
            let result: any = false
            try {
                const accountId = payload.accountId
                const productType = payload.productType
                let objectType = function () {
                    let result = payload.objectType
                    switch (payload.objectType) {
                        case 'androidProfiles':
                        case 'iosProfiles':
                            result = "profiles"
                            break;
                        case 'iosDevices':
                            result = "devices"
                            break;
                    }
                    return result
                }()
                const queryObjectType = payload.queryObjectType || (() => {
                    let queryObjectType = payload.objectType
                    if (payload.objectType == "devices") {
                        queryObjectType = "deviceProperties"
                    }
                    else if (payload.objectType == "utms") {
                        queryObjectType = "utmProperties"
                    }
                    else if (payload.objectType == "vpns") {
                        queryObjectType = "vpnProperties"
                    }
                    return queryObjectType
                })()
                const objectId = payload.objectId
                const object = payload.object
                if (objectStores[queryObjectType as keyof typeof objectStores]?.updateObjectInventory != undefined) {
                    result = await objectStores[queryObjectType as keyof typeof objectStores].updateObjectInventory(accountId, objectId, object)
                    if (result !== true) {
                        throw "could not update inventory"
                    }
                }
                else {
                    result = await (<any>queries)[productType].updateObjectInfo(accountId, queryObjectType, objectId, object)


                    if (result === true) {
                        for (let property in object) {
                            let value: any = object[property]
                            if (property == 'inventoryLocation') {
                                value = JSON.parse(object[property])
                            }
                            context.commit(MutationTypes.setObjectProperty, {
                                'objectPropertyPath': ["inventory"],
                                'accountId': accountId,
                                'productType': productType,
                                'objectType': objectType,
                                'objectId': objectId,
                                'property': property,
                                'value': value
                            })
                        }
                    }
                    else {
                        throw "could not update inventory"
                    }
                }
            }
            catch (e: any) {
                result = e
            }
            return result
        },
        [ActionTypes.deleteObject]: async (context, payload) => {
            let result: any = false
            try {
                let accountId = payload.accountId
                let productType = payload.productType
                let objectIdProperty = payload.objectIdProperty || tenantHelpers.getObjectIdPropertyForObjectType(payload.objectType)
                let objectId = payload.objectId
                let objectType = function (): string {
                    let result = payload.objectType
                    switch (payload.objectType) {
                        case 'androidProfiles':
                        case 'iosProfiles':
                            result = "profiles"
                            break;
                        case 'iosDevices':
                            result = "devices"
                            break;
                    }
                    return result
                }()
                let commitObjectType = payload.objectType == 'enterpriseProfiles' ? 'profiles' : payload.objectType
                let customerId = payload.customerId || undefined
                let objectStore = objectStores[objectType as keyof typeof objectStores] ? objectStores[objectType as keyof typeof objectStores] : undefined
                if (objectStore != undefined && objectStore instanceof GenericObjectStore) {
                    result = await objectStore.deleteObjectFromApi(accountId, objectId, false)
                }
                else {
                    result = await (<any>queries)[productType].deleteObject(accountId, objectType, objectId, undefined, customerId)
                }
                if (result !== false && !(result instanceof Error)) {
                    context.commit(MutationTypes.deleteObject, {
                        "accountId": accountId,
                        "productType": productType,
                        "objectType": commitObjectType,
                        "objectIdProperty": objectIdProperty,
                        "objectId": objectId
                    })
                    context.dispatch(ActionTypes.getObjectTypeCount, { "accountId": accountId, "objectType": commitObjectType })
                    
                }

            }
            catch (e: any) {
                console.error(e)
                result = e
            }
            return result
        },
        [ActionTypes.publishProfile]: async (context, payload) => {
            let accountId: string = payload.accountId
            let objectId: string = payload.objectId
            let result = false
            try {
                result = await queries.mobileSecurity.publishProfile(accountId, objectId)
                if (result) {
                    context.commit(MutationTypes.setObjectProperty, {
                        'accountId': accountId,
                        'productType': 'mobileSecurity',
                        'objectType': 'profiles',
                        'objectId': objectId,
                        'property': "status",
                        'objectPropertyPath': [],
                        'value': "PUBLISH"
                    })
                }
            }
            catch (e: any) {
                result = false
                console.error(e)
            }
            return result
        },
        [ActionTypes.revokeProfile]: async (context, payload) => {
            let accountId: string = payload.accountId
            let objectId: string = payload.objectId
            let result = false
            try {
                result = await queries.mobileSecurity.revokeProfile(accountId, objectId)
                if (result) {
                    context.commit(MutationTypes.setObjectProperty, {
                        'accountId': accountId,
                        'productType': 'mobileSecurity',
                        'objectType': 'profiles',
                        'objectId': objectId,
                        'property': "status",
                        'objectPropertyPath': [],
                        'value': "REVOKE"
                    })
                }
            }
            catch (e: any) {
                result = false
                console.error(e)
            }
            return result
        },
        [ActionTypes.getAuditLog]: async (context, accountId) => {
            let result: any = false
            try {
                result = await queries.unifiedSecurity.getAuditLog(accountId)
                if (result.entries) {
                    let table: any = {
                        'titles': [
                            {
                                "id": "timestamp",
                                "text": "Date",
                                "width": 150,
                                "sortable": false,
                                "visible": false
                            },
                            {
                                "id": "date",
                                "text": "Date",
                                "width": 150,
                                "sortable": false,
                                "sortBy": "timestamp"
                            },
                            {
                                "id": "user",
                                "text": "User",
                                "sortable": false
                            },
                            {
                                "id": "message",
                                "text": "Message",
                                "sortable": false
                            }
                        ],
                        "rows": []
                    }
                    result.entries.forEach((entry: any) => {
                        table.rows.push(
                            {
                                data: {
                                    "timestamp": entry.timestamp,
                                    "date": timeHelpers.formatDate(entry.timestamp, timeHelpers.getDateFormatI18n(i18n.getLanguage(), true)),
                                    "user": T(entry.user),
                                    "message": T(entry.message)
                                }
                            }
                        )
                    });
                    table.rows = arrayHelpers.sortByObjProperty(table.rows, 'timestamp', 'DESC')
                    let payload: any = {}
                    payload[accountId] = {
                        "unifiedSecurity": {
                            "privacy": {
                                "auditlog": table
                            }
                        }
                    }
                    context.commit(MutationTypes.setTenants, payload)
                }
            }
            catch (e: any) {
                result = false
                console.error(e)
            }
            return result
        },
        [ActionTypes.getObjectInfos]: async function (context, payload) {
            let accountId: string = payload.accountId
            let objectTypes: string[] = payload.objectTypes || []
            let fullRefresh: boolean = payload.fullRefresh || false
            let lookupApps: boolean = payload.lookupApps != undefined ? payload.lookupApps : true
            let force: boolean = false //payload.force || false
            let result: any = null
            let queryObject: any = {}
            let success: boolean = false

            objectTypes?.forEach(function (objectType: string) {
                if (objectType.indexOf("?") != -1) {
                    let thisProps: any = objectType.split('?')[1]
                    if (thisProps.indexOf("&") != -1) {
                        thisProps = thisProps.split('&')
                        queryObject[objectType.split('?')[0]] = { "props": [] }
                        thisProps?.forEach(function (thisProp: any) {
                            queryObject[objectType.split('?')[0]].props.push({
                                "property": thisProp.split('=')[0],
                                "value": thisProp.split('=')[1]
                            })
                        })
                    }
                    else {
                        queryObject[objectType.split('?')[0]] = {
                            "props": [{
                                "property": thisProps.split('=')[0],
                                "value": thisProps.split('=')[1]
                            }]
                        }
                    }
                }
                else {
                    queryObject[objectType] = { "props": undefined }
                }
            })

            let vppOptions: any = {}
            queryObject?.vpplicenses?.props?.forEach(function (prop: any) {
                vppOptions[prop.property] = prop.value
            })
            let results: any = []

            try {
                let getTags = async () => {
                    let result: string[] = []
                    try {
                        const tenantDomain = tenantHelpers.getTenantDomain(accountId)
                        result = await requestHandler.request("GET", config.mgtApiUriNext + "/tenants/" + tenantDomain + "/tags")
                    }
                    catch (e: any) { }
                    return result
                }

                queryObject.hasOwnProperty("enterpriseDevices") ? objectStores.enterpriseDevices.getObjectsInBatchesFromApi(accountId, queryObject?.enterpriseDevices?.props, queryObject.hasOwnProperty("zeroTouchDevices"), payload.customerId, queryObject?.zeroTouchDevices?.props) : null;

                results = await promiseHelpers.resolvePromiseObject({
                    "devices": (queryObject.hasOwnProperty("devices") ? queries.mobileSecurity.getObjectInfos(accountId, "devices", queryObject?.devices?.props, force) : null),
                    "depDevices": (queryObject.hasOwnProperty("depDevices") ? queries.mobileSecurity.getObjectInfos(accountId, "depDevices", queryObject?.depDevices?.props, force) : null),

                    "profiles": (queryObject.hasOwnProperty("profiles") ? queries.mobileSecurity.getObjectInfos(accountId, "profiles", queryObject?.profiles?.props, force) : null),
                    "enterpriseProfiles": (queryObject.hasOwnProperty("enterpriseProfiles") ? queries.mobileSecurity.getObjectInfos(accountId, "enterpriseProfiles", queryObject?.enterpriseProfiles?.props, force) : null),
                    "apps": (queryObject.hasOwnProperty("apps") ? queries.mobileSecurity.getObjectInfos(accountId, "apps", queryObject?.apps?.props, force) : null),
                    "homescreenlayouts": (queryObject.hasOwnProperty("homescreenlayouts") ? objectStores.homescreenlayouts.getObjectsFromApi(accountId, undefined, false) : null),
                    "apiKeys": (queryObject.hasOwnProperty("apiKeys") ? objectStores.apiKeys.getObjectsFromApi(accountId, undefined, true) : null),
                    "vpns": (queryObject.hasOwnProperty("vpns") ? queries.mobileSecurity.getObjectInfos(accountId, "vpns", queryObject?.vpns?.props, force) : null),
                    "users": (queryObject.hasOwnProperty("users") ? objectStores.users.getObjectsFromApi(accountId, undefined, false) : null),
                    "vppUsers": (queryObject.hasOwnProperty("vppUsers") ? objectStores.vppUsers.getObjectsFromApi(accountId, undefined, true) : null),
                    "roles": (queryObject.hasOwnProperty("roles") ? objectStores.roles.getObjectsFromApi(accountId, undefined, undefined, false) : null),
                    "certificates": (queryObject.hasOwnProperty("certificates") ? queries.mobileSecurity.getObjectInfos(accountId, "certificates", queryObject?.certificates?.props, force) : null),
                    "historyDevices": (queryObject.hasOwnProperty("historyDevices") ? queries.mobileSecurity.getObjectInfos(accountId, "historyDevices", queryObject?.historyDevices?.props, force) : null),
                    "historyVpns": (queryObject.hasOwnProperty("historyVpns") ? queries.mobileSecurity.getObjectInfos(accountId, "historyVpns", queryObject?.historyVpns?.props, force) : null),
                    "notifications": (queryObject.hasOwnProperty("notifications") ? queries.unifiedSecurity.getObjectInfos(accountId, "notifications", queryObject?.notifications?.props, force) : null),
                    "utms": (queryObject.hasOwnProperty("utms") ? queries.unifiedReporting.getObjectInfos(accountId, "utms", queryObject?.utms?.props, force) : null),
                    "uscUtms": (queryObject.hasOwnProperty("uscUtms") ? objectStores.uscUtms.getObjectsFromApi(accountId, queryObject?.uscUtms?.props, false) : null),
                    "ccutmStates": (queryObject.hasOwnProperty("ccutmStates") ? queries.unifiedSecurityConsole.getObjectInfos(accountId, "ccutmStates", queryObject?.ccutmStates?.props, force) : null),
                    "reports": (queryObject.hasOwnProperty("reports") ? queries.unifiedReporting.getObjectInfos(accountId, "reports", queryObject?.reports?.props, force) : null),
                    "licenses": (queryObject.hasOwnProperty("licenses") ? objectStores.licenses.getObjectsFromApi(accountId, queryObject?.licenses?.props, true) : null),
                    "vppassets": (queryObject.hasOwnProperty("vppassets") ? queries.mobileSecurity.vpp.getVPPAssetsSrv(accountId + '.sms', undefined, undefined, force) : null),
                    "vpplicenses": (queryObject.hasOwnProperty("vpplicenses")
                        ? queries.mobileSecurity.vpp.getVPPLicensesSrv(accountId + '.sms', undefined, vppOptions.objectId || undefined, undefined, undefined, undefined, undefined, undefined, vppOptions.deviceAssignedOnly || undefined, force)
                        : null
                    ),
                    "depProfiles": (queryObject.hasOwnProperty("depProfiles") ? queries.mobileSecurity.getObjectInfos(accountId, "depProfiles", queryObject?.depProfiles?.props, force) : null),
                    "tags": (queryObject.hasOwnProperty("tags") ? getTags() : null),
                    "zeroTouchCustomers": (queryObject.hasOwnProperty("zeroTouchCustomers") ? queries.mobileSecurity.getObjectInfos(accountId, "zeroTouchCustomers", queryObject?.zeroTouchCustomers?.props, force, undefined) : null),
                    "zeroTouchDevices": (queryObject.hasOwnProperty("zeroTouchDevices") && !queryObject.hasOwnProperty("enterpriseDevices") ? objectStores.zeroTouchDevices.getObjectsFromApi(accountId, queryObject?.zeroTouchDevices?.props, true, apis.getApiVersionForFeature('zeroTouch') == "2.0", payload.customerId) : null),
                    "zeroTouchConfigurations": (queryObject.hasOwnProperty("zeroTouchConfigurations") ? queries.mobileSecurity.getObjectInfos(accountId, "zeroTouchConfigurations", queryObject?.zeroTouchConfigurations?.props, force, payload.customerId) : null),
                    "images": (queryObject.hasOwnProperty("images") ? queries.unifiedSecurity.getObjectInfos(accountId, "images") : null),
                    "uscProfiles": (queryObject.hasOwnProperty("uscProfiles") ? objectStores.uscProfiles.getObjectsFromApi(accountId, undefined, false) : null),
                })
            }
            catch (e: any) {
                console.error(e)
            }
            try {
                result = {
                    "unifiedSecurity": {},
                    "mobileSecurity": {},
                    "unifiedReporting": {},
                    "unifiedSecurityConsole": {}
                }

                if (results.devices != null) {
                    result.mobileSecurity.devices = results.devices.devices
                    // GET TAGS
                    for (let i: number = 0; result.mobileSecurity.devices?.length > i; i++) {
                        let device: any = result.mobileSecurity.devices[i]
                        tenantHelpers.convertObjectForStore(accountId, "devices", device, {})
                    }
                }
                if (results.depDevices != null) {
                    results.depDevices.devices?.forEach(function (depDevice: any) {
                        let foundDep: boolean = false
                        let thisDevice: any = undefined
                        for (let i: number = 0; result.mobileSecurity?.devices?.length > i; i++) {
                            let device: any = result.mobileSecurity.devices[i]
                            if (depDevice['serial_number'] == device.info['serial'] && device.signedIn === true) {
                                device.depDevice = true
                                device.depDeviceInfo = depDevice
                                foundDep = true
                            }
                            else if (!device.depDevice) {
                                device.depDevice = false
                            }
                            thisDevice = device
                        }
                        if (!foundDep) {
                            if (!result.mobileSecurity.devices) {
                                result.mobileSecurity.devices = []
                            }
                            thisDevice = depDevice
                            result.mobileSecurity.devices.push(tenantHelpers.convertObjectForStore(accountId, "depDevices", thisDevice, {}))
                        }
                    })
                }
                if (results.enterpriseDevices != null) {
                    result.enterpriseDevices = results.enterpriseDevices || []
                    result.mobileSecurity.enterpriseDevices = results.enterpriseDevices
                    let previousDeviceNames: any = []
                    for (let i: number = 0; result.mobileSecurity.enterpriseDevices?.length > i; i++) {
                        let device = result.mobileSecurity.enterpriseDevices[i]
                        if (jsonHelpers.getObjectProperty(device, 'previousDeviceNames')) {
                            let thisPreviousDeviceNames = jsonHelpers.getObjectProperty(device, 'previousDeviceNames')
                            for (let thisIndex in thisPreviousDeviceNames) {
                                previousDeviceNames.push(thisPreviousDeviceNames[thisIndex])
                            }
                        }
                    }
                    if (previousDeviceNames.length) {
                        for (let i: number = 0; result.mobileSecurity.enterpriseDevices?.length > i; i++) {
                            if (jsonHelpers.getObjectProperty(result.mobileSecurity.enterpriseDevices[i], 'name')) {
                                if (previousDeviceNames.indexOf(result.mobileSecurity.enterpriseDevices[i].name) != -1) {
                                    result.mobileSecurity.enterpriseDevices[i].signedIn = false
                                }
                            }
                        }
                    }
                }
                if (results.profiles != null) {
                    result.mobileSecurity.profiles = results.profiles.profiles
                    // GET TAGS
                    for (let i: number = 0; result.mobileSecurity.profiles.length > i; i++) {
                        let profile: any = result.mobileSecurity.profiles[i]
                        tenantHelpers.convertObjectForStore(accountId, "profiles", profile, {})
                    }
                }
                if (results.enterpriseProfiles != null) {
                    result.enterpriseProfiles = results.enterpriseProfiles.policies || []
                    result.enterpriseProfiles.map(function (enterpriseProfile: any) {
                        enterpriseProfile.platform = "ANDROID_ENTERPRISE"
                        let thisDevices = []
                        if (result.mobileSecurity.enterpriseDevices) {
                            for (let i: number = 0; result.mobileSecurity.enterpriseDevices?.length > i; i++) {
                                let devicePolicyName: string = ""
                                if (result.mobileSecurity.enterpriseDevices[i].policyName != undefined) {
                                    devicePolicyName = result.mobileSecurity.enterpriseDevices[i].policyName.split("/")[3]
                                }
                                else if (result.mobileSecurity.enterpriseDevices[i].appliedPolicyName != undefined) {
                                    devicePolicyName = result.mobileSecurity.enterpriseDevices[i].appliedPolicyName.split("/")[3]
                                }
                                if (devicePolicyName == enterpriseProfile.name.split("/")[3]) {
                                    if (!result.mobileSecurity.enterpriseDevices) {
                                        result.mobileSecurity.enterpriseDevices = []
                                    }
                                    thisDevices.push(result.mobileSecurity.enterpriseDevices[i].id)
                                }
                            }
                        }
                        else if (context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items) {
                            for (let i: number = 0; context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items.length > i; i++) {
                                let devicePolicyName: string = ""
                                if (context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items[i].policyName != undefined) {
                                    devicePolicyName = context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items[i].policyName.split("/")[3]
                                }
                                else if (context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items[i].appliedPolicyName != undefined) {
                                    devicePolicyName = context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items[i].appliedPolicyName.split("/")[3]
                                }
                                if (devicePolicyName == enterpriseProfile.name.split("/")[3]) {
                                    thisDevices.push(context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items[i].id)
                                }
                            }
                        }
                        if (result.mobileSecurity.profiles == undefined) {
                            result.mobileSecurity.profiles = []
                        }
                        result.mobileSecurity.profiles.push(tenantHelpers.convertObjectForStore(accountId, "enterpriseProfiles", enterpriseProfile, { "devices": thisDevices }))
                    })
                }
                if (results.apps != null) {
                    result.mobileSecurity.apps = results.apps.apps || []
                    // GET TAGS
                    for (let i: number = 0; result.mobileSecurity.apps.length > i; i++) {
                        let thisApp: any = result.mobileSecurity.apps[i]
                        tenantHelpers.convertObjectForStore(accountId, "apps", thisApp, {})
                    }
                }
                if (results.homescreenlayouts != null) { result.mobileSecurity.homescreenlayouts = results.homescreenlayouts || [] }
                if (results.vpns != null) {
                    result.mobileSecurity.vpns = []
                    results.vpns.vpns.forEach(function (vpn: any) {
                        vpn.isCheckable = true
                        result.mobileSecurity.vpns.push(vpn)
                    })
                }
                if (results.users != null) { result.unifiedSecurity.users = results.users || [] }
                if (results.roles != null) { result.unifiedSecurity.roles = results.roles || [] }
                if (results.notifications != null) { result.unifiedSecurity.notifications = results.notifications.services || [] }

                if (results.images != null) { result.unifiedSecurity.images = results.images.images.map((image: any) => { return { "imageId": image } }) || [] }

                if (results.certificates != null) { result.mobileSecurity.certificates = results.certificates.certificates || [] }
                if (results.historyDevices != null) { result.mobileSecurity.historyDevices = results.historyDevices.devices || [] }
                if (results.historyVpns != null) { result.mobileSecurity.historyVpns = results.historyVpns.vpns || [] }
                if (results.vppassets != null) { result.mobileSecurity.vppassets = results.vppassets.assets || [] }
                if (results.utms != null) { result.unifiedReporting.utms = results.utms || [] }
                if (results.reports != null) { result.unifiedReporting.reports = results.reports.reports || [] }
                if (results.vpplicenses != null) { result.mobileSecurity.vpplicenses = results.vpplicenses.licenses || [] }

                if (results.uscUtms != null) {
                    result.unifiedSecurityConsole.uscUtms = results.uscUtms || []
                }
                if (results.uscProfiles != null) {
                    result.unifiedSecurityConsole.uscProfiles = results.uscProfiles || []
                }
                if (results.ccutmStates != null) {
                    result.unifiedSecurityConsole.ccutmStates = results.ccutmStates || []
                }

                if (results.depProfiles != null) {
                    result.mobileSecurity.depProfiles = []
                    results.depProfiles?.forEach(function (thisProfile: any) {
                        if (thisProfile) {
                            result.mobileSecurity.depProfiles.push(tenantHelpers.convertObjectForStore(accountId, "depProfiles", thisProfile, {}))
                        }
                    })
                }
                if (results.tags != null) {
                    result.mobileSecurity.tags = results.tags
                }
                if (results.zeroTouchCustomers != null && !results.zeroTouchCustomers.error) {
                    result.mobileSecurity.zeroTouchCustomers = results.zeroTouchCustomers
                }
                if (results.zeroTouchConfigurations != null && !results.zeroTouchConfigurations.error) {
                    result.mobileSecurity.zeroTouchConfigurations = results.zeroTouchConfigurations
                }

                // prepare device objects
                result.mobileSecurity.devices?.forEach(function (device: any) {
                    // set licenseInformation
                    device.licenseInformation = licenseHelpers.getLicenseByUUID(accountId,device.licenseUUID)
                    // parse enrollmentTokenData
                    if (device?.enrollmentTokenData) {
                        device.enrollmentTokenData = JSON.parse(device.enrollmentTokenData)
                    }
                    // set signedIn to false for previous registered devices, if this device has previous device names
                    if (device?.previousDeviceNames?.length) {
                        result.mobileSecurity?.devices?.forEach((testDevice: any) => {
                            if (device?.previousDeviceNames.indexOf(testDevice?.name) != -1) {
                                testDevice.signedIn = false
                            }
                        });
                    }
                })
                // prepare vpn objects
                result.mobileSecurity?.vpns?.forEach(function (vpn: any) {
                    // add isCheckable property
                    vpn.isCheckable = true
                    // set licenseInformation
                    vpn.licenseInformation = licenseHelpers.getLicenseByUUID(accountId, vpn.licenseUUID)
                })
                // prepare utm objects
                result.unifiedReporting?.utms?.forEach(function (utm: any) {
                    utm.licenseUUID = utm.utmId || undefined
                    // set licenseInformation
                    utm.licenseInformation = licenseHelpers.getLicenseByUUID(accountId, utm.licenseUUID)
                })
                // prepare uscUtm objects
                if (typeof result.unifiedSecurityConsole?.uscUtms != "string" && Array.isArray(result.unifiedSecurityConsole?.uscUtms)) {
                    result.unifiedSecurityConsole?.uscUtms?.forEach(function (utm: any) {
                        utm.licenseUUID = utm.utmId || undefined
                        // set licenseInformation
                        utm.licenseInformation = licenseHelpers.getLicenseByUUID(accountId, utm.licenseUUID)
                    })
                } else if (typeof result.unifiedSecurityConsole?.uscUtms === "string") {
                    delete result.unifiedSecurityConsole?.uscUtms
                }

                if (result.mobileSecurity?.vppassets) {
                    // get further app infos from itunes store api
                    result.mobileSecurity.vppassets = await queries.mobileSecurity.vpp.completeAssetInfos(result.mobileSecurity.vppassets)
                }
                if (result.mobileSecurity?.apps && lookupApps) {
                    // get further app infos from itunes store api
                    try {
                        result.mobileSecurity.apps = await queries.mobileSecurity.vpp.completeAssetInfos(result.mobileSecurity.apps, 'iTunesStoreID')
                    }
                    catch(e) {
                        console.error(e)
                    }
                    try {
                        result.mobileSecurity.apps = await queries.mobileSecurity.vpp.completeAssetInfos(result.mobileSecurity.apps, 'Identifier')
                    }
                    catch (e) {
                        console.error(e)
                    }
                }
                if (result.enterpriseDevices) { delete result.enterpriseDevices }
                if (result.enterpriseProfiles) { delete result.enterpriseProfiles }
                if (result.mobileSecurity.tags && !result.mobileSecurity.tags?.length) { delete result.mobileSecurity.tags }
                else if (result.mobileSecurity.tags && result.mobileSecurity.tags?.length) {
                    let uniqueTags: any = result.mobileSecurity.tags.filter(arrayHelpers.onlyUniqueFilter).map(function (tag: string) {
                        return { "id": tag, "text": tag }
                    })
                    result.mobileSecurity.tags = uniqueTags
                }
                for (let productProperty in result) {
                    if (jsonHelpers.isObjectEmpty(result[productProperty])) { delete result[productProperty] }
                }

                context.commit(MutationTypes.setObjectInfos, { 'accountId': accountId, 'products': result, 'fullRefresh': fullRefresh })
                success = true
            }
            catch (e: any) {
                console.error(e)
            }
            return success
        },
        [ActionTypes.getObjectInfo]: async function (context, payload) {
            let accountId: string = payload.accountId
            let productType: string = payload.productType || tenantHelpers.getProductTypeFromObjectType(payload.objectType)
            let objectType: string = function (): string {
                let result: string = payload.objectType
                switch (payload.objectType) {
                    case 'androidProfiles':
                    case 'iosProfiles':
                        result = "profiles"
                        break;
                    case 'iosDevices':
                        result = "devices"
                        break;
                }
                return result
            }()
            let queryType: string = payload.queryType || objectType
            let objectId: string = payload.objectId

            if(!objectId) throw "missing objectId"
            let customerId: any = payload.customerId || undefined
            let force: boolean = false//payload.force || false
            let updateOthers: boolean = payload.updateOthers != undefined ? payload.updateOthers : true
            let mainResult: any = []
            if (objectType == 'profiles' && objectId.indexOf('enterprise_') != -1) {
                queryType = 'enterpriseProfiles'
                objectId = objectId.replace('enterprise_', '')
            }
            let result: any = {
                "mobileSecurity": {},
                "unifiedReporting": {},
                "unifiedSecurityConsole": {},
                "unifiedSecurity": {}
            }
            let success: boolean = false

            let queryObject: any = {}

            if (objectType.indexOf("?") != -1) {
                let thisProps: any = objectType.split('?')[1]
                if (thisProps.indexOf("&") != -1) {
                    thisProps = thisProps.split('&')
                    queryObject[objectType.split('?')[0]] = { "props": [] }
                    thisProps?.forEach(function (thisProp: any) {
                        queryObject[objectType.split('?')[0]].props.push({
                            "property": thisProp.split('=')[0],
                            "value": thisProp.split('=')[1]
                        })
                    })
                }
                else {
                    queryObject[objectType.split('?')[0]] = {
                        "props": [{
                            "property": thisProps.split('=')[0],
                            "value": thisProps.split('=')[1]
                        }]
                    }
                }
            }
            else {
                queryObject[objectType] = { "props": undefined }
            }

            // Get single object
            try {
                result[productType][objectType] = []
                let objectStore = objectStores[objectType as keyof typeof objectStores] ? objectStores[objectType as keyof typeof objectStores] : undefined
                if (objectStore && objectStore instanceof GenericObjectStore) {
                    mainResult = await objectStore.getObjectFromApi(accountId, objectId, undefined, false)

                    if (!(mainResult instanceof Error)) {
                        result[productType][objectType].push(tenantHelpers.convertObjectForStore(accountId, queryType, mainResult, {}))
                    }
                }
                else {
                    mainResult = await (<any>queries)[productType].getObjectInfo(accountId, queryType, objectId, undefined, undefined, customerId)

                    if (queryType == 'images') {
                        mainResult.images = mainResult.images.map((image: any) => { return { "imageId": image } })
                    }

                    if (queryType == 'enterpriseProfiles') {
                        // set profile platform 
                        mainResult.platform = "ANDROID_ENTERPRISE"
                        let thisDevices = []
                        if (context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items) {
                            for (let i: number = 0; context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items.length > i; i++) {
                                if (context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items[i].name.indexOf('enterprises') != -1) {
                                    let devicePolicyName: string = ""
                                    if (context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items[i].policyName != undefined) {
                                        devicePolicyName = context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items[i].policyName.split("/")[3]
                                    }
                                    else if (context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items[i].appliedPolicyName != undefined) {
                                        devicePolicyName = context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items[i].appliedPolicyName.split("/")[3]
                                    }
                                    if (devicePolicyName == mainResult.name.split("/")[3]) {
                                        thisDevices.push(context.state.session.accounts[accountId].mobileSecurity.enterpriseDevices.items[i].id)
                                    }
                                }
                            }
                        }
                        result[productType][objectType].push(tenantHelpers.convertObjectForStore(accountId, "enterpriseProfiles", mainResult, { "devices": thisDevices }))
                    }
                    else {
                        result[productType][objectType].push(tenantHelpers.convertObjectForStore(accountId, queryType, mainResult, {}))
                    }

                    if (!updateOthers) {
                        let productType = tenantHelpers.getProductTypeFromObjectType(objectType)
                        if (!productType) throw "Missing productType"
                        let newResult: any = {}
                        newResult[productType] = { "objectTypes": {} }
                        newResult[productType][objectType] = result[productType][objectType]
                        result = newResult
                    }
                }

                // prepare device objects
                result.mobileSecurity.devices?.forEach(function (device: any) {
                    // set licenseInformation
                    device.licenseInformation = licenseHelpers.getLicenseByUUID(accountId, device.licenseUUID)
                })
                // prepare enterpriseDevice objects
                result.mobileSecurity.enterpriseDevices?.forEach(function (device: any) {
                    // set licenseInformation
                    device.licenseInformation = licenseHelpers.getLicenseByUUID(accountId, device.licenseUUID)
                })
                // prepare vpn objects
                result.mobileSecurity?.vpns?.forEach(function (vpn: any) {
                    // add isCheckable property
                    vpn.isCheckable = true
                    // set licenseInformation
                    vpn.licenseInformation = licenseHelpers.getLicenseByUUID(accountId, vpn.licenseUUID)
                })
                // prepare utm objects
                result.unifiedReporting?.utms?.forEach(function (utm: any) {
                    utm.licenseUUID = utm.utmId || undefined
                    // set licenseInformation
                    utm.licenseInformation = licenseHelpers.getLicenseByUUID(accountId, utm.licenseUUID)
                })
                // prepare uscUtm objects
                if (typeof result.unifiedSecurityConsole?.uscUtms != "string" && Array.isArray(result.unifiedSecurityConsole?.uscUtms)) {
                    result.unifiedSecurityConsole?.uscUtms?.forEach(function (utm: any) {
                        utm.licenseUUID = utm.utmId || undefined
                        // set licenseInformation
                        utm.licenseInformation = licenseHelpers.getLicenseByUUID(accountId, utm.licenseUUID)
                    })
                }
                if (result.mobileSecurity?.vppassets) {
                    // get further app infos from itunes store api
                    result.mobileSecurity.vppassets = await queries.mobileSecurity.vpp.completeAssetInfos(result.mobileSecurity.vppassets)
                }
                if (result.mobileSecurity?.apps) {
                    // get further app infos from itunes store api
                    result.mobileSecurity.apps = await queries.mobileSecurity.vpp.completeAssetInfos(result.mobileSecurity.apps, 'iTunesStoreID')
                    result.mobileSecurity.apps = await queries.mobileSecurity.vpp.completeAssetInfos(result.mobileSecurity.apps, 'Identifier')
                }

                if (result.enterpriseProfiles) {
                    delete result.enterpriseProfiles
                }
                for (let productProperty in result) {
                    if (jsonHelpers.isObjectEmpty(result[productProperty])) {
                        delete result[productProperty]
                    }
                }

                context.commit(MutationTypes.setObjectInfos, { 'accountId': accountId, 'products': result })
                success = mainResult
            }
            catch (e: any) {
                console.error(e)
                success = false
            }
            return success
        },
        [ActionTypes.addOrUpdateObject]: async (context, payload) => {
            let accountId = payload.accountId,
                productType = payload.productType || tenantHelpers.getProductTypeFromObjectType(payload.objectType),
                objectType = payload.objectType == "androidProfiles" ? "profiles" : payload.objectType == "iosProfiles" ? "profiles" : payload.objectType,
                queryType = payload.queryType || objectType,
                customerId = payload.customerId,
                objectId = payload.objectId,
                objectIdProperty = tenantHelpers.getObjectIdPropertyForObjectType(objectType),
                object = payload.object,
                thisContext = objectId != undefined ? "update" : "add"
            let queryResult: any = false

            if (!productType) throw "Missing productType"

            if (objectType == 'zeroTouchConfigurations' && object.customerId) {
                delete object.customerId
            }

            devLog.log('Vuex Action', 'Trying to ' + thisContext + ' object of type ' + objectType, payload, 'default', 3)
            let result: any = {
                "mobileSecurity": {},
                "unifiedReporting": {},
                "unifiedSecurityConsole": {},
                "unifiedSecurity": {}
            }

            try {
                let objectStore = objectStores[objectType as keyof typeof objectStores] ? objectStores[objectType as keyof typeof objectStores] : undefined
                if (objectStore && objectStore instanceof GenericObjectStore) {
                    // @ts-ignore
                    queryResult = await objectStore.addOrUpdateObjectFromApi(accountId, objectId, object, false)
                    objectId = objectId || queryResult[objectIdProperty]
                }
                else {

                    if (thisContext == "add") {
                        queryResult = await (<any>queries)[productType].addObjectInfo(accountId, queryType, object, customerId)
                        
                        objectId = queryResult[objectIdProperty] || queryResult
                        context.dispatch(ActionTypes.getObjectTypeCount, { "accountId": accountId, "objectType": objectType, "queryType":queryType })
                        
                    }
                    else if (objectId) {
                        if (objectId.indexOf("enterprise_") != -1 || objectType == "enterpriseProfiles") {
                            objectId = objectId.replace('enterprise_', '')
                            queryType = "enterpriseProfiles"
                            objectType = "profiles"
                        }
                        queryResult = await (<any>queries)[productType].updateObjectInfo(accountId, queryType, objectId, object, undefined, customerId)
                    }
                }

                if(queryResult.status == 400) {
                    throw queryResult
                }

                if (queryResult !== false) {
                    let additionalData: any = {}
                    if (queryType == "enterpriseProfiles") {
                        object.name = object.name
                        objectId = object.name
                        let thisDevices = []
                        if (jsonHelpers.getObjectProperty(context, "state.session.accounts[" + accountId + "].mobileSecurity.devices.items") != undefined) {
                            for (let i: number = 0; context.state.session.accounts[accountId].mobileSecurity.devices.items.length > i; i++) {
                                if (context.state.session.accounts[accountId].mobileSecurity.devices.items[i].name.indexOf('enterprises') != -1) {
                                    let devicePolicyName: string = ""
                                    if (context.state.session.accounts[accountId].mobileSecurity.devices.items[i].policyName != undefined) {
                                        devicePolicyName = context.state.session.accounts[accountId].mobileSecurity.devices.items[i].policyName.split("/")[3]
                                    }
                                    else if (context.state.session.accounts[accountId].mobileSecurity.devices.items[i].appliedPolicyName != undefined) {
                                        devicePolicyName = context.state.session.accounts[accountId].mobileSecurity.devices.items[i].appliedPolicyName.split("/")[3]
                                    }
                                    if (devicePolicyName == object.name.split("/")[3]) {
                                        thisDevices.push(context.state.session.accounts[accountId].mobileSecurity.devices.items[i].id)
                                    }
                                }
                            }
                        }
                        additionalData.devices = thisDevices
                    }

                    if (queryType == "apps" && !object.ChangeManagementState) {
                        object.ChangeManagementState = ""
                    }

                    let storeObject: any = tenantHelpers.convertObjectForStore(accountId, queryType, object, additionalData)
                    storeObject[objectIdProperty] = objectId
                    // prepare for store commitment
                    result[productType][objectType] = [storeObject]
                    for (let productProperty in result) {
                        if (jsonHelpers.isObjectEmpty(result[productProperty])) {
                            delete result[productProperty]
                        }
                    }
                    context.commit(MutationTypes.setObjectInfos, { 'accountId': accountId, 'products': result })
                }
            }
            catch (e: any) {
                devLog.log('Vuex Action', 'Error adding/updating object', e, 'default', 3)
                result = e
            }
            return result
        },
        [ActionTypes.setDarkmode]: (context, darkmode) => {
            if (darkmode == "0") {
                document.getElementsByTagName('body')[0].classList.remove('darkmode')
            }
            else {
                document.getElementsByTagName('body')[0].classList.add('darkmode')
            }
            context.commit(MutationTypes.setDarkmode, darkmode)
        }
    }
})
export const useStore = () => {
    return vuexStore
}

export default vuexStore
