import { T } from "@/classes/i18n"
import ObjectType, { type ObjectTypePayload } from "../../objectType"
import tenantHelpers from "@/helpers/helpers.tenants"
import buttons from "@/classes/buttons"
import dialogs from "@/dialogs/dialogs"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import deviceHelpers from "@/helpers/helpers.devices"
import router from "@/router/router"
import numberHelpers from "@/helpers/helpers.numbers"
import jsonHelpers from "@/helpers/helpers.json"
import licenseHelpers from "@/helpers/helpers.license"
import config from "@/classes/config"
import requestHandler from "@/queries/requests"
import apis from "@/classes/apis"
import devLog from "@/classes/log"
import products from "../.."
import getterHelpers from "@/helpers/helpers.getters"
import type { IosDevice } from "./iosDevices"
import type { DepDevice } from "./depDevices"

export type DepProfile = {
    "url": string,
    "is_supervised": boolean,
    "allow_pairing": boolean,
    "is_mandatory": boolean,
    "is_mdm_removable": boolean,
    "await_device_configured": boolean,
    "is_multi_user": boolean,
    "auto_advance_setup": boolean,
    "skip_setup_items": string[],
    "profile_uuid": string,
    "profile_name": string,
    "is_default": boolean,
    "configuration_web_url"?:string
    "devicesBefore"?:any[] // set by frontend
}

class DepProfiles extends ObjectType<DepProfile> {
    constructor(payload: ObjectTypePayload<DepProfile>) {
        super(payload)


        // ITEMLIST
        this.itemlist.getSortingOptions = (accountId, component) => {
            return [
                {
                    "id": "profile_name",
                    "text": T("Name")
                },
                {
                    "id": "profile_uuid",
                    "text": T("ID")
                }
            ]
        }
        this.itemlist.getToolbarButtons = (accountId, component) => {
            let toolbarEntries = []
            if (tenantHelpers.hasFunctionality(accountId, "dep")) {
                toolbarEntries.push(
                    {
                        icon: 'fal fa-plus',
                        title: T('Add profile'),
                        link: '#add-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-dep-profile',
                        hoverText: T("Add profile"),
                        id: 'addProfile',
                        vIf: false
                    }
                )
            }
            return toolbarEntries
        }

        // ITEMLIST-ITEM
        this.itemlistItem.getDetails = (accountId, item, component) => {
            let thisDetails = []
            let thisDevices = []
            let thisDevicesArray = []
            let tenantDomain = tenantHelpers.getTenantDomain(accountId)

            let devices = config.canUseNewObjectType("iosDevices") ? products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObjects(accountId) : useStore().getters.getObjects({
                "accountId":accountId,
                "productType":"mobileSecurity",
                "objectType":"devices",
            })

            thisDevices = (devices || []).filter(function (device: IosDevice | DepDevice) {
                return (device.depDevice && device.depDeviceInfo?.profile_uuid == item?.profile_uuid) || (item?.profile_uuid == device.profile_uuid)
            })


            thisDevicesArray = thisDevices.map(function (thisDevice: any) {

                return {
                    "id": thisDevice.deviceId,
                    "text": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || thisDevice?.serial_number, thisDevice?.alias || thisDevice?.info?.deviceName || thisDevice.description || undefined, false),
                    "title": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || thisDevice?.serial_number, thisDevice?.alias || thisDevice?.info?.deviceName || thisDevice.description || undefined, false),
                    "onClick": function () {
                        if (thisDevice?.deviceType == 'IOS') {
                            router.navigate('#show-tenant-' + tenantDomain + '-ios-devices-dashboard-' + thisDevice.deviceId)
                        }
                    },
                    "displayType": "label"
                }
            })

            thisDetails = [
                {
                    iconClass: 'fal fa-fw fa-hashtag',
                    title: T('ID'),
                    key: T('ID'),
                    value: item ? (item?.profile_uuid || '') : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                },
                {
                    iconClass: 'fal fa-fw fa-mobile-alt',
                    title: T('Devices'),
                    key: T('Devices'),
                    [item ? "labels" : "value"]: item ? thisDevicesArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                }
            ]
            return thisDetails
        }
        this.itemlistItem.getMenuEntries = (accountId, item, component) => {
            let menuLinks = []
            menuLinks.push(
                buttons.createButton({
                    title: T('Edit'),
                    text: T('Edit'),
                    link: '#edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-dep-profile-' + item?.profile_uuid,
                    icon: 'fal fa-edit'
                }),
                buttons.createButton({
                    title: T('Delete'),
                    text: T('Delete'),
                    onClick:() => {
                        this.dialogs.getDeleteObjectDialog(accountId,item)
                    },
                    icon: 'fal fa-trash',
                })
            )
            return menuLinks
        }
        this.itemlistItem.onClick = (accountId, item) => {
            if (jsonHelpers.getObjectProperty(item, 'profile_uuid') != undefined && licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid') && item?.profile_uuid) {
                router.navigate('#edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-dep-profile-' + item?.profile_uuid)
            }
        }
        this.itemlistItem.getTitle = (item, component) => {
            let result: any = {
                "title": "",
                "small": undefined,
                "link": undefined
            }
            result.title = item?.profile_name != item?.profile_uuid
                ? (item?.profile_name.length > 20 ? (item?.profile_name.substr(0, 20) + '...') : item?.profile_name)
                : T('Apple DEP Profile')
            result.small = "(" + deviceHelpers.getAliasedShortDeviceId(item?.profile_uuid, '', false) + ")"
            return result
        }
        this.itemlistItem.getLabels = (accountId, item) => {
            let labels = []
            if (item?.is_default) {
                labels.push({
                    title: T("Default"),
                    text: T("Default"),
                    class: '',
                    icon: "fa fa-star"
                })
            }
            if (item?.configuration_web_url != undefined && getterHelpers.useStore()?.state.session.accounts[getterHelpers.useStore().state.session.activeAccountId]?.mobileSecurity.settings.depEnrollmentCode?.length) {
                labels.push({
                    title: T("PIN secured"),
                    text: T("PIN secured"),
                    class: '',
                    icon: "fa fa-lock"
                })
            }

            return labels
        }

        this.queries.addObjectToApi = async (accountId, object, customerId?, updateStore = true) => {
            let result: DepProfile | Error
            try {
                let addedDevices = object.devices.filter(function (serial: string) { return serial != undefined })
                delete object.devices
                delete object.devicesBefore

                let response = await requestHandler.request(this.options.apiInfo.addObjectMethod, this.getAddObjectApiUrlPath(accountId, customerId), object)
                response = apis.parseApiResponse(response)

                if (!jsonHelpers.isObjectEmpty(response)) {
                    result = object
                    if ((<DepProfile>response).profile_uuid && addedDevices.length > 0) {
                        products.mobileSecurity.iosDevices.assignDepProfile(accountId, (<DepProfile>response).profile_uuid, addedDevices)
                    }

                    if (response[this.options.objectTypeInfo.primaryKeyProperty.property]) {
                        object[this.options.objectTypeInfo.primaryKeyProperty.property] = response[this.options.objectTypeInfo.primaryKeyProperty.property]
                    }
                    else if (typeof response == "string") {
                        object[this.options.objectTypeInfo.primaryKeyProperty.property] = response
                    }
                }
                else {
                    throw "Error getting objects"
                }
                if (updateStore) {
                    this.useStore?.().addObjectTypeObject(accountId, object)
                }
                return result
            }
            catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                throw e as Error
            }
        }

        this.queries.updateObjectFromApi = async (accountId, objectId :string, object, customerId, updateStore = true, altMethod?) => {
            let result: DepProfile | Error
            try {
                const devicesBeforeEdit = (<any>object).devicesBefore || []
                const devicesAfterEdit = (<any>object).devices || []
                const deletedDevices = devicesBeforeEdit.filter(function (serial: string) { return devicesAfterEdit.indexOf(serial) == -1 })
                const addedDevices = devicesAfterEdit.filter(function (serial: string) { return devicesBeforeEdit.indexOf(serial) == -1 })

                delete object.devicesBefore

                if (objectId) {
                    if (addedDevices.length) {
                        products.mobileSecurity.iosDevices.assignDepProfile(accountId, objectId, addedDevices)
                    }
                    if (deletedDevices.length) {
                        //mobileSecurityQueries.unassignDepProfile(accountid,objectId,deletedDevices)
                    }
                }
                return object
            }
            catch (e: any) {
                devLog.log("ObjectType", e?.message, e, "error")
                throw e as Error
            }
        }
        
    }
}

const depProfiles = new DepProfiles({
    "slug": "depProfiles",
    "objectType": "depProfiles",
    "productType": "mobileSecurity",
    "hasStore": true,
    "objectTypeInfo": {
        "nameProperty": {
            "primary": "profile_name",
            "pathToPrimaryProperty": undefined,
            "secondary": undefined,
            "pathToSecondaryProperty": undefined
        },
        "primaryKeyProperty": {
            "property": "profile_uuid",
            "pathToPrimaryProperty": undefined
        }
    },
    "appearance": {
        "iconClass": "fal fa-shield-alt",
        "color": "red",
        "showInSidebar": true,
        "showOnDashboard": true,
        "text": {
            "plural": "DEP Profiles",
            "singular": "DEP Profile",
            "sidebarName": "DEP Profiles",
            "title": "DEP Profiles"
        }
    },
    "apiInfo": {
        "url": config.mgtApiUri,
        "getCountGETProperties":"?select=length(@)",
        //GET
        "getObjectPath":"/tenants/{tenantDomain}/ios/dep/profile?profile_uuid={objectId}",
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/ios/dep/profile",
        // ADD
        "addObjectMethod": "POST",
        "addObjectPath": "/tenants/{tenantDomain}/ios/dep/profile",
        // UPDATE
        "updateObjectMethod": "POST",
        "updateObjectPath": "/tenants/{tenantDomain}/ios/dep/profile?profile_uuid={objectId}",
        //DELETE
        "deleteObjectMethod": "DELETE",
        "deleteObjectPath": "/tenants/{tenantDomain}/ios/dep/profile?profile_uuid={objectId}"
    }
})
export default depProfiles