import config from "@/classes/config";
import ObjectType, { type ObjectTypeObject, type ObjectTypePayload } from "../../objectType";
import licenseHelpers from "@/helpers/helpers.license";
import tenantHelpers from "@/helpers/helpers.tenants";
import i18n, { T } from "@/classes/i18n";
import jsonHelpers from "@/helpers/helpers.json";
import buttons from "@/classes/buttons";
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store";
import download from "downloadjs"
import dialogs from "@/dialogs/dialogs";
import router from "@/router/router";
import deviceHelpers from "@/helpers/helpers.devices";
import numberHelpers from "@/helpers/helpers.numbers";
import products from "../..";

type ProfileStatus = "PUBLISH" | "PUBLISHED" | "UPDATED" | "REVOKE" |"REVOKED"

type IosProfile = {
    checksums?: Array<string>,
    comment?: string,
    devices?: Array<string>,
    platform?: string,
    priority?: number
    profile?: any,
    profileId?: string,
    profilename?: string,
    roles?: Array<string>,
    status?: ProfileStatus,
    tags?: Array<string>,
    tenantDomain?: string,
    users?: Array<string>,
    policy?: any,
    // Clipboard
    oldItemName?: string
}

class IosProfiles extends ObjectType<IosProfile> {
    constructor(payload: ObjectTypePayload<IosProfile>) {
        super(payload)
        const thisObjectType = this

        // ITEMLIST
        thisObjectType.itemlist.getToolbarButtons = (accountId: string, itemlistComponent: any) => {
            let toolbarButtons = []
            if (licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid')) {
                toolbarButtons.push(
                    {
                        icon: 'fal fa-plus',
                        title: T('Add profile'),
                        link: '#add-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-ios-profile',
                        id: 'profilesButtonAdd',
                        vIf: false
                    }
                )
            }
            return toolbarButtons
        }
        thisObjectType.itemlist.getSortingOptions = (accountId: string, itemlistComponent: any) => {
            let sortingOptions = [
                {
                    "id": "profilename",
                    "text": T("Name")
                },
                {
                    "id": "priority",
                    "text": T("Priority")
                }
            ]
            return sortingOptions
        }
        thisObjectType.itemlist.getInfoBoxContent = (accountId: string, itemlistComponent) => {
            return ""
        }

        //ITEMLIST ITEM
        thisObjectType.itemlistItem.getMenuEntries = (accountId: string, item: ObjectTypeObject<IosProfile>, component: any) => {
            let menuLinks = []
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)
            const thisClass = this

            if (licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid')) {
                if (jsonHelpers.getObjectProperty(item, 'profilename') != 'unlicensed') {
                    menuLinks.push(
                        buttons.createButton({
                            id: 'edit',
                            title: T('Edit'),
                            text: T('Edit'),
                            link: '#edit-tenant-' + tenantDomain + '-ios-profile-' + item?.profileId,
                            icon: 'fal fa-edit',
                        })
                    )
                    menuLinks.push(
                        buttons.createButton({
                            id: 'copy',
                            title: T('Copy'),
                            text: T('Copy'),
                            onClick: function () {
                                thisClass.saveToClipboard(item)
                            },
                            icon: 'fal fa-clone'
                        })
                    )
                    menuLinks.push(
                        buttons.createButton({
                            id: 'export',
                            title: T('Export'),
                            text: T('Export'),
                            onClick: function () { thisClass.exportObject(tenantDomain, (item?.profileId || ''), 'profiles') },
                            icon: 'fal fa-download',
                        })
                    )
                    menuLinks.push(
                        buttons.createButton({
                            id: 'delete',
                            title: T('Delete'),
                            text: T('Delete'),
                            onClick: () => { 
                                this.dialogs.getDeleteObjectDialog(accountId,item)
                            },
                            icon: 'fal fa-trash',
                        })
                    )
                }
            }
            // @ts-ignore
            else if (item) {
                menuLinks.push(
                    buttons.createButton({
                        id: 'delete',
                        title: T('Delete'),
                        text: T('Delete'),
                        onClick: () => {
                            this.dialogs.getDeleteObjectDialog(accountId, item)
                        },
                        icon: 'fal fa-trash',
                    })
                )
            }
            return menuLinks
        }
        thisObjectType.itemlistItem.getDetails = (accountId: string, item: ObjectTypeObject<IosProfile>, component: any) => {
            let thisDetails = []
                let thisPartsArray: any[] = []
                let thisRolesArray: any[] = []
                let thisTagsArray: any[] = []
                let thisUsersArray: any[] = []
                let thisDevicesArray: any[] = []
                let thisPlatformHtml: string
                const tenantDomain = tenantHelpers.getTenantDomain(accountId)

                function capitalizeFirstLetter(string: string) {
                    return string.charAt(0).toUpperCase() + string.slice(1);
                }

                thisRolesArray = item?.roles ? item.roles.map(function (role: string) {
                    return {
                        "id": role,
                        "text": role,
                        "title": role,
                        "onClick": function () {
                            router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-role-' + role)
                        },
                        "displayType": "label"
                    }
                }) : []

                thisTagsArray = item?.tags ? item.tags.map(function (tag: string) {
                    return {
                        "id": tag,
                        "text": tag,
                        "title": tag,
                        "onClick": undefined,
                        "displayType": "label"
                    }
                }) : []

                thisUsersArray = item?.users ? item.users.map(function (user: string) {
                    return {
                        "id": user,
                        "text": user,
                        "title": user,
                        "onClick": function () {
                            router.navigate('#show-tenant-' + tenantDomain + '-users-dashboard-' + user)
                        },
                        "displayType": "label"
                    }
                }) : []

                if (item?.devices?.length != undefined && item.devices.length >= 5) {

                    thisDevicesArray = [{
                        "id": "none",
                        "text": item?.devices.length + " " + T('Devices'),
                        "title": item?.devices.map((deviceId: any) => {
                            // get device from store
                            const thisDevice = products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObject(accountId,deviceId)
                            return deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false)
                        }).join(', '),
                        "onClick": undefined,
                        "displayType": "label"
                    }]
                }
                else {
                    thisDevicesArray = item?.devices ? item.devices.map(function (deviceId: string) {
                        // get device from store
                        const thisDevice = products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObject(accountId, deviceId)
                        return {
                            "id": deviceId,
                            "text": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || deviceId, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
                            "title": deviceHelpers.getAliasedShortDeviceId(thisDevice?.deviceId || deviceId, thisDevice?.alias || thisDevice?.info?.deviceName || undefined, false),
                            "onClick": function () {
                                router.navigate('#show-tenant-' + tenantDomain + '-ios-devices-dashboard-' + deviceId)
                            },
                            "displayType": "label"
                        }
                    }) : []
                }

                if (item?.profile) {

                    if (Object.keys(item?.profile).length >= 5) {
                        thisPartsArray.push({
                            "id": "mulipleParts",
                            "text": Object.keys(item?.profile).length + " " + T("Parts"),
                            "title": Object.keys(item?.profile).map((profilePart) => {
                                const thisPartsString = T((
                                    capitalizeFirstLetter(profilePart)
                                        .replace("OpenNetworkConfiguration", "Network")
                                        .replace("Exchanges", "Exchange")
                                        .replace("SharedDeviceConfiguration", "Shared Device")
                                        .replace("HomeScreenLayout", "Home Screen Layout")
                                        .replace("GoogleAccounts", "Google Accounts")
                                        .replace("AirPrint", "Air Print")
                                ))
                                return thisPartsString
                            }).join(", "),
                            "displayType": "label"
                        })
                    }
                    else {
                        for (let profilePart in item?.profile) {
                            const thisPartsString = T((
                                capitalizeFirstLetter(profilePart)
                                    .replace("OpenNetworkConfiguration", "Network")
                                    .replace("Exchanges", "Exchange")
                                    .replace("SharedDeviceConfiguration", "Shared Device")
                                    .replace("HomeScreenLayout", "Home Screen Layout")
                                    .replace("GoogleAccounts", "Google Accounts")
                                    .replace("AirPrint", "Air Print")
                            ))
                            thisPartsArray.push({
                                "id": item.profileId + "_" + thisPartsString,
                                "text": thisPartsString,
                                "title": thisPartsString,
                                "displayType": "label"
                            })

                        }
                    }


                }
                thisPlatformHtml = T("Unknown")
                if (item?.platform == 'IOS') {
                    thisPlatformHtml = T('Device Profile')
                    if (item?.hasOwnProperty("iOSPerUserChannelUsers")) {
                        thisPlatformHtml = T('Shared iPad')
                    }
                    if (jsonHelpers.getObjectProperty(item, "profile.custom.mobileconfig") != undefined && !item?.hasOwnProperty("iOSPerUserChannelUsers")) {
                        //thisPlatformHtml = T('Apple Configurator 2 Profile')
                    }
                    thisDetails.push({
                        iconClass: 'fal fa-fw fa-microchip',
                        title: T('Type'),
                        key: T('Type'),
                        value: item ? thisPlatformHtml : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                    })
                }
                if (item?.platform == 'TVOS') {
                    thisDetails.push({
                        iconClass: 'fal fa-fw fa-microchip',
                        title: T('Type'),
                        key: T('Type'),
                        value: item ? "tvOS" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                    })
                }
                if (item?.platform == 'IOS_BYOD') {
                    thisDetails.push({
                        iconClass: 'fal fa-fw fa-microchip',
                        title: T('Type'),
                        key: T('Type'),
                        value: item ? "User Enrollment" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                    })
                }
                thisDetails.push(
                    {
                        iconClass: 'fal fa-fw fa-users',
                        title: T('Roles'),
                        key: T('Roles'),
                        [item ? "labels" : "value"]: item ? thisRolesArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                    },
                    {
                        iconClass: 'fal fa-fw fa-user',
                        title: T('Users'),
                        key: T('Users'),
                        [item ? "labels" : "value"]: item ? thisUsersArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                    }
                )
                thisDetails.push(
                    {
                        iconClass: 'fal fa-fw fa-mobile-alt',
                        title: T('Devices'),
                        key: T('Devices'),
                        [item ? "labels" : "value"]: item ? thisDevicesArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                    }
                )
                thisDetails.push(
                    {
                        iconClass: 'fal fa-fw fa-tags',
                        title: T('Tags'),
                        key: T('Tags'),
                        [item ? "labels" : "value"]: item ? thisTagsArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                    }
                )
                thisDetails.push(
                    {
                        iconClass: 'fal fas-fw fa-puzzle-piece',
                        title: T('Parts'),
                        key: T('Parts'),
                        [item ? "labels" : "value"]: item ? thisPartsArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                    }
                )
            return thisDetails
        }
        thisObjectType.itemlistItem.getDisabledState = (accountId: string, item: ObjectTypeObject<IosProfile>) => {
            return false;
        }
        thisObjectType.itemlistItem.getLabels = (accountId: string, item: ObjectTypeObject<IosProfile>) => {
            let thisLabels = []
            if (item?.priority != null) {
                thisLabels.push({
                    'title': T('Priority') + ': ' + item?.priority.toString(),
                    'text': item.priority.toString(),
                    'class': '',
                    'icon': "fal fa-sort"
                })
            }
            let statusClass: string = ""
            if (['UPDATED', 'REVOKE', 'PUBLISH'].indexOf(item?.status ? item.status : "") != -1) {
                statusClass = 'bg-yellow'
            }
            if (item && (item?.users?.length || item?.devices?.length || item?.roles?.length) && typeof item?.status == "string") {
                thisLabels.push({
                    'title': T(item?.status?.toLowerCase().charAt(0).toUpperCase() + item?.status?.toLowerCase().slice(1)),
                    'text': T(item?.status?.toLowerCase().charAt(0).toUpperCase() + item?.status?.toLowerCase().slice(1)),
                    'class': statusClass,
                    icon: "fa fa-info-circle"
                })
            }
            if (jsonHelpers.getObjectProperty(item, "policy.type") != undefined) {
                if (item?.policy.type == "autogenerated policy") {
                    thisLabels.push({
                        'title': T("Generated"),
                        'text': T("Generated"),
                        'class': '',
                        icon: "fa fa-cog"
                    })
                } else if (item?.policy.type == "template policy") {
                    thisLabels.push({
                        'title': T("Template"),
                        'text': T("Template"),
                        'class': '',
                        icon: "fa fa-file-export"
                    })
                }
            }
            return thisLabels
        }
        thisObjectType.itemlistItem.getTitle = (item: ObjectTypeObject<IosProfile>, component: any) => {
            let result: any = {
                "title": "",
                "small": undefined,
                "link": undefined
            }
            if (jsonHelpers.getObjectProperty(item, "policy.type") == "autogenerated policy") {
                result.title = typeof item?.profilename == "string" ? item.profilename.replace(/\^(.*)/, " ($1)") : ""
            }
            else {
                result.title = item?.profilename
            }
            return result
        }
        thisObjectType.itemlistItem.onClick = (accountId: string, item: ObjectTypeObject<IosProfile>) => {
            if (jsonHelpers.getObjectProperty(item, 'profilename') != 'unlicensed' && item?.profileId) {
                router.navigate('#edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-ios-profile-' + item?.profileId)
            }
        }
        this.itemlistItem.hasCheckbox = true
    }
    saveToClipboard = (object: ObjectTypeObject<IosProfile>, addNotification: boolean = true) => {
        const accountId = useStore()?.state.session.activeAccountId || ''
        if (typeof object == "object") {
            object = JSON.parse(JSON.stringify(object))
        }
        object.oldItemName = object.profilename
        object.profilename = object.profilename + T("_Copy")
        object.devices = []
        delete object.profileId

        useStore().commit(MutationTypes.setClipboard, {
            "objectType": "iosProfiles",
            "objects": [object]
        })
        if(addNotification) {
            useStore().dispatch(ActionTypes.addNotification, {
                "accountId": accountId,
                "content": {
                    "title": {
                        "icon": "fal fa-exclamation-triangle",
                        "text": "Clipboard"
                    },
                    "body": {
                        "content": "Added item to clipboard"
                    }
                }
            })
        }
    }

    exportObject = async (activeAccountId: string, objectId: string, objectType: string) => {

        try {
            let object = await this.queries.getObjectFromApi(activeAccountId, objectId)
            if (object instanceof Error) {
                throw "Error getting Object"
            }

            let extension: string = objectType.substring(0, objectType.length - 1)

            delete object.profileId
            delete object.tenantDomain
            delete object.checksums

            if (object.devices?.length) {
                object.devices = []
            }
            if (object.roles?.length) {
                object.roles = []
            }
            if (object.users?.length) {
                object.users = []
            }

            download(new Blob([JSON.stringify(object)]), `${object.profilename}.${extension == 'androidProfile' ? 'enterpriseProfile' : extension}`, "application/json")
        }
        catch (e: any) {
            console.error(e)
        }
    }
}

const iosProfiles = new IosProfiles({
    "productType": "mobileSecurity",
    "slug": "iosProfiles",
    "objectType": "iosProfiles",
    "hasStore": true,
    "appearance": {
        "showInSidebar": true,
        "showOnDashboard": true,
        "text": {
            "plural": "Profiles",
            "sidebarName": "Profiles",
            "singular": "Profile",
            "title": "Profiles"
        },
        "color": "red",
        "iconClass": "fal fa-shield-alt fa-fw"
    },
    "apiInfo": {
        "url": config.mgtApiUriNext,
        "getCountGETProperties": "?select=data.count",
        // GET
        "getObjectListResponseProperty": "profiles",
        "getObjectMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/apple/profiles",
        // ADD
        "addObjectMethod": "POST",
        "addObjectPath": "/tenants/{tenantDomain}/apple/profiles",
        // UPDATE
        "updateObjectMethod": "PUT",
        "updateObjectPath": "/tenants/{tenantDomain}/apple/profiles/{objectId}",
        //DELETE
        "deleteObjectMethod": "DELETE",
        "deleteObjectPath": "/tenants/{tenantDomain}/apple/profiles/{objectId}"
    },
    "objectTypeInfo": {
        "nameProperty": {
            "primary": "profilename"
        },
        "primaryKeyProperty": {
            "property": "profileId"
        }
    }
})
export default iosProfiles