<script setup lang="ts">
import { T } from '@/classes/i18n';
import { ActionTypes, useStore } from '@/store/vuex.store';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import inputTextComponent from '../inputtypes/input-text';
import queries from '@/queries/queries';
import router from '@/router/router';
import { useVue } from '@/app';

const props = defineProps<{
    properties: {
        fromEmailButton: boolean
    }
}>();

const gMail = ref(<string>"")
const inputElement = ref(<any | null>null)
const error = ref(false)

const activeAccountId = computed(function () {
    return useStore().state.session.activeAccountId || ""
})


async function submit() {
    let result: boolean | Object = false
    error.value = false
    try {
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = true
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].loading = true
        useStore().getters.getActiveModal(activeAccountId.value).buttons[0].disabled = true
        useStore().getters.getActiveModal(activeAccountId.value).buttons[0].loading = true
        
        // SetTenantProperty only returns a boolean | Object and the request is wrapped in try catch -> Throw error itself
        result = await queries.unifiedSecurity.setTenantProperty(activeAccountId.value, "enterpriseEmail", gMail.value)
        if(result == false) {
            throw result
        }
        
        await useStore().dispatch(ActionTypes.updateAccount, activeAccountId.value)
        router.navigate('show-tenant-' + activeAccountId.value + '.sms-mobile-security-settings')
        useVue().$refs.modals.removeModal()
        result = true
    }
    catch (e: unknown) {
        console.error(e)
        error.value = true

        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = false
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].loading = false
        useStore().getters.getActiveModal(activeAccountId.value).buttons[0].disabled = false
        useStore().getters.getActiveModal(activeAccountId.value).buttons[0].loading = false
    }
    return result
}

onMounted(() => {
    setTimeout(() => {
        if(inputElement.value?.$el?.focus) {
            inputElement.value.$el.focus()
        }
    }, 1)
})

defineExpose({
    submit
})

</script>

<template>
    <p>
        <div v-if="properties?.fromEmailButton !== true">
            {{ T('You successfully added a Google Enterprise account') + "." }}
            <br>
        </div>
        {{ T('Please enter the e-mail address of your Google account that you used during setup. This information will help you to remember which Google account you used at a later date. Our system cannot automatically detect the e-mail address used.') }}
    </p>
    <div class="row padding-xs-y form-group margin-xs-t-2">
        <div class="first col-md-6 col-xs-6">
            <label class="control-label inputname">
                {{ T("E-Mail Address") + ":" }}
            </label>
        </div>
        <div class="input col-md-11 col-xs-18">
            <inputTextComponent @keydown.enter="submit" ref="inputElement" v-model="gMail"></inputTextComponent>
        </div>
    </div>
    <div class="alert notification content bg-red" v-if="error">
            <i class="fal fa-fw fa-exclamation-triangle"></i> {{ T('An unexpected error occoured while setting the android enterprise e-mail address. Please ensure that the e-mail does not exceed 320 characters.') }}
    </div>
</template>