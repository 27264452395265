import type { ProductType } from "@/resources/registeredProducts"
import type { AppState, ObjectStoreCountProperties } from "../../../types/vuex"
import storeHelpers from "@/helpers/helpers.store"
import tenantHelpers from "@/helpers/helpers.tenants"
import arrayHelpers from "@/helpers/helpers.arrays"
import jsonHelpers from "@/helpers/helpers.json"
import type { Modal } from "@/templates/templates/modal"
import useRouterStore from "@/router/routerStore"
import type { UscUtm } from "@/classes/unifiedSecurityConsole/uscUtms"

export type Getters = {
    hasAccountid(state: AppState): (accountId: string) => boolean,
    getActiveAccountId(state: AppState): string,
    getCount(state:AppState): (payload:{
        "accountId":string,
        "productType":ProductType,
        "objectType":string,
        "countProperty":ObjectStoreCountProperties
    }) => number | undefined,
    getObjects(state: AppState): (payload: {
        "accountId": string,
        "productType": ProductType,
        "objectType": string
    }) => Array<any>,
    hasObject(state: AppState): (payload: {
        "accountId": string,
        "productType": ProductType,
        "objectType": string,
        "objectId": string,
        "objectIdProperty"?:string
    }) => boolean,
    getObject(state: AppState): (payload: {
        "accountId": string,
        "productType"?: ProductType,
        "objectType": string,
        "objectId": string,
        "property"?: string
    }) => any,
    getActiveModal(state: AppState): (accountId?: string) => Modal,
    getObjectTypeStore(state: AppState): (payload: {
        "accountId": string,
        "objectType": string,
        "sort"?: {
            "sortBy":string,
            "direction":"ASC"|"DESC"
        },
        "filter"?: {
            "searchString": string
        }
    }) => any,
    getOnClickHandlers(state: AppState): (ref: keyof typeof state.template.onClick) => any,
    hasSession(state: AppState) : boolean,
    getAccountInfo(state: AppState): (accountId: string) => any|undefined
}

const vuexGetters = <Getters> {
    hasAccountid: (state) => (accountId) => {
        return state.session.accounts.hasOwnProperty(accountId)
    },
    getActiveAccountId: (state) => {
        return state.session.activeAccountId
    },
    getCount: (state) => (payload) => {
        let objectType = payload.objectType
        if (['iosDevices'].indexOf(payload.objectType) != -1) {
            objectType = "devices"
        }
        else if (['androidProfiles', 'iosProfiles'].indexOf(payload.objectType) != -1) {
            objectType = "profiles"
        }

        const objectTypeStore = storeHelpers.getObjectTypeStore(payload.accountId, payload.productType, objectType)
        if (!objectTypeStore) throw "missing objectTypestore " + objectType
        if (objectTypeStore) {
            return objectTypeStore.items.length > (<number>objectTypeStore[payload.countProperty]) && objectType != 'profiles' ? objectTypeStore.items.length : (<number>objectTypeStore[payload.countProperty])
        }
        return undefined
    },
    getActiveModal:(state) => (accountId) => {
        if(accountId) return state.session.accounts[accountId].modals[0] || undefined
        return state.session.accounts[state.session.activeAccountId || ''].modals[0] || undefined
    },
    getObjects: (state) => (payload) => {
        const objectTypeStore = storeHelpers.getObjectTypeStore(payload.accountId, payload.productType, payload.objectType)
        if (!objectTypeStore) throw "missing objectTypestore " + payload.objectType
        return objectTypeStore.items || []
    },
    hasObject: (state) => (payload) => {
        const objectTypeStore = storeHelpers.getObjectTypeStore(payload.accountId, payload.productType, payload.objectType)
        if (!objectTypeStore) throw "missing objectTypestore " + payload.objectType
        const items = objectTypeStore.items || []
        const objectIdProperty = payload.objectIdProperty || tenantHelpers.getObjectIdPropertyForObjectType(payload.objectType)
        return items.some((item: any) => { return payload.objectId != undefined && item[objectIdProperty] == payload.objectId })
    },
    getObject: (state) => (payload) => {
        let accountId : string = payload.accountId,
            objectType: string = payload.objectType == 'iosProfiles' ? 'profiles' : payload.objectType == 'iosDevices' ? 'devices' : payload.objectType,
        productType = payload.productType || tenantHelpers.getProductTypeFromObjectType(objectType),
        objectId : string = decodeURI(payload.objectId),
        property : any = payload.property || tenantHelpers.getObjectIdPropertyForObjectType(objectType),
        result : any = undefined
        if (!productType) throw "missing productType " + productType
        const objectTypeStore = storeHelpers.getObjectTypeStore(accountId, productType, objectType)
        
        let items = objectTypeStore?.items || []
        if (objectId && items.length) {
            result = items.find((thisObject:any) => {
                if(objectType == 'licenses') {
                    return objectId != undefined && (thisObject['uuid'] == objectId || thisObject['id'] == objectId)
                }               
                return objectId != undefined && thisObject[property] == objectId
            }) || undefined
        }
        return result
    },
    getObjectTypeStore: (state) => (payload) => {
        let objectType : string = payload.objectType
        const sortingOptions = payload.sort
        const filterOptions = payload.filter
        if(['iosDevices'].indexOf(payload.objectType) != -1) {
            objectType = "devices"
        }
        else if(['androidProfiles','iosProfiles'].indexOf(payload.objectType) != -1) {
            objectType = "profiles"
        }
        let productType = tenantHelpers.getProductTypeFromObjectType(objectType)
        let result : any = undefined
        if (!productType) return {
            "items": [],
            "count": undefined,
            "androidCount": undefined,
            "iosCount": undefined
        }
        const objectTypeStore = storeHelpers.getObjectTypeStore(payload.accountId, productType, objectType)
        try {
            result = {
                "items": objectTypeStore?.items ? [...objectTypeStore.items] : [],
                "count":objectTypeStore?.count,
                "androidCount":objectTypeStore?.androidCount,
                "iosCount": objectTypeStore?.iosCount,
            }
        }
        catch(e:any) {
            result = undefined
        }
        if (["iosDevices","androidProfiles","iosProfiles"].indexOf(payload.objectType) != -1) {
            result.items =  result.items.filter((object : any) => {
                if ('iosDevices' == payload.objectType) {
                    return ["IOS","UNKNOWN"].indexOf(object.deviceType) != -1
                }
                else if ('androidProfiles' == payload.objectType) {
                    return ["ANDROID_ENTERPRISE"].indexOf(object.platform) != -1
                }
                else if ('iosProfiles' == payload.objectType) {
                    return ["IOS", "IOS_SHARED_IPAD", "TVOS", "IOS_CUSTOM_MOBILECONFIG", "IOS_BYOD"].indexOf(object.platform) != -1
                }
                else {
                    return object
                }
            })
        }


        // sorting
        if (sortingOptions && sortingOptions?.sortBy != "") {
            const activePage = useRouterStore()?.getActivePage
            if (activePage === "uscUtms" && result.items.some((el: UscUtm) => { return el.cluster?.isCluster === true })) {
                result.items = arrayHelpers.sortUtmsByObjProperty(result.items, sortingOptions?.sortBy, sortingOptions?.direction)
            }
            else {
                result.items = arrayHelpers.sortByObjProperty(result.items, sortingOptions.sortBy, sortingOptions.direction)
            }
        }

        // filter
        if (filterOptions && filterOptions?.searchString != "" && filterOptions.searchString.length > 2) {
            const allItems: any[] = result.items

            let objectIdProperty = tenantHelpers.getObjectIdPropertyForObjectType(payload.objectType)
            let searchForValues = filterOptions.searchString.toLowerCase().split(' ')

            result.items = result.items.filter((item: any) => {
                let result: boolean = false

                if (item) {

                    // Cluster Utm Special Filter and Current UTM is Cluster UTM
                    if (item?.cluster?.isCluster === true && typeof item.license?.clusterUuid === "string") {

                        // Get All Utms Assigned To Cluster
                        const clusterUtms = allItems.filter((utm: UscUtm) => {
                            return utm.license.clusterUuid == item.license.clusterUuid
                        })

                        // Same Filter Algorithm but for every UTM in the Cluster
                        clusterUtms.forEach((utm: UscUtm) => {
                            let searchArray = tenantHelpers.generateSearchArrayFromObject(utm)
                            let searchResults = function () {
                                return searchForValues.map(() => { return false })
                            }()

                            searchForValues.forEach((searchForValue, index) => {
                                searchResults[index] = searchArray.some((entry: any) => {
                                    return entry.indexOf(searchForValue) != -1
                                })
                            });

                            result = searchResults.indexOf(false) == -1 || result
                        })
                    }

                    // None Cluster Utm Filter
                    else {
                        let searchArray = tenantHelpers.generateSearchArrayFromObject(item)
                        let searchResults = function () {
                            return searchForValues.map(() => { return false })
                        }()

                        searchForValues.forEach((searchForValue, index) => {
                            searchResults[index] = searchArray.some((entry: any) => {
                                return entry.indexOf(searchForValue) != -1
                            })
                        });

                        result = searchResults.indexOf(false) == -1
                    }
                }
                return result
                })
        }
        return result
    },
    getOnClickHandlers: (state) => (ref) => { 
        return state.template.onClick[ref] 
    },
    hasSession: (state) => {
        return !jsonHelpers.isObjectEmpty(state.session.userInfo)
    },
    getAccountInfo: (state) => (accountId:string) => {
        return state.session.accounts[accountId as keyof typeof state.session.accounts]?.unifiedSecurity.account
    }
}

export default vuexGetters