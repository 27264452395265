const regularlyUpdatedDataComponent = {
    name:"regularly-updated-data",
    template:`<span v-html="html"></span>`,
    data:() => { return {
        "isActive":false,
        "html":""
    }},
    props: {
        "inputValue":{
            "required":true,
            "default":""
        },
        "method":{
            "required":false,
            "default":undefined
        },
        "refreshAfterMs":{
            required:false,
            default:5000
        }
    },
    methods:{
        updateData: function(this:any) {
            this.html = typeof this.method == "function" ? this.method(this.inputValue) : this.inputValue
            this.isActive ? setTimeout(this.updateData, Number(this.refreshAfterMs)) : null;
        }
    },
    created: function(this:any) {
        this.isActive = true
        this.updateData()
    },
    beforeUnmount: function(this:any) {
        this.isActive = false
    }
}
export default regularlyUpdatedDataComponent