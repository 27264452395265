import requestHandler from "@/queries/requests";
import type { AccountId } from "../../objectType";
import type { VppAsset } from "./vppAssets";
import type { VppUser } from "./vppUsers";
import type { VppAssignment } from "./vppAssignments";

export interface VppUserOnAdd {
    "clientUserId": string,
    "email": string,
    "managedAppleId": string
}
export interface VppUserOnUpdate {
    "clientUserId": string,
    "email"?: string,
    "managedAppleId"?: string
}
export interface VppUserOnRetire {
    "clientUserId": string
}

const vppApi = {
    getTokenInfo: async (accountId:AccountId) => {
        return await requestHandler.request("GET","/sms-mgt-api/api/2.0/tenants/"+accountId+".sms/apple/vpp/v2")
    },
    setTokenInfo: async (accountId:AccountId,file:string) => {
        return await requestHandler.request("POST","/sms-mgt-api/api/2.0/tenants/"+accountId+".sms/apple/vpp/v2",{
            "file":file
        })
    },
    deleteTokenInfo: async (accountId: AccountId) => {
        return await requestHandler.request("DELETE", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2")
    },
    configureTokenToV2: async (accountId:AccountId) => {
        return await requestHandler.request("GET","/sms-mgt-api/api/2.0/tenants/"+accountId+".sms/apple/vpp/v2/setup")
    },
    getVppAssets: async (accountId: AccountId) => {
        return await requestHandler.request("GET", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/assets")
    },
    getAllVppAssets: async (accountId: AccountId,page : number = 0) : Promise<VppAsset[]> => {  
        let assets: VppAsset[] = []
        const assetChunk = await requestHandler.request("GET", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/assets" + (page ? ("?pageIndex=" + page) : ("") ))
        assets = assets.concat(assetChunk.assets)
        if((assetChunk.nextPage || 0) > 0) {
            let childAssets = await vppApi.getAllVppAssets(accountId,assetChunk.nextPage)
            if(childAssets.length) {
                assets = assets.concat(childAssets)
            }
        }
        return assets
    },
    getVppUsers: async (accountId: AccountId) => {
        return await requestHandler.request("GET", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/users")
    },
    getAllVppUsers: async (accountId: AccountId, page: number = 0) => {
        let users: VppUser[] = []
        const userChunk = await requestHandler.request("GET", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/users" + (page ? ("?pageIndex=" + page) : ("")))
        users = users.concat(userChunk.users)
        if ((userChunk.nextPage || 0) > 0) {
            let childUsers = await vppApi.getAllVppUsers(accountId, userChunk.nextPage)
            if (childUsers.length) {
                users = users.concat(childUsers)
            }
        }
        return users
    },
    addVppUsers: async (accountId: AccountId, vppUsers: VppUserOnAdd[]) => {
        return await requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/users",{ users:vppUsers })
    },
    updateVppUsers: async (accountId: AccountId, vppUsers: VppUserOnUpdate[]) => {
        return await requestHandler.request("PUT", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/users", { users:vppUsers })
    },
    retireVppUsers: async (accountId: AccountId, vppUsers: VppUserOnRetire[]) => {
        return await requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/users/retire",{ users:vppUsers })
    },
    getVppAssignments: async (accountId: AccountId) => {
        return await requestHandler.request("GET", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/assignments")
    },
    getAllVppAssignments: async (accountId: AccountId, page: number = 0) => {
        let assignments: VppAssignment[] = []
        const assignmentChunk = await requestHandler.request("GET", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/assignments" + (page ? ("?pageIndex=" + page) : ("")))
        assignments = assignments.concat(assignmentChunk.assignments)
        if ((assignmentChunk.nextPage || 0) > 0) {
            let childAssignments = await vppApi.getAllVppAssignments(accountId, assignmentChunk.nextPage)
            if (childAssignments.length) {
                assignments = assignments.concat(childAssignments)
            }
        }
        return assignments
    },
    getVppServiceConfig: async (accountId: AccountId) => {
        return await requestHandler.request("GET", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/config/service")
    },
    getVppClientConfig: async (accountId: AccountId) => {
        return await requestHandler.request("GET", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/config/client")
    },
    regenerateVppSecret: async (accountId: AccountId) => {
        return await requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/config/regenSecret")
    },
    getVppApps: async (accountId: AccountId) => {
        return await requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/apps")
    },
    associateVppAssets: async (accountId: AccountId, payload: { "assets": Array<{ "adamId": string, "pricingParam": "STDQ" | "PLUS" }>, "clientUserIds": Array<string>, "serialNumbers": Array<string> }) => {
        return await requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/associate",payload)
    },
    disassociateVppAssets: async (accountId: AccountId, payload: { "assets": Array<{ "adamId": string, "pricingParam": "STDQ" | "PLUS" }>, "clientUserIds": Array<string>, "serialNumbers": Array<string> }) => {
        return await requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/disassociate",payload)
    },
    sendVppInvite: async (accountId: AccountId, payload: { "to": string, "inviteCode": string}) => {
        return await requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + accountId + ".sms/apple/vpp/v2/invite")
    },

}
export default vppApi