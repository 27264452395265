interface TaskLooperTask {
    "id":string, 
    "method":() => any
    preventTaskOnHiddenTab?:boolean
}

interface BackoffStrategy {
    enabled: boolean
    rate: number
}

class TaskLooper {
    private tasks : TaskLooperTask[] = []
    public msRate = 200
    private isRunning = false
    private backoffStrategy: BackoffStrategy

    constructor(msRate:number, backoffStrategy:BackoffStrategy = {
        enabled: false,
        rate: 0
    }) {
        this.msRate = msRate
        this.backoffStrategy = backoffStrategy
    }

    startLimiter(index:number = 0,deleteAfterExecution:boolean=false) {
        this.isRunning = true
        this.limiterLoop(index,deleteAfterExecution)
    }

    limiterLoop(index:number = 0,deleteAfterExecution:boolean=false) {
        if(this.isRunning == true) {
            const task = this.tasks[index]

            if (task != undefined && typeof task.method == "function")  {
                try {
                    if (task.preventTaskOnHiddenTab && !this.isTabVisible()) {
                        console.log("Tab is hidden, preventing task", task.id)
                    } else {
                        task.method()
                        if(deleteAfterExecution) {
                            this.tasks.shift()
                        }
                    }
                }
                catch(e:any) {
                    console.error(e)
                }
            }

            if (this.backoffStrategy.enabled) {
                this.msRate *= this.backoffStrategy.rate
            }

            setTimeout(() => {
                this.limiterLoop(index + 1 >= this.tasks.length ? 0 : index + 1)
            }, this.msRate)
        }
    }

    addTask(task:TaskLooperTask) {
        this.tasks.push(task)
    }

    removeTask(taskId:string) {
        this.tasks = this.tasks.filter((task:TaskLooperTask) => {
            return task.id != taskId
        })
    }

    isTabVisible() {
        return !document.hidden
    }
}
export default TaskLooper