import { defineStore } from 'pinia'

export type AddEditRuleState = {
    "sourceUTM"?: string,
    "sourceNode"?: string,
    "destinationUTM"?: string,
    "destinationNode"?: string,
    "service"?: string
}

export const useModalStateStore = defineStore('modalState', {
    state: () => ({} as { [key: string]: any }),
    actions: {
        addStateToStore(id: string, thisState: any) {
            if (!this.$state[id]) {
                this.$state[id] = thisState
            }
        },
        removeStateFromStore(id: string) {
            this.$state[id] = undefined
        },
        getStateById(id: string) {
            return this.$state[id]
        }
    }
})