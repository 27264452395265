import devLog from "@/classes/log"
import ObjectType, { type ObjectTypeObject, type ObjectTypePayload } from "../../objectType"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import apis from "@/classes/apis"
import jsonHelpers from "@/helpers/helpers.json"
import { T } from "@/classes/i18n"
import { Button } from "@/classes/buttons"
import dialogs from "@/dialogs/dialogs"
import products from "../.."
import type { ZeroTouchDevice } from "./zerotouchDevices"
import type { AndroidDevice, EnterpriseDevicePlusZeroTouch } from "./androidDevices"
import deviceHelpers from "@/helpers/helpers.devices"
import router from "@/router/router"
import stringHelpers from "@/helpers/helpers.strings"
import numberHelpers from "@/helpers/helpers.numbers"
import androidDevices from "./androidDevices"
import config from "@/classes/config"

interface ZeroTouchConfiguration extends ObjectType<ZeroTouchConfiguration> {
    "companyName": string,
    "configurationId": string,
    "configurationName": string,
    "contactEmail": string,
    "contactPhone": string,
    "customMessage": string,
    "dpcExtras": string,
    "dpcResourcePath": string,
    "name": string,
    "forcedResetTime": string,
    "enrollmentToken"?: any,
    "isDefault"?:boolean
}
interface ZeroTouchConfigurationOnUpdate extends ZeroTouchConfiguration {
    "devices"?:string[],
    "enrollmentTokenId"?:string
}
interface ZeroTouchConfigurationOnAdd extends ZeroTouchConfiguration {
    "devices"?:string[],
    "enrollmentTokenId"?:string
}

class ZeroTouchConfigurations extends ObjectType<ZeroTouchConfiguration> {
    constructor(payload: ObjectTypePayload<ZeroTouchConfiguration>) {
        super(payload)

        this.itemlist.getToolbarButtons = (accountId,itemlistComponent) => {
            let thisToolbarEntries = []
            if (tenantHelpers.hasFunctionality(accountId, "enterprise")) {
                thisToolbarEntries.push(
                    {
                        icon: 'fal fa-plus',
                        title: T('Add config'),
                        link: '#add-tenant-' + accountId + '.sms-zerotouchconfiguration',
                        hoverText: T("Add config"),
                        id: 'addConfig',
                        vIf: false
                    }
                )
            }
            return thisToolbarEntries
        }

        this.itemlist.getSortingOptions = (accountId, itemlistComponent) => {
            return [
                {
                    "id": "configurationName",
                    "text": T("Name")
                },
                {
                    "id": "companyName",
                    "text": T("Company")
                }
            ]
        }

        this.itemlistItem.onClick = (accountId,item) => {
            if (item?.configurationId) {
                router.navigate('edit-tenant-' + accountId + '.sms-zerotouchconfiguration-' + (item?.name || "").split('/')[1] + '-' + item?.configurationId)
            }
        }

        this.itemlistItem.getLabels = (accountId,item) => {
            let thisLabels = []

            if (item.enrollmentToken && item.enrollmentToken !== null) {
                let timestamp = new Date(item.enrollmentToken.expirationTimestamp).getTime()
                if (timestamp - Date.now() <= 0) {
                    thisLabels.push({
                        title: T("Enrollmenttoken expired"),
                        text: T("Enrollmenttoken expired"),
                        class: "",
                        icon: "fa fa-exclamation-triangle"
                    })
                }
                else {
                    thisLabels.push({
                        title: T("Valid"),
                        text: T("Valid"),
                        class: "bg-green",
                        icon: "fa fa-check"
                    })
                }
            }
            else if (item?.dpcExtras && item?.dpcExtras != "undefined" && item?.enrollmentToken) {

                let dpcInfos = JSON.parse(item?.dpcExtras || "{}")
                let enrollmentTokenId = dpcInfos?.["android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE"]?.["com.google.android.apps.work.clouddpc.EXTRA_ENROLLMENT_TOKEN"]
                if (!enrollmentTokenId) {
                    thisLabels.push({
                        title: T("No Enrollmenttoken"),
                        text: T("No Enrollmenttoken"),
                        class: "",
                        icon: "fa fa-exclamation-triangle"
                    })
                }
            }
            else {
                thisLabels.push({
                    title: T("No Enrollmenttoken"),
                    text: T("No Enrollmenttoken"),
                    class: "",
                    icon: "fa fa-exclamation-triangle"
                })
            }
            return thisLabels
        }

        this.itemlistItem.getMenuEntries = (accountId,item) => {
            let menuLinks = []
            menuLinks.push(new Button({
                title: T('Edit'),
                text: T('Edit'),
                link: '#edit-tenant-' + accountId + '.sms-zerotouchconfiguration-' + (item?.name || "").split('/')[1] + '-' + item?.configurationId,
                icon: 'fal fa-edit',
            }))

            let thisDevices: Array<AndroidDevice> | Array<ZeroTouchDevice | EnterpriseDevicePlusZeroTouch> = products.mobileSecurity.androidDevices.useStore?.().getObjectStoreObjects(accountId) || []
            thisDevices = (thisDevices?.filter((device) => {
                let deviceConfiguration = undefined
                if (device.zeroTouchDevice == true && (<ZeroTouchDevice>device).configuration != undefined) {
                    deviceConfiguration = (<ZeroTouchDevice>device).configuration
                }
                else if (device.zeroTouchDevice == true && (<EnterpriseDevicePlusZeroTouch>device).zeroTouchInfo != undefined) {
                    deviceConfiguration = (<EnterpriseDevicePlusZeroTouch>device).zeroTouchInfo.configuration
                }
                return deviceConfiguration && deviceConfiguration == item?.name
            }) || []) as Array<ZeroTouchDevice | EnterpriseDevicePlusZeroTouch>
            
            if (thisDevices.length == 0) {
                menuLinks.push(new Button({
                    title: T('Delete'),
                    text: T('Delete'),
                    onClick: () => {
                        this.dialogs.getDeleteObjectDialog(accountId, item, (item?.name || "").split('/')[1])
                    },
                    icon: 'fal fa-trash',
                }))
            }

            return menuLinks
        }

        this.itemlistItem.getDetails = (accountId,item,component) => {
            let thisDevices: Array<AndroidDevice> | Array<ZeroTouchDevice | EnterpriseDevicePlusZeroTouch> = products.mobileSecurity.androidDevices.useStore?.().getObjectStoreObjects(accountId) || []
            thisDevices = (thisDevices?.filter((device) => {
                let deviceConfiguration = undefined              
                if ((<ZeroTouchDevice>device).configuration != undefined) {
                    deviceConfiguration = (<ZeroTouchDevice>device).configuration
                }
                else if ((<EnterpriseDevicePlusZeroTouch>device).zeroTouchInfo != undefined) {
                    deviceConfiguration = (<EnterpriseDevicePlusZeroTouch>device).zeroTouchInfo.configuration
                }
                return deviceConfiguration && deviceConfiguration == item?.name
            }) || []) as Array<ZeroTouchDevice | EnterpriseDevicePlusZeroTouch>

            let thisDevicesArray = (<Array<ZeroTouchDevice | EnterpriseDevicePlusZeroTouch>>thisDevices).map((device) => {

                let thisTitle = deviceHelpers.getAliasedShortDeviceId(device?.deviceId || device.deviceId, (<EnterpriseDevicePlusZeroTouch>device)?.alias || (<ZeroTouchDevice>device).deviceIdentifier?.imei || (<ZeroTouchDevice>device).deviceIdentifier?.serialNumber || "", false)
                return {
                    "id": device.deviceId,
                    "text": thisTitle,
                    "title": thisTitle,
                    "onClick": function () {
                        router.navigate('#show-tenant-' + accountId + '.sms-android-devices-' + ((<ZeroTouchDevice>device).deviceIdentifier?.imei ? stringHelpers.removeSpaces((<ZeroTouchDevice>device).deviceIdentifier?.imei || "") : device.deviceId))
                    },
                    "displayType": "label"
                }
            })

            let dpcInfos = (item?.dpcExtras && item.dpcExtras != "undefined" ? JSON.parse(item?.dpcExtras || "{}") : undefined);
            let enrollmentTokenId = dpcInfos ? dpcInfos?.["android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE"]?.["com.google.android.apps.work.clouddpc.EXTRA_ENROLLMENT_TOKEN"] : undefined

            return [
                {
                    iconClass: 'fal fas-fw fa-building',
                    title: T('Company'),
                    key: T('Company'),
                    value: item ? (item?.companyName) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                },
                {
                    iconClass: 'fal fas-fw fa-check',
                    title: T('Default'),
                    key: T('Default'),
                    value: item ? (item?.isDefault ? T("Yes") : T("No")) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                },
                {
                    iconClass: 'fal fas-fw fa-check',
                    title: 'Enrollmenttoken',
                    key: 'Enrollmenttoken',
                    value: item && enrollmentTokenId && item?.enrollmentToken ? enrollmentTokenId : ""
                },
                {
                    iconClass: 'fal fas-fw fa-mobile',
                    title: T('Devices'),
                    key: T('Devices'),
                    [item ? "labels" : "value"]: item ? thisDevicesArray : ""
                }
            ]
        }


        this.queries.getCountFromApi = async (accountId, updateStore = false) => {
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)
            let customerId :any = undefined
            let result: number | Error
            try {
                result = 0
                let getNextBatch: Function = async function (nextPageToken: string) {
                    if (customerId) {
                        let currentResult: any = await requestHandler.request("GET", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('zeroTouch') + "/tenants/" + tenantDomain + "/android/androiddeviceprovisioning/customers/" + customerId + "/configurations" + "?pageSize=1" + (nextPageToken ? ('&nextPageToken=' + nextPageToken) : ''))
                        currentResult = apis.parseApiResponse(currentResult)
                        if (currentResult.total != undefined) {
                            result += currentResult.total
                        }
                    }
                }
                if (customerId) {
                    await getNextBatch('')
                }
                else {
                    let zeroTouchCustomers: any = config.canUseNewObjectType('zeroTouchCustomers') ? products.mobileSecurity.zeroTouchCustomers.useStore?.().getObjectStoreObjects(accountId) : useStore()?.getters.getObjects({
                        "accountId": accountId,
                        "objectType": "zeroTouchCustomers",
                        "productType": "mobileSecurity"
                    })
                    if (config.canUseNewObjectType('zeroTouchCustomers')) {
                        for (let i: number = 0; (zeroTouchCustomers?.length || 0) > i; i++) {
                            let account = zeroTouchCustomers[i]
                            for (let j: number = 0; (account.customers?.length || 0) > j; j++) {
                                customerId = account.customers[j].companyId
                                await getNextBatch('')
                            }
                        }
                    }
                    else {
                        for (let i: number = 0; (zeroTouchCustomers?.length || 0) > i; i++) {
                            customerId = zeroTouchCustomers[i].companyId
                            await getNextBatch('')
                        }
                    }
                }
                if (!Number.isInteger(result)) {
                    throw "Error getting count"
                }

                if(updateStore === true) {
                    this.useStore?.().setObjectTypeCount(accountId,result)
                }

                return result
            }
            catch (e: any) {
                let error = e?.data ? apis.parseApiResponse(e.data) : e
                if (error?.error?.code == 403 && error.error.status == "PERMISSION_DENIED"
                    && typeof error.error.message == "string"
                    && error.error.message.includes("The user must agree the terms of service for company id")
                    && error.error.message.includes("at https://partner.android.com/zerotouch")) {
                    let companyId = error.error.message.replace("The user must agree the terms of service for company id ", "").replace("at https://partner.android.com/zerotouch", "")
                    dialogs.misc.errorDialog(accountId,
                        T("Permission Denied"),
                        T('The user must agree the terms of service for company id %s at <a href="https://partner.android.com/zerotouch" target="_blank">https://partner.android.com/zerotouch</a>').replace("%s", companyId)
                    )
                }
                devLog.log("ObjectType", e.message, e, "error")
                throw e
            }
        }
        this.queries.getObjectsFromApi = async (accountId, customerId?, props?, updateStore = false, removeOldObjects=false) => {
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            let result: ZeroTouchConfiguration[] | Error

            try {
                result = []
                let getNextBatch: Function = async function (nextPageToken: string) {
                    if (customerId) {
                        let currentResult: any = await requestHandler.request("GET", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('zeroTouch') + "/tenants/" + tenantDomain + "/android/androiddeviceprovisioning/customers/" + customerId + "/configurations" + (propertiesString ? (propertiesString + "&") : "?") + "pageSize=75" + (nextPageToken ? ('&nextPageToken=' + nextPageToken) : ''))
                        currentResult = apis.parseApiResponse(currentResult)
                        if (currentResult.configurations.length && Array.isArray(result)) {
                            result = result.concat(currentResult.configurations)
                        }
                        if (currentResult.nextPageToken != null) {
                            await getNextBatch(currentResult.nextPageToken)
                        }
                    }
                }
                if (customerId) {
                    await getNextBatch('')
                }
                else {
                    let zeroTouchCustomers: any = config.canUseNewObjectType('zeroTouchCustomers') ? products.mobileSecurity.zeroTouchCustomers.useStore?.().getObjectStoreObjects(accountId) : useStore()?.getters.getObjects({
                        "accountId": accountId,
                        "objectType": "zeroTouchCustomers",
                        "productType": "mobileSecurity"
                    })
                    if (config.canUseNewObjectType('zeroTouchCustomers')) {
                        for (let i: number = 0; (zeroTouchCustomers?.length || 0) > i; i++) {
                            let account = zeroTouchCustomers[i]
                            for (let j: number = 0; (account.customers?.length || 0) > j; j++) {
                                customerId = account.customers[j].companyId
                                await getNextBatch('')
                            }
                        }
                    }
                    else {
                        for (let i: number = 0; (zeroTouchCustomers?.length || 0) > i; i++) {
                            customerId = zeroTouchCustomers[i].companyId
                            await getNextBatch('')
                        }
                    }
                }
                if (!Array.isArray(result)) {
                    throw "Error getting objects"
                }
                if (updateStore === true) {
                    if(removeOldObjects == true) {
                        this.useStore?.().clearObjectTypeObjectsFromStore(accountId)
                    }
                    this.useStore?.().setObjectTypeObjects(accountId, result)
                }
                return result
            }
            catch (e: any) {
                devLog.log("GenericStoreClass", e.message, e, "error")
                throw e
            }
        }
        this.queries.addObjectToApi = async (accountId, object: ZeroTouchConfigurationOnAdd,customerId,updateStore=true) => {
            let result = object
            if (object.configurationName) {
                object.configurationName = object.configurationName.trim()
            }

            let thisDevices: string[] = []
            if (object.devices) {
                thisDevices = object.devices
                delete object.devices
            }
            result = await requestHandler.request("POST", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('zeroTouch') + "/tenants/" + accountId + ".sms/android/androiddeviceprovisioning/customers/" + customerId + "/configurations/", object)
            
            if (result.configurationId && typeof customerId == "string") {
                thisDevices.forEach(function (zeroTouchDeviceId: any) {
                    products.mobileSecurity.zeroTouchDevices.updateZeroTouchDeviceConfiguration(accountId, customerId, zeroTouchDeviceId, result.configurationId)
                })
            }

            return result
        }
        this.queries.updateObjectFromApi = async (accountId, objectId, object: ZeroTouchConfigurationOnUpdate,customerId,updateStore=true) => {
            let updateMaskString = ""
            if (object.enrollmentTokenId == "") {
                delete object.enrollmentTokenId
            }

            let newDevicesList: string[] = object.devices ? jsonHelpers.copyObject(object.devices) : []
            delete object.devices

            let oldDevicesList: string[] = (products.mobileSecurity.androidDevices.useStore?.().getObjectStoreObjects(accountId) || []).filter((device: any) => {
                return device.zeroTouchInfo?.configuration?.split('/')[3] == objectId
            }).map((device: any) => { return device.zeroTouchInfo?.name?.split('/')[3] })


            let deletedDevices = (oldDevicesList || [])?.filter(function (ztDeviceId: string) {
                return newDevicesList.indexOf(ztDeviceId) == -1
            }) || []

            let addedDevices = (newDevicesList || [])?.filter(function (ztDeviceId: string) {
                return oldDevicesList.indexOf(ztDeviceId) == -1
            }) || []

                let i: number = 0
                for (let key in object) {
                    if (i == 0) {
                        updateMaskString += "?updateMask[]="
                    }
                    else {
                        updateMaskString += "&updateMask[]="
                    }
                    updateMaskString += key
                    i++
                }
            
            let result = await requestHandler.request("PUT", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('zeroTouch') + "/tenants/" + accountId + ".sms/android/androiddeviceprovisioning/customers/" + customerId + "/configurations/" + objectId + updateMaskString, object)
            if (typeof customerId == 'string') {
                addedDevices.forEach(function (zeroTouchDeviceId: any) {
                    products.mobileSecurity.zeroTouchDevices.updateZeroTouchDeviceConfiguration(accountId, customerId, zeroTouchDeviceId, String(objectId))
                })
                deletedDevices.forEach(function (zeroTouchDeviceId: any) {
                    products.mobileSecurity.zeroTouchDevices.deleteZeroTouchDeviceConfiguration(accountId, customerId, zeroTouchDeviceId)
                })
            }

            if(updateStore) {
                this.useStore?.().setObjectTypeObjects(accountId,[result])
            }

            return result
        }

        this.convertObjectForStore = (accountId, objectBase) => {
            objectBase.$itemlist = {
                "isCheckboxChecked": false,
                "isCheckboxHovering": false,
            }
            let newObject = jsonHelpers.copyObject(objectBase) as ObjectTypeObject<ZeroTouchConfiguration>
            objectBase.toJSON = () => {
                delete newObject.$itemlist
                delete newObject.toJSON
                return newObject as ZeroTouchConfiguration
            }
        }
    }
}

const zeroTouchConfigurations = new ZeroTouchConfigurations({
    "productType":"mobileSecurity",
    "objectType":"zeroTouchConfigurations",
    "slug":"zeroTouchConfigurations",
    "hasStore":true,
    "objectTypeInfo":{
        "nameProperty":{
            "primary": "configurationName"
        },
        "primaryKeyProperty":{
            "property":"configurationId"
        }
    },
    "appearance":{
        "iconClass":"fab fa-android",
        "text":{
            "plural":"Configurations",
            "sidebarName":"Configurations",
            "title":"Configurations",
            "singular":"Configuration"
        }
    },
    "apiInfo":{
        "url": "/sms-mgt-api/api/2.0",
        "getCountGETProperties": "?props[]=null&select=data.count",
        // GET
        "getObjectListResponseProperty": "emm",
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/android/androiddeviceprovisioning/customers/{customerId}/configurations",
        // Update
        "updateObjectMethod": "PUT",
        "updateObjectPath": "/tenants/{tenantDomain}/android/androiddeviceprovisioning/customers/{customerId}/configurations/{objectId}",
    }
})
export default zeroTouchConfigurations