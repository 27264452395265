<script setup lang="ts">
import { useGlobalMixin } from "@/mixins/mixins.global";
import loaderComponent from "./loader.vue";
import inputTextComponent from "../inputtypes/input-text";
import inputSelectComponent from "../inputtypes/input-select";
import { ActionTypes } from "@/store/vuex.store";
import { T } from "@/classes/i18n";
import { useStore } from '@/store/vuex.store'
import inputVueSelectVue from "../inputtypes/input-vue-select.vue";
import { computed, inject, onMounted, ref } from "vue";
import mixinHelpers from "@/helpers/helpers.mixins";

const newValue = ref("")
const loading = ref(true)
const selectOptions = ref([] as selectOption[])

const darkmode = computed(() => { return mixinHelpers.getDarkmode() })
const activeAccountId = computed(() => { return mixinHelpers.getActiveAccountId() })
const productType = computed(() => { return mixinHelpers.getProductType() || '' })
const objectType = computed(() => { return mixinHelpers.getObjectType() || '' })

const error = inject("itemlistItemError") as any
const errorMsg = inject("itemlistItemErrorMessage") as any
const props = withDefaults(defineProps<{
    inputType: string,
    submitFunction: Function | Promise<any>
    abortFunction: Function | Promise<any>
    value?: any
    select2Settings?: any,
    options?: any,
    placeholder?: string,
}>(), {
    options: undefined,
    select2Settings: undefined,
    placeholder: ''
});

function $getProductTypeFromObjectType(objectType: string) {
    return mixinHelpers.$getProductTypeFromObjectType(objectType)
}
function $getSelectOptionsForObjectType(accountId: string, objectType: string) {
    return mixinHelpers.$getSelectOptionsForObjectType(accountId, objectType)
}
function $tenantItemNeedsRefresh(accountId: string, productType: string, objectType: string) {
    return mixinHelpers.$tenantItemNeedsRefresh(accountId, productType, objectType)
}

async function init() {
    newValue.value = props.value as any
    let productType: any = ""
    if (["select", "select2"].indexOf(props.inputType) != -1) {
        if (typeof props.options == "string") {
            try {
                productType = $getProductTypeFromObjectType(props.options)
                if ($tenantItemNeedsRefresh(activeAccountId.value, productType, props.options)) {
                    let status: any = await useStore().dispatch(ActionTypes.getObjectInfos, {
                        "accountId": activeAccountId.value,
                        "objectTypes": [props.options == "users" ? "users?props[]=username" : props.options]
                    })
                    if (status != true) {
                        throw "Error fetching " + props.options
                    }
                }


                let thisSelectOptions: selectOption[] = await $getSelectOptionsForObjectType(activeAccountId.value, props.options)
                if(props.options == "users") {
                    thisSelectOptions = thisSelectOptions.sort(function(a: selectOption, b: selectOption) {
                        const firstElement = a.text.toLowerCase()
                        const secondElement = b.text.toLowerCase()
                        if (firstElement > secondElement) return 1;
                        if (firstElement < secondElement) return -1;
                        return 0;

                    })
                    thisSelectOptions.push({
                        "id": "noUserSelected",
                        "text": T('none')
                    })
                }
                selectOptions.value = thisSelectOptions
            }
            catch (e: any) {
                console.error(e)
                let thisErrorMsg = T("Error")
                if (e?.errors?.errors?.[0]?.message != undefined) {
                    thisErrorMsg = e?.errors?.errors?.[0]?.message
                }
                error.value = true
                errorMsg.value = thisErrorMsg

                setTimeout(function () {
                    error.value = false
                    errorMsg.value = ""

                }, 4000)
                props.abortFunction()
            }
        }
        else {
            selectOptions.value = props.options
        }
    }
    loading.value = false
}

function submit() {
    loading.value = true
    if (props.submitFunction && typeof props.submitFunction == 'function') {
        props.submitFunction(newValue.value)
    }
}
onMounted(() => {
    init()
})
</script>
<template>
    <span v-on:click.stop v-on:keyup.enter="submit()">
        <template v-if="inputType == 'text'">
            <inputTextComponent type="text" v-model="newValue" :value="newValue" :disabled="loading || null"
                v-on:keyup.enter="submit()"
                style="display: inline-block; width:calc(100% - 90px); min-width: 90px; min-height:1em; padding:0px 8px 0 4px;" />
        </template>
        <template v-if="inputType == 'select' && !loading">
            <inputSelectComponent v-model="newValue" :value="newValue" :disabled="loading || null"
                style="display: inline-block; width:calc(100% - 90px); min-width: 90px; min-height:1em; padding:0px 8px 0 4px;background-position:center right;"
                :selectOptions="selectOptions" />
        </template>
        <template v-if="inputType == 'select2' && !loading">
            <span
                style="display:inline-block; width:calc(100% - 90px); min-width:90px; position:relative; vertical-align:middle">
                <inputVueSelectVue v-model="newValue" :value="newValue" :selectOptions="selectOptions"
                    :bindSettingsToSelect2="select2Settings"
                    :placeholder="select2Settings && select2Settings.placeholder ? T(select2Settings.placeholder) : T(placeholder)"
                    :multiple="(select2Settings && select2Settings.multiple) || false"
                    :tags="(select2Settings && select2Settings.tags) || false" 
                    :inputMask="(props.options == 'tags' ? 'tags' : undefined)"
                    :noTranslations="true"  
                />
            </span>
        </template>
        &nbsp;&nbsp;
        <template v-if="!loading">
            <a v-on:click.stop="submit()" class="btn padding-xs-y-0"
                :class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'">
                <i class="fal fa-save"></i>
            </a>
            <a v-on:click.stop="abortFunction()" class="btn padding-xs-y-0"
                :class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'">
                <i class="fal fa-times"></i>
            </a>
        </template>
        <loaderComponent v-if="loading" />
    </span>
</template>