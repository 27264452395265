const en = {
    "XPlease enter": "Please enter",
    "Xto confirm":"to confirm",
    "XLink":"Link",
    "Xo'clock":"o'clock",
    "The apple servers have reported following error:":"Die Apple Server haben folgenden Fehler zurückgeliefert:",
    "Automatic VPP license assignment":"Automatische VPP Lizenzzuweisung",
    "Choose wether the VPP app license should be automatically assigned to the device or not.":"Wählen Sie aus, ob die VPP-App-Lizenz automatisch dem Gerät zugewiesen werden soll oder nicht.",
    "A profile with this name already exists. Saving this profile would overwrite the existing one.":"Ein Profil mit diesem Namen existiert bereits. Speichern dieses Profils würde das bestehende überschreiben.",
    "Productselection":"Produktauswahl",
    "Inventory list":"Inventarliste",
    "xFrom":"From",
    "xState": "State",
    "must match a schema in anyOf":"must match one of the points mentioned",
    "Resuming job at label dryrun":"Resuming Job at \"Dryrun\".",
    "Resuming job at label reboot": "Resuming Job at \"Reboot\".",
    "Resuming job at label finalize": "Resuming Job at \"Finalize\".",
    "Job is parked with label dryrun. Waiting for resume response.": "Job is parked at \"Dryrun\". Waiting for resume response.",
    "Job is parked with label reboot. Waiting for resume response.": "Job is parked at \"Reboot\". Waiting for resume response.",
    "Job is parked with label finalize. Waiting for resume response.": "Job is parked at \"Finalize\". Waiting for resume response.",
    "Job is done [Update is done]": "Job is done (Update)",
    "xDashboard": "Dashboard"
}
export default en