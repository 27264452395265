import type { VppUser } from "@/classes/mobileSecurity/vppUsers";
import ObjectType, { type ItemlistItemDetail, type Label, type MenuEntry, type ObjectTypePayload } from "../../objectType";
import stringHelpers from "@/helpers/helpers.strings";
import requestHandler from "@/queries/requests";
import objectStores from "@/classes/init";
import jsonHelpers from "@/helpers/helpers.json";
import config from "@/classes/config";
import { T } from "@/classes/i18n";
import dialogs from "@/dialogs/dialogs";
import tenantHelpers from "@/helpers/helpers.tenants";
import queries from "@/queries/queries";
import { MutationTypes } from "@/store/vuex.store";
import { useStore } from "@/store/vuex.store";
import { useVue } from "@/app";
import router from "@/router/router";
import { Button } from "@/classes/buttons";
import licenseHelpers from "@/helpers/helpers.license";
import numberHelpers from "@/helpers/helpers.numbers";
import arrayHelpers from "@/helpers/helpers.arrays";
import permissionsHelpers from "@/helpers/helpers.permissions";
import timeHelpers from "@/helpers/helpers.time";
import products from "../..";
import devLog from "@/classes/log";
import encodingHelpers from "@/helpers/helpers.encoding";
import type { Role } from "../roles/roles";
import deviceHelpers from "@/helpers/helpers.devices";
import { useAzureStore } from "./azure";
import frontendNotifications from "@/classes/notifications";

export type Username = string

export interface UserProfile {
    "appleId"?: string
    "city"?: string
    "linkWithVPPUser"?: string
    "clientUserIdStr"?: "create" | string
    "comment"?: string
    "country"?: string
    "email"?: string
    "firstname"?: string
    "lastname"?: string
    "state"?: string
    "variable1"?: string
    "variable2"?: string
    "variable3"?: string
    "azureAD"?: AzureUserInfo
}

export interface UserAndroidInfo {
    "enterprise"?: {
        "devices"?: Array<string>
    }
}

export interface AzureUserInfo {
    "azureId"?: string,
    "displayName"?: string,
    "userPrincipalName"?: string,
    "otherMails"?: string[]
}

export interface User extends ObjectType<User> {
    "username": Username
    "TOTP"?: boolean
    "tenantDomain"?: string
    "rspUserId"?: string
    "rspLogin"?: string
    "roles": Array<string>
    "profile"?: UserProfile
    "permissionScopes"?: Array<string>
    "devices": {
        "apple": Array<string>,
        "android": Array<string>
    },
    "android"?: UserAndroidInfo
    "vppUser"?: VppUser
}

export class UsersNew extends ObjectType<User> {
    constructor(payload: ObjectTypePayload<User>) {
        super(payload)
        this.itemlist.getSortingOptions = () => [{
            "id": "username",
            "text": "Name"
        }]
        this.itemlist.getInfoBoxContent = (accountId: string, itemlistComponent: any) => {
            if(useAzureStore().isAzureImporting === true) {
                return T("Users are currently being imported from Entra ID. Editing users and roles is deactivated as long as the process is running.")
            }
            return ""
        }
        this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
            itemlistComponent = itemlistComponent?.exposed ? itemlistComponent?.exposed : itemlistComponent
            const isAzureImporting: boolean = useAzureStore().isAzureImporting
            let toolBarEntries = []

            toolBarEntries.push(
                {
                    icon: 'fal fa-plus',
                    title: T('Add user'),
                    link: '#add-tenant-' + accountId + '.sms-user',
                    id: 'usersButtonAdd',
                    vIf: false,
                    disabled: isAzureImporting === true
                }
            )
            if (config.devMode) {
                toolBarEntries.push({
                    icon: 'fal fa-sync',
                    title: T('Sync DEP Users'),
                    onclick: function () {
                        dialogs.misc.confirmDialog(
                            accountId,
                            T('Confirm'),
                            `<p>
                  ${T('This will sync Apple DEP Users with Securepoint Mobile Security')}
                </p>`,
                            async () => {
                                await requestHandler.request('GET', config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/ios/dep/roster/class/person/sync")
                            }
                        )
                    },
                    id: 'syncAppleDEPUsers',
                    vIf: 'canUseAppleDEP'
                }
                )
            }
            toolBarEntries.push(
                {
                    icon: 'fal fa-user',
                    title: T('Connect RSP User'),
                    onclick: function () {
                        dialogs.misc.confirmDialog(
                            accountId,
                            T('Confirm'),
                            `<p>
                          ${T('Please confirm connecting your user from the Securepoint Reseller Portal to Securepoint Unified Security.')}
                        </p>
                        <p>
                          ${T('Connecting your RSP-Account includes the creation of a user and a role called "USC-Administrator", that will be assigned to your user.')}
                        </p>`,
                            async () => {
                                await queries.unifiedSecurity.importRSPUser(accountId)
                                itemlistComponent.refresh()
                                useStore().commit(MutationTypes.setTenants, {
                                    [accountId]: {
                                        unifiedSecurity: {
                                            account: {
                                                rspUserConnectable: false
                                            }
                                        }
                                    }
                                })
                            }
                        )
                    },
                    id: 'importRSPUsersButton',
                    vIf: 'canImportRSPUsers'
                },
                {
                    icon: 'fal fa-download',
                    title: T('Download CSV'),
                    onclick: async () => {
                        let users = this.useStore?.().getObjectStoreObjects(accountId)
                        if (users?.length && accountId != '') {
                            try {
                                accountId = tenantHelpers.getAccountId(accountId)
                                let tenantDomain: string = tenantHelpers.getTenantDomain(accountId)
                                queries.misc.download("/sms-mgt-api/api/2.0/tenants/" + tenantDomain + "/users?csv=1", "users.csv")
                                await timeHelpers.sleep(300)
                            } 
                            catch (e) {
                                console.error(e)
                                dialogs.misc.errorDialog(accountId, T('An error has occurred'), T('An error occurred while exporting the users.csv'))
                            }
                        } else {
                            dialogs.misc.errorDialog(accountId, T('An error has occurred'), T('An error occurred while exporting the users.csv'))
                        }
                    },
                    id: 'exportUsersButton',
                    vIf: "hasUsers"
                },
                {
                    icon: 'fal fa-upload',
                    title: T('Import users'),
                    onclick: function () {
                        let newModal = {
                            "id": "uploadUsers",
                            "abortable": true,
                            "content": {
                                "title": {
                                    "text": T('Import users')
                                },
                                "body": {
                                    "use": true,
                                    "content": undefined,
                                    "component": "import-users",
                                    "properties": undefined,
                                    "maxHeight": "670"
                                }
                            },
                            "buttons": [
                                {
                                    "text": T("Cancel"),
                                    "icon": "fal fa-times",
                                    "onClick": async function () {
                                        useVue().$refs.modals.removeModal()
                                    },
                                    "align": "left",
                                    "loading": false
                                },
                                {
                                    "text": T("Select .csv File"),
                                    "icon": "fal fa-fw fa-file-word",
                                    "onClick": async function () {
                                        useVue().$refs.modals.$refs.modal.$refs.modalComponent.importUsers()
                                    },
                                    "align": "right",
                                    "disabled": false,
                                    "loading": false
                                }
                            ]
                        }
                        useVue().$refs.modals.addModal(newModal)
                    },
                    id: 'importUsersButton',
                    vIf: false,
                    disabled: isAzureImporting === true
                }
            )
            return toolBarEntries
        }

        this.itemlistItem.onClick = (accountId: string, item) => {
            let objectId = item[this.options.objectTypeInfo.primaryKeyProperty.property as keyof typeof item] as string
            router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-user-' + objectId)
        }
        this.itemlistItem.getTitle = (item) => {
                return {
                    title: item?.rspUserId ? item.rspLogin : item?.username,
                    small: item?.rspUserId ? "(" + item.rspUserId + ")" : undefined
                }
        }
        
        this.itemlistItem.getMenuEntries = (accountId: string, item) => {
            let menuEnties: MenuEntry[] = []
            if (item && useAzureStore().isAzureImporting === false) {
                let objectId = item[this.options.objectTypeInfo.primaryKeyProperty.property as keyof typeof item] as string
                menuEnties.push(
                    new Button({
                        "id": 'edit',
                        "text": T('Edit'),
                        "title": T('Edit'),
                        "icon": 'fal fa-edit',
                        "onClick": () => {
                            router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-user-' + objectId)
                        }
                    })
                )
                menuEnties.push(
                    new Button({
                        "id": 'delete',
                        "text": T('Delete'),
                        "title": T('Delete'),
                        "icon": 'fal fa-trash',
                        "onClick": () => {
                            this.dialogs.getDeleteObjectDialog(accountId, item)
                        }
                    })
                )
                menuEnties.push(
                    new Button({
                        "id": 'resetPassword',
                        "text": T('Password reset'),
                        "title": T('Password reset'),
                        "icon": 'fal fa-paper-plane',
                        "onClick": () => {
                            try {
                                requestHandler.request("POST", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/users/temporary", {
                                    "username": item.username
                                })
                                frontendNotifications.addNotification(accountId, {
                                    "accountId": accountId,
                                    "content": {
                                        "body": {
                                            "content": T("A password reset e-mail has been sent to %s.").replace("%s", item.profile?.email || ""),
                                        },
                                        "title": {
                                            "text": T("Password Reset E-Mail Requested"),
                                            "icon": "fal fa-check"
                                        }
                                    },
                                    "highlightNew": true,
                                })
                            }
                            catch (e: unknown) {
                                console.error(e)
                            }
                        }
                    })
                )
            }

            return menuEnties
        }

        this.itemlistItem.getLabels = (accountId: string, item) => {
            let result: Label[] = []

            if(item?.profile?.azureAD?.azureId) {
                result.push({
                    title: "Entra ID",
                    text: "Entra ID",
                    class: "bg-blue",
                    icon: "fab fa-microsoft"
                })
            }
            if (item?.rspUserId) {
                result.push({
                    title: T("Reseller Portal User"),
                    text: T("RSP"),
                    class: "",
                    icon: "fa fa-user"
                })
            }
            return result
        }

        this.itemlistItem.getDetails = (accountId, item?) => {
            let result: ItemlistItemDetail[] = []
            let thisPermissionsArray: any = []
            const rolesObjectType = products.unifiedSecurity.roles
            const roles = rolesObjectType.useStore?.().getObjectStoreObjects(accountId) || []
            
            let thisDeviceIds: string[] = item?.devices?.apple ? jsonHelpers.copyObject(item?.devices?.apple) || [] : [];

            (item?.devices?.android || []).forEach((deviceId) => {
                thisDeviceIds.push(deviceId.split("/")[3])
            })

            roles.forEach((role: any) => {
                if (item?.roles.indexOf(role.rolename) != -1) {
                    role.permissionScopes.forEach((permissionScope: string) => {
                        if (permissionScope.indexOf("usc:administration:") != -1) {
                            thisPermissionsArray.push("usc:administration:*")
                        }
                        else {
                            thisPermissionsArray.push(permissionScope)
                        }
                    });
                }
            })
            thisPermissionsArray = thisPermissionsArray.filter(arrayHelpers.onlyUniqueFilter)
            thisPermissionsArray = thisPermissionsArray.map((permissionScope: string) => {
                const labelText = T(permissionsHelpers.getPermissionLabelFromScope(permissionScope))
                const permissionScopeClass = function () {
                    let htmlClass: string = ""
                    if (permissionScope == "usc:administration") {
                        htmlClass = "bg-red color-white"
                    }
                    if (permissionScope == "usc:administration:*") {
                        htmlClass = "bg-yellow color-white"
                    }
                    if (permissionScope == "usc:monitoring") {
                        htmlClass = "bg-blue color-white"
                    }
                    return htmlClass
                }()
                return <Label>{
                    "id": permissionScope,
                    "text": labelText,
                    "title": labelText,
                    "onClick": undefined,
                    "displayType": "label",
                    "class": permissionScopeClass
                }
            })

            let vppUserState: string | undefined = undefined
            if (item?.profile?.clientUserIdStr) {
                let id = item.profile.clientUserIdStr
                let vppUser = config.canUseNewObjectType('vppUsers') ? products.mobileSecurity.vppUsers.useStore?.().getObjectStoreObject(accountId, id) : objectStores.vppUsers.getObjectFromStore(accountId, id)
                vppUserState = vppUser?.status || undefined
            }

            result.push(
                {
                    "iconClass": "fal fa-at",
                    "key": T("Email"),
                    "title": T("EMail"),
                    "value": item ? item.profile?.email || "" : '<span class="content-placeholder" style="width:' + numberHelpers.getRandomArbitrary(50, 250) + 'px;" title="' + T("Loading...") + '"></span>',
                },
                {
                    "iconClass": "fal fa-users",
                    "key": T("Roles"),
                    "title": T("Roles"),
                    "value": item ? undefined : '<span class="content-placeholder" style="width:' + numberHelpers.getRandomArbitrary(50, 250) + 'px;" title="' + T("Loading...") + '"></span>',
                    "labels": item ? item.roles.map((role) => {
                        return {
                            "title": role,
                            "text": role,
                            "class": "clickable",
                            "onClick": () => {
                                router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-role-' + role)
                            },
                            "displayType": "label"
                        }
                    }) : undefined
                },
                {
                    "iconClass": "fal fa-mobile-alt",
                    "key": T("Devices"),
                    "title": T("Devices"),
                    "labels": licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'])
                        ? thisDeviceIds?.map((deviceID) => {
                            let device : any = undefined
                            if (config.canUseNewObjectType("iosDevices")) {
                                device = products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObject(accountId,deviceID)
                            }
                            else {
                                device = useStore()?.getters.getObject({
                                    "accountId": accountId,
                                    "productType": "mobileSecurity",
                                    "objectType": "devices",
                                    "objectId": deviceID
                                })
                            }
                            if(device == undefined) {
                                device = products.mobileSecurity.androidDevices.useStore?.().getObjectStoreObject(accountId, deviceID)
                            }
                            
                            return {
                                "id": deviceID,
                                "text": deviceHelpers.getAliasedShortDeviceId(device?.deviceId || deviceID, device?.alias || device?.info?.deviceName || undefined, false),
                                "title": deviceHelpers.getAliasedShortDeviceId(device?.deviceId || deviceID, device?.alias || undefined, false),
                                "onClick": () => {
                                    if (device?.deviceType == 'IOS') {
                                        router.navigate('#show-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-ios-devices-dashboard-' + deviceID)
                                    }
                                    else if (device?.enterpriseDevice) {
                                        router.navigate('#show-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-android-devices-dashboard-' + deviceID)
                                    }
                                },
                                "displayType": "label"
                            }
                        })
                        : undefined
                },
                {
                    iconClass: 'fal fa-fw fa-at',
                    title: T('VPP Apple ID'),
                    key: T('VPP Apple ID'),
                    value: item && item.profile?.clientUserIdStr ? item.profile?.appleId + (vppUserState ? ' (' + T(vppUserState) + ')' : '') : "not linked"
                },
                {
                    iconClass: 'fal fa-fw fa-key',
                    title: T('Permissions'),
                    key: T('Permissions'),
                    labels: item ? thisPermissionsArray : undefined
                }
            )

            return result
        }

        this.itemlistItem.hasCheckbox = () => {
            return useAzureStore().isAzureImporting === false
        }
        this.itemlistItem.isClickable = (accountId, item) => {
            return useAzureStore().isAzureImporting === false
        }

        this.itemlistItem.getDisabledState = (accountId, item) => {
            return useAzureStore().isAzureImporting === false
        }

        this.queries.addObjectToApi = async (accountId, object,customerId?, updateLocalStore = true) => {
            let result: User | Error
            let vppResult: VppUser | Error | undefined
            let linkWithVPPUser: boolean = false
            let addOrUpdateVppUser: "add" | "update" = "add"
            let clientUserIdStr: string | undefined = undefined
            let email: string | undefined = undefined
            let managedAppleIDStr: string | undefined = undefined

            try {


                if (object.profile?.linkWithVPPUser) {
                    delete object.profile.linkWithVPPUser
                    linkWithVPPUser = true
                    clientUserIdStr = (object.profile.clientUserIdStr == "create" || object.profile.clientUserIdStr == "") ? stringHelpers.generateUUID() : object.profile.clientUserIdStr
                    email = object.profile.email
                    managedAppleIDStr = object.profile.appleId
                    if (managedAppleIDStr && (object.profile.clientUserIdStr == "create" || object.profile.clientUserIdStr == "")) {
                        // add new vppUser
                        addOrUpdateVppUser = "add"
                    }
                    else if (managedAppleIDStr && object.profile.clientUserIdStr != undefined && object.profile.clientUserIdStr != "create" && object.profile.clientUserIdStr != "") {
                        // link existing vppUser
                        addOrUpdateVppUser = "update"
                    }
                    else if (!managedAppleIDStr) {
                        linkWithVPPUser = false
                    }

                    object.profile.clientUserIdStr = clientUserIdStr
                    delete object.profile.linkWithVPPUser
                }
                if (linkWithVPPUser && addOrUpdateVppUser == "add") {
                    if (clientUserIdStr && email && managedAppleIDStr) {
                        // add new vppUser
                        if (config.canUseNewObjectType("vppUsers")) {
                            vppResult = await products.mobileSecurity.vppUsers.queries.addObjectToApi(accountId, {
                                "clientUserId": clientUserIdStr,
                                "email": email,
                                "managedAppleId": managedAppleIDStr
                            })
                        }
                        else {
                            vppResult = await objectStores.vppUsers.addObjectToApi(accountId, {
                                clientUserIdStr,
                                email,
                                managedAppleIDStr,
                            })
                        }
                        if (vppResult instanceof Error) {
                            throw "can't add vppUser"
                        }
                    }
                }
                else if (linkWithVPPUser && addOrUpdateVppUser == "update") {
                    if (clientUserIdStr && email && managedAppleIDStr) {
                        if (config.canUseNewObjectType("vppUsers")) {
                            vppResult = await products.mobileSecurity.vppUsers.queries.updateObjectFromApi(accountId, clientUserIdStr, {
                                "clientUserId": clientUserIdStr,
                                "email": email,
                                "managedAppleId": managedAppleIDStr
                            })
                        }
                        else {
                            // link existing vppUser
                            vppResult = await objectStores.vppUsers.updateObjectFromApi(accountId, clientUserIdStr, {
                                clientUserIdStr,
                                email,
                                managedAppleIDStr,
                            })
                        }
                        if (vppResult instanceof Error) {
                            throw "can't update vppUser"
                        }
                    }
                }

                const response = await requestHandler.request("POST", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/users/", object)

                

                if (response.username) {
                    result = object as User
                    result.username = response.username
                    if (updateLocalStore) {
                        this.useStore?.().addObjectTypeObject(accountId, object) // Add User to store
                    }
                }
                else if (response.errors) {
                    throw response.errors
                }
                else if (response.error) {
                    throw new Error(response.error)
                }
                else {
                    throw new Error('Error adding user')
                }
            }
            catch (e: any) {
                throw e
                devLog.log("UsersStoreClass", "Error adding user", result, "error")
            }
            return result
        }

        this.queries.updateObjectFromApi = async (accountId, objectId, object,customerId?, updateLocalStore = true) => {
            let result: User | Error
            let vppResult: VppUser | Error | undefined
            let linkWithVPPUser: boolean = false
            let retireVPPUser: boolean = false
            let addOrUpdateVppUser: "add" | "update" = "add"
            let clientUserIdStr: string | undefined = undefined
            let email: string | undefined = undefined
            let managedAppleIDStr: string | undefined = undefined

            try {
                if (object.profile?.linkWithVPPUser) {
                    delete object.profile.linkWithVPPUser
                    linkWithVPPUser = true
                    clientUserIdStr = (object.profile.clientUserIdStr == "create" || object.profile.clientUserIdStr == "") ? stringHelpers.generateUUID() : object.profile.clientUserIdStr
                    email = object.profile.email
                    managedAppleIDStr = object.profile.appleId
                    if (managedAppleIDStr && (object.profile.clientUserIdStr == "create" || object.profile.clientUserIdStr == "")) {
                        // add new vppUser
                        addOrUpdateVppUser = "add"
                    }
                    else if (managedAppleIDStr && object.profile.clientUserIdStr != undefined && object.profile.clientUserIdStr != "create" && object.profile.clientUserIdStr != "") {
                        // link existing vppUser
                        addOrUpdateVppUser = "update"
                    }
                    else if (!managedAppleIDStr) {
                        linkWithVPPUser = false
                    }
                    object.profile.clientUserIdStr = clientUserIdStr
                    delete object.profile.linkWithVPPUser
                }


                let user = this.useStore?.().getObjectStoreObject(accountId, String(objectId))

                if ((user?.profile?.clientUserIdStr == undefined || user.profile?.clientUserIdStr == "" || user.profile?.clientUserIdStr == "create") && !linkWithVPPUser) {
                    delete object.profile?.appleId
                    delete object.profile?.clientUserIdStr
                    delete object.profile?.linkWithVPPUser
                }

                if (user?.profile?.clientUserIdStr && !linkWithVPPUser) {
                    clientUserIdStr = user.profile?.clientUserIdStr
                    delete object.profile?.appleId
                    delete object.profile?.clientUserIdStr
                    delete object.profile?.linkWithVPPUser

                    retireVPPUser = true
                }
                if (retireVPPUser && clientUserIdStr) {
                    if (config.canUseNewObjectType("vppUsers")) {
                        await products.mobileSecurity.vppUsers.queries.deleteObjectFromApi(accountId, clientUserIdStr)
                    }
                    else {
                        await objectStores.vppUsers.deleteObjectFromApi(accountId, clientUserIdStr)
                    }
                }

                if (linkWithVPPUser && addOrUpdateVppUser == "add" && clientUserIdStr && email && managedAppleIDStr) {
                    // add new vppUser
                    if (config.canUseNewObjectType("vppUsers")) {
                        vppResult = await products.mobileSecurity.vppUsers.queries.addObjectToApi(accountId, {
                            "clientUserId": clientUserIdStr,
                            "email": email,
                            "managedAppleId": managedAppleIDStr
                        })
                    }
                    else {
                        vppResult = await objectStores.vppUsers.addObjectToApi(accountId, {
                            clientUserIdStr,
                            email,
                            managedAppleIDStr,
                        })
                    }
                    if (vppResult instanceof Error) {
                        throw "can't add vppUser"
                    }
                }
                else if (linkWithVPPUser && addOrUpdateVppUser == "update" && clientUserIdStr && email && managedAppleIDStr) {
                    if (config.canUseNewObjectType("vppUsers")) {
                        vppResult = await products.mobileSecurity.vppUsers.queries.updateObjectFromApi(accountId, clientUserIdStr, {
                            "clientUserId": clientUserIdStr,
                            "email": email,
                            "managedAppleId": managedAppleIDStr
                        })
                    }
                    else {
                        // link existing vppUser
                        vppResult = await objectStores.vppUsers.updateObjectFromApi(accountId, clientUserIdStr, {
                            clientUserIdStr,
                            email,
                            managedAppleIDStr,
                        })
                    }
                    if (vppResult instanceof Error) {
                        throw "can't update vppUser"
                    }
                }

                const response = result = await requestHandler.request("PUT", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/users/" + encodingHelpers.encodeURI(object.username), object)
      
                
                if (response.code == 200) {                   
                    if (updateLocalStore) {
                        this.useStore?.().setObjectTypeObject(accountId, object.username, object) // Add User to store
                    }
                }
                else if (response.errors) {
                    throw response.errors
                }
                else if (response.error) {
                    throw new Error(response.error)
                }
                else {
                    throw new Error('Error updating user')
                }
            }
            catch (e: any) {
                console.error(e)
                throw e as Error
                devLog.log("UsersStoreClass", "Error updating user", result, "error")
            }
            return result
        }


        this.queries.deleteObjectFromApi = async (accountId, objectId,customerId?, updateLocalStore = true) => {
            let result: true | Error
            try {

                const user = this.useStore?.().getObjectStoreObject(accountId, objectId)

                const response = await requestHandler.request("DELETE", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/users/" + encodingHelpers.encodeURI(objectId as string))

                if (user?.profile?.clientUserIdStr) {
                    if (config.canUseNewObjectType("vppUsers")) {
                        await products.mobileSecurity.vppUsers.queries.deleteObjectFromApi(accountId, user.profile.clientUserIdStr)
                    }
                    else {
                        await objectStores.vppUsers.deleteObjectFromApi(accountId, user.profile.clientUserIdStr)
                    }
                }


                if (response.error) {
                    throw new Error(response.error)
                }
                else {
                    result = true
                    if (updateLocalStore) {
                        this.useStore?.().deleteObjectTypeObjectFromStore(accountId, objectId) // Remove user from store
                    }
                }
            }
            catch (e: any) {
                if (e instanceof Error) {
                    result = new Error(e.message)
                }
                else {
                    result = new Error('Error deleting user')
                }
                devLog.log("UsersStoreClass", result.message, result, "error")
            }
            return result
        }

    }
}

const usersNew = new UsersNew({
    "objectType":"users",
    "productType":"unifiedSecurity",
    "slug":"users",
    "apiInfo":{
        "getCountGETProperties":"?props[]=null&select=data.count",
        "url": config.mgtApiUriNext,
        "getObjectListPath":"/tenants/{tenantDomain}/users",
        "getObjectListResponseProperty":"users"
    },
    "hasStore":true,
    "objectTypeInfo":{
        "nameProperty":{
            "primary":"username"
        },
        "primaryKeyProperty":{
            "property":"username"
        },
    },
    "appearance":{
        "color":"red",
        "iconClass":"fal fa-user",
        "showInSidebar":true,
        "text":{
            "plural":"Users",
            "sidebarName":"Users",
            "title":"Users",
            "singular":"User"
        }
    }

})
export default usersNew