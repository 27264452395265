<script setup lang="ts">
    import i18n from "@/classes/i18n"
    import moment from "moment"
    import $ from 'jquery';
    import AirDatepicker, { type AirDatepickerOptions } from "air-datepicker";
    import localeDe from "air-datepicker/locale/de";
    import localeEn from "air-datepicker/locale/en";
    import 'air-datepicker/air-datepicker.css'
    import { createPopper } from '@popperjs/core';
    import { useStore } from "@/store/vuex.store";
    import useRouterStore from "@/router/routerStore";
import { computed, onMounted, ref } from "vue";

    const props = defineProps({
        "modelValue": null,
        "format": {
            "required": false,
            "default": "timestamp"
        },
        "dateTime": {
            "required": false,
            "default": false
        },
        "minDate": {
            "required":false,
            "default":<undefined|string|number>undefined
        }
    })

    const datePicker = ref(<undefined | AirDatepicker<HTMLElement>>undefined)


    const emit = defineEmits(['update:modelValue','change','input'])
    const value = computed({
        get: () => props.modelValue,
        set: (newValue) => { 
            if(newValue == "" && datePicker.value) {
                datePicker.value.selectedDates = []
                delete datePicker.value.lastSelectedDate
            }
            emit('update:modelValue', newValue) 
            emit('change', newValue)
            emit('input', newValue)
        }
    })

    onMounted(() => {
        let startDate
        if (!Array.isArray(value.value)) {
            startDate = value.value ? moment(value.value, props.dateTime ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY") : moment()
        } else {
            startDate = moment()
        }
        if (props.dateTime) {
            if (value.value == "01.01.1970 01:00") {
                startDate = moment().add(3, "days")
                emit('update:modelValue', startDate.format("DD.MM.YYYY HH:mm"))
                emit('input', startDate.format("DD.MM.YYYY HH:mm"))

            }
            if (value.value == "+3days") {
                startDate = moment().add(3, "days")
                emit('update:modelValue', startDate.format("DD.MM.YYYY HH:mm"))
                emit('input', startDate.format("DD.MM.YYYY HH:mm"))
            }
            if (Array.isArray(value.value) && value.value.length === 0 && useRouterStore().getActivePage === "uscProfiles") {
                startDate = moment().add(3, "days")
                emit('update:modelValue', startDate.format("DD.MM.YYYY HH:mm"))
            }
        }

        let options: Partial<AirDatepickerOptions> = {
            "locale": i18n.getLanguage() == 'de' ? localeDe : localeEn,
            "startDate": startDate.toDate(),
            "dateFormat": "dd.MM.yyyy",
            "timeFormat": "HH:mm",
            "onSelect": function ({ date, formattedDate, datepicker }) {
                value.value = formattedDate
            },
            position({ $datepicker, $target, $pointer, done }) {
                let popper = createPopper($target, $datepicker, {
                    placement: 'top',
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                padding: {
                                    top: 64
                                }
                            }
                        },
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 20]
                            }
                        },
                        {
                            name: 'arrow',
                            options: {
                                element: $pointer
                            }
                        }
                    ]
                })
                return function completeHide() {
                    popper.destroy();
                    done();
                }
            }
        }

        if(props.minDate) {
            options.minDate = props.minDate
        }

        options.timepicker = props.dateTime
        if(thisInput.value) {
            datePicker.value = new AirDatepicker(thisInput.value, options)
        }
    })
    const thisInput = ref(<null|HTMLElement>null)


    


</script>

<template>
     <input
        id="date"
        name="date"
        type="text"
        readonly
        v-bind="$attrs"
        v-model="value"
        ref="thisInput"
        style="width:100%"  
    />
</template>