export interface ButtonOptions {
  id?: string
  text?: string
  title?: string
  icon?: string
  onClick?: Function
  link?: string
  target?: "_self" | "_blank" | "_parent" | "_top"
  loading?: boolean | (() => boolean)
  disabled?: boolean | (() => boolean)
  topRightCircle?:undefined | ButtonTopRightCircle
  type?:"default"|"icon",
  size?: "xl"| "lg" | "sm" | "xs"
}

type ButtonTopRightCircle = {
  "counter":number
} | {
  "icon":string
}

export class Button {
  id: string|undefined = undefined
  text: string|undefined = undefined
  title: string|undefined = undefined
  icon: string|undefined = undefined
  onClick: Function | undefined = undefined
  link: string | undefined = undefined
  target: "_self" | "_blank" | "_parent" | "_top" | undefined = undefined
  loading: boolean | (() => boolean) = false
  disabled: boolean | (() => boolean) = false
  topRightCircle: undefined | ButtonTopRightCircle
  type: "default" | "icon" = "default"
  size: ButtonOptions['size'] = undefined

  constructor(payload: ButtonOptions) {
    if(payload.id) this.id = payload.id
    if(payload.text) this.text = payload.text
    if(payload.title) this.title = payload.title
    if(payload.icon) this.icon = payload.icon
    if(payload.onClick) this.onClick = payload.onClick
    if(payload.link) this.link = payload.link
    if(payload.target) this.target = payload.target
    if(payload.loading) this.loading = payload.loading
    if(payload.disabled) this.disabled = payload.disabled
    if (payload.topRightCircle) this.topRightCircle = payload.topRightCircle
    if (payload.type) this.type = payload.type
    if (payload.size) this.size = payload.size
  }
}

class Buttons {
  constructor() {
  }
  createButton(newButton: ButtonOptions): Button {
    return new Button(newButton)
  }
}
const buttons = new Buttons()
export default buttons