import cookies from "@/classes/cookieHandler";
import { useGlobalMixin } from "@/mixins/mixins.global";
import loaderComponent from "./loader.vue";

const cookieConsentComponent = {
    "mixins": [useGlobalMixin()],
    "name": "cookie-consent",
    "template": `<div id="cookie-consent">
        <template v-if="initialized && showConsent">
            <div class="modal">
                <div class="modal-dialog">
                    <div class="modal-content" >
                        <div class="modal-header">
                            <h4 class="modal-title float-xs-left">
                                {{ T('Cookie consent') }}
                            </h4>&nbsp;
                            <button type="button" class="close" v-on:click="abort()">
                                <span>
                                    <i class="fal fa-times"></i>
                                </span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>
                                {{T('This website uses cookies. In the following, you can determine which type of cookies may and may not be stored. By clicking on "Accept", you confirm the storage of the cookies according to your specifications.')}}
                            </p>
                            <div class="row padding-xs-y border-bottom">
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label for="essential" class="control-label inputname">
                                        {{T('Essential')}}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="form-field margin-xs-b-0 checkbox toggle">
                                        <input disabled v-model="form.essential" id="essential" type="checkbox" class="toggle"/><span></span>
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{ T('Necessary cookies that help make this website usable by enabling basic functions such as access to secure areas of the website. This website cannot function properly without these cookies.')}}
                                    </p>
                                </div>
                            </div>
                            <div class="row padding-xs-t">
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label for="utility" class="control-label inputname">
                                        {{T('Preferences')}}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="form-field margin-xs-b-0 checkbox toggle">
                                        <input v-model="form.utility" id="utility" type="checkbox" class="toggle"/><span></span>
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{T('Preference cookies enable this website to save information that affects the way the website behaves or looks, such as your preferred language or the region you are in.')}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="box-footer content-2 border-top">
                            <div class="row flexrow">
                                <div class="col-xs padding-xs-x text-right">
                                    <a
                                        class="btn btn-default btn-loader twist-in"
                                        :disabled="(!initialized || loading) ? true : null"
                                        :class="{'btn-loading':!initialized || loading}"
                                        v-on:click="submit()"
                                        id="submitConsentButton"
                                    >
                                        <span class="animate">
                                            <loader style="top:-3px;" class="text-size-2"/>
                                        </span>
                                        <span>
                                            <i class="fal fa-fw fa-save"></i> {{ T('Accept') }}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>`,
    "data": () => {
        return {
            "initialized":false,
            "loading":true,
            "showConsent":false,
            "form":{
                "essential":true,
                "utility":true,
                "accepted":false
            }
        }
    },
    "computed": {

    },
    "props": {

    },
    "methods": {
        submit: function(this:any) {
            this.loading = true

            this.form.accepted = true

            cookies.consentHandler.setInfo(this.form)

            this.loading = false
            this.showConsent = false
        },
        abort:function(this:any) {
            this.showConsent = false
        }

    },
    "mounted": async function (this: any) {
        this.initialized = true
        this.loading = false
        if(cookies.consentHandler.shouldShowConsentDialog()) {
            this.showConsent = true
        }
    },
    "watch": {

    },
    "components": {
        "loader": loaderComponent
    }
}
export default cookieConsentComponent
