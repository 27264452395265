<script setup lang="ts">
    import { T } from '@/classes/i18n';
    import { ref, computed, watch, onMounted } from 'vue';

    const props = defineProps<{
        modelValue: string,
    }>();
    const emit = defineEmits(['update:model-value', 'change'])
    const value = computed({
        get: () => {
            passwordCompliant.value;
            return props.modelValue
        },
        set: (value) => { 

            emit('update:model-value', value) 
            emit('change', value) 
            updatedCount.value++
        }
    })
    const updatedCount = ref(0)
    const showPassword = ref(false)
    const thisValue = computed(() => {       
        return props.modelValue || value.value
    })
    const hasMinLength = computed(() => {
        updatedCount.value;
        return thisValue.value?.length >= 8
    })
    const isUnderMaxLength = computed(() => {
        updatedCount.value;
        return thisValue.value?.length <= 256
    })
    const hasNoWhitespacesAtStartOrEnd = computed(() => {
        updatedCount.value;

        return thisValue.value?.length ? thisValue.value[0] !== " " && thisValue.value[thisValue.value?.length - 1] !== " " : true
    })
    const hasNoControlCharacters = computed(() => {
        updatedCount.value;

        return !(/[\x00-\x1f\x7f]+/.test(thisValue.value))
    })

    const hasAtLeastThreeCharTypes = computed(() => {
        updatedCount.value;

        let existingTypes = []
        if (/[0-9]+/.test(thisValue.value)) {
            existingTypes.push("number")
        }
        if (/[a-z]+/.test(thisValue.value)) {
            existingTypes.push("lowercase")
        }
        if (/[A-Z]+/.test(thisValue.value)) {
            existingTypes.push("uppercase")
        }
        if (/[\W]+/.test(thisValue.value)) {
            existingTypes.push("special character")
        }
        return existingTypes.length >= 3
    })

    const passwordCompliant = computed(() => {
        updatedCount.value;

        if (hasMinLength.value && isUnderMaxLength.value && hasNoWhitespacesAtStartOrEnd.value && hasNoControlCharacters.value && hasAtLeastThreeCharTypes.value) {           
            return true
        }
        return false
    })

    watch(() => { return props.modelValue }, () => {
        updatedCount.value++
    })

    defineExpose({
        "passwordCompliant":passwordCompliant
    })

</script>
<template>
    <div class="flexrow">
        <input 
            id="password" 
            :type="showPassword ? 'text' : 'password'" 
            v-model="value" 
            :placeholder="T('Password')" 
            autocomplete="new-password"
            v-bind="$attrs"
        >
        <a
            class="btn btn-primary"
            v-on:click="showPassword = !showPassword"
            :title="showPassword ? T('Hide password') : T('Show password')"
        >
            <i
                class="fal"
                :class="{
                    'fa-eye': showPassword == false,
                    'fa-eye-slash': showPassword == true,
                }"
            >
            </i>
        </a>
    </div>
    <div class="text-italic margin-xs-y" style="font-size:0.9em">
        <p>
            <i class="fal fa-fw" :class="{
                'fa-times color-red': !(hasMinLength && isUnderMaxLength),
                'fa-check color-green': (hasMinLength && isUnderMaxLength),
            }"></i> {{ T('Between 8 and 256 Characters') }}
        </p>
        <p>
            <i class="fal fa-fw" :class="{
                'fa-times color-red': !(hasNoWhitespacesAtStartOrEnd),
                'fa-check color-green': hasNoWhitespacesAtStartOrEnd,
            }"></i> {{ T('Does not start or end with whitespaces') }}
        </p>
        <p>
            <i class="fal fa-fw" :class="{
                'fa-times color-red': !(hasNoControlCharacters),
                'fa-check color-green': hasNoControlCharacters,
            }"></i> {{ T('No controlcharacters') }}
        </p>
        <p>
            <i class="fal fa-fw" :class="{
                'fa-times color-red': !(hasAtLeastThreeCharTypes),
                'fa-check color-green': hasAtLeastThreeCharTypes,
            }"></i> {{ T('Has at least 3 different character types (Lowercase, uppercase, numbers, special characters)') }}
        </p>
    </div>
    
</template>