import i18n from "@/classes/i18n"
import moment from "moment"
import $ from 'jquery';
import AirDatepicker, { type AirDatepickerOptions } from "air-datepicker";
import localeDe from "air-datepicker/locale/de";
import localeEn from "air-datepicker/locale/en";
import 'air-datepicker/air-datepicker.css'
import { createPopper } from '@popperjs/core';
import { useStore } from "@/store/vuex.store";
import useRouterStore from "@/router/routerStore";

const inputDateComponent = {
    "name":"input-date",
    "template": `
        <input
            id="date"
            name="date"
            type="text"
            v-bind="$attrs"
            v-on:change="(event) => { onChange(event) }"            
            style="width:100%"
        />
    `,
    "data": () => {
        return {
            datePicker:<undefined|AirDatepicker>undefined
        }
    },
    "props": {
        "modelValue": null,
        "format":{
            "required":false,
            "default":"timestamp"
        },
        "dateTime":{
            "required":false,
            "default":false
        }
    },
    "methods":{
        onChange: function(this:any,event:any) {           
            if (event.target.value == "") {
                this.datePicker.selectedDates = []
                delete this.datePicker.lastSelectedDate
                this.$emit('update:modelValue', "")
                this.$emit('input', "")
            }
            this.$emit('change', event.target.value)
            this.$emit('update:modelValue', event.target.value)
            this.$emit('input', event.target.value)
        }
    },
    "mounted":function(this:any) {
        let thisComponent : any = this
        
        let startDate
        if(!Array.isArray(thisComponent.modelValue)) {
            startDate = thisComponent.modelValue ? moment(thisComponent.modelValue, thisComponent.dateTime ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY") : moment()
        } else {
            startDate = moment()
        }
        if(this.dateTime) {
            if (thisComponent.modelValue == "01.01.1970 01:00") {
                startDate = moment().add(3, "days")
                thisComponent.$emit('update:modelValue', startDate.format("DD.MM.YYYY HH:mm") )
                thisComponent.$emit('input', startDate.format("DD.MM.YYYY HH:mm"))
                
            }
            if (thisComponent.modelValue == "+3days") {
                startDate = moment().add(3, "days")
                thisComponent.$emit('update:modelValue', startDate.format("DD.MM.YYYY HH:mm"))
                thisComponent.$emit('input', startDate.format("DD.MM.YYYY HH:mm"))
            }
            if(Array.isArray(thisComponent.modelValue) && thisComponent.modelValue.length === 0 && useRouterStore().getActivePage === "uscProfiles") {
                startDate = moment().add(3, "days")
                thisComponent.$emit('update:modelValue', startDate.format("DD.MM.YYYY HH:mm"))
            }
        }

        let options : Partial<AirDatepickerOptions> = {
            "locale":i18n.getLanguage() == 'de' ? localeDe : localeEn,
            "startDate": startDate.toDate(),
            "dateFormat": "dd.MM.yyyy",
            "timeFormat": "HH:mm",
            "onSelect": function ({ date, formattedDate, datepicker }) {
                console.log(formattedDate)
                thisComponent.$emit('update:modelValue', formattedDate)
                thisComponent.$emit('input', formattedDate)
                thisComponent.$emit('change', formattedDate)
                thisComponent.modelValue = formattedDate
            },
            position({ $datepicker, $target, $pointer, done }) {
                let popper = createPopper($target, $datepicker, {
                    placement: 'top',
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                padding: {
                                    top: 64
                                }
                            }
                        },
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 20]
                            }
                        },
                        {
                            name: 'arrow',
                            options: {
                                element: $pointer
                            }
                        }
                    ]
                })
                return function completeHide() {
                    popper.destroy();
                    done();
                }
            }
        }
        options.timepicker = thisComponent.dateTime
        this.datePicker = new AirDatepicker(thisComponent.$el, options)
        
    }
}
export default inputDateComponent
