<script setup lang="ts">
  import { ref } from 'vue';

  const isActive = ref(false)
  const maxHeight = ref(0)
  const content = ref(<HTMLElement|null>null)
  
  const inputToggle = () => { 
    isActive.value = !isActive.value 
  }

  const updateHeight = () => {
    if(content.value) maxHeight.value = Number(content.value.scrollHeight)
  }
</script>
<template>
  <div class="accordion">
      <div>
        <div class="accordion-title content-2 border" v-on:click="inputToggle(); updateHeight()" :class="{ 'active': isActive }">
          <slot name="title"></slot>
        </div>
        <div 
          class="accordion-content border-left border-bottom border-right content-2" 
          ref="content" 
          :class="{ 'active': isActive, 'close': !isActive }"
          :style="isActive ? ('max-height:' + (maxHeight + 32) + 'px') : ''"  
        >
          <slot name="content"></slot>
        </div>
      </div>  
    </div>
</template>