import ObjectType, { type ItemlistItemDetail, type Label, type MenuEntry, type ObjectTypeObject, type ObjectTypePayload } from "../../objectType";
import { T } from "@/classes/i18n"
import { Button } from "@/classes/buttons"
import tenantHelpers from "@/helpers/helpers.tenants"
import numberHelpers from "@/helpers/helpers.numbers"
import router from "@/router/router"
import frontendNotifications from "@/classes/notifications";
import ClipboardHandler from "@/classes/clipboard";

export interface TSecureDns {
  id: string
  name: string
  timeRange: {
      id: string
      schedule: number[]
  }[]
  allowlist: {
      ips: string[]
      hosts: string[]
  }
  denylist: {
      ips: string[]
      hosts: string[]
  }
  performance: {
      cacheBoost: boolean
  }
  security: {
      dnsRebindingProtection: boolean
      googleSb: boolean
  }
  rewrites: {
      domain: string
      answer: string
  }[]
  url: string
  mobileConfigUrl: string
};


class SecureDns extends ObjectType<TSecureDns> {
  constructor(payload: ObjectTypePayload<TSecureDns>) {
    super(payload)

    this.itemlist.getSortingOptions = () => {
      return [
        {
          "id": "name",
          "text": T("Name")
        }
      ]
    }
    this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
      let thisToolbarEntries = []
      if (itemlistComponent.exposed.enrollmentAllowed.value && itemlistComponent.exposed.accountRestrictions.value == 0) {
        thisToolbarEntries.push(
          {
            icon: 'fal fa-plus',
            title: T('Add configuration'),
            link: '#add-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-secureDnsProfiles',
            id: 'vpnsButtonAdd',
            vIf: false
          }
        )
      }
      else if (itemlistComponent.exposed.accountRestrictions.value > 0) {
        thisToolbarEntries.push(
          {
            icon: 'fal fa-exclamation-triangle',
            title: T('Enrollment disabled'),
            link: '',
            id: 'vpnsButtonAdd',
            disabled: true,
            vIf: false
          }
        )
      }
      else if (!itemlistComponent.exposed.enrollmentAllowed.value) {
        thisToolbarEntries.push(
          {
            icon: 'fal fa-exclamation-triangle',
            title: T('You have reached the devices limit'),
            link: '',
            id: 'vpnsButtonAdd',
            disabled: true,
            vIf: false
          }
        )
      }
      return thisToolbarEntries
    }

    this.itemlistItem.hasCheckbox = () => {
      return true
    }

    this.itemlistItem.getMenuEntries = (accountId, item) => {
      let menuLinks = []

      menuLinks.push(
        new Button({
          title: T('Delete'),
          text: T('Delete'),
          onClick: () => {
            this.dialogs.getDeleteObjectDialog(accountId, item)
          },
          icon: 'fal fa-trash',
        })
      )
      return menuLinks
    }

    this.itemlistItem.getDetails = (accountId, item) => {
      const defaultItem = "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"

      return [
        {
          iconClass: 'fal fa-fw fa-cloud',
          title: T('Categories #'),
          key: T('Categories #'),
          value: item ? item.timeRange.length : defaultItem
        },
        {
          iconClass: 'fal fa-fw fa-cloud',
          title: T('IP-Settings #'),
          key: T('IP-Settings #'),
          value: item ? item.allowlist.ips.length + item.denylist.ips.length : defaultItem
        },
        {
          iconClass: 'fal fa-fw fa-cloud',
          title: T('Host-Settings #'),
          key: T('Host-Settings #'),
          value: item ? item.allowlist.hosts.length + item.denylist.hosts.length : defaultItem
        },
        {
          iconClass: 'fal fa-fw fa-link',
          title: T('URL'),
          key: T('URL'),
          [item ? "labels" : "content"]: item ? [{
              title: T("Copy to clipboard"),
              text: T("Copy to clipboard"),
              onClick: function () {
                const clipboard = new ClipboardHandler()
                clipboard.copy(item.url || "")

                frontendNotifications.addNotification(accountId, {
                    "accountId": accountId,
                    "content": {
                        "body": {
                            "content": item.url?.substring(0, 20) + "...",
                        },
                        "title": {
                            "text": "Secure DNS Config: " + item.name + " " + T("copied"),
                            "icon": "fal fa-check"
                        }
                    },
                    "highlightNew": true,
                })
              },
              icon: 'fal fa-copy',
              class: "bg-red",
              displayType: "label"
          }] : defaultItem
      },
      {
        iconClass: 'fab fa-apple',
        title: T('Mobileconfig'),
        key: T('Mobileconfig'),
        [item ? "labels" : "content"]: item ? [{
            title: T("Download mobileconfig"),
            text: T("Download mobileconfig"),
            onClick: function () {
              const a = document.createElement('a');
              a.href = item.mobileConfigUrl;
              a.click();
            },
            icon: 'fal fa-copy',
            class: "bg-red",
            displayType: "label"
        }] : defaultItem
    }
      ]
    }

    this.itemlistItem.onClick = (accountId, item) => {
        router.navigate('#edit-tenant-' + accountId + '.sms-secureDnsProfiles-' + item?.id)
    }
  }
}

const secureDns = new SecureDns({
  "productType": "secureDns",
  "slug": "secureDnsProfiles",
  "objectType": "secureDnsProfiles",
  "hasStore": true,
  "appearance": {
    "iconClass": "fal fa-server",
    "text": {
      "plural": "Secure DNS",
      "title": "Secure DNS",
      "sidebarName": "Secure DNS",
      "singular": "Secure DNS"
    },
    "color": "red",
    "showInSidebar": true,
    "showOnDashboard": true,
  },
  "objectTypeInfo": {
    "primaryKeyProperty": {
      "property": "id",
      "pathToPrimaryProperty": undefined
    },
    "nameProperty": {
      "primary": "name",
      "pathToPrimaryProperty": undefined,
      "secondary": undefined,
      "pathToSecondaryProperty": undefined
    }
  },
  "apiInfo": {
    "url": "/sms-mgt-api/api/2.0",
    "getCountGETProperties": "?props[]=null&select=data.count",
    // GET
    "getObjectListResponseProperty": "securedns",
    "getObjectListMethod": "GET",
    "getObjectListPath": "/tenants/{tenantDomain}/secure-dns/configs",
    // Update
    "updateObjectMethod": "PUT",
    "updateObjectPath": "/tenants/{tenantDomain}/secure-dns/configs/{objectId}",
  },
})


export default secureDns