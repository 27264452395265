import enrollmentMixin from "@/mixins/mixins.enrollment";
import { useGlobalMixin } from "@/mixins/mixins.global";
import loaderComponent from "../components/loader.vue";
import type { User } from "@/classes/unifiedSecurity/users";
import { ActionTypes } from "@/store/vuex.store";
import objectStores from "@/classes/init";
import type { License } from "@/classes/unifiedSecurity/licenses";
import queries from "@/queries/queries";
import router from "@/router/router";
import { T } from "@/classes/i18n";
import moment from "moment"
import { useStore } from '@/store/vuex.store'
import { vueApp } from "@/app";
import timeHelpers from "@/helpers/helpers.time";
import imageHelpers from "@/helpers/helpers.images";
import jsonHelpers from "@/helpers/helpers.json";
import inputVueSelectVue from "../inputtypes/input-vue-select.vue";
import mixinHelpers from "@/helpers/helpers.mixins";
import config from "@/classes/config";
import products from "@/classes/objectTypes";
import type { VppUser } from "@/classes/objectTypes/mobileSecurity/apple/vppUsers";

const iosEnrollmentDialogComponent = {
    mixins: [useGlobalMixin(),enrollmentMixin],
    "name":"ios-enrollment-dialog",
    template: `
        <div>
            <template v-if="initialized">
                <template v-if="deviceLimitReached">
                    <div class="padding-xs-2">
                        <p>
                            {{ T('You have reached the devices limit. If you want to add more Devices, you have to upgrade your Mobile Security License.') }}
                        </p>
                    </div>
                </template>
                <template v-else-if="enrollmentDisabled">
                    <div class="padding-xs-2">
                        <p>
                            {{ T('The enrollment has been disabled.') }}
                        </p>
                    </div>
                </template>
                <template v-else-if="mdmLicenseExceeded">
                    <div class="padding-xs-2">
                        <p>
                            {{ T('Your Mobile Security has exceeded. Please upgrade your Mobile Security License.') }}
                        </p>
                    </div>
                </template>
                <template v-else>
                    <div>
                        <div>
                            <div class="col-xs-24">
                                    <div class="row padding-xs-b form-group border-bottom">
                                        <div class="first col-xs-24 col-lg-6 col-xl-5">
                                            <label
                                                class="control-label inputname"
                                                for="useManagedAppleId"
                                            >
                                                {{ T('Use managed Apple ID') }}
                                            </label>
                                        </div>
                                        <div class="input col-xs-24 col-lg-8">
                                            <label class="checkbox toggle">
                                                <input type="checkbox" id="useManagedAppleId" class="toggle" v-model="enrollment.ios.useManagedAppleId">
                                                <span></span>
                                            </label>
                                        </div>
                                        <div class="desc col-xs-24 col-lg-10">
                                            <p class="input-description">
                                                {{ T('Wether you want to enroll with a managed Apple ID or not.') }}
                                            </p>
                                        </div>
                                    </div>


                                    <div class="row padding-xs-y form-group border-bottom" v-if="enrollment.ios.useManagedAppleId">
                                        <div class="first col-xs-24 col-lg-6 col-xl-5">
                                            <label
                                                class="control-label inputname"
                                                for="managedAppleId"
                                            >
                                                {{ T('Managed Apple ID') }}
                                            </label>
                                        </div>
                                        <div class="input col-xs-24 col-lg-8">
                                            <input-vue-select
                                                v-model="enrollment.ios.username"
                                                :selectOptions="enrollment.ios.appleIdOptions"
                                                :placeholder="T('Choose a Managed Apple ID')"
                                                :multiple="false"
                                                id="managedAppleId"
                                            >
                                            </input-vue-select>
                                        </div>
                                        <div class="desc col-xs-24 col-lg-10">
                                            <p class="input-description">
                                                {{ T('Choose a Managed Apple ID') }}
                                            </p>
                                        </div>
                                    </div>

                                <div class="row padding-xs-y form-group border-bottom" v-if="enrollment.license.licenseOptions.length">
                                    <div class="first col-xs-24 col-lg-3">
                                        <label
                                            class="control-label inputname"
                                            for="licenseUUID"
                                        >
                                            {{ T('License') }}
                                        </label>
                                    </div>
                                    <div class="input col-xs-24 col-lg-11">
                                        <label class="checkbox toggle">
                                            <select
                                                id="licenseUUID" :disabled="enrollment.license.disabled || null"
                                                v-model="enrollment.license.selected"
                                                style="width:100%"
                                            >
                                                <option
                                                    value=""
                                                    disabled
                                                    :disabled="true || null">
                                                    {{ T('Please select a license') }}
                                                </option>
                                                <template v-for="license in enrollment.license.licenseOptions">
                                                    <option
                                                        :value="license.id"
                                                        :disabled="license.disabled || null"
                                                    >
                                                        {{ license.text }}
                                                    </option>
                                                </template>
                                            </select>
                                            <span></span>
                                        </label>
                                    </div>
                                    <div class="desc col-xs-24 col-lg-10">
                                        <p class="input-description">
                                            {{ T('The license that will be used for new enrolled devices.') }}
                                        </p>
                                    </div>
                                </div>
                                <template v-if="enrollment.ios.useManagedAppleId == false || (enrollment.ios.useManagedAppleId == true && isEmail(getMailWithoutIndex(enrollment.ios.managedAppleId)))">
                                    <div class="row padding-xs-y border-bottom">
                                        <div class="first col-xs-24 col-lg-3">
                                            <label class="control-label margin-xs-t-0"  for="device-enrollment-url">
                                                {{T('URL')}}
                                            </label>
                                        </div>
                                        <div class="input col-xs-24 col-lg-11">
                                            <input id="device-enrollment-url" type="text" class="form-control" :value="enrollment.enrollmentURL + (enrollment.ios.useManagedAppleId == true && isEmail(getMailWithoutIndex(enrollment.ios.managedAppleId)) ? ('&managedAppleId='+ getMailWithoutIndex(enrollment.ios.managedAppleId)) : '') +'&licenseUUID='+enrollment.license.selected" readonly onClick="this.select();"/>
                                        </div>
                                        <div class="input col-xs-24 col-lg-5 text-center">
                                            <a 
                                                class="btn btn-primary" 
                                                :class="{'btn-loading':copyURLStatus == 'loading'}"
                                                v-on:click="copyToClipboard(enrollment.enrollmentURL + (enrollment.ios.useManagedAppleId == true && isEmail(getMailWithoutIndex(enrollment.ios.managedAppleId)) ? ('&managedAppleId='+ getMailWithoutIndex(enrollment.ios.managedAppleId)) : '') +'&licenseUUID='+enrollment.license.selected)"
                                                :disabled="copyURLStatus != 'none' || null"
                                            >
                                                <span class="animate">
                                                    <loader
                                                        v-if="copyURLStatus == 'loading'"
                                                        style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
                                                    ></loader>
                                                </span>
                                                <span>
                                                    
                                                    <template v-if="copyURLStatus == 'none'">
                                                        {{ T('Copy to clipboard') }}
                                                    </template>
                                                    <template v-if="copyURLStatus == 'success'">
                                                        <i class="fal fa-check"></i>
                                                    </template>

                                                </span>
                                                
                                            </a>
                                        </div>
                                        <div class="input col-xs-24 col-lg-5 text-center">
                                            <a class="btn btn-primary" :href="enrollment.enrollmentURL + (enrollment.ios.useManagedAppleId == true && enrollment.ios.useManagedAppleId != '' && isEmail(getMailWithoutIndex(enrollment.ios.managedAppleId)) ? ('&managedAppleId='+ getMailWithoutIndex(enrollment.ios.managedAppleId)) : '') +'&licenseUUID='+enrollment.license.selected" target="_blank">
                                                ${T('Download Profile')}
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row padding-xs-y">
                                        <div class="col-xs-24">
                                            <div class="form-group padding-xs-y">



                                                <div class="col-sm-12 text-center" v-if="false">
                                                    <h5 class="padding-xs-y" v-if="false">
                                                        {{ T('1. Scan this QR-Code and go through the process to link your Apple ID to the managed Apple ID') }}
                                                    </h5>
                                                    <img 
                                                        style="display:inline-block;" 
                                                        :src="getUserEnrollmentQrURI(enrollment.ios.username)"
                                                    />
                                                    <br>
                                                    <a
                                                        class="btn"
                                                        @click="printUserEnrollmentImage(getUserEnrollmentQrURI(enrollment.ios.username))"
                                                    >
                                                        <i class="fal fa-print"></i> ${T('Print QR-Code')}
                                                    </a>
                                                </div>

                                                <div 
                                                    class="text-center"
                                                    :class="{
                                                        'col-sm-24':!(enrollment.ios.useManagedAppleId && enrollment.ios.hasInviteURL) || true,
                                                        'col-sm-12':enrollment.ios.useManagedAppleId && enrollment.ios.hasInviteURL && false
                                                    }"    
                                                >
                                                    <h5 class="padding-xs-y" v-if="enrollment.ios.useManagedAppleId && enrollment.ios.hasInviteURL && false">
                                                        {{ T('2. Scan this device enrollment QR-Code') }}
                                                    </h5>
                                                    <img style="display:inline-block;" :src="enrollment.ios.qrcodeURL+'?licenseUUID='+enrollment.license.selected + (enrollment.ios.useManagedAppleId == true && enrollment.ios.useManagedAppleId != '' && isEmail(getMailWithoutIndex(enrollment.ios.managedAppleId)) ? ('&managedAppleId='+ getMailWithoutIndex(enrollment.ios.managedAppleId)) : '')"/>
                                                    <br>
                                                    <a
                                                        class="btn"
                                                        @click="printImage(enrollment.ios.qrcodeURL+'?licenseUUID='+enrollment.license.selected + (enrollment.ios.useManagedAppleId == true && enrollment.ios.useManagedAppleId != '' && isEmail(getMailWithoutIndex(enrollment.ios.managedAppleId)) ? ('&managedAppleId='+ getMailWithoutIndex(enrollment.ios.managedAppleId)) : ''))"
                                                    >
                                                        <i class="fal fa-print"></i> ${T('Print QR-Code')}
                                                    </a>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <template v-if="!initialized">
                <div>
                    <div class="text-size-3 text-center padding-xs-t-4">
                        <loader class="color-red"></loader>
                    </div>
                    <template v-if="loaderText">
                        <div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity:0.8">
                        <span>{{ T(loaderText) }}</span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="padding-xs-b-4"></div>
                    </template>
                </div>
            </template>
        </div>
    `,
    data: function() {
        return {
            "initialized":false,
            "loaderText":"Loading...",
            "enrollment":{
                "EID":"",
                "enrollmentURL":"",
                "license": {
                    "licenseOptions":[],
                    "selected":"",
                    "disabled":false
                },
                "ios": {
                    "enabled":true,
                    "disabledReason":"",
                    "activeTab":null,
                    "qrcodeURL":"",
                    "useManagedAppleId":false,
                    "managedAppleId":"",
                    "appleIdOptions":[],
                    "username":"",
                    "hasInviteURL":false,
                    "hasInviteCode":false,
                },
            },
            "copyURLStatus":"none"
        }
    },
    methods: {
        copyToClipboard: function(this:any,text:string) {
            this.copyURLStatus = "loading"
            navigator.clipboard.writeText(text).then(async () => {
                this.copyURLStatus = "success"
                await timeHelpers.sleep(1500)
                this.copyURLStatus = "none"
            },(err) => {
                this.copyURLStatus = "none"
                console.error('Could not copy text: ', err);
            });
        },
        getBodyStyle:function(this:any) {
            let styles : string = "max-height:530px"
            if(this.maximized) {
                styles = 'height: calc(100% - 68px);position: relative;max-height: 100%;'
            }
            return styles
        },
        isEmail: function(this:any, mail:string) {
            return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/).test(mail)
        },
        "getLicenseTypeFromAdditionalData": function(this:any,additionalData:string) {
            let licenseUUID : string = jsonHelpers.getObjectProperty(JSON.parse(additionalData),"licenseUUID") || ""
            let licenseType = "Unknown"
            if(licenseUUID != "") {
                let licenses: any = (config.canUseNewObjectType("licenses")) ? products.unifiedSecurity.licenses.useStore?.().getObjectStoreObjects(this.$root.activeAccountId) : useStore().state.session.accounts[this.$root.activeAccountId].unifiedSecurity.licenses.items || []
                let foundLicenses = licenses.filter(function(license:any) {
                    return license.uuid == licenseUUID
                })
                if(foundLicenses.length == 1) {
                    licenseType = foundLicenses[0].type
                }
            }
            return licenseType
        },
        "printUserEnrollmentImage":function(this:any,URL:string) {
            const source = this.enrollment.ios.qrcodeURL
                    const headerContent = document.getElementById('print-header')?.outerHTML + "<h1 style='margin-top:64px'>iOS User Enrollment</h1>"
                    const footerContent = document.getElementsByClassName("main-footer")[0]?.outerHTML
                    imageHelpers.printImage(headerContent, footerContent, document.location.origin + source)
        },
        "printImage":function(this:any,URL:string) {
            const source = this.enrollment.ios.qrcodeURL
                    const headerContent = document.getElementById('print-header')?.outerHTML + "<h1 style='margin-top:64px'>iOS Enrollment</h1>"
                    const footerContent = document.getElementsByClassName("main-footer")[0]?.outerHTML
                    imageHelpers.printImage(headerContent, footerContent, document.location.origin + source)
        },
        "T":function(content:string) {
            return T(content)
        },
        "getDate":function(this:any, date:string) {
            return moment(date).format('DD.MM.YYYY HH:mm')
        },
        "switchToApnCheck":function(this:any) {
            this.$parent.hideModal()
            router.navigate('show-tenant-'+this.$root.activeTenantDomain+'-ios-devices-dashboard')
            vueApp.checkAppleAndAndroidSetup()
        },
        "setActiveTab": function(this:any,os:string,tab:string) {
            this.enrollment[os].activeTab = tab
        },
        "isExpired":function(this:any,expirationDate:string) {
            return !moment(expirationDate).isSameOrAfter(moment())
        },
        "getMailWithoutIndex": function(this:any,email:string) {
            return email != null && email != undefined && email != "" ? ((email).slice(0, email.indexOf('#usrNr') != -1 ? email.indexOf('#usrNr') : email.length)) : null
        },
        "getUserEnrollmentQrURI": function(this:any,username:string) {
            return config.mgtApiUri + "/tenants/" + this.activeAccountId + ".sms/users/" + username + "/qrcode/vpp"
        }
    },
    created: async function(this : any) {
        let activeTenantDomain : any = this.$root.activeTenantDomain
        let thisComponent : any = this
        this.initialized = false
        this.loaderText = "Getting tenant info..."

        this.enrollment.enrollmentURL = useStore().state.session.accounts[this.$root.activeAccountId].mobileSecurity.settings.ios.enrollmentURL
        this.enrollment.EID = useStore().state.session.accounts[this.$root.activeAccountId].mobileSecurity.settings.ios.EID

        if (useStore().state.session.accounts[this.$root.activeAccountId].mobileSecurity.settings.ios.apns.expired) {
            this.enrollment.ios.enabled = false
            this.enrollment.ios.disabledReason = '<i class="fal fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;iOS: '+T('Upload an Apple Push Certificate first')
        }
        else if (!useStore().state.session.accounts[this.$root.activeAccountId].mobileSecurity.settings.ios.apns.matchesPrivateKey) {
            this.enrollment.ios.enabled = false
            this.enrollment.ios.disabledReason = '<i class="fal fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;iOS: '+T('Invalid Apple Push Certificate')
        }

        this.loaderText = "Getting QR-Codes..."
        this.enrollment.ios.qrcodeURL = await queries.mobileSecurity.getQRCodeURL(activeTenantDomain, "ios")


        // LICENSE SELECTION
        this.loaderText = "Getting licenses..."
        let licenses : License[] = [],
        licenseOptions : any[] = []

        licenses = (config.canUseNewObjectType("licenses") ? products.unifiedSecurity.licenses.useStore?.().getObjectStoreObjects(this.activeAccountId) : objectStores.licenses.getObjectsFromStore(this.activeAccountId)) || []

        for(let i : number = 0; licenses.length > i; i++) {
            let license = licenses[i]
            let licenceUser = mixinHelpers.getLicenseLimit(license)
            if((license.type == 'Mobile Security' || license.type == 'MDM') && license.uuid && license.assignedUser < licenceUser && !license.expired && !license.dead) {
                licenseOptions.push({
                    "id":license.uuid,
                    "text":license.name+" | "+license.type+" ["+license.assignedUser+"/"+licenceUser+"] ("+license.uuid.substring(0,4)+")",
                    "disabled":license.assignedUser >= licenceUser || license.expired || license.dead,
                    "licenseType":license.type
                })
            }
        }
        this.enrollment.license.licenseOptions = licenseOptions

        if(licenseOptions.length) {
            this.enrollment.license.selected = licenseOptions[0].id
        }



        this.loaderText = "Getting registered Email addresses..."
        try {
            let users: any = {}
            if (config.canUseNewObjectType("users")) {
                await products.unifiedSecurity.users.queries.getObjectsFromApi(this.activeAccountId)
            }
            else {
                await useStore().dispatch(ActionTypes.getObjectInfos, {
                    "accountId": this.activeAccountId,
                    "objectTypes": ["users"]
                })    
            }
            if (config.canUseNewObjectType("vppUsers")) {
                await products.mobileSecurity.vppUsers.queries.getObjectsFromApi(this.activeAccountId)
            }
            else {
                await useStore().dispatch(ActionTypes.getObjectInfos, {
                    "accountId": this.activeAccountId,
                    "objectTypes": ["vppUsers"]
                })
            }
            

            
            users = config.canUseNewObjectType("users") ? products.unifiedSecurity.users.useStore?.()?.getObjectStoreObjects(this.activeAccountId) : useStore().getters.getObjectTypeStore({
                "accountId": this.activeAccountId,
                "objectType": "users"
            })?.items || []

            if (users.length) {
                for (let userIndex in users) {
                    let user: User = users[userIndex]
                    if (user.profile?.appleId) {
                        this.enrollment.ios.appleIdOptions.push(
                            {
                                "text": user.username + '<' + user.profile?.appleId + '>',
                                "appleId": user.profile?.appleId,
                                "clientUserIdStr": user.profile?.clientUserIdStr,
                                "id": user.username
                            }
                        )
                    }
                }
            }
        }
        catch(e:any) {
            console.error(e)
            this.loaderText = "<i class='fas fa-exclamation-triangle color-red'></i> Something went wrong"
        }




        this.loaderText = "Finished loading..."
        this.initialized = true
    },
    mounted: function(this : any) {},
    watch: {
        "enrollment.ios.useManagedAppleId": function (this: any, useManagedAppleId:boolean) {
            if(!useManagedAppleId) {
                this.enrollment.ios.username = ""
                this.enrollment.ios.managedAppleId = ""
                this.enrollment.ios.hasInviteURL = false
            }

        },
        "enrollment.ios.username":function(this:any,username:string) {
            if(username?.length) {
                let userOption = this.enrollment.ios.appleIdOptions.find((option:any) => {
                    return option.id == username
                })
                let vppUser: VppUser | undefined = undefined
                if (config.canUseNewObjectType("vppUsers")) {
                    vppUser = products.mobileSecurity.vppUsers.useStore?.().getObjectStoreObject(this.activeAccountId,userOption.clientUserIdStr)
                }
                else {
                    vppUser = useStore().getters.getObject({
                        "accountId": this.activeAccountId,
                        "objectId": userOption.clientUserIdStr,
                        "objectType": "vppUsers"
                    })
                }
                if (vppUser && vppUser.inviteCode) {
                    this.enrollment.ios.hasInviteCode = true
                }
                else {
                    this.enrollment.ios.hasInviteCode = false
                }
                
                if (vppUser && (<any>vppUser).inviteUrl) {
                    this.enrollment.ios.hasInviteURL = true
                }
                else {
                    this.enrollment.ios.hasInviteURL = false
                }
                this.enrollment.ios.managedAppleId = userOption.appleId
            }
            else {
                this.enrollment.ios.managedAppleId = ""
                this.enrollment.ios.hasInviteURL = false
                this.enrollment.ios.hasInviteCode = false
            }
        }
    },
    destroyed: function () {},

    "components": {
        "loader": loaderComponent,
        "input-vue-select": inputVueSelectVue
    }
}
export default iosEnrollmentDialogComponent