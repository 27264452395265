import ObjectType, { type ItemlistItemDetail, type Label, type MenuEntry, type ObjectTypePayload } from "../../objectType";
import objectStores from "@/classes/init";
import config from "@/classes/config";
import { T } from "@/classes/i18n";
import { MutationTypes, useStore } from "@/store/vuex.store";
import router from "@/router/router";
import numberHelpers from "@/helpers/helpers.numbers";
import arrayHelpers from "@/helpers/helpers.arrays";
import deviceHelpers from "@/helpers/helpers.devices";
import type { License } from "../../unifiedSecurity/licenses/licenses";
import moment from "moment";
import buttons from "@/classes/buttons";
import getterHelpers from "@/helpers/helpers.getters";


type SpcliSystemInfoData = {
    "timestamp": number,
    "hardwareSerial": string,
    "hostname": string,
    "version": string,
    "productname"?: string,
    "cluster"?: {
        "licenseId": string,
        "state": string,
        "sync": string,
        "version": string
    }
    "license": {
        "organisation": {
            "name": string,
            "unit": string,
            "contact": string
        },
        "serial": string,
        "valid": string,
        "users": string,
        "product": string,
        "version": string
    },
    "av": {
        "clamav": {
            "updated": string,
            "lastrun": string,
            "status": string
        },
        "ctav": {
            "updated": string,
            "lastrun": string,
            "status": string,
        }
    },
    "storage": {
        "total": number
        "available": number
    },
    "cpu": {
        "name": string,
        "mhz": number,
        "stats": {
            "user": string,
            "nice": string,
            "system": string,
            "idle": string
        }
    },
    "memory": {
        "total": number,
        "available": number
    },
    "swap": {
        "total": number,
        "available": number
    },
    "uptime": string,
    "network": {
        "connections": number,
        "interfaces": number
    },
    "shutdownState": string,
    "update": {
        "old": string,
        "cur": string,
        "new": string,
        "hint": string
    }
} | null

type MergedUpdateInfoData = {
    "old": string,
    "cur": string,
    "new": string,
    "hint": string
} | null

type AutoUpdateStateData = any | null
type SpcliAppmgmtStatusData = any | null
type SpcliInterfaceAddressGetData = any | null
type MergedRebootInfoData = any | null
type clusterInformation = {
    "isCluster": boolean,
    "licenseRole": "none" | "spare" | "master"
}
interface UtmMessages {
    "auto-update-state" ?: AutoUpdateStateData | { timestamp: number, data: AutoUpdateStateData },
    "spcli-system-info" ?: SpcliSystemInfoData | { timestamp: number, data: SpcliSystemInfoData },
    "spcli-appmgmt-status" ?: SpcliAppmgmtStatusData | { timestamp: number, data: SpcliAppmgmtStatusData },
    "merged-update-info" ?: MergedUpdateInfoData | { timestamp: number, data: MergedUpdateInfoData },
    "merged-reboot-info" ?: MergedRebootInfoData | { timestamp: number, data: MergedRebootInfoData },
    "spcli-interface-address-get" ?: SpcliInterfaceAddressGetData | { timestamp: number, data: SpcliInterfaceAddressGetData }
}
export interface UTM extends ObjectType<UTM> {
    "dead"?: boolean,
    "expirationDate"?: string,
    "inventory"?: ObjectInventory,
    "license"?: License,
    "licenseInformation"?: License,
    "licensed"?: boolean,
    "messages"?: UtmMessages,
    "model"?: string,
    "offlineReason"?: {
        "clientContext": string,
        "since": number
    },
    "spare"?: boolean,
    "tags"?: string[],
    "tenantDomain"?: string,
    "utmId": string,
    "utmname"?: string,
    "operationsLog"?: [],
    "cluster"?: clusterInformation
}



export class UTMs extends ObjectType<UTM> {
    constructor(payload: ObjectTypePayload<UTM>) {
        super(payload)
        this.itemlist.getSortingOptions = () => [
            {
                "id": "utmname",
                "text": "Name"
            }
        ]
        this.itemlist.getInfoBoxContent = (accountId: string, itemlistComponent: any) => {
            return ""
        }
        this.itemlistItem.onClick = (accountId: string, item) => {
            if (item?.utmId) {
                router.navigate('#edit-tenant-' + accountId + '.sms-usrutm-' + item?.utmId)
            }
        }
        this.itemlistItem.getLabels = (accountId: string, item) => {
            let result: Label[] = []

            if (item?.cluster?.isCluster === true && item.license?.clusterUuid) {
                result.push(
                    {
                        title: "Cluster " + deviceHelpers.getShortDeviceId(item.license.clusterUuid, 4),
                        text: "Cluster " + deviceHelpers.getShortDeviceId(item.license.clusterUuid, 4),
                        class: "bg-blue",
                        icon: "fa fa-circle-nodes"
                    }
                )
            }
            if (objectStores.uscUtms.isUpdateNeeded(item)) {
                result.push({
                    title: T("Update strongly recommended!"),
                    text: T("Update strongly recommended!"),
                    class: 'bg-red',
                    icon: "fa fa-exclamation-triangle"
                })
            }
            return result
        }

        this.itemlistItem.getDetails = (accountId, item?,component?) => {
            component = component?.exposed ? component?.exposed : component
            let result: ItemlistItemDetail[] = []
            const thisTagsArray = item?.tags?.map(function (tag: string) {
                return {
                    "id": tag,
                    "text": tag,
                    "title": tag,
                    "displayType": "label"
                }
            })

            result = [
                {
                    iconClass: 'fal fa-fw fa-server',
                    title: T('Model'),
                    key: T('Model'),
                    value: item ? this.getUtmMessageData(item, 'spcli-system-info')?.productname || "" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                },
                {
                    iconClass: 'fal fa-fw fa-key',
                    title: T('Expires on'),
                    key: T('Expires on'),
                    value: item ? moment(item?.expirationDate).format("DD.MM.YYYY") : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                },
                {
                    iconClass: 'fal fa-fw fa-tags',
                    title: T('Tags'),
                    key: T('Tags'),
                    [item ? "labels" : "value"]: item ? thisTagsArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                    editableContent: {
                        "type": "select2",
                        "options": "tags",
                        "select2Settings": {
                            "tags": true,
                            "multiple": true,
                            "placeholder": "Select tags"
                        },
                        "ref": "editTags",
                        "value": item?.tags || [],
                        "editingBoolProperty": "editTags",
                        "editButton": buttons.createButton({
                            "onClick": function () {
                                component.editTags.value = true
                            },
                            "icon": "fal fa-edit"
                        }),
                        "submitFunction": async (value: string[]) => {
                            try {
                                await this.queries.updateObjectPropertiesFromApi(accountId, item?.utmId || '', { 'utmname': item?.utmname, 'tags': value }, 'tags', undefined, undefined, undefined, "PUT")
                                getterHelpers.useStore().commit(MutationTypes.setObjectInfos, {
                                    'accountId': accountId, 'products': {
                                        'mobileSecurity': {
                                            'tags': value.filter(arrayHelpers.onlyUniqueFilter).map(function (tag: string) {
                                                return { "id": tag, "text": tag }
                                            })
                                        }
                                    }
                                })
                                component.editTags.value = false
                            }
                            catch (e: any) {
                                component.editTags.value = false
                                component.error.value = true
                                console.error(e)
                                if (e.responseJSON != undefined) {
                                    e = e.responseJSON
                                }
                                if (e?.errors?.errors?.[0]?.message != undefined) {
                                    component.errorMsg.value = e?.errors?.errors?.[0]?.message + ". " + T("Tags may not contain spaces or umlauts.")
                                }
                                setTimeout(function () {
                                    component.error.value = false
                                    component.errorMsg.value = ""
                                }, 4000)
                            }
                        },
                        "abortFunction": function () {
                            component.editTags.value = false
                        }
                    }
                },

                {
                    iconClass: 'fal fa-fw fa-user',
                    title: T('Licensee'),
                    key: T('Licensee'),
                    value: item ? this.getUtmMessageData(item, 'spcli-system-info')?.license?.organisation?.name ? this.getUtmMessageData(item, 'spcli-system-info')?.license?.organisation?.name : "" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                },
                {
                    iconClass: 'fal fa-fw fa-user',
                    title: T('License'),
                    key: T('License'),
                    [item ? "labels" : "value"]: item ? [{
                        "id": item.utmId + "_" + item.license?.name,
                        "text": item.license?.name + " (" + deviceHelpers.getShortDeviceId(item?.utmId) + ")",
                        "title": item.license?.name + " (" + deviceHelpers.getShortDeviceId(item?.utmId) + ")",
                        "onClick": function () {
                            router.navigate('show-tenant-' + accountId + '.sms-license-' + item?.license?.id + '-details')
                        },
                        "displayType": "label"
                    }] : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                }
            ]
            return result
        }

        this.itemlistItem.hasCheckbox = () => {
            return false
        }
        this.itemlistItem.isClickable = (accountId, item) => {
            return true
        }

        this.itemlistItem.getDisabledState = (accountId, item) => {
            return false
        }
    }

    getUtmMessageData(utm: UTM, messageDataType: keyof UtmMessages): UtmMessages[keyof UtmMessages] | undefined {
        if (!utm) {
            return undefined
        }
        else if (!utm?.messages) {
            return undefined
        }
        else if (utm?.messages?.[messageDataType] && utm.messages[messageDataType].data != undefined && utm.messages[messageDataType].timestamp != undefined) {
            return utm.messages[messageDataType].data
        }
        else {
            return utm.messages[messageDataType]
        }
    }

}


const usrUtms = new UTMs({
    "objectType": "usrUtms",
    "productType": "unifiedReporting",
    "slug": "usrutms",
    "apiInfo": {
        "getCountGETProperties": "?props[]=license&select=data.utms[?(license.licenseScopes[?contains(@,'laas_01') ==`true`])]|length(@)",
        "url": config.mgtApiUriNext,
        "getObjectListPath": "/tenants/{tenantDomain}/utms?props[]=license&select=data.utms[?(license.licenseScopes[?contains(@,'laas_01') ==`true`])]",
        "getObjectListResponseProperty": "",
        "getObjectPath": "/tenants/{tenantDomain}/utms/{objectId}",
        "updateObjectUrl": config.mgtApiUri,
        "updateObjectPath": "/tenants/{tenantDomain}/laas/utms/{objectId}"
    },
    "hasStore": true,
    "objectTypeInfo": {
        "nameProperty": {
            "primary": "utmname"
        },
        "primaryKeyProperty": {
            "property": "utmId"
        },
    },
    "appearance": {
        "color": "red",
        "iconClass": "fal fa-server",
        "showInSidebar": true,
        "text": {
            "plural": "UTMs",
            "sidebarName": "UTMs",
            "title": "UTMs",
            "singular": "UTM"
        }
    }

})
export default usrUtms