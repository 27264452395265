import jsonHelpers from "@/helpers/helpers.json"
import ObjectType, { type AccountId, type AddObjectTypeObject, type ObjectTypeObject, type ObjectTypePayload } from "../../objectType"

export interface ActiveUser extends ObjectType<ActiveUser> {
    username: string
    domain: string
    ip?: string
    userAgent?: string
    creationTime: number
}


class ActiveUsers extends ObjectType<ActiveUser> {
    constructor(payload: ObjectTypePayload<ActiveUser>) {
        super(payload)
    }
}

const activeUsers = new ActiveUsers({
    "productType":"mobileSecurity",
    "objectType":"activeUsers",
    "slug":"activeUsers",
    "hasStore":true,
    "appearance":{
        "iconClass":"fab fa-android",
    },
    "apiInfo":{
        "url": "/sms-mgt-api/api/2.0",
        "getCountGETProperties": "?props[]=null&select=total",
        // GET
        "getObjectListResponseProperty": "accounts",
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/websocket/active-users",
    }
})
export default activeUsers