<script setup lang="ts">
	import { ActionTypes, MutationTypes } from "@/store/vuex.store";
	import { useGlobalMixin } from "@/mixins/mixins.global";
	import i18n, { T } from "@/classes/i18n";
	import router from "@/router/router";
	import { useStore } from '@/store/vuex.store';
	import loader from "../components/loader.vue";
	import dialogs from "@/dialogs/dialogs";
	import queries from "@/queries/queries";
	import tenantHelpers from "@/helpers/helpers.tenants";
	import jsonHelpers from "@/helpers/helpers.json";
	import timeHelpers from "@/helpers/helpers.time";
	import encodingHelpers from "@/helpers/helpers.encoding";
	import { computed, onMounted, ref, watch } from "vue";
	import mixinHelpers from "@/helpers/helpers.mixins";
	import config from "@/classes/config";
	import products from "@/classes/objectTypes";
	import vppApi from "@/classes/objectTypes/mobileSecurity/apple/vpp";
	import inputPin from "../inputtypes/input-pin.vue";
	import btn from "../components/button.vue";
	import getterHelpers from "@/helpers/helpers.getters";
	import { useVue } from "@/app";

	const accountRestrictionOptions = [
		{ "id": 0, "text": "No restrictions" },
		{ "id": 1, "text": "No enrollment" },
		{ "id": 2, "text": "No enrollment, no VPN" }
	]
	const activeAccountId = computed(function() {
		return useStore().state.session.activeAccountId
	})
	const accountInitialized = computed(function() {
		return useStore().state.session.accounts[activeAccountId.value || ''].initialized
	})
	const activeAccount = computed(function() {
		return useStore().state.session.accounts[activeAccountId.value || '']
	})
	const settings = computed(function() {
		return useStore().state.session.accounts[activeAccountId.value || ''].mobileSecurity.settings
	})
	const accountRestrictions = computed({
		get: function() {
			return useStore().state.session.accounts[activeAccountId.value || ''].mobileSecurity.settings.accountRestrictions
		},
		set: function(restriction: any) {
			useStore().dispatch(ActionTypes.setTenantProperty, {
				"accountId": activeAccountId.value || '',
				"property": "accountRestrictions",
				"value": parseInt(restriction)
			})
		}
	})
	const legalHold = computed({
		get: () => {
			return useStore().state.session.accounts[activeAccountId.value || ''].unifiedReporting.settings.legalHold || "0"
		},
		set: (legalHold: any) => {
			useStore().dispatch(ActionTypes.setTenantProperty, {
				"accountId": activeAccountId.value,
				"property": 'legalHold',
				"value": legalHold.toString()
			})
		}
	})
	const triggerVPPAppUpdates = computed({
		get: () => {
			return (useStore().state.session.accounts[activeAccountId.value || ''].mobileSecurity.settings.triggerVPPAppUpdates || "1") == "1"
		},
		set: (triggerVPPAppUpdates: any) => {
			useStore().dispatch(ActionTypes.setTenantProperty, {
				"accountId": activeAccountId.value,
				"property": 'triggerVPPAppUpdates',
				"value": triggerVPPAppUpdates ? "1" : "0"
			})
		}
	})

	const appleReEnrollment = computed({
		get: () => {
			return (useStore().state.session.accounts[activeAccountId.value || ''].mobileSecurity.settings.appleReEnrollment || "0") === "1"
		},
		set: (appleReEnrollment) => {
			useStore().dispatch(ActionTypes.setTenantProperty, {
				"accountId": activeAccountId.value,
				"property": 'appleReEnrollment',
				"value": appleReEnrollment ? "1" : "0"
			})
		}
	})

	const zeroTouchCustomers = computed(() => {
		let allCompanies = config.canUseNewObjectType('zeroTouchCustomers') ? products.mobileSecurity.zeroTouchCustomers.useStore?.().getObjectStoreObjects(activeAccountId.value || '') : useStore()?.getters.getObjects({
			"active": activeAccountId.value || '',
			"objectType": "zeroTouchCustomers",
			"productType": "mobileSecurity"
		})
		let customers: any = {}
		allCompanies.forEach((company: any) => {
			if (customers[company.id] == undefined) {
				customers[company.id] = []
			}
			customers[company.id].push(company)
		});
		return customers
	})





	const hasAccountRole = mixinHelpers.$hasAccountRole
	const getObjectLength = jsonHelpers.getObjectLength
	const hasFunctionality = (functionality: string) => { 
		return tenantHelpers.hasFunctionality(activeAccountId.value || "", functionality) 
	}
	 
	const isFunctionalityEnabled = (functionality: string) => { 
		return tenantHelpers.isFunctionalityActivated(functionality) 
	}

	const getDateWithTime = function(date: any) {
		return timeHelpers.formatDate(date, timeHelpers.getDateFormatI18n(i18n.getLanguage(), true))
	}
	const downloadCert = async function(this: any) {
		try {
			await queries.mobileSecurity.downloadApplePushCert(activeAccountId.value || '')
		}
		catch (e: any) {
			console.error(e)
		}
	}
	 const getApplePushCertDialog = function(update: boolean = false) {
		dialogs.mobileSecurity.setApplePushCertificate(activeAccountId.value || '', update)
	}
	 const getEnterpriseDialog = function(type: string = "either", fromEmailButton: boolean = false) {
		if (type == 'either') {
			// either redirect
			if (!tenantHelpers.hasFunctionality(activeAccountId.value || '', 'enterprise')) {
				location.href = settings.value.android.enterprise.signupUrl
			}
			// or display success dialog
			else {
				getterHelpers.useStore().dispatch(ActionTypes.addModal, {
					"id": "addGmailAddress",
					"accountId": activeAccountId.value,
					"abortable": false,
					"content": {
						"title": {
							"text": fromEmailButton == false ? T("Successfully added Google Enterprise") : T("Set E-Mail Address"),
							"icon": fromEmailButton == false ? "fal fa-check" : "fal fa-plus",
						},
						"body": {
							"use": true,
							"content": undefined,
							"component": 'add-gmail-address',
							"properties": {
								"fromEmailButton": fromEmailButton
							}
						},
					},
					"buttons": [
						{
							"text": T("Close"),
							"icon": "fal fa-times",
							"onClick": async function (this: any) {
								// Since the user can cancel the button after linking AndroidEnterprise, we still have to do the required requests
								if(fromEmailButton == false) {
									try {
										await useStore().dispatch(ActionTypes.updateAccount, activeAccountId.value)
										router.navigate('show-tenant-' + activeAccountId.value + '.sms-mobile-security-settings')
									}
									catch(e: unknown) {
										console.error(e)
									}
								}
								useVue().$refs.modals.removeModal()
							},
							"align": "left",
							"disabled": false,
							"loading": false
						},
						{
							"text": T("Save"),
							"icon": "fal fa-check",
							"onClick": async function (this: any) {
								let result: boolean = false
								try {
									result = await useVue().$refs.modals.$refs.modal.$refs.modalComponent.submit()
									result = result
									if (result == true && useVue()?.$refs?.modals?.removeModal) {
										useVue().$refs.modals.removeModal()
									}
								}
								catch (e: unknown) {
									console.error(e)
								}
							},
							"align": "right",
							"disabled": false,
							"loading": false
						}
					]
				})
			}
		}
		else if (type == 'unlink') {
			dialogs.mobileSecurity.enterpriseUnlinkDialog(activeAccountId.value || "")
		}
	}
	 const getZeroTouchDialog = function(type: string = "add") {
		if (type == 'either') {
			// either redirect
			location.href = encodingHelpers.unescapeHTML(settings.value.android.zeroTouch.redirectUrl)
		}
		else if (type == 'unlink') {
			dialogs.misc.confirmDialog(
				activeAccountId.value || '',
				T('Unlink Zero Touch Account'),
				T('This removes Android Zero Touch functionalities from Securepoint Mobile Security'),
				async () => {
					await queries.mobileSecurity.unlinkZeroTouch(activeAccountId.value || '')
					await useStore().dispatch(ActionTypes.updateAccount, activeAccountId.value)
					router.navigate('show-tenant-' + activeAccountId.value + '.sms-mobile-security-settings')
					location.reload()
				},
				T('Unlink')
			)
		}
	}
	 const getConfirmZeroTouchDialog = function() {
		dialogs.misc.confirmDialog(
			activeAccountId.value || '',
			T('You successfully added your Google account'),
			(T('You successfully added your Google account') + '.<br> ' + T('You may now close this window') + '.'),
			async () => {
				await useStore().dispatch(ActionTypes.updateAccount, activeAccountId.value)
				router.navigate('show-tenant-' + activeAccountId.value + '.sms-mobile-security-settings')
			},
			undefined,
			T('Close')
		)
	}
	 const deleteZeroTouchCustomer = function(customerId: string) {
		dialogs.misc.confirmDialog(
			activeAccountId.value || '',
			T('Unlink Google account'),
			T('Do you really want to unlink this Google Account?'),
			async () => {
				if(config.canUseNewObjectType('zeroTouchCustomers')) {
					await products.mobileSecurity.zeroTouchCustomers.queries.deleteObjectFromApi(activeAccountId.value || '',customerId)
				}
				else {
					await useStore().dispatch(ActionTypes.deleteObject, {
						'accountId': activeAccountId.value as string,
						'objectId': customerId,
						'productType': "mobileSecurity",
						'objectType': "zeroTouchCustomers"
					})
				}
				await useStore().dispatch(ActionTypes.updateAccount, activeAccountId.value)

			},
			T('Unlink')
		)
	}
	 const getDEPDialog = function(type: string = 'add') {
		dialogs.mobileSecurity.setAppleDepDialog(activeAccountId.value || '', type)
	}
	 const getVPPDialog = function(type: string = 'add') {
		dialogs.mobileSecurity.setAppleVPPDialog(activeAccountId.value || '', type)
	}
	 const deleteDEPTokenDialog = function() {
		dialogs.misc.confirmDialog(
			activeAccountId.value || '',
			T("Confirm delete"),
			T("This will delete your apple DEP-Token from Mobile Security"),
			async () => {
				
				await queries.mobileSecurity.deleteDEPToken(activeAccountId.value || '')
				;location.reload()
			},
			T("DELETE")
		)
	}
	 const deleteVPPTokenDialog = function() {
		dialogs.misc.confirmDialog(
			activeAccountId.value || '',
			T("Confirm delete"),
			T("This will delete your apple VPP-Token from Mobile Security"),
			async () => {
				if (config.canUseNewObjectType("vppUsers")) {
					await vppApi.deleteTokenInfo(activeAccountId.value || "")
				}
				else {
					await queries.mobileSecurity.deleteVPPToken(activeAccountId.value || '')
				}
				;location.reload()
			},
			T("DELETE")
		)
	}

	const depPin = ref("")
	const useDepPin = ref(false)
	const savingDepPin = ref(false)
	const saveDepPin = async () => {
		savingDepPin.value = true
		const pin = useDepPin.value ? depPin.value : ""
		depPin.value = pin
		if(pin.length) {
			useStore().dispatch(ActionTypes.setTenantProperty, {
				"accountId": activeAccountId.value,
				"property": 'depEnrollmentCode',
				"value": pin
			})
		}
		else {
			await queries.unifiedSecurity.deleteTenantProperty(activeAccountId.value || "","depEnrollmentCode")
			useStore().commit(MutationTypes.setTenantProperty, {
				"accountId":activeAccountId.value,
				"property":"depEnrollmentCode",
				"value":""
			})
		}
		setTimeout(() => {
			savingDepPin.value = false
		},2000)
		
	}
	const canSaveDepPin = computed(() => {
		return depPin.value.length == 6 && useDepPin.value == true
	})

	watch(useDepPin,() => {
		if(useDepPin.value == false) {
			saveDepPin()
		}
	})
	watch(accountInitialized,() => {
		
	})

	onMounted(() => {
		if (settings.value.depEnrollmentCode.length > 0) {
			depPin.value = settings.value.depEnrollmentCode
			useDepPin.value = true
		}
	})

	defineExpose({
		getApplePushCertDialog,
		getEnterpriseDialog,
		getZeroTouchDialog,
		getConfirmZeroTouchDialog,
		deleteZeroTouchCustomer,
		getDEPDialog,
		getVPPDialog,
		deleteDEPTokenDialog,
		deleteVPPTokenDialog
	})

</script>
<template>
	<section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
		<div class="row">
			<div class="col-xs-24 col-print-24">
				<div class="box-shadow">
					<div class="box-content-2">
						<h4>
							<i class="fal fa-fw fa-certificate"></i> {{ T('Apple Push Certificate') }}
						</h4>
						<p>
							{{ T('To manage iOS devices, you need to use an Apple Push Certificate. The certificate establishes a trusted connection between iOS devices and Securepoint Mobile Security. You need to renew the certificate yearly.') }}
						</p>
						<template v-if="accountInitialized">
							<div class="row padding-xs-y border-bottom">
								<div class="col-lg-8">
									<label for="tenant-info-applepushcert" class="control-label">
										<template v-if="settings.ios.apns.notAfter == '' || settings.ios.apns.expired || !settings.ios.apns.matchesPrivateKey">
											{{ T('Apple Push Certificate') }}
										</template>
										<template v-else>
											{{ T('Apple Push Certificate expiry') }}
										</template>

									</label>
								</div>
								<div class="col-lg-8">
									<input
										type="text"
										class="form-control"
										id="tenant-info-applepushcert"
										:value="(settings.ios.apns.notAfter == '')
											? T('Not set')
											: (settings.ios.apns.expired)
												? T('Expired')
												: (!settings.ios.apns.matchesPrivateKey)
													? T('Wrong certificate')
													: getDateWithTime(settings.ios.apns.notAfter)
											"
										disabled
									>
								</div>
								<div class="col-lg-8">
									<a class="btn btn-primary col-xs-24" v-on:click="getApplePushCertDialog((settings.ios.apns.notAfter != '1970-01-01T00:00:00.000Z' && settings.ios.apns.notAfter != '') ? true : false)" :title="(settings.ios.apns.notAfter == '1970-01-01T00:00:00.000Z' || settings.ios.apns.notAfter == '') ? T('Add certificate') : T('Update certificate')">
										<template v-if="settings.ios.apns.notAfter == ''">
											<i class="fal fa-fw fa-plus"></i>&nbsp;&nbsp{{ T('Add') }}
										</template>
										<template v-else>
											<i class="fal fa-fw fa-sync"></i>&nbsp;&nbsp{{ T('Update') }}
										</template>
									</a>
									<template v-if="(settings.ios.apns.notAfter != '1970-01-01T00:00:00.000Z' && settings.ios.apns.notAfter != '') && !settings.ios.apns.expired">
										<a class="btn btn-primary col-xs-24" v-on:click="downloadCert()" :title="T('Download')">
											<i class="fal fa-fw fa-download"></i>&nbsp;&nbsp;{{ T("Download") }}
										</a>
									</template>
								</div>
							</div>

						</template>
						<template v-else>
							<loader class="color-primary text-size-2 margin-xs-b-2"/>
							<hr>
						</template>
						<template v-if="isFunctionalityEnabled('dep')">
							<h4>
								<i class="fab fa-fw fa-apple"></i> {{ T('Apple DEP') }}
							</h4>
							<p>
								{{ T('Device Enrollment Program lets you automate the enrollment and simplify initial device setup. You can supervise devices during activation without touching them.') }}
							</p>
							<template v-if="accountInitialized">
								<div class="row padding-xs-y border-bottom">
									<div class="col-lg-8">
										<label for="tenant-info-appleDep" class="control-label" v-if="hasFunctionality('dep')">
											{{T('Apple DEP-Token expiry')}}
										</label>
										<label for="tenant-info-appleDep" class="control-label" v-else>
											{{T('Apple DEP')}}
										</label>
									</div>
									<div class="col-lg-8">
										<input id="tenant-info-appleDep" type="text" class="form-control" :value="settings.ios.dep.enabled ? getDateWithTime(settings.ios.dep.expiry) : T('Not set')" disabled>
									</div>
									<div class="col-lg-8">
										<template v-if="hasFunctionality('dep')">
											<a class="btn btn-primary col-xs-24" v-on:click="getDEPDialog('update')">
												<i class="fal fa-fw fa-sync"></i>&nbsp;&nbsp;{{T('Update')}}
											</a>

											<a class="btn btn-primary col-xs-24" v-on:click="deleteDEPTokenDialog()">
												<i class="fal fa-fw fa-trash"></i>&nbsp;&nbsp;{{T('Delete')}}
											</a>
										</template>
										<template v-if="!hasFunctionality('dep')">
											<a 
												class="btn btn-primary col-xs-24" 
												v-on:click="getDEPDialog('add')"
												:disabled="settings.ios.apns.notAfter == '' || settings.ios.apns.expired || !settings.ios.apns.matchesPrivateKey || null"
											>
												<template v-if="settings.ios.apns.notAfter == '' || settings.ios.apns.expired || !settings.ios.apns.matchesPrivateKey">
													{{ T('Missing Apple Push Certificate') }}
												</template>
												<template v-else>
													<i class="fal fa-fw fa-plus"></i>&nbsp;&nbsp;{{T('Add')}}
												</template>
											</a>
										</template>
									</div>
								</div>
								<div class="margin-xs-y">
									<p >{{ T('Login to the MDM can be protected with a PIN. This must then be entered by the user of the end device during the initial setup. In the event of a lost or stolen device, the PIN provides an additional level of security, as the device cannot be put back into operation by a third party.') }}</p>
									<p v-html="T('<strong>Attention</strong>: The PIN is only used for newly created DEP profiles from version 1.23.0.')"></p>
								</div>
								<div class="row padding-xs-y border-bottom"> 
									<div class="col-lg-8">
										<label for="tenant-info-depProfilePin" class="control-label" v-if="hasFunctionality('dep')">
											{{ T('Define DEP Profile-PIN') }}
										</label>
									</div>
									<div class="col-lg-2">
										<label class="form-field margin-xs-b-0 toggle checkbox">
											<input type="checkbox" class="toggle" v-model="useDepPin"> <span></span>
										</label>
									</div>
									<div class="col-lg-6">
										<inputPin
											:focus-on-mount="false"
											v-model="depPin"
											:numbers-only="true"
											:pin-length="6"
											:isDisabled="!useDepPin"
											v-on:submit="saveDepPin"
											:regular-size="true"
											:hidePinOnMount="true"
											:canToggleVisibility="true"
										></inputPin>

									</div>
									<div class="col-lg-8">
										<btn
											class="col-xs-24"
											:button-options="{
												'loading': savingDepPin,
												'icon':'fal fa-fw fa-save',
												'text':T('Save'),
												'title':T('Save'),
												'disabled': !canSaveDepPin,
												'onClick':saveDepPin,
											}"
										></btn>
									</div>
								</div>

							<div class="margin-xs-y">
								<p>{{ T('By activating the re-enrolment function in Mobile Device Management (MDM), newly added DEP devices are automatically registered in the system and can be individually pre-configured before they are used for the first time.') }}</p>
								<p>{{ T('The settings for user profiles, applications and tags defined in this way are applied seamlessly the first time the device is registered.') }}</p>
								<p>{{ T('This function also extends to non-DEP devices. When a device is recommissioned, the configurations of the previous device are automatically transferred, provided it is still present in the system.') }}</p>
								<p>{{ T('If you want to prevent the settings for a specific device from being adopted, it is necessary to remove the device in question from the system before it is registered again.') }}</p>
								<p v-html="T('<strong>Attention:</strong> When transferring a device to a new user, it is important to update the user assignment in the system. Otherwise, the new user could gain access to the data and accounts of the previous user.')"></p>
							</div>
							<div class="row padding-xs-y border-bottom">
								<div class="col-lg-8">
									<label class="control-label">
										{{T('Activate Apple Re-Enrollment')}}
									</label>
								</div>
								<div class="col-lg-8">
									<label class="form-field margin-xs-b-0 toggle checkbox">
										<input type="checkbox" class="toggle" v-model="appleReEnrollment"> <span></span>
									</label>
								</div>
							</div>
							</template>
							<template v-else>
								<loader class="color-primary text-size-2 margin-xs-b-2"/>
								<hr>
							</template>
						</template>

						<template v-if="isFunctionalityEnabled('vpp')">
							<h4>
								<i class="fab fa-fw fa-apple"></i> Apple VPP / Apple Business Manager / Apple School Manager
							</h4>
							<p>
								{{ T('The Volume Purchase Program (VPP) makes it easy for businesses to purchase, distribute, and manage their apps and books in bulk.') }}
							</p>
							<template v-if="accountInitialized">
								<div class="row padding-xs-y border-bottom">
									<div class="col-lg-8">
										<label for="tenant-info-appleVPP" class="control-label" v-if="hasFunctionality('vpp')">
											{{T('Apple VPP-Token expiry')}}
										</label>
										<label for="tenant-info-appleVPP" class="control-label" v-else>
											{{T('Apple VPP')}}
										</label>
									</div>
									<div class="col-lg-8">
										<input id="tenant-info-appleVPP" type="text" class="form-control" :value="settings.ios.vpp.enabled ? getDateWithTime(settings.ios.vpp.expiry) : T('Not set')" disabled>
									</div>
									<div class="col-lg-8">
										<template v-if="hasFunctionality('vpp')">
											<a class="btn btn-primary col-xs-24" v-on:click="getVPPDialog('update')">
												<i class="fal fa-fw fa-sync"></i>&nbsp;&nbsp;{{T('Update')}}
											</a>
											<a class="btn btn-primary col-xs-24" v-on:click="deleteVPPTokenDialog()">
												<i class="fal fa-fw fa-trash"></i>&nbsp;&nbsp;{{T('Delete')}}
											</a>
										</template>
										<template v-if="!hasFunctionality('vpp')">
											<a
												class="btn btn-primary col-xs-24"
												v-on:click="getVPPDialog('add')"
												:disabled="settings.ios.apns.notAfter == '' || settings.ios.apns.expired || !settings.ios.apns.matchesPrivateKey || null"
											>
												<template v-if="settings.ios.apns.notAfter == '' || settings.ios.apns.expired || !settings.ios.apns.matchesPrivateKey">
													{{ T('Missing Apple Push Certificate') }}
												</template>
												<template v-else>
													<i class="fal fa-fw fa-plus"></i>&nbsp;&nbsp;{{T('Add')}}
												</template>
											</a>
										</template>
									</div>
								</div>
								<div class="row padding-xs-y border-bottom" v-if="hasFunctionality('vpp')">
									<div class="col-lg-8">
										<label for="tenant-info-appUpdates" class="control-label">
											{{T('Force automatic app updates')}}
										</label>
									</div>
									<div class="col-lg-8">
										<label class="form-field margin-xs-b-0 toggle checkbox">
											<input type="checkbox" class="toggle" v-model="triggerVPPAppUpdates"> <span></span>
										</label>
									</div>
									<div class="col-lg-8">
			
									</div>
								</div>

							</template>
							<template v-else>
								<loader class="color-primary text-size-2 margin-xs-b-2"/>
								<hr>
							</template>
						</template>

						<template v-if="isFunctionalityEnabled('enterprise')">
							<h4>
								<i class="fab fa-fw fa-android"></i> {{ T('Android Enterprise') }}
							</h4>
							<p>
								{{ T('Android 7.0 Nougat and later devices come with built-in enterprise features to power mobile, connected workforces. Securepoint Mobile Security enables organizations to manage these features.') }}
							</p>
							<template v-if="accountInitialized">
								<div class="row padding-xs-y">
									<template v-if="settings.android.enterprise.contactInfo != undefined">
										<div class="col-xs-24 col-lg-12 padding-xs-t padding-xs-b padding-lg-b-2">
											<h5>{{ T('Data Protection Officer') }}</h5>
											<strong>{{ T('Name') }}:</strong> <span>{{ settings.android.enterprise.contactInfo.dataProtectionOfficerName || '' }}</span><br>
											<strong>{{ T('Email') }}:</strong> <span>{{ settings.android.enterprise.contactInfo.dataProtectionOfficerEmail || '' }}</span><br>
											<strong>{{ T('Phone') }}:</strong> <span>{{ settings.android.enterprise.contactInfo.dataProtectionOfficerPhone || '' }}</span>
										</div>
										<div class="col-xs-24 col-lg-12 padding-xs-t padding-xs-b-2">
											<h5>{{ T('EU Representative') }}</h5>
											<strong>{{ T('Name') }}:</strong> <span>{{ settings.android.enterprise.contactInfo.euRepresentativeName || '' }}</span><br>
											<strong>{{ T('Email') }}:</strong> <span>{{ settings.android.enterprise.contactInfo.euRepresentativeEmail || '' }}</span><br>
											<strong>{{ T('Phone') }}:</strong> <span>{{ settings.android.enterprise.contactInfo.euRepresentativePhone || '' }}</span>
										</div>
									</template>
									<div class="col-lg-8">
										<label for="tenant-info-androidEnterprise" class="control-label">
											{{T('Android Enterprise')}}
										</label>
									</div>
									<div class="col-lg-8">
										<input
											id="tenant-info-androidEnterpriseName"
											type="text" class="form-control"
											:value="hasFunctionality('enterprise') ? (T('Linked') + ': ' + settings.android.enterprise.name) : settings.android.enterprise.disabledForThisSession ? T('Error') : T('Not set')"
											disabled
										/>
									</div>
									<div class="col-lg-8">
										<template v-if="!hasFunctionality('enterprise') && !settings.android.enterprise.disabledForThisSession">
											<a class="btn btn-primary col-xs-24" v-on:click="getEnterpriseDialog()">
												<i class="fal fa-fw fa-plus"></i>&nbsp;&nbsp;{{T('Add')+"/"+T('Link')}}
											</a>
										</template>
										<template v-else>
											<a class="btn btn-primary col-xs-24" v-on:click="getEnterpriseDialog('unlink')">
												<i class="fal fa-fw fa-unlink"></i>&nbsp;&nbsp;{{T('Unlink')}}
											</a>
										</template>
									</div>
									<!-- #36909 -->
									<template v-if="hasFunctionality('enterprise')">
										<div class="col-lg-8">
											<label class="control-label">
												{{T('Android Enterprise E-Mail Address')}}
											</label>
										</div>
										<div class="col-lg-8">
											<input
												type="text" 
												class="form-control"
												:value="settings?.enterpriseEmail || 'N/A'"
												disabled
											/>
										</div>
										<div class="col-lg-8">
											<a class="btn btn-primary col-xs-24" v-on:click="getEnterpriseDialog('either', true)">
												<i class="fal fa-fw fa-plus"></i>&nbsp;&nbsp;{{T('Set E-Mail Address')}}
											</a>
										</div>
									</template>
								</div>
							</template>
							<template v-else>
								<loader class="color-primary text-size-2 margin-xs-b-2"/>
							</template>
							<hr>
						</template>

						<template v-if="isFunctionalityEnabled('zeroTouch')">
							<h4>
								<i class="fab fa-fw fa-android"></i> {{ T('Android Zero Touch') }}
							</h4>
							<template v-if="accountInitialized">
								<div class="row padding-xs-y" >
									<div class="col-lg-8">
										<label for="tenant-info-androidZeroTouch" class="control-label">
											{{T('Android Zero Touch')}}
										</label>
									</div>
									<div class="col-lg-8">
										<input
											id="tenant-info-androidZeroTouchName"
											type="text" class="form-control"
											:value="settings.android.zeroTouch.enabled ? T('Linked') : T('Not set')"
											disabled
										/>
									</div>
									<div class="col-lg-8">
										<a class="btn btn-primary col-xs-24" :href="'/sms/lp/zerotouch?accountId=' + activeAccountId">
											<i class="fal fa-fw fa-plus"></i>&nbsp;&nbsp;{{T('Add') + "/" + T('Link')}}
										</a>
									</div>
								</div>
								<template v-if="getObjectLength(zeroTouchCustomers || {}) > 0">
									<div class="row padding-xs-y">
										<div class="col-lg-8">
											<label for="tenant-info-androidZeroTouch" class="control-label">
												{{T('Zero Touch Accounts')}}
											</label>
										</div>
										<div class="col-lg-16">
											<template v-for="companies, customerId in zeroTouchCustomers">
												<div class="box-shadow box">
													<div class="row padding-xs">
														<div class="col-lg-12">
															<strong class="padding-xs-b"> {{ customerId }}</strong><br>
														</div>
														<div class="col-lg-12">
															<a class="btn btn-primary col-xs-24" v-on:click="deleteZeroTouchCustomer(String(customerId))">
																<i class="fal fa-fw fa-unlink"></i>&nbsp;&nbsp;{{T('Unlink Account')}}
															</a>
														</div>
													</div>
												</div>
											</template>
										</div>
									</div>
								</template>
							</template>
							<template v-else>
								<loader class="color-primary text-size-2 margin-xs-b-2"/>
								<hr>
							</template>
						</template>

						<template v-if="hasAccountRole('__SUPPORT__')">
							<hr>
							<h4>
								<i class="fal fa-fw fa-ban"></i> {{ T('Restrictions') }}
							</h4>
							<template v-if="accountRestrictions != undefined">
								<div class="row padding-xs-t">
									<div class="col-lg-8">
										<label for="settings-accountrestrictions" class="control-label">
											{{ T('Restrictions') }}
										</label>
									</div>
									<div class="col-lg-16">
										<select v-model="accountRestrictions">
											<template v-for="restriction in accountRestrictionOptions">
												<option :value="restriction.id">{{ T(restriction.text) }}</option>
											</template>
										</select>
									</div>
								</div>
							</template>
							<template v-if="legalHold != undefined">
								<div class="row padding-xs-t">
									<div class="col-lg-8">
										<label for="settings-legalhold" class="control-label">
											{{ T('Legal Hold') }}
										</label>
									</div>
									<div class="col-lg-16">
										<select id="tenant-info-account-restrictions" class="form-control" v-model="legalHold">
											<option value="0">{{T('No hold')}}</option>
											<option value="1">{{T('Hold')}}</option>
										</select>
									</div>
								</div>
							</template>
							<template v-else>
								<loader class="color-primary text-size-2 margin-xs-b-2"/>
							</template>
						</template>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>