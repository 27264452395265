import { useGlobalMixin } from "@/mixins/mixins.global";

const appleUpdateDialogComponent = {
    mixins: [useGlobalMixin()],
    "name": "apple-update-device-dialog",
    "template": `<div>      
        <p><strong>{{ T('Select a update type:') }}</strong></p>
        <select v-model="value">
            <option value="Default">{{ T('Default') + ': ' + T('Download or install the update, depending on the current state.') }}</option>
            <option value="DownloadOnly">{{ T('Download only') + ': ' + T('Download the software update without installing it.') }}</option>
            <option value="InstallASAP">{{ T('Install ASAP') + ': ' + T('In iOS and tvOS, install a previously downloaded software update.') }}</option>
            <!-- option value="NotifyOnly">{{ T('Notify only') }}</option -->
            <!-- option value="InstallLater">{{ T('Install later') }}</option -->
            <!-- option value="InstallForceRestart">{{ T('Install & force restart') }}</option -->
        </select>
        
        <p class="notification bg-red color-white">
            <i class='fal fa-exclamation-triangle margin-xs-r'></i> {{ T('The device uses mobile data when it is not in the WIFI.') }}
        </p>

    </div>`,
    "data": () => {
        return {
            "value": "Default"
        }
    },
    "props": {
        "properties": {
            required: true,
            default: () => {
                return {}
            }
        }
    }
}
export default appleUpdateDialogComponent