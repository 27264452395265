<script setup lang="ts">
	import apis from "../../classes/apis"
	import { MutationTypes } from "@/store/vuex.store"
	import { T } from "@/classes/i18n";
	import loader from "../components/loader.vue";
	import objectStores from "@/classes/init";
	import type { License } from "@/classes/unifiedSecurity/licenses";
	import router from "@/router/router";
	import moment from "moment"
	import { useStore } from '@/store/vuex.store'
	import tenantHelpers from "@/helpers/helpers.tenants";
	import jsonHelpers from "@/helpers/helpers.json";
	import imageHelpers from "@/helpers/helpers.images";
	import accordion from "../components/accordion.vue";
	import mixinHelpers from "@/helpers/helpers.mixins";
	import products from "@/classes/objectTypes";
	import config from "@/classes/config";
	import { computed, onMounted, ref, watch } from "vue";
	import deviceHelpers from "@/helpers/helpers.devices";

	interface EnrollmentToken {
		"additionalData": string,
		"allowPersonalUsage": string,
		"duration": string,
		"expirationTimestamp": string,
		"name": string,
		"oneTimeOnly": true,
		"policyName": string,
		"qrCode": string,
		"expired"?:boolean,
		"signinDetail": {
			"signinUrl": string,
			"signinEnrollmentToken": string,
			"qrCode": string,
			"allowPersonalUsage": string,
			"code"?: string
		},
		"value": string
	}

	const activeAccountId = computed(() => {
		return useStore().state.session.activeAccountId
	})
	const deviceLimitReached = computed(() => {
		return !useStore()?.state.session.accounts[activeAccountId.value || ""].mobileSecurity.settings.enrollmentAllowed
	})
	const enrollmentDisabled = computed(() => {
		return useStore()?.state.session.accounts[activeAccountId.value || ""].mobileSecurity.settings.accountRestrictions > 0
	})
	const mdmLicenseExceeded = computed(() => {
		return useStore()?.state.session.accounts[activeAccountId.value || ""].mobileSecurity.settings.mdmLicenseExceeded
	})
	const canUseAndroidEnterprise = computed(() => {
		return tenantHelpers.hasFunctionality(activeAccountId.value || "", 'enterprise')
	})

	const initialized = ref(false)

	const loading = ref(true)
	const loaderText = ref("Loading...")

	const enterpriseDisabledReason = ref("Android enterprise is not set up")

	const license = ref({
		"licenseOptions": <selectOption[]>[],
		"selected": "",
		"disabled": false
	})

	const enrollmentToken = ref({
		"selected":<"none"|number>"none",
		"ownership":"DEVICE_OWNER",
		"tokens":<EnrollmentToken[]>[] 
	})

	const newTokenForm = ref({
		"policy": {
			"options": <selectOption[]>[],
			"value": "",
		},
		"duration": {
			"options": <selectOption[]>[
				{
					"id": "1800s",
					"text": "30 minutes"
				},
				{
					"id": "3600s",
					"text": "One hour"
				},
				{
					"id": "86400s",
					"text": "One day"
				},
				{
					"id": "604800s",
					"text": "One week"
				},
				{
					"id": "1296000s",
					"text": "15 days"
				},
				{
					"id": "2592000s",
					"text": "30 days"
				},
				{
					"id": "5184000s",
					"text": "60 days"
				},
				{
					"id": "7776000s",
					"text": "90 days"
				},
				{
					"id": "31536000s",
					"text": "1 year"
				},
				{
					"id": "315360000000s",
					"text": "Infinite"
				}
			],
			"value": "3600s",
		},
		"useCode": {
			"value": false
		},
		"additionalData": {
			"value": "",
		},
		"oneTimeOnly": {
			"value": false
		},
		"allowPersonalUsage": {
			"value": "ALLOW_PERSONAL_USAGE_UNSPECIFIED"
		}
	})



	const getShortDeviceId = deviceHelpers.getShortDeviceId
	const getDate = (date: string) => {
		return moment(date).format('DD.MM.YYYY HH:mm')
	}
	const isExpired = (expirationDate: string) => {
		return !moment(expirationDate).isSameOrAfter(moment())
	}

	const getLicenseInfoStringFromAdditionalData = (additionalData: string) => {
		let licenseUUID: string = jsonHelpers.getObjectProperty(JSON.parse(additionalData), "licenseUUID") || ""
		let licenseInfo = T("Unknown")
		if (licenseUUID != "") {
			let licenses = (config.canUseNewObjectType("licenses") ? products.unifiedSecurity.licenses.useStore?.().getObjectStoreObjects(activeAccountId.value || '') : objectStores.licenses.getObjectsFromStore(activeAccountId.value || '')) || []
			let foundLicense = licenses.find(function (license) {
				return license.uuid == licenseUUID
			})
			if (foundLicense) {
				licenseInfo = foundLicense.name + " " + foundLicense.type
			}
		}
		return licenseInfo
	}

	const getQrUrl = () => {
		return enrollmentToken.value.selected != 'none' ? '/sms-mgt-api/api/' + apis.getApiVersionForFeature('androidEnterprise') + '/tenants/' + tenantHelpers.getTenantDomain(activeAccountId.value || '') + '/android/emm/enterprise/enrollmentTokens/' + enrollmentToken.value.tokens[enrollmentToken.value.selected].name.split('/')[3] + '?qrcode=' + enrollmentToken.value.ownership : ""
	}

	const printImage = () => {
		const source = getQrUrl()
		const selectedTokenIndex = enrollmentToken.value.selected
		if(selectedTokenIndex != "none") {
			const selectedToken = enrollmentToken.value.tokens[selectedTokenIndex]

			let headerContent = document.getElementById('print-header')?.outerHTML + "<h1 style='margin-top:64px'>Android Enrollment</h1>"
			let footerContent = "<table>"

			footerContent += "<tr><th>" + T("Profile") + ":</th><td>" + selectedToken.policyName.split('/')[3] + "</td></tr>"
			footerContent += "<tr><th>" + T("Expires on:") + "</th><td>" + getDate(selectedToken.expirationTimestamp) + "</td></tr>"
			if (selectedToken.signinDetail?.code != undefined) {
				footerContent += "<tr><th>" + T("Code:") + "</th><td>" + selectedToken.signinDetail.code + "</td></tr>"
			}

			footerContent += "</table>"
			footerContent += document.getElementsByClassName("main-footer")[0]?.outerHTML
			imageHelpers.printImage(headerContent, footerContent, document.location.origin + source)
		}
	}

	const deleteToken = async(tokenIndex: number) => {
		loaderText.value = T("Deleting token...")
		loading.value = true
		let enrollmentTokenId = enrollmentToken.value.tokens[tokenIndex].name.split('/')[3]
		try {
			await products.mobileSecurity.androidDevices.deleteEnterpriseEnrollmentToken(activeAccountId.value || '', enrollmentTokenId)
			enrollmentToken.value.selected = "none"
			enrollmentToken.value.tokens.splice(tokenIndex, 1)
		}
		catch (e: any) {
			console.error(e)
		}
		loading.value = false
		loaderText.value = T("Loading...")
	}



	const createEnterpriseToken = async() => {
		let enrollmentTokenObj: any = {}
		loaderText.value = T("Creating enrollment token...")
		loading.value = true
		for (let fieldProperty in newTokenForm.value) {
			let fieldValue = newTokenForm.value[fieldProperty as keyof typeof newTokenForm.value].value
			if (fieldValue != "" && fieldProperty != 'policy') {
				enrollmentTokenObj[fieldProperty] = newTokenForm.value[fieldProperty as keyof typeof newTokenForm.value].value
			}
		}
		let policyId = newTokenForm.value.policy.value
		let licenseUUID: string = license.value.selected

		enrollmentTokenObj.additionalData = JSON.stringify({
			"userInput": enrollmentTokenObj.additionalData || "",
			"licenseUUID": licenseUUID
		})

		if (newTokenForm.value.allowPersonalUsage.value != 'ALLOW_PERSONAL_USAGE_UNSPECIFIED') {
			enrollmentTokenObj.allowPersonalUsage = newTokenForm.value.allowPersonalUsage.value
		}

		if (newTokenForm.value.useCode.value) {
			enrollmentTokenObj.useCode = true
		}

		try {
			let newToken: any = await products.mobileSecurity.androidDevices.createEnterpriseEnrollmentToken(activeAccountId.value || '', policyId, enrollmentTokenObj)
			enrollmentToken.value.tokens.push(newToken)
			enrollmentToken.value.selected = (enrollmentToken.value.tokens.length - 1)
		}
		catch (e: any) {
			console.error(e)
			loading.value = false
		}
	}

	const goToSettings = () => {
		useStore().commit(MutationTypes.removeModal, { accountId: activeAccountId.value || '' })
		router.navigate('show-tenant-' + tenantHelpers.getTenantDomain(activeAccountId.value || '') + '-mobile-security-settings')
	}

	onMounted(async () => {
		initialized.value = false
		loaderText.value = "Getting tenant info..."

		if (canUseAndroidEnterprise.value) {
			enterpriseDisabledReason.value = ""
			loaderText.value = "Getting enterprise policies..."
			if (!activeAccountId.value) {
				throw "Missing accountId"
			}

			let enterprisePolicies = await products.mobileSecurity.androidProfiles.queries.getObjectsFromApi(activeAccountId.value)
			if(enterprisePolicies instanceof Error) throw "Error getting Policies"

			enterprisePolicies.map(function (emmProfile: any, emmProfileIndex: number) {
				if (emmProfile.name.split("/")[3] != 'unlicensed') {
					newTokenForm.value.policy.options.push(
						{
							"id": emmProfile.name.split("/")[3],
							"text": emmProfile.name.split("/")[3]
						}
					)
					if (emmProfileIndex == 0) {
						newTokenForm.value.policy.value = emmProfile.name.split("/")[3]
					}
				}
			})

			loaderText.value = "Getting enrollment tokens..."
			let enrollmentTokens: any = await products.mobileSecurity.androidDevices.getEnterpriseEnrollmentTokens(activeAccountId.value || '')
			for (let enrollmentTokenIndex in enrollmentTokens.enrollmentTokens) {
				const thisToken = enrollmentTokens.enrollmentTokens[enrollmentTokenIndex]
				thisToken.expired = false
				if (isExpired(thisToken.expirationTimestamp)) {
					thisToken.expired = true
				}
				enrollmentToken.value.tokens.push(thisToken)
			}
		}
		else {
			enterpriseDisabledReason.value = '<i class="fal fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;Android: ' + T('Android Enterprise is not set up')
		}

		// LICENSE SELECTION
		loaderText.value = "Getting licenses..."
		let licenses: License[] = [],
			licenseOptions: any[] = []



		licenses = products.unifiedSecurity.licenses.useStore?.().getObjectStoreObjects(activeAccountId.value || '') || []

		for (let i: number = 0; licenses.length > i; i++) {
			let license = licenses[i]
			let licenceUser = mixinHelpers.getLicenseLimit(license)
			if ((license.type == 'Mobile Security' || license.type == 'MDM') && license.uuid && license.assignedUser < licenceUser && !license.expired && !license.dead) {
				licenseOptions.push({
					"id": license.uuid,
					"text": license.name + " | " + license.type + " [" + license.assignedUser + "/" + licenceUser + "] (" + license.uuid.substring(0, 4) + ")",
					"disabled": license.assignedUser >= licenceUser || license.expired || license.dead,
					"licenseType": license.type
				})
			}
		}
		license.value.licenseOptions = licenseOptions

		if (licenseOptions.length) {
			license.value.selected = licenseOptions[0].id
		}
		loaderText.value = "Finished loading..."
		initialized.value = true
		loading.value = false
	})


	watch(enrollmentToken,(newVal: any) => {
		if (newVal.selected != "none") {
			loading.value = false
		}
	},{deep:true})

</script>
<template>
	<div>
		<template v-if="initialized">
			<template v-if="deviceLimitReached">
				<div class="padding-xs-2">
					<p>
						{{ T('You have reached the devices limit. If you want to add more Devices, you have to upgrade your Mobile Security License.') }}
					</p>
				</div>
			</template>
			<template v-else-if="enrollmentDisabled">
				<div class="padding-xs-2">
					<p>
						{{ T('The enrollment has been disabled.') }}
					</p>
				</div>
			</template>
			<template v-else-if="mdmLicenseExceeded">
				<div class="padding-xs-2">
					<p>
						{{ T('Your Mobile Security has exceeded. Please upgrade your Mobile Security License.') }}
					</p>
				</div>
			</template>
			<template v-else>
				<div class="row" v-if="!canUseAndroidEnterprise">
					<div class="col-xs-24">
						<div class="row flexrow">
							<div class="col-xs padding-xs-x">
								<h5>{{T('Please add an Android Enterprise Account')}}</h5>
								<p>
									{{T('In order to use Securepoint Mobile Security with Android Enterprise you need to add an Android Enterprise Account first.')}}
								</p>
								<a
									class="btn btn-primary"
									v-on:click="goToSettings()"
								>
									<i class="fal fa-angle-right"></i> {{T('Settings')}}
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-else>
					<div class="col-xs-24">
						<div class="row flexrow">
							<div class="col-xs padding-xs-x padding-xs-b">
								<p class="notification">
									{{ T('All devices from Android 7.0 with access to the Google Play API are supported') }}.
								</p>
								<template v-if="enrollmentToken.tokens.length > 0">
									<label class="control-label padding-xs-t-2" for="useEnrollmentToken" style="padding-top:0">
										{{ T('Do you want to use an existing enrollment token?') }}
									</label>
									<select id="useEnrollmentToken" v-model="enrollmentToken.selected" :disabled="loading || undefined">
										<option value="none">{{ T('Create a new enrollment token') }}</option>
										<template v-for="thisToken, index in enrollmentToken.tokens">
											<template v-if="!thisToken.expired">
												<option :value="index">
													{{ T('Profile') }}: {{ thisToken.policyName.split('/')[3] }} &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Token: {{ getShortDeviceId(thisToken.name.split('/')[3]) }} {{ (thisToken.signinDetail ? ('&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Code: ' + thisToken.signinDetail.code) : '') }}
												</option>
											</template>
											<template v-if="thisToken.expired">
												<option :value="index">
													{{ T('Profile') }}: {{ thisToken.policyName.split('/')[3] }} &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Token: {{ getShortDeviceId(thisToken.name.split('/')[3]) }} {{ (thisToken.signinDetail ? ('&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Code: ' + thisToken.signinDetail.code) : '') }} &nbsp;&nbsp;&nbsp;&nbsp;<small>({{ T('Expired') }})</small>
												</option>
											</template>
										</template>
									</select>
								</template>
							</div>
						</div>
						<template v-if="!loading">
							<template v-if="enrollmentToken.selected != 'none'">
								<div class="row">
									<div class="col-xs-24 col-lg-12">
										<label class="control-label">
											{{ T('Profile:') }}
										</label>
										<input type="text" readonly disabled :value="enrollmentToken.tokens[enrollmentToken.selected].policyName.split('/')[3]">

										<label class="control-label">
											{{ T('License:') }}
										</label>
										<input type="text" readonly disabled :value="getLicenseInfoStringFromAdditionalData(enrollmentToken.tokens[enrollmentToken.selected].additionalData)">

										<label class="control-label">
											{{ T('Expires on:') }}
										</label>
										<input type="text" readonly disabled :value="getDate(enrollmentToken.tokens[enrollmentToken.selected].expirationTimestamp)">
										<label class="control-label">
											{{ T('Value:') }}
										</label>
										<template v-if="!enrollmentToken.tokens[enrollmentToken.selected].signinDetail">
											<input
												type="text"
												readonly
												disabled
												v-model="enrollmentToken.tokens[enrollmentToken.selected].value"
											>
										</template>
										<template v-else>
											<input
												type="text"
												readonly
												disabled
												v-model="enrollmentToken.tokens[enrollmentToken.selected].signinDetail.signinEnrollmentToken"
											>
										</template>

										<br>
										<a class="btn btn-primary" v-on:click="deleteToken(enrollmentToken.selected)">
											<i class="fal fa-trash-alt"></i> {{ T('Delete Token') }}
										</a>
									</div>
									<div class="col-xs-24 col-lg-12 padding-xs-t">
										<img
											:src="getQrUrl()"
											style="margin:0 auto"
											:class="{ 'padding-xs-t-4': enrollmentToken.ownership == 'PROFILE_OWNER' }"
										>
										<div class="text-center">
											<a
												class="btn"
												@click="printImage()"
											>
												<i class="fal fa-print"></i> {{T('Print QR-Code')}}
											</a>
										</div>
									</div>
								</div>
							</template>
							<template v-if="enrollmentToken.selected == 'none'">
								<label class="control-label">
									{{ T('Create a new enrollment token') }}
								</label>
								<div class="row padding-xs-y form-group border-bottom">
									<div class="first col-xs-24 col-lg-6 col-xl-5">
										<label class="control-label inputname" for="tokenPolicy">
											{{ T('Profile') }}
										</label>
									</div>
									<div class="input col-xs-24 col-lg-8">
											<select id="tokenPolicy" v-model="newTokenForm.policy.value">
												<option value="" disabled>
													{{ T('Please select a profile') }}
												</option>
												<template v-for="policyOption in newTokenForm.policy.options">
													<option :value="policyOption.id">
														{{ policyOption.text }}
													</option>
												</template>
											</select>
									</div>
									<div class="desc col-xs-24 col-lg-10">
										<p class="input-description">
											{{ T('This Profile will be applied to the enrolled device') }}.
										</p>
									</div>
								</div>

								<div class="row padding-xs-y form-group border-bottom" v-if="license.licenseOptions.length">
									<div class="first col-xs-24 col-lg-6 col-xl-5">
										<label class="control-label inputname" for="licenseUUID">
											{{ T('License') }}
										</label>
									</div>
									<div class="input col-xs-24 col-lg-8">
										<select
											id="licenseUUID" :disabled="license.disabled || undefined"
											v-model="license.selected"
											style="width:100%"
										>
											<option value="" disabled>
												{{ T('Please select a license') }}
											</option>
											<template v-for="thisLicense in license.licenseOptions">
												<option
													:value="thisLicense.id"
													:disabled="thisLicense.disabled || undefined"
												>
													{{ thisLicense.text }}
												</option>
											</template>
										</select>
									</div>
									<div class="desc col-xs-24 col-lg-10">
										<p class="input-description">
											{{ T('The license that will be used for new enrolled devices.') }}
										</p>
									</div>
								</div>

								<div class="row padding-xs-y form-group">
									<div class="first col-xs-24 col-lg-6 col-xl-5">
										<label class="control-label inputname" for="useCode">{{ T('Use code') }}</label>
									</div>
									<div class="input col-xs-24 col-lg-8">
										<label class="checkbox toggle">
											<input
												id="useCode"
												type="checkbox"
												class="toggle"
												v-model="newTokenForm.useCode.value"
											/>
											<span></span>
										</label>
									</div>
									<div class="desc col-xs-24 col-lg-10">
										<p class="input-description">
											{{ T('Whether the enrollment requires a code or not. Please note that due to security reasons only enrollment tokens with a code can be selected in the ZeroTouch enrollment.') }}
										</p>
									</div>
								</div>

								<accordion class="content-2">
									<template v-slot:title>
										{{ T('More options') }}
									</template>
									<template v-slot:content>
										<div class="row padding-xs-y form-group border-bottom">
											<div class="first col-xs-24 col-lg-6 col-xl-5">
												<label class="control-label inputname" for="tokenDuration">{{ T('Duration') }}</label>
											</div>
											<div class="input col-xs-24 col-lg-8">
												<label>
													<select
														id="tokenDuration"
														v-model="newTokenForm.duration.value"
													>
														<template v-for="durationOption in newTokenForm.duration.options">
															<option :value="durationOption.id">
																{{ T(durationOption.text) }}
															</option>
														</template>
													</select>
												</label>
											</div>
											<div class="desc col-xs-24 col-lg-10">
												<p class="input-description">
													{{ T('Determines how long this token can be used') }}.
												</p>
											</div>
										</div>
										<div class="row padding-xs-y form-group border-bottom">
											<div class="first col-xs-24 col-lg-6 col-xl-5">
												<label
													class="control-label inputname"
													for="tokenAdditionals"
												>
													{{ T('Additional data') }}
												</label>
											</div>
											<div class="input col-xs-24 col-lg-8">
												<label>
													<textarea id="tokenAdditionals" v-model="newTokenForm.additionalData.value"></textarea>
												</label>
											</div>
											<div class="desc col-xs-24 col-lg-10">
												<p class="input-description">
													{{ T('Arbitrary data associated with the enrollment token.') }}
												</p>
											</div>
										</div>
										<div class="row padding-xs-y form-group">
											<div class="first col-xs-24 col-lg-6 col-xl-5">
												<label
													class="control-label inputname"
													for="tokenOneTimeOnly"
												>
													{{ T('One time only') }}
												</label>
											</div>
											<div class="input col-xs-24 col-lg-8">
												<label class="checkbox toggle">
													<input
														id="tokenOneTimeOnly"
														type="checkbox"
														class="toggle"
														v-model="newTokenForm.oneTimeOnly.value"
													/>
													<span></span>
												</label>
											</div>
											<div class="desc col-xs-24 col-lg-10">
												<p class="input-description">
													{{ T('Whether the enrollment token is for one time use only.') }}
												</p>
											</div>
										</div>


										<div class="row padding-xs-y form-group">
											<div class="first col-xs-24 col-lg-6 col-xl-5">
												<label
													class="control-label inputname"
													for="allowPersonalUsage"
												>
													{{ T('Allow personal usage') }}
												</label>
											</div>
											<div class="input col-xs-24 col-lg-8">
												<label class="select">
													<select
														id="allowPersonalUsage"
														class="toggle"
														v-model="newTokenForm.allowPersonalUsage.value"
													>
														<option
															disabled
															value="ALLOW_PERSONAL_USAGE_UNSPECIFIED"
														>
															{{ T('Not specified') }}
														</option>
														<option
															value="PERSONAL_USAGE_ALLOWED"
														>
															{{ T('Personal usage is allowed') }}
														</option>
														<option
															value="PERSONAL_USAGE_DISALLOWED"
														>
															{{ T('Personal usage is disallowed') }}
														</option>
													</select>
												</label>
											</div>
											<div class="desc col-xs-24 col-lg-10">
												<p class="input-description">
													{{ T('Controls whether personal usage is allowed on a device provisioned with this enrollment token.') }}
													<br/>
													<br/>
													<strong>{{ T('For company-owned devices') }}:</strong>
													<br/>
													{{ T('Enabling personal usage allows the user to set up a work profile on the device. Disabling personal usage requires the user provision the device as a fully managed device.') }}
													<br>
													<strong>{{ T('For personally-owned devices') }}:</strong>
													<br>
													{{ T('Enabling personal usage allows the user to set up a work profile on the device. Disabling personal usage will prevent the device from provisioning. Personal usage cannot be disabled on personally-owned device.') }}

												</p>
											</div>
										</div>
									</template>
								</accordion>
								<div class="text-right padding-xs-t-2">
									<a
										v-on:click="createEnterpriseToken()"
										class="btn btn-primary"
										:disabled="license.selected == '' || null"
									>
										<i class="fal fa-plus"></i>&nbsp; {{ T('Create enrollment token') }}
									</a>
								</div>
							</template>
						</template>
						<template v-else>
							<div>
								<div class="text-size-3 text-center padding-xs-t-4">
									<loader class="color-red"></loader>
								</div>
								<template v-if="loaderText">
									<div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity:0.8">
										<span>
											{{ T(loaderText) }}
										</span>
									</div>
								</template>
								<template v-else>
									<div class="padding-xs-b-4"></div>
								</template>
							</div>
						</template>
					</div>
				</div>
			</template>
		</template>

		<template v-if="!initialized">
			<div>
				<div class="text-size-3 text-center padding-xs-t-4">
					<loader class="color-red"/>
				</div>
				<template v-if="loaderText">
					<div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity:0.8">
						<span>
							{{ T(loaderText) }}
						</span>
					</div>
				</template>
				<template v-else>
					<div class="padding-xs-b-4"></div>
				</template>
			</div>
		</template> 
	</div>


</template>