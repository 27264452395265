import { T } from "@/classes/i18n";
import { useStore } from '@/store/vuex.store';

const deviceStatsHelpers = {
  sumArrayFields: function (sumArray: any, fieldToSum: string) {
    let initValue = 0;
    return sumArray.reduce(function (sumValue: number, currentItem: any) {
      return sumValue + parseInt(currentItem[fieldToSum]);
    }, initValue)
  },
  categoryFromNumber: function (_ip: number | string): string {
    let ip = "" + _ip
    let len = ip.length
    if (len == 12 || len == 16) {
      ip = ip.match(/(.{1,3})\.?/g)!.map((octed) => parseInt(octed, 10)).join(".")
    }
    // Debug domain
    if (ip == "127.0.999.999") {
      return T("No cf message")
    } else if (ip == "127.0.70.1") {
      return T("Server unreached. Global def.")
    } else if (ip == "127.0.70.2") {
      return T("Server unreached. Group def.")
    } else if (ip == "127.0.70.3") {
      return T("No rules for host, user or ip")
    } else if (ip == "127.0.70.4") {
      return T("List did't match")
    } else if (ip == "127.0.70.5") {
      return T("List did't match. Internal def.")
    } else if (ip == "127.0.70.6") {
      return T("Group define")
    } else if (ip == "127.0.70.7") {
      return T("List define")
    }
    return useStore()?.state.resources.ContentFilterCategories[ip] ? useStore()?.state.resources.ContentFilterCategories[ip].category : ""
  },
  numberFromIP: function (_ip: string): string {
    var ip = _ip;
    let octets = ip.split(".");
    // Check if we got 4 octets
    if (octets.length !== 4) {
      // No, no ip
      return _ip;
    }
    // We got 4 octets
    return octets.map((octet) => {
      var val = parseInt(octet, 10);
      //
      if (val < 10) {
        return "00" + val;
      } else if (val < 100) {
        return "0" + val;
      }
      // >= 100
      return val;
    }).join("")
  },
  ipFromNumber: function (_ip: number | string): string {
    let ip = "" + _ip
    let len = ip.length
    if (len == 12 || len == 16) {
      ip = ip.match(/(.{1,3})\.?/g)!.map((octed) => parseInt(octed, 10)).join(".")
    }
    return ip;
  }
}

export default deviceStatsHelpers