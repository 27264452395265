<script setup lang="ts">
import { useVue } from '@/app';
import { T } from '@/classes/i18n';
import stringHelpers from '@/helpers/helpers.strings';
import vue from '@/main';
import queries from '@/queries/queries';
import { sprintf } from 'sprintf-js';
import { computed, onMounted, ref, watch, watchEffect, type Ref } from 'vue';
import { MutationTypes, useStore } from "@/store/vuex.store"
import inputVueSelect from '../inputtypes/input-vue-select.vue';
import dialogs from '@/dialogs/dialogs';
import timeHelpers from '@/helpers/helpers.time';
import buttons, { Button } from '@/classes/buttons';
import buttonComponent from '../components/button.vue';
import Loader from '../components/loader.vue';
import router from '@/router/router';
import tenantHelpers from '@/helpers/helpers.tenants';
import TableNext, { type TableEntryInfo } from "../components/tableNext.vue";
import type { Role } from '@/classes/objectTypes/unifiedSecurity/roles/roles';
import products from '@/classes/objectTypes';
import mixinHelpers from '@/helpers/helpers.mixins';
import type { AzureGroup, ImportAzurePayload } from '@/classes/objectTypes/unifiedSecurity/users/azure';
import {useAzureStore} from '@/classes/objectTypes/unifiedSecurity/users/azure';
import config from '@/classes/config';

const props = defineProps<{
  properties: undefined
}>()

// Computed Values
const accountId = computed(() => {
  return useStore().state.session.activeAccountId || ''
})

const azureStore = useAzureStore()

// Ref Values
// General
const loaderText = ref("Loading...")
const importType = ref('csv')
const initialized = ref(false)
const azureError = ref(T('There are no groups which can be imported from Entra ID.'))

const selectOptions = ref(<selectOption[]>[{
  "id": "csv",
  "text": "CSV"
}, {
  "id": "azure",
  "text": "Entra ID"
}])

const clipboardButton = ref(buttons.createButton({
  "text": T('Copy schema'),
  "title": T('Copy schema'),
  "disabled": false,
  "loading": false,
  "icon": "fal fa-copy",
  "onClick": () => {
    copyToClipboard(csvShema)
  }
}))

// Azure
const azurePattern: RegExp = /^[a-zA-Z0-9_-]{3,30}$/;
const removeAllRoles = ref(false)

// Getting SelectOptions for Each AzureGroup
const azureGroups = computed(() => {
  return (azureStore?.$state?.azureGroups || []) as AzureGroup[]
})

const roleSelectOptions = computed(() => {
  const roles = products.unifiedSecurity.roles.useStore?.().getObjectStoreObjects(accountId.value) || []
  return roles.map((role: Role) => { return { "id": role.rolename, "text": role.rolename } }) as selectOption[]
});

const checkedGroups = computed(() => {
  return Array.isArray(azureTable.value?.selection) ? azureGroups.value.filter((azureGroup) => {
    return azureTable.value?.selection.some((selection: string) => { return selection == azureGroup.id })
  }) : []
})
const hasInvalidName = computed(() => {
  return checkedGroups.value.some((azureGroup: AzureGroup) => {
    if (typeof azureGroup.mappingName != "string" || !azurePattern.test(azureGroup.mappingName)) {
      return true
    }
  });
})

const azureTable = ref(null) as Ref<any>
const selectableFields = <TableEntryInfo[]>[
  {
    "property": "displayName",
    "width": 0,
    "text": T("Entra ID-Group"),
    "displayType": "text",
    "getValue": (entry: AzureGroup) => {
      return entry.displayName
    }
  },
  {
    "property": "domainName",
    "width": 250,
    "text": "Entra ID-Tenant",
    "displayType": "text",
    "getValue": (entry: AzureGroup) => {
      return entry?.azureTenant?.domainName || ""
    },
  },
  {
    "property": "mappingName",
    "width": 0,
    "sortable": false,
    "text": T("Local Group (Role)"),
    "displayType": "input",
    "rowClassList": (entry: AzureGroup) => {
      let result: string[] = []
      if(!(typeof entry.mappingName == "string" && azurePattern.test(entry.mappingName))) {
        result.push("color-red")
      }
      return result
    },
    "inputOptions": {
      "type": "select",
      "saveValueToProperty": "mappingName",
      "select": {
        "options": (entry: AzureGroup) => { return [entry.selectOption].concat(roleSelectOptions.value) },
        "multiple": false,
        "loading": false,
        "tags": true
      },
      "disabled": (entry: AzureGroup) => {
        return checkedGroups.value.indexOf(entry) !== -1
      }
    },
    "getValue": (entry: AzureGroup) => {
      return entry.mappingName || ""
    },
  }
]

// CSV
const succesfulUploads = ref(<string[]>[])
const warnings = ref(<string[]>[])
const errors = ref(<string[]>[])

// reactive Values
const csvShema = `﻿username;firstname;lastname;email;comment;roles;password;variable1;variable2;variable3
MMustermann;Max;Mustermann;max@mustermann.de;Mustercomment;Musterrolle1,Musterrolle2;passwort123;musterVariable1;musterVariable2;musterVariable3
mmueller;Martin;Müller;martin@mueller.de;Vertrieb;auditor,vertrieb;456passwort;;;`

function importUsers() {
  if (importType.value == "csv") {
    uploadCsv()
  }
  if (importType.value == "azure") {
    importAzure()
  }
}

// Functions
function uploadCsv() {
  interface csvError {
    instancePath: string,
    schemaPath: string,
    keyword: string,
    message?: string,
  }
  interface csvUser {
    errors: {
      errors: csvError[],
      missing: unknown[]
      valid: boolean
    },
    username: string
  }
  interface csvApiResponse {
    summary: {
      success: number,
      failed: number,
      warnings: {
        skippedRoleImports: string[],
        skippedDuplicateUsers: string[]
      }
    },
    users: {
      [username: string]: csvUser
    }
  }
  let result: csvApiResponse

  // Creating an invisible input tag and simulating a click
  var element: any = document.createElement('input');
  element.setAttribute('type', 'file');
  element.style.display = 'none';

  $(element).on('change', async function (event: any) {
    errors.value = []
    succesfulUploads.value = []
    warnings.value = []

    let files: FormData | undefined = undefined
    vue.$refs.modals.$refs.modal.buttons[1].loading = true
    vue.$refs.modals.$refs.modal.buttons[1].disabled = true

    // If a file was uploaded
    if (event.target?.files) {
      try {
        let isCSV: boolean = true
        files = new FormData()

        // Checks if the file is a csv or has users
        for (let file of event.target.files) {
          files.append('file', file)
          isCSV = file.type == 'text/csv' && isCSV ? true : /\.csv$/.test(file.name)
          const reader = new FileReader();
          reader.addEventListener('load', (event) => {
            const result = event.target?.result;
            let rows = (result as string || "").split('\n')
            if (rows.length == 1 || (rows.length == 2 && rows[1].length < 5)) {
              errors.value.push(T('The selected .CSV file does not contain any users'))
            }
          });
          reader.readAsText(file)
        }

        // is CSV and has Users. Time for a request
        if (isCSV && errors.value.length == 0) {
          result = await queries.unifiedSecurity.uploadCsv(accountId.value, files)
          result = result

          // Checks if every user could be uploaded
          if (result && result?.users) {

            // Loops through failed users
            Object.keys(result.users).forEach((username: string) => {
              const user: csvUser = result.users[username];

              // Checks if the user has any errors
              if (user.errors.valid === false) {
                let errorString: string = username + ": ";

                (user.errors.missing || []).forEach((error: unknown) => {
                  if (typeof error === 'string' && error.includes(username)) {
                    error = error.replace(username, "%s")
                    errorString += sprintf(T(error), username)
                  }

                });
                (user.errors?.errors || []).forEach((error: csvError) => {
                  if (error.message) {
                    const property = stringHelpers.capitalizeFirstLetter(error.instancePath).slice((error.instancePath)?.lastIndexOf("/") + 1)
                    errorString += sprintf(T('Error with the %s property:') + " ", T(property)) + ' ' + stringHelpers.capitalizeFirstLetter(error.message)
                  }
                });
                errors.value.push(errorString)

              } // Checks if the upload had success
              else if (user.errors.valid) {
                succesfulUploads.value.push(sprintf(T("Successfully added user %s"), username))
              }
            })

            // Duplicate Users in the csv
            if (result?.summary?.warnings?.skippedDuplicateUsers?.length) {
              let errorString = T('The following users existed multiple times and were not imported:') + ' '
              result?.summary?.warnings?.skippedDuplicateUsers?.forEach((user: string) => {
                errorString = errorString + user + ', '
              })
              warnings.value.push(errorString.slice(0, -2))
            }

            // Roles which could not be imported
            if (result?.summary?.warnings?.skippedRoleImports?.length) {
              let errorString = T('The following roles could not be imported:') + ' '
              result?.summary?.warnings?.skippedRoleImports?.forEach((role: string) => {
                errorString = errorString + role + ", "
              })
              warnings.value.push(errorString.slice(0, -2))
            }

          }
        } else if (!isCSV) {
          throw T('Wrong filetype. Please upload a .csv file.')
        }
      } catch (e: any) {
        e = e.responseJSON ? e.responseJSON : e
        handleError(e)
        console.error(e)
      }

      vue.$refs.modals.$refs.modal.buttons[1].loading = false
      vue.$refs.modals.$refs.modal.buttons[1].disabled = false
      useVue().$refs.itemlist?.refresh?.()

      // checks if upload was a success
      if (!errors.value.length && !warnings.value.length) {
        useVue().$refs.modals.removeModal()
        dialogs.misc.confirmDialog(accountId.value, T('Import successfull'), T('The users have been added successfully'), useVue().$refs.modals.removeModal
          , undefined, undefined, undefined, undefined, "fal fa-check")
      }
    } else {
      vue.$refs.modals.$refs.modal.buttons[1].loading = false
      vue.$refs.modals.$refs.modal.buttons[1].disabled = false
    }
  })
  element.click();
}

async function importAzure() {

  let payload: ImportAzurePayload = {}

  checkedGroups.value.forEach((group: AzureGroup) => {

    if (group.azureTenant?.id && group.mappingName) {

      payload[group.azureTenant.id] ||= {
        "roleMapping": {}
      }

      payload[group.azureTenant.id].roleMapping[group.id] = {
        "rolename": group.mappingName,
        "importGroupRelations": false,
        "removeExistingRoles": removeAllRoles.value
      }
    }
  })
  try {
    if(azureStore !== undefined) {
      const result = await azureStore.importAzureGroups(accountId.value, payload)
    }
    useVue().$refs.modals.removeModal()
  }
  catch (e: unknown) {
    console.error(e)
  }
}

async function copyToClipboard(text: string) {
  if (clipboardButton.value) {
    clipboardButton.value.disabled = true
    clipboardButton.value.loading = true
  }
  navigator.clipboard.writeText(text).then(async () => {
    await timeHelpers.sleep(1500)
  }, (err) => {
    console.error('Could not copy text: ', err);
  });

  if (clipboardButton.value) {
    clipboardButton.value.loading = false
    clipboardButton.value.disabled = false
    clipboardButton.value.text = T('Copied')
    clipboardButton.value.icon = 'fal fa-check'
    await timeHelpers.sleep(1500)
    clipboardButton.value.icon = 'fal fa-copy'
    clipboardButton.value.text = T('Copy schema')
  }
}

function handleError(e: any) {
  if (typeof e === "string") {
    errors.value.push(e)
  }
  else if (typeof e.data === 'string') {
    errors.value.push(e.data)
  }
  else if (e?.data?.errors && Array.isArray(e.data?.errors)) {
    e.data.errors.forEach((error: any) => {
      errors.value.push(stringHelpers.capitalizeFirstLetter(error.message))
    })
  } else {
    errors.value.push(T("An undefined error has occurred. Please check the formatting of the file"))
  }
}


// Lifecycle Hooks
onMounted(async () => {
    try {
        await azureStore.initStore(accountId.value)
    }
    catch (e: unknown) {
      console.error(e)
      if (typeof e == "string") {
        azureError.value = e
      }
      else if (e instanceof Error) {
        azureError.value = e
      }
    }
  initialized.value = true
})


// Watches
watch(importType, () => {
  if (importType.value == 'csv') {
    clipboardButton.value = buttons.createButton({
      "text": T('Copy schema'),
      "title": T('Copy schema'),
      "disabled": false,
      "loading": false,
      "icon": "fal fa-copy",
      "onClick": () => {
        copyToClipboard(csvShema)
      }
    })
    const button = useStore().getters.getActiveModal(accountId.value).buttons[1] as Button
    if (button) {
      button.text = T("Select .csv File")
      button.title = T("Select .csv File")
      button.icon = "fal fa-fw fa-file-word"
      button.disabled = false
    }
  }

  if (importType.value == 'azure') {
    clipboardButton.value = buttons.createButton({
      "text": "Entra ID " + T('Settings'),
      "title": "Entra ID " + T('Settings'),
      "disabled": false,
      "loading": false,
      "icon": "fab fa-fw fal fa-microsoft",
      "onClick": () => {
        router.navigate("show-tenant-" + tenantHelpers.getTenantDomain(accountId.value) + "-general-settings")
        useStore().commit(MutationTypes.removeModal, {
          "accountId": accountId.value
        })
      }
    })
    const button = useStore().getters.getActiveModal(accountId.value).buttons[1] as Button
    if (button) {
      button.text = T("Import from Entra ID")
      button.title = T("Import from Entra ID")
      button.icon = "fab fa-fw fal fa-microsoft"
      button.disabled = true
    }
  }
})

watchEffect(() => {
  const button = useStore().getters.getActiveModal(accountId.value).buttons[1] as Button
  if (importType.value == "azure" && button) {
    if (checkedGroups.value.length == 0) {
      button.disabled = true
    }
    else {
      if (hasInvalidName.value) button.disabled = true;
      else button.disabled = false;
    }
  }
})

// Exposes
defineExpose({
  importUsers
})

</script>
<template>
  <template v-if="initialized === true">

    <!-- Select -->
    <div class="row flexrow" style="margin-bottom: 8px;">
      <inputVueSelect class="col-xs" :multiple="false" v-model="importType" :selectOptions="selectOptions">
      </inputVueSelect>
      <buttonComponent :buttonOptions="clipboardButton" style="width: auto; min-width: 170px; margin-left: 8px;">
      </buttonComponent>
    </div>

    <!-- CSV -->
    <template v-if="importType === 'csv'">
      <div class="row flexrow">
        <pre class="text-small"><strong>{{ T('Please import your .CSV with following schema:') }}</strong>
{{ T('username;firstname;lastname;email;comment(optional);roles(optional; comma separated);password;variable1(optional);variable2(optional);variable3(optional)') }}
{{ T('mmustermann;Max;Mustermann;max@mustermann.de;Administration;admin;passwort123;musterVariable1;musterVariable2;musterVariable3') }}
{{ T('mmueller;Martin;Müller;martin@mueller.de;Vertrieb;auditor,vertrieb;456passwort;;;') }}</pre>
      </div>
      <p v-for="user in succesfulUploads" class="notification bg-green color-white">{{ user }}</p>
      <p v-for="warning in warnings" class="notification bg-yellow color-white">{{ warning }}</p>
      <p v-for="error in errors" class="notification bg-red color-white">{{ error }}</p>
    </template>

    <!-- Azure -->
    <template v-else-if="importType === 'azure'">
      <template v-if="Array.isArray(azureGroups) && azureGroups.length > 0">
        {{ T('Please select the groups to be imported from the Entra ID') + ". " + T('Local group names can have 3 to 30 characters and cannot contain any special characters except "-" and "_".') }}
          <TableNext 
          ref="azureTable" 
          :hasOptions="false" 
          :max-height="363"
          :is-filterable="false"
          :object-list="azureGroups"
          :selectable-columns="selectableFields" 
          :rows-selectable="true" 
          :use-paging="false"
          :row-value-getter="(entry: AzureGroup) => { return entry.id }" 
          :is-searchable="false">
          </TableNext>
        <div>
          <div class="row margin-xs-t">
            <div class="col-xs-24">
              <label class="input checkbox">
                <input type="checkbox" v-model="removeAllRoles" :value="removeAllRoles"><span></span>
              </label>
              <span>
                {{ T('Do you want to remove all previous group assignments of the imported groups?') }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-24">
              <span>
                <i class="fal fa-exclamation-triangle margin-xs-r"></i>
                <strong>{{ T('Attention') }}</strong>
                {{ ": " + T('All users of the selected Entra ID group are imported. If you want to import an Entra ID group into several local groups, you can start the import again.Previous group assignments remain in place.') }}
              </span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        {{ azureError }}
      </template>
    </template>
  </template>

  <!-- Loading -->
  <template v-else>
    <div class="text-size-3 text-center padding-xs-t-4">
      <loader class="color-red"></loader>
    </div>
    <div v-if="loaderText !== undefined" class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity:0.8">
      <span>{{ T(loaderText) }}</span>
    </div>
  </template>
</template>