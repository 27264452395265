import { defineStore } from "pinia";
import requestHandler from "@/queries/requests";
import config from "@/classes/config"
import tenantHelpers from "@/helpers/helpers.tenants";
import encodingHelpers from "@/helpers/helpers.encoding";
import cookies from "@/classes/cookieHandler";
import router from "@/router/router";
import websocketHandler from "@/classes/websocket";

const useSessionStore = defineStore({
    id: "session",
    state: () => ({
        sessionInfo: <undefined | UserSession>undefined
    }),
    getters: {
        /**
         * returns if session exists
         * @returns {Boolean}
         */
        hasSession: (state): Boolean => {
            return state.sessionInfo != undefined || false
        },

        /**
         * return UserSession if exists, else returns undefined
         * @returns {undefined|UserSession}
         */
        getSession: (state): undefined | UserSession => {
            return state.sessionInfo
        },

        getAccountInfoFromSession: (state) => {
            return (accountId: string) => {
                let result: undefined | AccountInfo = undefined
                result = state.sessionInfo?.scopes.list_accounts.find((account: AccountInfo) => {
                    return String(account.accountid) == String(accountId)
                })
                return result
            }
        },

        /**
         * If signed in user is reseller
         * @returns {Boolean}
         */
        isReseller: (state): Boolean => {
            return state.sessionInfo?.roles.indexOf("__RESELLER__") != -1
        },

        /**
         * returns targetDomain as accountId
         * @returns {string}
         */
        getTargetAccountId: (state): string => {
            return tenantHelpers.getAccountId(state.sessionInfo?.targetDomain || state.sessionInfo?.domain || "")
        }
    },
    actions: {
        /**
        * Performs login request
        * @param username The username
        * @param password The users password
        * @param rememberme wether the login should be remembered or not
        * @returns { Boolean } 
        */
        async login(username: string, password: string, rememberme: boolean = false, code: string = ""): Promise<boolean> {
            let response = await requestHandler.request("POST", config.authApiUri, {
                username: username,
                password: password,
                rememberme: rememberme,
                code: code
            })
            return response.message == "OK"
        },
        /**
        * Performs auth request in order to check if a session already exists
        * @returns { UserSession }
        */
        async checkAuth(): Promise<UserSession | false> {
            let result: UserSession | false = false
            try {
                let response = await requestHandler.request("GET", config.authApiUri)
                result = response.roles != undefined ? response as UserSession : false
            }
            catch (e) {
            }
            return result
        },
        /**
         * 
         * @param value Wether session is active or not
         */
        setSession(sessionInfo: UserSession) {
            this.sessionInfo = sessionInfo
        },
        /**
         * Performs Logout request, clears stores & deletes session cookies
         * @returns 
         */
        async logout() {
            let result: boolean = false
            try {
                cookies.delete("hash")
                cookies.delete("JSESSIONID")
                cookies.delete("SP_SESSIONID")
                cookies.delete("SP_OAUTH2")
                if (this.getSession?.oauth2) {
                    location.href = "/oauth2/sign_out?rd=" + encodingHelpers.encodeURI('/sms/')
                }
                else {
                    result = await requestHandler.request("DELETE", config.authApiUri)
                }
            }
            catch (e: any) { } 
            finally {
                websocketHandler.closeConnection()
                router.navigate("login")
                this.sessionInfo = undefined
            }
            return result
        },

        /**
         * Return if current UserSession contains role
         * @param roleName The role which needs to be checked
         * @returns 
         */
        hasAccountRole(roleName: string) {
            return this.sessionInfo?.roles?.indexOf(roleName) != -1
        },

    }
});
export default useSessionStore

export interface Org {
    accountid: number;
    account_no: string;
    accountname: string;
    bill_street: string;
    bill_code: string;
    bill_city: string;
    bill_country: string;
    parent_accountid?: any;
    parent_account_no?: any;
}

export interface ListUser {
    id?: number;
    email?: string;
    login?: string;
    salutation?: string;
    given_name?: string;
    name?: string;
    primary_role?: string;
    primary_user?: boolean;
    accepted_latest_terms?: boolean;
    org?: Org;
    roles?: string[];
    permissions?: string[];
}

export interface AccountInfo {
    activetimezone: string;
    isReseller?: boolean;
    accountid: number;
    account_no: string;
    accountname: string;
    email: string;
    account_email: string;
    accountaddressid: number;
    bill_street: string;
    bill_code: string;
    bill_city: string;
    bill_state: string;
    bill_country: string;
    bill_pobox: string;
    parent_accountid: number;
    parent_account_no: string;
    parent_accountname: string;
    is_account_of_logged_in_user: boolean;
    rspUserConnectable?: boolean
}

export interface ListLicense {
    license_id: number;
    license_user: number;
    license_spare: number;
    msp_license_id: number;
    laas_enabled: boolean;
    license_uuid: string;
    fwaas_enabled: boolean;
    usc_monitoring_enabled: boolean;
    usc_messaging_enabled: boolean;
    accountid: number;
    account_no: string;
    accountname: string;
    sort_account_no: string;
    license_displayname: string;
    license_type: string;
    license_nfr: boolean;
    license_serial: string;
    license_marked_deleted: boolean;
    license_deletion_request: boolean;
    license_av_tid: string;
    extend_license_id: number;
    license_test: boolean;
    license_created_av: number;
    license_expiration_date: string;
    license_creation_date: string;
    permissionAffix: string;
    external_billing?: boolean;
    license_scopes?: string[];
    license_name?: string;
    license_org?: number;
    license_email?: string;
}

export interface SessionScopes {
    list_user: ListUser;
    list_accounts: AccountInfo[];
    list_licenses: ListLicense[];
}

export interface UserSession {
    domain: string;
    targetDomain: string;
    roles: string[];
    scopes: SessionScopes;
    tenantId: number;
    timeout: number;
    username: string;
    oauth2: boolean;
    basicAuth: boolean;
    permissionScopes: string[];
    subjects: string[];
    name?: string;
    login?: string;
    email?: string;
    id?: number;
}