<script setup lang="ts">
    import { vueApp } from '@/app';
    import { T } from '@/classes/i18n';
    import { computed, onMounted, ref, watch } from 'vue';
    const isActive = ref(false)
    const props = withDefaults(defineProps<{
        containerEl?: HTMLElement,
        mainButton?: boolean
    }>(), {
        containerEl:undefined,
        mainButton:false
    });

    const isMainButtonActive = computed(() => {
        return vueApp?.$refs?.scrollToTopButton?.isActive == true
    })

    const scrollToTop = () => {
        let scrollContainer = props.containerEl ? props.containerEl : document.getElementsByTagName('main')[0]
        scrollContainer.scrollTo({
            "top":0,
            "behavior":"smooth"
        })
    }

    onMounted(() => {
        setTimeout(() => {
            let scrollContainer = props.containerEl ? props.containerEl : document.getElementsByTagName('main')[0]
            scrollContainer?.addEventListener("scroll", () => {
                let scrollDistance = scrollContainer.scrollTop
                if (scrollDistance >= 2 && (props.mainButton == true || isMainButtonActive.value == false)) {
                    isActive.value = true
                }
                else {
                    isActive.value = false
                }
            })
        },500)
        
    })
    watch(isMainButtonActive, () => {
        if (props.mainButton == false && isMainButtonActive.value == true) isActive.value = false;
        else if(props.mainButton == false && isMainButtonActive.value == false) isActive.value = (props.containerEl?.scrollTop || 0)>= 2
    })
    defineExpose({
        isActive
    })
</script>
<template>
    <a 
    id="scrollTopButton" 
    :class="{ 'visible': isActive, 'customContainer': containerEl != undefined}" 
    v-on:click="scrollToTop"
        :title="T('Scroll back to top')">
        <i class="fal fa-arrow-up-to-line"></i>
    </a>
</template>
<style lang="scss">
    @import "../../styles/sass/settings";

    $buttonWidth:32px;

    #scrollTopButton {
        
        position:fixed;
        right:-50px;
        bottom:10px;
        width:$buttonWidth;
        height:$buttonWidth;
        z-index:10;
        background:$primaryColor;
        text-align: center;
        font-size:1.2em;
        opacity:0.7;
        transition:0.5s;
        border-radius:2px;
        
        &.visible {
            right:10px;
        }
        &:hover {
            opacity: 1;
            transform: scale(1.1);
        }
        
        i {
            color:rgba(255,255,255,0.7);
            width: $buttonWidth;
            line-height: $buttonWidth;
        }
    }
</style>