import miscDialogs from "./dialogs.misc";
import mobileSecurityDialogs from "./dialogs.mobile.security";
import unifiedReportingDialogs from "./dialogs.unified.reporting";
import unifiedSecurityDialogs from "./dialogs.unified.security";
import unifiedNetworkDialogs from "./dialogs.unified.network"; 

const dialogs = {
    misc: miscDialogs,
    mobileSecurity: mobileSecurityDialogs,
    unifiedReporting: unifiedReportingDialogs,
    unifiedSecurity: unifiedSecurityDialogs,
    unifiedNetwork: unifiedNetworkDialogs
}
export default dialogs