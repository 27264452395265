<script setup lang="ts">
import getterHelpers from '@/helpers/helpers.getters';
import mixinHelpers from '@/helpers/helpers.mixins';
import { computed } from 'vue';

// Props
const darkmode = computed(() => { return getterHelpers.useStore()?.state.browserSettings.darkmode })
const props = withDefaults(defineProps<{
    icon?:string,
    title?: string
}>(), {
    icon: "fal fa-box",
    title: ""
});
</script>
<template>
    <div class="box-body border-bottom">
        <div class="box-icon-left">
            <div class="icon-circle hover-bg-red" :class="[darkmode == '1' ? 'bg-darkbluegrey' : 'bg-gray']">
                <i :class="props.icon"></i>
            </div>
        </div>
        <div class="box-content-2">
            <div class="box-title">
                <strong class="padding-xs-t-2">
                    {{ title }}
                </strong>
            </div>
        </div>
        
    </div>
</template>
<style scoped>

</style>