import jsonHelpers from "../../helpers/helpers.json";
import { useGlobalMixin } from "@/mixins/mixins.global";
import apis from "../../classes/apis";
import { nextTick } from "vue";
import { T } from "@/classes/i18n";
import moment from "moment";
import { ActionTypes, MutationTypes } from "@/store/vuex.store";
import queries from "@/queries/queries";
import router from "@/router/router";
import $ from "jquery";
import requestHandler from "@/queries/requests";
import loaderComponent from "../components/loader.vue";
import table2Component from "../components/table2";
import itemlistItemComponent from "../components/itemlist-item.vue";
import { useStore } from '@/store/vuex.store'
import tenantHelpers from "@/helpers/helpers.tenants";
import numberHelpers from "@/helpers/helpers.numbers";
import dialogs from "@/dialogs/dialogs";
import objectStores from "@/classes/init";
import { vueApp } from "@/app";
import timeHelpers from "@/helpers/helpers.time";
import deviceInlineRenameComponent from "../components/device-inline-rename";
import inlineItemlistInputComponent from "../components/inline-itemlist-input.vue";
import regularlyUpdatedDataComponent from "../components/regularly-updated-data";
import googleMapComponent from "../components/map";
import stringHelpers from "@/helpers/helpers.strings";
import encodingHelpers from "@/helpers/helpers.encoding";
import useRouterStore from "@/router/routerStore";
import deviceHelpers from "@/helpers/helpers.devices";
declare var datepicker: any

const emmDeviceDetailsComponent =  {
	name: 'sms-page-emm-device-details',
	mixins: [useGlobalMixin()],
	data() {
		return {
			"policyLoading": false,
			"thisDevicePolicyName": "",
			"refreshCounter":0,
			"activeTab":"overview",
			"policies":[],
			"initialized":false,
			"changingDeviceState":false,
			"applicationReportTableData":{
				"loading":true,
				"table":{
					"titles":[],
					"rows":[]
				}
			},
			"nonComplianceTableData":{
				"loading": false,
				"table": {
					"titles": [],
					"rows": []
				}
			},
			"deviceActionStates":{
				"LOCK":false,
				"REBOOT":false,
				"RESET_PASSWORD":false,
				"LOCATION":false,
				"RELINQUISH_OWNERSHIP":false
			},
			"contractDataLoaded": false,
			"savingProperties":false,
			"deviceLocation":{
				"loading":false,
				"long":undefined,
				"lat":undefined,
				"errors":[],
				"timestamp":undefined
			},
			"propertiesForm": {
				"billNumber":"",
				"billDate":"",
				"hardwareDescription":"",
				"providerName":"",
				"providerTariff":"",
				"contractDate":"",
				"contractDuration":"",
				"handoverDate":"",
				"contractNumber":"",
				"providerCustomerNumber":"",
				"warrantyStartDate":"",
				"warrantyEndDate":"",
				"inventoryExternalLink":""
			},
		};
	},
	computed: {
		"device": function (this: any) {
			return useStore().getters.getObject({
				"accountId": this.activeAccountId,
				"productType": "mobileSecurity",
				"objectType": "enterpriseDevices",
				"objectId": this.objectId || ""
			})
		},
		hasLocationPlugin: function(this:any) {
			return (this.device?.applicationReports || []).some((applicationReport:any) => {
				return applicationReport.packageName == 'de.securepoint.emm.toolbox'
			}) && (this.device?.applicationReports || []).some((applicationReport:any) => {
				return applicationReport.packageName == 'de.securepoint.emm.toolbox.location'
			})
		}
	},
	template: `
		<section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-if="!initialized">
			<div class="loaderbox text-center">
				<loader
					class="color-red"
					:progress="$parent.loadingProgress"
				></loader>
			</div>
		</section>
		<section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-else-if="initialized">
			<div class="row itemlist show-details view-grid">
				<div class="col-xs-24 col-print-24 col-lg-12  padding-xs item" style="z-index:999999">
					<itemlist-item
						:item="device"
						:index="1"
						:showInfo="true"
						key="itemlistItem"
						ref="itemlistItem"
						:showMenu="false"
						:clickable="false"
						:refreshCounterProp="refreshCounter"
					/>
				</div>
				<div class="col-xs-24 col-print-24 col-lg-12 padding-xs">
					<div class="box-shadow box-fullheight content-2">
												

						<template v-if="device.inventory && device.inventory.inventoryLocation != undefined && !isObjectEmpty(device.inventory.inventoryLocation)">
							<h3 class="float-xs-left">${T('Inventory location')}</h3>
							<a 
								v-on:click="getInventoryLocationDialog()" 
								class="btn padding-xs-y-0 float-xs-right" 
								:class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'" 
								:title="T('Set inventory device location')"
							><i class="fal fa-edit"></i></a>
						</template>
						<div class="clearfix"></div>
						<template 
							v-if="device 
								&& device.inventory 
								&& device.inventory.inventoryLocation 
								&& device.inventory.inventoryLocation.latitude 
								&& device.inventory.inventoryLocation.longitude
							"
						>
							<googleMap
								id="inventoryMap"
								:entries="[{
									'position':{
										'lat':device.inventory.inventoryLocation.latitude,
										'lng':device.inventory.inventoryLocation.longitude
									},
									'infoHtml':getInfoBoxText() 
								}]"
							/>
						</template>
						<template v-if="!device.inventory || device.inventory.inventoryLocation != undefined && isObjectEmpty(device.inventory.inventoryLocation)">
							<div :style="
								(darkmode == '1' ? 'background: rgba(0, 0, 0, 0.2);' : 'background: rgba(0, 0, 0, 0.08);')
								+ 'top: 16px;'
								+ 'height:calc(100% - 32px);'
								+ 'left: 16px;'
								+ 'right: 16px;'
								+ 'position: absolute;'
							">
								<div style="position:absolute;top:50%;transform: translate(0, -50%); width: 100%;" class="text-center">
									<h3 style="
										width: 100%;
										text-align: center;
									">
										{{ T('Inventory location is not set') }}
									</h3>
									<p>{{ T('Click the following link to set the inventory location:') }} <br>
									<br><a class="btn" :title="T('Set inventory device location')" v-on:click="getInventoryLocationDialog()"> <i class="fal fa-fw fa-edit"></i> {{T('Set location')}}</a></p>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-24 padding-xs">
					<div class="box-shadow">
						<div class="tabs">
							<nav>
								<ul class="nav nav-tabs hide-on-print">
									<li>
										<a :class="{'active':activeTab == 'overview'}" v-on:click="setActiveTab('overview')">
											<i class="fal fa-fw fa-mobile-alt"></i> ${T('Overview')}
										</a>
									</li>
									<li>
										<a :class="{'active':activeTab == 'operations'}" v-on:click="setActiveTab('operations')">
											<i class="fal fa-fw fa-wrench"></i> ${T('Operations')}
										</a>
									</li>
									<li>
										<a :class="{'active':activeTab == 'applications'}" v-on:click="setActiveTab('applications')">
											<i class="fal fa-fw fa-rocket"></i> ${T('Applications')}
										</a>
									</li>
									
									<li>
										<a :class="{'active':activeTab == 'policy'}" v-on:click="setActiveTab('policy')">
											<i class="fal fa-fw fa-shield-alt"></i> ${T('Policy')}
										</a>
									</li>
									<li>
										<a :class="{'active':activeTab == 'nonCompliance'}" v-on:click="setActiveTab('nonCompliance')">
											<i class="fal fas-fw fa-exclamation-triangle"></i> ${T('Non compliance details')}
											<span class="label" :class="{'bg-red': nonComplianceTableData.table.rows.length > 0}">
												{{ nonComplianceTableData.table.rows.length }}
											</span>
										</a>
									</li>
								</ul>
							</nav>
							<div class="tabcontent">
								<div :class="{'hidden':activeTab != 'overview'}" id="device-summary">
									<div class="box-content-2">
										<div class="row">
											<div class="col-xs-24 col-lg-12">
												<h4>${T('Device Summary')}</h4>
												<table class="noborder border-h">
													<tbody>
														<tr v-if="device.enrolledAt">
															<td class="title"><i class="fal fa-fw fa-calendar"></i> ${T('Enrolled at')}</td>
															<td>
																{{ moment(device.enrolledAt * 1000).format('DD.MM.YYYY HH:mm') }}
															</td>
														</tr>
														<tr>
															<td class="title"><i class="fal fa-fw fa-hashtag"></i> ${T('Device ID')}</td>
															<td>
																{{ device.id }}
															</td>
														</tr>
														<tr>
															<td class="title"><i class="fal fa-fw fa-microchip"></i> ${T('Device Type')}</td>
															<td>
																"Android"
															</td>
														</tr>
														<tr>
															<td class="title"><i class="fal fa-fw fa-mobile-alt"></i> ${T('Model')}</td>
															<td>
																<template v-if="device && device.hardwareInfo">
																	{{ device.hardwareInfo.model || '' }}
																</template>
																<template v-else>
																	""
																</template>
															</td>
														</tr>
														<tr>
															<td class="title"><i class="fal fa-fw fa-building"></i> ${T('Manufacturer')}</td>
															<td>
																<template v-if="device && device.hardwareInfo">
																	{{ device.hardwareInfo.manufacturer || '' }}
																</template>
																<template v-else>
																	""
																</template>
															</td>
														</tr>
														<tr>
															<td class="title"><i class="fal fa-fw fa-hashtag"></i> ${T('IMEI Number')}</td>
															<td>
																<template v-if="device && device.networkInfo">
																	{{ device.networkInfo.imei || '' }}
																</template>
																<template v-else>
																	""
																</template>
															</td>
														</tr>
														<tr>
															<td class="title"><i class="fal fa-fw fa-microchip"></i> ${T('OS')}</td>
															<td>
																Android 
																<template v-if="device && device.softwareInfo">
																	<span title="Version">({{ device.softwareInfo.androidVersion || '' }})</span>
																</template>
															</td>
														</tr>
														<tr>
															<td class="title"><i class="fal fa-fw fa-hashtag"></i>  ${T('Serial Number')}</td>
															<td>
																<template v-if="device && device.hardwareInfo">
																	{{ device.hardwareInfo.serialNumber || '' }}
																</template>
																<template v-else>
																	""
																</template>
															</td>
														</tr>
														<tr>
															<td class="title"><i class="fal fa-fw fa-hdd"></i>  ${T('Storage Capacity')}</td>
															<td>
																{{ device.memoryInfo ? bytesToSize(device.memoryInfo.totalInternalStorage) : '' }}
															</td>
														</tr>
														<template v-if="device.enrollmentTokenData">
															<tr v-if="device.enrollmentTokenData.userInput != undefined">
																<td class="title">
																	<i class="fal fa-fw fa-info"></i>  ${T('Additional data')}
																</td>
																<td>
																	{{ device.enrollmentTokenData.userInput }}
																</td>
															</tr>

															<tr v-else-if="device.enrollmentTokenData">
																<td class="title">
																	<i class="fal fa-fw fa-info"></i>  ${T('Additional data')}
																</td>
																<td>
																	{{ device.enrollmentTokenData }}
																</td>
															</tr>
														</template>
													</tbody>
												</table>

												<template v-if="device.softwareInfo">
													<h4>${T('Softwareinfo')}</h4>
													<table class="noborder border-h">
														<tr>
															<td class="title">
																<i class="fal fa-fw fa-info-circle"></i> {{T('Android version')}}
															</td>
															<td>
																{{ device.softwareInfo.androidVersion }}
															</td>
														</tr>
														<tr>
															<td class="title">
																<i class="fal fa-fw fa-info-circle"></i> Build
															</td>
															<td>
																{{ device.softwareInfo.androidBuildNumber }}
															</td>
														</tr>
														<tr>
															<td class="title">
																<i class="fal fa-fw fa-info-circle"></i> {{T('Kernel version')}}
															</td>
															<td>
																{{ device.softwareInfo.deviceKernelVersion }}
															</td>
														</tr>
														<tr>
															<td class="title">
																<i class="fal fa-fw fa-info-circle"></i> {{T('Policy version')}}
															</td>
															<td>
																{{ device.softwareInfo.androidDevicePolicyVersionName }}
															</td>
														</tr>
														<tr>
															<td class="title">
																<i class="fal fa-fw fa-info-circle"></i> {{T('Update info')}}
															</td>
															<td>
																{{ (device.softwareInfo.systemUpdateInfo && device.softwareInfo.systemUpdateInfo.updateStatus) ? device.softwareInfo.systemUpdateInfo.updateStatus.replaceAll('_',' ').toLowerCase() : '' }}
															</td>
														</tr>
													</table>

												</template>

											</div>
											<div class="col-xs-24 col-lg-12">
												<template v-if="device.securityPosture && device.securityPosture.devicePosture != 'POSTURE_UNSPECIFIED'">
													<h4>${T('Security posture')}</h4>
													<table class="noborder border-h">
														<tr>
															<td>
																<template v-if="device.securityPosture.devicePosture == 'SECURE'">
																	<span class="text-bold">
																		<i class="fas fa-fw fa-circle color-green"></i> {{ T('Secure') }}
																	</span>
																</template>
																<template v-else>
																	<span class="text-bold">
																		<i class="fas fa-fw fa-circle color-red"></i> {{ T('Unsecure') }}
																	</span>
																</template>
															</td>
														</tr>
														<tr v-if="(device.securityPosture.postureDetails || []).length > 0">
															<td>
																<p v-for="postureDetail in device.securityPosture.postureDetails">
																	<template v-if="postureDetail.securityRisk">
																		{{T(postureDetail.securityRisk.replace("_"," "))}}<br>
																	</template>
																	<template v-if="postureDetail.advice && postureDetail.advice.length">
																		<template v-if="postureDetail.advice[0].defaultMessage">
																			{{ T(postureDetail.advice[0].defaultMessage) }}
																			<br>
																		</template>
																	</template>
																</p>
															</td>
														</tr>
													</table>
												</template>

												<template v-if="device.networkInfo">
														<h4>${T('Network Summary')}</h4>
														<table class="noborder border-h ">
																<tbody>
																		<tr>
																			<td class="title"><i class="fal fa-fw fa-hashtag"></i> ${T('IMEI Number')}</td>
																			<td>
																					{{ device.networkInfo.imei || '' }}
																			</td>
																		</tr>
																		<tr>
																			<td class="title"><i class="fal fa-fw fa-wifi"></i> ${T('WIFI MAC')}</td>
																			<td>
																					{{ device.networkInfo.wifiMacAddress || '' }}
																			</td>
																		</tr>
																</tbody>
														</table>

														<template v-if="device.networkInfo.telephonyInfos">
																<template v-for="slot,index in device.networkInfo.telephonyInfos">

																		<h4>Sim slot {{ index }}</h4>
																		<table class="noborder border-h ">
																				<tr>
																						<td class="title">
																								<i class="fal fa-fw fa-phone"></i> {{ T('Phone Number') }}
																						</td>
																						<td>
																								{{ slot.phoneNumber || "" }}
																						</td>
																				</tr>
																				<tr>
																						<td class="title">
																								<i class="fal fa-fw fa-info-circle"></i> {{ T('Carrier info') }}
																						</td>
																						<td>
																								<template v-if="slot.carrierName">
																										{{ slot.carrierName || "" }}
																								</template>
																						</td>
																				</tr>
																		</table>
																</template>
														</template>


												</template>

												<template v-if="device.deviceSettings">
													<h4>${T('Device settings')}</h4>

													<table class="noborder border-h">
														<tr>
															<td style="width:230px;">
																<strong>
																	<i class="fal fa-fw fa-shield-alt"></i>
																	{{T('Is secure')}}
																</strong>
																<p><i>{{T('Whether the device is secured with PIN/password.')}}</i></p>
															</td>
															<td>
																<template v-if="device.deviceSettings.isDeviceSecure">
																	<i class="fal fa-fw fa-check"></i>
																</template>
																<template v-else>
																	<i class="fal fa-fw fa-times"></i>
																</template>
															</td>
														</tr>
														<tr>
															<td>
																<strong>
																	<i class="fal fa-fw fa-file-code"></i>
																	{{T('Allow unknown sources')}}
																</strong>
																<p><i>{{T('Whether installing apps from unknown sources is enabled.')}}</i></p>
															</td>
															<td>
																<template v-if="device.deviceSettings.unknownSourcesEnabled">
																	<i class="fal fa-fw fa-check"></i>
																</template>
																<template v-else>
																	<i class="fal fa-fw fa-times"></i>
																</template>
															</td>
														</tr>
														<tr>
															<td>
																<strong>
																	<i class="fal fa-fw fa-file-code"></i>
																	{{T('Development settings enabled')}}
																</strong>
																<p><i>{{T('Whether developer mode is enabled on the device.')}}</i></p>
															</td>
															<td>
																<template v-if="device.deviceSettings.developmentSettingsEnabled">
																	<i class="fal fa-fw fa-check"></i>
																</template>
																<template v-else>
																	<i class="fal fa-fw fa-times"></i>
																</template>
															</td>
														</tr>
														<tr>
															<td>
																<strong>
																	<i class="fal fa-fw fa-file-code"></i>
																	{{T('ADB enabled')}}
																</strong>
																<p><i>{{T('Whether ADB is enabled on the device.')}}</i></p>
															</td>
															<td>
																<template v-if="device.deviceSettings.adbEnabled">
																	<i class="fal fa-fw fa-check"></i>
																</template>
																<template v-else>
																	<i class="fal fa-fw fa-times"></i>
																</template>
															</td>
														</tr>
														<tr>
															<td>
																<strong>
																	<i class="fal fa-fw fa-lock"></i>
																	{{T('Encrypted')}}
																</strong>
																<p><i>{{T('Whether the storage encryption is enabled.')}}</i></p>
															</td>
															<td>
																<template v-if="device.deviceSettings.isEncrypted">
																	<i class="fal fa-fw fa-check"></i>
																</template>
																<template v-else>
																	<i class="fal fa-fw fa-times"></i>
																</template>
															</td>
														</tr>
														<tr>
															<td>
																<strong>
																	<i class="fal fa-fw fa-check-circle"></i>
																	{{T('Verify Apps')}}
																</strong>
																<p><i>{{T('Whether Google Play Protect verification is enforced on the device.')}}</i></p>
															</td>
															<td>
																<template v-if="device.deviceSettings.verifyAppsEnabled">
																	<i class="fal fa-fw fa-check"></i>
																</template>
																<template v-else>
																	<i class="fal fa-fw fa-times"></i>
																</template>
															</td>
														</tr>
													</table>
												</template>
											</div>
										</div>
									</div>
								</div>
								<div :class="{'hidden':activeTab != 'applications'}" id="applications">
									<div class="box-content-2">
										<div class="row">
											<div class="col-xs-24">
												<div class="row">
													<div class="col-xs-24">
														<h4>${T('Applications')}</h4>
														<span></span>
													</div>
												</div>
											</div>
											<div class="col-xs-24">
												<div class="row">
													<div class="col-xs-24 padding-xs-x" >
														<template v-if="applicationReportTableData.length == 0">
															{{ T('Applications are only available for enterprise devices that have application reporting activated in their profile.')}}
														</template>
														<table2
															:class="{'hidden': applicationReportTableData.length == 0}"
															ref="applicationReportTable"
															class="applicationReportTable"
															:table="applicationReportTableData.table"
															:loading="applicationReportTableData.loading"
															:minheight="50"
														></table2>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div :class="{'hidden':activeTab != 'operations'}" id="device-operations">
									<div class="box-content-2">
										<div class="row">
											<div class="col-xs-24">
												<div class="row">
													<div class="col-xs-24">
														<h4>${T('Device Operations')}</h4>
														<span></span>
													</div>
												</div>
											</div>
											<div class="col-xs-24 padding-xs-t">
												<div class="row">
													<div class="col-lg-6 col-xl-3 padding-xs-x">
														<button
															class="btn-loader twist-in col-xs-24"
															v-on:click="doDeviceAction('LOCK')"
															:class="{'btn-loading': deviceActionStates['LOCK']}"
															:disabled="deviceActionStates['LOCK'] || null"
														>
															<span class="animate">
																<loader
																	v-if="deviceActionStates['LOCK']"
																	style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
																></loader>
															</span>
															<span><i class="fal fa-lock"></i> ${T('Lock')}</span>
														</button>
													</div>
													<div class="col-lg-6 col-xl-3 padding-xs-x">
														<button
															class="btn-loader twist-in col-xs-24"
															v-on:click="doDeviceAction('REBOOT')"
															:class="{'btn-loading': deviceActionStates['REBOOT']}"
															:disabled="deviceActionStates['REBOOT'] || null"
														>
															<span class="animate">
																<loader
																	v-if="deviceActionStates['REBOOT']"
																	style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
																></loader>
															</span>
															<span><i class="fal fa-redo"></i> ${T('Reboot')}</span>
														</button>
													</div>
													<div class="col-lg-6 col-xl-3 padding-xs-x">
														<button
															class="btn-loader twist-in col-xs-24"
															v-on:click="doDeviceAction('RESET_PASSWORD')"
															:class="{'btn-loading': deviceActionStates['RESET_PASSWORD']}"
															:disabled="deviceActionStates['RESET_PASSWORD'] || null"
														>
															<span class="animate">
																<loader
																	v-if="deviceActionStates['RESET_PASSWORD']"
																	style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
																></loader>
															</span>
															<span><i class="fal fa-redo"></i> ${T('Reset password')}</span>
														</button>
													</div>
													<div 
														class="col-lg-6 col-xl-3 padding-xs-x" 
														v-if="hasLocationPlugin && device.ownership != 'PROFILE_OWNER'"
													>
														<button
															class="btn-loader twist-in col-xs-24"
															v-on:click="doDeviceAction('LOCATION')"
															:class="{'btn-loading': deviceActionStates['LOCATION']}"
															:disabled="deviceActionStates['LOCATION'] || null"
														>
															<span class="animate">
																<loader
																	v-if="deviceActionStates['LOCATION']"
																	style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
																></loader>
															</span>
															<span><i class="fal fa-search"></i> ${T('Locate')}</span>
														</button>
													</div>
													<div class="col-lg-6 col-xl-3 padding-xs-x">
														<button
															class="btn-loader twist-in col-xs-24"
															v-on:click="toggleDeviceState()"
															:class="{'btn-loading': changingDeviceState }"
															:disabled="changingDeviceState || null"
														>
															<span class="animate">
																<loader
																	v-if="changingDeviceState"
																	style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
																></loader>
															</span>
															<span>
																<template v-if="device.state == 'ACTIVE'">
																	<i class="fal fa-toggle-off"></i> ${T('Disable Device')}
																</template>
																<template v-else>
																	<i class="fal fa-toggle-on"></i> ${T('Activate Device')}
																</template>
															</span>
														</button>
													</div>

													<div class="col-lg-6 col-xl-3 padding-xs-x" v-if="device.managementMode == 'PROFILE_OWNER' && device.ownership == 'COMPANY_OWNED'">
														<button
															class="btn-loader twist-in col-xs-24"
															v-on:click="doDeviceAction('RELINQUISH_OWNERSHIP')"
															:class="{'btn-loading': deviceActionStates['RELINQUISH_OWNERSHIP']}"
															:disabled="deviceActionStates['RELINQUISH_OWNERSHIP'] || null"
														>
															<span class="animate">
																<loader
																	v-if="deviceActionStates['RELINQUISH_OWNERSHIP']"
																	style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"
																></loader>
															</span>
															<span><i class="fal fa-search"></i> ${T('Relinquish ownership')}</span>
														</button>
													</div>

												</div>
											</div>
											<div class="col-xs-24">
												<div class="row">
													<div class="col-xs-24 padding-xs-t">
														<template v-if="deviceLocation.lat && deviceLocation.long && !deviceLocation.loading && deviceLocation.errors.length == 0">
															<googleMap
																id="locateDevice"
																:entries="[{
																	'position':{
																		'lat':deviceLocation.lat,
																		'lng':deviceLocation.long,
																		'accuracy':2
																	},
																	'infoHtml':T('Last update:') + ' ' + moment(deviceLocation.timestamp * 1000).format('DD.MM.YYYY HH:mm')
																}]"
															/>
														</template>
														<template v-if="!deviceLocation.loading && deviceLocation.errors.length > 0">
															<template v-for="error in deviceLocation.errors">
																<p class="error-bubble label bg-red margin-xs-t">
																	{{ T(getLocationError(error)) }}
																</p>
															</template>
														</template>
														<template v-if="deviceLocation.loading">
															<p class="text-size-2 text-center">
																<loader/>
															</p>
														</template>

													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div :class="{'hidden':activeTab != 'policy'}" class="tab-pane" id="device-policy">
									<div class="box-content-2">
										<div class="row">
											<div class="col-xs-24">
												<div class="row">
													<div class="col-xs-24">
														<h4>${T('Policy')}</h4>
														<span></span>
													</div>
												</div>
											</div>
											<div class="col-xs-24">
												<div class="row padding-xs-y form-group border-bottom" v-if="device != undefined && initialized">
													<div class="first col-xs-24 col-lg-4 col-xl-5">
														<label class="control-label inputname" for="policypicker">
															{{ T('Device policy') }}
														</label>
													</div>
													<div class="input col-xs-20 col-lg-8 col-xl-7">
														<label class="form-field margin-xs-b-0 select">
															<select v-model="thisDevicePolicyName" id="policypicker" :disabled="policyLoading">
																<option v-for="policy in policies" :value="policy.id">{{ getPolicyName(policy.text) }}</option>
															</select>
														</label>
													</div>
													<button
														class="btn margin-md-0 btn-loader twist-in
														col-xs-1 col-lg-4 col-xl-2 flex-shrink"
														v-on:click="saveDevicePolicy()"
														:class="{ 'btn-loading': policyLoading }"
														:disabled="policyLoading"
														style="min-width: 32px; max-width: 100px;"
													>
													<span class="animate">
														<loader v-if="policyLoading" style="font-size: 1.5em;top:2px;"></loader>
													</span>
													<span><i class="fal fa-save"></i> <span class="display-xs-none display-lg-inline">${T('Save')}</span></span>
													</button>
													<div class="desc col-xs-24 col-lg-8 col-xl-10">		
														<p class="input-description">
															${T('Choose a policy for this device.')}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div :class="{'hidden':activeTab != 'nonCompliance'}" class="tab-pane" id="nonComplianceDetails">
									<div class="box-content-2">
										<div class="row">
											<div class="col-xs-24">
												<div class="row">
													<div class="col-xs-24">
														<h4>${T('Non compliance details')}</h4>
														<span></span>
													</div>
												</div>
											</div>
											<div class="col-xs-24">
												<table2
													ref="nonComplianceTable"
													class="nonComplianceTable"
													:table="nonComplianceTableData.table"
													:loading="nonComplianceTableData.loading"
													:minheight="50"
												></table2>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="padding-xs-y-4"></div>
		</section>
	`,
	props: {

	},
	methods: {
		getAliasedShortDeviceId: deviceHelpers.getAliasedShortDeviceId,
		isObjectEmpty: jsonHelpers.isObjectEmpty,
		moment:moment,
		setActiveTab:function(this:any,tab:string) {
			this.activeTab = tab
		},
		getPolicyName:function(title:string) {
			return title.replace(/\^(.*)/," ($1)")
		},
		setContent: function(this:any,ContentFromItem:string) {
			if (this.device) {
				return ContentFromItem
			}
			else {
				return '<span class="content-placeholder" style="width:'+numberHelpers.getRandomArbitrary(50,250)+'px;" title="' + T("Loading...") + '"></span>'
			}
		},
		getDeviceDetails: async function(this:any,refresh:boolean = false) {
			let thisComponent : any = this
			try {

				await useStore().dispatch(ActionTypes.getObjectInfos, {
					"accountId":this.$root.activeAccountId,
					"objectTypes":["enterpriseProfiles"]
				})
				let device : any = await useStore().dispatch(ActionTypes.getObjectInfo, {
					"accountId":this.$root.activeAccountId,
					"productType":"mobileSecurity",
					"objectType":"enterpriseDevices",
					"objectId":useRouterStore().getObjectId || ""
				})
/*
				if(device == false) {
					router.navigate('404-' + this.objectId)
				}
*/
				device = await useStore().getters.getObject({
					"accountId":this.$root.activeAccountId,
					"productType":"mobileSecurity",
					"objectType":"enterpriseDevices",
					"objectId":useRouterStore().getObjectId || ""
				})

				let deviceInfo : any = device || undefined
				device.properties = device.inventory || {}

				if (tenantHelpers.hasFunctionality(this.activeAccountId, 'zerotouch')) {
					await useStore().dispatch(ActionTypes.getObjectInfos, {
						"accountId": this.activeAccountId,
						"objectTypes": ['zeroTouchDevices']
					})
				}

				let activeAccountId = useStore().state.session.activeAccountId
				if (!activeAccountId) {
					throw "Missing accountId"
				}

				let policies : any = await queries.mobileSecurity.getObjectInfos(activeAccountId,'enterpriseProfiles',[{'property':"fields",'value':'*(name)'}])

				if (typeof deviceInfo.enrollmentTokenData == "string") {
					try {
						deviceInfo.enrollmentTokenData = JSON.parse(deviceInfo.enrollmentTokenData)
					}
					catch(e:any) {
						deviceInfo.enrollmentTokenData = undefined
					}
				}

				this.policies = []
				for(let policyIndex in policies.policies) {
					if(policies.policies[policyIndex].name.split('/')[3] != 'unlicensed') {
						this.policies.push({
							'id':policies.policies[policyIndex].name,
							'text':policies.policies[policyIndex].name.split('/')[3]
						})
					}
				}

				this.propertiesForm = {
					billNumber: device.properties.billNumber || "",
					billDate: device.properties.billDate ? moment.unix(device.properties.billDate).format("DD.MM.YYYY") : "",
					hardwareDescription: device.properties.hardwareDescription || "",
					contractNumber: device.properties.contractNumber || "",
					providerCustomerNumber: device.properties.providerCustomerNumber || "",
					providerName: device.properties.providerName || "",
					providerTariff: device.properties.providerTariff || "",
					inventoryExternalLink: device.properties.inventoryExternalLink || "",
					contractDate: device.properties.contractDate ? moment.unix(device.properties.contractDate).format("DD.MM.YYYY") : "",
					contractDuration: device.properties.contractDuration || "",
					handoverDate: device.properties.handoverDate ? moment.unix(device.properties.handoverDate).format("DD.MM.YYYY") : "",
					warrantyStartDate: device.properties.warrantyStartDate ? moment.unix(device.properties.warrantyStartDate).format("DD.MM.YYYY") : "",
					warrantyEndDate: device.properties.warrantyEndDate ? moment.unix(device.properties.warrantyEndDate).format("DD.MM.YYYY") : "",
				}

				for(let property in device) {
					this.device[property] = device[property]
				}

				this.thisDevicePolicyName = this.device.policyName
				this.initialized = true

				nextTick(function() {
					thisComponent.getApplicationReportTableData()
					thisComponent.getNonComplianceTableData()
				})
			}
			catch(e:any) {
				if (e.status == 404) {
					router.navigate('404-' + this.objectId)
				}
			}
		},
		getInventoryLocationDialog: function(this:any) {

			dialogs.unifiedSecurity.setInventoryLocation(
				this.device?.inventory?.inventoryLocation || {},
				async (inventoryLocation, inventoryLocationDescription) => {
					if (!jsonHelpers.isObjectEmpty(inventoryLocation)) {
						await useStore().dispatch(ActionTypes.updateObjectInventory, {
							"accountId": this.activeAccountId,
							"productType": 'mobileSecurity',
							"objectType": 'enterpriseDevices',
							"objectId": this.device.id,
							"object": { inventoryLocation: JSON.stringify(inventoryLocation) }
						})
						return true
					}
					else {
						return false
					}
				},
				this.device?.inventory?.inventoryLocationDescription
			)
		},
		toggleDeviceState: async function(this:any) {
			this.changingDeviceState = true
			if(this.device.state == "ACTIVE") {
				try {
					await useStore().dispatch(ActionTypes.updateObjectProperty,{
						"accountId":this.activeAccountId,
						"productType":"mobileSecurity",
						"objectType":"enterpriseDevices",
						"queryType":"enterpriseDevices",
						"objectPropertyPath":[],
						"objectId":this.device.id,
						"object":{
							"state":"DISABLED"
						},
						"property":"state",
						"value":"DISABLED"
					})
					this.device.state = "DISABLED"
				}
				catch(e:any) {
					console.error(e)
				}

			}
			else {
				try {
					await useStore().dispatch(ActionTypes.updateObjectProperty,{
						"accountId":this.activeAccountId,
						"productType":"mobileSecurity",
						"objectType":"enterpriseDevices",
						"queryType":"enterpriseDevices",
						"objectPropertyPath":[],
						"objectId":this.device.id,
						"object":{
							"state":"ACTIVE"
						},
						"property":"state",
						"value":"ACTIVE"
					})
					this.device.state = "ACTIVE"
				}
				catch(e:any) {
					console.error(e)
				}
			}
			this.changingDeviceState = false
		},
		initContractform: function() {
			let thisComponent : any = this
			if (!thisComponent.contractDataLoaded) {
				let contract : any = {}
				setTimeout(function() {
					let datepicker : any = {}
					let count : number = 1
					$('[data-plugin="datepicker"]').each(function(this : any) {
						datepicker['datepicker-'+count] = (<any>$(this)).datepicker(<any>{
							dateFormat: 'dd.mm.yyyy',
							onSelect: function(formattedDate : any, date : any, inst : any) {
								thisComponent.propertiesForm[inst.$el[0].id] = formattedDate
								inst.hide()
							}
						}).data('datepicker')
						count++
					})
					$('main').on('scroll', function () {
						for(let index in datepicker) {
							if (datepicker[index].visible) {

								var val = datepicker[index].el.value
								datepicker[index].update();
								datepicker[index].el.value = val
							}
						}
					})
				}, 100)
				thisComponent.contractDataLoaded = true
			}
		},
		saveContractData: async function() {
			let thisComponent : any = this
			thisComponent.savingProperties = true
			let tenantDomain : any = thisComponent.activeTenantDomain
			let deviceId : any = thisComponent.objectId
			let properties : any = thisComponent.propertiesForm
			if (properties.billDate) {
				properties.billDate = moment(properties.billDate, "DD.MM.YYYY").format('X')
			}
			if (properties.contractDate) {
				properties.contractDate = moment(properties.contractDate, "DD.MM.YYYY").format('X')
			}
			if (properties.handoverDate) {
				properties.handoverDate = moment(properties.handoverDate, "DD.MM.YYYY").format('X')
			}
			if (properties.warrantyStartDate) {
				properties.warrantyStartDate = moment(properties.warrantyStartDate, "DD.MM.YYYY").format('X')
			}
			if (properties.warrantyEndDate) {
				properties.warrantyEndDate = moment(properties.warrantyEndDate, "DD.MM.YYYY").format('X')
			}
			if (properties.inventoryExternalLink.indexOf('http://') == -1 && properties.inventoryExternalLink.indexOf('https://') == -1) {
				properties.inventoryExternalLink = 'http://' + properties.inventoryExternalLink
			}

			await objectStores.enterpriseDevices.updateObjectFromApi(thisComponent.activeAccountId,deviceId,thisComponent.device,true,true,properties)

			await thisComponent.getDeviceDetails()
			thisComponent.savingProperties = false
		},
		T: function(content : string) {
			return T(content)
		},
		getDate: function(timestamp : any, dateTime : boolean = false) {
			let date : any = ""
			if (dateTime) {
				date = moment.unix(timestamp).format("DD.MM.YYYY HH:mm")
			}
			else {
				date = moment.unix(timestamp).format("DD.MM.YYYY")
			}
			return date;
		},
		getBatteryColor: function(percentage : number) {
			let batteryPercentage : number = Number(Math.max(Math.min(percentage,100),0).toFixed(2)) || -1;
			let quarter : number = percentage > 0 ? Math.floor(percentage / 25) : 0;
			let batteryColor : string = "color-gray";
			switch(quarter) {
				case 0:
					batteryColor = "color-red";
					break
				case 1:
					batteryColor = "color-red";
					break
				case 2:
					batteryColor = "color-yellow";
					break
				case 3:
					batteryColor = "color-green";
					break
				case 4:
					batteryColor = "color-green";
					break
			}
			return batteryColor
		},
		getStoragePercentage: function(storageValue : number ,storageMax : number ) {
			let storagePercentage : number = 0;
			if (storageValue != undefined && storageMax != undefined) {
				try {
					if (storageValue == storageMax && storageMax == 0) {
						storagePercentage = 100;
					}
					else {
						storagePercentage = 100 - Math.ceil(storageValue/storageMax*100);
					}
				} catch(e:any) {}
			}
			return storagePercentage
		},
		bytesToSize: function(bytes : any) {
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
			if (bytes === 0) return 'n/a'
			const i = Math.ceil(Math.floor(Math.log(bytes) / Math.log(1024)))
			if (i === 0) return `${bytes} ${sizes[i]})`
			return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
		},
		setThWidth: function(ref : string) {
			let thisComponent : any = this
			setTimeout(function() { thisComponent.$refs[ref].setThWidth() }, 100)
		},
		doDeviceAction: async function(this:any,deviceAction : string) {
			let thisComponent : any = this
			
			if(deviceAction == 'RESET_PASSWORD') {

				let newModal: any = {
					"id": "resetPassword",
					"abortable": true,
					"content": {
						"title": {
							"text": T("Reset password")
						},
						"body": {
							"use": true,
							"content": undefined,
							"component": "device-password-reset"
						}
					},
					"onAbort":function() {
						thisComponent.deviceActionStates[deviceAction] = false
					},
					"buttons": [
						{
							"text": T("Cancel"),
							"icon": "fal fa-times",
							"onClick": async function () {
								thisComponent.deviceActionStates[deviceAction] = false
								vueApp.$refs.modals.removeModal()
							},
							"align": "left",
							"loading": false
						},
						{
							"text": T("Save"),
							"icon": "fal fa-fw fa-save",
							"onClick": async function (modal:any) {
								thisComponent.deviceActionStates[deviceAction] = true
								this.disabled = true

								let deviceActionObj: any = {
									"type": "RESET_PASSWORD",
									"resetPasswordFlags": []
								}

								if (modal.$refs.modalComponent.newPassword != "") {
									deviceActionObj.newPassword = modal.$refs.modalComponent.newPassword
								}
								if (modal.$refs.modalComponent.REQUIRE_ENTRY) {
									deviceActionObj.resetPasswordFlags.push("REQUIRE_ENTRY")
								}
								if (modal.$refs.modalComponent.DO_NOT_ASK_CREDENTIALS_ON_BOOT) {
									deviceActionObj.resetPasswordFlags.push("DO_NOT_ASK_CREDENTIALS_ON_BOOT")
								}
								if (modal.$refs.modalComponent.LOCK_NOW) {
									deviceActionObj.resetPasswordFlags.push("LOCK_NOW")
								}

								try {
									await requestHandler.request("POST", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('androidEnterprise') +"/tenants/" + thisComponent.$root.activeTenantDomain + "/android/emm/enterprise/devices/" + thisComponent.$root.objectId, deviceActionObj)
									vueApp.$refs.modals.removeModal()
									thisComponent.deviceActionStates[deviceAction] = false
								}
								catch(e:any) {
									console.error(e)
									thisComponent.deviceActionStates[deviceAction] = false
								}
								
							},
							"align": "right",
							"disabled": false,
							"loading": false
						}
					]
				}

				vueApp.$refs.modals.addModal(newModal)

			}
			else if(deviceAction == 'LOCATION') {
				dialogs.misc.inputDialog(thisComponent.activeAccountId, "Message Text", "", async (message: string) => {
					try {
						thisComponent.deviceActionStates[deviceAction] = true
						await requestHandler.request(
							"POST", 
							"/sms-mgt-api/api/" + apis.getApiVersionForFeature('androidEnterprise') + "/tenants/" + thisComponent.$root.activeTenantDomain + "/android/emm/enterprise/devices/" + thisComponent.$root.objectId, 
							message ? { "type": deviceAction, "payload": { "message": message } } : { "type": deviceAction, "payload": { "message": "Lokalisierung" } }
						)
						thisComponent.waitForLocation()
						setTimeout(function () {
							thisComponent.deviceActionStates[deviceAction] = false
						}, 5000)
					} 
					catch(e:any) {
						console.error(e)
						thisComponent.deviceActionStates[deviceAction] = false
					}
				},
				"text",
				(value) => {
					return value.length >= 3 && value.length < 512
				},
				"Message Text")
			}    
			else if(deviceAction == 'RELINQUISH_OWNERSHIP') {
				let deviceId = thisComponent.$root.objectId
				let accountId = thisComponent.activeAccountId
				dialogs.misc.confirmDialog(
					accountId,
					T("Confirm"),
					`
						<p><strong>${T("Do you really want to relinquish this device?")}</strong></p>
						<p>${T('This removes the work profile and all policies from a company-owned Android 8.0+ device, relinquishing the device for personal use. Apps and data associated with the personal profile(s) are preserved. The device will be deleted from the server after it acknowledges the command.')}</p>
					`,
					async function() {
						try {
							thisComponent.deviceActionStates[deviceAction] = true
							await requestHandler.request("POST", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('androidEnterprise') +"/tenants/" + thisComponent.$root.activeTenantDomain + "/android/emm/enterprise/devices/" + deviceId,{ "type": deviceAction })
							await timeHelpers.sleep(5000)
							thisComponent.deviceActionStates[deviceAction] = false
							thisComponent.$store.commit(MutationTypes.deleteObject, {
								"accountId": accountId,
								"productType": 'mobileSecurity',
								"objectType": 'devices',
								"objectIdProperty": 'deviceId',
								"objectId": deviceId
							})
							router.navigate('#show-tenant-' + thisComponent.$root.activeTenantDomain +'-android-devices-dashboard')
							
						} 
						catch(e:any) {
							console.error(e)
							thisComponent.deviceActionStates[deviceAction] = false
						}
					},
					T('RELINQUISH')
				)
			}    
			else {
				try {
					thisComponent.deviceActionStates[deviceAction] = true
					await requestHandler.request("POST", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('androidEnterprise') +"/tenants/"+thisComponent.$root.activeTenantDomain+"/android/emm/enterprise/devices/"+thisComponent.$root.objectId,{ "type": deviceAction })
					setTimeout(function() {
						thisComponent.deviceActionStates[deviceAction] = false
					},5000)

				} catch(e:any) {
					console.error(e)
					thisComponent.deviceActionStates[deviceAction] = false
				}
			}
		},
		getLocationError: function(errorMsg:string) {
			let errorMap : any = {
				"timeout": "Timeout: The device did not respond",
				"legal_not_accepted": "Terms and conditions or privacy policy not accepted",
				"permission_denied":"Location authorization is missing",
				"gps_disabled":"GPS / location is system-wide deactivated",
				"could_not_compute_location_was_null":"Android reported a location error",
				"could_not_compute_cancelled_by_android":"Android reported a location error",
				"could_not_compute_error_occurred":"Android reported a location error"
			}
			return errorMap[errorMsg] || "Unknown Error"
		},
		waitForLocation: async function(this:any) {
			this.deviceLocation.loading = true
			useStore().commit(MutationTypes.addSubscriptionHook,{
				"accountId":this.activeAccountId,
				"hookKey":"locationMessage",
				"hookFunction":async (message:any) => {
					
					if (message.topic == "/device/located" && message.data?.name.split('/')[3] == this.objectId) {
						let location = await this.getLocation()
						if(location?.longitude != undefined && location?.latitude != undefined && location?.errors != undefined) {
							this.deviceLocation.long = location.longitude
							this.deviceLocation.lat = location.latitude
							this.deviceLocation.errors = location.errors
							this.deviceLocation.loading = false
							this.deviceLocation.timestamp = message.timestamp
						}
						useStore().commit(MutationTypes.deleteSubscriptionHook,{
							"accountId":this.activeAccountId,
							"hookKey":"locationMessage"
						})
					}
				}
			})


			setTimeout(() => {
				if (this.deviceLocation.loading) {
					useStore().commit(MutationTypes.deleteSubscriptionHook, {
						"accountId": this.activeAccountId,
						"hookKey": "locationMessage"
					})
					this.deviceLocation.loading = false
					this.deviceLocation.errors = ["timeout"]
				}
			},600000)
		},
		getLocation: async function(this:any) {
			let location
			try {
				location = await requestHandler.request("GET", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('androidEnterprise') +"/tenants/"+this.activeTenantDomain+"/android/emm/enterprise/devices/"+this.objectId+"/location")
			}
			catch(e:any) {
				if(e.status == 404) {
				}
				else {
					console.error(e)
				}
			}
			return apis.parseApiResponse(location)
		},
		getNonComplianceTableData: async function (this:any) {
			let nonComplianceDetailsTableData : any = []
			this.nonComplianceTableData.loading = true

			this.nonComplianceTableData.table = {
				"titles":[
					{
						"id":"number",
						"text":"#",
						"sortable":true,
						"width":60
					},
					{
						"id":"setting",
						"text": T("Setting"),
						"sortable":true
					},
					{
						"id":"nonComplianceReason",
						"text": T("Non compliance reason"),
						"sortable":false
					},
					{
						"id":"packageName",
						"text": T("Packagename"),
						"sortable":true
					},
					{
						"id":"path",
						"text": T("Path"),
						"sortable":true
					},
					{
						"id":"value",
						"text": T("Value"),
						"sortable":false
					},
					{
						"id":"installationFailureReason",
						"text": T("Installation failure reason"),
						"sortable":false
					}
				],
				"rows": (<Array<any>>this.device.nonComplianceDetails || []).map((nonCompliance, nonComplianceIndex) => {
					return {
						data:{
							"number": nonComplianceIndex + 1,
							"setting": T(nonCompliance.settingName) || '',
							"nonComplianceReason": T(nonCompliance.nonComplianceReason) || '',
							"packageName": nonCompliance.packageName || '',
							"path": nonCompliance.fieldPath || '',
							"value": nonCompliance.currentValue || '',
							"installationFailureReason": T(nonCompliance.installationFailureReason) || ''
						}
					}
				})
			}

			this.nonComplianceTableData.loading = false
		},
		getApplicationReportTableData: async function (this:any) {
			if(this.$refs) {
				let thisComponent:any = this
				
				this.applicationReportTableData.loading = true
				if (this.device.applicationReports?.length > 0) {
					let index : number = 1;

					this.applicationReportTableData.table.titles = [
						{
							"id": "number",
							"text": "#",
							"sortable": true,
							"width": 60
						},
						{
							"id": "name",
							"text": T("Name"),
							"sortable": true
						},
						{
							"id": "packageName",
							"text": T("Packagename"),
							"sortable": true
						},
						{
							"id": "version",
							"text": "Version",
							"sortable": true
						},
						{
							"id": "status",
							"text": "Status",
							"sortable": true
						},
						{
							"id": "info",
							"text": "Info",
							"sortable": true
						}
					]

					this.applicationReportTableData.table.rows = (<Array<any>>this.device.applicationReports || []).map((application:any,index) => {
						return {
							data: {
								"number": index + 1,
								"name": T(application.displayName) || '',
								"packageName": application.packageName || '',
								"version": application.versionName || '',
								"status": application.state || '',
								"info": {
									"buttons":[
										application.keyedAppStates ? {
											"text": "Keyed app states",
											"title": "App states",
											"icon": "fal fa-fw fa-info",
											"onClick": async function () {

												if (application.keyedAppStates) {
													let tableData: any = {
														"loading": false,
														"titles": [
															{
																"id": "severity",
																"text": "Severity",
																"width": 110,
																"sortable": true
															},
															{
																"id": "lastUpdate",
																"text": "Last update",
																"sortable": true
															},
															{
																"id": "key",
																"text": "Key",
																"sortable": true
															},
															{
																"id": "message",
																"text": "Message",
																"sortable": false
															},
															{
																"id": "data",
																"text": "Data",
																"sortable": true
															},
														],
														"rows": (application.keyedAppStates || []).map((appState: any) => {
															return {
																data: {
																	"severity": {
																		"labels": [
																			{
																				"title": T(stringHelpers.capitalizeFirstLetter(appState.severity)),
																				"text": T(stringHelpers.capitalizeFirstLetter(appState.severity)),
																				"icon": appState.severity == 'ERROR' ? 'fal fa-exclamation-triangle' : 'fal fa-info-circle',
																				"class": appState.severity == 'ERROR' ? 'bg-red color-white' : 'bg-blue color-white',
																			}
																		]
																	},
																	"lastUpdate": moment(appState.lastUpdateTime).format('DD.MM.YY, HH:mm:ss'),
																	"key": encodingHelpers.escapeHTML(appState.key),
																	"message": encodingHelpers.escapeHTML(appState.message),
																	"data": encodingHelpers.escapeHTML(appState.data)
																}
															}
														})
													}


													let newModal: any = {
														"id": "keyedAppStates",
														"abortable": true,
														"content": {
															"title": {
																"text": T("Keyed app states")
															},
															"body": {
																"use": true,
																"content": undefined,
																"component": "key-app-states",
																"properties": {
																	tableData: tableData
																}
															}
														},
														"buttons": [
															{
																"text": T("Close"),
																"icon": "fal fa-times",
																"onClick": async function () {
																	vueApp.$refs.modals.removeModal()
																},
																"align": "right",
																"loading": false
															}
														]
													}
													vueApp.$refs.modals.addModal(newModal)
												}
											},
											"disabled": function () {
												let result: boolean = (application.keyedAppStates || []).length == 0
												return result
											}
										} : null
									]
								}
							}
						}
					})	
				}
				this.applicationReportTableData.loading = false
			}
		},
		getInfoBoxText(this:any) {
			let inventoryStreet: any = this.device?.inventory?.inventoryLocation?.street
			let inventoryPostalCode: any = this.device?.inventory?.inventoryLocation?.postalCode
			let inventoryCity: any = this.device?.inventory?.inventoryLocation?.city
			const content = `
					<div> 
						${inventoryStreet ? ('<i class="fal fa-fw fa-road"></i>&nbsp;' + encodingHelpers.escapeHTML(inventoryStreet) + '<br>') : ''}
						${inventoryPostalCode ? ('<i class="fal fa-fw fa-globe"></i>&nbsp;' + encodingHelpers.escapeHTML(inventoryPostalCode) + '<br>') : ''}
						${inventoryCity ? encodingHelpers.escapeHTML(inventoryCity) : ''}
					<div>
				`
			return content
		},
		saveDevicePolicy: async function (this: any) {
			let policyName = this.thisDevicePolicyName
			if (!this.policyLoading && this.initialized && this.policyName != "") {
				try {
					this.policyLoading = true
					let newPolicy: any = {
						"policyName": policyName
					}
				
					await useStore().dispatch(ActionTypes.updateObjectProperty, {
						"accountId": this.activeAccountId,
						"productType": "mobileSecurity",
						"objectType": "enterpriseDevices",
						"queryType": "enterpriseDevices",
						"objectPropertyPath": [],
						"objectId": this.device.id,
						"object": newPolicy,
						"property": "policyName",
						"value": policyName
					})
					this.device.policyName = this.thisDevicePolicyName
					
				}
				catch (e: any) {
					console.error(e)
				}
				finally {
					this.policyLoading = false
				}
			}
		}
	},
	watch: {
		darkmode: function(this:any) {
			this.getDeviceDetails()
		},
		device: {
			handler: function(device : any,deviceOld : any) {
				let thisComponent : any = this
				let tenantDomain : any = thisComponent.activeTenantDomain
				if (device != undefined) {
						 thisComponent.initContractform()
				}
			},
			deep:true
		},
	},
	created(this:any) {
	},
	mounted(this:any) {
		this.getDeviceDetails()
	},
	beforeUnmount(this:any) {
	},
	components: {
		"loader": loaderComponent,
		"device-inline-rename": deviceInlineRenameComponent,
		"inline-itemlist-input": inlineItemlistInputComponent,
		"regularly-updated-data": regularlyUpdatedDataComponent,
		"googleMap": googleMapComponent,
		"itemlist-item": itemlistItemComponent,
		"table2": table2Component
	}
}
export default emmDeviceDetailsComponent