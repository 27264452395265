import { useGlobalMixin } from "@/mixins/mixins.global";
import { useStore } from '@/store/vuex.store';
import loaderComponent from "../components/loader.vue";

const confirmDialogComponent = {
    mixins: [useGlobalMixin()],
    "name":"confirm-dialog",
    "template": `<div>
        <div
            v-if="properties.content"
            v-html="properties.content"
        ></div>
        <template v-if="properties.enterString">
            <p><span>{{ T('Please enter') }}</span> <strong>{{ properties.enterString }}</strong> <span>{{ T('Xto confirm') }}</span></p>
            <input 
                type="text" 
                v-on:keyup="onType()" 
                v-on:keyup.enter="submit()" 
                :placeholder="properties.enterString" 
                v-model="enteredString"
                ref="enterStringInput"
            />
        </template>
    </div>`,
    "data":() => {
        return {
            "enteredString":""
        }
    },
    "computed": {
        "activeAccountId": function(this:any) {
            return useStore().state.session.activeAccountId || undefined
        },
        "tenant":function(this:any) {
            return useStore().state.session.accounts[this.activeAccountId] || undefined
        },
        "routes":function(this:any) {
            return this.$router.options.routes
        }
    },
    "props": {
        "properties":{
            required:true,
            default:() => { return {
                "enterString":undefined,
                "content":undefined
            }}
        }
    },
    "methods": {
        "submit":function(this:any) {
            if(this.properties.enterString == this.enteredString) {
                if (useStore().getters.getActiveModal(this.activeAccountId).buttons[1] != undefined) useStore().getters.getActiveModal(this.activeAccountId).buttons[1].onClick()
            }
        },
        "onType":function(this:any) {
            if ((this.properties.enterString && this.properties.enterString == this.enteredString) || !this.properties.enterString) {
                if(useStore().getters.getActiveModal(this.activeAccountId).buttons[1] != undefined) useStore().getters.getActiveModal(this.activeAccountId).buttons[1].disabled = false
            }
            else {
                if (useStore().getters.getActiveModal(this.activeAccountId).buttons[1] != undefined) useStore().getters.getActiveModal(this.activeAccountId).buttons[1].disabled = true
            }
        }
    },
    "created":function(this:any) {
        this.onType()
    },
    "watch": {
    },
    "mounted": function(this:any) {
        setTimeout(() => {
            if(this.$refs.enterStringInput) {
                this.$refs.enterStringInput.focus();
            }
        }, 0)
    },
    "components": {
        "loader":loaderComponent
    },
}
export default confirmDialogComponent
