<script setup lang="ts">
  import { computed, nextTick, onMounted, reactive, ref, watch } from "vue";
  import devLog from "../../classes/log";
  import { useStore } from '@/store/vuex.store';
  import { ActionTypes } from "@/store/vuex.store";
  import i18n, { T } from "@/classes/i18n";
  import router from "@/router/router";
  import queries from "@/queries/queries";
  
  import mainFooterComponent from "../templates/main-footer";
  import accordionComponent from "../components/accordion.vue";
  import buttonComponent from "../components/button.vue";
  import inputCode from "../inputtypes/input-pin.vue";

  import encodingHelpers from "@/helpers/helpers.encoding";
  import tenantHelpers from "@/helpers/helpers.tenants";

  import logoIcon from "@/img/securepoint-icon-w.svg"
  import accounts from "@/modules/accounts";
  import useRouterStore from "@/router/routerStore";
  import loader from "../components/loader.vue";
import sessionHelpers from "@/helpers/helpers.session";
import unifiedSecurityQueries from "@/queries/unified.security";

  const accordion = ref(<null | typeof accordionComponent>null)
  const goingBackToLogin = ref(false)
    
  const loading = ref( false)
  const doRedirect = ref( false)
  const redirectPath = ref("/sms/")
  const domainFocus = ref(false)
  const usernameFocus = ref(false)
  const passwordFocus = ref(false)
  const noRolesAndAccounts = reactive({
    "noAccounts": false,
    "noRoles": false
  })
  const errorMsg = ref(T('Something went wrong, please try again.')) 

  const twoFaUserError = ref(false)

  const lang = ref("en")

  const form = ref({
    "error": false,
    "totpError":false,
    "passwordResetError":false,
    "inputData": {
      "domain": "",
      "username": "",
      "password": "",
      "rememberme": false,
      "code":""
    }
  })
  
  const account = computed(() => {
    return {
      "userInfo": useStore().state.session.userInfo
    }
  })
  
  const context = computed(() => {
    return useRouterStore().getContext
  })

  const login = async (e?: any) => {
      let success: boolean = false
      if (e) {
        e.preventDefault()
      }
      loading.value = true
      form.value.error = false
      form.value.totpError = false
      twoFaUserError.value = false
      form.value.passwordResetError = false
      devLog.log('Login', 'Trying to sign in...', undefined, 'default', 3)
      let domain: string = form.value.inputData.domain.trim()
      let username: string = form.value.inputData.username.trim()
      let password: string = form.value.inputData.password
      let rememberme: boolean = form.value.inputData.rememberme
      let code: string = form.value.inputData.code
      let usernameWithDomain: string = username

      if (domain) {
        domain = /\.sms$/.test(domain) ? domain : domain + ".sms"
        usernameWithDomain = username + "@" + domain
      }
      try {
        var login = await queries.unifiedSecurity.login(usernameWithDomain, password, rememberme,code)
        var userInfo = await queries.unifiedSecurity.checkAuth()
        if (userInfo) {
          if(userInfo == "2FA_REQUIRED") { throw "2FA_REQUIRED" }
          devLog.log('Login', 'User successfully signed in', undefined, 'success', 3)

          if (!sessionHelpers.has2FAActive(userInfo)) {
            console.log("no 2fa")
          }

          proceedWithUserInfo(userInfo)
        }
        else {
          throw "Got no userinfo"
        }
      }
      catch (e:any) {
        devLog.log('Login', 'Login failed', e, 'error', 3)
        form.value.inputData.code = ""
        if(e.responseJSON) { e = e.responseJSON }
        if (e.data) { e = e.data }

        if (e.data == "TOTP-Token is missing.") {
          useRouterStore().setContext("enter2FA")
        }
        else if (e.data == "TOTP-Token is invalid.") {
          useRouterStore().setContext("enter2FA")
          form.value.inputData.code = ""
          form.value.totpError = true
          errorMsg.value = getErrorMessage(e.data)
        }
        else if (e.data == "RSP_2FA_REQUIRED" || e == "RSP_2FA_REQUIRED") {
          useRouterStore().setContext("rsp2faError")
        }
        else if (e.data == "2FA_REQUIRED" || e == "2FA_REQUIRED") {
          twoFaUserError.value = true
        }
        
        else {
          errorMsg.value = getErrorMessage(e.data)
          form.value.error = true
        }

        loading.value = false
      }

    }
    // #34465
    const getErrorMessage = (errorMessage: unknown) => {
      let returnMessage = T('Something went wrong, please try again.')
      if (typeof errorMessage == "string") {
        const errorMap: Record<string, string | undefined> = {
          "TOTP-Token is invalid.": "TOTP-Token is invalid.",
          "Wrong code. Please try again.": "Wrong code. Please try again.",
          "Missing username, password": "Missing username and password.",
          "Missing username": "Missing username.",
          "Username is missing.": "Missing username.",
          "Missing password": "Missing password."
        }
        
        const error = errorMap[errorMessage]
        if (error) {
          returnMessage = T(error)
        }
        else if (errorMessage == "Tenant '" + form.value.inputData.domain + "' is inactive.") {
          returnMessage = T("Tenant %s is inactive.").replace("%s", form.value.inputData.domain)
        }
        else if (errorMessage == "Cannot authenticate user " + form.value.inputData.username + " for tenant " + form.value.inputData.domain + ". Check your tenant, password and username.") {
          returnMessage = T("Cannot authenticate user %s for tenant %y. Check your tenant, password and username.").replace("%s", form.value.inputData.username).replace("%y", form.value.inputData.domain)
        }
        else if (errorMessage == "Cannot authenticate user " + form.value.inputData.username + " for tenant carbon.super. Check your tenant, password and username.") {
          returnMessage = T("Cannot authenticate user %s for tenant. Check your tenant, password and username.").replace("%s", form.value.inputData.username)
        }
      }
      else if (noRolesAndAccounts.noAccounts == true || noRolesAndAccounts.noRoles == true) {
        if (noRolesAndAccounts.noAccounts == true && noRolesAndAccounts.noRoles == true) {
          returnMessage = T("This Account cannot login due to missing roles and accounts.")
        }
        else if (noRolesAndAccounts.noAccounts == true) {
          returnMessage = T("This account cannot login due to missing accounts.")
        }
        else if (noRolesAndAccounts.noRoles == true) {
          returnMessage = T("This account cannot login due to missing roles.")
        }
        goBackToLogin()
      }
      noRolesAndAccounts.noAccounts = false
      noRolesAndAccounts.noRoles = false
      return returnMessage;
    }

    const proceedWithUserInfo = async (userInfo: any) => {
      await accounts.getAccounts(userInfo)
      let requestedRouteName: string = "tenant-dashboard"
      let requestedObjectId: any = undefined
      let activeAccountid: any = undefined
      let requestedAccountid: any = undefined

      // if no accountid is set by redirect
      if (userInfo?.targetDomain) {
        requestedAccountid = tenantHelpers.getAccountId(userInfo.targetDomain)
      }
      else if (userInfo?.domain) {
        requestedAccountid = tenantHelpers.getAccountId(userInfo.domain)
      }
      else if (requestedAccountid == undefined && userInfo?.scopes?.list_accounts?.length) {
        requestedAccountid = userInfo.scopes.list_accounts[0].accountid
      }
      
      doRedirect.value = true

      setTimeout(function () {
        router.navigate("show-tenant-" + requestedAccountid+".sms-dashboard")
      }, 500)
    }


    const goToRspTwoFaSetup = () => {
      window.location.href = 'https://my.securepoint.de/users/me/2fa-setup'
    }

    const sendPasswordResetMail = async () => {
      twoFaUserError.value = false
      form.value.passwordResetError = false
      try {
        loading.value = true
        let result = await unifiedSecurityQueries.resetPassword(form.value.inputData.domain.trim(),form.value.inputData.username.trim())
        useRouterStore().setContext("sentResetEmail")
        loading.value = false
      }
      catch(e:any) {
        form.value.passwordResetError = true
        if(e.data.code == 429) {
          errorMsg.value = T("Too many requests. Please try again later.")  
        }
        else {
          errorMsg.value = T('Something went wrong, please try again.')
        }
        loading.value = false
      }
    }



    const goBackToLogin = () => {
      goingBackToLogin.value = true
      useRouterStore().setContext(undefined)
      setTimeout(() => {
        goingBackToLogin.value = false
      },500)
    }

    onMounted(() => {
      let path = location.pathname
      let hash = location.hash

      if (hash != '#login') {
        redirectPath.value = path+hash
      }
      console.log(i18n)
      lang.value = i18n.getLanguage()
    })

    watch(() => form.value.inputData.code,() => {
      if(form.value.inputData.code.length == 6 && context.value == "enter2FA") {
        login()
      }
    })

    defineExpose({
      noRolesAndAccounts, twoFaUserError
    })

</script>
<template>
  <div>
    <div key="bg" id="loginBg"></div>

    

      <TransitionGroup :name="goingBackToLogin ? 'loginBoxReverse' : 'loginBox'" appear>
        <template v-if="context == undefined">
          <div class="login-wrapper" style="max-width: 555px">
          <div class="box-shadow">
            <header id="main-header">
                <div id="logoarea">
                    <a href="#" class="logo-small">
                        <img :src="logoIcon" class="logo">&nbsp;
                        <span class="app-name">Unified Security</span>
                    </a>
                </div>
            </header>
            <main>              
              <div class="content-2">
                  <div class="row">
                      <div class="col-xs-24">
                          <div class="content text-center">
                              <p class="text-bold">
                                {{ T('Sign in with a Reseller Account:') }}
                              </p>
                              <div
                                  v-if="account.userInfo.oauth2 && account.userInfo.validLicenses.length == 0"
                                  class="notification color-white bg-red content text-center"
                                  id="oauth-login-failed-message"
                              >
                                {{ T('No valid license found.') }}
                              </div>
                              <a
                                  id="oAuthLogin"
                                  :href="'/oauth2/start?rd='+encodingHelpers.encodeURI(redirectPath)"
                                  class="btn btn-primary btn-loader twist-in btn-lg text-center margin-xs-t-2"
                              >
                                  <span class="animate" v-if="loading">
                                      <i class="progress-circular no-progress" style="font-size: 1.5em;top:2px;">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 50 50" style="stroke-width: 4px;">
                                              <circle cx="25" cy="25" r="20" style="stroke: rgba(0, 0, 0, 0.1); fill: none;"></circle>
                                              <g transform="translate(25,25) rotate(-90)">
                                                  <circle stroke-dasharray="110" stroke-dashoffset="0" cx="0" cy="0" r="20" style="fill: none; stroke-linecap: round;" transform="rotate(14.0181)">
                                                      <animate attributeName="stroke-dashoffset" values="360;140" dur="2.2s" keyTimes="0;1" calcMode="spline" fill="freeze" keySplines="0.41,0.314,0.8,0.54" repeatCount="indefinite" begin="0"></animate>
                                                      <animate attributeName="stroke" fill="freeze" dur="8s" begin="0" repeatCount="indefinite"></animate>
                                                  </circle>
                                              </g>
                                          </svg>
                                      </i>
                                  </span>
                                  <span>
                                      <span class="btn-prefix"><i class="fal fa-sign-in-alt"></i></span>
                                      <span>
                                        {{ T('Sign in') }}
                                      </span>
                                  </span>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="content-2" v-if="form.error">
                <div
                    id="user-login-failed-message"
                    class="notification color-white bg-red content text-center"
                >
                  {{ errorMsg }}
                </div>
              </div>

              <div class="content-2" v-if="twoFaUserError">
                <div
                    id="user-login-failed-message"
                    class="notification color-white bg-red content text-center"
                >
                {{ T("From version 1.24, login is only possible with activated 2-factor authentication (2FA).") }} <a class="color-white text-underline hover-color-white" v-on:click="() => { useRouterStore().setContext('resetPassword')}">{{ T('Request a password reset email now') }}</a><template v-if="lang == 'en'">&nbsp;</template>{{ T("to activate 2FA.") }}
              </div>
              </div>
              <accordionComponent class="content-2" ref="accordion">
                <template v-slot:title>
                  {{ T('Sign in with your Securepoint Unified Security Account') }}
                </template>
                <template v-slot:content>
                  <div>
                    <form autocomplete="on" method="POST" @submit.prevent="login">
                        
                        
                        <label class="form-field" :class="{'focus':domainFocus}">
                            <span class="form-text-prefix text-center">
                                <i class="fal fa-globe"></i>
                            </span>
                            <input 
                              id="login-domain" 
                              type="text" 
                              class="form-control" 
                              :placeholder="T('Tenant domain (e.g. 123456.sms)')" 
                              autofocus 
                              v-model="form.inputData.domain"
                              @focus="domainFocus = true"  
                              @blur="domainFocus = false"  
                            >
                        </label>
                        <label class="form-field" :class="{'focus':usernameFocus}">
                            <span class="form-text-prefix text-center">
                                <i class="fal fa-user"></i>
                            </span>
                            <input
                                id="username"
                                name="username"
                                autocomplete="username"
                                type="text"
                                class="form-control"
                                :placeholder="T('Username')"
                                v-model="form.inputData.username"
                                @focus="usernameFocus = true"
                                @blur="usernameFocus = false"
                            >
                        </label>
                        <label class="form-field" :class="{'focus':passwordFocus}">
                            <span class="form-text-prefix text-center">
                                <i class="fal fa-key"></i>
                            </span>
                            <input
                                id="password"
                                autocomplete="password"
                                type="password"
                                name="password"
                                class="form-control"
                                :placeholder="T('Password')"
                                v-model="form.inputData.password"
                                @focus="passwordFocus = true"
                                @blur="passwordFocus = false"
                            >
                        </label>



                        <div class="row flexrow">
                            <div class="col-xs padding-xs-t padding-xs-x">
                                <a v-on:click="() => { 
                                  useRouterStore().setContext('resetPassword')
                                }">{{ T('Forgot password?') }}</a>
                            </div>
                            
                            <div class="col-xs text-right padding-xs-x">
                                <label class="form-field margin-xs-0 toggle checkbox">
                                    <input id="login-rememberme" type="checkbox" class="toggle margin-xs-0" v-model="form.inputData.rememberme">&nbsp;&nbsp;
                                    <span></span>
                                    {{ T('Remember me') }}
                                </label>
                            </div>
                            <div class="col-xs flex-shrink padding-xs-x">
                                <label class="form-field text-right margin-xs-0">
                                    <button id="login-action" type="submit" class="btn btn-primary btn-loader twist-in" :class="{'btn-loading':loading}">
                                      <span class="animate" v-if="loading">
                                          <i class="progress-circular no-progress" style="font-size: 1.5em;top:2px;">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 50 50" style="stroke-width: 4px;">
                                                  <circle cx="25" cy="25" r="20" style="stroke: rgba(0, 0, 0, 0.1); fill: none;"></circle>
                                                  <g transform="translate(25,25) rotate(-90)">
                                                      <circle stroke-dasharray="110" stroke-dashoffset="0" cx="0" cy="0" r="20" style="fill: none; stroke-linecap: round;" transform="rotate(14.0181)">
                                                          <animate attributeName="stroke-dashoffset" values="360;140" dur="2.2s" keyTimes="0;1" calcMode="spline" fill="freeze" keySplines="0.41,0.314,0.8,0.54" repeatCount="indefinite" begin="0"></animate>
                                                          <animate attributeName="stroke" fill="freeze" dur="8s" begin="0" repeatCount="indefinite"></animate>
                                                      </circle>
                                                  </g>
                                              </svg>
                                          </i>
                                      </span>
                                        <span><i class="fal fa-sign-in-alt"></i>&nbsp;&nbsp;{{ T('Sign in') }}</span>
                                    </button>
                                </label>
                            </div>
                        </div>
                    </form>
                  </div>
                </template>
              </accordionComponent>
            </main>
            <mainFooterComponent key="footer" :showAppName="false" ref="footer"/>
        </div>
        </div>
        </template>

        <template v-if="context == 'enter2FA'">
          <div class="login-wrapper" style="max-width: 555px">
          <div class="box-shadow">
            <header id="main-header">
                <div id="logoarea">
                    <a href="#" class="logo-small">
                        <img :src="logoIcon" class="logo">&nbsp;
                        <span class="app-name">Unified Security</span>
                    </a>
                </div>
            </header>
            <main>
              <div class="content-2">
                <div class="row">
                  <div class="col-xs-24">
                    <div class="content text-center padding-xs-y-4">
                      <template v-if="loading">
                        <loader class="color-red text-size-4"></loader>
                      </template>
                      <template v-else>
                        <p class="text-bold">
                          {{ T("Two-Factor-Authentication") }}
                        </p>
                        <p class="margin-xs-y-2">
                          {{ T("Open the two-factor-authentication app on your device to view your authentication code.") }}
                        </p>
                        <div class="content-2" v-if="form.totpError">
                          <div
                              id="user-login-failed-message"
                              class="notification color-white bg-red content text-center"
                          >
                            {{ errorMsg }}
                          </div>
                        </div>
                        <p style="display: block;width: fit-content; margin:0 auto">
                          <inputCode 
                            :numbers-only="true" 
                            :focus-on-mount="true" 
                            :pin-length="6" 
                            v-model="form.inputData.code"
                            :totp="true"
                          ></inputCode>
                        </p>
                      </template>
     
                    </div>
                  </div>
                </div>
                <div class="row margin-xs-t-2">
                  <div class="col-xs-24 text-center">
                    <buttonComponent
                      :button-options="{
                        'icon':'fal fa-arrow-left',
                        'text':T('Back to login'),
                        'onClick':goBackToLogin
                      }"
                    ></buttonComponent>
                  </div>
                </div>
              </div>
            </main>
          </div>
          </div>
        </template>
        <template v-if="context == 'resetPassword'">
          <div class="login-wrapper" style="max-width: 555px">
          <div class="box-shadow">
            <header id="main-header">
                <div id="logoarea">
                    <a href="#" class="logo-small">
                        <img :src="logoIcon" class="logo">&nbsp;
                        <span class="app-name">Unified Security</span>
                    </a>
                </div>
            </header>
            <main>
              <div class="content-2">
                <div class="row">
                  <div class="col-xs-24">
                    <div class="content text-center">
                      <p class="text-bold">
                        {{ T("Password reset") }}
                      </p>
                      <p class="margin-xs-y-2">
                        {{ T("After clicking \"Reset password\" you will receive an email with further instructions.") }}
                      </p>
                      <div class="content-2" v-if="form.passwordResetError">
                        <div
                            id="user-login-failed-message"
                            class="notification color-white bg-red content text-center"
                        >
                          {{ errorMsg }}
                        </div>
                      </div>
                      <div>
                        <label class="form-field" :class="{'focus':domainFocus}">
                            <span class="form-text-prefix text-center">
                                <i class="fal fa-globe"></i>
                            </span>
                            <input 
                              id="login-domain" 
                              type="text" 
                              class="form-control" 
                              :placeholder="T('Tenant domain (e.g. 123456.sms)')" 
                              autofocus 
                              v-model="form.inputData.domain"
                              @focus="domainFocus = true"  
                              @blur="domainFocus = false"  
                            >
                        </label>
                        <label class="form-field" :class="{'focus':usernameFocus}">
                            <span class="form-text-prefix text-center">
                                <i class="fal fa-user"></i>
                            </span>
                            <input
                                id="username"
                                name="username"
                                autocomplete="username"
                                type="text"
                                class="form-control"
                                :placeholder="T('Username')"
                                v-model="form.inputData.username"
                                @focus="usernameFocus = true"
                                @blur="usernameFocus = false"
                            >
                        </label>

                      </div>
                      <div class="row margin-xs-t-2">
                        <div class="col-xs-24 col-sm-12">
                          <buttonComponent
                            :button-options="{
                              'icon':'fal fa-arrow-left',
                              'text':T('Back to login'),
                              'onClick':goBackToLogin
                            }"
                          ></buttonComponent>
                        </div>
                        <div class="col-xs-24 col-sm-12">
                          <buttonComponent
                            :button-options="{
                              'icon':'fal fa-envelope',
                              'text':T('Reset password'),
                              'onClick':sendPasswordResetMail
                            }"
                          ></buttonComponent>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          </div>
        </template>
        <template v-if="context == 'sentResetEmail'">
          <div class="login-wrapper" style="max-width: 555px">
            <div class="box-shadow">
              <header id="main-header">
                  <div id="logoarea">
                      <a href="#" class="logo-small">
                          <img :src="logoIcon" class="logo">&nbsp;
                          <span class="app-name">Unified Security</span>
                      </a>
                  </div>
              </header>
              <main>
                <div class="content-2">
                  <div class="row">
                    <div class="col-xs-24">
                      <div class="content text-center">
                        <p class="notification bg-red color-white">
                          <i class="fal fa-check"></i> {{ T("Email sent") }}
                        </p>
                        <p class="margin-xs-t-2">
                          {{ T("An email with further instructions has been sent to the user.") }}
                        </p>
                        <div class="row margin-xs-t-2">
                          <div class="col-xs-24">
                            <buttonComponent
                              :button-options="{
                                'icon':'fal fa-arrow-left',
                                'text':T('Back to login'),
                                'onClick':goBackToLogin
                              }"
                            ></buttonComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </template>
        <template v-if="context == 'rsp2faError'">
          <div class="login-wrapper" style="max-width: 555px">
            <div class="box-shadow">
              <header id="main-header">
                  <div id="logoarea">
                      <a href="#" class="logo-small">
                          <img :src="logoIcon" class="logo">&nbsp;
                          <span class="app-name">Unified Security</span>
                      </a>
                  </div>
              </header>
              <main>
                <div class="content-2">
                  <div class="row">
                    <div class="col-xs-24">
                      <div class="content text-center">
                        <p class="notification bg-red color-white">
                          <i class="fal fa-exclamation-triangle"></i> {{ T("Missing Two-Factor-Authentication") }}
                        </p>
                        <p class="margin-xs-t-2">
                          {{ T("As of version 1.24, USP administrators are required to use Two-Factor-Authentication as an additional security measure.") }}
                        </p>
                        <p class="margin-xs-b-4">
                          {{ T("To be able to use the portal again, please set up Two-Factor-Authentication (TOTP or Webauthn) for your account in the Securepoint Reseller Portal.") }}
                        </p>
                        <div class="row margin-xs-t-2">
                          <div class="col-xs-24 col-sm-12">
                            <buttonComponent
                              :button-options="{
                                'icon':'fal fa-arrow-left',
                                'text':T('Back to login'),
                                'onClick':goBackToLogin
                              }"
                            ></buttonComponent>
                          </div>
                          <div class="col-xs-24 col-sm-12">
                            <buttonComponent
                              :button-options="{
                                'icon':'fal fa-lock',
                                'text':T('Enable 2FA'),
                                'onClick':goToRspTwoFaSetup
                              }"
                            ></buttonComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>

        </template>

        </TransitionGroup>
        
</div>
</template>

<style lang="scss">
  #wrapper.login-page .login-wrapper, #wrapper.register-page .login-wrapper {
    animation-name: none;
    opacity: 1;
    &.loginBox-enter-active,
    &.loginBox-leave-active {
      transition: all 0.5s ease;
    }
    &.loginBox-leave-to {
      opacity: 0;
      left:25%;
    }
    &.loginBox-enter-from {
      opacity: 0;
      left:75%;
    }

    &.loginBoxReverse-enter-active,
    &.loginBoxReverse-leave-active {
      transition: all 0.5s ease;
    }
    &.loginBoxReverse-leave-to {
      opacity: 0;
      left:75%;
    }
    &.loginBoxReverse-enter-from {
      opacity: 0;
      left:25%;
    }
  }


</style>