import { useStore } from '@/store/vuex.store';
import type { AccountId, ObjectStore } from "../../../types/vuex";
import type { ProductType } from "@/resources/registeredProducts";

const storeHelpers = {
  getStore:function() {
    
  },
  getProductStore:function(accountId:AccountId,productType:ProductType) {
    const productStore = useStore()?.state.session.accounts[accountId][productType]
    if (!productStore) throw "Missing productStore \""+productType+"\" for accountId " + accountId;
    return productStore
  },
  getObjectTypeStore:function(accountId:AccountId,productType:ProductType,objectType:string) {
    const productStore = useStore()?.state.session.accounts[accountId]?.[productType]
    if (!productStore) throw "Missing productStore \""+productType+"\" for accountId " + accountId;
    const objectTypeStore = productStore?.[objectType as keyof typeof productStore] as ObjectStore<any> | undefined
    return objectTypeStore
  }
}
export default storeHelpers