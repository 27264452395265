<script setup lang="ts">
import type { Button, ButtonOptions } from '@/classes/buttons';
import { computed, ref } from 'vue';
import loaderComponent from "./loader.vue"
import Tooltip from './tooltip.vue';

export interface ButtonComponentButtonOptions {
  "buttonOptions":ButtonOptions|Button
}
defineOptions({
  inheritAttrs: false
})
const props = withDefaults(defineProps<ButtonComponentButtonOptions>(), {
  "buttonOptions":() => { return {
    "disabled": false,
    "icon": "",
    "link": undefined,
    "loading": false,
    "onClick": undefined,
    "target": "_blank",
    "text": "Button",
    "title": "",
    "topRightCircle": undefined,
    "type":"default",
    "size":undefined
  }},
  "updater":0
});

const isDisabled = computed(() => {
  props.updater;
  return (typeof props.buttonOptions.disabled == 'function' ? props.buttonOptions.disabled() : props.buttonOptions.disabled) || null
})

const buttonSpacing = computed(() => {
  return props.buttonOptions.text ? "margin-right: 3px;" : ''
})

</script>

<template>

  <template v-if="props.buttonOptions.title">
    <Tooltip :tooltip="props.buttonOptions.title" :is-tag="'span'" :removeOnBeforeUnmount="true">

      <a class="btn btn2 btn-loader twist-in" :class="[
    { 'btn-loading': typeof props.buttonOptions.loading == 'function' ? props.buttonOptions.loading() : props.buttonOptions.loading },
    { 'disabled': (typeof props.buttonOptions.disabled == 'function' ? props.buttonOptions.disabled() : props.buttonOptions.disabled) || null },
    { 'style-icon': props.buttonOptions.type == 'icon' },
    { ['btn-' + props.buttonOptions.size]: props.buttonOptions.size != undefined }
  ]" :disabled="isDisabled || null"
        :href="props.buttonOptions.link" :target="props.buttonOptions.target"
        v-on:click="props.buttonOptions.onClick != undefined ? props.buttonOptions.onClick() : null" v-bind="$attrs">
        <span class="btn-wrapper">
          <span>
            <template v-if="props.buttonOptions.icon">
              <i class="fa-fw" :class="props.buttonOptions.icon" :style="buttonSpacing"></i>
            </template>
            <template v-if="props.buttonOptions.text">
              {{ props.buttonOptions.text }}
            </template>
          </span>
          <span class="animate">
            <loaderComponent v-if="props.buttonOptions.loading"></loaderComponent>
          </span>
        </span>
        <span class="topRightCircle" v-if="props.buttonOptions.topRightCircle">
          <template v-if="props.buttonOptions.topRightCircle.counter">
            {{ props.buttonOptions.topRightCircle.counter }}
          </template>
          <template v-if="props.buttonOptions.topRightCircle.icon">
            <i :class="props.buttonOptions.topRightCircle.icon"></i>
          </template>
        </span>
      </a>
    </Tooltip>
  </template>
  <template v-else>
    <a class="btn btn2 btn-loader twist-in" :class="[
    { 'btn-loading': typeof props.buttonOptions.loading == 'function' ? props.buttonOptions.loading() : props.buttonOptions.loading },
    { 'disabled': (typeof props.buttonOptions.disabled == 'function' ? props.buttonOptions.disabled() : props.buttonOptions.disabled) || null },
    { 'style-icon': props.buttonOptions.type == 'icon' },
    { ['btn-' + props.buttonOptions.size]: props.buttonOptions.size != undefined }
  ]" :title="props.buttonOptions.title"
      :disabled="(typeof props.buttonOptions.disabled == 'function' ? props.buttonOptions.disabled() : props.buttonOptions.disabled) || null"
      :href="props.buttonOptions.link" :target="props.buttonOptions.target"
      v-on:click="props.buttonOptions.onClick != undefined ? props.buttonOptions.onClick() : null" v-bind="$attrs">
      <span class="btn-wrapper">
        <span>
          <template v-if="props.buttonOptions.icon">
            <i class="fa-fw" :class="props.buttonOptions.icon" :style="buttonSpacing"></i>
          </template>
          <template v-if="props.buttonOptions.text">
            {{ props.buttonOptions.text }}
          </template>
        </span>
        <span class="animate">
          <loaderComponent v-if="props.buttonOptions.loading"></loaderComponent>
        </span>
      </span>
      <span class="topRightCircle" v-if="props.buttonOptions.topRightCircle">
        <template v-if="props.buttonOptions.topRightCircle.counter">
          {{ props.buttonOptions.topRightCircle.counter }}
        </template>
        <template v-if="props.buttonOptions.topRightCircle.icon">
          <i :class="props.buttonOptions.topRightCircle.icon"></i>
        </template>
      </span>
    </a>
  </template>

</template>
<style lang="scss">

.btn2 {
  overflow: visible !important;
}
.btn.btn2.btn-loader > span.btn-wrapper {
  overflow: hidden;
  transition: 0s;
  padding:0;
  display: block;
  position: relative;
  transform:scale(1,1) !important;
  font-size:1em;
  > span {
    padding: 0.5em 0.75em;
    line-height: 1.5em;
    font-size:1em;
    display:block;
    transition:0.3s;
  }
}
.btn.btn2.btn-loader.btn2.btn-loading  > span.btn-wrapper {
  > span {
    transition:0.3s; 
    transform:scale(0,0);
    &.animate {
      transform:scale(1,1);
    }
  }
  
}

.btn span.topRightCircle {
  position: absolute !important;
  z-index:10;
  top:-10px;
  left:calc(100% - 10px);
  line-height:8px;
  padding:6px;
  font-size:10px;
  background-color:#E74C3C;
  color:#fff;
  display:block;
  border-radius:50%;
  border:2px solid;
  width:auto;
  min-width:22px;
  i {
    line-height:8px;
  }
}
.darkmode .btn span.topRightCircle {
  border-color:#25262A;
}

.btn.btn2.style-icon {
  background:none;
  border:none;
  padding:0;
  margin:0;
  display:inline-block;
  position:relative;
  color:rgba(0,0,0,0.3);
  span.topRightCircle {
    border:none;
    min-width:17px;
    top:-5px;
    left:calc(100% - 15px);
    padding:4px;
    background:rgba(0,0,0,0.08);
    color:rgba(0,0,0,0.5);
    box-shadow:1px 1px 5px -2px rgba(0,0,0,0.1);
  }
  &:hover {
    color:#E74C3C;
  }
}
.darkmode .btn.btn2.style-icon {
  color: rgba(255, 255, 255, 0.5);
  span.topRightCircle {
    background:rgba(0,0,0,0.2);
    color:rgba(255,255,255,0.6);
    box-shadow:1px 1px 5px -2px rgba(0,0,0,0.1);
  }
  &:hover {
    color:#E74C3C;
  }
}

</style>