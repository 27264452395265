import config from '@/classes/config'
import products from '@/classes/objectTypes'
import tenantHelpers from '@/helpers/helpers.tenants'
import { useStore } from '@/store/vuex.store'

const depInfosMixin = {
    data() {
        return {
            "depProfileStatusInfo": {
                "empty": "No DEP Profile assigned",
                "assigned": "The profile has been assigned to the device, but will not be applied until the next reset",
                "pushed": "The profile has been applied to the device",
                "removed": "The profile has been removed from the device",
            }
        }
    },
    computed: {
        "depProfiles": function(this:any) {
            if(config.canUseNewObjectType("depProfiles")) {
                return products.mobileSecurity.depProfiles.useStore?.().getObjectStoreObjects(this.activeAccountId) || []
            }
            return useStore()?.getters.getObjectTypeStore({
                "accountId":this.activeAccountId,
                "objectType":"depProfiles",
            })?.items || []
        },
        "canUseAppleDEP": function(this:any) {
            return tenantHelpers.hasFunctionality(this.activeAccountId,'appleDEP')
        }
    },
    created: function (this: any) {

    }
}
export default depInfosMixin