import config from "@/classes/config"
import i18n from "@/classes/i18n"
import timeHelpers from "@/helpers/helpers.time"

const mainFooterComponent = {
    "name":"footerTemplate",
    "template": `
        <footer class="main-footer">
            <div v-if="showAppName" class="float-md-right hidden-xs">Securepoint Unified Security <small>{{version}}</small></div>
            Copyright &copy; 2016 - ${timeHelpers.getCurrentDate('YYYY')} <a href="https://www.securepoint.de/" target="_blank" rel="noreferrer noopener">Securepoint GmbH</a>&nbsp;&nbsp;•&nbsp;&nbsp;<span id="footerlinks" class="media-screen-only" v-html="getFooterLinks()" v-once></span>
        </footer>
    `,
    "props": {
        "showAppName": {
            "required":false,
            "default":true
        }
    },
    "methods":{
        getFooterLinks: function() {
            let result : string = ""
            if(i18n.getLanguage() == "de") {
                result = '<a href="https://www.securepoint.de/unternehmen/impressum.html" target="_blank" rel="noreferrer noopener">Impressum</a> | <a href="https://www.securepoint.de/unternehmen/agb.html" target="_blank" rel="noreferrer noopener">AGB</a> | <a href="https://www.securepoint.de/unternehmen/datenschutz.html" target="_blank" rel="noreferrer noopener">Datenschutz</a> | <a href="#pluginLicenses">Lizenzen</a>'
            } else {
                result = '<a href="https://www.securepoint.de/en/company/legal-notice.html" target="_blank" rel="noreferrer noopener">Imprint</a> | <a href="https://www.securepoint.de/en/company/terms-conditions.html" target="_blank" rel="noreferrer noopener">GTC</a> | <a href="https://www.securepoint.de/en/company/data-protection.html" target="_blank" rel="noreferrer noopener">Privacy</a>  | <a href="#pluginLicenses">Licenses</a>'
            }
            return result
        }
    },
    "computed": {
        "version": function(this:any) {
            return config.version
        }
    }
}
export default mainFooterComponent
