<script setup lang="ts">
import getterHelpers from '@/helpers/helpers.getters';
import { MutationTypes } from '@/store/vuex.store';
import { T } from "@/classes/i18n"
import { computed, onMounted, ref, watch } from 'vue';
import inputTextComponent from '../inputtypes/input-text.vue';
import { useVue } from '@/app';
import inputSelectComponent from '../inputtypes/input-vue-select.vue';
import requestHandler from '@/queries/requests';
import config from '@/classes/config';
import tenantHelpers from '@/helpers/helpers.tenants';
import {type CreateNode, type CreateNodeGroup } from '@/classes/objectTypes/unifiedNetwork/topologies';
import loader from '../components/loader.vue';
import products from '@/classes/objectTypes';
import { debounce } from 'throttle-debounce';
import validationHelpers from '@/helpers/helpers.validation';
import ipaddr from '@/lib/ipaddr';

const props = defineProps<{
    properties: {
        sattelitteId: string,
        selectedTopologyId: string,
    }
}>();

const nameRegex: RegExp = new RegExp("^[^\\s]+$")
// Refs
const node = ref(<any>undefined)
const loading = ref(false)
const error = ref("")

const networkObjectName = ref("")
const networkGroupName = ref("")
const networkGroup = ref("")
const type = ref("host")
const address = ref("")
const zone = ref(<number | null>null)

// Computed Refs
const activeAccountId = computed(() => { return getterHelpers.useStore()?.getters.getActiveAccountId || "" })
const activeTenantDomain = computed(() => { return tenantHelpers.getTenantDomain(activeAccountId.value) })

const typeSelectOptions = computed(() => {
    return [{
        text: T("Host"),
        id: "host",
    }, {
        text: T("Network (address)"),
        id: "network-address",
    }] as selectOption[]
})
const networkGroupSelectOptions = computed(() => {
    return Array.isArray(node.value?.nkView?.nodes) ? node.value.nkView.nodes.map((node: any) => {
        if (Array.isArray(node.node_refs)) return {
            id: node.name,
            text: node.name
        } as selectOption
    }).filter((option: selectOption) => option !== undefined) : []
})
const zoneSelectOptions = computed(() => {
    return (node.value?.nkView?.zones || []).map((zone: any) => {
        return {
            "text": zone.name,
            "id": zone.id
        } as selectOption
    })
})
const selectedZone = computed(() => {
    return zoneSelectOptions.value.find((thisZone: { id: number, text: string }) => {
        return thisZone.id == zone.value
    }) as { id: number, text: string }
})
const reservedObjects = computed(() => {
    return (node.value?.nkView?.reservedObjects || []) as string[]
})
const addressErrors = computed(() => {
    let thisErrors: string[] = []
    if (address.value.length >= 1) {
        const thisAddress = new ipaddr(address.value)
        if (thisAddress.isIPv4() || thisAddress.isIPv6()) {
            const validationMap = {
                'host': () => {
                    if (thisAddress.hasCidr()) {
                        thisErrors.push(T("This value must not have a network mask"))
                    }
                },
                'network-address': () => {
                    if (thisAddress.hasCidr() == false) {
                        thisErrors.push("Missing network mask.")
                    }
                }
            }
            validationMap[type.value as keyof typeof validationMap]?.()
        }
        else {
            thisErrors.push("The specified address does not match an ipv4 or ipv6 address.")
        }
    }
    else {
        thisErrors.push("Enter an IP address.")
    }
    return thisErrors
})


// Functions
const checkReadyState = () => {
    setError()
    if ((networkObjectName.value != "" && !reservedObjects.value.includes(networkObjectName.value) && !nameRegex.test(networkGroupName.value))
        && ((networkGroupName.value != "" && !nameRegex.test(networkGroupName.value)) || networkGroupName.value == "")
        && !isNaN(zone.value || NaN)
        && validationHelpers
        && type.value != ""
        && error.value == ""
        && addressErrors.value.length == 0
    )
        useVue().$refs.modals.$refs.modal.buttons[1].disabled = false;
    else useVue().$refs.modals.$refs.modal.buttons[1].disabled = true;
}

const setError = debounce(1000, () => {
    if(reservedObjects.value.includes(networkObjectName.value)) error.value = T("Some of the provided names collide with the reserved names of the UTM.");
    else if(reservedObjects.value.includes(networkGroupName.value) && !networkGroupSelectOptions.value.some((option: selectOption) => option.id == networkGroupName.value)) error.value = T("Some of the provided names collide with the reserved names of the UTM.");
    else if((networkObjectName.value != "" && !nameRegex.test(networkObjectName.value)) || (networkGroupName.value != "" && !nameRegex.test(networkGroupName.value))) error.value = T("Some of the provided names contain backslashes or whitespaces.")
    else if(networkObjectName.value != "" && networkGroupName.value != "" && networkObjectName.value == networkGroupName.value) error.value = T("Please chose unique identifiers for name and service group name.");
    else error.value = ""
})

const submit = async () => {
    let payload: CreateNode | CreateNodeGroup = {
        "name": networkObjectName.value,
        "node_address": address.value,
        "node_zone": {
            "id": selectedZone.value.id,
            "name": selectedZone.value.text
        }
    } as CreateNode
    if (networkGroup.value) {
        payload = {
            name: networkGroup.value,
            nodes: [payload]
        } as CreateNodeGroup
    }
    try {
        useVue().$refs.modals.$refs.modal.buttons[1].loading = true
        requestHandler.request("POST", config.mgtApiUriNext + "/tenants/" + activeTenantDomain.value + "/sun/topologies/" + props.properties.selectedTopologyId + "/nodes/" + props.properties.sattelitteId + "/objects", payload)
        useVue().$refs.modals.$refs.modal.buttons[1].loading = false
        getterHelpers.useStore().commit(MutationTypes.removeModal, {})
    }
    catch (e: unknown) {
        console.error(e)
        error.value = T("An unknown error occurred while adding the service. Please try again in a short time or change the input values.")
    }
}

onMounted(async () => {
    loading.value = true
    let thisNode = await products.unifiedNetwork.topologies.getNodeObjectFromApi(activeAccountId.value, props.properties.sattelitteId)
    node.value = thisNode
    loading.value = false
})

watch([networkGroupName, networkObjectName, networkGroupName, error, address, zone, type], () => {
    checkReadyState()
})

defineExpose({
    submit
})

</script>
<template>
    <div class="content-2">
        <template v-if="loading == false">

            <div v-if="error && error !== 'debounced'" class="text-center notification bg-red">
                {{ T(error) }}
            </div>
            <!-- Name -->
            <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="sourceNodeSelect">
                        {{ T('Name') }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputTextComponent :placeholder="T('Name')" v-model="networkObjectName"></inputTextComponent>
                </div>
                <div class="col-lg-7 col-xl-8">
                </div>
            </div>

            <!-- Type -->
            <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="sourceNodeSelect">
                        {{ T('Type') }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputSelectComponent :select-options="typeSelectOptions" v-model="type"></inputSelectComponent>
                </div>
                <div class="col-lg-7 col-xl-8">
                </div>
            </div>

            <!-- Address -->
            <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="sourceNodeSelect">
                        {{ T('Address') }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">

                    <inputTextComponent v-model="address"></inputTextComponent>
                    <template v-if="addressErrors.length">
                        <template v-for="err in addressErrors">
                            <span class="error-bubble label bg-red margin-xs-t"> {{ T(err) }}</span>
                        </template>
                    </template>
                </div>
                <div class="desc col-lg-7 col-xl-8">
                    <p class="input-description">
                        {{ T('IPv4 or IPv6 address to be used as the transfer network.') }}
                    </p>
                </div>
            </div>

            <!-- Zone -->
            <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="sourceNodeSelect">
                        {{ T('Zone') }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputSelectComponent :select-options="zoneSelectOptions" :placeholder="T('Select a zone')"
                        v-model="zone">
                    </inputSelectComponent>
                </div>
                <div class="col-lg-7 col-xl-8">
                </div>
            </div>

            <!-- Groups -->
            <div class="row padding-xs-t padding-xs-b-2 form-group">
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="sourceNodeSelect">
                        {{ T('Network Group') }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputSelectComponent :placeholder="T('Select Network Group')"
                        :select-options="networkGroupSelectOptions" v-model="networkGroup" :tags="true"
                        :clearable="true"></inputSelectComponent>
                </div>
                <div class="col-lg-7 col-xl-8">
                    <p class="input-description">
                        {{ T('Adds a network object to an existing network group or creates a new group') }}
                    </p>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="text-size-3 text-center padding-xs-t-4">
                <div class="text-size-2">
                    <loader class="color-red"></loader>
                </div>
            </div>
            <div class="text-center padding-xs-t-2" style="opacity:0.8">
                <span>
                    {{ T('Getting Node Information...') }}
                </span>
            </div>
        </template>
    </div>
</template>