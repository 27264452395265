<script setup lang="ts">
    import { T } from "@/classes/i18n";
import mixinHelpers from "@/helpers/helpers.mixins";
    import { useStore } from '@/store/vuex.store';
    import { computed } from "vue";
    
    const isSessionInitialized = computed(() => {
        return mixinHelpers.isSessionInitialized()
    })
    const pluginLicenses = computed(() => {
        return useStore().state.resources.pluginLicenses?.plugins || []
    })
</script>
<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div>
            <div class="row" v-if="!isSessionInitialized">
                <div class="col-xs-24 col-lg-16 padding-xs media-screen-only">
                    <h1>{{ T('Licenses') }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-24 col-lg-16 padding-xs media-screen-only">
                    <div class="box-shadow bg-white padding-xs-2">
                        <table class="border-h">
                            <thead>
                                <tr>
                                    <th>
                                        Plugin
                                    </th>
                                    <th>
                                        {{ T('License') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="plugin in pluginLicenses">
                                    <tr>
                                        <td>
                                            <template v-if="plugin.link">
                                                <a :href="plugin.link" target="_blank" rel="noreferrer noopener">{{ plugin.pluginName }}</a>
                                            </template>
                                            <template v-else>
                                                {{ plugin.pluginName || '' }}
                                            </template>
                                        </td>
                                        <td>
                                            <template v-for="license, index in plugin.licenses">
                                                <template v-if="license.link">
                                                    <a :href="license.link" target="_blank" rel="noreferrer noopener">
                                                        {{ license.licenseName }}
                                                    </a>
                                                </template>
                                                <template v-else>
                                                    {{ license.licenseName || '' }}
                                                </template>
                                                <template v-if="plugin.licenses.length - 1 > index">
                                                    , 
                                                </template>
                                            </template>
                                        </td>
                                    </tr>

                                </template>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>