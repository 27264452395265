import { useGlobalMixin } from "@/mixins/mixins.global"

const ipadUserDeleteComponent = {
	name:'ipadUser-delete',
	mixins: [useGlobalMixin()],
	template: `
		<div class="row padding-xs-b form-group border-bottom">
			<div class="first col-xs-24 col-lg-6 col-xl-5">
				<label
					class="control-label inputname"
					for="forceLogout"
				>
					{{ T('Force') }}
				</label>
			</div>
			<div class="input col-xs-24 col-lg-8">
				<label class="checkbox toggle">
					<input type="checkbox" id="forceLogout" class="toggle" v-model="forceLogout">
					<span></span>
				</label>
			</div>
			<div class="desc col-xs-24 col-lg-10">
				<p class="input-description">
					{{ T('Defines wether you want to force delete or not.') }}
				</p>
			</div>
		</div>
	`,
	data: function() {
		return {
			"forceLogout":false
		}
	},
	methods: {
	},
	created: async function(this : any) {
	},
	mounted: function(this:any) {
	},
	watch: {
	}
}
export default ipadUserDeleteComponent