<template>
    <span class="betterTooltip" ref="tooltipWrapper" @mouseover="isHovering = true" @mouseleave="isHovering = false">
        <slot />

        <template v-if="$slots.content">
            <div ref="tooltipContent" style="display: none;"><slot name="content"></slot></div>
        </template>
    </span>
</template>

<script setup lang="ts">
import { onBeforeUnmount, ref, watch, nextTick } from 'vue';
import { createPopper, preventOverflow } from '@popperjs/core';
import stringHelpers from '@/helpers/helpers.strings';

const tooltipWrapper = ref(null);
const isHovering = ref(false);
const randomId = stringHelpers.generateUUID();
const tooltipContent = ref(null);

watch(isHovering, async () => {
    const bodyEl = document.getElementsByTagName('body')[0];
    if (isHovering.value) {
        await nextTick();

        const newEl = document.createElement('span');
        newEl.classList.add('tooltipContent');
        newEl.id = "tooltip#" + randomId;
        newEl.innerHTML = '<span></span><span class="arrow"></span>';

        const contentSlot = tooltipContent.value as any;
        newEl.children[0].innerHTML = contentSlot.innerHTML

        bodyEl.appendChild(newEl);

        const contentEl = document.getElementById('tooltip#' + randomId);
        if (tooltipWrapper.value && contentEl) {
            createPopper(tooltipWrapper.value, contentEl, {
                placement: "bottom",
                onFirstUpdate: (e) => {
                    setTimeout(() => {
                        e.elements?.popper.classList.add('show');
                    }, 200);
                },
                modifiers: [
                    preventOverflow,
                    {
                        name: "offset",
                        options: {
                            offset: [0, 8]
                        }
                    }
                ]
            });
        }
    } else {
        const contentEl = document.getElementById('tooltip#' + randomId);
        if (contentEl) {
            contentEl.remove();
        }
    }
});

onBeforeUnmount(() => {
    const contentEl = document.getElementById('tooltip#' + randomId);
    if (contentEl) {
        contentEl.remove();
    }
});
</script>

<style lang="scss">
    .betterTooltip {
        position: relative;
    }
    .tooltipContent {
        display: block;
        position: absolute;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.7);
        padding: 8px;
        width: max-content;
        line-height: 1em;
        border-radius: 4px;
        color: #fff;
        max-width: 400px;
    }
</style>