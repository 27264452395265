<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div class="row media-screen-only" v-if="accountInitialized != undefined">
            <div class="col-xs-24 padding-xs-b">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="padding-xs-t padding-xs-l">
                            <div class="toolbar flexrow">
                                <div class="col-xs" style="margin-bottom:8px; margin-right:8px;" :title="T('Period')">
                                    <label class="form-field margin-xs-b-0">
                                        <span class="form-icon-prefix"><i class="fal fa-clock"></i></span>
                                        <select v-model="period" class="form-control">
                                            <option value="today">{{ T('Today') }}</option>
                                            <option value="lastday">{{ T('Yesterday') }}</option>
                                            <option value="currentweek">{{ T('Current week') }}</option>
                                            <option value="currentmonth">{{ T('Current month') }}</option>
                                            <option value="last30days">{{ T('Last 30 days') }}</option>
                                        </select>
                                    </label>
                                </div>

                                <div class="btn-toolbar" style="margin-bottom:8px; margin-right:8px;">
                                    <div class="btn-group">
                                        <a class="btn margin-btn-b-0 btn-loader twist-in" v-on:click="load()"
                                            title="Refresh">
                                            <span class="animate">
                                                <i class="progress-circular no-progress"
                                                    style="font-size: 1.5em;top:2px;">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                                        viewBox="0 0 50 50" style="stroke-width: 4px;">
                                                        <circle cx="25" cy="25" r="20"
                                                            style="stroke: rgba(0, 0, 0, 0.1); fill: none;"></circle>
                                                        <g transform="translate(25,25) rotate(-90)">
                                                            <circle stroke-dasharray="110" stroke-dashoffset="0" cx="0"
                                                                cy="0" r="20" style="fill: none; stroke-linecap: round;"
                                                                transform="rotate(14.0181)">
                                                                <animate attributeName="stroke-dashoffset"
                                                                    values="360;140" dur="2.2s" keyTimes="0;1"
                                                                    calcMode="spline" fill="freeze"
                                                                    keySplines="0.41,0.314,0.8,0.54"
                                                                    repeatCount="indefinite" begin="0"></animate>
                                                                <animate attributeName="stroke" fill="freeze" dur="8s"
                                                                    begin="0" repeatCount="indefinite"></animate>
                                                            </circle>
                                                        </g>
                                                    </svg>
                                                </i>
                                            </span>
                                            <span><i class="fal fa-fw fa-sync"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-24 col-md-8 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-icon icon-center">
                            <div class="icon-circle icon-circle bg-green">
                                <i class="fa fa-signal"></i>
                            </div>
                        </div>
                        <div class="box-content-2 text-center">
                            <p class="text-size-3" v-if="!loading">
                                {{ countStats.total }}
                            </p>
                            <p v-else class="text-size-3 color-red">
                                <loaderComponent></loaderComponent>
                            </p>
                        </div>
                        <div class="text-center padding-xs-b-2">
                            {{ T('Hits total') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-8 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-icon icon-center">
                            <div class="icon-circle icon-circle bg-red">
                                <i class="fa fa-ban"></i>
                            </div>
                        </div>
                        <div class="box-content-2 text-center">
                            <p class="text-size-3" v-if="!loading">
                                {{ countStats.totalBlocked }}
                            </p>
                            <p v-else class="text-size-3 color-red">
                                <loaderComponent></loaderComponent>
                            </p>
                        </div>
                        <div class="text-center padding-xs-b-2">
                            {{ T('Blocked domains') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-8 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-icon icon-center">
                            <div class="icon-circle icon-circle bg-aqua">
                                <i class="fa fa-percent"></i>
                            </div>
                        </div>
                        <div class="box-content-2 text-center">
                            <p class="text-size-3" v-if="!loading">
                                {{ countStats.totalBlockedPercentage }}
                            </p>
                            <p v-else class="text-size-3 color-red">
                                <loaderComponent></loaderComponent>
                            </p>
                        </div>
                        <div class="text-center padding-xs-b-2">
                            {{ T('% blocked') }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-chart-area"></i>
                                {{ T('Hits total') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loading">
                                <highcharts class="highcharts-nobg highcharts-gauge" :options="requestChart" />
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-chart-area"></i>
                                {{ T('Client activity') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loading">
                                <highcharts class="highcharts-nobg highcharts-gauge"
                                    :options="clientActivityChart.options" />
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-check-circle"></i>
                                {{ T('Top Permitted Domains') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loading">
                                <tableNext :loading="loading" :objectList="topPermittedDomains.rows"
                                    :selectable-columns="topDomainTitles" :isSearchable="false" :isFilterable="false"
                                    :hasOptions="false" maxheight="250">
                                </tableNext>
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-ban"></i>
                                {{ T('Top Blocked Domains') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loading">
                                <tableNext :objectList="topBlockedDomains.rows" :selectable-columns="topDomainTitles"
                                    :isSearchable="false" :isFilterable="false" :hasOptions="false" maxheight="250">
                                </tableNext>
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-chart-pie"></i>
                                {{ T('Top Block Reasons') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loading">
                                <highcharts class="highcharts-nobg highcharts-gauge" :options="topBlockReasonsChart" />
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-chart-pie"></i>
                                {{ T('Top Clients') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loading">
                                <highcharts class="highcharts-nobg highcharts-gauge" :options="topClientChart" />
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { T } from "@/classes/i18n";
import type Highcharts from "highcharts"
import requestHandler from '@/queries/requests';
import tenantHelpers from '@/helpers/helpers.tenants';
import loaderComponent from "@/templates/components/loader.vue";
import tableNext from "../components/tableNext.vue";

const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId
})
const accountInitialized = computed(() => {
    return useStore().state.session.accounts[activeAccountId.value || ''].initialized
})
const timezone = computed(() => {
    return useStore().state.session.accounts[activeAccountId.value || ''].unifiedSecurity.account.timezone
})
const statsUrl = computed(() => {
    return "/sms-mgt-api/api/2.0/tenants/" + tenantHelpers.getTenantDomain(activeAccountId.value || "") + "/stats/execute"
})

const requestChart = ref({
    chart: {
        height: "50%",
        zooming: {
            type: "x"
        },
        type: 'area'
    },
    title: {
        text: "",
        align: 'left'
    },
    subtitle: {
        text: "",
        align: 'left'
    },
    xAxis: {
        type: 'datetime',
        values: {
            enabled: true,
            format: '{value:%H:%M}',
        }
    },
    yAxis: {
        title: {
            text: ''
        }
    },
    legend: {
        enabled: true
    },
    series: [{
        name: 'Permitted Requests',
        data: []
    },
    {
        name: 'Blocked Requests',
        data: []
    }],
    credits: {
        enabled: false
    },
    time: {
        useUTC: false
    }
})

const topBlockReasonsChart = ref({
    chart: {
        type: 'pie',
        height: "35%",
    },
    title: {
        text: "",
        align: 'left'
    },
    subtitle: {
        text: "",
        align: 'left'
    },
    legend: {
        enabled: true
    },
    plotOptions: {
        series: {
            label: {
                connectorAllowed: false
            }
        }
    },
    series: [
        {
            name: 'Blocked Requests',
            borderWidth: 0,
            pointWidth: 0,
            borderColor: 'none',
            color: '#ff0000',
            data: [],
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '13px'
                }
            }
        }],
    credits: {
        enabled: false
    },
    time: {
        useUTC: false
    }

})

const topClientChart = ref({
    chart: {
        type: 'pie',
        height: "35%",
    },
    title: {
        text: "",
        align: 'left'
    },
    subtitle: {
        text: "",
        align: 'left'
    },
    legend: {
        enabled: true
    },
    plotOptions: {
        series: {
            label: {
                connectorAllowed: false
            }
        }
    },
    series: [
        {
            name: 'Total Requests',
            borderWidth: 0,
            pointWidth: 0,
            borderColor: 'none',
            color: '#ff0000',
            data: [],
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '13px'
                }
            }
        }],
    credits: {
        enabled: false
    },
    time: {
        useUTC: false
    }
})

const clientActivityChart = ref({
    options: {
        chart: {
            type: 'area',
            height: "50%",
        },
        title: {
            text: "",
            align: 'left'
        },
        subtitle: {
            text: "",
            align: 'left'
        },
        xAxis: {
            type: 'datetime',
            values: {
                enabled: true,
                format: '{value:%H:%M}',
            }
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        legend: {
            enabled: true
        },
        series: [] as any[],
        credits: {
            enabled: false
        },
        time: {
            useUTC: false
        }
    }
})


const period = ref("today")

const countStats = ref({
    totalBlocked: "0",
    total: "0",
    totalBlockedPercentage: "0",
})

const topDomainTitles = ref([
    {
        "property": "domain",
        "text": "Domain",
        "sortable": false,
        "displayType": "text",
        "getValue": (item: any) => item.data.domain
    },
    {
        "property": "count",
        "text": "Requests",
        "sortable": false,
        "displayType": "text",
        "getValue": (item: any) => item.data.count
    }
])

const topPermittedDomains = ref({
    rows: []
})

const topBlockedDomains = ref({
    rows: []
})

const loading = ref(false)

async function load() {
    loading.value = true

    const numberFormatter = Intl.NumberFormat()

    const counts = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "Counts",
            "options": {
                "relate": "count",
                "period": period.value,
                "size": 10,
                "offset": 0,
                "timezone": timezone.value,
                "order": {
                    "asc": false
                }
            }
        }
    })


    countStats.value.total = numberFormatter.format(parseInt(counts.result[0].total_requests))
    countStats.value.totalBlocked = numberFormatter.format(parseInt(counts.result[0].blocked_requests))
    countStats.value.totalBlockedPercentage = parseFloat(counts.result[0].blocked_percentage || 0).toFixed(2) + " %"


    const requestHistogramm = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "RequestHistogramm",
            "options": {
                "relate": "count",
                "period": period.value,
                "timezone": timezone.value,
                "order": {
                    "asc": false
                }
            }
        }
    })

    requestChart.value.series[0].data = requestHistogramm.result.map((item: any) => {
        return [new Date(item.interval).getTime(), item.count]
    })

    const requestBlockedHistogramm = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "RequestBlockedHistogramm",
            "options": {
                "relate": "count",
                "period": period.value,
                "timezone": timezone.value,
                "order": {
                    "asc": false
                }
            }
        }
    })

    requestChart.value.series[1].data = requestBlockedHistogramm.result.map((item: any) => {
        return [new Date(item.interval).getTime(), item.count]
    })

    const topPermitted = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "TopPermitted",
            "options": {
                "relate": "count",
                "period": period.value,
                "timezone": timezone.value,
                "order": {
                    "asc": false
                }
            }
        }
    })
    topPermittedDomains.value.rows = topPermitted.result.map((item: any) => {
        return {
            data: {
                domain: item.domain,
                count: parseInt(item.count)
            }
        }
    })

    const topBlocked = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "TopBlocked",
            "options": {
                "relate": "count",
                "period": period.value,
                "timezone": timezone.value,
                "order": {
                    "asc": false
                }
            }
        }
    })
    topBlockedDomains.value.rows = topBlocked.result.map((item: any) => {
        return {
            data: {
                domain: item.domain,
                count: parseInt(item.count)
            }
        }
    })

    const topBlockReasons = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "BlockReasons",
            "options": {
                "relate": "count",
                "period": period.value,
                "timezone": timezone.value,
                "order": {
                    "asc": false
                }
            }
        }
    })
    topBlockReasonsChart.value.series[0].data = topBlockReasons.result.map((item: any) => {
        return [tenantHelpers.secureDnsCategoryIdToName(parseInt(item.blockReason)), parseInt(item.count)]
    })

    const topClients = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "TopClients",
            "options": {
                "relate": "count",
                "period": period.value,
                "timezone": timezone.value,
                "order": {
                    "asc": false
                }
            }
        }
    })
    topClientChart.value.series[0].data = topClients.result.map((item: any) => {
        return [item.deviceName ?? T("Unknown"), parseInt(item.count)]
    })

    const clientActivity = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "ClientHistogramm",
            "options": {
                "relate": "count",
                "period": period.value,
                "timezone": timezone.value,
                "order": {
                    "asc": false
                }
            }
        }
    })

    clientActivityChart.value.options.series = []
    Object.entries(clientActivity.result).forEach(([clientName, data]) => {
        clientActivityChart.value.options.series.push({
            name: T(clientName),
            borderWidth: 0,
            pointWidth: 0,
            borderColor: 'none',
            data: (data as any[]).map((d) => {
                return [new Date(d.interval).getTime(), d.count]
            })
        })
    })

    loading.value = false
}

onMounted(async () => {
    await load()
})

watch(period, async () => {
    await load()
})

</script>