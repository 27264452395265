import translations from "@/translations/translations"
const langInfos = {
  ...translations
}

class I18n {

  private storage : any = {}
  private defaultLanguage = 'en'
  private currentLanguage = 'en'

  constructor() {
    try {
      var lang = location.search.match(/lang=([^&]+)/i);
      if (lang && lang.length === 2) {
        this.setLanguage(lang[1]);
      }
      else if ((navigator.language || navigator.userLanguage).substr(0, 2) == 'de') {
        this.setLanguage('de');
      }
      else if ((navigator.language || navigator.userLanguage).substr(0, 2) == 'en') {
        this.setLanguage('en');
      }
      else {
        // @ts-ignore
        this.currentLanguage = this.normalizeLanguage(navigator.language /* Mozilla */ || navigator.userLanguage /* IE */ || this.defaultLanguage);
      }      
    } 
    catch (e:any) { 
      console.error(e)
    }
  }

  private normalizeLanguage = (language:string) => {
    language = language.replace(/_/, '-').toLowerCase();
    if (language.length > 3) {
      language = language.substring(0, 3) + language.substring(3).toUpperCase();
    }
    return language.substring(0, 2);
  }

  private translateTextNode = (node:Node) => {
    var nodeValue = node.nodeValue;
    if (nodeValue) {
      var translation = this.translateString(nodeValue);
      if (translation) {
        node.nodeValue = translation;
      }
    }
  }

  private translateAttribute = (node:any, attribute:string) => {
    var attributeValue = node.getAttribute(attribute);
    if (attributeValue) {
      var translation = this.translateString(attributeValue);
      if (translation) {
        node.setAttribute(attribute, translation);
      }
    }
  }

  private lukeNodeWalker(node:any) {

    if (!node) return;

    if (node === document) {
      node = node.childNodes;
    }

    if (!node.length) {
      node = [node];
    }

    for (var i = 0, l = node.length; i < l; i++) {

      switch (node[i].nodeType) {
        case 3: // TEXT_NODE
        case 4: // CDATA_SECTION_NODE
        case 7: // PROCESSING_INSTRUCTION_NODE
        case 8: // COMMENT_NODE
          this.translateTextNode(node[i]);
          break;
        case 1: // ELEMENT_NODE
          if (node[i].getAttribute('translate') === 'false') {
            break;
          }
          this.translateAttribute(node[i], 'title');
          this.translateAttribute(node[i], 'placeholder');
          this.translateAttribute(node[i], 'alt');
        // fallthrough
        default:
          if (node[i].childNodes && node[i].childNodes.length > 0) {
            this.lukeNodeWalker(node[i].childNodes);
          }
      }
    }
  }


  public setLanguage = (language:string) => {
    this.currentLanguage = this.normalizeLanguage(language);
    return this.defaultLanguage = this.normalizeLanguage(language);
  }

  public getLanguage = () => {
    return this.currentLanguage;
  }

  public addTranslation = (language:string, translation:any) => {
    if (!this.storage[language]) {
      this.storage[language] = translation;
    } 
    else {
      for (var property in translation) {
        if (translation.hasOwnProperty(property)) {
          this.storage[language][property] = translation[property];
        }
      }
    }
  }

  public translateString = (text:string, regexp?:RegExp) : string => {
    var translation : string = "";
    var orig = text;

    if (text && typeof text === 'string') {
      text = text.trim();
    }

    if (!regexp) {
      if (this.storage[this.currentLanguage] && this.storage[this.currentLanguage][text]) {
        translation = this.storage[this.currentLanguage][text];
      }
      else if (this.storage[this.defaultLanguage] && this.storage[this.defaultLanguage][text]) {
        translation = this.storage[this.defaultLanguage][text];
      }

      if (!translation && this.currentLanguage === this.defaultLanguage) {
        return orig.replace(/#\d+$/, '');
      }

      if (translation) {
        var spacesBefore = orig.match(/^\s*/);
        var spacesAfter = orig.match(/\s*$/);
        if (spacesBefore) {
          translation = spacesBefore[0] + translation;
        }
        if (spacesAfter) {
          translation = translation + spacesAfter[0];
        }
      }
      else if (/:$/.test(text)) {
        text = text.replace(/:$/, '');
        return i18n.translateString(text) + ":";
      }
    }
    else {
      var obj = this.storage[this.currentLanguage];
      var r;
      if (!obj) {
        obj = this.storage[this.defaultLanguage];
      }
      if (obj) {
        for (var t in obj) {
          if (t[0] == "r" && t[1] == "/" && t[t.length - 1] == "/" && (r = new RegExp(t.slice(2).slice(0, -1))).test(text)) {
            translation = text.replace(r, obj[t]);
            break;
          }
        }
      }
    }

    return translation || orig;
  }

  public reverseTranslateString = (text : string) => {
    var translation = this.storage[this.currentLanguage];
    for (var property in translation) {
      if (translation.hasOwnProperty(property)) {
        if (translation[property] === text) {
          return property.replace(/#\d+$/, '');
        }
      }
    }
    return text;
  }

  public translateElement = (element:any) => {
    this.lukeNodeWalker(element);
    return element;
  }

  public T = (element:any, regexp?:RegExp) => {
    if (typeof (element) === 'string') {
      return this.translateString(element, regexp);
    }
    return this.translateElement(element);
  }
}
const i18n = new I18n()
export const T = i18n.T
for(const langFlag in langInfos) {
  i18n.addTranslation(langFlag, langInfos[langFlag as keyof typeof langInfos])
}
export default i18n