import jsonHelpers from "../../helpers/helpers.json"
import devLog from "../log"
import apis from "../apis"
import { GenericObjectStore, type GenericObject, type GetPropertiesObjectList, type ItemlistDetail, type Label, type MenuEntry, type shemaErrors } from "../genericObjectStore"
import { useStore } from '@/store/vuex.store'
import queries from "@/queries/queries"
import type { PromiseObject } from "@/helpers/helpers.promises"
import type { ProductType } from "@/resources/registeredProducts"
import router from "@/router/router"
import { ActionTypes } from "@/store/vuex.store"
import buttons from "../buttons"
import { T } from "../i18n"
import type { ZeroTouchDevice } from "./zeroTouchDevices"
import objectStores from "../init"
import moment from "moment"
import requestHandler from "@/queries/requests"
import type { License } from "../unifiedSecurity/licenses"
import { useVue } from "@/app"
import dialogs from "@/dialogs/dialogs"
import promiseHelpers from "@/helpers/helpers.promises"
import tenantHelpers from "@/helpers/helpers.tenants"
import numberHelpers from "@/helpers/helpers.numbers"
import { sprintf } from "sprintf-js"
import config from "../config"
import licenseHelpers from "@/helpers/helpers.license"
import deviceHelpers from "@/helpers/helpers.devices"
import products from "../objectTypes"

 

type EnterpriseValue = any

type ManagementMode = "MANAGEMENT_MODE_UNSPECIFIED" | "DEVICE_OWNER" | "PROFILE_OWNER"

type DeviceState = "DEVICE_STATE_UNSPECIFIED" |  "ACTIVE" |  "DISABLED" |  "DELETED" |  "PROVISIONING"
type NonComplianceReason = "NON_COMPLIANCE_REASON_UNSPECIFIED" |  "API_LEVEL" |  "MANAGEMENT_MODE" |  "USER_ACTION" |  "INVALID_VALUE" |  "APP_NOT_INSTALLED" |  "UNSUPPORTED" |  "APP_INSTALLED" |  "PENDING" |  "APP_INCOMPATIBLE" |  "APP_NOT_UPDATED"
type InstallationFailureReason = "INSTALLATION_FAILURE_REASON_UNSPECIFIED" |  "INSTALLATION_FAILURE_REASON_UNKNOWN" |  "IN_PROGRESS" |  "NOT_FOUND" |  "NOT_COMPATIBLE_WITH_DEVICE" |  "NOT_APPROVED" |  "PERMISSIONS_NOT_ACCEPTED" |  "NOT_AVAILABLE_IN_COUNTRY" |  "NO_LICENSES_REMAINING" |  "NOT_ENROLLED" |  "USER_INVALID"
interface NonComplianceDetail {
  "settingName": string,
  "nonComplianceReason": NonComplianceReason,
  "packageName": string,
  "fieldPath": string,
  "currentValue": EnterpriseValue,
  "installationFailureReason": InstallationFailureReason
}
interface UserFacingMessage {
  "localizedMessages": {
    [key:string]: string
  },
  "defaultMessage": string
}
type UpdateStatus = "UPDATE_STATUS_UNKNOWN" |  "UP_TO_DATE" |  "UNKNOWN_UPDATE_AVAILABLE" |  "SECURITY_UPDATE_AVAILABLE" |  "OS_UPDATE_AVAILABLE"
interface SystemUpdateInfo {
  "updateStatus": UpdateStatus,
  "updateReceivedTime": string
}
interface SoftwareInfo {
  "androidVersion": string,
  "androidDevicePolicyVersionCode": number,
  "androidDevicePolicyVersionName": string,
  "androidBuildNumber": string,
  "deviceKernelVersion": string,
  "bootloaderVersion": string,
  "androidBuildTime": string,
  "securityPatchLevel": string,
  "primaryLanguageCode": string,
  "deviceBuildSignature": string,
  "systemUpdateInfo": SystemUpdateInfo
}
interface HardwareInfo {
  "brand": string,
  "hardware": string,
  "deviceBasebandVersion": string,
  "manufacturer": string,
  "serialNumber": string,
  "model": string,
  "batteryShutdownTemperatures"?: number[],
  "batteryThrottlingTemperatures"?: number[],
  "cpuShutdownTemperatures"?: number[],
  "cpuThrottlingTemperatures"?: number[],
  "gpuShutdownTemperatures"?: number[],
  "gpuThrottlingTemperatures"?: number[],
  "skinShutdownTemperatures"?: number[],
  "skinThrottlingTemperatures"?: number[]
}
type DisplayState = "DISPLAY_STATE_UNSPECIFIED	" |  "OFF" |  "ON" |  "DOZE" |  "SUSPENDED"
interface Display {
  "name": string,
  "displayId": number,
  "refreshRate": number,
  "state": DisplayState,
  "width": number,
  "height": number,
  "density": number
}
type ApplicationEventType = "APPLICATION_EVENT_TYPE_UNSPECIFIED" |  "INSTALLED" |  "CHANGED" |  "DATA_CLEARED" |  "REMOVED" |  "REPLACED" |  "RESTARTED" |  "PINNED" |  "UNPINNED"
interface ApplicationEvent {
  "eventType": ApplicationEventType,
  "createTime": string
}
type Severity = "SEVERITY_UNSPECIFIED" |  "INFO" |  "ERROR"
interface KeyedAppState {
  "key": string,
  "severity": Severity,
  "message": string,
  "data": string,
  "createTime": string,
  "lastUpdateTime": string
}
type ApplicationSource = "APPLICATION_SOURCE_UNSPECIFIED" | "SYSTEM_APP_FACTORY_VERSION" | "SYSTEM_APP_UPDATED_VERSION" | "INSTALLED_FROM_PLAY_STORE"
type ApplicationState = "APPLICATION_STATE_UNSPECIFIED" | "REMOVED" | "INSTALLED"
interface ApplicationReport {
  "packageName": string,
  "versionName": string,
  "versionCode": number,
  "events": ApplicationEvent[],
  "displayName": string,
  "packageSha256Hash": string,
  "signingKeyCertFingerprints": string[],
  "installerPackageName": string,
  "applicationSource": ApplicationSource,
  "state": ApplicationState,
  "keyedAppStates":KeyedAppState[]
}
interface TelephonyInfo {
  "phoneNumber": string,
  "carrierName": string
}
interface NetworkInfo {
  "imei": string,
  "meid": string,
  "wifiMacAddress": string,
  "networkOperatorName": string,
  "telephonyInfos": TelephonyInfo[]
}
interface MemoryInfo {
  "totalRam": string,
  "totalInternalStorage": string
}
type MemoryEventType = "MEMORY_EVENT_TYPE_UNSPECIFIED" | "RAM_MEASURED" | "INTERNAL_STORAGE_MEASURED" | "EXTERNAL_STORAGE_DETECTED" | "EXTERNAL_STORAGE_REMOVED" | "EXTERNAL_STORAGE_MEASURED"
interface MemoryEvent {
  "eventType": MemoryEventType,
  "createTime": string,
  "byteCount": string
}
type PowerManagementEventType = "POWER_MANAGEMENT_EVENT_TYPE_UNSPECIFIED" | "BATTERY_LEVEL_COLLECTED" | "POWER_CONNECTED" | "POWER_DISCONNECTED" | "BATTERY_LOW" | "BATTERY_OKAY" | "BOOT_COMPLETED" | "SHUTDOWN"
interface PowerManagementEvent {
  "eventType": PowerManagementEventType,
  "createTime": string,
  "batteryLevel": number
}
interface HardwareStatus {
  "createTime": string,
  "batteryTemperatures": number[],
  "cpuTemperatures": number[],
  "gpuTemperatures": number[],
  "skinTemperatures": number[],
  "fanSpeeds": number[],
  "cpuUsages": number[]
}
type EncryptionStatus = "ENCRYPTION_STATUS_UNSPECIFIED" | "UNSUPPORTED" | "INACTIVE" | "ACTIVATING" | "ACTIVE" | "ACTIVE_DEFAULT_KEY" | "ACTIVE_PER_USER"
interface DeviceSettings {
  "isDeviceSecure": boolean,
  "unknownSourcesEnabled": boolean,
  "developmentSettingsEnabled": boolean,
  "adbEnabled": boolean,
  "isEncrypted": boolean,
  "encryptionStatus": EncryptionStatus,
  "verifyAppsEnabled": boolean
}
interface User {
  "accountIdentifier": string
}
type DevicePosture = "POSTURE_UNSPECIFIED" | "SECURE" | "AT_RISK" | "POTENTIALLY_COMPROMISED"
type SecurityRisk = "SECURITY_RISK_UNSPECIFIED" | "UNKNOWN_OS" | "COMPROMISED_OS"
interface PostureDetail {
  "securityRisk": SecurityRisk,
  "advice": UserFacingMessage[]
}
interface SecurityPosture {
  "devicePosture": DevicePosture,
  "postureDetails": PostureDetail[]
}
type Ownership = "OWNERSHIP_UNSPECIFIED" | "COMPANY_OWNED" | "PERSONALLY_OWNED"
type CommonCriteriaModeStatus = "COMMON_CRITERIA_MODE_STATUS_UNKNOWN" | "COMMON_CRITERIA_MODE_DISABLED" | "COMMON_CRITERIA_MODE_ENABLED"
interface CommonCriteriaModeInfo {
  "commonCriteriaModeStatus": CommonCriteriaModeStatus
}
type PasswordQuality = "PASSWORD_QUALITY_UNSPECIFIED" | "BIOMETRIC_WEAK" | "SOMETHING" | "NUMERIC" | "NUMERIC_COMPLEX" | "ALPHABETIC" | "ALPHANUMERIC" | "COMPLEX" | "COMPLEXITY_LOW" | "COMPLEXITY_MEDIUM" | "COMPLEXITY_HIGH"
type PasswordPolicyScope = "SCOPE_UNSPECIFIED" | "SCOPE_DEVICE" | "SCOPE_PROFILE"
type RequirePasswordUnlock = "REQUIRE_PASSWORD_UNLOCK_UNSPECIFIED" | "USE_DEFAULT_DEVICE_TIMEOUT" | "REQUIRE_EVERY_DAY"
interface PasswordRequirements {
  "passwordMinimumLength": number,
  "passwordMinimumLetters": number,
  "passwordMinimumLowerCase": number,
  "passwordMinimumNonLetter": number,
  "passwordMinimumNumeric": number,
  "passwordMinimumSymbols": number,
  "passwordMinimumUpperCase": number,
  "passwordQuality": PasswordQuality,
  "passwordHistoryLength": number,
  "maximumFailedPasswordsForWipe": number,
  "passwordExpirationTimeout": string,
  "passwordScope": PasswordPolicyScope,
  "requirePasswordUnlock": RequirePasswordUnlock
}
export interface EnterpriseDevice extends GenericObject<EnterpriseDevice> {

  "id": string,
  "deviceId": string,
  "alias"?: string,
  "licenseUUID"?: string,
  "licenseInformation"?: License | undefined,
  "consent"?: string,
  "signedIn"?: boolean,
  "username"?: string,
  "inventory"?: ObjectInventory

  "name": string
  "userName"?: string,
  "managementMode"?:ManagementMode,
  "state"?: DeviceState,
  "appliedState"?: DeviceState,
  "policyCompliant"?: boolean,
  "nonComplianceDetails"?: NonComplianceDetail[],
  "enrollmentTime"?: string,
  "lastStatusReportTime"?: string,
  "lastPolicyComplianceReportTime"?: string,
  "lastPolicySyncTime"?: string,
  "policyName"?: string,
  "appliedPolicyName"?: string,
  "appliedPolicyVersion"?: string,
  "apiLevel"?: number,
  "enrollmentTokenData"?: string,
  "enrollmentTokenName"?: string,
  "disabledReason"?: UserFacingMessage,
  "softwareInfo"?: SoftwareInfo,
  "hardwareInfo"?: HardwareInfo,
  "displays"?: Display[],
  "applicationReports"?: ApplicationReport[],
  "previousDeviceNames"?: string[],
  "networkInfo"?: NetworkInfo,
  "memoryInfo"?: MemoryInfo,
  "memoryEvents"?: MemoryEvent[],
  "powerManagementEvents"?: PowerManagementEvent[],
  "hardwareStatusSamples"?: HardwareStatus[],
  "deviceSettings"?: DeviceSettings,
  "user"?: User,
  "systemProperties"?: {
    [key:string]: string
  },
  "securityPosture"?: SecurityPosture,
  "ownership"?: Ownership,
  "commonCriteriaModeInfo"?: CommonCriteriaModeInfo,
  "appliedPasswordPolicies"?: PasswordRequirements[]
}


export interface EnterpriseDevicePlusZeroTouch extends EnterpriseDevice {
  "zeroTouchDevice":boolean
  "zeroTouchInfo":ZeroTouchDevice
}



export default class EnterpriseDevices extends GenericObjectStore<EnterpriseDevice | EnterpriseDevicePlusZeroTouch > {
  constructor() {

    super()
    this.settings.primaryKeyProperty = "id"
    this.settings.nameProperty.primary = "alias"
    this.settings.productType = "mobileSecurity"
    this.settings.objectType = "enterpriseDevices"
    this.settings.appearance.iconClass = "fal fa-mobile-alt"
    this.settings.appearance.text.singular = "Device"
    this.settings.appearance.text.plural = "Devices"
    this.settings.appearance.text.title = "Devices"
    this.settings.apiInfo.listPath = "/tenants/{tenantDomain}/android/emm/enterprise/devices"
    this.settings.apiInfo.objectListPropertyInResponse = () => { return apis.getApiVersionForFeature('androidEnterprise') == '2.0' ? "emm" : "devices" }

    this.settings.nameProperty.primary = "alias"
    this.settings.nameProperty.secondary = "id"

    this.itemlist.sortingOptions = [
      {
        "id": "alias",
        "text": "Name"
      },
      {
        "id": "id",
        "text": "ID"
      },
      {
        "id": "hardwareInfo.serial",
        "text": "Serial"
      },
      {
        "id": "hardwareInfo.model",
        "text": "Product name"
      }
    ]

    this.itemlist.getToolbar = (accountId,itemlistComponent) => {
      itemlistComponent = itemlistComponent?.exposed ? itemlistComponent?.exposed : itemlistComponent
      let result = <any>[]
      if (this.isEnrollmentAllowed(accountId) && itemlistComponent.accountRestrictions.value == 0) {
        result.push(
          {
            icon: 'fal fa-envelope',
            title: T('Send invite'),
            onclick: () => { dialogs.mobileSecurity.inviteDeviceDialog(accountId,'enterpriseDevices') },
            id: 'devicesButtonInvite',
            vIf: false
          },
          {
            icon: 'fal fa-plus',
            title: T('Enroll new device'),
            onclick: () => { dialogs.mobileSecurity.enrollDeviceDialog(accountId,'enterpriseDevices') },
            id: 'devicesButtonEnroll',
            vIf: false
          }
        )
      }
      else if (itemlistComponent.accountRestrictions.value > 0) {
        result.push(
          {
            icon: 'fal fa-exclamation-triangle',
            title: T('Enrollment disabled'),
            link: '',
            id: 'devicesButtonEnroll',
            disabled: true,
            vIf: false
          }
        )
      }
      else if (!this.isEnrollmentAllowed(accountId)) {
        result.push(
          {
            icon: 'fal fa-exclamation-triangle',
            title: T('You have reached the devices limit'),
            link: '',
            id: 'devicesButtonEnroll',
            disabled: true,
            vIf: false
          }
        )
      }
      result.push(
        {
          icon: 'fal fa-link',
          title: T('Assign licenses'),
          link: "#show-tenant-" + itemlistComponent.activeTenantDomain.value + "-licenses-dashboard",
          id: 'assignLicenses',
          vIf: "hasDevicesWithoutLicense"
        },
        {
          icon: 'fal fa-sync',
          title: T('OS update'),
          onclick: async function () {

            dialogs.misc.confirmDialog(
              this.activeAccountId, 
              "Confirm update", 
              T("The update process is a two-step process. Depending on the current status, the download or the installation of the update is initiated.") + "<br><br><i class='fal fa-exclamation-triangle color-red margin-xs-r'></i>" + T('The device uses mobile data when it is not in the WIFI.'), 
              async () => {
                let promiseObject: PromiseObject = {}
                itemlistComponent.activeItems.value.items.forEach((item: any) => {
                  if (item?.deviceId && item.deviceType == 'IOS' && item.availableUpdates?.length > 0 && item.info?.supervised) {
                    promiseObject[item.deviceId] = queries.mobileSecurity.sendDeviceMessage(itemlistComponent.activeAccountId.value, item.deviceId, {
                      "type": "SCHEDULE_OS_UPDATE",
                      "payload": {
                        "Updates": {
                          "InstallAction": "Default",
                          "ProductVersion": item.availableUpdates[0].ProductVersion
                        },
                        "RequestRequiresNetworkTether": false
                      }
                    })
                  }
                });
                await promiseHelpers.resolvePromiseObject(promiseObject)
              }
            )
          },
          id: 'updateDevices',
          vIf: "hasUpdatableDevices"
        }
      )

      return result
    }
    this.itemlist.getInfoBoxContent = (accountId: string, itemlistComponent: any) => {
      itemlistComponent = itemlistComponent?.exposed ? itemlistComponent?.exposed : itemlistComponent
      let result = ""

      if (itemlistComponent.hasNotEnrolledEnterpriseDevices()) {
        result += (result.length ? '<br>' : '') + '<i class="fal fa-fw fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;' + T("We have detected that one or more devices deny app installation with state NOT_ENROLLED. This either means the enterprise is no longer enrolled with Managed Google Play or the admin has not accepted the latest Managed Google Play Terms of Service.")
        result += " " + '<a href="https://play.google.com/work/termsofservice" target="_blank">' + T('Click here') + '</a>' + " "
        result += T('to accept the new Terms of Service.')
      }
      if (itemlistComponent.hasDevicesWithoutLicense()) {
        result += (result.length ? '<br>' : '') + '<i class="fal fa-fw fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;' + T("One or more devices are unlicensed, please assign a license to each of your devices.")
      }

      return result
    },
    this.itemlist.getTitle = (item: EnterpriseDevice|ZeroTouchDevice, component) => {
      component = component?.exposed ? component?.exposed : component
      let primaryName : string | undefined = ""
      let secondaryName : string | undefined = ""
      if((<ZeroTouchDevice>item)?.deviceIdentifier != undefined) {
        primaryName = item && this.settings.nameProperty.primary ? deviceHelpers.getShortDeviceId((<ZeroTouchDevice>item)[objectStores.zeroTouchDevices.settings.nameProperty.primary as keyof ZeroTouchDevice] as string) : ""
        secondaryName = undefined
      }
      else {
        primaryName = item && this.settings.nameProperty.primary ? (<EnterpriseDevice>item)[this.settings.nameProperty.primary] as string : ""
        secondaryName = deviceHelpers.getShortDeviceId((item && this.settings.nameProperty.secondary ? (<EnterpriseDevice>item)[this.settings.nameProperty.secondary] : item ? (<EnterpriseDevice>item).id : "") as string)
      }

      
      return {
        title: primaryName ? primaryName : secondaryName,
        small: primaryName ? secondaryName : "",
        link: item?.name.indexOf('enterprises') != -1 ? {
          "innerHtml": "<i class=\"fal fa-edit\"></i>",
          "onclick": function () {
            component.editAlias.value = true
          },
          "showIf": function () {
            if(component) {
              return component.editAlias.value == false
            }
            return false
          }
        } : undefined
      }
    }
    // setup itemlist options
    this.itemlist.isDisabled = (accountId,item): boolean => {
      if (item?.name.indexOf('enterprises') != -1) {
        item = item as EnterpriseDevice | EnterpriseDevicePlusZeroTouch
        if (item?.licenseUUID == '' && item?.signedIn) {
          return true
        }
        return false
      }
      return true
    }
    this.itemlist.isClickable = (accountId,item): boolean => {
      if (item?.name.indexOf('enterprises') != -1) {
        item = item as EnterpriseDevice | EnterpriseDevicePlusZeroTouch
        if (!licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid')) {
          return false
        }
        if (item?.licenseUUID == '' || item?.signedIn === false) {
          return false
        }
        return true
      }
      return false
    }
    this.itemlist.onClick = (accountId: string, item) => {
      let objectId = item[this.settings.primaryKeyProperty] as string
      if (item?.name.indexOf('enterprises') != -1) {
        item = item as EnterpriseDevice | EnterpriseDevicePlusZeroTouch
        if (item.consent == "" || item.consent != config.devicesConsentDate) {
          dialogs.mobileSecurity.assignDeviceOwnership(accountId, "mobileSecurity", "enterpriseDevices", item.id || "",item)
        }
        else {
          router.navigate('show-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-enterprise-device-' + objectId + '-details')
        }
      }
    }
    this.itemlist.hasCheckBox = (item) => {
      if (item?.name.indexOf('enterprises') != -1) {
        return true
      }
      return false
    }
    this.itemlist.getStatus = (accountId: string, item) => {
      return undefined
    }

    this.itemlist.getMenuEntries = (accountId: string, item) => {
      let menuEnties: MenuEntry[] = []
      if (item?.name.indexOf('enterprises') != -1) {
        item = item as EnterpriseDevice | EnterpriseDevicePlusZeroTouch
        if (item) {
          let objectId = item[this.settings.primaryKeyProperty] as string
          if (item.consent == "" || item.consent != config.devicesConsentDate) {
            menuEnties.push(
              buttons.createButton({
                "text": T('License conditions'),
                "title": T('License conditions'),
                "icon": 'fal fa-check-square',
                "onClick": () => {
                  dialogs.mobileSecurity.assignDeviceOwnership(accountId, "mobileSecurity", "enterpriseDevices", item.id || "",item)
                }
              })
            )
          }
          else {
            menuEnties.push(
              buttons.createButton({
                "text": T('Details'),
                "title": T('Details'),
                "icon": 'fal fa-info-circle',
                "onClick": () => {
                  router.navigate('show-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-enterprise-device-' + objectId + '-details-new')
                }
              })
            )
          }
          menuEnties.push(
            buttons.createButton({
              "text": T('Delete'),
              "title": T('Delete'),
              "icon": 'fal fa-trash',
              "onClick": () => {
                this.dialogs.getDeleteObjectDialog(accountId, item,true)
              }
            })
          )
        }
      }
      return menuEnties
    }
    this.dialogs.getDeleteObjectDialog = (accountId: string, object: EnterpriseDevice, confirm: boolean = false) => {
      let title = object.alias ? object.alias : deviceHelpers.getShortDeviceId(object.deviceId)
      let objectIdProperty = this.settings.primaryKeyProperty
      dialogs.misc.confirmDialog(accountId,
        T("Confirm delete"),
        sprintf(T("Do you want to delete device %s?"), title) + "<br>"
        + T("When you delete this device in the MDM portal, the following happens:<br>BYOD: Profile is removed<br>COPE/COBO/COSU: Device is being reset"),
        async () => {
          await this.deleteObjectFromApi(accountId, object[objectIdProperty])
        }, confirm ? T("Delete") : undefined)
    }

    this.itemlist.getLabels = (accountId: string, item: EnterpriseDevice | EnterpriseDevicePlusZeroTouch | ZeroTouchDevice | undefined) => {
      let result: Label[] = []
      if (item?.name.indexOf('enterprises') != -1) {
        item = item as EnterpriseDevice | EnterpriseDevicePlusZeroTouch
        var timeEnrolled = item?.enrollmentTime ? moment().unix() - moment(item.enrollmentTime).unix() : null
        if (timeEnrolled != null) {
          if (timeEnrolled < (60 * 60 * 24)) {
            result.push({
              title: T("New"),
              text: T("New"),
              class: "bg-green"
            })
          }
        }
        if (item.consent == "" || item.consent != config.devicesConsentDate) {
          result.push({
            title: T('Terms not accepted'),
            text: T('Terms not accepted'),
            class: 'bg-red'
          })
        }
        if (item?.appliedState != item?.state) {
          let state = item?.state == "DISABLED" ? T("Disabling...") : T('Activating...')
          result.push({
            title: state,
            text: state,
            class: "bg-yellow"
          })
        }
        else if (item?.appliedState == "DISABLED") {
          result.push({
            title: T("Disabled"),
            text: T("Disabled"),
            class: "bg-red"
          })
        }

        if (item?.signedIn === false) {
          result.push({
            title: T("Signed out"),
            text: T("Signed out"),
            class: "bg-yellow"
          })
        }

        if(!item?.licenseUUID) {
          result.push({
            title: T("Not licensed"),
            text: T("Not licensed"),
            class: "bg-red"
          })
        }
        //@ts-ignore
        if (item?.licenseInformation != undefined && !licenseHelpers.isLicenseValid(item.licenseInformation)) {
          result.push({
            title: T("License expired"),
            text: T("License expired"),
            class: "red"
          })
        }

        if ((<EnterpriseDevicePlusZeroTouch>item)?.zeroTouchDevice) {
          result.push({
            title: T("Zero Touch"),
            text: T("Zero Touch"),
            class: ""
          })
        }
      }
      else {
        item = item as ZeroTouchDevice
        result.push({
          title: T("Zero Touch"),
          text: T("Zero Touch"),
          class: ""
        })
      }

      return result
    }
    this.itemlist.getDetails = (accountId: string, item:EnterpriseDevice|EnterpriseDevicePlusZeroTouch|ZeroTouchDevice|undefined,component) => {
      let result: ItemlistDetail[] = []
      component = component?.exposed ? component?.exposed : component
      if(item?.name.indexOf('enterprises') != -1) {
        item = item as EnterpriseDevice | EnterpriseDevicePlusZeroTouch
        let operatingMode: string = ""
        if(item?.managementMode === "PROFILE_OWNER" && item?.ownership === "COMPANY_OWNED") {
          operatingMode = "Profile Owner (COPE)"
        }
        else if(item?.managementMode === "PROFILE_OWNER") {
          operatingMode = "Profile Owner (BYOD)"
        }
        else if(item?.managementMode === "DEVICE_OWNER") {
          operatingMode = "Device Owner (COBO)"
        }

        result = [
          {
            iconClass: 'fal fa-fw fa-microchip',
            title: T('Model'),
            key: T('Model'),
            value: item ?
              item?.hardwareInfo?.brand ? item?.hardwareInfo?.brand + " - " + item?.hardwareInfo?.model : item?.hardwareInfo?.model || ""
              : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
          },
          {
            iconClass: 'fal fa-fw fa-hashtag',
            title: T('OS'),
            key: T('OS'),
            value: item ?
              item?.softwareInfo?.androidVersion ? "Android " + item?.softwareInfo?.androidVersion : ""
              : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
          },
          {
            iconClass: 'fal fa-fw fa-hashtag',
            title: T('Serial Number'),
            key: T('Serial Number'),
            value: item ? (item?.hardwareInfo?.serialNumber || "") : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
          },
          {
            iconClass: 'fal fa-fw fa-hashtag',
            title: T('IMEI'),
            key: T('IMEI'),
            value: item ? (item?.networkInfo?.imei || "") : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
          },
          {
            iconClass: 'fal fa-fw fa-address-card',
            title: T('Ownership'),
            key: T('Ownership'),
            value: item ? (item.managementMode == "DEVICE_OWNER" ? 'COPE' : item.ownership == "COMPANY_OWNED" ? "COPE" : 'BYOD') : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
          },
          {
            iconClass: 'fal fa-fw fa-address-card',
            title: T('Operating mode'),
            key: T('Operating mode'),
            value: item ? operatingMode : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
          },
          {
            iconClass: 'fal fa-fw fa-user',
            title: T('User'),
            key: T('User'),
            value: item ? item.username || '' : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
            editableContent: licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid') ? ({
              "type": "select",
              "options": "users",
              "ref": "editUser",
              "value": item?.username,
              "editingBoolProperty": "editUsername",
              "editButton": buttons.createButton({
                "onClick": function () {
                  component.editUsername.value = true
                },
                "icon": "fal fa-edit"
              }),
              "submitFunction": async function (value: string) {
                let payload = {
                  "accountId": accountId,
                  "objectId": (<EnterpriseDevice>item)?.id || "",
                  "productType": <ProductType>"mobileSecurity",
                  "objectType": "enterpriseDevices",
                  "queryType": "enterpriseDeviceProperties",
                  "value": value === 'noUserSelected' ? '' : value,
                  "object": { 'username': value },
                  "property": "username"
                }
                try {
                  await useStore()?.dispatch(ActionTypes.updateObjectProperty, payload)
                  component.editUsername.value = false
                }
                catch(e:any) {
                  component.editUsername.value = false
                  component.error.value = true
                  console.error(e)
                  setTimeout(() => {
                    component.error.value = false
                  }, 2000)
                }
              },
              "abortFunction": function () {
                component.editUsername.value = false
              }
            })
              : undefined
          },
          {
            iconClass: 'fal fa-fw fa-shield-alt',
            title: T('Device profile'),
            key: T('Device profile'),
            [item ? 'labels' : 'value']: item ?
              [
                {
                  "id": "test",
                  "text": this.getPolicyName(item) || T("Unknown"),
                  "title": this.getPolicyName(item) || T("Unknown"),
                  "icon": this.getPolicyStatus(item) != "INSTALLED" ? "fa fa-exclamation-triangle" : undefined,
                  "onClick": () => {
                    router.navigate('edit-tenant-'+accountId+'.sms-android-profile-enterprise_' + this.getPolicyName(item as EnterpriseDevice))
                  },
                  "displayType": "label"
                }
              ]
              : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
          }]

          if ((<EnterpriseDevicePlusZeroTouch>item)?.zeroTouchDevice == true) {
            item = item as EnterpriseDevicePlusZeroTouch
            let objectId = (<EnterpriseDevicePlusZeroTouch>item)?.zeroTouchInfo?.configuration?.split('/')[3] || undefined
            let configuration: any = undefined

            if (objectId) {
              
              configuration = config.canUseNewObjectType("zeroTouchConfigurations") ? products.mobileSecurity.zeroTouchConfigurations.useStore?.().getObjectStoreObject(accountId,objectId) : useStore()?.getters.getObject({
                "accountId": accountId,
                "productType": "mobileSecurity",
                "objectType": "zeroTouchConfigurations",
                "objectId": objectId
              })
            }

            result.push(
              {
                iconClass: 'fal fa-fw fa-wrench',
                title: 'Zero-Touch',
                key: 'Zero-Touch',
                value: item ? (configuration ? configuration.configurationName : "") : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
              }
            )
          }
          if (item?.licenseUUID && item?.licenseUUID != '' && item?.licenseInformation?.type != undefined) {
            result.push({
                iconClass: 'fal fa-fw fa-key',
                title: T('License'),
                key: T('License'),
                [item ? "labels" : "value"]: item ? [{
                  "id": item.licenseUUID + "_" + item.licenseInformation?.name,
                  "text": item.licenseInformation?.name + " (" + deviceHelpers.getShortDeviceId(String(item?.licenseInformation?.id)) + ")",
                  "title": item.licenseInformation?.name + " (" + deviceHelpers.getShortDeviceId(String(item?.licenseInformation?.id)) + ")",
                  "onClick": () => {
                    router.navigate('show-tenant-' + accountId + '.sms-license-' + (item as EnterpriseDevice|EnterpriseDevicePlusZeroTouch)?.licenseInformation?.id + '-details')
                  },
                  "displayType": "label"
                }] : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
              })
          } else {
            result.push({
              iconClass: 'fal-fa-fw fa-key',
              title: T('License'),
              key: T('License'),
              [item ? "labels" : "value"]: item ? [{
                "id": "",
                "text": T("Not licensed"),
                "title": T("Not licensed"),
                "displayType": "label"
              }] : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
            })
          }
            result.push({
              iconClass: 'fal fa-fw fa-clock',
              title: T('Contact'),
              key: T('Contact'),
              value: item ? undefined : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
              regularlyUpdatedData: !item ? undefined : {
                "inputValue": item ? item.lastStatusReportTime : undefined,
                "method": (inputValue: any) => {
                  return inputValue ? moment(inputValue).fromNow() : ""
                },
                "refreshAfterMs": 10000
              }
            })

      }
      else {
        item = item as ZeroTouchDevice
        result = [
          {
            iconClass: 'fal fa-fw fa-microchip',
            title: T('Model'),
            key: T('Model'),
            value: item ? (<ZeroTouchDevice>item).deviceIdentifier?.model || "" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
          },
          {
            iconClass: 'fal fa-fw fa-hashtag',
            title: T('OS'),
            key: T('OS'),
            value: item ? "Android" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
          },
          {
            iconClass: 'fal fa-fw fa-hashtag',
            title: T('Serial Number'),
            key: T('Serial Number'),
            value: item ? ((<ZeroTouchDevice>item).deviceIdentifier?.serialNumber || "") : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
          },
          {
            iconClass: 'fal fa-fw fa-hashtag',
            title: T('IMEI'),
            key: T('IMEI'),
            value: item ? ((<ZeroTouchDevice>item).deviceIdentifier?.imei || "") : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
          },
          {
            iconClass: 'fal fa-fw fa-address-card',
            title: T('Ownership'),
            key: T('Ownership'),
            value: item ? "" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
          },
          {
            iconClass: 'fal fa-fw fa-user',
            title: T('User'),
            key: T('User'),
            value: item ? '' : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
          },
          {
            iconClass: 'fal fa-fw fa-shield-alt',
            title: T('Device profile'),
            key: T('Device profile'),
            ['value']: item ? "" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
          },
          {
            iconClass: 'fal fa-fw fa-key',
            title: T('License'),
            key: T('License'),
            value: item ? "" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
          },
          {
            iconClass: 'fal fa-fw fa-clock',
            title: T('Contact'),
            key: T('Contact'),
            value: item ? "" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
          }
        ]
      }
      
      return result
    }
  }

  isEnrollmentAllowed(accountId:string) {
    return <boolean|undefined>useStore()?.state.session.accounts[accountId]?.mobileSecurity?.settings?.enrollmentAllowed && licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid') || false
  }

  getPolicyName(object:EnterpriseDevice) {
    let result : string = ""
    if (object) {
      if (object.policyName && object.appliedPolicyName) {
        if (object.policyName == object.appliedPolicyName) {
          result = object.policyName.split('/')[3]
        }
        else {
          result = object.policyName.split('/')[3]
        }
      }
      else if (object.appliedPolicyName) {
        result = object.appliedPolicyName.split('/')[3]
      }
      else if (object.policyName) {
        result = object.policyName.split('/')[3]
      }
      
      if(result && result != "") {
        let policy = useStore()?.getters.getObject({
          "accountId":useStore()?.state.session.activeAccountId || "",
          "objectId":result,
          "objectType":"profiles",
          "productType":"mobileSecurity"
        })
        if (jsonHelpers.getObjectProperty(policy, "policy.type") == "autogenerated policy") {
          result = result.replace(/\^(.*)/, " ($1)")
        }
      }
    }


    return result
  }
  
  getPolicyStatus(object:EnterpriseDevice) {
    if (object) {
      if (object.policyName && object.appliedPolicyName) {
        if (object.policyName == object.appliedPolicyName) {
          return "INSTALLED"
        }
        else {
          return "PENDING"
        }
      }
      else if (object.appliedPolicyName) {
        return "INSTALLED"
      }
      else if (object.policyName) {
        return "PENDING"
      }
      return "UNKOWN STATUS"
    }
    return undefined
  }

  async getCountFromApi(accountId: string, updateLocalStore: boolean = true) {
    let result: number | Error = 0
    try {
      let countSelectStr = apis.getApiVersionForFeature('androidEnterprise') == '1.1' ? "&select=data.count" : "&select=data.count"
      const countResult = await requestHandler.request("GET", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('androidEnterprise') + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/android/emm/enterprise/devices?fields=devices.name&props[]=name"+countSelectStr)
      if (typeof countResult === "number") {
        result = countResult

        if (updateLocalStore) {
          this.setCount(accountId, countResult)
        }
      }
      else if (countResult.error) {
        throw new Error(countResult.error)
      }
      else {
        throw new Error('Error getting count for EnterpriseDevices')
      }
    }
    catch(e:any) {
      if (e instanceof Error) {
        result = 0//new Error(e.message)
      }
      else {
        result = 0//new Error('Error getting count for users')
      }
      devLog.log("UsersStoreClass", e.message, result, "error")
    }
    return result
  }

  async getObjectsInBatchesFromApi(accountId: string, props?: GetPropertiesObjectList | undefined,plusZeroTouchDevices:boolean=false,customerId?:any,ztProps?:GetPropertiesObjectList) {
      let result = true

      let pageSize = props?.find((prop) => { return prop.property == "pageSize" })?.value as string | undefined

      if (pageSize == undefined) {
        props?.push({
          "property": "pageSize",
          "value": "75"
        })
      }
      const propertiesString: string = props ? this.getPropertiesString(props) : ""

      let nextPageToken: string | undefined

      let batchLoop = async (batchProps: string, pageToken?: string) => {
        let response = await requestHandler.request("GET", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('androidEnterprise') + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/android/emm/enterprise/devices/" + propertiesString + (pageToken ? "&nextPageToken=" + pageToken : ""))
        response = apis.parseApiResponse(response)
        if (response.emm) {
          this.addOrUpdateObjectsInStore(accountId, response.emm)
        }
        else if (response.devices) {
          this.addOrUpdateObjectsInStore(accountId, response.devices)
        }

        if (response.nextPageToken != undefined) {
          await batchLoop(batchProps, response.nextPageToken)
        }
        else if (useVue().$refs?.itemlist?.refreshCounter != undefined) {
          useVue().$refs.itemlist.refreshCounter++
        }
        return true
      }
      await batchLoop(propertiesString)
      objectStores.zeroTouchDevices.getObjectsFromApi(accountId, ztProps, true, apis.getApiVersionForFeature('zeroTouch') == "2.0", customerId)
  }


  async getObjectFromApi(accountId: string, objectId: EnterpriseDevice[keyof EnterpriseDevice], props?: GetPropertiesObjectList, updateLocalStore: boolean = true) {
    let result: EnterpriseDevice | Error
    try {
      
      const propertiesString: string = props ? this.getPropertiesString(props) : ""
      let response = await requestHandler.request("GET", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('androidEnterprise') + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/android/emm/enterprise/devices/" + encodeURI(objectId as string) + propertiesString)
      response = apis.parseApiResponse(response)
      if (response.deviceId) {
        result = response as EnterpriseDevice
        if (updateLocalStore) {
          this.addOrUpdateObjectsInStore(accountId, result) // Add EnterpriseDevice to store
        }
      }
      else if (response.error) {
        throw new Error(response.error)
      }
      else {
        throw new Error('Error getting EnterpriseDevice')
      }
    }
    catch(e:any) {
      if (e instanceof Error) {
        result = new Error(e.message)
      }
      else {
        result = new Error('Error getting EnterpriseDevice')
      }

      devLog.log("EnterpriseDevicesStoreClass", (<Error>result).message, result, "error")
    }
    return result
  }


  async updateObjectFromApi(accountId: string, objectId: string, object: EnterpriseDevice, updateLocalStore: boolean = true, updateProperties?: boolean, properties?: string[],propertyPath?:string[]) {
    let result: EnterpriseDevice | Error | shemaErrors
    let updateMaskString = ""
    let response : any = undefined
    try {
      if (Array.isArray(properties)) {
        properties.forEach((property,i) => {
          if (i == 0) {
            updateMaskString += "?updateMask[]="
          }
          else {
            updateMaskString += "&updateMask[]="
          }
          updateMaskString += property
        })
      }
      else {
        let i: number = 0
        for (let key in object) {
          if (i == 0) {
            updateMaskString += "?updateMask[]="
          }
          else {
            updateMaskString += "&updateMask[]="
          }
          updateMaskString += key
          i++
        }
      }
        
      response = await requestHandler.request("PUT", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('androidEnterprise') + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/android/emm/enterprise/devices/" + encodeURI(objectId) + (updateProperties ?  "/properties" + updateMaskString : updateMaskString), object)
      response = apis.parseApiResponse(response)
      if (response.errors) { 
        throw response.errors 
      }
      else if (response.error) { 
        throw new Error(response.error) 
      }
      result = response
      //if (updateLocalStore) this.addOrUpdateObjectsInStore(accountId, object) // Add User to store
    }
    catch(e:any) {
      if (e instanceof Error) {
        result = new Error(e.message)
        devLog.log("EnterpriseDevicesStoreClass", (<Error>result).message, result, "error")
      }
      else {
        result = e as shemaErrors
        devLog.log("EnterpriseDevicesStoreClass", "Error updating EnterpriseDevice", result, "error")
      }
    }
    return apis.parseApiResponse(result)
  }

  async deleteObjectFromApi(accountId: string, objectId: EnterpriseDevice[keyof EnterpriseDevice], updateLocalStore: boolean = true) {
    let result: true | Error
    try {
      let response = await requestHandler.request("DELETE", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('androidEnterprise') + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/android/emm/enterprise/devices/" + encodeURI(objectId as string))
      response = apis.parseApiResponse(response)
      if (response.error) {
        throw new Error(response.error)
      }
      result = true
      if (updateLocalStore) {
        this.removeObjectFromStore(accountId, objectId) // Remove EnterpriseDevice from store
      }
    }
    catch(e:any) {
      if (e instanceof Error) {
        result = new Error(e.message)
      }
      else {
        result = new Error('Error deleting EnterpriseDevice')
      }
      devLog.log("EnterpriseDevicesStoreClass", result.message, result, "error")
    }
    return apis.parseApiResponse(result)
  }

  async updateObjectInventory(accountId: string, objectId: string, object: any): Promise<boolean | Error | shemaErrors> {
    let result = false
    try {
      let thisResult = await this.updateObjectFromApi(accountId,objectId,object,false,true,undefined)
      if(thisResult.code == 200) {
        result = true
        let device = this.getObjectFromStore(accountId,objectId)
        if(device.inventory == undefined) {
          device.inventory = {}
        }
        else {
          let keys = Object.keys(object)
          keys.forEach((key) => {
            let value : any

            if (key == 'inventoryLocation') {
              value = JSON.parse(object[key])
            }
            else {
              value = object[key]
            }

            (<any>device.inventory)[key as keyof typeof device.inventory] = value
          })
        }
      }
      else {
        result = thisResult
      }
    }
    catch(e:any) {
        result = e
    }
    return result
  }

  addGenericObjectInfos(accountId:string,item: EnterpriseDevice) {
    if (item?.$itemlist == undefined) item.$itemlist = {
      "isCheckboxChecked": false,
      "isCheckboxHovering": false
    }
    if (item?.id == undefined) item.id = item?.name?.split("/")[3]
    if (item?.licenseUUID != undefined) item.licenseInformation = licenseHelpers.getLicenseByUUID(accountId,item.licenseUUID) 

    return item as EnterpriseDevice
  }
}