import { T } from "@/classes/i18n";
import { useGlobalMixin } from "@/mixins/mixins.global";
import { ActionTypes } from "@/store/vuex.store";
import { MutationTypes } from "@/store/vuex.store";
import { useStore } from '@/store/vuex.store';

const usersettingsComponent = {
	mixins: [useGlobalMixin()],
	"name": "usersettings",
	"data": function () {
		return {
			"showUsersettings": false
		}
	},
	"computed": {
		"darkmode":{
			get: function (this: any) {
				return useStore().state.browserSettings.darkmode == "1"
			},
			set: function (this: any, darkmode:boolean) {
				useStore().dispatch(ActionTypes.setDarkmode, darkmode ? "1" : "0")
			}
		}
	},
	"template": `
		<div class="menu" v-on:click.stop>
			<ul>
				<li>
					<a class="toggle" v-on:click="toggleShowUsersettings()">
							<i class="fal fa-cogs"></i>
					</a>
					<transition name="notifications"
							:duration="{ enter:300, leave:300 }"
							enterActiveClass="notifications-entering"
							enterToClass="notifications-enter-to"
							leaveActiveClass="notifications-leaving"
							leaveToClass="notifications-leave-to"
					>
						<ul v-on:blur="hideUsersettings()" class="menu" v-if="showUsersettings" :key="'default'">
							<li>
								<div class="padding-xs-2 border-bottom">
									<i class="fal fa-cogs color-red"></i>&nbsp;&nbsp; <strong>{{ T('Interface settings') }}</strong>
								</div>
								<div class="padding-xs-2">
									<div class="row form-group">
										<div class="first col-xs-12">
												<label class="control-label inputname" for="darkmodeToggle">
													${T('Darkmode')}
												</label>
										</div>
										<div class="input col-xs-12 toggle text-right">
											<label class="form-field margin-xs-b-0 checkbox toggle">
												<input id="darkmodeToggle" name="darkmodeToggle" type="checkbox" class="form-control checkbox toggle user-selection" v-model="darkmode">
												<span></span>
											</label>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</transition>
				</li>		
			</ul>
		</div>
	`,
	"methods": {
		"toggleShowUsersettings": function (this: any) {
			this.showUsersettings = !this.showUsersettings
		},
		"hideUsersettings": function (this: any) {
			this.showUsersettings = false
		}
	},
	mounted: function (this: any) {
		useStore().commit(MutationTypes.addOnClickHandler, {
			"id": "usersettings",
			"ref": "root",
			"method": () => {
				if (this.showUsersettings) {
					this.hideUsersettings()
				}
			}
		})
	}
}
export default usersettingsComponent