<script setup lang="ts">
    import { useStore } from '@/store/vuex.store';
    import { T } from "@/classes/i18n";
    import router from "@/router/router";
    import { ActionTypes } from "@/store/vuex.store";
    import loaderComponent from "../components/loader.vue";
    import { sprintf } from "sprintf-js";
    import stringHelpers from '@/helpers/helpers.strings';
    import tenantHelpers from '@/helpers/helpers.tenants';
    import licenseHelpers from '@/helpers/helpers.license';
    import { computed, onMounted, ref } from 'vue';
import deviceHelpers from '@/helpers/helpers.devices';
import config from '@/classes/config';
import products from '@/classes/objectTypes';

    const initialized = ref(false)
    const loading = ref(true)

    const getNoItemsText = () => {
        return sprintf(T("No %s found"), T('Warnings'))
    }
    const openLink = (link: string|undefined) => {
        if (link) {
            router.navigate(link)
        }
    }


    const activeAccountId = computed(() => {
        return useStore().state.session.activeAccountId
    })

    const warnings = computed(function() {
        let result: {
            "timestamp": number,
            "productType": string,
            "objectType": string,
            "title": string,
            "text": string,
            "type": "warning" | "error" | "info",
            "link": string | undefined
        }[] = []

        let devices : any[] = []
        
        
        let iosDevices = config.canUseNewObjectType("iosDevices") ? products.mobileSecurity.iosDevices.useStore?.().getObjectStoreObjects(activeAccountId.value || "") : useStore().getters.getObjectTypeStore({ "accountId": activeAccountId.value, "objectType": "devices" })?.items as any[] || []
        if(iosDevices) { devices = devices.concat(iosDevices) }

        let androidDevices = products.mobileSecurity.androidDevices.useStore?.().getObjectStoreObjects(activeAccountId.value || "")
        if (androidDevices) { devices = devices.concat(androidDevices) }

        devices.forEach((device: any) => {
            
            let deviceName = deviceHelpers.getAliasedShortDeviceId(device.deviceId, device?.alias || device?.info?.deviceName || undefined)
            let deviceLink = "#show-tenant-" + activeAccountId.value + ".sms" + (device.enterpriseDevice ? "-android-device-" : "-ios-device-") + device.deviceId + "-details"

            if ((device?.availableUpdates || []).length > 0) {
                result.push({
                    "timestamp": Date.now(),
                    "productType": "mobileSecurity",
                    "objectType": "devices",
                    "title": deviceName + ": " + T("OS Update"),
                    "text": "Version " + device.availableUpdates[0].ProductVersion + ' ' + T("is available for this device") + ".",
                    "type": "info",
                    "link": deviceLink
                })
            }

            if (['AT_RISK', 'POTENTIALLY_COMPROMISED'].indexOf(device?.enterpriseInfo?.securityPosture?.devicePosture) != -1) {
                let message = ""
                if (device?.enterpriseInfo?.securityPosture?.postureDetails?.length) {
                    device?.enterpriseInfo?.securityPosture?.postureDetails.forEach((postureDetail: any) => {
                        if (postureDetail.securityRisk) message += T(stringHelpers.capitalizeFirstLetter(postureDetail.securityRisk.replace('_', ' '))) + ".<br>"
                        if (postureDetail.advice?.length) message += T(postureDetail.advice[0].defaultMessage) + "<br>"
                    });
                }

                result.push({
                    "timestamp": Date.now(),
                    "productType": "mobileSecurity",
                    "objectType": "devices",
                    "title": deviceName + ": " + T(stringHelpers.capitalizeFirstLetter(device?.enterpriseInfo?.securityPosture?.devicePosture.replace('_', ' '))),
                    "text": T(message),
                    "type": "warning",
                    "link": deviceLink
                })
            }

            if (device?.profile?.status == "PARTIALLY INSTALLED") {
                result.push({
                    "timestamp": Date.now(),
                    "productType": "mobileSecurity",
                    "objectType": "devices",
                    "title": deviceName + ": " + T("profile partially installed"),
                    "text": T("The profile is partially installed on this device."),
                    "type": "warning",
                    "link": deviceLink
                })
            }

            (device.applicationReports || []).forEach((applicationReport: any) => {
                (applicationReport.keyedAppStates || []).forEach((keyedAppState: any) => {
                    if (keyedAppState.severity == "ERROR") {
                        result.push({
                            "timestamp": Date.now(),
                            "productType": "mobileSecurity",
                            "objectType": "devices",
                            "title": deviceName + ": " + "App error",
                            "text": keyedAppState.message,
                            "type": "error",
                            "link": deviceLink
                        })
                    }
                });
            });
        });
        return result
    })

    onMounted(async () => {
        let requestObjectTypes: string[] = []
        if (licenseHelpers.hasOneOfLicenses(activeAccountId.value || '', ['Mobile Security', 'MDM'], 'valid')) {
            if(config.canUseNewObjectType("iosDevices")) {
                await products.mobileSecurity.iosDevices.queries.getObjectsFromApi(activeAccountId.value || "")
            }
            else {
                requestObjectTypes.push(`devices?props[]=deviceId&props[]=deviceType&props[]=iOSPerUserChannelProfiles&props[]=signedIn&props[]=profile&props[]=ownership&props[]=signedIn&props[]=info&props[]=configured&props[]=licenseUUID&props[]=username&props[]=alias&props[]=availableUpdates`)
            }
            await products.mobileSecurity.androidDevices.queries.getObjectsFromApi(activeAccountId.value || "")
        }

        if (requestObjectTypes.length) {
            await useStore().dispatch(ActionTypes.getObjectInfos, {
                "accountId": activeAccountId.value,
                "objectTypes": requestObjectTypes
            })
        }

        initialized.value = true
    })

</script>
<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div class="row">
            <div class="col-xs-24">
                <template v-if="initialized">
                    <template v-for="warning in warnings">
                        <div 
                            :class="{ 'clickable': warning.link }"
                            class="box box-shadow bg-white margin-xs-b"
                            v-on:click="openLink(warning.link)"
                        >
                            <div class="box-body">
                                <div class="box-icon-left">
                                    <template v-if="warning.type == 'info'">
                                        <div class="icon-circle bg-blue">
                                            <i class="fal fa-info"></i>
                                        </div>
                                    </template>
                                    <template v-if="warning.type == 'warning'">
                                        <div class="icon-circle bg-yellow">
                                            <i class="fal fa-bell"></i>
                                        </div>
                                    </template>
                                    <template v-if="warning.type == 'error'">
                                        <div class="icon-circle bg-red">
                                            <i class="fal fa-exclamation-triangle"></i>
                                        </div>
                                    </template>
                                </div>
                                <div class="box-content-2">
                                    <strong>
                                        {{ warning.title }}
                                    </strong>
                                    <br>
                                    <p>
                                        {{ warning.text }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="warnings.length == 0">
                        <div class="text-center padding-xs-y-8 margin-xs-t-8 margin-xs-b-4 col-xs text-size-2">
                            <p>
                                {{ getNoItemsText() }}
                            </p>
                        </div>
                    </template>
                </template>

                <div
                    v-else
                    class="loaderbox text-center"
                >
                    <loaderComponent class="color-red"></loaderComponent>
                </div>
            </div>
        </div>
    </section>
</template>