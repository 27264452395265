<script setup lang="ts">
    import { T } from '@/classes/i18n';
    import getterHelpers from '@/helpers/helpers.getters';
    import { computed, ref, watch } from 'vue';
    import inputPin from '../inputtypes/input-pin.vue';    

    const activeAccountId = computed(() => {
        return getterHelpers.useStore()?.state.session.activeAccountId || ''
    })

    const onEnter = () => {
        document.getElementById('dialogbutton-1-publishConfig')?.click()
    }

    const errors = ref(<string[]>[])

    const setError = (error: string) => {
        errors.value.push(error)
    }
    const clearErrors = () => {
        errors.value = []
    }
    const clearPin = () => {
        pin.value = ""
    }

    const pin = ref("")
    watch(pin, () => {
        if (pin.value.length == 6) {
            getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = false
        }
        else {
            getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = true
        }
    })


    defineExpose({
        pin, 
        setError,
        clearErrors,
        clearPin
    })

</script>
<template>
    <div class="padding-xs">
        <p class="text-center margin-xs-b-2">
            {{ T('This UTM requires a PIN for authentication.') }}
            {{ T('To increase security for actions that could disrupt ongoing operations, the new authentication layer is mandatory from UTM version 12.5.2.') }}
            {{ T('The PIN is identical to the websession PIN and is initially 000000 until the first login to the administration web interface.') }}
        </p>
        <div class="text-center">
            <template v-for="error in errors">
                <p class="label bg-red">
                    <i class="fal fa-fw fa-exclamation"></i> {{ T(error) }}
                </p>
                <br>
            </template>
        </div>
        <div id="pinInputRow" class="margin-xs-t-4 margin-xs-b-2">
            <inputPin v-model="pin" :pinLength="6" :isDisabled="false" :numbersOnly="true" :focusOnMount="true"  v-on:submit="onEnter" :hidePinOnMount="true" :canToggleVisibility="true">
            </inputPin>
        </div>
    </div>
</template>
<style lang="scss">
    .sms-table.table-next.small {
        td {
            padding:8px;
            i.icon {
                margin-top:-3px;
            }
        }
    }
    .iconWrapper-publish-state {
        display: inline-block;
        width:1.75em;
        height:1.75em;
        line-height:1.75em;
        text-align: center;
        border-radius: 50%;
        font-size:0.9em;
    }
    #pinInputRow {
        display: flex;
        justify-content: center;
        justify-items: center;
    }
</style>