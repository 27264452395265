import { useStore } from '@/store/vuex.store'
import moment from "moment"
import requestHandler from "./requests"
import tenantHelpers from '@/helpers/helpers.tenants'
import config from '@/classes/config'
import useSessionStore from '@/classes/storeSession'

const unifiedSecurityConsoleQueries = {
    sync: async function (accountId:string) {
        let tenantDomain = tenantHelpers.getTenantDomain(accountId)
        return await requestHandler.request("POST", "/sms-mgt-api/api/" + config.mgtApiVersionNext + "/tenants/" + tenantDomain + "/laas/synchronize")
    },
    getObjectInfos: async function (accountid : string, objectType : string, props?:any[],force:boolean=false) {
        let result : any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if(useStore()?.getters.hasAccountid(accountid) == false) {
                throw { "status": 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            let timeStamp : number = moment().unix()

            let propertiesString : string = ""

            if(force === true) {
                propertiesString += "?_="+timeStamp
            }

            (props || []).forEach(function(prop:any) {
                if(propertiesString.length == 0) {
                    propertiesString += "?"+prop.property+"="+prop.value
                }
                else {
                    propertiesString += "&"+prop.property+"="+prop.value
                }

            })

            switch(objectType) {
                case 'ccutmStates':
                    result = await requestHandler.request("GET", "/sms-mgt-api/api/"+config.mgtApiVersionNext+"/tenants/" + tenantDomain + "/utms/state"+propertiesString)
                break;
                case 'tags':
                    let results = await Promise.all([
                        requestHandler.request("GET", "/sms-mgt-api/api/" + config.mgtApiVersionNext + "/tenants/" + tenantDomain + "/utms?props[]=tags&select=data.utms[?tags].tags[]"),
                        requestHandler.request("GET", "/sms-mgt-api/api/" + config.mgtApiVersionNext + "/tenants/" + tenantDomain + "/utms/profiles?props[]=tags&select=data.utmProfiles[?tags].tags[]"),
                    ])

                    let tags: string[] = []
                    tags = results[0]?.length > 0 ? tags.concat(results[0]) : tags
                    tags = results[1]?.length > 0 ? tags.concat(results[1]) : tags

                    // filter out duplicates
                    result = tags.filter(function (value: any, index: any, self: any) {
                        return self.indexOf(value) === index;
                    })
                break;
            }
        }
        catch(e:any) {
            if(e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    },

    updateObjectInfo: async function (accountid : string, objectType : string, objectId : string, object : any,updateMask? : string[]) {
        let result : boolean = false
        let results : any = []
        let updateMaskString : string = ""
        let payload : any = ""
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if(useStore()?.getters.hasAccountid(accountid) == false) {
                throw {
                    "status": 401
                }
            }
            let tenantDomain : string = tenantHelpers.getTenantDomain(accountid)
            switch(objectType) {
                case 'ccutmProperties':
                case 'utmProperties':
                    if(updateMask) {
                        results = []
                        for(let i in updateMask) {
                            let property : string = updateMask[i]
                            payload = {}
                            payload[property] = object[property]

                            if(property == 'username' && object[property] == "") {
                                results[i] = await requestHandler.request("DELETE", "/sms-mgt-api/api/"+config.mgtApiVersion+"/tenants/" + tenantDomain + "/laas/utms/" + objectId + "/properties/"  + property)
                            }
                            else {
                                results[i] = await requestHandler.request("PUT", "/sms-mgt-api/api/"+config.mgtApiVersion+"/tenants/" + tenantDomain + "/laas/utms/" + objectId + "/properties/"  + property, payload)
                            }
                        }
                    }
                    else {
                        result = await requestHandler.request("POST", "/sms-mgt-api/api/"+config.mgtApiVersion+"/tenants/" + tenantDomain + "/laas/utms/" + objectId + '/properties/', object)
                    }
                break;
                case 'reports':
                    result = await requestHandler.request("PUT", "/sms-mgt-api/api/"+config.mgtApiVersion+"/tenants/" + tenantDomain + "/laas/reports/" + objectId, object)
                break;
            }
            result = true
        }
        catch(e:any) {
            if(e.status == 401) {
                //app.router.push({'name':'401'})
            }
            else {
                console.error(e)
            }
            result = false
        }
        return result
    }            
}
export default unifiedSecurityConsoleQueries