import punycode from "punycode"
import $ from "jquery"

export type FlagObjectProperty = string
export type FlagObjectMapInfo = FlagObjectProperty[]
export interface FlagObject {
    [key: string]: boolean
}
const encodingHelpers = {

    encodeURI: function (value:string): string {
        return encodeURIComponent(value).replace(/[!'()*]/g, (c) => {
            return '%' + c.charCodeAt(0).toString(16);
        });
    },
    escapeHTML: function (value: string): string {
        return $('<div>').text(value).html()
    },

    unescapeHTML: function (value: string): string {
        return $('<div>').html(value).text()
    },

    escapeAttribute: function (value: string): string {
        return ("" + value).replace(/[^a-zA-Z0-9]/g, function (char) { return "&#x" + char.charCodeAt(0).toString(16) + ";" })
    },

    unescapeAttribute: function (value: string): string {
        return encodingHelpers.unescapeHTML(value)
    },

    unicodeToPunycode: function (unicodeString: string) : string {
        return punycode!.toASCII(unicodeString);
    },

    punycodeToUnicode: function (punycodeString: string) : string {
        return punycode!.toUnicode(punycodeString);
    },
    bitMask: {
        decimalToBinary: function(decimal:number){
            return (decimal >>> 0).toString(2);
        },
        binaryToDecimal: function (binary:string){
            return parseInt(binary,2)
        },
        getDecimalFromFlagObject: function (flagObject:FlagObject,mapInfo:FlagObjectMapInfo) : number {
            let result = ""
            mapInfo.forEach((property) => {
                result += flagObject[property] === true ? "1" : "0"
            })
            result = Array.from(result).reverse().join("")
            return encodingHelpers.bitMask.binaryToDecimal(result)
        },
        getFlagObjectFromDecimal: function (decimal:number,mapInfo:FlagObjectMapInfo) : FlagObject {
            let result : any = {}
            const binary = encodingHelpers.bitMask.decimalToBinary(decimal)
            const reversedBinary = Array.from(binary).reverse().join("")
            mapInfo.forEach((property,index) => {
                result[property] = reversedBinary[index] === "1"
            })
            return result
        }
    }

}

export default encodingHelpers