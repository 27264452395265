<script setup lang="ts">
    import getterHelpers from "@/helpers/helpers.getters";
    import loader from "../components/loader.vue";
    import { T } from "@/classes/i18n";
    import { computed, onMounted, ref, watch } from "vue";
    import inputVueSelect from "../inputtypes/input-vue-select.vue";
    import objectStores from "@/classes/init";
    import deviceHelpers from "@/helpers/helpers.devices";
    
    const props = defineProps<{
        properties: {
            coreUtmId:"",
            utmId:"",
            existingSatellites:[],
            nodes:[]
        }
    }>();

    const errors = ref(<string[]>[])

    const activeAccountId = computed(() => {
        return getterHelpers.useStore()?.getters.getActiveAccountId
    })

    const utmOptions = computed(() => {
        let result = <selectOption[]>[]
        result = props.properties.nodes.filter((utm:any) => {
            return utm.utmId != props.properties.coreUtmId && (<string[]>props.properties.existingSatellites).indexOf(utm.utmId) == -1
        }).map((utm: any) => {
            const utmInfo = objectStores.uscUtms.getObjectFromStore(activeAccountId.value, utm.utmId)

            return {
                "id": utm.utmId,
                "text": utmInfo?.utmname + " (" + deviceHelpers.getShortDeviceId(utm.utmId) + ")" + (utm.nkView == null ? " | " + T("Offline") : ""),
                "disabled": utm.nkView == null || utm.online === false,
                "groupId": utm.nkView == null || utm.online === false ? '#unavailableUtmsHeader' : '#availableUtmsHeader'
            }
        })
        result.push({
            "type": 'groupName',
            id: '#availableUtmsHeader',
            text: T('Available'),
            disabled: true
        })
        result.push({
            "type": 'groupName',
            id: '#unavailableUtmsHeader',
            text: T('Unavailable'),
            disabled: true
        })
        return result
    })

    const initialized = ref(false)
    const selectedUtm = ref("")

    // FUNCTIONS
    const checkReadyState = () => {
        if (initialized.value == true && selectedUtm.value) {
            getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
            getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = false
        }
        else {
            getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = true
        }
    }

    // WATCHERS
    watch(utmOptions,() => {
        checkReadyState()
    })
    watch(selectedUtm,() => {
        checkReadyState()
    })
   
    onMounted(async () => {
        // IF UTMID IS NOT GIVEN, USE FIRST IN LIST
        if (!props?.properties?.utmId && utmOptions.value?.length && selectedUtm.value == "") {
            
            initialized.value = true
        }
        // IF UTMID IS GIVEN, USE IT
        else if (props?.properties?.utmId) {
            selectedUtm.value = props.properties.utmId
            initialized.value = true
        }
        checkReadyState()
    })

    const setErrors = (newErrors:string[]) => {
        errors.value = newErrors
    }
    const clearErrors = () => {
        errors.value = []
    }

    // EXPOSE SELECTED VALUES
defineExpose({ selectedUtm, setErrors, clearErrors })
</script>
<template>
    <div class="content-2">
        <template v-if="initialized">
            <template v-if="utmOptions.some((option) => { return option.disabled !== true })">
                <p>
                    {{
                    T('Select an UTM that will connect to the Core-UTM via VPN.')
                    }}
                </p>
                <p>
                    {{
                    T('The changes will be assigned to the UTMs as soon as the settings are published.')
                    }}
                </p>
                <div class="row padding-xs-y form-group border-bottom">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label class="control-label inputname" for="utmSelect">
                            {{ T('UTM') }}
                        </label>
                    </div>  
                    <div class="input col-xs-24 col-lg-10">
                        <input-vue-select id="utmSelect" v-model="selectedUtm" :select-options="utmOptions"
                            :disabled="props.properties?.utmId" :placeholder="T('Select an UTM')"></input-vue-select>
                    </div>
                </div>

            </template>
            <template v-else>
                <p class="notification bg-red color-white">
                    <i class="color-white fal fa-exclamation-triangle"></i>&nbsp;&nbsp;{{ T('Missing an UTM that can used as a satellite. Please make sure that your UTMs are up-to-date and connected to the Unified Security Console in order to configure them.') }}
                </p>
            </template>

            <template v-for="error in errors">
                <p class="notification bg-red color-white">
                    {{ T(error) }}
                </p>
            </template>

        </template>
        <template v-else>
            <div class="text-center padding-xs-y-8 col-xs">
                <div class="text-size-3">
                    <loader class="text-size-2 color-red" />
                </div>
            </div>
        </template>
    </div>
</template>